/**
 * @Author: giligili
 * @Date: 2021-08-18
 * @Last Modified by: giligili
 * @Last Modified time: 2023-05-23 13:36:59
 */

import EnumAll from 'collection-admin-web/common/constant/enum';
import {aesEncryptCallSecret} from 'collection-admin-web/common/util/encryption';

import mixinGetCaseDetail from '../../mixin/get-case-detail';
import BasicType from './basic-type';

const CallDialType = EnumAll.Call.DialType.TYPE;

export default class TransferType extends BasicType {

    type = 'SMART_IVR_TRANSFER_MANUAL';

    constructor(props) {
        super(props);

        const {screenshotCustomer} = this.inviteInfo;

        this.title = screenshotCustomer === 'true'
            ? '【截图用户进线】'
            : '【机器人转人工】';
        this.encryptedMobileNumber = aesEncryptCallSecret(
            this.sharedData.incomingMobileNumber
        );

        this.extraData.dialType = CallDialType.SMART_IVR_TRANSFER_MANUAL;
        this.extraData.encryptedMobileNumber = this.encryptedMobileNumber;
        this.extraData.isScreenshotCustomer = (screenshotCustomer === 'true');
    }

}

Object.assign(TransferType.prototype, mixinGetCaseDetail);
