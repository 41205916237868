<!-- @author wenxiujiang -->
<!-- @email wenxiujiang@yangqianguan.com -->
<!-- @date 2020-9-7 18:51:49 -->
<!-- @desc field-input.vue -->

<template>
    <a-input
        :value="value"
        :type="type"
        v-bind="def.props"
        @change="onChange"
        @pressEnter="onPressEnter"
        @blur="onBlur"
    >
        <a-icon
            v-if="isPassword"
            slot="suffix"
            :type="invisible ? 'beauty-eye-invisible-o' : 'beauty-eye-o'"
            @click="toggleType"
        />
        <slot
            slot="prefix"
            name="prefix"
        />
        <slot
            slot="suffix"
            name="suffix"
        />
        <slot
            slot="addonAfter"
            name="addonAfter"
        />
        <slot
            slot="addonBefore"
            name="addonBefore"
        />
    </a-input>
</template>

<script type="text/babel">
export default {
    name: 'FieldInput',

    model: {
        prop: 'value',
        event: 'change'
    },

    props: {
        value: {
            type: [String, Number],
            default: undefined
        },
        def: {
            type: Object,
            required: true
        },
        format: { // 支持业务对于输入内容的format处理, 需要时在业务component中定义format即可
            type: Function,
            default: null
        }
    },

    data() {
        return {
            invisible: true
        };
    },

    computed: {
        isPassword() {
            return this.def.props.type === 'password';
        },

        type() {
            if (this.isPassword) {
                return this.invisible ? 'password' : 'text';
            }

            return 'text';
        }
    },

    methods: {
        toggleType() {
            this.invisible = !this.invisible;
        },

        onChange(evt) {
            const {format} = this;
            const {value} = evt.target;

            if (format && value) {
                try {
                    Object.assign(evt.target, {
                        value: format(value),
                        originalVal: value
                    });
                } catch (err) {
                    this.$emit('change', evt);

                    throw err;
                }
            }

            this.$emit('change', evt);
        },

        onPressEnter(evt) {
            this.$emit('pressEnter', evt);
        },

        onBlur(evt) {
            this.$emit('blur', evt.target.value);
        }
    }
};
</script>
