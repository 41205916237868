var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "inline-font-size-btn" },
    [
      _c("a-icon", {
        staticClass: "icon",
        attrs: { type: "font-size" },
        on: {
          click: function ($event) {
            return _vm.onChange()
          },
        },
      }),
      _vm._v(" "),
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.fontSize,
              expression: "fontSize",
            },
          ],
          staticClass: "font-size-select",
          attrs: { size: "small" },
          on: {
            change: [
              function ($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function (o) {
                    return o.selected
                  })
                  .map(function (o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.fontSize = $event.target.multiple
                  ? $$selectedVal
                  : $$selectedVal[0]
              },
              _vm.onChange,
            ],
          },
        },
        _vm._l(_vm.optionMap, function (title, value) {
          return _c("option", { key: value, domProps: { value: value } }, [
            _vm._v("\n            " + _vm._s(title) + "\n        "),
          ])
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }