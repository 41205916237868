/*
 * Author: xymo
 * Created Date: 2021-11-05
 * email: xymo@yangqianguan.com
 */

<template>
    <div class="alarm-remind-case">
        <span class="mr10">{{ notification.data.name }} 的催告提醒</span>

        <span class="mr10">{{ notification.data.timeRemind | dateTime }}</span>

        <yqg-router-link
            class="mr10"
            to="remindCase"
            :query="{remindCaseId}"
        >
            {{ notification.data.remark || '无备注' }}
        </yqg-router-link>

        已到时间
    </div>
</template>

<script type="text/babel">
    export default {
        name: 'AlarmRemindCase',

        props: {
            notification: {
                type: Object,
                default: () => ({})
            }
        },

        computed: {
            remindCaseId() {
                const {notification: {data: {caseId}}} = this;

                return window.btoa(caseId);
            }
        }
    };
</script>
