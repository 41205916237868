/**
 * @author lany44
 * @date 2018/6/20-下午4:05
 * @file StringUtil
 */

/* eslint-disable no-param-reassign */

import _ from 'underscore';

export {default as parseUri} from './lib/parse-uri';

const isString = value => (typeof value === 'string');

/* 驼峰转换为连字符链接，例如camelStr => camel-str */
export const camelCaseToHyphen = camelStr => (
    isString(camelStr) ? camelStr.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase() : camelStr
);

// 连字符转驼峰
export const hyphenToCamelCase = hyphenStr => (
    isString(hyphenStr) ? hyphenStr.replace(/-([a-z])/g, match => match[1].toUpperCase()) : hyphenStr
);

/* 驼峰转换为下划线链接，例如camelStr => camel_str */
export const camelCaseToUnderscore = camelStr => (
    isString(camelStr) ? camelStr.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase() : camelStr
);

// 下划线转驼峰
export const underscoreToCamelCase = str => (
    isString(str) ? str.replace(/_([a-z])/g, match => match[1].toUpperCase()) : str
);

// 括号转驼峰
export const parenthesesToCamelCase = str => (
    isString(str) ? str.replace(/\(([^)])/g, match => match[1].toUpperCase()).replace(/\)/g, '') : str
);

export const numberSpace4 = x => {
    if (!x && x !== 0 && x !== '0') return '';

    const parts = x.toString().trim().split('.');
    parts[0] = parts[0] && parts[0].replace(/\B(?=(\d{4})+(?!\d))/g, ' ');

    return parts.join('.');
};

export const numberWithCommas = x => {
    if (!x && x !== 0 && x !== '0') return '';

    const parts = x.toString().trim().split('.');
    parts[0] = parts[0] && parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    return parts.join('.');
};

export const numberWithCommasFixed2 = x => ((x === +x) ? numberWithCommas((x).toFixed(2)) : x);

export const numberWithCommasFixed4 = x => ((x === +x) ? numberWithCommas((x).toFixed(4)) : x);

export const numberWithoutCommas = x => {
    if (!x && x !== 0 && x !== '0') return '';

    x = x.toString().trim().replace(/,/g, '');
    if (`${+x}` === x) {
        x = +x;
    }

    return x;
};

// 判断邮箱是否合法
export const isEmailValid = email => (/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(email.trim()));

/**
 *
 * @param pDigitNum 百分数保留几位小数
 */
export const decimalToPercent = (pDigitNum = 2) => ({
    // 0.101 => 10.1%
    filter: x => x && (x = +x) && (Math.round(x * (10 ** (pDigitNum + 2))) / (10 ** pDigitNum)),

    // 10.11% => 0.101
    reverseFilter: x => x && (x = +x) && (Math.round(x * (10 ** pDigitNum)) / (10 ** (pDigitNum + 2)))
});

export const isPhoneNumberValid = phoneNum => (
    /^0?(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/.test(phoneNum)
);

/**
 * 复制到剪贴板
 * @param content 要复制的内容
 * @param tagName
 */
export const copyToClipboard = (content = '', tagName = 'input') => {
    const input = document.createElement(tagName);

    if (tagName === 'textarea') {
        input.value = content;
    }

    input.setAttribute('value', content);
    document.body.appendChild(input);
    input.select();
    document.execCommand('copy');
    document.body.removeChild(input);
};

// 从数组生成逗号分隔的字符串，一般用于多选选择框
export const genCommaJoinStringFromArray = array => (
    _.isArray(array) ? array.join(',') : null
);

// 比特值换算成其他方式，一般用于展示
export const formatBytes = (bytes, toFixed = 2) => {
    if (+bytes === 0) return '0 Bytes';

    const unit = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const index = Math.floor(Math.log(bytes) / Math.log(unit));

    return `${parseFloat((bytes / Math.pow(unit, index)).toFixed(toFixed))}${sizes[index]}`;
};
