var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "file-link card" }, [
    _c("a", { attrs: { href: _vm.url, target: "_blank" } }, [
      _vm.isImage
        ? _c("img", { attrs: { src: _vm.url, alt: _vm.url } })
        : _c("span", [
            _vm._v("\n            " + _vm._s(_vm.url) + "\n        "),
          ]),
    ]),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "btn-delete sm danger",
        on: {
          click: function ($event) {
            return _vm.onDelete()
          },
        },
      },
      [_c("i", { staticClass: "b-icon-trash" })]
    ),
    _vm._v(" "),
    _vm.sortable
      ? _c("button", { staticClass: "normal sm sortable-handle" }, [
          _c("i", { staticClass: "b-icon-adjust" }),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }