/**
 * @author giligili
 * @date 2023-03-21
 * @email zhimingwang@fintopia.tech
 * @desc 控制 popover 是否显示
 */
let id = 0;
// selectors 选择器
export default (selectors = []) => ({
    data() {
        return {
            popoverVisible: false,
            overlayClassName: `popover-${id += 1}`
        };
    },
    mounted() {
        document.addEventListener('click', this.onDocumentClick);
        this.$el.addEventListener('click', this.onElementClick);
    },
    beforeDestroy() {
        document.removeEventListener('click', this.onDocumentClick);
        this.$el.removeEventListener('click', this.onElementClick);
    },
    methods: {
        getPopupContainer() {
            return this.$el;
        },
        popoverToggle() {
            this.popoverVisible = !this.popoverVisible;
        },
        popoverClose() {
            this.popoverVisible = false;
        },
        popoverOpen() {
            this.popoverVisible = true;
        },
        onElementClick(event) {
            const target = event.target;
            const popoverElement = document.querySelector(`.${this.overlayClassName}`);
            if (popoverElement && popoverElement.contains(target))
                return;
            this.popoverToggle();
        },
        onDocumentClick(event) {
            const target = event.target;
            const excludeDoms = [this.$el, ...selectors.map(selector => document.querySelector(selector))]
                .filter(val => val);
            if (excludeDoms.some(dom => dom.contains(target)))
                return;
            this.popoverClose();
        }
    }
});
