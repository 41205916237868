/**
 * @author panezhang
 * @date 2018/7/29-23:10
 * @file property-type-map
 */

import TYPE from './property-type';

export default {
    [TYPE.TEXT]: '文本',
    [TYPE.IMAGE]: '图片',
    [TYPE.IMAGE_LIST]: '图片列表',
    [TYPE.FILE]: '文件',
    [TYPE.FILE_LIST]: '文件列表',
    [TYPE.MARKDOWN]: 'MARKDOWN',
    [TYPE.JSON_RAW]: 'RAW JSON',
    [TYPE.JSON_TYPED]: 'TYPED JSON',
    [TYPE.HTML]: '模板',
    [TYPE.CSS]: '样式表',
    [TYPE.LIST]: '数组'
};
