/*
 * @Author: yefenghan
 * @Date: 2024-08-07 22:54:36
 * @Last Modified by: yefenghan
 * @Last Modified time: 2024-08-27 22:29:28
 */
import ComUploadLog from '@shared/client/util/log';
const MAX_RETRY_TIME = 5;
const EMPTY_POINT_LIMIT = 33;
class WhiteScreen {
    constructor() {
        this.retryTime = 0;
        this.retryTimer = null;
        this.container = ['html', 'body', '#app'];
        this.strictContainer = [];
        this.maxRetryTime = MAX_RETRY_TIME;
        this.emptyPointLimit = EMPTY_POINT_LIMIT;
        this.callback = () => ({});
        this.reportConfig = null;
    }
    static checkSupport() {
        if (document && document.elementsFromPoint && typeof document.elementsFromPoint === 'function') {
            return true;
        }
        return false;
    }
    init(options) {
        const { container, strictContainer, maxRetryTime, emptyPointLimit, callback, reportConfig } = options || {};
        this.container = container || this.container;
        this.strictContainer = strictContainer || this.strictContainer;
        this.maxRetryTime = maxRetryTime || this.maxRetryTime;
        this.emptyPointLimit = emptyPointLimit || this.emptyPointLimit;
        this.callback = callback || this.callback;
        this.reportConfig = reportConfig || null;
        if (!WhiteScreen.checkSupport()) {
            this.callback({
                error: '当前环境不支持白屏检测'
            });
            return;
        }
        if (this.emptyPointLimit > EMPTY_POINT_LIMIT) {
            this.callback({
                error: '空白点阈值不能大于33'
            });
            return;
        }
        if (document.readyState === 'complete') {
            this.idleCallback();
        }
        else {
            window.addEventListener('load', () => this.idleCallback());
        }
    }
    sampling() {
        const containerSelectors = this.getContainerSelector();
        if (containerSelectors.length >= this.emptyPointLimit) {
            // 全都是空白点
            if (this.retryTime < this.maxRetryTime) {
                this.delayRetry();
            }
            else {
                this.exportResult(true, containerSelectors);
            }
        }
        else {
            this.exportResult(false);
        }
    }
    exportResult(isWhiteScreen, selectors) {
        let selector = 'NONE';
        if (selectors && selectors.length) {
            selector = this.findMostFrequentElement(selectors) || 'NONE';
        }
        if (this.reportConfig) {
            const { appId, country, measurement, metricsType, parameter, isReportAll } = this.reportConfig;
            if (isReportAll || isWhiteScreen) {
                ComUploadLog(metricsType || 'WHITE_SCREEN', Object.assign({ measurement: measurement || 'app_metrics_for_white_screen', time: new Date().toString(), isWhiteScreen,
                    selector }, parameter), {
                    appId,
                    country
                }, true);
            }
        }
        this.callback({
            isWhiteScreen,
            selector
        });
    }
    findMostFrequentElement(strArray) {
        const frequencyMap = new Map();
        for (const element of strArray) {
            frequencyMap.set(element, (frequencyMap.get(element) || 0) + 1);
        }
        let maxCount = 0;
        let mostFrequentElement = null;
        for (const [element, count] of frequencyMap.entries()) {
            if (count > maxCount) {
                maxCount = count;
                mostFrequentElement = element;
            }
        }
        return mostFrequentElement;
    }
    delayRetry() {
        this.retryTimer = setTimeout(() => {
            this.idleCallback();
            this.retryTime++;
            clearTimeout(this.retryTimer);
            this.retryTimer = null;
        }, 1000);
    }
    checkContiner(element, containerElems, strictElements) {
        const wrapperElement = containerElems.find(i => i.element === element[0]);
        if (wrapperElement) {
            return wrapperElement.selector;
        }
        const strictElement = strictElements.find(strictElem => {
            return element.some(e => e === strictElem.element);
        });
        if (strictElement) {
            return strictElement.selector;
        }
        return false;
    }
    getContainerSelector() {
        const selectorMap = [];
        const { innerWidth, innerHeight } = window;
        const containerElementsMap = this.container
            .map(selector => ({
            selector,
            element: document.querySelector(selector)
        }))
            .filter(i => i.element);
        const strictElementsMap = this.strictContainer
            .map(selector => ({
            selector,
            element: document.querySelector(selector)
        }))
            .filter(i => i.element);
        for (let i = 1; i <= 9; i++) {
            // x轴采样点
            const xElement = document.elementsFromPoint((innerWidth * i) / 10, innerHeight / 2);
            const xContainer = this.checkContiner(xElement, containerElementsMap, strictElementsMap);
            if (xContainer)
                selectorMap.push(xContainer);
            // 中心点不再重复计算
            if (i === 5)
                continue;
            // y轴采样点
            const yElement = document.elementsFromPoint(innerWidth / 2, (innerHeight * i) / 10);
            // 上升对角线采样点
            const upDiagonalElement = document.elementsFromPoint((innerWidth * i) / 10, (innerHeight * i) / 10);
            // 下降对角线采样点
            const downDiagonalElement = document.elementsFromPoint((innerWidth * i) / 10, (innerHeight * (10 - i)) / 10);
            const yContainer = this.checkContiner(yElement, containerElementsMap, strictElementsMap);
            if (yContainer)
                selectorMap.push(yContainer);
            const upDiagonalContainer = this.checkContiner(upDiagonalElement, containerElementsMap, strictElementsMap);
            if (upDiagonalContainer)
                selectorMap.push(upDiagonalContainer);
            const downDiagonalContainer = this.checkContiner(downDiagonalElement, containerElementsMap, strictElementsMap);
            if (downDiagonalContainer)
                selectorMap.push(downDiagonalContainer);
        }
        return selectorMap;
    }
    idleCallback() {
        if (window.requestIdleCallback) {
            window.requestIdleCallback(deadline => {
                if (deadline.timeRemaining() > 0 || deadline.didTimeout) {
                    this.sampling();
                }
            }, { timeout: 1000 });
        }
        else {
            this.sampling();
        }
    }
}
export default new WhiteScreen();
