var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("yqg-simple-form", {
    attrs: {
      title: _vm.title,
      options: _vm.options,
      "confirm-label": _vm.confirmLable,
    },
    on: {
      confirm: _vm.confirm,
      cancel: function ($event) {
        return _vm.$emit("dismiss")
      },
    },
    scopedSlots: _vm._u(
      [
        _vm.href
          ? {
              key: "extraBtns",
              fn: function () {
                return [
                  _c(
                    "a-button",
                    {
                      staticClass: "yqg-form-btn yqg-btn-yellow",
                      attrs: { href: _vm.href, download: "" },
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.downloadButtonText) +
                          "\n        "
                      ),
                    ]
                  ),
                ]
              },
              proxy: true,
            }
          : null,
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }