<!-- @author xiaoduoshu -->
<!-- @email xiaoduoshu@fintopia.tech -->
<!-- @date 2022-4-1 19:21:09.252 -->

<template>
    <div class="yqg-antv-upload-file-url">
        <a-upload
            v-if="defaultFileList"
            :custom-request="customRequest"
            :default-file-list="defaultFileList"
            :multiple="isMultiple"
            :disabled="disabled"
            v-bind="$attrs"
            :remove="onRemove"
            @change="onFileChange"
        >
            <div>
                <a-button icon="plus">
                    上传文件
                </a-button>
            </div>
        </a-upload>
    </div>
</template>

<script type="text/babel">

    import handleUpload from '../mixins/handle-upload';

    export default {
        name: 'YqgAntvUploadFileFile',

        mixins: [handleUpload],

        inheritAttrs: false
    };
</script>

