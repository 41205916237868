<!-- @author yanglan -->
<!-- @email yanglan@yangqianguan.com -->
<!-- @date 2018-06-08 14:46:04.919 -->
<!-- @desc generated by yqg-cli@0.1.13 -->

<template>
    <div
        v-if="innerOptions"
        class="yqg-info-view"
    >
        <template v-for="(fieldDef, index) in innerOptions.fieldDefs">
            <slot
                v-if="fieldDef.field"
                :name="fieldDef.field"
                :fieldDef="fieldDef"
            >
                <div
                    :key="index"
                    class="yqg-info-view-field"
                >
                    <label>{{ fieldDef.label }}：</label>
                    <span class="yqg-info-view-field-value">
                        {{ getValue(fieldDef) | nullFilter }}
                    </span>
                </div>
            </slot>

            <br
                v-else-if="fieldDef.type === 'br'"
                :key="index"
            >
        </template>
    </div>
</template>

<script type="text/babel">
import enumOptionsConvert from '../../constant/enum/enum-options-convert';

import nullFilter from 'yqg-common/vue/filter/lib/null-filter';

export default {
    name: 'YqgInfoView',

    filters: {
        nullFilter
    },

    props: {
        data: {
            type: Object,
            required: true
        },
        options: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            innerOptions: null
        };
    },

    computed: {
        hasEnumConf() {
            const vm = this;

            return vm.options.fieldDefs.some(({enumType}) => enumType);
        }
    },

    watch: {
        options() {
            const vm = this;

            vm.updateInnerOptions();
        }
    },

    created() {
        const vm = this;

        vm.updateInnerOptions();
    },

    methods: {
        updateInnerOptions() {
            const vm = this;

            enumOptionsConvert(vm.options, vm.updateEnumFieldDef)
                .then(options => {
                    const {fieldDefs} = options;
                    vm.innerOptions = {
                        ...options,
                        fieldDefs: fieldDefs.filter(({isHide}) => !isHide)
                    };
                })
                .catch(err => err);
        },

        updateEnumFieldDef(fieldDef) {
            return fieldDef.enumType ? {...fieldDef, map: fieldDef.enumType.MAP} : fieldDef;
        },

        getValue({field, map, filter}) {
            const vm = this;
            const {data} = vm;
            const value = vm.getDepthValue(data, field);
            const res = (map && map[value]) || value;

            return (filter && filter(res)) || res;
        },

        getDepthValue(value = {}, field = '') {
            const fields = field.split('.');

            return fields.reduce((preVal, currVal) => {
                if (!preVal) return null;

                return preVal[currVal];
            }, value);
        }
    }
};
</script>

<style lang="scss" rel="stylesheet/scss">
.yqg-info-view {
    .yqg-info-view-field {
        display: inline-block;
        min-width: 250px;
        margin-right: 8px;
        margin-bottom: 15px;

        > span {
            color: #333;
            line-height: 1.5;
        }
    }
}
</style>
