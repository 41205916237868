/*
 * @Author: xiaodongyu
 * @Date 2021-04-16 08:58:41
 * @Last Modified by: zhengwang1 
 * @Last Modified time: 2023-12-28 16:14:53 
 */
/* global __STAGE__ */

export default {
    data() {
        return {
            workCardUrl: '',
            parentElement: null
        };
    },

    mounted() {
        if (!this.hideOaAvatar) {
            this.initWorkCard();
        }
    },

    beforeDestroy() {
        if (!this.hideOaAvatar) {
            this.parentElement.removeChild(this.oaFrame);
            window.removeEventListener('message', this.onPostMessage);
        }
    },

    methods: {
        initWorkCard() {
            const stage = __STAGE__;
            let subDomainSuffix = '-test';
            if (/prod/i.test(stage)) {
                subDomainSuffix = '';
            } else if (/feat/i.test(stage)) {
                subDomainSuffix = '-feat';
            }

            const oaFrameSrc = `https://oa${subDomainSuffix}.yangqianguan.com/static/share.html`;
            const oaFrame = document.createElement('iframe');
            oaFrame.style = 'position: absolute; top: -100px; width: 0; height: 0; border: 0; visibility: hidden;';
            oaFrame.src = oaFrameSrc;
            this.$el.parentElement.appendChild(oaFrame);

            // 先存下，因为beforeDestroy已经拿不到this.$el.parentElement了
            this.parentElement = this.$el.parentElement;
            this.oaFrame = oaFrame;
            window.addEventListener('message', this.onPostMessage);
        },

        onPostMessage(evt) {
            const {workCardBlob = null} = evt.data || {};
            if (workCardBlob) {
                this.workCardUrl = URL.createObjectURL(workCardBlob);
            }
        }
    }
};
