var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    { staticClass: "yqg-enum" },
    [
      _vm._t(
        "default",
        function () {
          return [
            _vm._v(
              "\n        " +
                _vm._s(_vm.map[_vm.value] || _vm.defaultText || _vm.value) +
                "\n    "
            ),
          ]
        },
        { value: _vm.map[_vm.value], map: _vm.map }
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }