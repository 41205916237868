/**
 * @Author: xymo
 * @Date: 2023-07-20
 * @Last Modified by: xymo
 * @Last Modified time: 2023-07-20 18:51:28
 */

import Resource from 'collection-admin-web/common/resource/knowledge';
import Enum from 'collection-admin-web/common/util/enum';
import {formatTreeData} from 'collection-admin-web/common/util/tree';

export const CategoryEnum = Enum.query(
    async () => {
        const resp = await Resource.fetchQuestionCategoryTree({hideLoading: true});
        const body = formatTreeData({
            tree: resp.data.body,
            childrenKey: 'childList',
            nodeBodyField: 'data'
        });

        return {...resp, data: {...resp.data, body}};
    },
    {queryOnce: true}
);

const flattenCategory = (tree, res) => {
    if (tree.length) {
        tree.forEach(({data: {id, name}, childList}) => {
            if (childList) {
                flattenCategory(childList, res);
            }

            res.push({id, name});
        });
    }
};

export const flattenedCategoryEnum = Enum.query(async () => {
    const resp = await Resource.fetchQuestionCategoryTree({hideLoading: true});
    const res = [];
    flattenCategory(resp.data.body, res);
    resp.data.body = res;

    return resp;
}, {queryOnce: true});
