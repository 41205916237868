var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "alarm-complaint" },
    [
      _vm._v(
        "\n    你于 " +
          _vm._s(_vm._f("dateTime")(_vm.notification.data.timeRemind)) +
          " 收到了一个 " +
          _vm._s(_vm.notification.data.name) +
          " 的投诉\n\n    "
      ),
      _c("yqg-case-link", {
        attrs: { text: "案件详情 >", "case-id": _vm.notification.data.caseId },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }