<!-- @author panezhang -->
<!-- @email panezhang@yangqianguan.com -->
<!-- @date 2018-07-28 19:17:01.766 -->
<!-- @desc generated by yqg-cli@0.3.1 -->

<template>
    <b-dialog
        :open="visible"
        :close-on-click-outside="false"
        title="上传图片"
        @close="cancel"
    >
        <div class="image-upload-modal-body b-form form-block">
            <b-form-group label="">
                <yqg-upload
                    v-model="url"
                    auto-upload
                    accept="image/*"
                    :bucket="bucket"
                    :root-dir="rootDir"
                    :dir="dir"
                />
            </b-form-group>
        </div>

        <button
            slot="actions"
            class="simple lg"
            @click="cancel()"
        >
            取消
        </button>
        <button
            slot="actions"
            class="simple lg"
            @click="confirm()"
        >
            确定
        </button>
    </b-dialog>
</template>

<script type="text/babel">

export default {
    name: 'ImageUploadModal',

    props: {
        bucket: null,

        dir: null,

        rootDir: null
    },

    data() {
        return {
            url: '',
            visible: false
        };
    },

    mounted() {
        const vm = this;
        vm.visible = true;
    },

    methods: {
        cancel() {
            const vm = this;
            vm.visible = false;
            vm.reject();
        },

        confirm() {
            const {$toast, url} = this;
            if (!url) {
                $toast.error('请上传一张图片');

                return;
            }

            this.visible = false;
            this.resolve(url);
        }
    }
};

</script>
