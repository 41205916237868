var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-input",
    _vm._b(
      {
        attrs: { value: _vm.value, type: _vm.type },
        on: {
          change: _vm.onChange,
          pressEnter: _vm.onPressEnter,
          blur: _vm.onBlur,
        },
      },
      "a-input",
      _vm.def.props,
      false
    ),
    [
      _vm.isPassword
        ? _c("a-icon", {
            attrs: {
              slot: "suffix",
              type: _vm.invisible ? "beauty-eye-invisible-o" : "beauty-eye-o",
            },
            on: { click: _vm.toggleType },
            slot: "suffix",
          })
        : _vm._e(),
      _vm._v(" "),
      _vm._t("prefix", null, { slot: "prefix" }),
      _vm._v(" "),
      _vm._t("suffix", null, { slot: "suffix" }),
      _vm._v(" "),
      _vm._t("addonAfter", null, { slot: "addonAfter" }),
      _vm._v(" "),
      _vm._t("addonBefore", null, { slot: "addonBefore" }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }