<!-- @author giligili -->
<!-- @email zhimingwang@yangqianguan.com -->
<!-- @date 2021-07-02 15:27:09.935 -->
<!-- @desc generated by yqg-cli@5.0.9 -->

<template>
    <div class="yqg-modal">
        <div class="yqg-modal-title">
            <slot name="title">
                {{ title }}
            </slot>
        </div>

        <div class="yqg-modal-container mb20">
            <slot name="container" />
        </div>

        <div class="yqg-modal-footer">
            <slot name="footer">
                <a-button
                    v-if="$listeners.cancel"
                    @click="$listeners.cancel"
                    v-text="cancelLabel"
                />

                <a-button
                    v-if="$listeners.confirm"
                    type="primary"
                    @click="$listeners.confirm"
                    v-text="confirmLabel"
                />
            </slot>
        </div>
    </div>
</template>

<script type="text/babel">

    export default {
        name: 'YqgModal',

        props: {
            title: {
                type: String,
                default: ''
            },

            cancelLabel: {
                type: String,
                default: '取消'
            },

            confirmLabel: {
                type: String,
                default: '确认'
            }
        }
    };
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .yqg-modal {
        .yqg-modal-title {
            margin-bottom: 20px;
            font-size: 24px;
            font-weight: 600;
            color: #000;
        }

        .yqg-modal-footer {
            text-align: right;

            .ant-btn {
                margin-left: 10px;
            }
        }
    }

</style>
