/**
 * @Author: xymo
 * @Date: 2023-09-01
 * @Last Modified by: yefenghan
 * @Last Modified time: 2024-09-05 15:58:58
 */

export default email => {
    if (typeof email !== 'string') return '';
    if (!email) return '';

    const [name, domain] = email.split('@');

    if (name.length <= 3) {
        return email;
    }

    const part1 = name.slice(0, 2);
    const part2 = name.slice(-2);
    const newName = part1 + '****' + part2;

    return `${newName}@${domain}`;
};
