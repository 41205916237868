/**
 * @author giligili
 * @date 2021-07-30
 * @email zhimingwang@yangqianguan.com
 * @desc form-options
 */

/**
 * @author giligili
 * @date 2021-07-30
 * @email zhimingwang@yangqianguan.com
 * @desc form-options
 */

import * as Fields from './fields';

export default {
    fieldDefs: [
        Fields.minOverdueDays,
        Fields.maxOverdueDays,
        Fields.startPrincipal,
        Fields.endPrincipal,
        Fields.contactType
    ]
};
