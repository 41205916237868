var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "qa-display" },
    _vm._l(_vm.renderQaList, function (question) {
      return _c(
        "collapse",
        {
          key: question.id,
          staticClass: "question-wrapper",
          scopedSlots: _vm._u(
            [
              {
                key: "title",
                fn: function () {
                  return [
                    _c(
                      "div",
                      { staticClass: "title" },
                      [
                        _c(
                          "div",
                          { staticClass: "title-content" },
                          [
                            _c("html-text", {
                              attrs: { text: question.description },
                            }),
                            _vm._v(" "),
                            question.disabled
                              ? _c(
                                  "a-tag",
                                  {
                                    staticClass: "ml10",
                                    attrs: {
                                      color:
                                        question.disabled === 1 ? "orange" : "",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                        " +
                                        _vm._s(
                                          question.disabled === 1
                                            ? "未开始"
                                            : "已过期"
                                        ) +
                                        "\n                    "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "a-space",
                          [
                            _vm.$app.permissions.KNOWLEDGE_TEXT_STAR
                              ? _c("a-icon", {
                                  style: {
                                    color: question.collectTime ? "red" : "",
                                    fontSize: "18px",
                                  },
                                  attrs: {
                                    theme: question.collectTime
                                      ? "filled"
                                      : "outlined",
                                    type: "heart",
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.collect(question)
                                    },
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "footer",
                fn: function () {
                  return [
                    _c("a-space", { staticClass: "footer" }, [
                      _c(
                        "span",
                        [
                          _c("a-icon", { attrs: { type: "appstore" } }),
                          _vm._v(
                            "\n                    所属分类：\n\n                    "
                          ),
                          _vm._l(
                            question.questionCategoryIds,
                            function (category) {
                              return _c(
                                "span",
                                { key: category },
                                [
                                  _vm._v("\n                        ["),
                                  _c("def-value", {
                                    attrs: {
                                      value: category,
                                      def: {
                                        enumType:
                                          _vm.$options.flattenedCategoryEnum,
                                      },
                                    },
                                  }),
                                  _vm._v("]\n                    "),
                                ],
                                1
                              )
                            }
                          ),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        [
                          _c("a-icon", { attrs: { type: "time-control" } }),
                          _vm._v(
                            "\n                    更新于：" +
                              _vm._s(_vm._f("dateTime")(question.updateTime)) +
                              "\n                "
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        [
                          _c("a-icon", { attrs: { type: "calendar" } }),
                          _vm._v(
                            "\n                    有效期：\n                    "
                          ),
                          question.disabled
                            ? _c("span", [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(
                                      question.disabled === 1
                                        ? "未开始"
                                        : "已过期"
                                    ) +
                                    "\n                    "
                                ),
                              ])
                            : _c("span", [_vm._v("生效中")]),
                          _vm._v(" "),
                          question.enableStartTime || question.enableEndTime
                            ? _c("span", [
                                _vm._v(
                                  "\n                        (" +
                                    _vm._s(
                                      _vm.getTime(
                                        question.enableStartTime,
                                        " /"
                                      )
                                    ) +
                                    " ~ " +
                                    _vm._s(
                                      _vm.getTime(question.enableEndTime, "/ ")
                                    ) +
                                    ")\n                    "
                                ),
                              ])
                            : _c("span", [_vm._v("(永久生效)")]),
                        ],
                        1
                      ),
                    ]),
                  ]
                },
                proxy: true,
              },
            ],
            null,
            true
          ),
        },
        [
          _vm._v(" "),
          _vm._l(question.answers, function (answer, idx) {
            return _c(
              "collapse",
              {
                key: "question-" + idx,
                attrs: { "default-expand": "" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "title",
                      fn: function () {
                        return [
                          _c("span", [
                            _vm._v(
                              "\n                    " +
                                _vm._s("回答" + (idx + 1)) +
                                "\n                "
                            ),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  true
                ),
              },
              [
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "answer" },
                  [_c("html-text", { attrs: { text: answer.answer } })],
                  1
                ),
              ]
            )
          }),
        ],
        2
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }