/**
 * @Author: xymo
 * @Date: 2024-02-21
 * @Last Modified by: xymo
 * @Last Modified time: 2024-02-21 15:13:18
 */

import Fields from './fields';

export default {
    layout: 'horizontal',
    fieldDefs: [
        Fields.symbol
    ]
};
