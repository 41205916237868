<!-- @author giligili -->
<!-- @email zhimingwang@yangqianguan.com -->
<!-- @date 2021-09-08 15:42:31.002 -->
<!-- @desc generated by yqg-cli@5.1.2 -->

<template>
    <div class="yqg-checkbox">
        <a-checkbox
            v-if="!checkAllInvisible"
            :checked="checkAll"
            :indeterminate="indeterminate"
            @change="onCheckAllChange"
        >
            全选
        </a-checkbox>

        <a-checkbox-group
            :value="checkedValue"
            @change="onChange"
        >
            <template v-for="option in filterOptions">
                <a-checkbox
                    :key="option.value"
                    :value="option.value"
                >
                    {{ option.label }}
                </a-checkbox>
            </template>
        </a-checkbox-group>
    </div>
</template>

<script type="text/babel">

    import _ from 'underscore';

    import {enumType} from 'collection-admin-web/common/mixin';

    export default {
        name: 'YqgAntvCheckbox',

        mixins: [enumType],

        model: {
            prop: 'value',
            event: 'change'
        },

        props: {
            value: {
                type: Array,
                default: () => []
            },

            enumFilter: {
                type: Function,
                default: val => val
            },

            saveExtraValue: {
                type: Boolean,
                default: false
            },

            checkAllInvisible: {
                type: Boolean,
                default: true
            }
        },

        data() {
            const {list, value} = this;

            return {
                extraValue: this.getExtraValue(list, value),
                checkedValue: this.getCheckedValue(list, value)
            };
        },

        computed: {
            filterOptions() {
                const {options} = this;

                return this.enumFilter(options);
            },

            indeterminate() {
                const {checkedValue, list} = this;

                return !!(checkedValue.length && checkedValue.length < list.length);
            },

            checkAll() {
                const {checkedValue, list} = this;

                return !!(checkedValue.length && checkedValue.length === list.length);
            }
        },

        watch: {
            list(nextValue) {
                const {value} = this;

                this.extraValue = this.getExtraValue(nextValue, value);
                this.checkedValue = this.getCheckedValue(nextValue, value);
            },

            value(nextValue) {
                const {list} = this;

                this.checkedValue = this.getCheckedValue(list, nextValue);
            }
        },

        methods: {
            getExtraValue(list = [], nextValue = []) {
                const hashMap = list.map(({value}) => value).reduce((accu, curr) => ({...accu, [curr]: curr}), {});

                return nextValue.filter(value => _.isUndefined(hashMap[value]));
            },

            getCheckedValue(list = [], nextValue = []) {
                const hashMap = list.map(({value}) => value).reduce((accu, curr) => ({...accu, [curr]: curr}), {});

                return nextValue.filter(value => !_.isUndefined(hashMap[value])).map(val => val.toString());
            },

            onCheckAllChange(event) {
                const checkAll = event.target.checked;

                const val = checkAll
                    ? this.filterOptions.map(({value}) => value)
                    : [];

                this.onChange(val);
            },

            onChange(val) {
                const {saveExtraValue, extraValue = []} = this;

                this.$emit(
                    'change',
                    val.concat(saveExtraValue ? extraValue : [])
                );
            },

            isChecked(checkboxValue) {
                const {value} = this;

                return value.includes(checkboxValue);
            }
        }
    };
</script>
