var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "yqg-marquee" },
    [
      _c(
        "a-tooltip",
        {
          attrs: {
            placement: "bottom",
            "overlay-style": _vm.tooltipStyle,
            "get-popup-container": function () {
              return _vm.$el
            },
          },
        },
        [
          _c(
            "template",
            { slot: "title" },
            [
              _c("yqg-md-view", {
                staticClass: "marquee-detail",
                attrs: { "md-text": _vm.content },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              ref: "marquee",
              staticClass: "marquee-text",
              on: { mouseenter: _vm.pause, mouseleave: _vm.resume },
            },
            [
              _c("yqg-md-view", {
                ref: "marqueeText",
                attrs: { "md-text": _vm.content },
              }),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }