<!-- @author giligili -->
<!-- @email zhimingwang@yangqianguan.com -->
<!-- @date 2021-05-21 16:08:41.740 -->
<!-- @desc generated by yqg-cli@4.0.7 -->

<template>
    <div class="yqg-multi-input">
        <template v-for="(val, idx) in values">
            <div
                :key="idx"
                class="input-wrap"
            >
                <a-input
                    :value="val"
                    @input="(event) => changeValue(idx, event)"
                />
                <a-icon
                    class="yqg-pointer ml10"
                    type="minus"
                    @click="() => deleteValue(idx)"
                />
            </div>
        </template>

        <a-icon
            v-if="canAdd"
            class="yqg-pointer"
            type="plus"
            @click="addValue"
        />
    </div>
</template>

<script type="text/babel">
    import {notNull} from 'collection-admin-web/common/util/object';

    export default {
        name: 'YqgMultiInput',

        props: {
            values: {
                type: Array,
                default: () => []
            }
        },

        computed: {
            canAdd() {
                return this.values.every(val => notNull(val));
            }
        },

        methods: {
            addValue() {
                this.onChange([...this.values, '']);
            },

            deleteValue(idx) {
                this.onChange(this.values.filter((val, index) => index !== idx));
            },

            changeValue(idx, event) {
                this.onChange([
                    ...this.values.slice(0, idx),
                    event.target.value,
                    ...this.values.slice(idx + 1)
                ]);
            },

            onChange(nextValue) {
                this.$emit('change', nextValue);
            }
        }
    };
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .yqg-multi-input {
        .input-wrap {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
        }
    }
</style>
