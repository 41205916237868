/**
 * @author lany44
 * @date 2018/6/20-下午4:18
 * @file DataProcessor
 */

export const translateMultiRowInputToArray = text => (text && text.trim ? text.trim().split(/\n+/) : []);

export const translateArrayToMultiRowInput = arr => (!!arr && arr.join ? arr.join('\n') : '');

/**
 * 解析层级对象
 * @param value {*} {innerObj: {a: 'xxx', b: 'yyy'}} {{a: 'xxx', b: 'yyy'}}
 * @param field innerObj.a a
 */
export const getValue = (value = {}, field = '') => {
    if (typeof value !== 'object') {
        return value;
    }

    const fields = field.split('.');

    return fields.reduce((preVal, currVal) => {
        if (!preVal) return null;

        return preVal[currVal];
    }, value);
};

// 从 map 生成供 YqgArraySelect 使用的对象数组，数组每个元素如 {key: 'someKey', value: 'someValue'}
export const genYqgListFromMap = vo => (
    Object.keys(vo).map(key => ({key, value: vo[key]}))
);

export const genListFromMap = (vo, keyProperty, valueProperty) => (
    Object.keys(vo).map(key => ({[keyProperty]: key, [valueProperty]: vo[key]}))
);

// 从对象数组生成供 YqgArraySelect 用的对象数组，例如([{idx: 1, value: '教育分期'}], 'idx', 'value') => {key: 1, value: '教育分期'}
export const genYqgListFromObjectArray = (arr, keyProperty, valueProperty) => (
    arr.map(item => ({key: item[keyProperty], value: item[valueProperty]}))
);

// 从对象数组生成供YqgSelect用的map，例如([{idx: 1, value: '教育分期'}], 'idx', 'value') => {1: '教育分期'}
export const genMapFromObjectArray = (arr, keyProperty, valueProperty) => {
    const map = {};
    if (arr && arr.length > 0) {
        arr.forEach(item => {
            map[getValue(item, keyProperty)] = getValue(item, valueProperty);
        });
    }

    return map;
};

// 使用$http.get带数组参数时需要在参数名后添加[]
export const genArrayParams = (arrayParamList, paramsObj) => {
    arrayParamList.forEach(arrayParam => {
        if (paramsObj[arrayParam]) {
            paramsObj[`${arrayParam}[]`] = paramsObj[arrayParam];
            delete paramsObj[arrayParam];
        }
    });

    return paramsObj;
};

// Creates an array of elements split into groups the length of size.
// If array can't be split evenly, the final chunk will be the remaining elements
export const chunk = (array = [], size = 1) => {
    if (array.length <= 1) {
        return [];
    }

    const result = [];
    let index = 0;

    while (index < array.length) {
        result.push(array.slice(index, (index += size)));
    }

    return result;
};

/**
 * 把data里类型为Date的字段转换为时间戳
 * @param data 需要处理的对象
 * @param fields 需要处理的字段，不填则处理所有字段
 * @param postFix 转换成时间戳后字段的后缀，可以为空
 * @param del 在有postFix的情况下，是否删除原始字段
 * @returns {*}
 */
export const transformDateFieldToTimeStamp = ({data, fields = Object.keys(data), postFix = '', del = true}) => {
    if (!data) {
        return data;
    }

    data = Object.assign({}, data);
    fields.forEach(field => {
        if (data[field] && data[field] instanceof Date) {
            data[field + postFix] = data[field].getTime();

            if (postFix && del) {
                delete data[field];
            }
        }
    });

    return data;
};

/**
 * 把一个代码编辑器中的 Javascript 原生对象字符串转成 JSON 字符串
 * 实现方法是创造真正的 Javascript 执行环境将完整的对象 return 出去，再 stringify
 * 为了防止坏人在代码块中植入可执行代码，强制代码块必须被包裹在花括号中，且代码中的括号将被去除
 * @param str 需要处理的字符串
 * @returns {*}
 */
export const pureJSONString = (str = '') => {
    str = str.trim();
    if (!((str[0] === '{' && str.slice(-1) === '}') || (str[0] === '[' && str.slice(-1) === ']'))) return '';

    const SYMBOLS = {
        LEFT_BRACE: '&#40;',
        RIGHT_BRACE: '&#41;'
    };

    // replace braces to prevent function call
    str = str.replace('(', SYMBOLS.LEFT_BRACE)
        .replace(')', SYMBOLS.RIGHT_BRACE);

    return JSON.stringify((new Function('', `return ${str};`))()) // eslint-disable-line
        .replace(SYMBOLS.LEFT_BRACE, '(')
        .replace(SYMBOLS.RIGHT_BRACE, ')');
};

export const reformatJson = (code, indent = 4, isShowToast = true) => {
    try {
        code = JSON.stringify(JSON.parse(code), null, indent);
        if (isShowToast) {
            YqgToast.success('Reformat 成功');
        }
    } catch (err) {
        YqgToast.error('Reformat 失败，只支持对严格的 JSON 格式进行 Reformat');
        throw err;
    }

    return code;
};

/**
 * 把一个对象进行深度克隆
 * 如果需要复制的对象内没有二级对象，则直接用...，不必用此方法
 * @param obj 需要克隆的对象
 * @returns {*}
 */
export const deepClone = obj => {
    if (typeof obj !== 'object') return obj;

    if (window.JSON) return JSON.parse(JSON.stringify(obj));

    const result = obj.constructor === Object ? {} : [];

    Object.keys(obj).forEach(key => (result[key] = deepClone(obj[key])));

    return result;
};
