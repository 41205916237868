var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-tree-select",
    _vm._b(
      {
        on: { change: _vm.onChange },
        model: {
          value: _vm.compValue,
          callback: function ($$v) {
            _vm.compValue = $$v
          },
          expression: "compValue",
        },
      },
      "a-tree-select",
      _vm.compProps,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }