/*
 * Author: xymo
 * Created Date: 2021-06-03
 * email: xymo@yangqianguan.com
 */

import Resource from '@yqg/resource';

const api = {
    urlPrefix: '/admin/operation/col/case/',

    list: {
        url: 'listDemandLetter',
        method: 'get'
    },

    export: {
        url: 'exportDemandLetter',
        download: true
    },

    listDemandLetterServiceProvider: {
        url: 'listDemandLetterServiceProvider',
        method: 'get'
    }
};

export default Resource.create(api);
