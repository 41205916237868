<template>
    <div class="speech-detection">
        <a-spin
            :spinning="spinning"
            tip="Loading..."
        >
            <div style="font-size: 14px;">
                <div>检测说明：</div>
                <div>请尝试说几句话，判断耳机里能否听到你刚才说话的声音。如果能听到，代表麦克风、扬声器、网络没有问题。</div>
                <div>无声解决方案：</div>
                <div>请切换麦克风或者扬声器设备后重试。如还未解决，请反馈管理员。如果切换设备后能够听到声音，请在电脑设置中调整为对应设备。</div>
            </div>

            <div style="display: flex; align-items: center; margin-top: 20px;">
                <span style="width: 80px; font-size: 14px;">麦克风</span>
                <div
                    id="volume-bar"
                    style="width: 420px; background: #eee; height: 20px;"
                >
                    <div
                        id="volume"
                        style="width: 0; background: #76c7c0; height: 100%;"
                    />
                </div>
            </div>
            <div style="margin-top: 10px;">
                <span style="font-size: 14px;">输入设备名称：</span>
                <a-select
                    v-model="audioInputDeviceId"
                    style="width: 400px;"
                    @change="onAudioInputChange"
                >
                    <a-select-option
                        v-for="device in audioInputDevices"
                        :key="device.deviceId"
                        :value="device.deviceId"
                    >
                        {{ device.label }}
                    </a-select-option>
                </a-select>
            </div>

            <div style="display: flex; align-items: center; margin-top: 20px;">
                <span style="width: 80px; font-size: 14px;">扬声器</span>
                <div
                    id="volume-bar2"
                    style="width: 420px; background: #eee; height: 20px;"
                >
                    <div
                        id="volume2"
                        style="width: 0; background: #76c7c0; height: 100%;"
                    />
                </div>
            </div>
            <div style="margin-top: 10px;">
                <span style="font-size: 14px;">输出设备名称：</span>
                <a-select
                    v-model="audioOutDevicesId"
                    style="width: 400px;"
                    @change="onAudioOutChange"
                >
                    <a-select-option
                        v-for="device in audioOutDevices"
                        :key="device.deviceId"
                        :value="device.deviceId"
                    >
                        {{ device.label }}
                    </a-select-option>
                </a-select>
            </div>
            <div style="margin-top: 20px; display: flex; flex-direction: row-reverse;">
                <a-button
                    type="primary"
                    @click="handleCancel"
                >
                    关闭
                </a-button>
            </div>
        </a-spin>
    </div>
</template>

<script type="text/babel">
    import {message} from 'ant-design-vue';
    import {debounce} from 'underscore';

    import Api from '../resource/speech-detection';

    const SETTIMEOUT_TIME = 2000;

    export default {
        name: 'YqgSpeechDetectionModal',

        props: {
            callAccountId: {
                type: String,
                default: ''
            },
            type: {
                type: String,
                default: ''
            }
        },

        data() {
            this.audioCtxInput = null;
            this.audioCtxOutData = null;
            this.audioInputStream = null;
            this.audioOutStream = null;
            this.levelCheckerInput = null;
            this.levelCheckerOut = null;

            this.devicechangeLock = false;

            return {
                audioContext: null,
                audioInputDevices: [],
                audioOutDevices: [],
                audioInputDeviceId: '',
                audioOutDevicesId: '',
                spinning: false
            };
        },
        mounted() {
            this.init();

            window.navigator.mediaDevices.ondevicechange = debounce(() => {
                const vm = this;

                // 安排一个锁，防止多次弹窗
                if (!this.devicechangeLock) {
                    vm.devicechangeLock = true;

                    this.$confirm({
                        title: '音频设备发生变化，将重新检测设备',
                        content: '',
                        style: {top: '100px'},
                        centered: false,
                        okCancel: false,
                        onOk() {
                            vm.devicechangeLock = false;

                            // 暂停音频检测
                            vm.close();

                            // 获取新设备列表
                            vm.getDevices().then(res => {
                                if (!res?.flag) {
                                    message.error('未检测到音频设备');

                                    return;
                                }

                                vm.audioInputDevices = res.audioInputDevices;
                                vm.audioOutDevices = res.audioOutDevices;
                                vm.audioInputDeviceId = vm.audioInputDevices[0].deviceId;
                                vm.audioOutDevicesId = vm.audioOutDevices[0].deviceId;

                                // 从新音频检测启动
                                vm.spinning = true;
                                setTimeout(() => {
                                    vm.onDevicechange(vm.audioInputDeviceId, vm.audioOutDevicesId);
                                }, SETTIMEOUT_TIME);
                            }).catch(error => {
                                message.error(error?.err || '未检测到音频设备');
                            });
                        },
                    });
                }
            }, 500);
        },

        destroyed() {
            window.navigator.mediaDevices.ondevicechange = null;

            this.$emit('setMediaConstraints', null);
        },

        methods: {
            mediaErrorCaptured(error) {
                // 媒体权限失败处理（通用 详细）
                const nameMap = {
                    AbortError: '操作中止',
                    NotAllowedError: '打开设备权限不足，原因是用户拒绝了媒体访问请求',
                    NotFoundError: '找不到满足条件的设备',
                    NotReadableError: '系统上某个硬件、浏览器或网页层面发生的错误导致设备无法被访问',
                    OverConstrainedError: '指定的要求无法被设备满足',
                    SecurityError: '安全错误，使用设备媒体被禁止',
                    TypeError: '类型错误',
                    NotSupportedError: '不支持的操作',
                    NetworkError: '网络错误发生',
                    TimeoutError: '操作超时',
                    UnknownError: '因未知的瞬态的原因使操作失败)',
                    ConstraintError: '条件没满足而导致事件失败的异常操作',
                };
                // 媒体权限失败处理（通用 简单）
                const messageMap = {
                    'permission denied': '麦克风、摄像头权限未开启，请检查后重试',
                    'requested device not found': '未检测到摄像头',
                    'could not start video source': '无法访问到摄像头',
                };

                let nameErrorMsg = nameMap[error.name];
                if (!nameErrorMsg) {
                    nameErrorMsg = messageMap[error.message.toLowerCase() || '未知错误'];
                }

                this.$error({
                    title: nameErrorMsg,
                    onOk: () => {
                        this.closeModal();
                    },
                });
            },
            getUserMedia(constrains, success) {
                if (navigator.mediaDevices === undefined) {
                    navigator.mediaDevices = {};
                }

                if (navigator.mediaDevices.getUserMedia === undefined) {
                    navigator.mediaDevices.getUserMedia = function (constraints) {
                    const getUserMedia =
                        navigator.webkitGetUserMedia || navigator.mozGetUserMedia;

                    if (!getUserMedia) {
                        return Promise.reject(
                        new Error('浏览器不支持访问用户媒体设备，请升级或更换浏览器')
                        );
                    }

                    return new Promise(function (resolve, reject) {
                        getUserMedia.call(navigator, constraints, resolve, reject);
                    });
                    };
                }

                navigator.mediaDevices
                    // 参数配置
                    .getUserMedia(constrains)
                    // 成功回调 暴露出去
                    .then(success)
                    // 失败回调 待通用处理
                    .catch(this.mediaErrorCaptured);
            },
            init() {
                let constraints = {audio: true};
                if (this.audioInputDeviceId) {
                    constraints = {audio: {deviceId: {exact: this.audioInputDeviceId}}};
                }

                this.getUserMedia(constraints, mediaStream => {
                    this.getDevices().then(res => {
                        if (!res?.flag) {
                            message.error('未检测到音频设备');

                            return;
                        }

                        this.audioInputDevices = res.audioInputDevices;
                        this.audioOutDevices = res.audioOutDevices;
                        this.audioInputDeviceId = this.audioInputDevices[0].deviceId;
                        this.audioOutDevicesId = this.audioOutDevices[0].deviceId;

                        this.handleInputStream(mediaStream);

                        this.setInputDevice();
                        this.setOutDevice(this.audioOutDevicesId);
                    }).catch(error => {
                        message.error(error?.err || '未检测到音频设备');
                    });
                });
            },

            // 音频设备变化
            onDevicechange(audioInputDeviceId, audioOutDevicesId) {
                let constraints = {audio: true};
                if (audioInputDeviceId) {
                    constraints = {audio: {deviceId: {exact: audioInputDeviceId}}};
                }

                this.getUserMedia(constraints, mediaStream => {
                    this.handleInputStream(mediaStream);

                    audioInputDeviceId && this.setInputDevice(audioInputDeviceId);
                    audioOutDevicesId && this.setOutDevice(audioOutDevicesId);
                });
            },
            // 设置扬声器
            setOutDevice(audioOutDeviceId) {
                this.$emit('onSelectChange', audioOutDeviceId);
            },
            // 设置麦克风，并且调用接口，通知ytalk进行回声检测
            setInputDevice(audioInputDeviceId) {
                const vm = this;

                this.$emit('setMediaConstraints', audioInputDeviceId);

                const callAccountNumber = this.callAccountId;
                if (!callAccountNumber) {
                    message.error('callAccountNumber不存在');
                    this.spinning = false;

                    return;
                }

                if (!this.type) {
                    message.error('type不存在');
                    this.spinning = false;

                    return;
                }

                const params = {
                    callAccountNumber
                };

                this.spinning = true;
                Api[this.type]({params, userId: callAccountNumber}, {hideLoading: true}).then(() => {
                    this.spinning = false;
                    this.$emit('setMediaConstraints', null);
                }).catch(err => {
                    this.spinning = false;
                    this.$emit('setMediaConstraints', null);

                    const code = err?.data?.status?.code || '';
                    const title = code ? `(code: ${code})` : '';

                    this.$confirm({
                        title: `系统异常 ${title}`,
                        style: {top: '100px'},
                        centered: false,
                        content: '请关闭通话检测窗口，重新检测',
                        okText: '重新检测',
                        cancelText: '关闭',
                        onOk() {
                            vm.close();

                            vm.spinning = true;
                            setTimeout(() => {
                                vm.onDevicechange(vm.audioInputDeviceId, vm.audioOutDevicesId);
                            }, SETTIMEOUT_TIME);
                        },
                    });
                });
            },

            // 获取设备列表
            getDevices() {
                return new Promise((resolve, reject) => {
                    navigator.mediaDevices.enumerateDevices().then(devices => {
                        let audioInputDevices = [];
                        let audioOutDevices = [];
                        let defaultInputDevice = null;
                        let defaultOutDevice = null;

                        devices.forEach(device => {
                            // 没有授予硬件权限时，deviceId为空字符串
                            if (device.deviceId == 'communications' || device.deviceId == '') {
                                return;
                            }

                            if (device.kind == 'audioinput') {
                                if (device.deviceId === 'default') {
                                    defaultInputDevice = device.label;
                                }

                                // 音频设备
                                audioInputDevices.push(device);
                            } else if (device.kind == 'audiooutput') {
                                if (device.deviceId === 'default') {
                                    defaultOutDevice = device.label;
                                }

                                audioOutDevices.push(device);
                            }
                        });

                        audioInputDevices = audioInputDevices.filter(device => !((device.deviceId !== 'default') && defaultInputDevice.includes(device.label)));
                        audioOutDevices = audioOutDevices.filter(device => !((device.deviceId !== 'default') && defaultOutDevice.includes(device.label)));

                        if (!audioInputDevices || audioInputDevices.length === 0) {
                            reject({flag: false, err: '未检测到麦克风'});

                            return;
                        }

                        if (!audioOutDevices || audioOutDevices.length === 0) {
                            reject({flag: false, err: '未检测到扬声器'});

                            return;
                        }

                        resolve({flag: true, audioInputDevices, audioOutDevices});
                    })
                    .catch(err => {
                        reject({flag: false, err});
                    });
                });
            },
            async onAudioInputChange(deviceId) {
                this.audioInputDeviceId = deviceId;
                this.close();

                this.spinning = true;
                setTimeout(() => {
                    this.onDevicechange(deviceId);
                }, SETTIMEOUT_TIME);
            },
            onAudioOutChange(deviceId) {
                this.setOutDevice(deviceId);
            },

            // 处理输入的流
            handleInputStream(stream) {
                this.audioInputStream = stream;
                const {audioCtx, levelChecker} = this.setAudioVolume(stream, 'volume');
                this.audioCtxInput = audioCtx;
                this.levelCheckerInput = levelChecker;
            },
            // 处理接收输出的音频流
            handleOutStream(stream) {
                this.audioOutStream = stream;
                const {audioCtx, levelChecker} = this.setAudioVolume(stream, 'volume2');
                this.audioCtxOut = audioCtx;
                this.levelCheckerOut = levelChecker;
            },

            // 方案一
            setAudioVolume(stream, id) {
                // // 创建一个音频上下文对象
                const audioContext = window.AudioContext || window.webkitAudioContext;
                const audioCtx = new audioContext();

                // 创建媒体流输入源节点，将音频流连接到该节点
                const liveSource = audioCtx.createMediaStreamSource(stream);

                // 创建音频分析对象，用于检测音频的音量级别
                // 采样的缓冲区大小为2048，输入和输出都是单声道
                const levelChecker = audioCtx.createScriptProcessor(4096, 1, 1);

                // 将该分析对象与麦克风音频进行连接
                liveSource.connect(levelChecker);

                // 将该分析对象连接到音频上下文的目标节点（通常是扬声器）
                levelChecker.connect(audioCtx.destination);

                levelChecker.onaudioprocess = e => {
                    // 获取输入缓冲区的数据
                    const buffer = e.inputBuffer.getChannelData(0);

                    // 计算音频的平方和，即音频信号的能量
                    let sum = 0.0;
                    for (let i = 0; i < buffer.length; i += 1) {
                        sum += buffer[i] * buffer[i];
                    }

                    // 计算音频的平均音量并将其转化为百分比形式
                    const volume = Math.round(Math.sqrt(sum / buffer.length) * 100);

                    // 打印音频大小
                    // this.volumeRate = volume;

                    const volumeBar = document.getElementById(id);
                    if (volumeBar) volumeBar.style.width = volume + '%';
                };

                return {audioCtx, levelChecker};
            },

            // 方案二
            setAudioVolume2(stream, id) {
                const audioContext = new (window.AudioContext || window.webkitAudioContext)();
                const analyser = audioContext.createAnalyser();
                var source = audioContext.createMediaStreamSource(stream);

                source.connect(analyser);
                analyser.fftSize = 256;
                var bufferLength = analyser.frequencyBinCount;
                var dataArray = new Uint8Array(bufferLength);

                function updateVolumeMeter() {
                    analyser.getByteFrequencyData(dataArray);

                    var average = dataArray.reduce((a, b) => a + b) / bufferLength;
                    var volume = Math.min(average, 255); // 限制最大值为 255

                    // 更新音量条宽度
                    var volumeLevel = document.getElementById(id);
                    if (volumeLevel) volumeLevel.style.width = (volume / 255) * 100 + '%';

                    // 继续检测
                    requestAnimationFrame(updateVolumeMeter);
                }

                updateVolumeMeter();
            },

            async closeModal() {
                this.$emit('closeModal');
            },

            handleCancel() {
                this.$emit('closeModal');
                this.close();
            },

            // 关闭音频流
            closeStream() {
                // 关闭ytalk
                this.$emit('hangup');
                this.$emit('setMediaConstraints', null);

                // 关闭麦克风
                if (this.audioInputStream && this.audioInputStream.getTracks()) {
                    this.audioInputStream.getTracks().forEach(track => {
                        track.stop();
                    });
                }

                this.levelCheckerInput?.disconnect();
                if (this.levelCheckerInput?.onaudioprocess) this.levelCheckerInput.onaudioprocess = null;
                this.audioCtxInput?.close().catch(() => {});
                const volumeBar = document.getElementById('volume');
                if (volumeBar) volumeBar.style.width = 0 + '%';

                // 关闭扬声器
                if (this.audioOutStream && this.audioOutStream.getTracks()) {
                    this.audioOutStream.getTracks().forEach(track => {
                        track.stop();
                    });
                }

                this.levelCheckerOut?.disconnect();
                if (this.levelCheckerOut?.onaudioprocess) this.levelCheckerOut.onaudioprocess = null;
                this.audioCtxOut?.close().catch(() => {});
                const volumeBar2 = document.getElementById('volume2');
                if (volumeBar2) volumeBar2.style.width = 0 + '%';
            },

            // 关闭音频流，并且挂断检测语音
            close() {
                this.closeStream();
                this.$emit('hangup');
            },
        },
    };
</script>
