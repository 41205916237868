var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "alarm-audit-abnormal" },
    [
      _vm._v("\n    " + _vm._s(_vm.notification.data.remark) + "\n\n    "),
      _c("yqg-router-link", { attrs: { text: "请查看", to: "audit" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }