/**
 * @Author: tsz
 * @Date: 2024-03-27 10:00:00
 * @Last Modified by: tsz
 * @Last Modified time: 2024-07-23 10:00:00
 */
import { axios as http } from '@yqg/resource';
const LIMIT = 5000; // onLoad 后最长时间
const MAX_LIMIT = 15000; // 最长时间
const IGNORE_TAG_SET = ['SCRIPT', 'STYLE', 'META', 'HEAD', 'LINK'];
const DELAY = 500; // 计算 FMP 重试间隔
const RETRYTIME = 4;
let navigationStartTime = 0;
const OVER_LIMIT_TIME = 15001; // 超过 15s, 不去采集, 用于标记
const DEAD_LINE_TYPE_1 = 1; // 首屏时间超过 delayTime
const DEAD_LINE_TYPE_2 = 2; // 首屏时间超过 delayTime - RETRYTIME * DELAY
const DEAD_LINE_TYPE_3 = 3; // 首屏时间超过 maxDeadline
const DEAD_LINE_TYPE_4 = 4; // 首屏时间超过 15s
try {
    navigationStartTime = window.performance.getEntriesByType('navigation')[0].startTime;
}
catch (error) {
    navigationStartTime = 0;
    // eslint-disable-next-line no-console
    console.warn('window.performance.getEntriesByType 不支持');
}
/**
 * @param observerData - 观察者数据数组
 * @returns {*}
 */
function removeSmallScore(observerData) {
    for (let i = 1; i < observerData.length; i++) {
        if (observerData[i].score < observerData[i - 1].score) {
            observerData.splice(i, 1);
            return removeSmallScore(observerData);
        }
    }
    return observerData;
}
function isIE() {
    const userAgent = window.navigator.userAgent;
    return userAgent.indexOf('MSIE') !== -1 || userAgent.indexOf('Trident/') !== -1;
}
class FirstScreenTime {
    constructor() {
        this.observer = null;
        this.startTime = navigationStartTime;
        this.observerData = [];
        this.firstScreenTime = 0;
        this.calcFirstScreenTime = 'init';
        this.loseTime = 0;
        this.loseTimeArr = [];
        this.reqCount = 0;
        this.callBack = null;
        this.requestInterceptor = null;
        this.responseInterceptor = null;
        this.timer = null;
        this.maxDeadline = MAX_LIMIT;
        this.initStartTime = null;
        this.unmountObserverTime = null;
        this.unmountObserverType = null;
        this.fmpTime = null;
        this.deadlineType = null;
        this.dataKey = null;
        this.isAsyncCalc = false;
    }
    init(callBack, options) {
        if (!performance.now) {
            this.calcFirstScreenTime = 'done';
            // eslint-disable-next-line no-console
            console.warn('performance.now 不支持，首屏时间无法被采集，首屏时间无法被采集');
            return;
        }
        this.initStartTime = performance.now();
        if (this.initStartTime > 15000) {
            this.outPutData(OVER_LIMIT_TIME, DEAD_LINE_TYPE_4);
            // eslint-disable-next-line no-console
            console.warn('首屏时间超过15s，首屏时间无法被采集(init)');
        }
        this.callBack = callBack;
        this.maxDeadline = (options === null || options === void 0 ? void 0 : options.maxDeadline) || MAX_LIMIT;
        this.isAsyncCalc = (options === null || options === void 0 ? void 0 : options.isAsyncCalc) || false;
        this.mountObserver();
        window.addEventListener('beforeunload', this.unmountObserverListener);
    }
    mountObserver() {
        if (!window.MutationObserver) {
            // eslint-disable-next-line no-console
            console.warn('MutationObserver 不支持，首屏时间无法被采集');
            return;
        }
        this.requestInterceptor = http.interceptors.request.use(config => {
            this.reqCount += 1;
            return config;
        }, err => {
            this.reqCount = Math.max(0, this.reqCount - 1);
            return Promise.reject(err);
        });
        // http response 拦截器
        this.responseInterceptor = http.interceptors.response.use(res => {
            this.reqCount = Math.max(0, this.reqCount - 1);
            return Promise.resolve(res);
        }, error => {
            this.reqCount = Math.max(0, this.reqCount - 1);
            return Promise.reject(error.response || error);
        });
        this.calcFirstScreenTime = 'pending';
        const observer = new window.MutationObserver(() => {
            const time = this.getTimestamp() - this.startTime;
            const body = document.querySelector('body');
            let score = 0;
            setTimeout(() => {
                const tempStartTime = this.getTimestamp();
                if (body) {
                    score = this.traverseEl(body, 1, false);
                    this.observerData.push({ score, time });
                }
                else {
                    this.observerData.push({ score: 0, time });
                }
                const tempEndTime = this.getTimestamp();
                this.loseTime += tempEndTime - tempStartTime;
                this.loseTimeArr.push(tempEndTime - tempStartTime);
            }, 0);
        });
        observer.observe(document, { childList: true, subtree: true });
        this.observer = observer;
        if (document.readyState === 'complete') {
            this.unmountObserverTime = performance.now();
            this.unmountObserverType = 'complete';
            this.unmountObserver(LIMIT);
        }
        else {
            window.addEventListener('load', () => {
                // eslint-disable-next-line no-console
                console.log('load', performance.now(), this.observerData);
                this.unmountObserverTime = performance.now();
                this.unmountObserverType = 'load';
                this.unmountObserver(LIMIT);
            }, false);
        }
    }
    getTimestamp() {
        return performance.now();
    }
    traverseEl(element, layer, identify) {
        const height = window.innerHeight || 0;
        let score = 0;
        const tagName = element.tagName;
        if (IGNORE_TAG_SET.indexOf(tagName) === -1) {
            const len = (element.children ? element.children.length : 0);
            if (len > 0) {
                for (let i = len - 1; i >= 0; i--) {
                    score += this.traverseEl(element.children[i], layer + 1, score > 0);
                }
            }
            if (score <= 0 && !identify) {
                if (element.getBoundingClientRect && element.getBoundingClientRect().top >= height) {
                    return 0;
                }
            }
            score += 1 + 0.5 * layer;
        }
        return score;
    }
    // 通过传入actionTime，将observerData中大于actionTime的数据过滤掉
    getfirstScreenTimeByActionTime(actionTime) {
        if (actionTime) {
            this.observerData = this.observerData.filter(item => item.time <= actionTime);
        }
        return this.scaleFirstScreenTime();
    }
    scaleFirstScreenTime() {
        let data = null;
        const { observerData } = this;
        for (let i = 1; i < observerData.length; i++) {
            if (observerData[i].time >= observerData[i - 1].time) {
                const scoreDiffer = observerData[i].score - observerData[i - 1].score;
                if (!data || data.rate <= scoreDiffer) {
                    data = { time: observerData[i].time, rate: scoreDiffer };
                }
            }
        }
        if (data && data.time > 0 && data.time < 3600000) {
            this.firstScreenTime = data.time;
            return this.firstScreenTime;
        }
        return null;
    }
    getfirstScreenTime() {
        this.observerData = removeSmallScore(this.observerData);
        return this.scaleFirstScreenTime();
    }
    compare(delayTime) {
        var _a, _b;
        let type = 0;
        const timeTemp = performance.now() - this.startTime;
        const time = (((_a = this.observerData[this.observerData.length - 1]) === null || _a === void 0 ? void 0 : _a.time) || 0);
        // eslint-disable-next-line no-console
        console.log(timeTemp, this.maxDeadline, '<---timeTemp,this.maxDeadline');
        if (timeTemp > this.maxDeadline) {
            type = DEAD_LINE_TYPE_3;
        }
        else if (((_b = this.observerData) === null || _b === void 0 ? void 0 : _b.length) <= 0) { // 没有收集到dom分数，就继续循环等待，直到超过最大时间
            type = 0;
        }
        else if (timeTemp > delayTime) {
            type = DEAD_LINE_TYPE_1;
        }
        else if (timeTemp - time > RETRYTIME * DELAY) {
            type = DEAD_LINE_TYPE_2;
        }
        return type;
    }
    unmountObserver(delayTime, immediately) {
        if (this.observer) {
            let type = this.compare(delayTime);
            type = immediately ? DEAD_LINE_TYPE_4 : type;
            if (immediately || (type && this.reqCount === 0) || type === DEAD_LINE_TYPE_3) {
                // eslint-disable-next-line no-console
                console.log(performance.now(), '<---scale-res');
                this.uninstallBinding();
                setTimeout(() => {
                    const fmp = this.getfirstScreenTime() || 0;
                    this.outPutData(fmp, type);
                }, 0);
            }
            else {
                this.timer && clearTimeout(this.timer);
                this.timer = null;
                // eslint-disable-next-line no-console
                console.log(performance.now(), '<---before-setTimeout');
                this.timer = setTimeout(() => {
                    // eslint-disable-next-line no-console
                    console.log(performance.now(), '<---setTimeout');
                    const curTime = performance.now();
                    const tempTime = this.maxDeadline + 500;
                    if (curTime > tempTime) {
                        this.uninstallBinding();
                        let fmp = this.getfirstScreenTime();
                        if (fmp && (fmp > tempTime)) {
                            fmp = OVER_LIMIT_TIME;
                        }
                        this.outPutData(fmp, DEAD_LINE_TYPE_4);
                        // eslint-disable-next-line no-console
                        console.warn('首屏时间超过15s，首屏时间无法被采集(unmountObserver)');
                        return 0;
                    }
                    this.unmountObserver(delayTime);
                }, DELAY);
            }
        }
        return 0;
    }
    uninstallBinding() {
        // 清除observer
        this.observer && this.observer.disconnect();
        try {
            // 取消请求拦截器
            http.interceptors.request.eject(this.requestInterceptor);
            // 取消响应拦截器
            http.interceptors.response.eject(this.responseInterceptor);
        }
        catch (error) {
            // eslint-disable-next-line no-console
            console.log(error);
        }
        // 清除定时器
        this.timer && clearTimeout(this.timer);
        this.observer = null;
    }
    outPutData(fmp, type) {
        // eslint-disable-next-line no-console
        console.log('首屏时间：', fmp, '终止类型：', type, '损耗时间：', this.loseTime);
        this.fmpTime = fmp;
        this.deadlineType = type;
        this.handleCallBack(fmp, type, this.loseTime);
        this.calcFirstScreenTime = 'done';
    }
    handleCallBack(fmp, type, loseTime) {
        const pathname = window.location.pathname;
        const search = window.location.search;
        const data = {
            pathname,
            search,
            firstScreenTime: fmp,
            deadLineType: type,
            loseTime,
            maxDeadline: this.maxDeadline,
            initStartTime: this.initStartTime,
            unmountObserverTime: this.unmountObserverTime,
            unmountObserverType: this.unmountObserverType
        };
        this.dataKey = data;
        this.callBack && this.callBack(data);
    }
    unmountObserverListener() {
        if (this.calcFirstScreenTime === 'pending') {
            this.unmountObserver(0, true);
        }
        if (!isIE()) {
            window.removeEventListener('beforeunload', this.unmountObserverListener);
        }
    }
}
// 导出实例
const fmp = new FirstScreenTime();
export default fmp;
