<!-- @Author: xymo -->
<!-- @Date: 2023-09-04 -->
<!-- @Last Modified by: xymo -->
<!-- @Last Modified time: 2023-09-04 14:13:51 -->

<template>
    <div class="alarm-wechat-message">
        【企微提醒】

        <span class="mr10">{{ notification.data.remark }}</span>

        <yqg-router-link
            class="mr10"
            text="请及时处理"
            to="case"
            :query="{caseId: encryCaseId}"
        />
    </div>
</template>

<script type="text/babel">
    export default {
        name: 'AlarmWechatMessage',

        props: {
            notification: {
                type: Object,
                default: () => ({})
            }
        },

        computed: {
            encryCaseId() {
                return window.btoa(this.notification.data.caseId);
            }
        }
    };
</script>
