import { render, staticRenderFns } from "./field-checkbox.vue?vue&type=template&id=aba41106&scoped=true&"
import script from "./field-checkbox.vue?vue&type=script&lang=js&"
export * from "./field-checkbox.vue?vue&type=script&lang=js&"
import style0 from "./field-checkbox.vue?vue&type=style&index=0&id=aba41106&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aba41106",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/jenkins/agent/workspace/fintopia-web7chz7/fintopia-web-ci/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('aba41106')) {
      api.createRecord('aba41106', component.options)
    } else {
      api.reload('aba41106', component.options)
    }
    module.hot.accept("./field-checkbox.vue?vue&type=template&id=aba41106&scoped=true&", function () {
      api.rerender('aba41106', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "packages/vue/antd/component/yqg-simple-form/component/field-checkbox.vue"
export default component.exports