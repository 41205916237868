<!-- @author giligili -->
<!-- @email zhimingwang@yangqianguan.com -->
<!-- @date 2021-05-25 15:47:03.502 -->
<!-- @desc generated by yqg-cli@4.0.7 -->

<template>
    <a-form-item
        :colon="false"
        :label-col="{span: 4}"
        :wrapper-col="{span: 20}"
    >
        <span
            slot="label"
            class="label"
        >
            {{ label }}
            &nbsp;
            <a-icon
                v-show="!showDetail"
                type="plus-circle"
                @click="showDetailChange"
            />
            <a-icon
                v-show="showDetail"
                type="minus-circle"
                @click="showDetailChange"
            />
        </span>

        <yqg-json-array-visual
            v-show="showDetail"
            :value="value"
            :json-visual-props="{
                fieldDefs,
                fieldFormat
            }"
        />

        <span v-show="!showDetail">...</span>
    </a-form-item>
</template>

<script type="text/babel">
    export default {
        name: 'JsonArrayLayout',

        props: {
            value: {
                type: Array,
                default: () => [{}]
            },

            label: {
                type: String,
                required: true
            },

            fieldDefs: {
                type: Array,
                default: () => []
            },

            fieldFormat: {
                type: Function,
                default: field => field
            }
        },

        data() {
            return {
                showDetail: true
            };
        },

        mounted() {
            this.$emit('change', this.value);
        },

        methods: {
            showDetailChange() {
                this.showDetail = !this.showDetail;
            }
        }
    };
</script>
