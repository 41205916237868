<!-- @author giligili -->
<!-- @email zhimingwang@yangqianguan.com -->
<!-- @date 2021-05-20 18:50:21.912 -->
<!-- @desc generated by yqg-cli@4.0.7 -->

<template>
    <div class="string-array-layout">
        <a-form-item
            :colon="false"
            :label-col="{span: 4}"
            :wrapper-col="{span: 20}"
        >
            <span
                slot="label"
                class="label"
            >
                {{ label }}
                &nbsp;
                <a-icon
                    v-show="!showDetail"
                    type="plus-circle"
                    @click="showDetailChange"
                />
                <a-icon
                    v-show="showDetail"
                    type="minus-circle"
                    @click="showDetailChange"
                />
            </span>

            <yqg-multi-input
                v-show="showDetail"
                :values="value"
                @change="onChange"
            />

            <span v-show="!showDetail">...</span>
        </a-form-item>
    </div>
</template>

<script type="text/babel">
    export default {
        name: 'StringArrayLayout',

        props: {
            value: {
                type: Array,
                default: () => ['']
            },

            label: {
                type: String,
                required: true
            }
        },

        data() {
            return {
                showDetail: true
            };
        },

        mounted() {
            this.$emit('change', this.value);
        },

        methods: {
            showDetailChange() {
                this.showDetail = !this.showDetail;
            },

            onChange(nextValue) {
                this.$emit('change', nextValue);
            }
        }
    };

</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .string-array-layout {
        .label {
            display: flex;
            align-items: center;
        }
    }
</style>
