var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-dialog",
    {
      attrs: {
        open: _vm.visible,
        "close-on-click-outside": false,
        title: "上传图片",
      },
      on: { close: _vm.cancel },
    },
    [
      _c(
        "div",
        { staticClass: "image-upload-modal-body b-form form-block" },
        [
          _c(
            "b-form-group",
            { attrs: { label: "" } },
            [
              _c("yqg-upload", {
                attrs: {
                  "auto-upload": "",
                  accept: "image/*",
                  bucket: _vm.bucket,
                  "root-dir": _vm.rootDir,
                  dir: _vm.dir,
                },
                model: {
                  value: _vm.url,
                  callback: function ($$v) {
                    _vm.url = $$v
                  },
                  expression: "url",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "simple lg",
          attrs: { slot: "actions" },
          on: {
            click: function ($event) {
              return _vm.cancel()
            },
          },
          slot: "actions",
        },
        [_vm._v("\n        取消\n    ")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "simple lg",
          attrs: { slot: "actions" },
          on: {
            click: function ($event) {
              return _vm.confirm()
            },
          },
          slot: "actions",
        },
        [_vm._v("\n        确定\n    ")]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }