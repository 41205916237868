
/*
 * Author: giligili
 * Created Date: 2021-05-13
 * email: zhimingwang@yangqianguan.com
 * desc: 账号审批的map
 */

import AccountCertifyType from 'collection-admin-web/common/constant/types/account-certify';

export default {
    [AccountCertifyType.INIT]: '审批中',
    [AccountCertifyType.CERTIFIED]: '审批通过',
    [AccountCertifyType.DENIED]: '审批拒绝'
};
