/**
 * @author giligili
 * @date 2021-11-15
 * @email zhimingwang@yangqianguan.com
 * @desc 电话修复渠道
 */

export default {
    CHINA_MOBILE: 'CHINA_MOBILE', // 移动
    CHINA_UNICOM: 'CHINA_UNICOM', // 联通
    CHINA_TELECOM: 'CHINA_TELECOM' // 电信
};
