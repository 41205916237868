var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "smsEl" },
    [
      _c(
        "a-popover",
        {
          staticClass: "header-sms",
          attrs: {
            trigger: "click",
            placement: "leftBottom",
            "get-popup-container": function () {
              return _vm.$refs.smsEl
            },
          },
          scopedSlots: _vm._u([
            {
              key: "content",
              fn: function () {
                return [
                  _c("yqg-simple-table", {
                    attrs: {
                      records: _vm.records,
                      options: _vm.$options.TableOptions,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "op",
                        fn: function (ref) {
                          var record = ref.record
                          return [
                            _c("a-button", {
                              attrs: { size: "small", type: "danger" },
                              domProps: { textContent: _vm._s("取消") },
                              on: {
                                click: function ($event) {
                                  return _vm.cancelSend(record)
                                },
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.visible,
            callback: function ($$v) {
              _vm.visible = $$v
            },
            expression: "visible",
          },
        },
        [
          _c("a-icon", {
            attrs: { type: "mail" },
            on: { click: _vm.fetchSmsList },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }