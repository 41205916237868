/**
 * @author giligili
 * @date 2022-03-07
 * @email zhimingwang@yangqianguan.com
 * @desc 版本检查客户端逻辑
 */

import {genPromise} from 'collection-admin-web/common/util/tool';

import {WORKER_TYPE, CLIENT_TYPE} from './constant/type';
import VersionSharedWorker from './server.shared-worker'; // eslint-disable-line

let messageId = 0;

export default function getVersionClient(...vals) {
    const sharedWorker = new VersionSharedWorker(...vals);

    sharedWorker.messageMap = {};

    sharedWorker.get = function get() {
        const data = {type: CLIENT_TYPE.GET_VERSION};

        return this.sendMessage(data);
    };

    sharedWorker.update = function update(payload) {
        const data = {type: CLIENT_TYPE.UPDATE_VERSION, payload};

        return this.sendMessage(data);
    };

    sharedWorker.sendMessage = function sendMessage(data) {
        messageId += 1;

        this.messageMap[messageId] = genPromise();

        this.port.postMessage({...data, messageId});

        return this.messageMap[messageId].promise;
    };

    sharedWorker.receiveMessage = function receiveMessage({data}) {
        const {type, messageId: mId, payload} = data;

        if (!this.messageMap[mId]) {
            return;
        }

        if (type !== WORKER_TYPE.ACK) {
            this.messageMap[mId].reject();
        }

        this.messageMap[messageId].resolve(payload);
    };

    sharedWorker.port.addEventListener('message', sharedWorker.receiveMessage.bind(sharedWorker));

    sharedWorker.port.start();

    return sharedWorker;
}
