var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-dialog",
    {
      staticClass: "select-scene-dialog",
      attrs: {
        open: _vm.visible,
        "dialog-class": "sm",
        "close-on-click-outside": false,
        title: "请选择本次呼出线路",
      },
      on: { close: _vm.cancel },
    },
    [
      _c("div", { staticClass: "select-scene-modal-body" }, [
        _vm.latestScene && _vm.sceneMap[_vm.latestScene]
          ? _c("p", { staticClass: "error" }, [
              _vm._v(
                "\n            该用户最近一次拨通线路：" +
                  _vm._s(_vm.sceneMap[_vm.latestScene]) +
                  "\n        "
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.showInvalidTip
          ? _c(
              "div",
              { staticClass: "reason" },
              _vm._l(_vm.invalidList, function (item, index) {
                return _c("p", { key: index }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(item.reason) +
                      "\n            "
                  ),
                ])
              }),
              0
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "select-content" },
          _vm._l(_vm.latestSceneList, function (sceneItem) {
            return _c(
              "div",
              {
                key: sceneItem.sceneCode,
                staticClass: "scene-item",
                class: {
                  active: sceneItem.sceneCode === _vm.scene,
                  disabled: !sceneItem.available,
                },
                on: {
                  click: function ($event) {
                    return _vm.selectScene(sceneItem)
                  },
                },
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.sceneMap[sceneItem.sceneCode]) +
                    "\n            "
                ),
              ]
            )
          }),
          0
        ),
        _vm._v(" "),
        _vm.sceneTips && _vm.sceneTips.length
          ? _c(
              "ul",
              { staticClass: "tip-list" },
              _vm._l(_vm.sceneTips, function (tip, index) {
                return _c("li", { key: index }, [
                  _vm._v("\n                " + _vm._s(tip) + "\n            "),
                ])
              }),
              0
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "call-btn-group" }, [
        _c(
          "button",
          {
            staticClass: "primary lg",
            attrs: { slot: "actions" },
            on: {
              click: function ($event) {
                return _vm.confirm()
              },
            },
            slot: "actions",
          },
          [_vm._v("\n            立即拨打\n        ")]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }