/**
 * @author giligili
 * @date 2021-07-27
 * @email zhimingwang@yangqianguan.com
 * @desc table-options
 */

import * as Fields from './fields';

export default {
    colDefs: [
        Fields.timeCreated,
        Fields.collectorName,
        Fields.type,
        Fields.reduceAmount,
        Fields.status
    ]
};
