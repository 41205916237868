/**
 * @author giligili
 * @date 2021-07-06
 * @email zhimingwang@yangqianguan.com
 * @desc 身份证掩码
 */

export default idNumber => idNumber && idNumber.replace(
    /(^\S*)(\S{1})(\S{1}$)/,
    (_, $1, $2, $3) => ($1.replace(/./g, '*') + $2 + $3.replace(/./g, '*'))
);
