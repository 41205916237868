/**
 * @Author: xymo
 * @Date: 2024-02-21
 * @Last Modified by: xymo
 * @Last Modified time: 2024-02-21 17:12:04
 */

import EnterpriseWeChatSymbol from './we-chat-symbol';

export default {
    symbol: {
        field: 'symbol',
        label: '选择微信主体',
        type: 'select',
        enumType: EnterpriseWeChatSymbol,
        required: true
    }
};
