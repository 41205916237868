<!-- @author giligili -->
<!-- @email zhimingwang@yangqianguan.com -->
<!-- @date 2021-07-21 12:51:06.029 -->
<!-- @desc generated by yqg-cli@5.1.1 -->

<template>
    <div class="alarm-assist-case">
        你于 {{ notification.data.timeRemind | dateTime }} 收到 {{ notification.data.extraData.operatorName }} 发起的协催任务

        <p>
            <yqg-router-link
                :hash="hash"
                to="assistReceive"
            />
        </p>

        <div class="btn-wrap">
            <a-button
                class="yqg-btn-warning"
                @click="abortAssit"
                v-text="'拒绝'"
            />

            <a-button
                type="primary"
                @click="acceptAssit"
                v-text="'同意'"
            />
        </div>
    </div>
</template>

<script type="text/babel">

    import {mapActions} from 'vuex';

    import EnumAll from 'collection-admin-web/common/constant/enum';
    import bus, {OnRefreshMyReceiveAssistList} from 'collection-admin-web/common/constant/event-bus';
    import CaseAssist from 'collection-admin-web/common/resource/case/assist';

    const AssistStatusType = EnumAll.Case.AssistStatus.TYPE;

    export default {
        name: 'AlarmAssistCase',

        props: {
            notification: {
                type: Object,
                default: () => ({})
            }
        },

        computed: {
            hash() {
                const {typePrimaryId: id} = this.notification.data;

                return window.encodeURI(JSON.stringify({id}));
            }
        },

        methods: {
            ...mapActions('notification', ['fetchNotificationList']),

            acceptAssit() {
                const {typePrimaryId} = this.notification.data;

                CaseAssist.updateStatus({
                    caseAssistFlowId: typePrimaryId,
                    status: AssistStatusType.AGREE
                })
                    .then(({data: {body}}) => {
                        if (body) {
                            this.$message.success('已同意该协催任务!');

                            this.fetchNotificationList();

                            bus.$emit(OnRefreshMyReceiveAssistList);
                        } else {
                            this.$message.error('操作失败!');
                        }
                    })
                    .catch(err => err);
            },

            abortAssit() {
                const {typePrimaryId} = this.notification.data;

                CaseAssist.updateStatus({
                    caseAssistFlowId: typePrimaryId,
                    status: AssistStatusType.REFUSE
                })
                    .then(({data: {body}}) => {
                        if (body) {
                            this.$message.success('已拒绝该协催任务!');

                            this.fetchNotificationList();

                            bus.$emit(OnRefreshMyReceiveAssistList);
                        } else {
                            this.$message.error('操作失败!');
                        }
                    })
                    .catch(err => err);
            }
        }
    };
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .btn-wrap {
        text-align: right;
    }
</style>
