/**
 * @author giligili
 * @date 2021-06-30
 * @email zhimingwang@yangqianguan.com
 * @desc 质检枚举
 */

import CallQualityComplaintRelative from 'collection-admin-web/common/resource/call-quality-complaint/relative';
import CallQualityConfigResource from 'collection-admin-web/common/resource/call-quality/config';
import CallQualityRelative from 'collection-admin-web/common/resource/call-quality/relative';
import CommonResource from 'collection-admin-web/common/resource/common';
import Enum from 'collection-admin-web/common/util/enum';

const {listFailReason} = CommonResource;

const genFailReason = (failReasons = [], format = desc => desc) => {
    const failReasonList = Array.isArray(failReasons) ? failReasons : [failReasons];

    return failReasonList
        .map(({lvName, reasons}) => reasons.map(({key, desc, score}) => ({id: key, name: format(desc, lvName, score)})))
        .flat();
};

const handleFailReason = (failReasons, failReasonFormat = desc => desc) => ({
    failReason: genFailReason(failReasons, failReasonFormat)
        .reduce((accu, {id, name}) => ({...accu, [id]: name}), {}),
    failReasonWithName: genFailReason(failReasons, (desc, lvName) => `${desc} (${lvName})`)
        .reduce((accu, {id, name}) => ({...accu, [id]: name}), {}),

    failReasonLv: failReasons,
    failReasonLevelReasonsList: failReasons.map(({failLv, lvName, reasons = []}) => ({
        failLv,
        lvName,
        reasonsMap: reasons.reduce((accu, {key, desc, score}) => ({...accu, [key]: failReasonFormat(desc, lvName, score)}), {})
    }))
});

const genOptions = dataBodyField => ({
    queryOnce: true,
    dataBodyField
});

/* @desc 通话违规 start */
export const FailReason = Enum.query(listFailReason, genOptions('failReason'));
export const FailReasonLv = Enum.query(listFailReason, {
    ...genOptions('failReasonLv'),
    textField: 'lvName',
    valueField: 'failLv'
});
export const FailReasonWithName = Enum.query(listFailReason, genOptions('failReasonWithName'));
/* @desc 通话违规 end */

/* @desc 警告/提示原因 */
const WarnReasonResource = () => CallQualityRelative.listWarnReason()
    .then(({data: {body: {failReasons = []}}}) => {
        const body = handleFailReason(failReasons);

        return {data: {body}};
    });

export const WarnReason = Enum.query(
    WarnReasonResource,
    genOptions('failReason')
);

/* @desc 警告和违规原因 */
export const FailAndWarnReason = Enum.query(
    () => Promise.all([FailReason.query(), WarnReason.query()])
        .then(() => ({data: {body: {...FailReason.MAP, ...WarnReason.MAP}}})),
    {queryOnce: true}
);

/* @desc 投诉加分原因 */
const BonusReasonResource = () => CallQualityComplaintRelative.listBonusReason()
    .then(({data: {body: {failReasons = []}}}) => {
        const body = handleFailReason(failReasons, (desc, lvName, score) => `${desc}(${score})`);

        return {data: {body}};
    });

export const BonusReason = Enum.query(
    BonusReasonResource,
    genOptions('failReason')
);

/* @desc 投诉减分原因 */
const DeductReasonResource = () => CallQualityComplaintRelative.listDeductReason()
    .then(({data: {body: {failReasons = []}}}) => {
        const body = handleFailReason(failReasons, (desc, lvName, score) => `${desc}(${score})`);

        return {data: {body}};
    });

export const DeductReason = Enum.query(
    DeductReasonResource,
    genOptions('failReason')
);

/* @desc 加分和减分原因 */
export const BonusAndDeductReason = Enum.query(
    () => Promise.all([BonusReason.query(), DeductReason.query()])
        .then(() => ({data: {body: {...BonusReason.MAP, ...DeductReason.MAP}}})),
    {queryOnce: true}
);

/* @desc 机器质检结果 */
export {default as MachineResult} from './call-quality/machine-result-map';

/* @desc 手工质检/投诉手工质检 */
export {default as ManualResultLevel, ManualResultComplaintLevel} from './call-quality/manual-result-level-map';

/* @desc 误检原因 */
export const MismatchReason = Enum.query(CallQualityConfigResource.getMismatchReason, genOptions('mismatchReasons'));
