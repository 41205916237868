var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.src
    ? _c(
        "div",
        { staticClass: "yqg-pipe-audio" },
        [
          _c(
            "audio",
            {
              ref: "audio",
              attrs: { controls: "", controlsList: "nodownload", src: _vm.src },
              on: {
                play: function ($event) {
                  return _vm.$emit("played")
                },
                contextmenu: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [_vm._v("\n        暂不支持音频播放\n    ")]
          ),
          _vm._v(" "),
          _c(
            "a-tooltip",
            {
              scopedSlots: _vm._u(
                [
                  {
                    key: "title",
                    fn: function () {
                      return [_c("span", [_vm._v(_vm._s(_vm.rate) + "倍速")])]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                3456682032
              ),
            },
            [
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.rate,
                    expression: "rate",
                  },
                ],
                staticClass: "rate",
                attrs: { max: "2", min: "0.5", step: "0.5", type: "range" },
                domProps: { value: _vm.rate },
                on: {
                  change: _vm.setRate,
                  __r: function ($event) {
                    _vm.rate = $event.target.value
                  },
                },
              }),
            ]
          ),
          _vm._v(" "),
          _vm.download
            ? _c(
                "a",
                {
                  staticClass: "download-btn",
                  attrs: { target: "_blank", download: "" },
                  on: { click: _vm.downloadAudio },
                },
                [_c("a-icon", { attrs: { type: "cloud-download" } })],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }