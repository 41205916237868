/*
 * @Author: huayizhang 
 * @Date: 2021-11-05 13:34:18 
 * @Last Modified by:   huayizhang 
 * @Last Modified time: 2021-11-05 13:34:18 
 */

export default {
    CASE: 'CASE',
    REMIND_CASE: 'REMIND_CASE'
};
