/*
 * Author: xymo
 * Created Date: 2020-08-07
 * email: xymo@yangqianguan.com
 */

export default {
    MANUAL: 'MANUAL',
    AUTO: 'AUTO'
};
