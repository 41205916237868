<!-- @author panezhang -->
<!-- @email panezhang@yangqianguan.com -->
<!-- @date 2018-07-27 15:07:17.314 -->
<!-- @desc generated by yqg-cli@0.3.0 -->

<template>
    <div class="file-link card">
        <a
            :href="url"
            target="_blank"
        >
            <img
                v-if="isImage"
                :src="url"
                :alt="url"
            >
            <span v-else>
                {{ url }}
            </span>
        </a>

        <button
            class="btn-delete sm danger"
            @click="onDelete()"
        >
            <i class="b-icon-trash" />
        </button>

        <button
            v-if="sortable"
            class="normal sm sortable-handle"
        >
            <i class="b-icon-adjust" />
        </button>
    </div>
</template>

<script>

export default {
    name: 'FileLink',

    props: {
        url: {
            type: String,
            required: true
        },

        sortable: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        isImage() {
            return /\.(jpg|jpeg|png|gif|svg)$/i.test(this.url);
        }
    },

    methods: {
        onDelete() {
            this.$emit('delete');
        }
    }
};

</script>

<style lang="scss" scoped>
    .file-link {
        position: relative;
        width: 300px;
        height: 200px;
        word-break: break-all;
        justify-content: space-between;

        img {
            max-width: 100%;
            max-height: 100%;
        }

        .btn-delete {
            position: absolute;
            bottom: 10px;
            right: 10px;
        }

        .sortable-handle {
            position: absolute;
            bottom: 10px;
            right: 50px;
            cursor: move;
        }
    }

</style>
