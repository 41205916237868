<!-- @author giligili -->
<!-- @email zhimingwang@yangqianguan.com -->
<!-- @date 2021-06-30 14:59:17.910 -->
<!-- @desc generated by yqg-cli@5.0.9 -->

<!-- @todo 一些属性可以配置(比如宽度, 高度什么的)，目前都是写死的 -->

<template>
    <div class="yqg-color-picker">
        <!-- 调色板 🎨 -->
        <div
            ref="palette"
            class="palette palette-transparent"
            :style="paletteStyle"
            @mouseup="mouseUp"
            @mousedown="mouseDown"
            @mousemove="mouseMove"
            @mouseleave="mouseLeave"
            @click="paletteClick($event)"
        >
            <span
                class="palette-selector"
                :style="paletteSelectorStyle"
            />
        </div>

        <!-- 颜色选择 -->
        <div class="color-select">
            <a
                class="color-preview"
                :style="previewStyle"
            />
            <div class="color-range">
                <input
                    class="hue"
                    min="0"
                    max="360"
                    type="range"
                    :value="color.hsv.h"
                    @input="hueChange($event)"
                >
                <input
                    class="opacity opacity-transparent"
                    :style="opacityStyle"
                    min="0"
                    max="1"
                    step="0.01"
                    type="range"
                    :value="color.a"
                    @input="opacityChange($event)"
                >
            </div>
        </div>

        <!-- hex 输入 -->
        <div class="color-input">
            <a-input
                :value="color.hexa"
                @input="hexaChange"
            />
            <a-tag
                class="color-model"
                color="cyan"
            >
                HEXA
            </a-tag>
        </div>

        <div
            v-if="preset && preset.length"
            class="color-preset"
        >
            <a-button
                v-for="val in preset"
                :key="val"
                class="preset-item"
                :style="{background: val}"
                :value="val"
                @click="hexaChange($event)"
            />
        </div>
    </div>
</template>

<script type="text/babel">
    /* eslint id-length: ["error", { "min": 1 }]*/

    import Color from './helper/color';

    // https://www.zhihu.com/question/22077462
    export default {
        name: 'YqgColorPicker',

        props: {
            value: {
                type: String,
                default: '#e43424'
            },

            preset: {
                type: Array,
                default: () => []
            }
        },

        data() {
            const color = new Color({hexa: this.value});

            return {
                color,
                paletteSelectorStyle: {top: 0, left: 0}
            };
        },

        computed: {
            h() {
                return this.color.hsv.h;
            },

            a() {
                return this.color.a;
            },

            paletteStyle() {
                const {color: {a, hsv: {h}}} = this;

                // 这里用 hsla 模拟 hsv 调色板
                return {
                    backgroundImage: `
                        linear-gradient(to top, hsla(0, 0%, 0%, ${a}), transparent),
                        linear-gradient(to left, hsla(${h}, 100%, 50%, ${a}), hsla(0, 0%, 100%, ${a})),
                        linear-gradient(45deg, #ddd 25%, transparent 0, transparent 75%, #ddd 0),
                        linear-gradient(45deg, #ddd 25%, transparent 0, transparent 75%, #ddd 0)
                    `
                };
            },

            previewStyle() {
                const {color: {hexa}} = this;

                return {
                    backgroundColor: hexa
                };
            },

            opacityStyle() {
                const {color: {hsv: {h}}} = this;

                return {
                    backgroundImage: `
                        linear-gradient(to right, hsla(${h},100%,50%,0), hsla(${h},100%,50%,1)),
                        linear-gradient(45deg, #ddd 25%, transparent 0, transparent 75%, #ddd 0),
                        linear-gradient(45deg, #ddd 25%, transparent 0, transparent 75%, #ddd 0)
                    `
                };
            }
        },

        mounted() {
            this.changePlace();
        },

        methods: {
            changePlace() {
                const {width, height} = this.$refs.palette.getBoundingClientRect();
                const {color: {hsv: {s, v}}} = this;

                this.paletteSelectorStyle = {
                    left: `${s * width / 100}px`, // eslint-disable-line
                    top: `${(100 - v) * height / 100}px` // eslint-disable-line
                };
            },

            hueChange(event) {
                const h = event.target.value;

                const {color: {hsv: {s, v}}} = this;

                this.color.parseHsv(h, s, v);

                this.$emit('change', this.color.hexa);
            },

            hexaChange(event) {
                const hexa = event.target.value;

                if (!/^#([0-9|a-f|A-F]{6}|[0-9|a-f|A-F]{8})$/.test(hexa)) return;

                this.color.parseHexa(hexa);

                this.changePlace();

                this.$emit('change', this.color.hexa);
            },

            opacityChange(event) {
                const a = event.target.value;

                this.color.parseOpacity(a);

                this.$emit('change', this.color.hexa);
            },

            paletteClick(event) {
                const {color: {hsv: {h}}} = this;
                const {clientX, clientY} = event;

                const [s, v] = this.getSV(clientX, clientY);

                this.color.parseHsv(h, s, v);

                this.changePlace();

                this.$emit('change', this.color.hexa);
            },

            getSV(clientX, clientY) {
                const {x, y, width, height} = this.$refs.palette.getBoundingClientRect();

                const s = (clientX - x) * 100 / width; // eslint-disable-line
                const v = 100 - ((clientY - y) * 100 / height); // eslint-disable-line

                return [s, v];
            },

            mouseMove(event) {
                if (!this.start) return;

                const {color: {hsv: {h}}} = this;
                const {clientX, clientY} = event;

                const [s, v] = this.getSV(clientX, clientY);

                this.color.parseHsv(h, s, v);

                this.changePlace();

                this.$emit('change', this.color.hexa);
            },

            mouseDown() {
                this.start = true;
            },

            mouseUp() {
                this.start = false;
            },

            mouseLeave() {
                this.start = false;
            }
        }
    };
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .yqg-color-picker {
        width: 300px;

        input[type="range"] {
            display: block;
            width: 100%;
            height: 10px;
            margin: 0;
            border-radius: 5px;
            outline: 0;
            pointer-events: all;
            appearance: none;
        }

        input[type="range"]::-webkit-slider-runnable-track {
            position: relative;
            display: flex;
            align-items: center;
        }

        input[type="range"]::-webkit-slider-thumb {
            appearance: none;
            position: relative;
            width: 10px;
            height: 10px;
            background: #fff;
            box-shadow: 0 0 10px rgba(0, 0, 0, 10%);
            border-radius: 50%;
            transform: scale(1.2);
            transition: 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46);
            cursor: grab;
        }

        input[type="range"]::-webkit-slider-thumb:active,
        input[type="range"]:focus::-webkit-slider-thumb {
            transform: scale(1.5);
        }

        .opacity-transparent {
            background-position: 0 0, 0 0, 5px 5px !important;
            background-size: 100% 100%, 10px 10px, 10px 10px !important;
        }

        .palette-transparent {
            background-position: 0 0, 0 0, 0 0, 5px 5px !important;
            background-size: 100% 100%, 100% 100%, 10px 10px, 10px 10px !important;
        }

        .palette {
            position: relative;
            height: 200px;
            user-select: none;
            cursor: crosshair;
            opacity: 100%;
            transition: opacity 0.1s;

            .palette-selector {
                position: absolute;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                border: 2px solid #fff;
                transform: translate(-50%, -50%);
            }
        }

        .color-select {
            display: flex;
            height: 40px;
            margin: 10px 0;

            .color-preview {
                width: 40px;
                height: 40px;
                margin-right: 10px;
                border-radius: 50%;
                overflow: hidden;
            }

            .color-range {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: space-around;

                .hue {
                    background:
                        linear-gradient(
                            to right,
                            hsl(0deg, 100%, 50%),
                            hsl(30deg, 100%, 50%),
                            hsl(60deg, 100%, 50%),
                            hsl(90deg, 100%, 50%),
                            hsl(120deg, 100%, 50%),
                            hsl(180deg, 100%, 50%),
                            hsl(210deg, 100%, 50%),
                            hsl(240deg, 100%, 50%),
                            hsl(270deg, 100%, 50%),
                            hsl(300deg, 100%, 50%),
                            hsl(330deg, 100%, 50%),
                            hsl(360deg, 100%, 50%)
                        );
                }
            }
        }

        .color-input {
            display: flex;

            .color-model {
                margin-left: 10px;
            }

            ::v-deep .ant-tag {
                display: flex;
                align-items: center;
            }
        }

        .color-preset {
            margin-top: 10px;

            .preset-item {
                width: 20px;
                height: 20px;
                margin-bottom: 10px;
                margin-right: 10px;
                padding: 0;
                border-radius: 5px;
                cursor: pointer;
            }
        }
    }
</style>
