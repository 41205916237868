var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "yqg-editor-container",
      class: { "full-screen": _vm.fullScreen },
    },
    [
      _c("div", { staticClass: "header-actions" }, [
        _c(
          "div",
          { staticClass: "left" },
          [
            _vm._t("header-left", function () {
              return [
                _c(
                  "button",
                  {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip",
                        value: "插入图片",
                        expression: "'插入图片'",
                      },
                    ],
                    staticClass: "simple",
                    on: {
                      click: function ($event) {
                        return _vm.openImageUploadModal()
                      },
                    },
                  },
                  [_c("i", { staticClass: "b-icon-image" })]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip",
                        value: "插入文件",
                        expression: "'插入文件'",
                      },
                    ],
                    staticClass: "simple",
                    on: {
                      click: function ($event) {
                        return _vm.openFileUploadModal()
                      },
                    },
                  },
                  [_c("i", { staticClass: "b-icon-copy" })]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip",
                        value: "格式化",
                        expression: "'格式化'",
                      },
                    ],
                    staticClass: "simple",
                    on: {
                      click: function ($event) {
                        return _vm.reformatCode()
                      },
                    },
                  },
                  [_c("i", { staticClass: "b-icon-adjust" })]
                ),
                _vm._v(" "),
                _vm._t("action-left"),
              ]
            }),
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "right" },
          [
            _vm._t("action-right"),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "simple",
                on: {
                  click: function ($event) {
                    return _vm.toggleFullScreen()
                  },
                },
              },
              [
                _c("i", {
                  class: {
                    "b-icon-expand": !_vm.fullScreen,
                    "b-icon-compress": _vm.fullScreen,
                  },
                }),
              ]
            ),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "editor" },
        [
          _vm._t("editor", function () {
            return [
              _c("yqg-code-textarea", {
                attrs: { value: _vm.value, options: _vm.options },
                on: { input: _vm.onChange, ready: _vm.onCodeMirrorRef },
              }),
            ]
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }