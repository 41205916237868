<!-- @author giligili -->
<!-- @email zhimingwang@yangqianguan.com -->
<!-- @date 2021-07-21 12:51:06.029 -->
<!-- @desc generated by yqg-cli@5.1.1 -->

<template>
    <div class="alarm-assist-case-agree">
        你发出的协催任务

        <p>
            <yqg-router-link
                to="assistInitiate"
                :hash="hash"
            />
        </p>

        已被 {{ notification.data.extraData.operatorName }} 同意 <a @click="finishNotification(notification.data)">我知道了</a>
    </div>
</template>

<script type="text/babel">

    import {mapActions} from 'vuex';

    export default {
        name: 'AlarmAssistCaseAgree',

        props: {
            notification: {
                type: Object,
                default: () => ({})
            }
        },

        computed: {
            hash() {
                const {typePrimaryId: id} = this.notification.data;

                return window.encodeURI(JSON.stringify({id}));
            }
        },

        methods: {
            ...mapActions('notification', ['finishNotification'])
        }
    };
</script>
