var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "yqg-modal-wrapper" },
    _vm._l(_vm.modalMap, function (ref, key) {
      var visible = ref.visible
      var isForegroundWatermark = ref.isForegroundWatermark
      var modalComponent = ref.modalComponent
      var componentProps = ref.componentProps
      var dialogProps = ref.dialogProps
      var close = ref.close
      var dismiss = ref.dismiss
      var afterClose = ref.afterClose
      var watermarkStyle = ref.watermarkStyle
      var getContainer = ref.getContainer
      return _c(
        "a-modal",
        _vm._b(
          {
            key: key,
            on: { cancel: dismiss },
            scopedSlots: _vm._u(
              [
                modalComponent.onlyClose
                  ? {
                      key: "footer",
                      fn: function () {
                        return [
                          _c("a-button", {
                            domProps: {
                              textContent: _vm._s(_vm.$t("common.close")),
                            },
                            on: { click: close },
                          }),
                        ]
                      },
                      proxy: true,
                    }
                  : null,
              ],
              null,
              true
            ),
          },
          "a-modal",
          Object.assign(
            {},
            {
              visible: visible,
              afterClose: afterClose,
              destroyOnClose: true,
              maskClosable: false,
              footer: modalComponent.onlyClose ? undefined : null,
              width: 768,
              style: { overflowX: "hidden", overflowY: "auto" },
              bodyStyle: !isForegroundWatermark ? watermarkStyle : {},
              getContainer: getContainer
                ? getContainer
                : function () {
                    return _vm.$el
                  },
            },
            modalComponent.dialogProps,
            dialogProps
          ),
          false
        ),
        [
          _c(
            modalComponent,
            _vm._g(
              _vm._b(
                { tag: "component" },
                "component",
                _vm.getModalComponentProps({
                  modalComponent: modalComponent,
                  componentProps: componentProps,
                  close: close,
                  dismiss: dismiss,
                }),
                false
              ),
              Object.assign(
                {},
                { close: close, dismiss: dismiss },
                componentProps && componentProps.vOn
              )
            )
          ),
          _vm._v(" "),
          isForegroundWatermark
            ? _c("div", {
                staticClass: "watermark-foreground",
                style: watermarkStyle,
              })
            : _vm._e(),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }