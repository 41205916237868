var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "yqg-editor-container",
    _vm._b(
      {
        attrs: { value: _vm.value, options: _vm.CodeMirrorOptions },
        on: {
          input: _vm.onChange,
          ready: _vm.onCodeMirrorRef,
          "action-format": _vm.reformatJSON,
        },
      },
      "yqg-editor-container",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }