/**
 * @Author: xymo
 * @Date: 2023-07-19
 * @Last Modified by: xymo
 * @Last Modified time: 2023-07-19 14:27:46
 */
import { assign, customGet, customPost } from '@yqg/resource';
const api = {
    urlPrefix: '/admin/operation/col/knowledge',
    fetchQuestionCategoryTree: customGet('fetchQuestionCategoryTree'),
    listByCondition: customPost('listByCondition'),
    cancelCollect: customPost('cancelCollect'),
    collect: customPost('collect'),
    fetchByQuestionCategoryId: customPost('fetchByQuestionCategoryId')
};
export default assign(api);
