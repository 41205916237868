var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import axios from 'axios';
import buildURL from 'axios/lib/helpers/buildURL';
export var ObjectKey;
(function (ObjectKey) {
    ObjectKey["Dot"] = ".k";
    ObjectKey["Bracket"] = "[k]";
    ObjectKey["Whole"] = "json";
})(ObjectKey || (ObjectKey = {}));
export var ArrayKey;
(function (ArrayKey) {
    ArrayKey["None"] = "";
    ArrayKey["Dot"] = ".i";
    ArrayKey["Bracket"] = "[i]";
    ArrayKey["EmptyBracket"] = "[]";
    ArrayKey["Whole"] = "json";
})(ArrayKey || (ArrayKey = {}));
export function setUrlParams(path) {
    var args = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        args[_i - 1] = arguments[_i];
    }
    if (!/:/.test(path))
        return path;
    var isFormData = typeof FormData !== 'undefined' && args[0] instanceof FormData;
    var _a = (args[1] || args[0] || {}).params, params = _a === void 0 ? '' : _a;
    if (!params)
        params = args[0] || {};
    var keys = [];
    var replacedPath = path.replace(/\/:([^/]+)/g, function (match, key) {
        var _a;
        var replacement = (_a = (isFormData ? params.get(key) : params[key])) !== null && _a !== void 0 ? _a : '';
        keys.push(key);
        return "/".concat(replacement);
    });
    if (!this || !this.keepPathVar || Object.keys(params).length === keys.length) {
        keys.forEach(function (key) {
            if (isFormData) {
                params.delete(key);
                return;
            }
            delete params[key];
        });
    }
    return replacedPath;
}
function appendField(formData, name, value) {
    var args = [name, value];
    if (value instanceof File && value.name) {
        args.push(value.name);
    }
    formData.append.apply(formData, args);
}
export var objToFormData = function (obj, config) {
    if (config === void 0) { config = {}; }
    if (!obj)
        return obj;
    var _a = config.objectKey, objectKey = _a === void 0 ? ObjectKey.Dot : _a, _b = config.arrayKey, arrayKey = _b === void 0 ? ArrayKey.None : _b;
    var formData = new FormData();
    Object.keys(obj).forEach(function (key) {
        var curData = obj[key];
        if (curData === undefined || curData === null)
            return;
        if (curData && curData.constructor === Array) {
            switch (arrayKey) {
                case ArrayKey.None:
                case ArrayKey.Dot:
                case ArrayKey.Bracket:
                case ArrayKey.EmptyBracket:
                    curData.forEach(function (item, idx) {
                        appendField(formData, "".concat(key).concat(arrayKey.replace('i', idx)), item);
                    });
                    return;
                case ArrayKey.Whole:
                    curData = JSON.stringify(curData);
                    break;
            }
        }
        if (curData && curData.constructor === Object) {
            switch (objectKey) {
                case ObjectKey.Dot:
                case ObjectKey.Bracket:
                    Object.keys(curData).forEach(function (subKey) {
                        appendField(formData, "".concat(key).concat(objectKey.replace('k', subKey)), curData[subKey]);
                    });
                    return;
                case ObjectKey.Whole:
                    curData = JSON.stringify(curData);
                    break;
            }
        }
        appendField(formData, key, curData);
    });
    return formData;
};
export function getCustomRequest(_a) {
    var url = _a.url, method = _a.method, link = _a.link, download = _a.download, targetSelf = _a.targetSelf, formData = _a.formData;
    if (!(link || download || targetSelf) && !axios[method]) {
        throw new Error("Method [".concat(method, "] is not valid http verb!"));
    }
    return function (dataOrConfig, config) {
        var _a = this.parse(url, dataOrConfig, config), parsedUrl = _a[0], cd = _a[1], parsedConfig = _a[2];
        var configOrData = cd;
        if (link || download || targetSelf) {
            var _b = (configOrData || {}).params, params = _b === void 0 ? configOrData : _b;
            var requestUrl = axios.getUri({ params: params, url: parsedUrl });
            if (download)
                window.open(requestUrl, '_blank');
            if (targetSelf)
                window.location.href = requestUrl;
            return requestUrl;
        }
        if (formData) {
            configOrData = objToFormData(configOrData, formData);
        }
        return axios[method].apply(axios, [parsedUrl, configOrData, parsedConfig]);
    };
}
export function getRequestMappingParam(value, method) {
    if (value === void 0) { value = ''; }
    if (typeof value === 'string') {
        return {
            url: value,
            method: method
        };
    }
    return __assign(__assign({}, value), { method: method });
}
export function customGet(value) {
    return getCustomRequest(getRequestMappingParam(value, 'get'));
}
export function customPost(value) {
    return getCustomRequest(getRequestMappingParam(value, 'post'));
}
export function customPut(value) {
    return getCustomRequest(getRequestMappingParam(value, 'put'));
}
export function customPatch(value) {
    return getCustomRequest(getRequestMappingParam(value, 'patch'));
}
export function customDelete(value) {
    return getCustomRequest(getRequestMappingParam(value, 'delete'));
}
export function customHead(value) {
    return getCustomRequest(getRequestMappingParam(value, 'head'));
}
export function customOptions(value) {
    return getCustomRequest(getRequestMappingParam(value, 'options'));
}
export function fixAxiosGetUri(instance) {
    if (instance === void 0) { instance = axios; }
    if (!instance.defaults.paramsSerializer) {
        instance.defaults.paramsSerializer = function (params) { return buildURL('', params).slice(1).replace(/\[\]=/g, '='); };
    }
}
