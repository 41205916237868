/**
 * @author panezhang
 * @date 2018/7/30-15:03
 * @file transform
 */

import TYPE from './constant/property-type';

/**
 * 是否为 yqg-json-typed-editor 支持的类型
 * @param type
 * @returns {boolean}
 */
export const isValidType = type => [TYPE.JSON_TYPED, TYPE.LIST].includes(type);

function toRawArray(typedPropertyList = []) {
    return typedPropertyList.map(({type, value}) => {
        let resultValue;
        switch (type) {
            case TYPE.JSON_TYPED:
                resultValue = toRawJson(value);
                break;
            case TYPE.LIST:
                resultValue = toRawArray(value);
                break;
            default:
                resultValue = value;
        }

        return resultValue;
    });
}

function toRawJson(typedPropertyList = []) {
    return typedPropertyList.reduce((rawJson, {key, type, value}) => {
        switch (type) {
            case TYPE.JSON_TYPED:
                rawJson[key] = toRawJson(value);
                break;
            case TYPE.LIST:
                rawJson[key] = toRawArray(value);
                break;
            default:
                rawJson[key] = value;
        }

        return rawJson;
    }, {});
}

export function toRaw(type, typedPropertyList) {
    switch (type) {
        case TYPE.JSON_TYPED:
            return toRawJson(typedPropertyList);
        case TYPE.LIST:
            return toRawArray(typedPropertyList);
        default:
            throw new Error(`Unknown how to transform type [${type}] to raw json.`);
    }
}
