<!-- @author giligili -->
<!-- @email zhimingwang@yangqianguan.com -->
<!-- @date 2021-07-30 13:52:19.408 -->
<!-- @desc generated by yqg-cli@5.0.2 -->

<template>
    <a-icon
        type="edit"
        @click="openFeedbackModal()"
    />
</template>

<script type="text/babel">

    import FeedbackModal from './modal/feedback-modal';

    export default {
        name: 'HeaderFeedback',

        methods: {
            openFeedbackModal() {
                this.$modal.open(FeedbackModal).catch(x => x);
            }
        }
    };

</script>
