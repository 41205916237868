<!-- @author panezhang -->
<!-- @email panezhang@yangqianguan.com -->
<!-- @date 2018-07-28 20:34:09.859 -->
<!-- @desc generated by yqg-cli@0.3.1 -->

<template>
    <yqg-editor-container
        :value="value"
        :options="CodeMirrorOptions"
        v-bind="$attrs"
        @input="onChange"
        @ready="onCodeMirrorRef"
        @action-format="reformatJSON"
    />
</template>

<script type="text/babel">

import 'codemirror/mode/vue/vue';
import beautify from 'js-beautify';

const DEFAULT_OPTIONS = {
    mode: 'text/x-vue',
    smartIndent: true
};

export default {
    name: 'YqgHtmlEditor',

    props: {
        value: {
            type: String,
            default: ''
        },

        options: {
            type: Object,
            default: () => ({})
        }
    },

    data() {
        const {options} = this;

        return {
            CodeMirrorOptions: {
                ...DEFAULT_OPTIONS,
                ...options
            }
        };
    },

    methods: {
        onChange(value) {
            this.$emit('input', value);
        },

        onCodeMirrorRef(cm) {
            this.cm = cm;
        },

        reformatJSON(value) {
            const vm = this;
            try {
                vm.onChange(beautify.html(value));
                vm.$toast.success('Reformat 成功');
            } catch (error) {
                vm.$toast.error('Reformat 失败，请检查格式');
            }
        }
    }
};

</script>
