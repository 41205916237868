/*
 * Author: xymo
 * Created Date: 2021-04-13
 * email: xymo@yangqianguan.com
 */

<template>
    <a-layout-footer class="footer">
        Copyright © {{ year }} {{ copyright }}. All rights reserved.
    </a-layout-footer>
</template>

<script type="text/babel">
/* global __COPYRIGHT__ */

import moment from 'moment';

export default {
    name: 'Footer',

    data() {
        return {
            year: moment().year(),
            copyright: __COPYRIGHT__
        };
    }
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.footer {
    font-size: 16px;
    color: #ddd;
    text-align: center;
    background: transparent;
}
</style>
