/*
 * Author: xymo
 * Created Date: 2021-05-19
 * email: xymo@yangqianguan.com
 */

export default {
    YQG: 'YQG',
    SUPER: 'SUPER'
};
