/**
 * @Author: giligili
 * @Date: 2021-06-29
 * @Last Modified by: xymo
 * @Last Modified time: 2023-06-16 13:53:35
 */
import { assign, customGet, customPost, customDelete } from '@yqg/resource';
const api = {
    urlPrefix: '/admin/operation/col/callQuality/',
    listAnalyzeConfig: customGet('listAnalyzeConfig'),
    addAnalyzeConfig: customPost('addAnalyzeConfig'),
    updateAnalyzeConfig: customPost('updateAnalyzeConfig'),
    deleteAnalyzeConfig: customDelete('deleteAnalyzeConfig'),
    getMismatchReason: customGet('getMismatchReason'),
    setMismatchReason: customPost('setMismatchReason'),
    listConfigSnapshot: customGet('listConfigSnapshot'),
    deleteConfigSnapshot: customDelete('deleteConfigSnapshot'),
    useConfigSnapshot: customPost('useConfigSnapshot'),
    saveConfigSnapshot: customPost('saveConfigSnapshot'),
    listKeyWordExtra: customGet('listKeyWordExtra'),
    deleteKeyWordExtra: customDelete('deleteKeyWordExtra'),
    updateKeyWordExtra: customPost('updateKeyWordExtra'),
    addKeyWordExtra: customPost('addKeyWordExtra')
};
export default assign(api);
