import { render, staticRenderFns } from "./yqg-antv-upload.vue?vue&type=template&id=1c12db54&"
import script from "./yqg-antv-upload.vue?vue&type=script&lang=js&"
export * from "./yqg-antv-upload.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/jenkins/agent/workspace/fintopia-web7chz7/fintopia-web-ci/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1c12db54')) {
      api.createRecord('1c12db54', component.options)
    } else {
      api.reload('1c12db54', component.options)
    }
    module.hot.accept("./yqg-antv-upload.vue?vue&type=template&id=1c12db54&", function () {
      api.rerender('1c12db54', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/common/component/yqg-antv-upload/yqg-antv-upload.vue"
export default component.exports