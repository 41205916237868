/*
 * @Author: ruiwang
 * @Date 2023-03-02 16:17:53
 * @Last Modified by: ruiwang
 * @Last Modified time: 2023-03-02 18:45:08
 */
export default {
    methods: {
        genSlots({ def, parentIndex }) {
            const scopedSlots = Object.keys(this.$scopedSlots)
                .filter(slotKey => slotKey.startsWith(`${def.field}.`))
                .reduce((acc, cur) => {
                const name = cur.replace(`${def.field}.`, '');
                acc[name] = args => {
                    return this.renderSlot(cur, Object.assign(Object.assign({}, args), { parentIndex }));
                };
                return acc;
            }, {});
            return scopedSlots;
        },
        renderSlot(name, props, fallback) {
            var _a, _b;
            return ((_b = (_a = this.$scopedSlots)[name]) === null || _b === void 0 ? void 0 : _b.call(_a, props)) || fallback;
        }
    }
};
