/*
 * @Author: ruiwang
 * @Date: 2024-08-08 14:39:30
 * @Last Modified by: ruiwang
 * @Last Modified time: 2024-09-13 18:49:28
 */
import { CountryMap } from '../../../common/constant/country';
import MetricReporter from '../log-metrics';
const getUA = () => {
    try {
        return navigator.userAgent;
    }
    catch (_a) {
        return '';
    }
};
const generateReferer = () => {
    try {
        return location.href;
    }
    catch (e) {
        return '';
    }
};
export default class ApiMetrics {
    constructor(options) {
        if (ApiMetrics.instance) {
            return ApiMetrics.instance;
        }
        this.timer = null;
        this.options = options;
        const { appId, stage, size = 20, time = 1000, limitRate = 0.1, } = options;
        const random = Math.random();
        if (random > limitRate)
            return;
        const countryCode = CountryMap[stage];
        if (!countryCode)
            return;
        const isProd = /prod/i.test(stage);
        // 模板唯一键 (这里最终和influxdb模板measurement字段设置一致)，命名规范app_metrics_for_{业务标识}，例如：app_metrics_for_activity
        const measurement = 'app_metrics_for_api_metric';
        try {
            this.reporter = new MetricReporter({
                appId,
                countryCode,
                size,
                time,
                isProd,
                measurement,
                postUplaod: true
            });
        }
        catch (e) {
            /* handle error */
        }
        this.initApiMetricsObserver();
        ApiMetrics.instance = this;
    }
    initApiMetricsObserver() {
        try {
            this.observer = new PerformanceObserver((list) => {
                setTimeout(() => {
                    list.getEntries()
                        .filter(({ initiatorType }) => initiatorType === 'xmlhttprequest')
                        .forEach((entry) => {
                        var _a;
                        const metric = this.genMetricInfo(entry);
                        if (metric) {
                            (_a = this.reporter) === null || _a === void 0 ? void 0 : _a.report(metric);
                        }
                    });
                }, 0);
            });
            this.observer.observe({ type: 'resource', buffered: true });
            if (window) {
                window.addEventListener('unload', () => {
                    // 这里执行清理操作
                    this.clearObserver();
                });
            }
        }
        catch (e) {
            /* handle error */
        }
    }
    genMetricInfo(entry) {
        var _a;
        if (!entry)
            return null;
        const { name: fullUrl, startTime, fetchStart, requestStart, responseStart, responseEnd, responseStatus, encodedBodySize, decodedBodySize, transferSize, nextHopProtocol, duration } = entry;
        const urlObj = new URL(fullUrl);
        const { pathname, searchParams, search } = urlObj;
        const { options: { ignoreList = ['/logMetrics'] } } = this;
        if (ignoreList === null || ignoreList === void 0 ? void 0 : ignoreList.find((item) => pathname.startsWith(item))) {
            return null;
        }
        const spans = Math.ceil(duration);
        const compressed = decodedBodySize !== encodedBodySize;
        const timeToFetch = responseStart - requestStart;
        const requestTime = responseStart - requestStart;
        const recevieTime = responseEnd - responseStart;
        const waitTime = requestStart - startTime;
        const ua = getUA();
        const uaGenerator = ((_a = this.options) === null || _a === void 0 ? void 0 : _a.generateReferer) || generateReferer;
        const referer = uaGenerator();
        const metricsType = 'api_metric';
        const metric = {
            metricsType,
            ua,
            referer,
            url: pathname,
            spans,
            recevieTime,
            timeToFetch,
            requestTime,
            waitTime,
            compressed,
            startTime,
            fetchStart,
            requestStart,
            responseStart,
            responseEnd,
            status: responseStatus === 200 ? 'success' : 'fail',
            encodedBodySize,
            decodedBodySize,
            transferSize,
            nextHopProtocol
        };
        if (search) {
            const params = {};
            for (const [key, value] of searchParams.entries()) {
                params[key] = value;
            }
            Object.assign(metric, { params });
        }
        return metric;
    }
    clearObserver() {
        try {
            if (this.observer) {
                setTimeout(() => {
                    var _a;
                    const unprocessedEntries = this.observer.takeRecords();
                    unprocessedEntries
                        .filter(({ initiatorType }) => initiatorType === 'xmlhttprequest')
                        .forEach((entry) => {
                        var _a;
                        const metric = this.genMetricInfo(entry);
                        if (metric) {
                            (_a = this.reporter) === null || _a === void 0 ? void 0 : _a.report(metric);
                        }
                    });
                    (_a = this.observer) === null || _a === void 0 ? void 0 : _a.disconnect();
                }, 0);
            }
        }
        catch (error) {
            // ignore
        }
    }
}
