var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "yqg-code-textarea" },
    [
      _c(
        "codemirror",
        _vm._g(
          {
            ref: "cm",
            attrs: { value: _vm.value, options: _vm.CodeMirrorOptions },
          },
          _vm.$listeners
        )
      ),
      _vm._v(" "),
      _vm.reformatJson
        ? _c(
            "a-button",
            {
              staticStyle: { "margin-top": "10px" },
              on: {
                click: function ($event) {
                  return _vm.reformatJSON()
                },
              },
            },
            [_vm._v("\n        Reformat JSON\n    ")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }