<!-- @Author: giligili -->
<!-- @Date: 2021-07-05 15:33:47.212 -->
<!-- @Last Modified by: shaotanliang -->
<!-- @Last Modified time: 2023-11-30 15:01:52 -->

<template>
    <div class="yqg-wavesurfer">
        <div
            ref="wave"
            class="wavesurfer"
        />

        <div ref="time" />

        <slot
            v-if="controls"
            name="controls"
        >
            <div class="controls mt10">
                <span class="pull-left yqg-tip">录音总时长：{{ totalTime | millisToMinSecond }}</span>

                <div class="control">
                    <a-button
                        type="primary"
                        icon="fast-backward"
                        @click="wavesurfer.skipBackward()"
                    >
                        后退
                    </a-button>

                    <a-button
                        class="yqg-btn-warning"
                        @click="wavesurfer.playPause()"
                    >
                        <a-icon type="caret-right" />
                        播放
                        /
                        <a-icon type="pause" />
                        暂停
                    </a-button>

                    <a-button
                        type="primary"
                        icon="fast-forward"
                        @click="wavesurfer.skipForward()"
                    >
                        快进
                    </a-button>

                    <a-button
                        type="primary"
                        @click="stop"
                    >
                        <span class="stop-icon" />
                        停止
                    </a-button>

                    <yqg-antv-select
                        class="rate-select"
                        :value="rate"
                        :enum-type="$options.Rate"
                        @change="rateChange"
                    />
                </div>
                <!-- placeholder -->
                <div />
            </div>
        </slot>
    </div>
</template>

<script type="text/babel">
    import WaveSurfer from 'wavesurfer';
    import TimelinePlugin from 'wavesurfer/dist/plugin/wavesurfer.timeline.min'; // eslint-disable-line @typescript-eslint/no-unused-vars

    import Rate from './constant/rate';

    export default {
        name: 'YqgWavesurfer',

        Rate,

        props: {
            src: {
                type: String,
                required: true
            },

            controls: {
                type: Boolean,
                default: false
            },

            currentTime: { // ms哦
                type: Number,
                default: 0
            }
        },

        data() {
            return {
                rate: 1,
                totalTime: 0
            };
        },

        watch: {
            src() {
                this.initWavesufer();
            },

            currentTime(nextVal) {
                if (!nextVal) return;

                this.seekTo(nextVal);
            }
        },

        mounted() {
            this.initWavesufer();
        },

        beforeDestroy() {
            if (this.wavesurfer) {
                this.wavesurfer.destroy();
            }
        },

        methods: {
            initWavesufer() {
                if (this.wavesurfer) {
                    this.wavesurfer.destroy();
                }

                const {src, currentTime, $refs: {wave, time}} = this;

                const wavesurfer = WaveSurfer.create({
                    container: wave,
                    waveColor: '#cccccc',
                    progressColor: '#4ea1d3',
                    height: 50
                });

                wavesurfer.load(src);

                wavesurfer.on('ready', () => {
                    const timeline = Object.create(WaveSurfer.Timeline);

                    timeline.init({wavesurfer, container: time});

                    this.totalTime = (wavesurfer?.getDuration() || 0) * 1000;

                    this.$emit('ready', wavesurfer);
                });

                wavesurfer.on('finish', () => {
                    this.seekTo(currentTime);

                    this.$emit('finish', wavesurfer);
                });

                wavesurfer.on('audioprocess', () => {
                    this.$emit('audioprocess', wavesurfer);
                });

                this.wavesurfer = wavesurfer;
            },

            /* @desc wavesurfer 没有提供stop event em... */
            stop() {
                this.$emit('stop', this.wavesurfer);

                this.wavesurfer.stop();
            },

            rateChange(val) {
                this.rate = val;

                this.wavesurfer.setPlaybackRate(this.rate);
            },

            seekTo(val) {
                const {wavesurfer} = this;

                const second = (+val) / 1000;
                const progress = second / (wavesurfer?.getDuration() || 0);

                wavesurfer.seekTo(progress);
            }
        }
    };
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .yqg-wavesurfer {
        .wavesurfer {
            background-color: #f0f5f9;
        }

        .controls {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .stop-icon {
                display: inline-block;
                width: 10px;
                height: 10px;
                margin-right: 7px;
                border-radius: 2px;
                background-color: #fff;
            }

            .control {
                display: flex;

                & > * {
                    margin: 0 2px;
                }
            }
        }
    }
</style>
