var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "collapse" },
    [
      _c(
        "div",
        { staticClass: "title-wrapper", on: { click: _vm.handleClick } },
        [
          _c("a-icon", {
            staticClass: "mr10",
            attrs: { type: _vm.expand ? "minus" : "plus" },
          }),
          _vm._v(" "),
          _vm._t("title"),
        ],
        2
      ),
      _vm._v(" "),
      _vm.expand || _vm.rendered
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.expand,
                  expression: "expand",
                },
              ],
              staticClass: "content",
            },
            [_vm._t("default")],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._t("footer"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }