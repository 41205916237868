/*
 * @Author: huayizhang 
 * @Date: 2021-05-13 10:58:03 
 * @Last Modified by: huayizhang
 * @Last Modified time: 2021-05-13 10:58:24
 */

import Enum from 'collection-admin-web/common/util/enum';

export default Enum.from({
    PINGAN: '外呼系统一',
    ZOMA: '外呼系统二',
    HUAYUN: '外呼系统三',
    YQG: 'YTalk(外呼系统四)',
    YQGSMART: '智能外呼(外呼系统五)',
    IVR: 'IVR'
});
