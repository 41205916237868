<!-- @author giligili -->
<!-- @email zhimingwang@yangqianguan.com -->
<!-- @date 2021-09-24 14:46:05.384 -->
<!-- @desc generated by yqg-cli@5.1.2 -->

<template>
    <div class="router-loading">
        <a-spin
            spinning
            size="large"
        />
    </div>
</template>

<script type="text/babel">
    export default {
        name: 'YqgRouterLoading'
    };
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .router-loading {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }
</style>
