/**
 * @author huayizhang
 * @date 2018/11/20-17:02
 * @file NumberToPercentFilter
 */

import PercentFixed2 from './percent-fixed2-filter';

export default function () {
    return input => {
        const numberToPercent = PercentFixed2();

        return numberToPercent(input) === '/' ? '/' : `${numberToPercent(input)}%`;
    };
}
