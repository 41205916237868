import { axios as http } from '@yqg/resource';
import { STAGE } from '@shared/common/constant/stage';
import StageUtil from 'yqg-common/core/StageUtil';
import parseUa from 'yqg-common/core/WebviewInterface/util/parse-ua';
const prod = StageUtil.isProdEnv();
const prodIndo = /prod-indo/i.test(STAGE);
const uploadLogMap = {
    'test': 'https://event-tracking-api-test.yangqianguan.com/logMetrics',
    'prod': 'https://event-tracking-api.yangqianguan.com/logMetrics',
    'prod-indo': 'https://event-tracking-api.easycash.id/logMetrics'
};
const uploadLogKey = prodIndo ? 'prod-indo' : prod ? 'prod' : 'test';
export const api = {
    uploadLog: uploadLogMap[uploadLogKey]
};
let osType = 'WAP';
if (parseUa.isAndroid) {
    osType = 'ANDROID';
}
else if (parseUa.isIOS) {
    osType = 'IOS';
}
const hitLogReport = (!prod || location.href.indexOf('hitLogReportOpen') > -1) ? true : Math.random() < 0.1;
// reportForce: 强制上报，不走采样
export default function uploadLog(metricsType, parameter, logSetting, reportForce = false) {
    if (!reportForce && !hitLogReport)
        return;
    const { appId, country } = logSetting;
    // 跨域接口含自定义Header + appliaction/json，会导致有个预检请求
    http.post(api.uploadLog, {
        level: 'INFO',
        logs: [{
                appId,
                metricsType,
                measurement: parameter === null || parameter === void 0 ? void 0 : parameter.measurement,
                time: +new Date(),
                osType,
                uuid: parameter === null || parameter === void 0 ? void 0 : parameter.uuid,
                parameter: Object.assign({ url: window.location.href, userAgent: navigator.userAgent }, parameter)
            }]
    }, {
        headers: {
            'YQG-PLATFORM-SDK-TYPE': appId,
            'Country': country
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        hideLoading: true
    });
}
