/*
 * @Author: yefenghan 
 * @Date: 2024-08-20 15:01:53 
 * @Last Modified by: yefenghan
 * @Last Modified time: 2024-08-20 15:32:43
 */

export default phone => {
    if (!phone) {
        return null;
    }

    const phoneStr = String(phone);

    if (phoneStr.length <= 2) {
        return phoneStr;
    }

    return `${'*'.repeat(phoneStr.length - 2)}${phoneStr.slice(-2)}`;
};
