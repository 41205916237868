/*
 * @Author: mengpeng
 * @Date: 2022-11-10 10:42:00
 * @Last Modified by: mengpeng 
 * @Last Modified time: 2022-11-10 11:07:34 
 */

import {evalProp} from '../util/object';

export default {
    props: {
        dynamicProps: {
            type: Boolean,
            default: false
        }
    },

    methods: {
        getCompProps(params) {
            const {def: {staticProps}} = params;
            const {dynamicProps} = this;

            return {
                ...params,
                ...(dynamicProps ? evalProp(staticProps, params) : {})
            };
        }
    },
};
