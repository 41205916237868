/*
 * @Author: yefenghan 
 * @Date: 2024-08-13 16:03:54 
 * @Last Modified by: yefenghan
 * @Last Modified time: 2024-08-17 19:40:37
 */

export default {
    /** @desc 批量发送短信 */
    TOUCH_BATCH_SEND_SMS: 'TOUCH_BATCH_SEND_SMS',
    /** @desc IVR拨打 */
    TOUCH_ASSIGN_CASE_TO_IVR: 'TOUCH_ASSIGN_CASE_TO_IVR',
    /** @desc IVR批量拨打 */
    TOUCH_BATCH_ASSIGN_CASE_TO_IVR: 'TOUCH_BATCH_ASSIGN_CASE_TO_IVR',
    /** @desc 案件详情顶部发送短信 */
    TOUCH_CASE_SEND_SMS: 'TOUCH_CASE_SEND_SMS',
    /** @desc 案件详情发送催收短信	 */
    TOUCH_CASE_COLLECTION_SEND_SMS: 'TOUCH_CASE_COLLECTION_SEND_SMS',
    /** @desc 案件详情生成协议发送短信 */
    TOUCH_SEND_AGREEMENT_SMS: 'TOUCH_SEND_AGREEMENT_SMS',
    /** @desc 发送H5快捷还款券 */
    TOUCH_H5_REPAY_SEND_SMS: 'TOUCH_H5_REPAY_SEND_SMS',
    /** @desc 发送奖励提现券	 */
    TOUCH_SEND_INSTALMENT_COUPON: 'TOUCH_SEND_INSTALMENT_COUPON',
    /** @desc 发送结算减免券 */
    TOUCH_SEND_SETTLE_IN_ADVANCE_COUPON: 'TOUCH_SEND_SETTLE_IN_ADVANCE_COUPON',

    /** @desc 添加催记 */
    ADD_FLOW: 'ADD_FLOW',
    /** @desc 添加投诉催记 */
    ADD_COMPLAINT_FLOW: 'ADD_COMPLAINT_FLOW',

    /** @desc 手动输入开始拨打 */
    YTALK_DIAL_INPUT: 'YTALK_DIAL_INPUT',
    /** @desc 外呼组件开始拨打 */
    YTALK_DIAL_COMPONENT: 'YTALK_DIAL_COMPONENT',
    /** @desc 坐席手动挂断 */
    YTALK_SEAT_HANGUP: 'YTALK_SEAT_HANGUP',
    /** @desc 转发满意度匹配 */
    YTALK_TRANSFER_SATIS_IVR: 'YTALK_TRANSFER_SATIS_IVR',
    /** @desc 添加接通通话记录 */
    YTALK_ADD_CONNECT_CALL_DETAIL: 'YTALK_ADD_CONNECT_CALL_DETAIL',
    /** @desc 添加未接通通话记录 */
    YTALK_ADD_UNCONNECT_CALL_DETAIL: 'YTALK_ADD_UNCONNECT_CALL_DETAIL',
    /** @desc 开启坐席辅助 */
    YTALK_START_AGENT_COPILOT: 'YTALK_START_AGENT_COPILOT',
    /** @desc 切换工作状态 */
    YTALK_CHANGE_WORK_STATUS: 'YTALK_CHANGE_WORK_STATUS',
};
