var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "agent-copilot" },
    [
      _c(
        "a-collapse",
        { attrs: { "default-active-key": "1" } },
        [
          _c(
            "a-collapse-panel",
            { key: "1", attrs: { header: "坐席辅助" } },
            _vm._l(_vm.formatCopilotInfoList, function (item) {
              return _c(
                "div",
                {
                  key: item.id,
                  ref: "content",
                  refInFor: true,
                  staticClass: "content",
                },
                [
                  _c("div", { staticClass: "question" }, [
                    _c("span", { staticClass: "question-label" }, [
                      _vm._v(
                        "\n                        用户可能想要了解：\n                    "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "span",
                      { staticClass: "question-content" },
                      [_c("html-text", { attrs: { text: item.question } })],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "answer-tips" }, [
                    _vm._v("\n                    推荐话术\n                "),
                  ]),
                  _vm._v(" "),
                  _vm._l(item.answerList, function (answer, index) {
                    return _c("div", { key: index, staticClass: "answer" }, [
                      _c(
                        "div",
                        { staticClass: "answer-item" },
                        [
                          _c("span", { staticClass: "answer-tips" }, [
                            _vm._v(" 答案" + _vm._s(index + 1) + "："),
                          ]),
                          _vm._v(" "),
                          _c("html-text", { attrs: { text: answer.answer } }),
                        ],
                        1
                      ),
                    ])
                  }),
                  _vm._v(" "),
                  _c("a-divider"),
                ],
                2
              )
            }),
            0
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }