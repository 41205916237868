/**
 * @author giligili
 * @date 2021-06-24
 * @email zhimingwang@yangqianguan.com
 * @desc 打分类型
 */

import BooleanType from '../types/boolean';

export default {
    [BooleanType.TRUE]: '转接打分',
    [BooleanType.FALSE]: 'APP打分'
};
