<!-- @author giligili -->
<!-- @email zhimingwang@yangqianguan.com -->
<!-- @date 2021-07-21 16:53:02.903 -->
<!-- @desc generated by yqg-cli@5.1.1 -->

<script type="text/babel">

    import bus, {OnCloseMessageModal} from 'collection-admin-web/common/constant/event-bus';

    import genFormOptions from './constant/form-options';

    export default {
        name: 'SimpleModal',

        props: {
            // textarea, collector
            type: {
                type: String,
                default: 'textarea'
            },

            title: {
                type: String,
                default: '处理结果'
            },

            label: {
                type: String,
                default: '结果'
            },

            field: {
                type: String,
                default: 'result'
            },

            fieldOptions: {
                type: Object,
                default: () => ({})
            },

            confirm: {
                type: Function, // return Promise instance
                default: record => Promise.resolve(record)
            },

            defaultCond: {
                type: Object,
                default: () => ({})
            },

            customRender: {
                type: Function,
                default: undefined
            },

            customFooter: {
                type: Function,
                default: undefined
            },

            customTitle: {
                type: Function,
                default: undefined
            }
        },

        computed: {
            options() {
                const {field, label, type, fieldOptions} = this;

                return genFormOptions({field, label, type, fieldOptions});
            }
        },

        mounted() {
            bus.$on(OnCloseMessageModal, () => this.$emit('dismiss'));
        },

        beforeDestroy() {
            bus.$off(OnCloseMessageModal, () => this.$emit('dismiss'));
        },

        methods: {
            onConfirm({record}) {
                this.confirm(record)
                    .then(() => this.$emit('close', record))
                    .catch(err => err);
            }
        },

        render() {
            if (!this.customRender) {
                return (
                    <yqg-simple-form
                        title={this.title}
                        options={this.options}
                        values={this.defaultCond}
                        onConfirm={this.onConfirm}
                        onCancel={() => this.$emit('dismiss')}
                    />
                );
            }

            return (
                <yqg-modal
                    title={this.title}
                    onConfirm={this.onConfirm}
                    scopedSlots={{
                        title: this.customTitle
                            ? props => this.customTitle(props, this)
                            : undefined,
                        container: this.customRender
                            ? props => this.customRender(props, this)
                            : undefined,
                        footer: this.customFooter
                            ? props => this.customFooter(props, this)
                            : undefined
                    }}
                />
            );
        }
    };
</script>
