/**
 * @author giligili
 * @date 2021-11-15
 * @email zhimingwang@yangqianguan.com
 * @desc 电话修复渠道
 */

import CallRepairChannel from '../types/call-repair-channel';

export default {
    [CallRepairChannel.CHINA_MOBILE]: '移动',
    [CallRepairChannel.CHINA_UNICOM]: '联通',
    [CallRepairChannel.CHINA_TELECOM]: '电信'
};
