/**
 * @author giligili
 * @date 2021-08-18
 * @email zhimingwang@yangqianguan.com
 * @desc 基础的CallType 描述了通话具有的所有功能
 * 应该是一个抽象类, 由其他 CallType Class 实现
 *
 */
export default class BasicType {

    type = 'BASIC';

    static promiseInsFactory() {
        let resolve;
        let reject;

        const promise = new Promise((res, rej) => {
            resolve = res;
            reject = rej;
        });

        return {
            promise,
            resolve,
            reject
        };
    }

    constructor({sharedData, extraData, ytalkConfig, inviteInfo}) {
        this.extraData = extraData || {};
        this.sharedData = sharedData || {};
        this.ytalkConfig = ytalkConfig || {};
        this.inviteInfo = inviteInfo || {};

        this.callDirection = sharedData.callDirection;

        this.headersRet = BasicType.promiseInsFactory();
        this.sharedDataRet = BasicType.promiseInsFactory();
        this.caseDetailRet = BasicType.promiseInsFactory();
    }

    getHeaders() {
        const {headersRet} = this;

        headersRet.reject();

        return headersRet.promise;
    }

    getSharedData() {
        const {sharedDataRet} = this;

        sharedDataRet.reject();

        return sharedDataRet.promise;
    }

    getCaseDetail() {
        const {caseDetailRet} = this;

        caseDetailRet.reject();

        return caseDetailRet.promise;
    }

    updateSharedData(shared) {
        this.sharedData = shared;
    }

}
