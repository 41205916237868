/**
 * @author xinyiliang
 * @date 2019/6/13-19:23
 * @file date
 */

import moment from 'moment';

const DateFormatMap = {
    month: 'YYYY年MM月',
    dateMonth: 'YYYY年MM月DD日',
    dateHyphen: 'YYYY-MM-DD',
    dateTime: 'YYYY-MM-DD HH:mm:ss',
    hourMinutes: 'HH:mm',
    monthDay: 'MM-DD',
    monthHyphen: 'YYYY-MM'
};

export const month = timeStamp => (!timeStamp ? '/' : moment(timeStamp).format(DateFormatMap.month));

export const monthHyphen = timeStamp => (!timeStamp ? '/' : moment(timeStamp).format(DateFormatMap.monthHyphen));

export const dateHyphen = timeStamp => (!timeStamp ? '/' : moment(timeStamp).format(DateFormatMap.dateHyphen));

export const dateMonth = timeStamp => (!timeStamp ? '/' : moment(timeStamp).format(DateFormatMap.dateMonth));

export const dateTime = timeStamp => (!timeStamp ? '/' : moment(timeStamp).format(DateFormatMap.dateTime));

export const hourMinutes = timeStamp => (!timeStamp ? '' : moment(timeStamp).format(DateFormatMap.hourMinutes));

export const monthDay = timeStamp => (!timeStamp ? '' : moment(timeStamp).format(DateFormatMap.monthDay));

export const customizeDate = (timeStamp, format) => (
    (timeStamp && format) ? moment(timeStamp).format(format) : (timeStamp || '')
);

export const durationFormat = millSeconds => {
    const hours = `${Math.floor(moment.duration(millSeconds).asHours())}`.padStart(2, '0');
    const minutesSeconds = moment.utc(millSeconds).format('mm:ss');

    return `${hours}:${minutesSeconds}`;
};

export const millsToSecond = millSeconds => {
    if ([undefined, null, ''].includes(millSeconds)) return '-';

    return (millSeconds / 1000).toFixed(2);
};
