/*
 * Author: xymo
 * Created Date: 2021-06-10
 * email: xymo@yangqianguan.com
 */

export default x => {
    if (!x && x !== 0 && x !== '0') return '';

    const parts = x.toString().trim().split('.');
    parts[0] = parts[0] && parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    return parts.join('.');
};
