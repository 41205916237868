<!-- @author panezhang -->
<!-- @email panezhang@yangqianguan.com -->
<!-- @date 2018-08-15 20:07:25.234 -->
<!-- @desc generated by yqg-cli@0.3.1 -->

<template>
    <div
        :class="{'full-screen': fullScreen}"
        class="yqg-editor-container"
    >
        <div class="header-actions">
            <div class="left">
                <slot name="header-left">
                    <button
                        v-b-tooltip="'插入图片'"
                        class="simple"
                        @click="openImageUploadModal()"
                    >
                        <i class="b-icon-image" />
                    </button>

                    <button
                        v-b-tooltip="'插入文件'"
                        class="simple"
                        @click="openFileUploadModal()"
                    >
                        <i class="b-icon-copy" />
                    </button>

                    <button
                        v-b-tooltip="'格式化'"
                        class="simple"
                        @click="reformatCode()"
                    >
                        <i class="b-icon-adjust" />
                    </button>

                    <slot name="action-left" />
                </slot>
            </div>

            <div class="right">
                <slot name="action-right" />
                <button
                    class="simple"
                    @click="toggleFullScreen()"
                >
                    <i :class="{'b-icon-expand': !fullScreen, 'b-icon-compress': fullScreen}" />
                </button>
            </div>
        </div>

        <div class="editor">
            <slot name="editor">
                <yqg-code-textarea
                    :value="value"
                    :options="options"
                    @input="onChange"
                    @ready="onCodeMirrorRef"
                />
            </slot>
        </div>
    </div>
</template>

<script type="text/babel">

import ImageUploadModal from 'yqg-common/vue/modal/image-upload-modal';
import FileUploadModal from 'yqg-common/vue/modal/file-upload-modal';

const EVENT = {
    INPUT: 'input',
    READY: 'ready',
    ACTION_IMAGE: 'action-image',
    ACTION_FILE: 'action-file',
    ACTION_FORMAT: 'action-format',
    ACTION_FULL_SCREEN: 'action-full-screen'
};

export default {
    name: 'YqgEditorContainer',

    props: {
        value: {
            type: String,
            default: ''
        },

        options: {
            type: Object,
            default: () => ({})
        },

        bucket: null,

        dir: null,

        rootDir: null
    },

    data() {
        return {
            fullScreen: false
        };
    },

    methods: {
        onChange(value) {
            this.$emit(EVENT.INPUT, value);
        },

        onCodeMirrorRef(cm) {
            this.cm = cm;
            this.$emit(EVENT.READY, cm);
        },

        openImageUploadModal() {
            const vm = this;
            const {bucket, dir, rootDir} = vm;

            return this.$modal
                .open(ImageUploadModal, {
                    bucket,
                    dir,
                    rootDir
                })
                .then(imgUrl => {
                    if (this.$slots.editor) {
                        this.$emit(EVENT.ACTION_IMAGE, imgUrl);
                    } else {
                        this.cm.replaceSelection(imgUrl);
                    }
                })
                .catch(x => x);
        },

        openFileUploadModal() {
            const vm = this;
            const {bucket, dir, rootDir} = vm;

            return this.$modal
                .open(FileUploadModal, {
                    bucket,
                    dir,
                    rootDir
                })
                .then(({url: fileUrl}) => {
                    if (this.$slots.editor) {
                        this.$emit(EVENT.ACTION_FILE, fileUrl);
                    } else {
                        this.cm.replaceSelection(fileUrl);
                    }
                })
                .catch(x => x);
        },

        toggleFullScreen() {
            this.fullScreen = !this.fullScreen;
            if (this.fullScreen) {
                document.body.style.overflow = 'hidden';
            } else {
                document.body.style.overflow = 'auto';
            }

            if (this.$slots.editor) {
                this.$emit(EVENT.ACTION_FULL_SCREEN);
            } else {
                this.$nextTick(() => this.cm.refresh());
            }
        },

        reformatCode() {
            const vm = this;
            const {value} = vm;
            vm.$emit(EVENT.ACTION_FORMAT, value);
        }
    }
};

</script>

<style lang="scss" rel="stylesheet/scss">

    @import "~base-ui/src/style/variables";

    .yqg-editor-container {
        $full-screen-padding-top: 15px;
        $header-actions-bar-height: 30px;
        $header-actions-margin-bottom: 10px;

        .header-actions {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;
        }

        &.full-screen {
            position: fixed;
            z-index: $z-index-dialog-wrapper;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            padding: 15px 25px;
            background-color: #fff;

            .CodeMirror {
                height: calc(100vh - 100px);
            }
        }
    }

</style>
