/*
 * @Author: wenxiujiang 
 * @Date: 2023-07-20 05:54:34 
 * @Last Modified by: wenxiujiang
 * @Last Modified time: 2023-07-21 13:24:47
 */

import {Node} from 'tiptap';

export default class Audio extends Node {

    get name() {
        return 'audio';
    }

    get schema() {
        return {
            content: 'inline*',
            group: 'inline',
            selectable: false,
            draggable: true,
            inline: true,
            attrs: {
                src: {
                    default: null
                },
                title: {
                    default: null
                }
            },
            parseDOM: [
                {
                    tag: 'audio',
                    getAttrs: dom => ({
                        src: dom.getAttribute('src'),
                        title: dom.getAttribute('title')
                    })
                }
            ],
            toDOM: node => {
                return [
                    'audio', {
                        src: node.attrs.src,
                        title: node.attrs.title,
                        class: 'yqg-rich-audio',
                        controls: 'controls'
                    }
                ];
            }
        };
    }

    commands({type}) {
        return attrs => (state, dispatch) => {
            const {selection} = state;
            const position = selection.$cursor ? selection.$cursor.pos : selection.$to.pos;
            const node = type.create(attrs);
            const transaction = state.tr.insert(position, node);
            dispatch(transaction);
        };
    }

}
