var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.autoDialStatus
    ? _c("div", { staticClass: "header-predict-call" }, [
        _c(
          "span",
          {
            staticClass: "progress-text",
            class: {
              "yqg-text-success":
                _vm.autoDialStatus ===
                _vm.$options.AutoDialStatus.TYPE.PROCEEDING,
            },
          },
          [
            _vm._v(
              "\n        " +
                _vm._s(_vm.$options.AutoDialStatus.MAP[_vm.autoDialStatus]) +
                "\n    "
            ),
          ]
        ),
        _vm._v(" "),
        _vm.batchNumber
          ? _c("span", [_vm._v("呼叫轮次: " + _vm._s(_vm.batchNumber))])
          : _vm._e(),
        _vm._v(" "),
        _vm.progress
          ? _c("span", [_vm._v("呼叫进度: " + _vm._s(_vm.progress))])
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }