<!-- @author giligili -->
<!-- @email zhimingwang@yangqianguan.com -->
<!-- @date 2021-07-21 12:51:06.029 -->
<!-- @desc generated by yqg-cli@5.1.1 -->

<template>
    <div class="alarm-audit-abnormal">
        {{ notification.data.remark }}

        <yqg-router-link
            text="请查看"
            to="audit"
        />
    </div>
</template>

<script type="text/babel">
    export default {
        name: 'AlarmAuditAbnormal',

        props: {
            notification: {
                type: Object,
                default: () => ({})
            }
        }
    };
</script>
