/**
 * @author giligili
 * @date 2021-06-25
 * @email zhimingwang@yangqianguan.com
 * @desc 数据加密的密钥
 */

// https://code.yangqianguan.com/D35256
export const SECRET = '爱你一万年';

// https://code.yangqianguan.com/D38694
export const CALL_SECRET = 'ainiliangwannian';
