/**
 * @author giligili
 * @date 2021-07-30
 * @email zhimingwang@yangqianguan.com
 * @desc table-options
 */

import * as Fields from './fields';

export default ({rowSelection} = {}) => {
    const colDefs = [
        Fields.name,
        Fields.relationship,
        Fields.displayMobileNumber,
        Fields.validCntRatio,
        Fields.flowResponses
    ];

    if (rowSelection) {
        return {
            rowKey: 'encryptedMobileNumber',
            rowSelection,
            colDefs
        };
    }

    return {
        rowKey: 'encryptedMobileNumber',
        colDefs: [...colDefs, Fields.op]
    };
};
