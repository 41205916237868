var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "yqg-wavesurfer" },
    [
      _c("div", { ref: "wave", staticClass: "wavesurfer" }),
      _vm._v(" "),
      _c("div", { ref: "time" }),
      _vm._v(" "),
      _vm.controls
        ? _vm._t("controls", function () {
            return [
              _c("div", { staticClass: "controls mt10" }, [
                _c("span", { staticClass: "pull-left yqg-tip" }, [
                  _vm._v(
                    "录音总时长：" +
                      _vm._s(_vm._f("millisToMinSecond")(_vm.totalTime))
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "control" },
                  [
                    _c(
                      "a-button",
                      {
                        attrs: { type: "primary", icon: "fast-backward" },
                        on: {
                          click: function ($event) {
                            return _vm.wavesurfer.skipBackward()
                          },
                        },
                      },
                      [_vm._v("\n                    后退\n                ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "a-button",
                      {
                        staticClass: "yqg-btn-warning",
                        on: {
                          click: function ($event) {
                            return _vm.wavesurfer.playPause()
                          },
                        },
                      },
                      [
                        _c("a-icon", { attrs: { type: "caret-right" } }),
                        _vm._v(
                          "\n                    播放\n                    /\n                    "
                        ),
                        _c("a-icon", { attrs: { type: "pause" } }),
                        _vm._v("\n                    暂停\n                "),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "a-button",
                      {
                        attrs: { type: "primary", icon: "fast-forward" },
                        on: {
                          click: function ($event) {
                            return _vm.wavesurfer.skipForward()
                          },
                        },
                      },
                      [_vm._v("\n                    快进\n                ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "a-button",
                      { attrs: { type: "primary" }, on: { click: _vm.stop } },
                      [
                        _c("span", { staticClass: "stop-icon" }),
                        _vm._v("\n                    停止\n                "),
                      ]
                    ),
                    _vm._v(" "),
                    _c("yqg-antv-select", {
                      staticClass: "rate-select",
                      attrs: {
                        value: _vm.rate,
                        "enum-type": _vm.$options.Rate,
                      },
                      on: { change: _vm.rateChange },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div"),
              ]),
            ]
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }