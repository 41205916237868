/**
 * @author giligili
 * @date 2021-07-05
 * @email zhimingwang@yangqianguan.com
 * @desc 对应后端 /admin/operation/col/general 
 */

import Resource from '@yqg/resource';

import {aesDecryptCallSecret} from 'collection-admin-web/common/util/encryption';

const api = {
    urlPrefix: '/admin/operation/col/general/',

    getData: {
        url: 'getData',
        method: 'get'
    },

    listData: {
        url: 'listData',
        method: 'get'
    }
};

const resource = Resource.create(api);

export default {
    ...resource,

    getData(...vals) {
        return resource.getData(...vals)
            .then(({data: {body: {dataJson, encryptedDataJson}}}) => {
                let nextBody = {};

                try {
                    nextBody = encryptedDataJson
                        ? JSON.parse(aesDecryptCallSecret(encryptedDataJson))
                        : JSON.parse(dataJson);
                } catch (err) {
                    return Promise.reject(err);
                }

                return {data: {body: nextBody}};
            });
    },

    listData: params => resource.listData(params).then(({data: {body: {
        dataJson, encryptedDataJson
    }}}) => {
        try {
            const body = encryptedDataJson
                ? JSON.parse(aesDecryptCallSecret(encryptedDataJson))
                : JSON.parse(dataJson);
            body.dataList = body.dataList?.map(({loginTime, ...rest}) => ({
                ...rest,
                loginTime: +loginTime
            }));

            return {data: {body}};
        } catch (err) {
            return Promise.reject(err);
        }
    })
};
