var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "yqg-editor-container",
    {
      attrs: {
        value: _vm.innerValue,
        options: _vm.CodeMirrorOptions,
        bucket: _vm.bucket,
        dir: _vm.dir,
        "root-dir": _vm.rootDir,
      },
      on: {
        input: _vm.onChange,
        ready: _vm.onCodeMirrorRef,
        "action-format": _vm.reformatJSON,
      },
    },
    [
      _vm.parse && _vm.dirty
        ? _c(
            "span",
            {
              staticClass: "tip",
              attrs: { slot: "action-left" },
              slot: "action-left",
            },
            [_vm._v("\n        （格式有误）\n    ")]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }