/*
 * @Author: huayizhang 
 * @Date: 2021-07-05 14:09:50 
 * @Last Modified by: huayizhang
 * @Last Modified time: 2021-07-05 14:10:12
 */

import Enum from 'collection-admin-web/common/util/enum';

export default Enum.from({
    VALID: '处理中',
    FINISHED: '已处理'
});
