/**
 * @Author: giligili
 * @Date: 2023-01-28
 * @Last Modified by: giligili
 * @Last Modified time: 2023-07-06 14:26:13
 */
// https://stackoverflow.com/users/109538/broofa
const createUuid = () => 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    const r = Math.random() * 16 | 0;
    const v = c === 'x' ? r : ((r & 0x3) | 0x8);
    return v.toString(16);
});
export default createUuid;
