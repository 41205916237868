var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "typed-property" }, [
    _c("div", { staticClass: "card item", style: _vm.style }, [
      _c("div", { staticClass: "title" }, [
        _c("div", { staticClass: "left" }, [
          _c("span", { staticClass: "key" }, [
            _vm._v(
              "\n                    " +
                _vm._s(_vm.propertyKey) +
                "\n                "
            ),
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "type" }, [
            _vm._v(
              "\n                    [" +
                _vm._s(_vm.typeText) +
                "]\n                "
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "right" }, [
          _vm.hasChild(_vm.type)
            ? _c(
                "button",
                {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip",
                      value: _vm.createToolTip,
                      expression: "createToolTip",
                    },
                  ],
                  staticClass: "simple sm",
                  on: {
                    click: function ($event) {
                      return _vm.$emit("add", _vm.propertyKey, _vm.property)
                    },
                  },
                },
                [_c("i", { staticClass: "b-icon-create" })]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "button",
            {
              directives: [
                {
                  name: "b-tooltip",
                  rawName: "v-b-tooltip",
                  value: "编辑",
                  expression: "'编辑'",
                },
              ],
              staticClass: "simple sm",
              on: {
                click: function ($event) {
                  return _vm.$emit("edit", _vm.prefix, _vm.property)
                },
              },
            },
            [_c("i", { staticClass: "b-icon-edit" })]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              directives: [
                {
                  name: "b-tooltip",
                  rawName: "v-b-tooltip",
                  value: "复制节点",
                  expression: "'复制节点'",
                },
              ],
              staticClass: "simple sm",
              on: {
                click: function ($event) {
                  return _vm.$emit("clone", _vm.prefix, _vm.property)
                },
              },
            },
            [_c("i", { staticClass: "b-icon-copy" })]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              directives: [
                {
                  name: "b-tooltip",
                  rawName: "v-b-tooltip",
                  value: "删除",
                  expression: "'删除'",
                },
              ],
              staticClass: "simple sm",
              on: {
                click: function ($event) {
                  return _vm.$emit("remove", _vm.propertyKey, _vm.property)
                },
              },
            },
            [_c("i", { staticClass: "b-icon-trash" })]
          ),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "simple sm",
              on: {
                click: function ($event) {
                  _vm.collapse = !_vm.collapse
                },
              },
            },
            [
              _c("i", {
                class: {
                  "b-icon-collapse-down": _vm.collapse,
                  "b-icon-collapse-up": !_vm.collapse,
                },
              }),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.collapse,
              expression: "!collapse",
            },
          ],
          staticClass: "value",
        },
        [
          _vm.type === _vm.TYPE.TEXT
            ? _c("span", [
                _vm._v(
                  "\n                " + _vm._s(_vm.value) + "\n            "
                ),
              ])
            : _vm.type === _vm.TYPE.IMAGE
            ? _c("img", { attrs: { src: _vm.value, alt: _vm.value } })
            : _vm.type === _vm.TYPE.IMAGE_LIST
            ? _c(
                "div",
                _vm._l(_vm.value, function (imgSrc, index) {
                  return _c("img", {
                    key: index,
                    attrs: { src: imgSrc, alt: imgSrc },
                  })
                }),
                0
              )
            : _vm.type === _vm.TYPE.MARKDOWN
            ? _c("yqg-marked", { attrs: { md: _vm.value } })
            : _vm.type === _vm.TYPE.JSON_RAW
            ? _c("yqg-code-preview", {
                attrs: { code: JSON.stringify(_vm.value, null, 2) },
              })
            : _vm.displayPreview
            ? _c("pre", [_vm._v(_vm._s(_vm.value))])
            : _vm._e(),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _vm.hasChild(_vm.type)
      ? _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.collapse,
                expression: "!collapse",
              },
            ],
            staticClass: "item-children",
          },
          [
            _c("yqg-json-typed-editor", {
              attrs: {
                prefix: _vm.propertyKey,
                level: _vm.level + 1,
                type: _vm.type,
                bucket: _vm.bucket,
                dir: _vm.dir,
                "root-dir": _vm.rootDir,
              },
              on: {
                change: function ($event) {
                  return _vm.$emit("change")
                },
              },
              model: {
                value: _vm.property.value,
                callback: function ($$v) {
                  _vm.$set(_vm.property, "value", $$v)
                },
                expression: "property.value",
              },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("button", { staticClass: "simple sm sortable-handle" }, [
      _c("i", { staticClass: "b-icon-adjust" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }