<!-- @author giligili -->
<!-- @email zhimingwang@yangqianguan.com -->
<!-- @date 2021-05-20 16:46:38.135 -->
<!-- @desc generated by yqg-cli@4.0.7 -->

<template>
    <div class="yqg-json-visual yqg-card">
        <template v-for="field in fields">
            <component
                :is="$options.FieldCompMap[field.type]"
                :key="field.field"
                v-bind="field"
                :value="values[field.field]"
                :field-format="fieldFormat"
                @change="(nextValue) => onChange(field.field, nextValue)"
            />
        </template>

        <a-button
            v-if="$listeners.delete"
            size="small"
            type="danger"
            @click="$emit('delete')"
        >
            删除
        </a-button>
    </div>
</template>

<script type="text/babel">
    import {isJsonString} from 'collection-admin-web/common/util/object';

    import FieldCompMap from './constant/field-comp-map';

    export default {
        name: 'YqgJsonVisual',

        FieldCompMap,

        props: {
            value: {
                type: [Object, String],
                default: () => ({})
            },

            fieldDefs: {
                type: Array,
                default: () => []
            },

            fieldFormat: {
                type: Function,
                default: field => field
            }
        },

        data() {
            const {fieldDefs, fieldFormat} = this;

            return {
                values: this.formatValue(this.value),
                fields: fieldDefs.map(field => fieldFormat(field))
            };
        },

        watch: {
            value(nextValue) {
                this.values = this.formatValue(nextValue);
            }
        },

        methods: {
            formatValue(value) {
                let ret = value;

                if (typeof value == 'string') {
                    if (isJsonString(value) && value.charAt(0) === '{') {
                        ret = JSON.parse(value);
                    } else {
                        ret = {};
                    }
                }

                return ret;
            },

            onChange(field, value) {
                this.$set(this.values, field, value);
            }
        }
    };
</script>
