/*
 * @Author: huayizhang 
 * @Date: 2021-07-13 16:03:39 
 * @Last Modified by: huayizhang
 * @Last Modified time: 2021-07-13 16:04:07
 */

import Enum from 'collection-admin-web/common/util/enum';

export default Enum.from({
    INIT: '未还款',
    PROCEEDING: '未还清',
    FINISHED: '已还清',
    INVALID: '已作废'
});
