var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: { "preview-file-wrap": _vm.customPreview } },
    [
      _c(
        _vm.component,
        _vm._b(
          {
            tag: "component",
            staticClass: "field-file",
            on: { change: _vm.onChange, preview: _vm.onPreview },
          },
          "component",
          _vm.antProp,
          false
        ),
        [
          _c(
            _vm.def.props.draggable ? "p" : "a-button",
            { tag: "component" },
            [
              _c("a-icon", { attrs: { type: "upload" } }),
              _vm._v(" "),
              _vm.draggableTextComp
                ? _c(_vm.draggableTextComp, { tag: "component" })
                : _c("span", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.draggableText || _vm.$t("common.chooseFile")
                      ),
                    },
                  }),
              _vm._v(" "),
              _vm.def.props.draggable && _vm.acceptTips
                ? _c("div", [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.acceptTips) +
                        "\n            "
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.message
        ? _c("div", { staticClass: "yqg-text-danger" }, [
            _vm._v("\n        " + _vm._s(_vm.message) + "\n    "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.customPreview
        ? _vm._l(_vm.fileList, function (file) {
            return _c("div", { key: file.uid }, [
              file.response
                ? _c("div", [
                    _c(
                      "p",
                      [
                        _c("a-icon", { attrs: { type: "link" } }),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.onPreview(file)
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.decodeURIComponentSafe(file.name))
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _vm.isPdf(file.name) ||
                        (_vm.def.docxPreview && _vm.isDocx(file.name))
                          ? _c(
                              "a-button",
                              {
                                attrs: { size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onPreviewPdf(file)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(_vm.$t("file.preview")) +
                                    "\n                    "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        (_vm.isPdf(file.name) || _vm.isDocx(file.name)) &&
                        _vm.def.print
                          ? _c(
                              "a-button",
                              {
                                attrs: { size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.def.print(file.response.body)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(_vm.$t("common.print")) +
                                    "\n                    "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("a-icon", {
                          staticClass: "danger",
                          attrs: { type: "delete" },
                          on: {
                            click: function ($event) {
                              return _vm.remove(file)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.isImage(file.name)
                      ? _c("img", {
                          staticClass: "preview-img",
                          attrs: { src: _vm.getFileUrl(file) },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isAudio(file.name)
                      ? _c("audio", {
                          attrs: { src: _vm.getFileUrl(file), controls: "" },
                        })
                      : _vm._e(),
                  ])
                : _vm._e(),
            ])
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }