/**
 * @Author: giligili
 * @Date: 2023-01-28
 * @Last Modified by: giligili
 * @Last Modified time: 2023-07-12 16:51:57
 */
import store from 'collection-admin-web/common/store';
const DEFAULT_OPTIONS = {
    time: 3000
};
export default class RequestTimeout {
    constructor(payload = {}, options = DEFAULT_OPTIONS) {
        this.time = options.time;
        this.payload = payload;
        this.startTime = Date.now();
    }
    report() {
        var _a, _b;
        const endTime = Date.now();
        if (endTime - this.startTime <= this.time)
            return;
        YqgReporter.info({
            action: 'REQUEST_TIMEOUT',
            description: `请求响应超过${this.time}ms`,
            payload: Object.assign(Object.assign({}, this.payload), { referer: window.location.href, cookie: document.cookie, userName: (_b = (_a = store === null || store === void 0 ? void 0 : store.getters) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.name, startTime: new Date(this.startTime).toString(), endTime: new Date(endTime).toString(), time: endTime - this.startTime })
        });
    }
}
