<!-- @author panezhang -->
<!-- @email panezhang@yangqianguan.com -->
<!-- @date 2018-07-27 15:24:28.504 -->
<!-- @desc generated by yqg-cli@0.3.0 -->

<template>
    <b-dialog
        :open="visible"
        :title="title"
        :close-on-click-outside="false"
        dialog-class="lg"
        @close="cancel"
    >
        <div
            v-if="editing"
            class="b-form form-block"
        >
            <b-form-group label="类型">
                <b-select
                    v-model="editing.type"
                    :map="TYPE_MAP"
                    @change="onTypeChange()"
                />
            </b-form-group>

            <b-form-group
                v-if="editing.type && parentProperty.type !== TYPE.LIST"
                label="Key"
            >
                <b-input v-model="editing.key">
                    <span
                        v-if="prefix"
                        slot="left"
                        class="b-input-slot-left"
                    >
                        {{ prefix }}.
                    </span>
                </b-input>
            </b-form-group>

            <b-form-group
                v-if="valueEditor && valueEditor.name"
                label=""
            >
                <component
                    :is="valueEditor.name"
                    v-model="editing.value"
                    v-bind="valueEditor.props"
                />
            </b-form-group>
        </div>

        <button
            slot="actions"
            class="simple lg"
            @click="cancel()"
        >
            取消
        </button>
        <button
            slot="actions"
            class="simple lg"
            @click="confirm()"
        >
            确定
        </button>
    </b-dialog>
</template>

<script type="text/babel">

import TYPE from './constant/property-type';
import TYPE_MAP from './constant/property-type-map';

const EDITOR_MAP = {
    [TYPE.TEXT]: {
        name: 'b-input',
        props: {multiLine: true}
    },

    [TYPE.IMAGE]: {
        name: 'yqg-upload',
        props: {accept: 'image/*', autoUpload: true}
    },

    [TYPE.IMAGE_LIST]: {
        name: 'yqg-upload',
        props: {accept: 'image/*', multiple: true}
    },

    [TYPE.FILE]: {
        name: 'yqg-upload',
        props: {autoUpload: true}
    },

    [TYPE.FILE_LIST]: {
        name: 'yqg-upload',
        props: {multiple: true}
    },

    [TYPE.MARKDOWN]: {
        name: 'yqg-md-editor'
    },

    [TYPE.JSON_RAW]: {
        name: 'yqg-json-editor',

        validate: value => {
            try {
                JSON.parse(value);

                return {valid: true};
            } catch (err) {
                return {valid: false, errorText: 'JSON 格式有误'};
            }
        },

        format: value => JSON.stringify(value, null, 2), // for init
        parse: value => JSON.parse(value) // for resolve
    },

    [TYPE.HTML]: {
        name: 'yqg-html-editor'
    },

    [TYPE.CSS]: {
        name: 'yqg-style-editor'
    }
};

export default {
    name: 'TypedPropertyEditModal',

    props: {
        parentProperty: {
            type: Object,
            required: true
        },

        property: {
            type: Object,
            default: () => ({})
        },

        prefix: {
            type: String,
            default: ''
        },

        bucket: null,

        dir: null,

        rootDir: null
    },

    data() {
        return {
            TYPE,
            TYPE_MAP,

            editing: null,
            visible: false
        };
    },

    computed: {
        isNew() {
            return !(this.editing && this.editing.timeCreated);
        },

        valueEditor() {
            const {bucket, dir, rootDir} = this;

            return {
                ...EDITOR_MAP[this.editing.type],
                props: {
                    ...(EDITOR_MAP[this.editing.type] && EDITOR_MAP[this.editing.type].props),
                    bucket,
                    dir,
                    rootDir
                }
            };
        },

        title() {
            return this.isNew ? '添加页面数据' : '编辑页面数据';
        }
    },

    mounted() {
        const vm = this;
        vm.visible = true;
        vm.initEditing();
    },

    methods: {
        initEditing() {
            const {property, property: {type, value}} = this;
            const editing = {...property};
            const editor = EDITOR_MAP[type];
            if (editor && editor.format) {
                editing.value = editor.format(value);
            }

            this.editing = editing;
        },

        onTypeChange() {
            const {property: {type, value}, editing, valueEditor: {format = x => x} = {}} = this;
            if (editing.type === type) {
                editing.value = format(value);
            } else {
                delete editing.value;
            }
        },

        cancel() {
            const vm = this;
            vm.visible = false;
            vm.reject();
        },

        confirm() {
            const vm = this;
            const {
                $toast,

                isNew,
                parentProperty,
                editing,
                editing: {key, type, value},
                valueEditor: {
                    validate = () => ({valid: true}),
                    parse = x => x
                } = {}
            } = this;

            if (parentProperty.type !== TYPE.LIST) {
                if (!key) {
                    $toast.error('Key 不能为空');

                    return;
                }

                if (isNew
                        && parentProperty.value
                        && parentProperty.value.some(({key: existKey}) => existKey === key)) {
                    $toast.error(`Key [${key}] 已存在`);

                    return;
                }
            }

            if (!type) {
                $toast.error('类型不能为空');

                return;
            }

            const {valid, errorText} = validate(value);
            if (!valid) {
                this.$toast.error(errorText);

                return;
            }

            vm.visible = false;
            vm.resolve({
                timeCreated: Date.now(),
                ...editing,
                timeUpdated: Date.now(),
                value: parse(value)
            });
        }
    }
};

</script>
