<!-- @Author: yefenghan -->
<!-- @Date: 2024-4-18 18:43:59 -->
<!-- @Last Modified by: yefenghan -->
<!-- @Last Modified time: 2024-04-18 18:43:59 -->

<template>
    <div class="expert-case-entry-message">
        <span class="mr10">{{ notification.data.remark }}</span>

        <yqg-router-link
            class="mr10"
            text="请及时处理"
            to="case"
            :query="{caseId: encryCaseId}"
        />
    </div>
</template>

<script type="text/babel">

    export default {
        name: 'ExpertCaseEntryMessage',

        props: {
            notification: {
                type: Object,
                default: () => ({})
            }
        },

        computed: {
            encryCaseId() {
                return window.btoa(this.notification.data.caseId);
            }
        }
    };
</script>
