/**
 * @author xinzhong
 * @email xinzhong@yangqianguan.com
 * @date 2020-11-25 16:16:08.762
 * @desc generated by yqg-cli@1.3.1-alpha.6
 */

import Vue from 'vue';
import Router from 'vue-router';

import StageUtil from 'yqg-common/core/StageUtil';

import routes from './routes';

Vue.use(Router);

//  const scrollBehavior = (to, from, savePosition) => {
//     if (savePosition) {
//         return savePosition;
//     }
// 
//     const position = {y: 0};
// 
//     if (to.hash) {
//         position.selector = to.hash;
//         position.offset = {y: 80};
//     }
// 
//     return position;
// };

const router = new Router({
    mode: 'history',
    fallback: false,
    // scrollBehavior,
    routes
});

const rootTitle = document.head.querySelector('meta[name=description]').content;
export const setTitle = ({meta: {navTitle: title}}) => {
    const prefix = StageUtil.isProdEnv() ? '' : `(${StageUtil.getCurrentStage()}) `;
    document.title = `${prefix}${title ? `${title} - ` : ''}${rootTitle}`;
};

export default router;
