/*
 * @Author: xiaodongyu
 * @Date 2019-12-12 11:35:18
 * @Last Modified by: wenxiujiang
 * @Last Modified time: 2024-04-16 14:02:52
 */
import moment from 'moment';

import {boolOf, valueOf} from './enum';

const listSeparator = '\n';

// ListBrList 展示textarea, 接口要List
const list = {
    format: val => val && val.join(listSeparator),
    unformat: (val, {lineTrim}) => {
        if (lineTrim) {
            if (val?.trim()) {
                return val.trim().split(listSeparator).reduce((acc, cur) => cur.trim() ? [...acc, cur.trim()] : acc, []);
            }

            return undefined;
        }

        return (val ? val.trim().split(listSeparator) : undefined);
    }
};

// CommaBrComma 展示textarea, 接口要CommaString
const comma = {
    format: val => val && val.split(',').join(listSeparator),
    unformat: val => val
        && val
            .trim()
            .split(listSeparator)
            .join(',')
};

// ListBrJsonList 展示textarea, 接口要JsonList, 多用于查询条件，不支持编辑
const listJson = {
    ...list,
    unformat: val => val && JSON.stringify(val.trim().split(listSeparator))
};

// BooleanEnumBoolean 展示enum, 接口要boolean
const boolean = {
    format: valueOf,
    unformat: boolOf
};

const bool = {
    format: val => ((!!val || val === false) ? `${val}` : undefined),
    unformat: val => (typeof val !== 'string' ? undefined : val === 'true')
};

// CommaListComma 展示enum, 接口要CommaString
const listComma = {
    format: val => val && val.trim().split(','),
    unformat: val => val && val.join(',')
};

// 展示,分隔string，接口要list
const commaToList = {
    format(val) {
        return val && val.join(',');
    },
    unformat(val) {
        return val ? val.trim().split(',') : undefined;
    }
};

// ObjectJsonObject 展示textarea, 接口要Object
const json = {
    format: val => {
        try {
            return JSON.stringify(val, null, 2);
        } catch (err) {
            return val;
        }
    },
    unformat: val => {
        try {
            return JSON.parse(val);
        } catch (err) {
            return val;
        }
    }
};

// StringMomentString 财务相关接口要 DateString
const date = {
    format: val => {
        if (!val) return val;
        if (Array.isArray(val)) {
            return val.map(item => (moment(item).valueOf()));
        }

        return (moment(val).valueOf());
    },
    unformat: val => {
        if (!val) return val;
        if (Array.isArray(val)) {
            return val.map(item => moment(item).format('YYYY-MM-DD'));
        }

        return moment(val).format('YYYY-MM-DD');
    }
};
const dateTime = {
    format: val => {
        if (!val) return val;
        if (Array.isArray(val)) {
            return val.map(item => moment(item).valueOf());
        }

        return moment(val).valueOf();
    },
    unformat: val => {
        if (!val) return val;
        if (Array.isArray(val)) {
            return val.map(item => moment(item).format('YYYY-MM-DD HH:mm:ss'));
        }

        return moment(val).format('YYYY-MM-DD HH:mm:ss');
    }
};

const getPrecision = num => {
    const [integer, decimal = ''] = `${num}`.split('.');

    return {
        num: +`${integer}${decimal}`,
        digits: decimal?.length
    };
};

const percent = {
    format: val => {
        if (!val) return val;

        const {num, digits} = getPrecision(val);
        const res = (num * 100) / Math.pow(10, digits);

        return !isNaN(res) ? res : val;
    },
    unformat: val => {
        if (!val) return val;

        const {num, digits} = getPrecision(val);
        const res = num / (100 * Math.pow(10, digits));

        return !isNaN(res) ? res : val;
    }
};

const FormatMap = {
    boolean,
    bool,
    list,
    comma,
    listJson,
    json,
    listComma,
    date,
    dateTime,
    percent,
    commaToList
};

export default format => {
    if (typeof format === 'string') {
        return FormatMap[format];
    }

    return format;
};

