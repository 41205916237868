var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "yqg-modal" }, [
    _c(
      "div",
      { staticClass: "yqg-modal-title" },
      [
        _vm._t("title", function () {
          return [_vm._v("\n            " + _vm._s(_vm.title) + "\n        ")]
        }),
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "yqg-modal-container mb20" },
      [_vm._t("container")],
      2
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "yqg-modal-footer" },
      [
        _vm._t("footer", function () {
          return [
            _vm.$listeners.cancel
              ? _c("a-button", {
                  domProps: { textContent: _vm._s(_vm.cancelLabel) },
                  on: { click: _vm.$listeners.cancel },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.$listeners.confirm
              ? _c("a-button", {
                  attrs: { type: "primary" },
                  domProps: { textContent: _vm._s(_vm.confirmLabel) },
                  on: { click: _vm.$listeners.confirm },
                })
              : _vm._e(),
          ]
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }