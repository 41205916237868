<!-- @author giligili -->
<!-- @email zhimingwang@yangqianguan.com -->
<!-- @date 2021-10-18 16:33:11.293 -->
<!-- @desc generated by yqg-cli@5.1.2 -->

<template>
    <div class="network-loading">
        <div class="indicator">
            <div
                v-if="reqCount > 0"
                class="indeterminate"
                :class="className"
            />
            <div
                v-else
                class="no-animation"
                :class="className"
            />
        </div>

        <div
            v-if="reqCount > 0"
            class="mask"
        />
    </div>
</template>

<script type="text/babel">
    import {STAGE} from 'yqg-common/constant/AppConfig';

    export default {
        name: 'YqgNetworkLoading',

        props: {
            reqCount: {
                type: Number,
                default: 0
            }
        },

        data() {
            return {
                className: /prod/.test(STAGE) ? 'prod' : STAGE
            };
        }
    };
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .network-loading {
        position: fixed;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 99999;

        .mask {
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }

        .indicator {
            position: relative;
            display: block;
            width: 100%;
            height: 4px;
            overflow: hidden;
            background-color: #fff;
            background-clip: padding-box;
            border-radius: 2px;

            .indeterminate {
                background-color: #67c65a;

                &.feat {
                    background-color: #fc0;
                }

                &.prod {
                    background-color: #ff1338;
                }

                &::before {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    content: "";
                    background-color: inherit;
                    animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
                    will-change: left, right;
                }

                &::after {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    content: "";
                    background-color: inherit;
                    animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
                    animation-delay: 1.15s;
                    will-change: left, right;
                }
            }

            .no-animation {
                border-bottom: 4px solid #67c65a;

                &.feat {
                    border-bottom: 4px solid #fc0;
                }

                &.prod {
                    border-bottom: 4px solid #ff1338;
                }
            }
        }
    }

    @keyframes indeterminate {
        0% {
            right: 100%;
            left: -35%;
        }

        60% {
            right: -90%;
            left: 100%;
        }

        100% {
            right: -90%;
            left: 100%;
        }
    }

    @keyframes indeterminate-short {
        0% {
            right: 100%;
            left: -200%;
        }

        60% {
            right: -8%;
            left: 107%;
        }

        100% {
            right: -8%;
            left: 107%;
        }
    }
</style>

