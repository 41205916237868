var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "rankEl" },
    [
      _c(
        "a-popover",
        {
          attrs: {
            title: "催回率排名",
            placement: "leftBottom",
            trigger: "click",
            "get-popup-container": function () {
              return _vm.$refs.rankEl
            },
          },
          model: {
            value: _vm.visible,
            callback: function ($$v) {
              _vm.visible = $$v
            },
            expression: "visible",
          },
        },
        [
          _c("a-icon", {
            attrs: { type: "fall" },
            on: {
              click: function ($event) {
                return _vm.listCaseStatistics()
              },
            },
          }),
          _vm._v(" "),
          _c("yqg-simple-table", {
            attrs: {
              slot: "content",
              options: _vm.TableOptions,
              records: _vm.records,
            },
            slot: "content",
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }