/**
 * @Author: giligili
 * @Date: 2021-08-19
 * @Last Modified by: giligili
 * @Last Modified time: 2023-08-08 12:29:57
 */

import EnumAll from 'collection-admin-web/common/constant/enum';
import {genField} from 'collection-admin-web/common/util/tool';

export const result = genField('结果', 'result', 'select', {
    enumType: EnumAll.Call.CollectionResult
});
export const remark = genField('备注', 'remark', 'textarea');
export const timeCreated = genField('催记时间', 'timeCreated', 'dateTime');
export const collecteeName = genField('催收对象', 'collecteeName');
export const collecteeMobile = genField('联系电话', 'collecteeMobile');
export const collectionType = genField('联系人类型', 'collectionType', 'select', {
    enumType: EnumAll.Call.ContactType
});
