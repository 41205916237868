<!-- @author giligili -->
<!-- @email zhimingwang@yangqianguan.com -->
<!-- @date 2021-07-20 18:46:22.057 -->
<!-- @desc generated by yqg-cli@5.1.1 -->

<template>
    <div class="yqg-antv-date-picker">
        <a-radio-group
            v-if="showPreset && presets.length"
            class="presets mr10"
            :value="presetTime"
            @change="presetTimeChange"
        >
            <a-radio-button
                v-for="(preset, index) in presets"
                :key="index"
                :value="preset.value"
            >
                {{ preset.text }}
            </a-radio-button>
        </a-radio-group>

        <a-date-picker
            show-time
            :value="momentValue"
            @change="timeChange"
        />
    </div>
</template>

<script type="text/babel">

    import moment from 'moment';

    const dayStamp = 24 * 3600 * 1000;
    const todayStartTime = moment(Date.now()).startOf('day').valueOf();

    export default {
        name: 'YqgAntvDateTimePicker',

        props: {
            showPreset: {
                type: Boolean,
                default: false
            },

            presets: {
                type: Array,
                default: () => ['今天', '明天', '后天']
                    .map((text, index) => ({
                        text,
                        value: index
                    }))
            },

            value: {
                type: Number,
                default: undefined
            },

            formatPresetTime: {
                type: Function,
                default: value => Math.floor((value - todayStartTime) / dayStamp)
            },

            unformatPresetTime: {
                type: Function,
                default: (presetTime, value) => {
                    const timeStamp = value
                        ? value - moment(value).startOf('day').valueOf()
                        : 18 * 3600 * 1000;

                    return todayStartTime + timeStamp + presetTime * dayStamp;
                }
            }
        },

        computed: {
            momentValue() {
                return this.value && moment(this.value);
            },

            presetTime() {
                return this.formatPresetTime(this.value);
            }
        },

        methods: {
            presetTimeChange(event) {
                const time = event.target.value;

                this.$emit('change', this.unformatPresetTime(time, this.value));
            },

            timeChange(momentIns) {
                const time = momentIns && momentIns.valueOf();

                this.$emit('change', time);
            }
        }
    };
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .yqg-antv-date-picker {
        display: flex;

        .presets {
            display: flex;
            white-space: nowrap;
        }
    }
</style>
