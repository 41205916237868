/**
 * @Author: giligili
 * @Date: 2021-07-20
 * @Last Modified by: yefenghan
 * @Last Modified time: 2024-07-11 10:19:16
 */

import Resource from '@yqg/resource';

const api = {
    urlPrefix: '/admin/operation/col/notification/',

    listNotificationsByCaseId: {
        url: 'listNotificationsByCaseId',
        method: 'get'
    },

    updateNotificationV2: {
        url: 'updateNotificationV2',
        method: 'post'
    },

    createNotificationV2: {
        url: 'createNotificationV2',
        method: 'post'
    },

    invalidateNotification: {
        url: 'invalidateNotification',
        method: 'post'
    },

    finishNotification: {
        url: 'finishNotification',
        method: 'post'
    },

    queryNotification: {
        url: 'queryNotification',
        method: 'get'
    },

    listNotification: {
        url: 'listNotification',
        method: 'get'
    },

    batchFinishTransferCaseNotification: {
        url: 'batchFinishTransferCaseNotification',
        method: 'post'
    },

    listWorkStatusNotification: {
        url: 'listWorkStatusNotification',
        method: 'get'
    }
};

export default Resource.create(api);
