<!-- @Author: chengyuzhang -->
<!-- @Date: 2020-05-12 10:53:52.422 -->
<!-- @Last Modified by: suzilin -->
<!-- @Last Modified time: 2024-07-22 16:55:17 -->

<template>
    <div
        class="yqg-md-editor"
        :class="{'full-screen': fullScreen}"
    >
        <md-editor
            class="md-editor"
            :value="value"
            :customized-style="{height: '100%', border: '1px solid #eee'}"
            :options="options"
            :disabled="isDisabled"
            @change="onChange"
        />
        <md-view
            class="md-view"
            :md-text="value"
        />
    </div>
</template>

<script type="text/babel">
import fileUploadModal from '../../modal/file-upload-modal';
import imageUploadModal from '../../modal/image-upload-modal';
import mdEditor from './component/md-editor';
import mdView from './component/md-view';

export default {
    name: 'YqgMdEditor',

    components: {
        mdEditor,
        mdView
    },

    props: {
        value: {
            type: String,
            default: ''
        },

        def: {
            type: Object,
            default: () => ({})
        },

        ctx: {
            type: Object,
            default: () => ({})
        },

        userActionList: {
            type: Array,
            default: () => []
        },

        disableUploadImage: {
            type: Boolean,
            default: false
        },

        disableUploadFile: {
            type: Boolean,
            default: false
        },

        fileDef: {
            type: Object,
            default: undefined
        },

        imageDef: {
            type: Object,
            default: undefined
        },

        override: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            fullScreen: false
        };
    },

    computed: {
        options() {
            const vm = this;

            return {
                override: vm.override,
                actionList: [
                    ...(vm.disableUploadImage ? [] : [{
                        name: 'image',
                        icon: {
                            type: 'icon',
                            value: 'file-image'
                        },
                        insert(selectionText) {
                            return vm.openImageUploadModal(selectionText);
                        }
                    }]),
                    ...(vm.disableUploadFile ? [] : [{
                        name: 'file',
                        icon: {
                            type: 'icon',
                            value: 'file'
                        },
                        insert(selectionText) {
                            return vm.openFileUploadModal(selectionText);
                        }
                    }]),
                    ...vm.userActionList,
                    {
                        name: 'expend',
                        icon: {
                            type: 'icon',
                            value: vm.fullScreen ? 'fullscreen-exit' : 'fullscreen'
                        },
                        insert(selectionText) {
                            vm.fullScreen = !vm.fullScreen;

                            return selectionText;
                        }
                    }
                ]
            };
        },

        isDisabled() {
            const {def: {disabled = false}, ctx} = this;

            return !!(typeof(disabled) === 'function' ? disabled(ctx) : disabled);
        },
    },

    methods: {
        onChange(value) {
            this.$emit('change', value);
        },

        openImageUploadModal() {
            const {imageDef} = this;

            return this.$modal
                .open(imageUploadModal, {imageDef})
                .then(({imageDesc, file, imageUrl}) => `![${imageDesc || ''}](${file || imageUrl})`);
        },

        openFileUploadModal(selectionText) {
            const {fileDef} = this;

            return this.$modal
                .open(fileUploadModal, {fileDef})
                .then(({fileDesc: fileDescription, file, fileUrl}) => {
                    const url = `${file || fileUrl}`;
                    const description = selectionText || fileDescription || 'file-description';
                    const extStartIdx = url.lastIndexOf('.');
                    if (extStartIdx === -1) {
                        return `[${description}](${url})`;
                    }

                    const ext = url.slice(extStartIdx);
                    const attname = `?attname=${encodeURI(description)}${ext}`;

                    return `[${description}](${url}${attname})`;
                });
        }

    }
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.yqg-md-editor {
    width: 100%;
    display: flex;
    line-height: 20px;

    .md-editor {
        flex-shrink: 0;
        width: 50%;
        height: 500px;
    }

    .md-view {
        margin-top: 32px;
        height: 500px;
        flex-grow: 1;
        border: 1px solid #eee;
        border-left: none;
    }

    &.full-screen {
        position: fixed;
        z-index: 9999;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        padding: 15px 25px;
        background-color: #fff;
        height: 100vh;

        .md-editor,
        .md-view {
            height: calc(100vh - 100px);
        }
    }

    img {
        max-width: 100%;
    }
}
</style>
