/**
 * @Author: KylesLight
 * @Date: 21/10/2017-1:18 PM
 * @Last Modified by: xiaodongyu
 * @Last Modified time: 2023-04-11 19:58:52
 */

import Vue from 'vue';

import {axios, objToFormData, ObjectKey, ArrayKey} from '@yqg/resource';

const formDataConfig = {
    objectKey: ObjectKey.Whole,
    arrayKey: ArrayKey.Whole
};
axios.objToFormData = obj => objToFormData(obj, formDataConfig);

axios.formData = (url, obj, options) => {
    const formData = axios.objToFormData(obj);
    if (!formData) return formData;

    return axios.post(url, formData, options);
};

export default (Vue.prototype.$http = axios);
