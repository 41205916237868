<!-- @Author: giligili -->
<!-- @Date: 2021-07-30 13:52:19.408 -->
<!-- @Last Modified by: yefenghan -->
<!-- @Last Modified time: 2024-06-21 15:44:07 -->

<template>
    <a-badge
        v-if="show"
        class="header-multi-call"
        :count="multiCallCount"
        :offset="[3, -3]"
        @click="openMultiCallModal"
    >
        <svg
            viewBox="0 0 1024 1024"
            width="16"
            height="16"
        >
            <path
                v-if="multiCallCount"
                :d="$options.CallSvg"
            />

            <path
                v-else
                :d="$options.CallNotSvg"
            />
        </svg>
    </a-badge>
</template>

<script type="text/babel">

    import {mapGetters, mapActions} from 'vuex';

    import EnumAll from 'collection-admin-web/common/constant/enum';
    import bus, {OnYtalkCallRelease} from 'collection-admin-web/common/constant/event-bus';

    import {CallSvg, CallNotSvg} from './constant/svg';
    import MultiCallModal from './modal/multi-call';

    const CallDialType = EnumAll.Call.DialType.TYPE;

    export default {
        name: 'HeaderMultiCall',

        CallSvg,
        CallNotSvg,

        computed: {
            ...mapGetters(['user']),

            ...mapGetters('multiCall', ['caseList', 'multiCallCount']),

            show() {
                const {user: {thirdPartyType}} = this;

                return thirdPartyType === EnumAll.Call.Channel.TYPE.YQG;
            }
        },

        mounted() {
            // 延迟50ms 防止抢占页面并发限制
            setTimeout(this.fetchMultiCallCount, 50);

            bus.$on(OnYtalkCallRelease, this.multiCallRelease);
        },

        beforeDestroy() {
            bus.$off(OnYtalkCallRelease, this.multiCallRelease);
        },

        methods: {
            ...mapActions('multiCall', ['fetchMultiCallList', 'fetchMultiCallCount']),

            openMultiCallModal() {
                this.$modal.open(
                    MultiCallModal,
                    {},
                    {dialogProps: {width: 950}}
                ).catch(err => err);
            },

            multiCallRelease(payload) {
                if (![CallDialType.RECOMMEND_MULTI, CallDialType.MULTI].includes(payload?.extraData?.dialType)) return;

                // 这里后端是基于添加通话记录结果做了一些逻辑
                // 如果不加延时，getList接口调用在添加通话记录接口前面, 得到的数据不正确
                // 先 hack 一下
                setTimeout(() => {
                    this.fetchMultiCallList();
                }, 500);
            }
        }
    };

</script>

