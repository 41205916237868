/*
 * @Author: xiaodongyu
 * @Date 2019-11-29 23:27:11
 * @Last Modified by: yunfengguo
 * @Last Modified time: 2024-05-29 15:34:59
 */
/* eslint-disable global-require, no-underscore-dangle */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { upload } from 'qiniu-js';
import { axios as http } from '@yqg/resource';
import Web from '../resource/web';
import { boolOf } from '../util/enum';
import DefType from './def-type';
export const merge = (...args) => Object.assign({}, ...args);
export const required = (def) => merge(def, { required: true });
export const primaryStaticProps = { staticProps: { class: 'yqg-text-primary' } };
export const successStaticProps = { staticProps: { class: 'yqg-text-success' } };
export const warningStaticProps = { staticProps: { class: 'yqg-text-warning' } };
export const sizeLongProps = { props: { style: { maxWidth: '100%', width: '500px' } } };
export const fixedLeft = (def, width = 20) => merge(def, { column: { fixed: 'left', width } });
export const fixedRight = (def, width = 20) => merge(def, { column: { fixed: 'right', width } });
// ant-form-item-control-wrapper占全行
export const wholeRow = (def) => merge(def, { itemProps: { wrapperCol: { span: 24 } } });
export const getPre = (prefix) => suffix => `${prefix}${suffix}`;
export const clearFields = (fields) => ({ form }) => form.setFieldsValue(fields.reduce((acc, field) => (Object.assign(Object.assign({}, acc), { [field]: undefined })), {}));
export const getSdTextStaticProps = (checker = boolOf, success = true) => ({ value }) => {
    const valid = checker(value);
    return {
        class: {
            'yqg-text-success': success && valid,
            'yqg-text-danger': !valid
        }
    };
};
// yqg-simple-table的column默认不换行，此方法用来给长文本设置宽度和换行，放在staticProps里，slot不可用
export const genColumnWrapStyle = ({ minWidth, maxWidth, wrap = false }) => ({
    style: {
        display: 'inline-block',
        minWidth: minWidth ? `${minWidth}px` : 'inherit',
        maxWidth: maxWidth ? `${maxWidth}px` : 'inherit',
        whiteSpace: wrap ? 'normal' : null,
        wordBreak: wrap ? 'break-all' : null
    }
});
export const staticComp = (def) => merge(def, { component: 'def-value' });
export const dateTimeDef = { type: 'date', props: { showTime: true }, filter: 'dateTime' };
export const blockItem = { itemProps: { class: 'yqg-form-item-block' } };
export const hiddenItem = { itemProps: { class: 'yqg-form-item-hidden' } };
export const rangeItem = { label: ' ', itemProps: { class: 'yqg-form-item-range' } };
export const op = { field: 'op', label: 'common.op' };
export const id = { field: 'id', label: 'ID' };
export const time = { field: 'time', label: 'common.time', filter: 'dateTime' };
export const timeCreate = { field: 'timeCreate', label: 'common.timeCreate', filter: 'dateTime' };
export const timeUpdate = { field: 'timeUpdate', label: 'common.timeUpdate', filter: 'dateTime' };
export const timeCreated = merge(timeCreate, { field: 'timeCreated' });
export const timeUpdated = merge(timeUpdate, { field: 'timeUpdated' });
export const remark = { field: 'remark', type: 'textarea', label: 'common.remark' };
export const numberCommasFixed2 = { filter: 'numberCommasFixed2' };
export const generateColumnDefs = (tableTitle, extraDefMap) => tableTitle.map(({ value: field, label, cellFilter: filter }) => (Object.assign(Object.assign({ field,
    label,
    filter }, (DefType[filter] ? { type: filter } : {})), (extraDefMap && extraDefMap[field]))));
export const getCommonOptions = (_a) => {
    var { tableTitle, rowKey = 'id', op: flag = true, extraDefMap = {}, extraCols = [] } = _a, rest = __rest(_a, ["tableTitle", "rowKey", "op", "extraDefMap", "extraCols"]);
    return (Object.assign({ rowKey, colDefs: [
            ...generateColumnDefs(tableTitle, extraDefMap),
            ...(flag ? [op] : []),
            ...extraCols
        ] }, rest));
};
// const fetchUrlMap = {
//     // 'dev': 'https://octopus-api-test.yangqianguan.com/',
//     'dev': 'https://test-sea-octopus-api.fintopia.tech/',
//     'test-overseas': 'https://test-sea-octopus-api.fintopia.tech/',
//     'test-indo': 'https://test-sea-octopus-api.fintopia.tech/',
//     'test-mex': 'https://test-sea-octopus-api.fintopia.tech/',
//     'test-sea': 'https://test-sea-octopus-api.fintopia.tech/',
//     'test-eu': 'https://test-sea-octopus-api.fintopia.tech/',
//     'feat-overseas': 'https://test-sea-octopus-api.fintopia.tech/',
//     'prod-indo': '',
//     'prod-mex-huawei': '',
//     'prod-phi': '',
//     'prod-sea': '',
//     'prod-eu': ''
// };
// customRequest 方法 开启开关 将会 使用公共七牛云服务 获取token
export const customRequest = (options) => __awaiter(void 0, void 0, void 0, function* () {
    const { file, onError, onProgress, onSuccess } = options;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const env = __STAGEENV__;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    if (__USE__COMMON_API__) {
        const { data: { body } } = yield http.get('api-web/yqg-upload/get-token');
        const token = body;
        const observable = upload(file, null, token);
        return new Promise((resolve, reject) => {
            observable.subscribe({
                next: onProgress,
                error: err => {
                    onError(err);
                    reject(err);
                    uploadlogFail(env);
                },
                complete: res => {
                    onSuccess(res);
                    resolve(res);
                    uploadlogSuccess(env);
                }
            });
        });
    }
    else {
        const { data: { body: token } } = yield Web.getToken();
        const observable = upload(file, null, token);
        return new Promise((resolve, reject) => {
            observable.subscribe({
                next: onProgress,
                error: err => {
                    onError(err);
                    reject(err);
                },
                complete: res => {
                    onSuccess(res);
                    resolve(res);
                }
            });
        });
    }
});
// 区分正式 测试环境
const uploadlogSuccess = (env) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    const hasProd = env.includes('prod');
    const url = !hasProd ? 'https://event-tracking-api-test.yangqianguan.com/logMetrics' : 'https://event-tracking-api.yangqianguan.com/logMetrics';
    http.post(url, {
        level: 'INFO',
        logs: [{
                time: +new Date(),
                metricsType: 'qiniuyun-gettoken-success',
                parameter: {
                    url: (_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.href,
                    userAgent: navigator === null || navigator === void 0 ? void 0 : navigator.userAgent,
                    env
                }
            }]
    }, {
        headers: {
            'YQG-PLATFORM-SDK-TYPE': 'qiniuyun-gettoken',
            'Country': 'CN',
            'content-type': 'application/json'
        }
    });
});
const uploadlogFail = (env) => __awaiter(void 0, void 0, void 0, function* () {
    var _b;
    const hasProd = env.includes('prod');
    const url = !hasProd ? 'https://event-tracking-api-test.yangqianguan.com/logMetrics' : 'https://event-tracking-api.yangqianguan.com/logMetrics';
    yield http.post(url, {
        params: {
            level: 'ERROR',
            logs: [
                {
                    appId: 'qiniuyun-gettoken',
                    metricsType: 'qiniuyun-gettoken-error',
                    parameter: {
                        url: (_b = window === null || window === void 0 ? void 0 : window.location) === null || _b === void 0 ? void 0 : _b.href,
                        userAgent: navigator === null || navigator === void 0 ? void 0 : navigator.userAgent,
                        env
                    }
                }
            ]
        },
    }, {
        headers: {
            'YQG-PLATFORM-SDK-TYPE': 'qiniuyun-gettoken',
            'Country': 'CN',
            'content-type': 'application/json'
        }
    });
});
export const qiniuFileDef = {
    type: 'file',
    props: { customRequest, listType: 'text', valueField: 'url', urlField: 'url' }
};
export const qiniuImageDef = {
    type: 'file',
    props: { customRequest, listType: 'picture', valueField: 'url', urlField: 'url' }
};
