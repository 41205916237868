/**
 * @author giligili
 * @date 2021-07-30
 * @email zhimingwang@yangqianguan.com
 * @desc fields
 */

import EnumAll from 'collection-admin-web/common/constant/enum';
import Enum from 'collection-admin-web/common/util/enum';
import {genField} from 'collection-admin-web/common/util/tool';

const ContactMap = EnumAll.Call.ContactType.MAP;
const ContactType = EnumAll.Call.ContactType.TYPE;

export const minOverdueDays = genField('最小逾期天数', 'minOverdueDays', 'number');
export const maxOverdueDays = genField('最大逾期天数', 'maxOverdueDays', 'number');
export const startPrincipal = genField('最小本金', 'startPrincipal', 'number');
export const endPrincipal = genField('最大本金', 'endPrincipal', 'number');
export const contactType = genField('联系人类型', 'contactType', 'select', {
    enumType: Enum.from({
        [ContactType.SELF]: ContactMap[ContactType.SELF],
        [ContactType.IMMEDIATE]: ContactMap[ContactType.IMMEDIATE]
    })
});

