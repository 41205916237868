/**
 * @author giligili
 * @date 2023-03-14
 * @email zhimingwang@fintopia.tech
 * @desc table-options
 */

import * as Fields from './fields';

export default {
    rowKey: 'id',
    colDefs: [
        Fields.term,
        Fields.billingDate,
        Fields.repaidDate,
        Fields.status,
        Fields.isOverdue,
        Fields.amount,
        Fields.repaidAmount,
        Fields.remainAmount
    ]
};
