/**
 * @author giligili
 * @date 2022-07-08
 * @email zhimingwang@yangqianguan.com
 * @desc 获取七牛TOKEN的resource
 */

import UploadResource from 'collection-admin-web/common/resource/upload';

import * as BucketType from './bucket-type';

export default {
    [BucketType.WEB]: {
        getToken: UploadResource.getToken,
        tokenFilter: res => ({
            token: res.data.body,
            serverResponseTime: res.data.status.serverResponseTime
        }),
        fileFilter: res => res.body.url,
        getFileName: () => null
    },
    [BucketType.COLLECTION]: {
        getToken: UploadResource.getQiniuPrivateFileUploadToken,
        tokenFilter: res => ({
            token: res.data.body.uploadToken,
            serverResponseTime: res.data.status.serverResponseTime
        }),
        fileFilter: res => res.key,
        getFileName: (file, timestamp) => {
            // 这里后端不想设置key，想让前端传文件名+后缀
            // 利用一下getToken接口响应时间, 尽量保证不重复
            // ....
            const fileTags = file.name.split(/\.(?=[^.]*$)/);
            fileTags[0] += `-${timestamp}`;

            return fileTags.join('.');
        }
    }
};
