/**
 * @Author: zhangpeng
 * @Date: 17/3/8-上午11:06
 * @Last Modified by: ruiwang
 * @Last Modified time: 2023-08-30 14:36:45
 */

export const ProxyRoot = {
    chidori: '/chidori'
};

export default {
    apiWeb: {
        root: '/api-web',
        common: '/api-web/:module/:action',

        getToken: '/api-web/yqg-upload/get-token',
        getHuaweiSignedUrl: '/api-web/yqg-upload/get-huawei-signed-url',

        fileUpload: '/api-web/yqg-upload/upload-file', // POST
        fileUploadAws: '/api-web/yqg-upload/upload-file-aws',

        hello: '/api-web/hello',
        getVersion: '/api-web/get-version',
        getI18n: '/api-web/get-i18n',
        getI18nIncremental: '/api-web/get-i18n-incremental',
        pipe: '/api-web/pipe',
        report: '/api-web/report'
    },

    admin: {
        proxy: '/admin',
        getStaticTextByKey: '/admin/operation/staticText/getByKey'
    },

    chidori: {
        auth: '/rpc/employee/auth/verification', // 验证 token，并获取到用户信息
        employee: '/rpc/employee', // 获取所有用户信息,
        verifyTicket: '/admin/public/auth/verifyTicket' // 发送跨域请求进行登录
    },

    index: '/admin/index',
    login: '/admin/login',
    logout: '/admin/logout',

    isLogin: '/admin/isLogin',
    getLoginToken: '/admin/getLoginToken',

    authenticate: {
        userpass: '/admin/authenticate/userpass',
        userpassV2: '/admin/authenticateOrThrow/userpass',

        api: {
            userpass: '/admin/api/authenticate/userpass'
        }
    }
};
