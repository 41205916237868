/**
 * @author giligili
 * @date 2021-08-23
 * @email zhimingwang@yangqianguan.com
 * @desc 催记类型
 */

export default {
    SELF: '电催',
    RELATIVES: '电催',
    ENTERPRISE_WECHAT: '企业微信'
};
