<!-- @Author: wenxiujiang -->
<!-- @Date: 2023-7-20 06:24:52 -->
<!-- @Last Modified by: wenxiujiang -->
<!-- @Last Modified time: 2023-07-20 06:40:17 -->

<template>
    <yqg-simple-form
        :title="$t('common.uploadAudio')"
        :options="formOptions"
        @cancel="dismiss"
        @confirm="onConfirm"
    />
</template>

<script type="text/babel">
import {modal} from '../../../mixin';
import getFormOptions from '../constant/audio-upload-form-options';

export default {
    name: 'AudioUploadModal',

    mixins: [modal],

    props: {
        fileDef: {
            type: Object,
            default: undefined
        },

        multiple: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        formOptions() {
            return getFormOptions(this);
        }
    },

    methods: {
        onConfirm({record}) {
            this.close(record);
        }
    }
};
</script>
