var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "field-rich" },
    [
      !_vm.disabled
        ? [
            _c("editor-menu-bar", {
              attrs: { editor: _vm.editor },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (ref) {
                      var commands = ref.commands
                      var isActive = ref.isActive
                      return [
                        _vm.def && _vm.def.customMenubar
                          ? _c(
                              _vm.def.customMenubar,
                              _vm._b(
                                { tag: "component", staticClass: "menubar" },
                                "component",
                                { commands: commands, isActive: isActive },
                                false
                              )
                            )
                          : _c("div", { staticClass: "menubar" }, [
                              _c(
                                "div",
                                { staticClass: "row" },
                                [
                                  _vm._l(
                                    _vm.$options.CmdIcon.action,
                                    function (ref) {
                                      var icon = ref.icon
                                      var cmd = ref.cmd
                                      return _c(
                                        "a-tooltip",
                                        {
                                          key: cmd,
                                          attrs: {
                                            title: _vm.$t(_vm.prefix(cmd)),
                                          },
                                        },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass: "menubar__button",
                                              on: { click: commands[cmd] },
                                            },
                                            [
                                              _c("a-icon", {
                                                attrs: { type: icon },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  _vm._v(" "),
                                  _vm._l(
                                    _vm.$options.CmdIcon.active.row1,
                                    function (ref) {
                                      var cmd = ref.cmd
                                      var icon = ref.icon
                                      var text = ref.text
                                      var arg = ref.arg
                                      var title = ref.title
                                      return _c(
                                        "a-tooltip",
                                        {
                                          key: text || icon || cmd,
                                          attrs: {
                                            title: _vm.$t(
                                              _vm.prefix(title || cmd)
                                            ),
                                          },
                                        },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass: "menubar__button",
                                              class: {
                                                "is-active": isActive[cmd](arg),
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return commands[cmd](arg)
                                                },
                                              },
                                            },
                                            [
                                              icon
                                                ? _c("a-icon", {
                                                    attrs: { type: icon },
                                                  })
                                                : [
                                                    _vm._v(
                                                      "\n                                " +
                                                        _vm._s(text) +
                                                        "\n                            "
                                                    ),
                                                  ],
                                            ],
                                            2
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "row" },
                                [
                                  _vm._l(
                                    _vm.$options.CmdIcon.active.row2,
                                    function (ref) {
                                      var cmd = ref.cmd
                                      var icon = ref.icon
                                      var text = ref.text
                                      var arg = ref.arg
                                      var title = ref.title
                                      return _c(
                                        "a-tooltip",
                                        {
                                          key: text || icon || cmd,
                                          attrs: {
                                            title: _vm.$t(
                                              _vm.prefix(title || cmd)
                                            ),
                                          },
                                        },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass: "menubar__button",
                                              class: {
                                                "is-active": isActive[cmd](arg),
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return commands[cmd](arg)
                                                },
                                              },
                                            },
                                            [
                                              icon
                                                ? _c("a-icon", {
                                                    attrs: { type: icon },
                                                  })
                                                : [
                                                    _vm._v(
                                                      "\n                                " +
                                                        _vm._s(text) +
                                                        "\n                            "
                                                    ),
                                                  ],
                                            ],
                                            2
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "a-tooltip",
                                    {
                                      attrs: {
                                        title: _vm.$t(_vm.prefix("fontSize")),
                                      },
                                    },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "menubar__button",
                                          class: {
                                            "is-active":
                                              isActive["inline-font-size"](),
                                          },
                                        },
                                        [
                                          _c("inline-font-size-btn", {
                                            on: {
                                              change: function ($event) {
                                                return _vm.onInlineStyleChange(
                                                  commands,
                                                  "font-size",
                                                  $event
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "a-tooltip",
                                    {
                                      attrs: {
                                        title: _vm.$t(_vm.prefix("fontColor")),
                                      },
                                    },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "menubar__button no-padding",
                                          class: {
                                            "is-active":
                                              isActive["inline-color"](),
                                          },
                                        },
                                        [
                                          _c("inline-color-btn", {
                                            on: {
                                              change: function ($event) {
                                                return _vm.onInlineStyleChange(
                                                  commands,
                                                  "color",
                                                  $event
                                                )
                                              },
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (ref) {
                                                    var color = ref.color
                                                    return [
                                                      _c("a-icon", {
                                                        staticClass:
                                                          "inline-style-btn-icon",
                                                        style:
                                                          "color: " +
                                                          color +
                                                          ";",
                                                        attrs: {
                                                          type: "font-colors",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.onInlineStyleChange(
                                                              commands,
                                                              "color",
                                                              color
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              557503226
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "a-tooltip",
                                    {
                                      attrs: {
                                        title: _vm.$t(
                                          _vm.prefix("backgroundColor")
                                        ),
                                      },
                                    },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "menubar__button no-padding",
                                          class: {
                                            "is-active":
                                              isActive[
                                                "inline-background-color"
                                              ](),
                                          },
                                        },
                                        [
                                          _c("inline-color-btn", {
                                            on: {
                                              change: function ($event) {
                                                return _vm.onInlineStyleChange(
                                                  commands,
                                                  "background-color",
                                                  $event
                                                )
                                              },
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (ref) {
                                                    var color = ref.color
                                                    return [
                                                      _c("a-icon", {
                                                        staticClass:
                                                          "inline-style-btn-icon",
                                                        style:
                                                          "color: " +
                                                          color +
                                                          ";",
                                                        attrs: {
                                                          type: "bg-colors",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.onInlineStyleChange(
                                                              commands,
                                                              "background-color",
                                                              color
                                                            )
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              2433290287
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "a-tooltip",
                                    {
                                      attrs: {
                                        title: _vm.$t(_vm.prefix("link")),
                                      },
                                    },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "menubar__button",
                                          on: {
                                            click: function ($event) {
                                              return _vm.openLinkModal(
                                                commands.anchor
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("a-icon", {
                                            attrs: { type: "link" },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "a-tooltip",
                                    {
                                      attrs: {
                                        title: _vm.$t(_vm.prefix("image")),
                                      },
                                    },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "menubar__button",
                                          on: {
                                            click: function ($event) {
                                              return _vm.openImageUploadModal(
                                                commands.image,
                                                commands.hard_break
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("a-icon", {
                                            attrs: { type: "file-image" },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "a-tooltip",
                                    {
                                      attrs: {
                                        title: _vm.$t(_vm.prefix("audio")),
                                      },
                                    },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "menubar__button",
                                          on: {
                                            click: function ($event) {
                                              return _vm.openAudioUploadModal(
                                                commands.audio,
                                                commands.hard_break
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("a-icon", {
                                            attrs: { type: "audio" },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "a-tooltip",
                                    {
                                      attrs: {
                                        title: _vm.$t(_vm.prefix("file")),
                                      },
                                    },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "menubar__button",
                                          on: {
                                            click: function ($event) {
                                              return _vm.openFileUploadModal(
                                                commands.anchor,
                                                commands.hard_break
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("a-icon", {
                                            attrs: { type: "file" },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "a-tooltip",
                                    {
                                      attrs: {
                                        title: _vm.$t(_vm.prefix("table")),
                                      },
                                    },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "menubar__button",
                                          on: {
                                            click: function ($event) {
                                              return commands.createTable({
                                                rowsCount: 3,
                                                colsCount: 3,
                                                withHeaderRow: false,
                                              })
                                            },
                                          },
                                        },
                                        [
                                          _c("a-icon", {
                                            attrs: { type: "custom-table" },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  isActive.table()
                                    ? _vm._l(
                                        _vm.$options.CmdIcon.table,
                                        function (ref) {
                                          var icon = ref.icon
                                          var cmd = ref.cmd
                                          return _c(
                                            "a-tooltip",
                                            {
                                              key: cmd,
                                              attrs: {
                                                title: _vm.$t(_vm.prefix(cmd)),
                                              },
                                            },
                                            [
                                              _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "menubar__button",
                                                  on: { click: commands[cmd] },
                                                },
                                                [
                                                  _c("a-icon", {
                                                    attrs: { type: icon },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          )
                                        }
                                      )
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ]),
                      ]
                    },
                  },
                ],
                null,
                false,
                1594981518
              ),
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "search" },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchTerm,
                      expression: "searchTerm",
                    },
                  ],
                  attrs: {
                    placeholder: _vm.$t(_vm.prefix("search")),
                    type: "text",
                  },
                  domProps: { value: _vm.searchTerm },
                  on: {
                    keydown: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      $event.preventDefault()
                      return _vm.onSearch(_vm.editor.commands.find)
                    },
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.searchTerm = $event.target.value
                    },
                  },
                }),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.replaceWith,
                      expression: "replaceWith",
                    },
                  ],
                  attrs: {
                    placeholder: _vm.$t(_vm.prefix("replace")),
                    type: "text",
                  },
                  domProps: { value: _vm.replaceWith },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.replaceWith = $event.target.value
                    },
                  },
                }),
                _vm._v(" "),
                _c(
                  "a-button",
                  {
                    staticClass: "search-btn",
                    attrs: { size: "small" },
                    on: {
                      click: function ($event) {
                        return _vm.onSearch(_vm.editor.commands.find)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$t(_vm.prefix("search"))) +
                        "\n            "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "a-button",
                  {
                    staticClass: "search-btn",
                    attrs: { size: "small" },
                    on: {
                      click: function ($event) {
                        return _vm.onClear(_vm.editor.commands.clearSearch)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$t(_vm.prefix("clear"))) +
                        "\n            "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "a-button",
                  {
                    staticClass: "search-btn",
                    attrs: {
                      size: "small",
                      disabled: !_vm.searchTerm || !_vm.replaceWith,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.onReplace(
                          _vm.editor.commands.find,
                          _vm.editor.commands.replace
                        )
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$t(_vm.prefix("replace"))) +
                        "\n            "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "a-button",
                  {
                    staticClass: "search-btn",
                    attrs: {
                      size: "small",
                      disabled: !_vm.searchTerm || !_vm.replaceWith,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.onReplace(
                          _vm.editor.commands.find,
                          _vm.editor.commands.replaceAll
                        )
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$t(_vm.prefix("replaceAll"))) +
                        "\n            "
                    ),
                  ]
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("editor-content", {
        staticClass: "editor__content",
        attrs: { editor: _vm.editor },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }