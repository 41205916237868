<!-- @Author: xymo -->
<!-- @Date: 2023-07-19 -->
<!-- @Last Modified by: xymo -->
<!-- @Last Modified time: 2023-07-20 13:24:28 -->

<template>
    <div class="qa-display">
        <collapse
            v-for="question in renderQaList"
            :key="question.id"
            class="question-wrapper"
        >
            <template #title>
                <div class="title">
                    <div class="title-content">
                        <html-text :text="question.description" />

                        <a-tag
                            v-if="question.disabled"
                            :color="question.disabled === 1 ? 'orange' : ''"
                            class="ml10"
                        >
                            {{ question.disabled === 1 ? '未开始' : '已过期' }}
                        </a-tag>
                    </div>
                    <a-space>
                        <a-icon
                            v-if="$app.permissions.KNOWLEDGE_TEXT_STAR"
                            :style="{
                                color: question.collectTime ? 'red' : '',
                                fontSize: '18px'
                            }"
                            :theme="question.collectTime ? 'filled' : 'outlined'"
                            type="heart"
                            @click.stop="collect(question)"
                        />
                    </a-space>
                </div>
            </template>

            <collapse
                v-for="(answer, idx) in question.answers"
                :key="`question-${idx}`"
                default-expand
            >
                <template #title>
                    <span>
                        {{ `回答${idx + 1}` }}
                    </span>
                </template>
                <div class="answer">
                    <html-text :text="answer.answer" />
                </div>
            </collapse>

            <template #footer>
                <a-space class="footer">
                    <span>
                        <a-icon type="appstore" />
                        所属分类：

                        <span
                            v-for="category in question.questionCategoryIds"
                            :key="category"
                        >
                            [<def-value
                                :value="category"
                                :def="{enumType: $options.flattenedCategoryEnum}"
                            />]
                        </span>
                    </span>
                    <span>
                        <a-icon type="time-control" />
                        更新于：{{ question.updateTime | dateTime }}
                    </span>
                    <span>
                        <a-icon type="calendar" />
                        有效期：
                        <span v-if="question.disabled">
                            {{ question.disabled === 1 ? '未开始' : '已过期' }}
                        </span>
                        <span v-else>生效中</span>

                        <span
                            v-if="question.enableStartTime || question.enableEndTime"
                        >
                            ({{ getTime(question.enableStartTime, ' /') }} ~ {{ getTime(question.enableEndTime, '/ ') }})
                        </span>

                        <span v-else>(永久生效)</span>
                    </span>
                </a-space>
            </template>
        </collapse>
    </div>
</template>

<script type="text/babel">

import moment from 'moment';

import event from 'collection-admin-web/common/mixin/event';
import Resource from 'collection-admin-web/common/resource/knowledge';

import {flattenedCategoryEnum} from '../constant/enum/category';
import collapse from './collapse';
import htmlText from './html-text';

export default {
    name: 'QaDisplay',

    flattenedCategoryEnum,

    components: {
        htmlText,
        collapse
    },

    mixins: [event],

    props: {
        keywords: {
            type: String,
            default: ''
        },

        qaList: {
            type: Array,
            default: () => []
        },

        type: {
            type: String,
            default: 'ALL'
        }
    },

    data() {
        return {
            qaActiveKeys: []
        };
    },

    computed: {
        renderQaList() {
            const {qaList = [], addKeywords} = this;

            return qaList.map(({question, id, answerList = [], ...rest}) => {
                let newAnswer = [];

                newAnswer = answerList?.map(item => ({
                    ...item,
                    answer: addKeywords(item.answer)
                }));

                return {
                    description: addKeywords(question),
                    answers: newAnswer,
                    id,
                    ...rest
                };
            });
        }
    },

    methods: {
        getTime(time, str) {
            if (!time) return str;

            if ((this.$app?.timestamp ?? this.timestamp) !== false) {
                return moment(time).format('YYYY-MM-DD HH:mm:ss');
            }

            return time;
        },

        addKeywords(text) {
            const {keywords} = this;

            if (!keywords) return text;

            const keywordsArr = keywords.split(' ');
            const reg = new RegExp(keywordsArr.join('|'), 'g');

            return text && text.replace(
                reg,
                (replace, index, str) => {
                    const start = str?.lastIndexOf('<', index);
                    const end = str?.lastIndexOf('>', index);
                    if (end < start) return replace;

                    return `<span style="color: red">${replace}</span>`;
                }
            );
        },

        testKeywords(text, id) {
            const {keywords} = this;

            if (!keywords) return false;

            const keywordsArr = keywords.split(' ');
            const reg = new RegExp(keywordsArr.join('|'), 'g');

            if (reg.test(text)) {
                if (this.qaActiveKeys.indexOf(`q-${id}`) < 0) this.qaActiveKeys.push(`q-${id}`);

                return '1';
            }

            return '';
        },

        async collect({collectTime, id}) {
            if (collectTime) {
                this.uploadEvent({
                    key: 'KNOWLEDGE',
                    elementContent: `router-${this.type}-cancel-btn-collect-${id}`
                });

                await Resource.cancelCollect({questionIds: [id]});
                this.$message.success('取消收藏成功');
            } else {
                this.uploadEvent({
                    key: 'KNOWLEDGE',
                    elementContent: `btn-${this.type}-collect-${id}`
                });

                await Resource.collect({questionIds: [id]});
                this.$message.success('收藏成功');
            }

            this.$emit('collect');
        }
    }
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.qa-display {
    transition: none;

    .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        font-weight: bolder;

        .title-content {
            display: flex;
            align-items: center;
        }
    }

    .answer {
        background: #fff;
    }

    .footer {
        margin-top: 5px;
        padding-top: 5px;
        color: #a7b0c7;

        & > span {
            margin-left: 5px;

            &:nth-child(1) {
                margin-left: 0;
            }
        }
    }

    .ant-btn-link {
        color: rgba(0, 0, 0, 0.45);
        padding: 0 0 0 10px;
    }
}
</style>
