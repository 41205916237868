/**
 * @Author: giligili
 * @Date: 2021-07-08
 * @Last Modified by: yefenghan
 * @Last Modified time: 2024-04-10 16:56:08
 */

import InstalmentStatusMap from 'collection-admin-web/common/constant/maps/instalment-status';
import RepayTypeMap from 'collection-admin-web/common/constant/maps/repay-type';
import CaseAssignRecord from 'collection-admin-web/common/resource/case/assign-record';
import CaseDemandLetter from 'collection-admin-web/common/resource/case/demand-letter';
import CaseDetail from 'collection-admin-web/common/resource/case/detail';
import CaseRelative from 'collection-admin-web/common/resource/case/relative';
import Enum from 'collection-admin-web/common/util/enum';

export {default as CaseTagMap} from './case-tag-map';
export {default as AssistStatus} from './assist-status-map';
export {default as NotificationStatus} from './notification-status-map';

export const RepayType = Enum.from(RepayTypeMap);
export const RelativeType = Enum.from({
    CASE: '催收',
    REMIND_CASE: '催告'
});
export const InstalmentStatus = Enum.from(InstalmentStatusMap);
export const RiskRejectReason = Enum.query(CaseDetail.getRiskRejectReason, {
    queryOnce: true
});
export const DemandLetterProvider = Enum.query(CaseDemandLetter.listDemandLetterServiceProvider, {
    queryOnce: true,
    dataBodyField: 'providerList',
    textField: 'desc',
    valueField: 'name'
});
export const AssignUser = Enum.query(CaseAssignRecord.getAssignOptMap, {
    queryOnce: true,
    dataBodyField: 'collectorMap'
});
export const StopCollection = Enum.query(CaseRelative.getStopCollectionCacheSetTypeMap, {
    queryOnce: true
});
export const StopCollectionSms = Enum.query(CaseRelative.getStopCollectionMessageGroup, {
    queryOnce: true
});
// 雅安案件生成调解的状态
export const YaanMediateApply = Enum.from({
    INIT: '推送中',
    PUSHED: '已推送',
    GENERATE_SUCCESS: '申请成功',
    GENERATE_FAILED: '申请失败'
});
// 修复渠道
export const RepairChannel = Enum.from({
    CHINA_MOBILE: '移动',
    CHINA_UNICOM: '联通'
});
// 案件状态
export const Status = Enum.from({
    INIT: '未还款',
    PROCEEDING: '未还清',
    FINISHED: '已还清',
    INVALID: '已作废'
});
