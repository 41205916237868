/**
 * @author giligili
 * @date 2021-07-21
 * @email zhimingwang@yangqianguan.com
 * @desc form-options
 */

import {required} from 'collection-admin-web/common/constant/fields';

import TypeFieldMap from './type-fied-map';

export default ({type, label, field, fieldOptions = {}}) => ({
    layout: 'horizontal',
    fieldDefs: [
        required({
            field,
            label,
            ...(TypeFieldMap[type] || {type}),
            ...fieldOptions
        })
    ]
});
