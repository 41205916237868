import HubClient from '../hub/client';
import SessionQueue from './queue';
import Session from './session';
class SessionManage extends HubClient {
    constructor(options) {
        super(Object.assign(Object.assign({}, options), { name: SessionManage.clientName }));
        this.createSession = (data) => {
            const session = new Session(data, this.client);
            this.sessionQueue.push(session);
            session.addListener('SESSION_END', () => {
                this.sessionQueue.pop(session);
                session.removeListener('SESSION_END');
                session.destroy();
            });
        };
        this.init = () => {
            this.device.addListener('newRTCSession', this.createSession);
        };
        this.destroy = () => {
            this.device.removeListener('newRTCSession', this.createSession);
            this.sessionQueue.clear();
        };
        const { device } = options;
        this.device = device;
        this.sessionQueue = new SessionQueue();
        this.init();
        super.register();
    }
}
SessionManage.clientName = 'sessionClient';
export default SessionManage;
