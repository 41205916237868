/**
 * @Author: xymo
 * @Date: 2023-09-25
 * @Last Modified by: xymo
 * @Last Modified time: 2023-09-25 14:30:54
 */

import CollectionTypeMap from 'collection-admin-web/common/constant/enum/collection-type';

let id = 0;

export default {
    rowKey: () => (id += 1),
    pagination: false,
    colDefs: [
        {label: '明文手机号', field: 'phoneNum'},
        {label: '联系人类型', field: 'contactType', enumType: CollectionTypeMap}
    ]
};
