/**
 * @author panezhang
 * @date 2018/7/28-02:35
 * @file constant
 */

export const STATE = {
    INITIAL: 'INITIAL',
    UPLOADING: 'UPLOADING',
    SUCCESS: 'SUCCESS',
    FAILED: 'FAILED'
};

export const STATE_MAP = {
    [STATE.INITIAL]: '待上传',
    [STATE.UPLOADING]: '上传中',
    [STATE.SUCCESS]: '上传成功',
    [STATE.FAILED]: '上传失败'
};
