var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "yqg-antv-upload" },
    [
      _c(
        _vm.is,
        _vm._b(
          { key: _vm.key, tag: "component", on: { change: _vm.onChange } },
          "component",
          _vm.props,
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }