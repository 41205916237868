<!-- @author giligili -->
<!-- @email zhimingwang@fintopia.tech -->
<!-- @date 2021-12-20 11:55:50.741 -->
<!-- @desc generated by yqg-cli@5.2.0 -->

<template>
    <div
        ref="fileEl"
        class="header-file-list"
    >
        <a-popover
            title="文件列表"
            placement="leftBottom"
            trigger="click"
            :get-popup-container="getPopupContainer"
        >
            <a-icon
                class="header-file-list"
                type="file"
                @click="fetchFileList"
            />

            <template #content>
                <yqg-simple-table
                    :options="$options.TableOptions"
                    :records="records"
                >
                    <template #op="{record}">
                        <a-button
                            v-if="
                                record.downloadable === $options.BooleanType.TRUE
                                    && record.fileUrl
                            "
                            class="yqg-btn-info"
                            size="small"
                        >
                            <a
                                download
                                :href="record.fileUrl"
                            >下载</a>
                        </a-button>
                    </template>
                </yqg-simple-table>
            </template>
        </a-popover>
    </div>
</template>

<script type="text/babel">

    import {BooleanType} from 'collection-admin-web/common/constant/boolean';
    import CommonResource from 'collection-admin-web/common/resource/common';

    import TableOptions from './constant/table-options';

    export default {
        name: 'HeaderFileList',

        BooleanType,

        TableOptions,

        data() {
            return {
                records: []
            };
        },

        methods: {
            getPopupContainer() {
                return this.$refs.fileEl;
            },

            fetchFileList() {
                CommonResource.getFileList()
                    .then(({data: {body}}) => {
                        this.records = body?.fileList || [];
                    })
                    .catch(err => err);
            }
        }
    };
</script>
