/**
 * @Author: xiaodongyu
 * @Date: 2023-03-10 10:31:17
 * @Last Modified by: xiaodongyu
 * @Last Modified time: 2023-03-10 10:38:07
 */
export const numberWithCommas = x => {
    if (!x && x !== 0 && x !== '0')
        return '';
    const parts = x.toString().trim().split('.');
    parts[0] = parts[0] && parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
};
export const numberWithCommasFixed2 = x => ((x === +x) ? numberWithCommas((x).toFixed(2)) : x);
export const numberWithCommasFixed4 = x => ((x === +x) ? numberWithCommas((x).toFixed(4)) : x);
