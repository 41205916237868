/**
 * @author center
 * @date 2019/5/30-下午12:35
 * @file valid-options
 */

export default [
    {name: '账号', field: 'account', required: true},
    {name: '密码', field: 'password', required: true},
    {name: 'socket', field: 'socket', required: true},
    {name: 'uriHost', field: 'uriHost', required: true}
];
