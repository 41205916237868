/**
 * @Author: xymo
 * @Date: 2022-07-26
 * @Last Modified by: yefenghan
 * @Last Modified time: 2024-06-18 16:43:07
 */
const KEYS = {
    CALL_RECORDS_QUERY_COND: 'CALL_RECORDS_QUERY_COND',
    CALL_RECORDS_QUERY_COND_TOGGLE: 'CALL_RECORDS_QUERY_COND_TOGGLE',
    CALL_OUT_QUERY_COND: 'CALL_OUT_QUERY_COND',
    CASE_DETAIL_LIST_FLOW_QUERY_COND_CONNECTED: 'CASE_DETAIL_LIST_FLOW_QUERY_COND_CONNECTED',
    MEDIATION_SCHEMES: 'MEDIATION_SCHEMES',
    FLOW_MODAL_CONTROL: 'FLOW_MODAL_CONTROL',
    USER_INFO: 'USER_INFO' // 用户登录信息
};
const isLocalStorageAvailable = () => (typeof localStorage !== 'undefined');
const setJson = (key, payload) => {
    if (isLocalStorageAvailable()) {
        // flag: true代表该数据存在localStorage中
        localStorage.setItem(key, JSON.stringify({ payload, flag: true }));
    }
};
const getJson = (key, defaultVal = null) => {
    if (isLocalStorageAvailable()) {
        const item = JSON.parse(localStorage.getItem(key));
        // 如果该数据在localStorage中，返回payload,否则返回默认值
        if (item && item.flag) {
            return item.payload;
        }
    }
    return defaultVal;
};
export default {
    getCallRecordsQueryCond: () => getJson(KEYS.CALL_RECORDS_QUERY_COND),
    setCallRecordsQueryCond: (cond) => setJson(KEYS.CALL_RECORDS_QUERY_COND, cond),
    getCallRecordsQueryCondToggle: () => getJson(KEYS.CALL_RECORDS_QUERY_COND_TOGGLE),
    setCallRecordsQueryCondToggle: (toggle) => setJson(KEYS.CALL_RECORDS_QUERY_COND_TOGGLE, toggle),
    getCallOutQueryCond: () => getJson(KEYS.CALL_OUT_QUERY_COND),
    setCallOutQueryCond: (cond) => setJson(KEYS.CALL_OUT_QUERY_COND, cond),
    getCaseDetailListFlowQueryCondConnected: (defaultVal) => getJson(KEYS.CASE_DETAIL_LIST_FLOW_QUERY_COND_CONNECTED, defaultVal),
    setCaseDetailListFlowQueryCondConnected: (connected) => setJson(KEYS.CASE_DETAIL_LIST_FLOW_QUERY_COND_CONNECTED, connected),
    getMediationSchemes: (defaultVal) => getJson(KEYS.MEDIATION_SCHEMES, defaultVal),
    setMediationSchemes: (value) => setJson(KEYS.MEDIATION_SCHEMES, value),
    getFlowModalContro: (defaultVal) => getJson(KEYS.FLOW_MODAL_CONTROL, defaultVal),
    setFlowModalContro: (value) => setJson(KEYS.FLOW_MODAL_CONTROL, value),
    getUserInfo: () => getJson(KEYS.USER_INFO),
    setUserInfo: (value) => setJson(KEYS.USER_INFO, value),
    removeUserInfo: () => localStorage.removeItem(KEYS.USER_INFO)
};
