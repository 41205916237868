var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "alarm-assist-case-stop" }, [
    _vm._v("\n    你" + _vm._s(_vm.message) + "的协催任务\n\n    "),
    _c(
      "p",
      [_c("yqg-router-link", { attrs: { to: _vm.to, hash: _vm.hash } })],
      1
    ),
    _vm._v(
      "\n\n    已被 " +
        _vm._s(_vm.notification.data.extraData.operatorName) +
        " 中止 "
    ),
    _c(
      "a",
      {
        on: {
          click: function ($event) {
            return _vm.finishNotification(_vm.notification.data)
          },
        },
      },
      [_vm._v("我知道了")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }