var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "yqg-multi-input" },
    [
      _vm._l(_vm.values, function (val, idx) {
        return [
          _c(
            "div",
            { key: idx, staticClass: "input-wrap" },
            [
              _c("a-input", {
                attrs: { value: val },
                on: {
                  input: function (event) {
                    return _vm.changeValue(idx, event)
                  },
                },
              }),
              _vm._v(" "),
              _c("a-icon", {
                staticClass: "yqg-pointer ml10",
                attrs: { type: "minus" },
                on: {
                  click: function () {
                    return _vm.deleteValue(idx)
                  },
                },
              }),
            ],
            1
          ),
        ]
      }),
      _vm._v(" "),
      _vm.canAdd
        ? _c("a-icon", {
            staticClass: "yqg-pointer",
            attrs: { type: "plus" },
            on: { click: _vm.addValue },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }