<!-- @Author: xymo -->
<!-- @Date: 2021-04-26 13:34:48.347 -->
<!-- @Last Modified by: yefenghan -->
<!-- @Last Modified time: 2024-06-14 15:54:26 -->

<template>
    <a-select
        show-search
        option-filter-prop="children"
        :value="undefined"
        :options="options"
        placeholder="搜索"
        @select="onSelect"
    />
</template>

<script type="text/babel">
import {pinyin, customPinyin} from 'pinyin-pro';

export default {
    name: 'HeaderSearch',

    props: {
        routes: {
            type: Array,
            required: true
        }
    },

    computed: {
        options() {
            const options = [];
            this.getOptions(this.routes, options);

            return options;
        }
    },

    mounted() {
        customPinyin({
            还款: 'huán kuǎn',
        });
    },

    methods: {
        getOptions(routes, options) {
            const baseOption = {type: 'string', separator: '', toneType: 'none', v: true};
            routes.forEach(({name, path, meta: {navTitle, external, hideInSider}, subRoutes}) => {
                if (!hideInSider && !subRoutes) {
                    const shortcutStr = [
                        pinyin(navTitle, baseOption),
                        pinyin(navTitle, {...baseOption, pattern: 'first'}).toUpperCase(),
                        name
                    ].join(' ');
                    const label = `${navTitle} (${shortcutStr})`;
                    options.push({value: external ? path : name, label});
                }

                if (subRoutes && subRoutes.length) this.getOptions(subRoutes, options);
            });
        },

        onSelect(value) {
            const regex = /^(http|https):\/\/[^ "]+$/;
            if (regex.test(value)) {
                window.open(value, '_blank');
            } else {
                this.$router.push({name: value})
                    .catch(err => err);
            }
        }
    }
};
</script>
