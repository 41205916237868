export default function () {
    function baseParseInt(value) {
        return parseInt(value, 0);
    }

    return value => {
        let secondTime = baseParseInt(value);
        let minuteTime = 0;
        let hourTime = 0;
        if (secondTime > 60) {
            minuteTime = baseParseInt(secondTime / 60);
            secondTime = baseParseInt(secondTime % 60);
            if (minuteTime > 60) {
                hourTime = baseParseInt(minuteTime / 60);
                minuteTime = baseParseInt(minuteTime % 60);
            }
        }
        let result = `${baseParseInt(secondTime)}s`;

        if (minuteTime > 0) {
            result = `${baseParseInt(minuteTime)}min${result}`;
        }
        if (hourTime > 0) {
            result = `${baseParseInt(hourTime)}h${result}`;
        }

        return result;
    };
}
