/**
 * @author center
 * @date 2018/3/27-下午1:52
 * @file parse-ua
 */

import {isMobile, isAndroid, isIOS, isBubbleWebview, isBubbleEcWebview, isWebview} from '../../ToolFunction';

const ua = window.navigator.userAgent;

export default {
    isMobile: isMobile(ua),
    isAndroid: isAndroid(ua),
    isIOS: isIOS(ua),
    isBubbleWebview: isBubbleWebview(ua),
    isBubbleEcWebview: isBubbleEcWebview(ua),
    isWebview: isWebview(ua)
};
