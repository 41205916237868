/**
 * @author giligili
 * @date 2021-07-06
 * @email zhimingwang@yangqianguan.com
 * @desc 婚姻状态
 */

export default {
    10: '已婚',
    20: '未婚',
    30: '离异'
};
