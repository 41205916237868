<!-- @author weisun -->
<!-- @email weisun@yangqianguan.com -->
<!-- @date 2022/12/5 11:56 -->
<!-- @file yqg-static-comp-format -->

<script>

import {pickValue} from '@yqg/vue/antd/util/object';

export default {
    name: 'StaticCompFormat',

    functional: true,

    render(createElement, context) {
        const {def, record} = context.props;
        const originVal = pickValue(record, def.field);

        return createElement('div', def?.formatFunc ? def.formatFunc({value: originVal, record}) : originVal);
    }
};

</script>
