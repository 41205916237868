/**
 * @Author: giligili
 * @Date: 2021-06-07
 * @Last Modified by: xymo
 * @Last Modified time: 2023-06-07 14:04:18
 */
import { assign, customGet, customPost, customDelete } from '@yqg/resource';
const api = {
    urlPrefix: '/admin/operation/col/',
    getStaticTextTypeMap: customGet('getStaticTextTypeMap'),
    listStaticText: customGet('listStaticText'),
    createStaticText: customPost('createStaticText'),
    updateStaticText: customPost('updateStaticText'),
    deleteStaticText: customDelete('deleteStaticText'),
    getLatestStaticText: customGet('getLatestStaticText')
};
export default assign(api);
