import * as Sentry from '@sentry/vue';
const isOpen = (val) => {
    if (val === false) {
        return false;
    }
    if (!val) {
        return true;
    }
    return false;
};
export const fmpCustomReport = (data = {}, options) => {
    const isCustomReport = isOpen(options === null || options === void 0 ? void 0 : options.isCustomReport);
    const transaction = Sentry.getCurrentHub().getScope().getTransaction();
    // 创建一个span，然后在这个span中添加自定义逻辑
    if (transaction) {
        // 可以在这里添加自定义逻辑，例如添加自定义 span
        const span = transaction.startChild({
            op: 'custom_fmp',
            description: 'first screen time'
        });
        // 完成自定义 span
        span.finish(data.firstScreenTime + 1);
    }
    // 创建新的事务fmp，进行上报
    if (isCustomReport) {
        const transaction = Sentry.startTransaction({
            name: 'fmp'
        });
        // dev环境由于initSentry初始化给拦截了，导致transaction为undefined
        if (!transaction) {
            return;
        }
        transaction.setTag('tagName', 'fmp');
        transaction.setData('dataKey', data);
        transaction.setContext('dataKey', data);
        transaction.finish();
    }
};
function getFirstActionTime(spans) {
    const tempArr = [];
    let spanStartTime = null;
    spans.forEach((span) => {
        if (span.op === 'ui.action') {
            span.startTimestamp && tempArr.push(span.startTimestamp);
        }
        else {
            if (!spanStartTime) {
                spanStartTime = span.startTimestamp;
            }
            else {
                spanStartTime = span.startTimestamp < spanStartTime ? span.startTimestamp : spanStartTime;
            }
        }
    });
    tempArr.sort((a, b) => a - b);
    const firstActionTime = tempArr[0] || 0;
    const diffTime = firstActionTime - spanStartTime;
    return {
        spanStartTime,
        diffTime
    };
}
export const customBeforeSendTransaction = (fmpInstance) => {
    return (event) => {
        var _a, _b, _c, _d;
        // eslint-disable-next-line no-console
        console.log(performance.now(), '<----customBeforeSendTransaction');
        const logicalCondition = (event === null || event === void 0 ? void 0 : event.transaction) && (event === null || event === void 0 ? void 0 : event.transaction) !== 'fmp' && ((_b = (_a = event === null || event === void 0 ? void 0 : event.measurements) === null || _a === void 0 ? void 0 : _a.lcp) === null || _b === void 0 ? void 0 : _b.value);
        // eslint-disable-next-line no-console
        console.log(event === null || event === void 0 ? void 0 : event.transaction, '<----event?.transaction');
        // eslint-disable-next-line no-console
        console.log((_d = (_c = event === null || event === void 0 ? void 0 : event.measurements) === null || _c === void 0 ? void 0 : _c.lcp) === null || _d === void 0 ? void 0 : _d.value, '<----event?.measurements?.lcp?.value');
        // eslint-disable-next-line no-console
        console.log(logicalCondition, '<----logicalCondition');
        if (!logicalCondition || !fmpInstance) {
            return event;
        }
        return new Promise((resolve, reject) => {
            try {
                const waitFmpDone = () => {
                    const curTime = performance.now();
                    // eslint-disable-next-line no-console
                    console.log(fmpInstance, fmpInstance.calcFirstScreenTime, curTime, '<-----calcFirstScreenTime----curTime');
                    if (((fmpInstance === null || fmpInstance === void 0 ? void 0 : fmpInstance.calcFirstScreenTime) === 'done' && (fmpInstance === null || fmpInstance === void 0 ? void 0 : fmpInstance.fmpTime) > 0) || curTime > 16000) {
                        // 解决设定范围内有点击事件，导致fmp计算不准确的问题
                        const firstActionTimeObj = getFirstActionTime(event.spans);
                        let firstActionTime = firstActionTimeObj.diffTime;
                        firstActionTime = firstActionTime > 0 ? firstActionTime * 1000 : 0;
                        // eslint-disable-next-line no-console
                        console.log(firstActionTime, '<----firstActionTime');
                        const fmpTimeByActionTime = firstActionTime > 0 ? fmpInstance.getfirstScreenTimeByActionTime(firstActionTime) : 0;
                        // eslint-disable-next-line no-console
                        console.log(fmpTimeByActionTime, '<----fmpTimeByActionTime');
                        const fmpTime = fmpTimeByActionTime > 0 ? fmpTimeByActionTime : fmpInstance === null || fmpInstance === void 0 ? void 0 : fmpInstance.fmpTime;
                        const dataKey = fmpTimeByActionTime > 0 ? Object.assign(Object.assign({}, ((fmpInstance === null || fmpInstance === void 0 ? void 0 : fmpInstance.dataKey) || {})), { firstScreenTime: fmpTimeByActionTime, deadLineType: 5 }) : fmpInstance === null || fmpInstance === void 0 ? void 0 : fmpInstance.dataKey;
                        // eslint-disable-next-line no-console
                        console.log(fmpTime, '<----fmpTime');
                        // eslint-disable-next-line no-console
                        console.log(event, '<----event');
                        try {
                            let spans = event.spans || [];
                            if (fmpTime) {
                                spans.forEach((span) => {
                                    if (span.op === 'custom_fmp') {
                                        const spanStartTime = firstActionTimeObj.spanStartTime;
                                        const fmpMsToS = (fmpTime / 1000) || 0;
                                        span.startTimestamp = spanStartTime + fmpMsToS;
                                        // eslint-disable-next-line no-console
                                        console.log(span.startTimestamp, '<----fmp节点时间修改成功');
                                        span.endTimestamp = spanStartTime + fmpMsToS + 0.001;
                                    }
                                });
                            }
                            else {
                                spans = spans.filter((span) => span.op !== 'custom_fmp');
                            }
                        }
                        catch (error) {
                            // eslint-disable-next-line no-console
                            console.error(error, '<----fmp节点时间修改失败');
                        }
                        if (event === null || event === void 0 ? void 0 : event.measurements) {
                            event.measurements.firstScreenTime = fmpTime ? fmpTime : (curTime > 16000 ? 15001 : 0);
                        }
                        if (event === null || event === void 0 ? void 0 : event.contexts) {
                            event.contexts.dataKey = dataKey;
                        }
                        resolve(event);
                    }
                    else {
                        setTimeout(() => {
                            waitFmpDone();
                        }, 500);
                    }
                };
                waitFmpDone();
            }
            catch (error) {
                reject(error);
            }
        });
    };
};
