/*
 * Author: xymo
 * Created Date: 2021-04-13
 * email: xymo@yangqianguan.com
 */

import timezone from '@yqg/vue/antd/store/module/timezone';

import caseDetail from './case-detail';
import multiCall from './multi-call';
import notification from './notification';
import user from './user';

export default {
    user,
    timezone,
    multiCall,
    caseDetail,
    notification
};
