/**
 * @Author: xiaoduoshu
 * @Date: 2022-03-16 10:26:45
 * @Last Modified by: xymo
 * @Last Modified time: 2023-06-25 15:26:20
 */
import { assign, customGet } from '@yqg/resource';
const api = {
    urlPrefix: 'admin/operation/col/enterpriseWeChat/',
    listSymbol: customGet('listSymbol'),
    listSymbolByCollector: customGet('listSymbolByCollector')
};
export default assign(api);
