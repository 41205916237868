/*
 * Author: giligili
 * Created Date: 2021-05-18
 * email: zhimingwang@yangqianguan.com
 */

import Privilege from 'collection-admin-web/common/resource/privilege';
import Enum from 'collection-admin-web/common/util/enum';

export default Enum.query(Privilege.listAllRoleGroup, {dataBodyField: 'colRoleGroupList', valueField: 'name'});
