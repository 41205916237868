<!-- @author giligili -->
<!-- @email zhimingwang@yangqianguan.com -->
<!-- @date 2021-05-19 14:02:37.896 -->
<!--
    @desc generated by yqg-cli@4.0.7
    单纯利用a-tabs的ui
    why ?
    1. 因为a-tabs在切换tab的时候不会重新实例化组件, 这就造成不能利用mounted钩子, 要单独实现初始化数据的逻辑, 且还要监听activedkey

    2. 刷新保持当前tab需要额外实现

    实现
    利用vue-router提供的功能, 切换uri, 实现组件替换

    适合在整个页面都是一个Tab这种情况, 如果是某个layout的话, 还是使用a-tabs

    demo
    app/permission/privilege/privilege.vue
-->

<template>
    <a-tabs
        class="yqg-antv-uri-tabs"
        type="card"
        :active-key="activeRouterName"
        @tabClick="clickTab"
    >
        <a-tab-pane
            v-for="{name, label} in tabs"
            :key="name"
            :tab="label"
        />
    </a-tabs>
</template>

<script type="text/babel">
    export default {
        name: 'YqgAntvUriTabs',

        props: {
            /* @desc [{name: <RouterName>'', label: ''}] */
            tabs: {
                type: Array,
                required: true
            }
        },

        computed: {
            activeRouterName() {
                return this.$route.name;
            }
        },

        methods: {
            clickTab(name) {
                if (this.activeRouterName === name) return;

                this.$router.push({name});
            }
        }
    };

</script>
