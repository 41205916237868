/**
 * @Author: xymo
 * @Date: 2021-06-10
 * @Last Modified by: giligili
 * @Last Modified time: 2023-07-11 15:19:49
 */
// TODO: 重载?
export const numberWithCommas = x => {
    if (!x && x !== 0 && x !== '0')
        return '';
    const parts = x.toString().trim().split('.');
    parts[0] = parts[0] && parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
};
