var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-tabs",
    {
      staticClass: "yqg-antv-uri-tabs",
      attrs: { type: "card", "active-key": _vm.activeRouterName },
      on: { tabClick: _vm.clickTab },
    },
    _vm._l(_vm.tabs, function (ref) {
      var name = ref.name
      var label = ref.label
      return _c("a-tab-pane", { key: name, attrs: { tab: label } })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }