import { _JL as reportBuilder } from './jsnlog';
var Logger = reportBuilder.Logger;
var originalLog = Logger.prototype.log;
var setVersionInfo = function (logObject) {
    if (typeof window !== 'undefined') {
        var versionInfo = window.VERSION_INFO;
        if (versionInfo)
            return { versionInfo: versionInfo, original: logObject };
    }
    return logObject;
};
var warnExceptionNames = ['ChunkLoadError'];
var ignoreError = function (error) { return !error; };
Object.assign(Logger.prototype, {
    log: function (level, logObject, error) {
        var _a = error || {}, stack = _a.stack, isAxiosError = _a.isAxiosError, name = _a.name;
        if (this.loggerName === 'onerrorLogger' && !error) {
            return this;
        }
        if (error && (isAxiosError
            || !stack
            || !/\.js/i.test(stack)
            || (/https?/i.test(stack) && !new RegExp("".concat(location.hostname.replace(/\./g, '\\.'), "|yangqianguan\\.com|fintopia\\.tech"), 'i').test(stack))
            || ignoreError(error)))
            return this;
        return originalLog.apply(this, [name && warnExceptionNames.includes(name) ? reportBuilder.getWarnLevel() : level, setVersionInfo(logObject), error]);
    }
});
export var setIgnoreError = function (func) { return (ignoreError = func); };
export var addWarnExceptionName = function (exceptionName) { return warnExceptionNames.push(exceptionName); };
export { reportBuilder };
var YqgReporter = reportBuilder('YqgReporter');
function customReportMethod(level, logObjectOrErr, err) {
    if (logObjectOrErr instanceof Error) {
        return this.log(level, err, logObjectOrErr);
    }
    return this.log(level, logObjectOrErr, err);
}
var methods = ['info', 'warn', 'error', 'fatal'];
var customReporter;
try {
    customReporter = methods.reduce(function (reporter, method) {
        var levelMethod = "get".concat(method[0].toUpperCase()).concat(method.slice(1), "Level");
        reporter[method] = customReportMethod.bind(YqgReporter, reportBuilder[levelMethod]());
        return reporter;
    }, {});
    customReporter.fatalException = customReportMethod.bind(YqgReporter, reportBuilder.getFatalLevel());
    Object.assign(YqgReporter, customReporter);
}
catch (err) {
    YqgReporter.fatalException('custom report signature failed!', err);
}
export default YqgReporter;
