/*
 * @Author: xiaodongyu
 * @Date 2019-11-22 10:49:59
 * @Last Modified by: bowang
 * @Last Modified time: 2023-03-24 11:22:29
 */
import countToTime from './count-to-time';
import date from './date';
import markdown from './mardown-to-html';
import {numberWithCommas, numberWithCommasFixed2, numberWithCommasFixed4} from './number-with-commas';
import percent from './percent';
import phoneMask from './phone-mask';

export default {
    install(Vue) {
        Vue.filter('date', date());
        Vue.filter('dateTime', date('YYYY-MM-DD HH:mm:ss'));
        Vue.filter('time', date('HH:mm:ss'));
        Vue.filter('numberCommasFixed4', numberWithCommasFixed4);
        Vue.filter('numberCommasFixed2', numberWithCommasFixed2);
        Vue.filter('numberWithCommas', numberWithCommas);
        Vue.filter('phoneMask', phoneMask);
        Vue.filter('percent', percent);
        Vue.filter('countToTime', countToTime);
        Vue.filter('markdown', markdown);
    }
};
