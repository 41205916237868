var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "yqg-json-typed-editor" }, [
    _vm.level === 0
      ? _c("div", [
          _c(
            "button",
            {
              staticClass: "normal",
              on: {
                click: function ($event) {
                  return _vm.onAddProperty(_vm.prefix)
                },
              },
            },
            [
              _c("i", { staticClass: "b-icon-create" }),
              _vm._v(" 添加数据\n        "),
            ]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "normal",
              on: {
                click: function ($event) {
                  return _vm.expandAll()
                },
              },
            },
            [_vm._v("\n            全部展开\n        ")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "normal",
              on: {
                click: function ($event) {
                  return _vm.collapseAll()
                },
              },
            },
            [_vm._v("\n            全部折叠\n        ")]
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.value && _vm.value.length > 0
      ? _c(
          "div",
          { staticClass: "list", class: _vm.dragging ? "dragging" : "" },
          [
            _c(
              "draggable",
              {
                attrs: { list: _vm.value, handle: ".sortable-handle" },
                on: {
                  change: function ($event) {
                    return _vm.onChange()
                  },
                  start: function ($event) {
                    _vm.dragging = true
                  },
                  end: function ($event) {
                    _vm.dragging = false
                  },
                },
              },
              _vm._l(_vm.value, function (property, index) {
                return _c(
                  "div",
                  { key: property.key || index },
                  [
                    _c("typed-property", {
                      attrs: {
                        property: property,
                        prefix: _vm.prefix,
                        level: _vm.level,
                        index: index,
                        bucket: _vm.bucket,
                        dir: _vm.dir,
                        "root-dir": _vm.rootDir,
                      },
                      on: {
                        add: _vm.onAddProperty,
                        edit: _vm.onEditProperty,
                        clone: _vm.onCloneProperty,
                        remove: _vm.onRemoveProperty,
                        change: function ($event) {
                          return _vm.onChange()
                        },
                      },
                    }),
                  ],
                  1
                )
              }),
              0
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }