<!-- @author giligili -->
<!-- @email zhimingwang@yangqianguan.com -->
<!-- @date 2021-07-30 13:52:19.408 -->
<!-- @desc generated by yqg-cli@5.0.2 -->

<template>
    <div ref="rankEl">
        <a-popover
            v-model="visible"
            title="催回率排名"
            placement="leftBottom"
            trigger="click"
            :get-popup-container="() => $refs.rankEl"
        >
            <a-icon
                type="fall"
                @click="listCaseStatistics()"
            />

            <yqg-simple-table
                slot="content"
                :options="TableOptions"
                :records="records"
            />
        </a-popover>
    </div>
</template>

<script type="text/babel">

    import Common from 'collection-admin-web/common/resource/common';

    import TableOptions from './constant/table-options';

    export default {
        name: 'HeaderRank',

        data() {
            return {
                TableOptions,
                visible: false,
                records: null
            };
        },

        methods: {
            listCaseStatistics() {
                const vm = this;

                Common.listCaseStatistics().then(({data: {body: {collectorCaseStatisticsList = []}}}) => {
                    vm.records = collectorCaseStatisticsList.map((record, index) => ({
                        $$index: index + 1,
                        ...record
                    }));
                    vm.visible = true;
                });
            }
        }
    };

</script>
