import { Watermark } from 'watermark-js-plus';
// https://zhensherlock.github.io/watermark-js-plus/
export default class YqgWatermark {
    constructor(props) {
        this.options = props || {};
    }
    create() {
        this.watermark = new Watermark(Object.assign({ contentType: 'text', content: 'default watermark', width: 200, height: 190, rotate: 25, fontWeight: 'normal', fontSize: '15px', layout: 'grid', fontFamily: 'sans-serif', gridLayoutOptions: {
                rows: 2,
                cols: 2,
                gap: [10, 10],
                matrix: [
                    [1, 0],
                    [0, 1]
                ],
            }, monitorProtection: false, globalAlpha: 0.06 }, this.options));
        this.watermark.create();
    }
    destroy() {
        if (!this.watermark)
            return;
        this.watermark.destroy();
    }
}
