/**
 * @author KylesLight
 * @date 03/04/2018-3:05 PM
 * @file yqg-affix
 */

const AFFIX_CLASS_NAME = 'yqg-affix';
const DEFAULT_HEADER_HEIGHT = 54;

export default {
    inserted(el, {value}) {
        const rect = el.getBoundingClientRect();
        const offsetTop = parseInt(value, 10) || (rect.top - DEFAULT_HEADER_HEIGHT);

        el.classList.remove(AFFIX_CLASS_NAME);
        window.addEventListener('scroll', () => {
            const scrollTop = window.scrollY;

            if (scrollTop >= offsetTop) {
                el.classList.add(AFFIX_CLASS_NAME);

                return;
            }

            el.classList.remove(AFFIX_CLASS_NAME);
        });
    }
};
