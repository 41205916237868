<!-- @author panezhang -->
<!-- @email panezhang@yangqianguan.com -->
<!-- @date 2018-07-28 19:06:04.179 -->
<!-- @desc generated by yqg-cli@0.3.1 -->

<template>
    <div
        :class="{'full-screen': fullScreen}"
        class="yqg-md-editor"
    >
        <b-md-editor
            :value="value"
            :options="options"
            @change="onChange"
        />
    </div>
</template>

<script type="text/babel">

import ImageUploadModal from 'yqg-common/vue/modal/image-upload-modal';
import FileUploadModal from 'yqg-common/vue/modal/file-upload-modal';

export default {
    name: 'YqgMdEditor',

    model: {
        prop: 'value',
        event: 'change'
    },

    props: {
        value: {
            type: String,
            default: ''
        },

        actionList: {
            type: Array,
            default: () => ([])
        },

        disableUploadImage: {
            type: Boolean,
            default: false
        },

        disableUploadFile: {
            type: Boolean,
            default: false
        },

        override: {
            type: Boolean,
            default: false
        },

        bucket: null,

        dir: null,

        rootDir: null
    },

    data() {
        const vm = this;

        return {
            fullScreen: false,
            options: vm.getBMdEditorOptions(false)
        };
    },

    methods: {
        getBMdEditorOptions(fullScreen) {
            const vm = this;

            return {
                override: vm.override,

                actionList: [
                    ...(vm.disableUploadImage ? [] : [{
                        name: 'image',
                        icon: 'b-icon-image',
                        insert(selectionText) {
                            return vm.openImageUploadModal(selectionText);
                        }
                    }]),
                    ...(vm.disableUploadFile ? [] : [
                        {
                            name: 'file',
                            icon: 'b-icon-copy',
                            insert(selectionText) {
                                return vm.openFileUploadModal(selectionText);
                            }
                        }
                    ]),
                    ...vm.actionList,
                    {
                        name: 'expend',
                        icon: fullScreen ? 'b-icon-compress' : 'b-icon-expand',
                        insert(selectionText) {
                            vm.fullScreen = !vm.fullScreen;
                            vm.options = vm.getBMdEditorOptions(vm.fullScreen);

                            return selectionText;
                        }
                    }
                ]
            };
        },

        openImageUploadModal(selectionText) {
            const vm = this;
            const {bucket, dir, rootDir} = vm;

            return this.$modal
                .open(ImageUploadModal, {
                    bucket,
                    dir,
                    rootDir
                })
                .then(imgUrl => {
                    const description = selectionText || 'image-description';

                    return `![${description}](${imgUrl})`;
                });
        },

        openFileUploadModal(selectionText) {
            const vm = this;
            const {bucket, dir, rootDir} = vm;

            return this.$modal
                .open(FileUploadModal, {
                    bucket,
                    dir,
                    rootDir
                })
                .then(({description: fileDescription, url: fileUrl}) => {
                    const description = selectionText || fileDescription || 'file-description';
                    const suffix = fileUrl.slice(fileUrl.lastIndexOf('.'));
                    const attname = `?attname=${encodeURI(description)}${suffix}`;

                    return `[${description}](${fileUrl}${attname})`;
                });
        },

        onChange(text) {
            this.$emit('change', text);
        }
    }
};

</script>

<style lang="scss" rel="stylesheet/scss">

    @import "~base-ui/src/style/variables";

    .yqg-md-editor {
        &.full-screen {
            position: fixed;
            z-index: $z-index-dialog-wrapper;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            padding: 15px 25px;
            background-color: #fff;

            .b-md-editor .container-content {
                height: calc(100vh - 100px);
            }
        }

        img {
            max-width: 100%;
        }
    }

</style>
