var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "yqg-color-picker" }, [
    _c(
      "div",
      {
        ref: "palette",
        staticClass: "palette palette-transparent",
        style: _vm.paletteStyle,
        on: {
          mouseup: _vm.mouseUp,
          mousedown: _vm.mouseDown,
          mousemove: _vm.mouseMove,
          mouseleave: _vm.mouseLeave,
          click: function ($event) {
            return _vm.paletteClick($event)
          },
        },
      },
      [
        _c("span", {
          staticClass: "palette-selector",
          style: _vm.paletteSelectorStyle,
        }),
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "color-select" }, [
      _c("a", { staticClass: "color-preview", style: _vm.previewStyle }),
      _vm._v(" "),
      _c("div", { staticClass: "color-range" }, [
        _c("input", {
          staticClass: "hue",
          attrs: { min: "0", max: "360", type: "range" },
          domProps: { value: _vm.color.hsv.h },
          on: {
            input: function ($event) {
              return _vm.hueChange($event)
            },
          },
        }),
        _vm._v(" "),
        _c("input", {
          staticClass: "opacity opacity-transparent",
          style: _vm.opacityStyle,
          attrs: { min: "0", max: "1", step: "0.01", type: "range" },
          domProps: { value: _vm.color.a },
          on: {
            input: function ($event) {
              return _vm.opacityChange($event)
            },
          },
        }),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "color-input" },
      [
        _c("a-input", {
          attrs: { value: _vm.color.hexa },
          on: { input: _vm.hexaChange },
        }),
        _vm._v(" "),
        _c("a-tag", { staticClass: "color-model", attrs: { color: "cyan" } }, [
          _vm._v("\n            HEXA\n        "),
        ]),
      ],
      1
    ),
    _vm._v(" "),
    _vm.preset && _vm.preset.length
      ? _c(
          "div",
          { staticClass: "color-preset" },
          _vm._l(_vm.preset, function (val) {
            return _c("a-button", {
              key: val,
              staticClass: "preset-item",
              style: { background: val },
              attrs: { value: val },
              on: {
                click: function ($event) {
                  return _vm.hexaChange($event)
                },
              },
            })
          }),
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }