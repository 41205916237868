var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { EventEmitter } from 'eventemitter3';
import pinyinUtil from './vendor/pinyinjs';
var filterBody = function (res, filterFunc, options) {
    if (options === void 0) { options = {}; }
    var data = res.data;
    var dataBodyField = options.dataBodyField;
    var body = data.body;
    if (dataBodyField) {
        body = pickValue(body, dataBodyField);
    }
    body = body.filter(filterFunc);
    return __assign(__assign({}, res), { data: __assign(__assign({}, data), { body: body }) });
};
var getKeys = function (keyString) {
    if (keyString === void 0) { keyString = ''; }
    return keyString.replace(/]/g, '').split(/[[.]/);
};
function pickValue(obj, keyString) {
    var keys = getKeys(keyString);
    var value = obj;
    try {
        keys.forEach(function (key) { return (value = value[key]); });
    }
    catch (err) {
        value = undefined;
    }
    return value;
}
var isTextFieldValid = function (textField) { return (textField !== undefined && textField !== '' && textField !== null); };
var getItemText = function (item, value, _a) {
    var _b = _a.textField, textField = _b === void 0 ? 'name' : _b, _c = _a.textTpl, textTpl = _c === void 0 ? '' : _c;
    return (textTpl ? textTpl.replace(/\{{2}(\w+)\}{2}/g, function (match, sub1) { return (item[sub1] || ''); }) : item[textField]);
};
var getMapList = function (arr, options) {
    var map = {};
    var itemMap = {};
    var list = [];
    var _a = options.valueField, valueField = _a === void 0 ? 'id' : _a, onHandleRecord = options.onHandleRecord;
    arr.forEach(function (val) {
        if (typeof val === 'string') {
            var obj = { value: val, text: val };
            map[val] = val;
            itemMap[val] = obj;
            list.push(obj);
            return;
        }
        onHandleRecord === null || onHandleRecord === void 0 ? void 0 : onHandleRecord(val);
        var value = val[valueField];
        var text = getItemText(val, value, options);
        if (isTextFieldValid(text) && isTextFieldValid(value) && !map[value]) {
            var obj = __assign(__assign({}, val), { value: value, text: text });
            map[value] = text;
            itemMap[value] = obj;
            list.push(obj);
        }
    });
    return { map: map, itemMap: itemMap, list: list };
};
var defualtEnablePolyphone = function (text) { return text && text.length <= 5; };
var handlePinyin = function (list, options) {
    if (options === void 0) { options = {}; }
    return list.forEach(function (item) {
        var text = item.text;
        var getPinyinText = options.getPinyinText, getPinyinExtra = options.getPinyinExtra, _a = options.enablePolyphone, enablePolyphone = _a === void 0 ? defualtEnablePolyphone : _a;
        var pinyinText = (getPinyinText && getPinyinText(item)) || text;
        var firstLetter = pinyinUtil.getFirstLetter(pinyinText, enablePolyphone(pinyinText));
        var normal = pinyinUtil.getPinyin(pinyinText, '', false, enablePolyphone(pinyinText));
        var extra = getPinyinExtra && getPinyinExtra(item);
        item.pinyin = [].concat(firstLetter, normal, text, extra).join('\n');
    });
};
var handleDisabled = function (list, options) {
    if (options === void 0) { options = {}; }
    var _a = options.disabledValues, disabledValues = _a === void 0 ? [] : _a;
    if (!disabledValues.length)
        return;
    list.forEach(function (item) { return (item.disabled = disabledValues.includes(item.value)); });
};
var Enum = (function () {
    function Enum() {
        this.refresh = this.refresh.bind(this);
    }
    Enum.from = function (map, options) {
        if (options === void 0) { options = {}; }
        var yqgEnum = new Enum();
        yqgEnum.options = options;
        yqgEnum.extraMap = options.extraMap || {};
        yqgEnum.populate({ map: map });
        return yqgEnum;
    };
    Enum.fromArray = function (arr, options) {
        if (options === void 0) { options = {}; }
        var yqgEnum = new Enum();
        yqgEnum.options = options;
        yqgEnum.extraMap = options.extraMap || {};
        yqgEnum.populate(getMapList(arr, options));
        return yqgEnum;
    };
    Enum.query = function (fetch, options) {
        if (options === void 0) { options = {}; }
        var _a = options.dataBodyField, dataBodyField = _a === void 0 ? '' : _a, _b = options.queryOnce, queryOnce = _b === void 0 ? false : _b, _c = options.extraMap, extraMap = _c === void 0 ? {} : _c;
        var yqgEnum = new Enum();
        yqgEnum.options = options;
        yqgEnum.extraMap = extraMap;
        yqgEnum.populate({ map: {}, itemMap: {} });
        yqgEnum.ee = new EventEmitter();
        yqgEnum.query = function () {
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            if (queryOnce && yqgEnum.QUERY_PROMISE) {
                return yqgEnum.QUERY_PROMISE;
            }
            yqgEnum.QUERY_PROMISE = fetch.apply(void 0, args).then(function (resp) {
                var body = resp.data.body;
                if (!body)
                    return resp;
                if (dataBodyField) {
                    body = pickValue(body, dataBodyField);
                }
                var mapList = {};
                if (Array.isArray(body)) {
                    mapList = getMapList(body, options);
                }
                else {
                    mapList.map = body || {};
                }
                yqgEnum.populate(mapList);
                return resp;
            }).catch(function (err) {
                yqgEnum.QUERY_PROMISE = null;
                return Promise.reject(err);
            });
            return yqgEnum.QUERY_PROMISE;
        };
        return yqgEnum;
    };
    Enum.filter = function (superEnum, filterFn, options) {
        var filteredEnum = null;
        if ('query' in superEnum) {
            var superOptions_1 = superEnum.options;
            var dataBodyField = superOptions_1.dataBodyField, restOptions = __rest(superOptions_1, ["dataBodyField"]);
            filteredEnum = Enum.query(function () { return superEnum.query().then(function (res) { return filterBody(res, filterFn, superOptions_1); }); }, options || restOptions);
        }
        else {
            filteredEnum = Enum.from(filterFn(superEnum.MAP));
        }
        filteredEnum.superEnum = superEnum;
        return filteredEnum;
    };
    Enum.map = function (baseEnum, options) {
        var mappedEnum = Enum.query(baseEnum.query, options);
        mappedEnum.superEnum = baseEnum;
        return mappedEnum;
    };
    Enum.prototype.filter = function (filterFn, options) {
        return Enum.filter(this, filterFn, options);
    };
    Enum.prototype.map = function (options) {
        return Enum.map(this, options);
    };
    Enum.prototype.refresh = function () {
        var _a;
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var self = this;
        while (self) {
            if (self.QUERY_PROMISE)
                delete self.QUERY_PROMISE;
            self = self.superEnum;
        }
        self = this;
        while (self) {
            if (self.ee)
                (_a = self.ee).emit.apply(_a, __spreadArray(['refresh'], args, false));
            self = self.superEnum;
        }
    };
    Enum.prototype.onRefresh = function (handler, context) {
        if (this.ee)
            this.ee.on('refresh', handler, context);
    };
    Enum.prototype.offRefresh = function (handler, context) {
        if (this.ee)
            this.ee.off('refresh', handler, context);
    };
    Enum.prototype.populate = function (_a) {
        var _this = this;
        var map = _a.map, itemMap = _a.itemMap, list = _a.list;
        this.MAP = map;
        this.ITEM_MAP = itemMap;
        this.LIST = list || Object.keys(map).map(function (value) { return ({ value: value, text: map[value] }); });
        handlePinyin(this.LIST, this.options);
        handleDisabled(this.LIST, this.options);
        this.TYPE = {};
        Object.keys(map).forEach(function (value) { return (_this.TYPE[value] = value); });
    };
    Enum.prototype.getText = function (value) {
        var _a = this, MAP = _a.MAP, extraMap = _a.extraMap, options = _a.options, _b = _a.options, textField = _b.textField, textTpl = _b.textTpl;
        var text = MAP[value] || extraMap[value];
        var superEnum = this.superEnum;
        while (!text && superEnum) {
            var _c = superEnum.options, superTextField = _c.textField, superTextTpl = _c.textTpl;
            if (textField === superTextField && textTpl === superTextTpl) {
                text = superEnum.MAP[value];
            }
            else if (superEnum.ITEM_MAP[value]) {
                text = getItemText(superEnum.ITEM_MAP[value], value, options);
            }
            superEnum = superEnum.superEnum;
        }
        return text || value;
    };
    return Enum;
}());
export default Enum;
