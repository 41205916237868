<!-- @author huayizhang -->
<!-- @email huayizhang@yangqianguan.com -->
<!-- @date 2021-07-16 15:18:29.426 -->
<!-- @desc generated by yqg-cli@1.2.7 -->

<template>
    <img
        v-if="$app.isAuthorized($app.PermissionTypes.CALL.MULTI.DIAL)"
        class="yqg-add-multi-call-list"
        :src="Icon"
        @click="onAddPhone()"
    >
</template>

<script type="text/babel">

    import {mapActions} from 'vuex';

    import CallMulti from 'collection-admin-web/common/resource/call/multi';

    import Icon from './img/add-phone.svg';

    export default {
        name: 'YqgAddMultiCallList',

        props: {
            caseId: {
                type: [Number, String],
                default: ''
            },

            phoneBookId: {
                type: [Number, String],
                default: ''
            },

            encryptedMobileNumber: {
                type: String,
                default: ''
            },

            isRepairedMobile: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                Icon
            };
        },

        methods: {
            ...mapActions('multiCall', ['fetchMultiCallCount']),

            onAddPhone() {
                const vm = this;
                const {caseId, phoneBookId, isRepairedMobile, encryptedMobileNumber} = vm;

                CallMulti.addList({
                    caseId,
                    phoneBookId,
                    isRepairedMobile,
                    encryptedMobileNumber
                }).then(({data: {body}}) => {
                    if (body) {
                        vm.$app.opSuccess('添加至一键多呼列表');

                        this.fetchMultiCallCount();
                    }
                });
            }
        }
    };

</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .yqg-add-multi-call-list {
        height: 16px;
        cursor: pointer;
    }

</style>
