/*
 * Author: giligili
 * Created Date: 2021-05-20
 * email: zhimingwang@yangqianguan.com
 */

import GeneralConfigType from 'collection-admin-web/common/constant/types/general-config';

export default {
    [GeneralConfigType.JSON]: 'JSON',
    [GeneralConfigType.JSON_ARRAY]: 'JSON数组'
};
