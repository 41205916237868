var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("yqg-simple-form", {
    attrs: { title: "选择微信主体", options: _vm.FormOptions },
    on: { confirm: _vm.onConfirm, cancel: _vm.dismiss },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }