/**
 * @author center
 * @date 2019/10/17-下午7:42
 * @file event-bus
 */

import Vue from 'vue';

export const YTALK_MAKE_CALL = 'YTALK_MAKE_CALL';

export const YTALK_SELECT_SCENE = 'YTALK_SELECT_SCENE';

export const YTALK_SELECT_SCENE_END = 'YTALK_SELECT_SCENE_END';

export const YTALK_CALL_END = 'YTALK_CALL_END';

export const YTALK_WORKER_CALL_END = 'YTALK_WORKER_CALL_END';

export const YTALK_GET_CUSTOM_DATA_SUCCESS = 'YTALK_GET_CUSTOM_DATA_SUCCESS';

export const INSURANCE_REFRESH_USER_CALL_LOG = 'INSURANCE_REFRESH_USER_CALL_LOG';

export const YTALK_CAN_CALL_CHANGE = 'YTALK_CAN_CALL_CHANGE'; // yqg-call-center中的canCall变化

export const YQG_CALL_BTN_MOUNTED = 'YQG_CALL_BTN_MOUNTED'; // yqg-call-btn的mounted

export const YTALK_ADD_CALL_DETAIL = 'YTALK_ADD_CALL_DETAIL';

export const INSURANCE_EDIT_FOLLOW_UP_LOG_SUCCESS = 'INSURANCE_EDIT_FOLLOW_UP_LOG_SUCCESS'; // 添加完回访记录

export const YTALK_CALL_CENTER_LOGIN = 'YTALK_CALL_CENTER_LOGIN';

export default new Vue();
