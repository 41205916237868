var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-button-group",
    [
      _vm.needAll
        ? _c(
            "a-button",
            {
              attrs: {
                type: !_vm.active && _vm.active !== 0 ? "primary" : "default",
              },
              on: {
                click: function ($event) {
                  return _vm.onClick()
                },
              },
            },
            [_vm._v("\n        " + _vm._s(_vm.$t("common.all")) + "\n    ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.options, function (opt) {
        return _c(
          "a-button",
          {
            key: opt.value,
            attrs: { type: _vm.active === opt.value ? "primary" : "default" },
            on: {
              click: function ($event) {
                return _vm.onClick(opt.value)
              },
            },
          },
          [_vm._v("\n        " + _vm._s(opt.label) + "\n    ")]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }