/**
 * @Author: xymo
 * @Date: 2023-09-19
 * @Last Modified by: xymo
 * @Last Modified time: 2023-09-19 14:23:02
 */

import {mapGetters} from 'vuex';

import EVENTS from 'collection-admin-web/common/constant/event';
import Event from 'collection-admin-web/common/resource/event';

export default {
    computed: {
        ...mapGetters(['user']),
    },

    methods: {
        uploadEvent(info = {}, BRAND = 'CN') {
            const userId = this.user?.collectorId;

            if (!userId) return;

            const {
                key,
                elementType = 'Button',
                eventValue = '', /// 控件的值，例如输入框中用户输入的内容
                eventType = 'C', // C:点击,S: 单选框,I: App文本输入,T: Web文本输入,P: 页面访问,M: 手动埋点
                ...other
            } = info;

            const {
                businessEvent = '', // web上传事件直接就是business_code不需要做事件映射转换
                tag = ''
            } = EVENTS[key] || {};

            const {name, path, fullPath} = this.$route;
            const elementId = tag ? `${name}_${tag}` : name;

            const data = {
                userId,
                events: [{
                    eventType,
                    businessEvent,
                    eventValue,
                    componentId: `${elementId}:${BRAND}${path}`,
                    eventParams: {
                        activity: `${BRAND}:${fullPath}`,
                        elementType,
                        elementId,
                        platform: 'web',
                        ...other
                    },
                    eventTime: Date.now(),
                    platformType: 'WEB',
                    sequenceNumber: 10
                }]
            };

            Event.uploadEvents(data, {hideLoading: true});
        }
    }
};
