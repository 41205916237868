var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "b-click-outside",
          rawName: "v-b-click-outside",
          value: _vm.closeMenu,
          expression: "closeMenu",
        },
      ],
      staticClass: "yqg-nav-popover user-icon-wrap",
    },
    [
      _vm.showAvatar && _vm.workCardUrl
        ? _c("div", [
            _c("img", {
              staticClass: "work-card-avatar",
              attrs: { src: _vm.workCardUrl },
              on: { click: _vm.changeMenuOpen },
            }),
          ])
        : _c("i", {
            staticClass: "b-icon-user",
            on: { click: _vm.changeMenuOpen },
          }),
      _vm._v(" "),
      _vm.isMenuOpen
        ? _c("ul", { staticClass: "card drop-down-menu" }, [
            _c("div", { on: { click: _vm.closeMenu } }, [_vm._t("default")], 2),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }