/*
 * @Author: yefenghan
 * @Date: 2024-07-08 10:55:13
 * @Last Modified by: yefenghan
 * @Last Modified time: 2024-07-18 23:57:44
 */
import { assign, customGet, customPost } from '@yqg/resource';
const api = {
    urlPrefix: '/admin/operation/col/status/',
    /** @desc 查询工作状态 */
    listWorkStatus: customGet('listWorkStatus'),
    /** @desc 新建/编辑工作状态 */
    saveWorkStatus: customPost('saveWorkStatus'),
    /** @desc 删除工作状态 */
    deleteWorkStatus: customPost('deleteWorkStatus'),
    /** @desc 查询全部工作状态 */
    getAllWorkStatus: customGet('getAllWorkStatus'),
};
export default assign(api);
