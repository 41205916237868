/**
 * @author giligili
 * @date 2021-07-21
 * @email zhimingwang@yangqianguan.com
 */

import EnumAll from 'collection-admin-web/common/constant/enum';

export default {
    text: {
        type: 'text',
        disabled: true
    },
    textarea: {
        type: 'textarea'
    },
    collector: {
        type: 'select',
        enumType: EnumAll.Basic.Collector
    }
};
