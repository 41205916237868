/*
 * @Author: xiaodongyu
 * @Date 2020-04-03 21:22:02
 * @Last Modified by: wenxiujiang
 * @Last Modified time: 2022-11-01 13:30:08
 */

import {pickValue} from '../../util/object';
import zh from './zh-cn';

export default {
    install(Vue) {
        Vue.prototype.$t = key => pickValue(zh, key) || key;
    }
};
