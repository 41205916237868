import { HubClient } from '../hub';
class DeviceHubClient extends HubClient {
    constructor(options) {
        super(Object.assign(Object.assign({}, options), { name: DeviceHubClient.clientName }));
        this.destroy = () => {
            this.device = null;
        };
        const { device } = options;
        this.device = device;
        super.register();
    }
}
DeviceHubClient.clientName = 'deviceClient';
export default DeviceHubClient;
