/*
 * Author: xymo
 * Created Date: 2021-05-18
 * email: xymo@yangqianguan.com
 */

import Enum from 'collection-admin-web/common/util/enum';

import ContactType from '../types/call-contact';

export default Enum.from({
    [ContactType.SELF]: '本人',
    [ContactType.NEW]: '新建联系人',
    [ContactType.NEW_SELF]: '新建本人',
    [ContactType.IMMEDIATE]: '紧急联系人',
    [ContactType.CONTACT]: '其他',
    [ContactType.CALL_RECORD]: '其他',
    [ContactType.BOTH]: '其他',
    [ContactType.STRONG]: '其他',
    [ContactType.REPAIRED_SELF]: '修复本人'
});
