var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "yqg-stage-tag" },
    [
      _vm._t("default"),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "tag",
          style: { "background-color": _vm.stageConfig.color },
        },
        [_vm._v("\n        " + _vm._s(_vm.stageConfig.name) + "\n    ")]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }