<!-- @Author: giligili -->
<!-- @Date: 2023-03-10 15:00:44.235 -->
<!-- @Last Modified by: xymo -->
<!-- @Last Modified time: 2023-08-29 14:15:50 -->

<template>
    <div
        v-if="$app.permissions.MEDIATE_SCHEME_SAVE || $app.permissions.MEDIATE_SCHEME_QUERY"
        class="mediate-plan"
    >
        <p class="yqg-island-title">
            协商方案

            <a-button
                v-if="$app.permissions.MEDIATE_SCHEME_SAVE"
                size="small"
                type="primary"
                @click="openMediatePlanModal"
                v-text="'制定协商方案'"
            />
        </p>

        <div
            v-if="records && records.length"
            class="yqg-island"
        >
            <div class="yqg-panel-title">
                方案记录
            </div>

            <yqg-resize-observe @change="onYstResizeChange">
                <yqg-simple-table
                    ref="yst"
                    :records="records"
                    :options="$options.TableOptions"
                >
                    <template #imageUrls="{value}">
                        <div class="imageUrls">
                            <yqg-antv-upload
                                v-if="value"
                                :value="value"
                                :limit="Infinity"
                                :disabled="true"
                                ui="image"
                                list-type="picture-card"
                            />

                            <span v-else>/</span>
                        </div>
                    </template>

                    <template #op="{record}">
                        <a-button
                            size="small"
                            type="primary"
                            @click="viewMediatePlanModal(record)"
                            v-text="'查看'"
                        />

                        <a-button
                            v-if="$app.permissions.CASE_DETAIL_LIST_COUPON && record.isReduce === $app.BooleanType.TRUE"
                            size="small"
                            type="primary"
                            @click="viewReduceModal(record)"
                            v-text="'审批进度'"
                        />

                        <a-popconfirm
                            v-if="$app.permissions.MEDIATE_SCHEME_CANCEL && record.status !== 'INVALID' && record.status !== 'FINISH'"
                            title="确认作废当前方案嘛？"
                            @confirm="cancelMediationScheme"
                        >
                            <a-button
                                size="small"
                                type="danger"
                                v-text="'作废'"
                            />
                        </a-popconfirm>
                    </template>
                </yqg-simple-table>
            </yqg-resize-observe>
        </div>
    </div>
</template>

<script type="text/babel">

    import EnumAll from 'collection-admin-web/common/constant/enum';

    import bus, {OnRefreshMediateSchemeList, OnAddReduceSuccess} from 'src/common/constant/event-bus';
    import ystFixedColumnsAutoAlign from 'src/common/mixin/yst/fixed-columns-auto-align';
    import Mediate from 'src/common/resource/mediate';
    import Trial from 'src/common/resource/trial';

    import TableOptions from './constant/table-options';
    import EditMediatePlanModal from './modal/edit-mediate-plan';
    import ViewReduceModal from './modal/view-reduce';

    const BooleanType = EnumAll.Basic.Boolean.TYPE;

    export default {
        name: 'MediateTrial',

        TableOptions,

        mixins: [ystFixedColumnsAutoAlign],

        props: {
            caseId: {
                type: String,
                required: true
            },

            orderInfo: {
                type: Object,
                required: true
            }
        },

        data() {
            return {
                records: []
            };
        },

        computed: {
            params() {
                const {caseId, orderInfo: {orderNumber}} = this;

                return {caseId, orderId: orderNumber};
            }
        },

        mounted() {
            this.fetchMediationSchemes();
        },

        methods: {
            fetchMediationSchemes() {
                if (!this.$app.permissions.MEDIATE_SCHEME_QUERY) return;

                const {params} = this;

                Mediate.listMediationSchemes({params})
                    .then(({data: {body: {schemes = []}}}) => {
                        this.records = schemes;
                    })
                    .catch(err => err);
            },

            openMediatePlanModal() {
                const {caseId, orderId} = this.params;

                Mediate.supportCreateMediationScheme({orderId})
                    .then(({data: {body: {support = BooleanType.FLASE, errorMsg}}}) => {
                        if (support === BooleanType.FALSE) return this.$message.error(errorMsg || '不支持制定协商方案');

                        return this.$modal.open(EditMediatePlanModal, {caseId, orderId});
                    })
                    .then(this.fetchMediationSchemes)
                    .catch(err => err);
            },

            viewMediatePlanModal(mediateInfo) {
                const {caseId, orderId} = this.params;

                this.$modal.open(EditMediatePlanModal, {isEdit: false, caseId, orderId, mediateInfo})
                    .catch(err => err);
            },

            viewReduceModal(record) {
                const {caseTrialId} = record;

                Trial.getCaseTrialById({params: {caseTrialId}})
                    .then(({data: {body}}) => {
                        if (!body) return this.$message.error('未找到减免记录!');

                        return this.$modal.open(ViewReduceModal, {reduceInfo: body});
                    })
                    .catch(err => err);
            },

            cancelMediationScheme() {
                const {orderId} = this.params;

                Mediate.cancelMediationScheme({orderId})
                    .then(({data: {body}}) => {
                        if (!body) return this.$message.error('作废失败!');

                        this.$message.success('作废成功!');

                        this.fetchMediationSchemes();

                        bus.$emit(OnRefreshMediateSchemeList, {orderNumber: orderId});
                        bus.$emit(OnAddReduceSuccess);
                    })
                    .catch(err => err);
            }
        }
    };

</script>

<style lang="scss" scoped>
    .mediate-plan {
        .imageUrls {
            ::v-deep .ant-upload-list-picture-card-container {
                display: inline-block;
                width: 60px;
                height: 60px;
                float: none;
                margin-bottom: 0;

                .ant-upload-list-item {
                    width: 60px;
                    height: 60px;

                    .ant-upload-list-item-info::before {
                        left: 0;
                    }
                }
            }
        }
    }
</style>
