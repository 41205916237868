/*
 * @Author: huayizhang 
 * @Date: 2021-05-26 14:46:44 
 * @Last Modified by: huayizhang
 * @Last Modified time: 2021-06-23 17:49:25
 */

import Resource from '@yqg/resource';

const api = {
    getChildRoleTypeMap: {
        url: '/admin/operation/col/admin/getChildRoleTypeMap',
        method: 'get'
    },
    batchUpdateAdminUserRoleType: {
        url: '/admin/operation/col/admin/batchUpdateAdminUserRoleType',
        method: 'post'
    },
    getAreaMap: {
        url: '/admin/operation/col/admin/getAreaMap',
        method: 'get'
    }
};

export default Resource.create(api);
