var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.processFlowId && _vm.processFlowType
    ? _c(
        "a-button",
        {
          staticClass: "yqg-flow-id-link",
          attrs: { type: "link" },
          on: {
            click: function ($event) {
              return _vm.to()
            },
          },
        },
        [_vm._v("\n    " + _vm._s(_vm.text || _vm.processFlowId) + "\n")]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }