<!-- @author xinzhong -->
<!-- @email xinzhong@yangqianguan.com -->
<!-- @date 2018-11-02 10:46:39.359 -->
<!-- @desc generated by yqg-cli@0.3.6 -->

<template>
    <b-input
        v-model="filterValue"
        v-bind="$attrs"
    />
</template>

<script type="text/babel">

export default {
    name: 'YqgInput',

    props: {
        value: {
            type: [String, Number],
            default: null
        },

        filter: {
            type: Object,
            default: () => ({})
        }
    },

    computed: {
        filterValue: {
            get() {
                return (this.filter.filter && this.filter.filter(this.value)) || this.value;
            },

            set(value) {
                const reverseValue = (this.filter.reverseFilter && this.filter.reverseFilter(value)) || value;
                this.$emit('input', reverseValue);
            }
        }
    }
};

</script>
