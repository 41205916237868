var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showKeyboard
    ? _c(
        "div",
        { staticClass: "ytalk-keyboard" },
        _vm._l(_vm.keyNumbers, function (number) {
          return _c(
            "span",
            {
              key: number,
              staticClass: "keyboard-number",
              on: {
                click: function ($event) {
                  return _vm.$emit("change", number)
                },
              },
            },
            [
              _c("span", { staticClass: "number" }, [
                _vm._v("\n            " + _vm._s(number) + "\n        "),
              ]),
            ]
          )
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }