var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "yqg-call-quality-detail-link" }, [
    _vm.canLink
      ? _c(
          "a",
          {
            on: {
              click: function ($event) {
                return _vm.to(_vm.query)
              },
            },
          },
          [_vm._v("\n        " + _vm._s(_vm.text || _vm.qualityId) + "\n    ")]
        )
      : _vm.qualityId
      ? _c("span", [
          _vm._v("\n        " + _vm._s(_vm.text || _vm.qualityId) + "\n    "),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }