/*
 * @Author: huayizhang 
 * @Date: 2022-06-02 11:46:47 
 * @Last Modified by:   huayizhang 
 * @Last Modified time: 2022-06-02 11:46:47 
 */

export default millis => {
    const minutes = Math.floor(millis / 60000);
    const seconds = ((millis % 60000) / 1000).toFixed(0);

    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
};
