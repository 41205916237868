var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("yqg-simple-form", {
    attrs: { title: _vm.$t("common.uploadAudio"), options: _vm.formOptions },
    on: { cancel: _vm.dismiss, confirm: _vm.onConfirm },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }