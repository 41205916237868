var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "recommend-call-list" }, [
    _c(
      "div",
      { staticClass: "container mb20" },
      [
        _c("yqg-simple-form", {
          attrs: {
            options: _vm.$options.FormOptions,
            "confirm-label": "刷新列表",
          },
          on: { confirm: _vm.onRefresh },
        }),
        _vm._v(" "),
        _vm.recommendCaseList.length
          ? _c(
              "div",
              [
                _c("contact-list", {
                  attrs: { "data-source": _vm.recommendCaseList },
                  on: { change: _vm.rowSelection },
                }),
              ],
              1
            )
          : _c("a-empty"),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "footer" },
      [
        _vm.selectContactList.length
          ? _c("yqg-layout-multi-call-btn", {
              attrs: {
                "data-source": _vm.selectContactList,
                value: _vm.selectEncryptedMobileNumberList,
                "dial-type": _vm.$options.DialType.RECOMMEND_MULTI,
              },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }