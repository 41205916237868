/**
 * @author KylesLight
 * @date 05/01/2018-4:52 PM
 * @file filter-util
 */

const DatePresentationReg = /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}).(\d{3})/;
const DateFormat = 'YYYY-MM-DD';
const DateMinuteFormat = 'YYYY-MM-DD HH:mm';
const DateTimeFormat = 'YYYY-MM-DD HH:mm:ss';
const DateMonthFormat = 'YYYY-MM';
const MillisecondInMinute = 60 * 1e3;
export const MillisecondInDay = MillisecondInMinute * 60 * 24;

const isSafari = () => /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent);

const getTimestamp = time => {
    if (typeof time === typeof 0) return time;
    let dateTime = time;
    if (isSafari() && typeof time === 'string') dateTime = time.replace(/-/g, '/');

    if (!time || !isFinite(new Date(dateTime))) return null;

    return new Date(dateTime).getTime();
};

export const DayTypeList = ['日', '一', '二', '三', '四', '五', '六'];
export const MonthTypeList = [
    '一月',
    '二月',
    '三月',
    '四月',
    '五月',
    '六月',
    '七月',
    '八月',
    '九月',
    '十月',
    '十一月',
    '十二月'
];
export const DailyMillisecond = 24 * 60 * MillisecondInMinute;

export const RoundModeType = {
    Normal: 'normal',
    DayStart: 'dayStart',
    DayEnd: 'dayEnd'
};

export const getTimeComponent = time => {
    const timeStamp = getTimestamp(time);

    if (Number.isNaN(timeStamp)) return {};

    const offset = new Date().getTimezoneOffset() * MillisecondInMinute;
    const day = new Date(timeStamp - offset).getDay();
    const timePresentation = new Date(timeStamp - offset).toJSON();
    const [
        entity, // eslint-disable-line no-unused-vars
        year,
        month,
        date,
        hour,
        minute,
        second,
        millisecond
    ] = timePresentation.match(DatePresentationReg);

    return {
        year,
        month,
        date,
        hour,
        minute,
        second,
        millisecond,
        day
    };
};

export const getTimeDigitalComponent = timeStamp => {
    const {
        year,
        month,
        date,
        hour,
        minute,
        second,
        millisecond,
        day
    } = getTimeComponent(timeStamp);

    return {
        year: +year,
        month: +month,
        date: +date,
        hour: +hour,
        minute: +minute,
        second: +second,
        millisecond: +millisecond,
        day: +day
    };
};

export const genDateStringWithFormat = (timeStamp, format) => {
    const {
        year,
        month,
        date,
        hour,
        minute,
        second,
        millisecond
    } = getTimeComponent(timeStamp);

    return format
        .replace(/YYYY/g, () => year)
        .replace(/MM/g, () => month)
        .replace(/DD/g, () => date)
        .replace(/HH/g, () => hour)
        .replace(/mm/g, () => minute)
        .replace(/ss/g, () => second)
        .replace(/SSS/g, () => millisecond); // TODO: 增加 format 支持
};

export const getDate = time => {
    const timeStamp = getTimestamp(time);
    if (!timeStamp || Number.isNaN(timeStamp)) return time;

    return genDateStringWithFormat(timeStamp, DateFormat);
};

export const getDateMinute = time => {
    const timeStamp = getTimestamp(time);
    if (!timeStamp || Number.isNaN(timeStamp)) return time;

    return genDateStringWithFormat(timeStamp, DateMinuteFormat);
};

export const getDateTime = time => {
    const timeStamp = getTimestamp(time);
    if (!timeStamp || Number.isNaN(timeStamp)) return time;

    return genDateStringWithFormat(timeStamp, DateTimeFormat);
};

export const getDateMonth = time => {
    const timeStamp = getTimestamp(time);
    if (!timeStamp || Number.isNaN(timeStamp)) return time;

    return genDateStringWithFormat(timeStamp, DateMonthFormat);
};

export const isValidDateString = dateStr => {
    if (!/\d+-\d{2}-\d{2}/.test(dateStr)) return false;
    const date = new Date(dateStr);
    if (!date.getTime() && date.getTime() !== 0) return false;

    return date.toISOString().slice(0, 10) === dateStr;
};

export const roundTimestamp = (timestamp, type) => {
    const time = new Date(timestamp);
    const year = time.getFullYear();
    const month = time.getMonth();
    const date = time.getDate();

    switch (type) {
        case RoundModeType.DayEnd: {
            const roundMilliseconds = MillisecondInDay - 1;

            return (
                timestamp
                && new Date(year, month, date).getTime() + roundMilliseconds
            );
        }
        case RoundModeType.DayStart: {
            return timestamp && new Date(year, month, date).getTime();
        }
        default: {
            return timestamp;
        }
    }
};

export const roundTimeToMonthStart = time => {
    const date = new Date(time);
    const year = date.getFullYear();
    const month = date.getMonth();

    return time && new Date(year, month);
};

export const roundTimestampToMonthStart = time => {
    const date = roundTimeToMonthStart(time);

    return date && date.getTime();
};

export const roundTimeToMonthEnd = time => {
    const date = new Date(time);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;

    return time && new Date(year, month) - 1;
};

const now = new Date();
const year = now.getFullYear();
const month = now.getMonth();
const halfOfYear = now.getMonth() - 6;
const week = now.getDate() - 7;

export const curMonthString = genDateStringWithFormat(now, 'YYYY-MM');
export const curDateString = getDate(now);
export const curMonthStartDateString = getDate(new Date(year, month));
export const halfOfYearDateString = getDate(new Date(year, halfOfYear));
export const weeklyDate = getDate(new Date(year, month, week));

export const curDate = getDateTime(roundTimestamp(now, RoundModeType.DayEnd));
export const curDateStart = getDateTime(roundTimestamp(now, RoundModeType.DayStart));
export const curMonthStartDate = getDateTime(
    roundTimestamp(new Date(year, month), RoundModeType.DayStart)
);

// 以前每次对时间进行判断都得重写一遍，太蠢
export const isBeforeNow = date => (!date || getTimestamp(date) < Date.now());
export const isAfterNow = date => (!date || getTimestamp(date) > Date.now());
export const isBeginBeforeEnd = (begin, end) => (!begin || !end || (getTimestamp(begin) < getTimestamp(end)));
