/*
 * @Author: huayizhang 
 * @Date: 2021-07-14 11:49:53 
 * @Last Modified by:   huayizhang 
 * @Last Modified time: 2021-07-14 11:49:53 
 */

export default {
    SDK_TYPE_YQD: 'SDK_TYPE_YQD',
    SDK_TYPE_ZEBRA: 'SDK_TYPE_ZEBRA',
    SDK_TYPE_ZEBRA_OLD: 'SDK_TYPE_ZEBRA_OLD',
    SDK_TYPE_GOD_DUCK: 'SDK_TYPE_GOD_DUCK',
    SDK_TYPE_KOI: 'SDK_TYPE_KOI',
    SDK_TYPE_OPPO: 'SDK_TYPE_OPPO',
    SDK_TYPE_FENQILE: 'SDK_TYPE_FENQILE',
    SDK_TYPE_PPD: 'SDK_TYPE_PPD'
};
