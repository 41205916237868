/*
 * @Author: huayizhang 
 * @Date: 2021-08-02 14:13:26 
 * @Last Modified by: huayizhang
 * @Last Modified time: 2021-11-05 16:53:24
 */

export default {
    rowKey: '$$index',
    pagination: false,
    scroll: {x: 1150, y: 300},
    colDefs: [
        {label: '排名', field: '$$index'},
        {label: '催收员姓名', field: 'collectorName'},
        {label: '当月分配案件数量', field: 'caseCountAssignedMonth', filter: 'numberCommas'},
        {label: '当月分配案件金额', field: 'caseAmountAssignedMonth', filter: 'numberCommasFixed2'},
        {label: '当月还清案件数量', field: 'caseCountFinshedMonth', filter: 'numberCommas'},
        {label: '当月还清案件金额', field: 'caseAmountFinshedMonth', filter: 'numberCommasFixed2'},
        {label: '当日首催率百分比', field: 'colPercentTodayFirst'},
        {label: '当月阶段催回率百分比', field: 'colPercentMonthStep'}
    ]
};
