/**
 * @author giligili
 * @date 2021-08-19
 * @email zhimingwang@yangqianguan.com
 * @desc table-options
 */

import {merge} from 'collection-admin-web/common/constant/fields';

import * as Fields from './fields';

let id = 0;
export default ({labels, fields}) => ({
    rowKey: () => (id += 1),
    colDefs: fields.map((field, index) => merge(Fields[field], labels[index] ? {label: labels[index]} : {})) // eslint-disable-line
});
