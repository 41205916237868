/**
 * @author giligili
 * @date 2021-08-24
 * @email zhimingwang@yangqianguan.com
 * @desc enum-type 代替@yqg/antd/mixin/enum-type(这个mixin依赖于table和form的def 不灵活)
 */

import Enum from '@yqg/enum';

export default {
    model: {
        prop: 'value',
        event: 'change'
    },

    props: {
        enumType: {
            type: [String, Object],
            default: ''
        }
    },

    data() {
        return {
            map: {},
            list: [],
            wrappedEnumType: null
        };
    },

    computed: {
        options() {
            const {list} = this;

            return list.map(({text, value, pinyin}) => ({
                label: text,
                value: `${value}`,
                pinyin
            }));
        }
    },

    watch: {
        enumType() {
            this.initData();
        }
    },

    mounted() {
        this.initData();
        const {enumType} = this;
        if (enumType?.query) {
            enumType.onRefresh?.(this.initData);
        }
    },

    beforeDestroy() {
        const {enumType} = this;
        if (enumType?.query) {
            enumType.offRefresh?.(this.initData);
        }
    },

    methods: {
        async initData() {
            const {def} = this;
            let {enumType} = this;

            if (!enumType) {
                enumType = Enum.from({});

                return;
            }

            switch (true) {
                case enumType.constructor === Enum:
                    if (enumType.query) await enumType.query();

                    break;
                case enumType.constructor === Object:
                    enumType = Enum.from(enumType);
                    break;
                case typeof enumType === 'string':
                    if (this.$t(enumType).constructor === Array) {
                        enumType = Enum.fromArray(this.$t(enumType), def.enumOptions);
                    } else {
                        enumType = Enum.from(this.$t(enumType));
                    }

                    break;
                default: break;
            }

            this.wrappedEnumType = enumType;
            this.map = enumType.MAP;
            this.list = enumType.LIST;
        }
    }
};
