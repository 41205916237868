/**
 * @author giligili
 * @date 2021-07-26
 * @email zhimingwang@yangqianguan.com
 * @desc 分期还款状态
 */

export default {
    INIT: 'INIT',
    PROCEEDING: 'PROCEEDING',
    FINISHED: 'FINISHED',
    EXTENDED: 'EXTENDED'
};
