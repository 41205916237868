<!-- @author panezhang -->
<!-- @email panezhang@yangqianguan.com -->
<!-- @date 2018-07-28 20:05:38.661 -->
<!-- @desc generated by yqg-cli@0.3.1 -->

<template>
    <yqg-editor-container
        :value="innerValue"
        :options="CodeMirrorOptions"
        :bucket="bucket"
        :dir="dir"
        :root-dir="rootDir"
        @input="onChange"
        @ready="onCodeMirrorRef"
        @action-format="reformatJSON"
    >
        <span
            v-if="parse && dirty"
            slot="action-left"
            class="tip"
        >
            （格式有误）
        </span>
    </yqg-editor-container>
</template>

<script type="text/babel">

import {pureJSONString} from 'yqg-common/core/ToolFunction/ToolFunction';

import YqgEditorContainer from '../yqg-editor-container';

const DEFAULT_OPTIONS = { // TODO by panezhang 尝试加入 lint
    indentUnit: 2,
    mode: 'application/json',
    smartIndent: true,
    tabSize: 2
};

export default {
    name: 'YqgJsonEditor',

    components: {
        YqgEditorContainer
    },

    props: {
        value: {
            type: [String, Object, Array],
            default: ''
        },

        options: {
            type: Object,
            default: () => ({})
        },

        parse: { // 将 v-model 解析为 Object
            type: Boolean,
            default: false
        },

        bucket: null,

        dir: null,

        rootDir: null
    },

    data() {
        const {options} = this;

        return {
            CodeMirrorOptions: {
                ...DEFAULT_OPTIONS,
                ...options
            },

            innerValue: ''
        };
    },

    computed: {
        dirty() {
            const {parse, value, innerValue} = this;
            if (!parse) return false;

            return typeof value !== 'string' && innerValue !== JSON.stringify(value, null, 2);
        }
    },

    watch: {
        value: {
            immediate: true,
            handler(value) {
                try {
                    this.innerValue = typeof value !== 'string' ? JSON.stringify(value, null, 2) : value;
                } catch (err) {
                    console.error(err); // eslint-disable-line no-console
                }
            }
        }
    },

    methods: {
        emitChange(value) {
            this.$emit('input', value);
        },

        onChange(innerValue) {
            this.innerValue = innerValue;

            if (this.parse) {
                this.reformatJSON(innerValue, true);

                return;
            }

            this.emitChange(innerValue);
        },

        onCodeMirrorRef(cm) {
            this.cm = cm;
        },

        reformatJSON(innerValue, silent = false) {
            const vm = this;
            try {
                const parsed = JSON.parse(pureJSONString(innerValue));
                vm.emitChange(vm.parse ? parsed : JSON.stringify(parsed, null, 2));

                if (!silent) {
                    vm.$toast.success('Reformat 成功');
                }
            } catch (error) {
                if (!silent) {
                    console.error(error); // eslint-disable-line no-console
                    vm.$toast.error('Reformat 失败，请检查格式');
                }
            }
        }
    }
};

</script>

<style lang="scss" rel="stylesheet/scss" scoped>

    @import "~base-ui/src/style/variables";

    .tip {
        color: $red-light;
    }

</style>
