/**
 * @author zhangpeng
 * @date 17/3/7-下午11:23
 * @file StageType
 */

export default {
    dev: 'dev',

    test: 'test',
    feat: 'feat',
    prod: 'prod',

    testOverseas: 'test-overseas',
    featOverseas: 'feat-overseas',
    prodIndo: 'prod-indo',
    prodBrazil: 'prod-brazil',
    prodPhi: 'prod-phi',
    prodIndia: 'prod-india',

    testLocal: 'test-local',
    prodLocal: 'prod-local'
};
