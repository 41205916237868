<!-- @author giligili -->
<!-- @email zhimingwang@yangqianguan.com -->
<!-- @date 2021-08-19 17:01:47.775 -->
<!-- @desc generated by yqg-cli@5.1.1 -->

<template>
    <div class="collection-flows-table">
        <p
            v-if="title"
            class="yqg-island-title"
        >
            {{ title }}
        </p>

        <yqg-simple-table
            v-if="formatCollectionFlows.length"
            :options="TableOptions"
            :pagination="false"
            :records="formatCollectionFlows"
        >
            <template #result="{record: {timePromised}, value, def}">
                <yqg-table-cell
                    :def="def"
                    :value="value"
                >
                    <span v-if="timePromised">{{ timePromised | moment('YYYY-MM-DD') }}</span>
                </yqg-table-cell>
            </template>
        </yqg-simple-table>

        <p v-else>
            暂无数据
        </p>
    </div>
</template>

<script type="text/babel">

    import genTableOptions from './constant/table-options';

    export default {
        name: 'CollectionFlowsTable',

        props: {
            title: {
                type: String,
                default: ''
            },

            labels: {
                type: Array,
                default: () => ([])
            },

            fields: {
                type: Array,
                default: () => ['timeCreated', 'result', 'remark']
            },

            collectionFlows: {
                type: Array,
                default: () => []
            },

            flowNumber: {
                type: Number,
                default: undefined
            }
        },

        computed: {
            TableOptions() {
                const {labels, fields} = this;

                return genTableOptions({labels, fields});
            },

            formatCollectionFlows() {
                const {collectionFlows, flowNumber} = this;

                // 倒序
                const sortCollectionFlows = Array.from(collectionFlows).sort(
                    ({timeCreated}, {timeCreated: nextTimeCreated}) => (nextTimeCreated - timeCreated)
                );

                return sortCollectionFlows.slice(0, flowNumber);
            }
        }
    };
</script>
