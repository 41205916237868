var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "yqg-antv-upload-file-url" },
    [
      _vm.defaultFileList
        ? _c(
            "a-upload",
            _vm._b(
              {
                attrs: {
                  "custom-request": _vm.customRequest,
                  "default-file-list": _vm.defaultFileList,
                  "list-type": "text",
                  disabled: _vm.disabled,
                  remove: _vm.onRemove,
                },
                on: { change: _vm.onFileChange },
              },
              "a-upload",
              _vm.$attrs,
              false
            ),
            [
              _c(
                "div",
                {
                  staticClass: "upload-control",
                  class: { disabled: !_vm.canUpload },
                  on: { click: _vm.upload },
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.urls || "请上传文件") +
                      "\n        "
                  ),
                ]
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }