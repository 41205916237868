/**
 * @author giligili
 * @date 2021-08-27
 * @email zhimingwang@yangqianguan.com
 * @desc vue 全局插件
 */

import download from './download';

export default {
    install(Vue) {
        Object.assign(Vue.prototype, {
            $download: download
        });
    }
};
