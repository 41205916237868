import { render, staticRenderFns } from "./yqg-infinite-pagination.vue?vue&type=template&id=df7a57c4&scoped=true&"
import script from "./yqg-infinite-pagination.vue?vue&type=script&lang=js&"
export * from "./yqg-infinite-pagination.vue?vue&type=script&lang=js&"
import style0 from "./yqg-infinite-pagination.vue?vue&type=style&index=0&id=df7a57c4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "df7a57c4",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/jenkins/agent/workspace/fintopia-web7chz7/fintopia-web-ci/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('df7a57c4')) {
      api.createRecord('df7a57c4', component.options)
    } else {
      api.reload('df7a57c4', component.options)
    }
    module.hot.accept("./yqg-infinite-pagination.vue?vue&type=template&id=df7a57c4&scoped=true&", function () {
      api.rerender('df7a57c4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "packages/vue/antd/component/yqg-infinite-pagination.vue"
export default component.exports