var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "yqg-md-editor", class: { "full-screen": _vm.fullScreen } },
    [
      _c("md-editor", {
        staticClass: "md-editor",
        attrs: {
          value: _vm.value,
          "customized-style": { height: "100%", border: "1px solid #eee" },
          options: _vm.options,
          disabled: _vm.isDisabled,
        },
        on: { change: _vm.onChange },
      }),
      _vm._v(" "),
      _c("md-view", {
        staticClass: "md-view",
        attrs: { "md-text": _vm.value },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }