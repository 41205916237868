/*
 * @Author: ruiwang
 * @Date: 2020-09-07 17:29:02
 * @Last Modified by: xiaodongyu
 * @Last Modified time: 2024-05-14 15:03:03
 */

import Enum from '@yqg/enum';

export default {
    inject: {
        setCustomInitialValue: {
            default: null,
        },
    },

    model: {
        prop: 'value',
        event: 'change',
    },

    props: {
        def: {
            type: Object,
            required: true,
        },

        enumType: {
            type: [String, Object],
            default: '',
        },

        optionDisabled: {
            type: Function,
            default: undefined,
        },
    },

    data() {
        return {
            list: [],
            wrappedEnumType: null,
        };
    },

    computed: {
        options() {
            const {
                list,
                optionDisabled,
                def: {props: {optionLabelProp} = {}},
            } = this;

            return list.map(option => {
                const {text, value, pinyin, disabled} = option;

                return {
                    ...(optionLabelProp && {
                        [optionLabelProp]: option[optionLabelProp],
                    }),
                    label: text,
                    value: `${value}`,
                    pinyin,
                    disabled:
                        typeof optionDisabled === 'function'
                            ? optionDisabled?.(value, option)
                            : disabled,
                };
            });
        },
    },

    watch: {
        enumType() {
            this.initData();
        },
    },

    mounted() {
        this.initData();
        const {enumType} = this;
        if (enumType?.query) {
            enumType.onRefresh?.(this.initData);

            if (this.$i18n) {
                this.$watch('$i18n.locale', () => {
                    enumType.refresh?.();
                });
            }
        }
    },

    beforeDestroy() {
        const {enumType} = this;
        if (enumType?.query) {
            enumType.offRefresh?.(this.initData);
        }
    },

    methods: {
        async initData(searchValue) {
            const {def} = this;
            let {enumType} = this;
            let forceUpdate = false;
            if (!enumType) {
                enumType = Enum.from({});

                return;
            }

            switch (true) {
                case typeof enumType.populate === 'function':
                    if (enumType.query) {
                        await enumType.query(searchValue);
                        forceUpdate = true;
                    }

                    break;
                case enumType.constructor === Object:
                    enumType = Enum.from(enumType);
                    break;
                case typeof enumType === 'string':
                    if (this.$t(enumType).constructor === Array) {
                        enumType = Enum.fromArray(
                            this.$t(enumType),
                            def.enumOptions
                        );
                    } else {
                        enumType = Enum.from(this.$t(enumType));
                    }

                    break;
                default:
                    break;
            }

            this.wrappedEnumType = enumType;
            this.list = enumType.LIST;
            if (!this.value && def.defaultFirstItem) {
                let value;
                if (typeof def.defaultFirstItem === 'function') {
                    value = def.defaultFirstItem(enumType);
                } else if (enumType.LIST[0] !== undefined) {
                    [{value}] = enumType.LIST;
                }

                const initialValue = {[def.field]: value};
                this.setCustomInitialValue?.(initialValue, forceUpdate);
            }
        },
    },
};
