/**
 * @author giligili
 * @date 2021-07-26
 * @email zhimingwang@yangqianguan.com
 * @desc 分期还款状态
 */

import InstalmentStatusType from 'collection-admin-web/common/constant/types/instalment-status';

export default {
    [InstalmentStatusType.INIT]: '未还款',
    [InstalmentStatusType.PROCEEDING]: '未还款',
    [InstalmentStatusType.FINISHED]: '已结清',
    [InstalmentStatusType.EXTENDED]: '展期作废'
};
