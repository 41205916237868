<!-- @Author: xymo -->
<!-- @Date: 2023-06-25 13:46:52 -->
<!-- @Last Modified by: xymo -->
<!-- @Last Modified time: 2023-06-25 13:46:52 -->

<template>
    <div class="application">
        <a-tabs
            :active-key="tabKey"
            type="card"
            @change="onChange"
        >
            <a-tab-pane
                v-for="{type, description} in tabList"
                :key="type"
                :tab="description"
            >
                <application-content
                    v-if="tabKey === type"
                    :type="type"
                />
            </a-tab-pane>
        </a-tabs>
    </div>
</template>

<script type="text/babel">

    import event from 'collection-admin-web/common/mixin/event';

    import applicationContent from './component/application-content';

    const tabList = [
        {
            type: 'ALL',
            description: '知识库'
        },
        {
            type: 'COLLECTED',
            description: '我的收藏'
        }
    ];

    export default {
        name: 'Application',

        components: {
            applicationContent
        },

        mixins: [event],

        data() {
            return {
                tabList,
                tabKey: 'ALL',
            };
        },

        mounted() {
            this.uploadEvent({
                key: 'KNOWLEDGE',
                elementContent: `router-${this.key}`
            });
        },

        methods: {
            onChange(key) {
                this.tabKey = key;
                this.uploadEvent({
                    key: 'KNOWLEDGE',
                    elementContent: `router-${key}`
                });
            }
        }
    };

</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .application {
        color: red;
    }

</style>
