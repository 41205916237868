/*
 * @Author: huayizhang 
 * @Date: 2021-10-22 12:57:00 
 * @Last Modified by: huayizhang
 * @Last Modified time: 2021-10-22 13:53:52
 */

export default {
    layout: 'horizontal',
    fieldDefs: [
        {
            label: '券金额', field: 'reduceAmount', type: 'number', required: true,
            rules: [{validator: ({ctx, value}) => {
                const isValid = (
                    Number.isInteger(value)
                    && value > 1
                    && value <= ctx.couponInfo.maxCouponAmount
                );

                return isValid
                    ? ''
                    : '输入金额必须为大于1，小于等于发券上限的正整数';
            }}]
        }
    ]
};
