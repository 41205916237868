/**
 * @Author: xymo
 * @Date: 2021-04-13
 * @Last Modified by: giligili
 * @Last Modified time: 2023-07-12 16:39:57
 */
import _ from 'underscore';
export const isMobile = /mobile/i.test(navigator.userAgent);
/* @desc 生成field */
// TODO 没用了
export const genField = (label, field, type, extraProps = {}) => (Object.assign({ label,
    field,
    type }, extraProps));
/* @desc 定义 */
export const isDef = (val) => val !== undefined && val !== null;
/* @desc 非空 */
export const notNull = (val) => val !== undefined && val !== null && val !== '';
/* @desc 删除空串的属性和删除空串数组的属性 */
// TODO
export const deleteEmptyString = (value) => {
    const loop = (temp) => {
        if (_.isArray(temp)) {
            temp.forEach(item => loop(item));
        }
        else if (_.isObject(temp)) {
            Object.keys(temp).forEach(key => {
                if (_.isString(temp[key])) {
                    if (!temp[key])
                        delete temp[key];
                }
                else if (_.isArray(temp[key]) && temp[key].every(item => (item === ''))) {
                    delete temp[key];
                }
                else {
                    loop(temp[key]);
                }
            });
        }
    };
    if (_.isArray(value)) {
        value.forEach(item => loop(item));
    }
    else if (_.isObject(value)) {
        loop(value);
    }
    return value;
};
/* @desc 是不是jsonString */
export const isJsonString = (val) => {
    let ret = val
        && typeof val === 'string'
        && (val.charAt(0) === '{' || val.charAt(0) === '[');
    try {
        JSON.parse(val);
    }
    catch (err) {
        ret = false;
    }
    return ret;
};
