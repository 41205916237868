var render, staticRenderFns
import script from "./def-value.vue?vue&type=script&lang=js&"
export * from "./def-value.vue?vue&type=script&lang=js&"
import style0 from "./def-value.vue?vue&type=style&index=0&id=55c839dc&lang=scss&rel=stylesheet%2Fscss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55c839dc",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/jenkins/agent/workspace/fintopia-web7chz7/fintopia-web-ci/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('55c839dc')) {
      api.createRecord('55c839dc', component.options)
    } else {
      api.reload('55c839dc', component.options)
    }
    
  }
}
component.options.__file = "packages/vue/antd/component/def-value.vue"
export default component.exports