class HubService {
    constructor() {
        this.clientMap = {};
        this.getClientCallbacks = [];
    }
    register(name, client) {
        this.clientMap[name] = client;
        this.getClientCallbacks.forEach(({ name: clientName, callback }) => {
            if (clientName !== name)
                return;
            this.clientResponse(clientName, callback);
        });
    }
    clientResponse(name, callback) {
        const client = this.clientMap[name];
        if (!client)
            return false;
        callback(client);
        return true;
    }
    getClient(name, callback) {
        if (this.clientResponse(name, callback))
            return;
        this.getClientCallbacks.push({
            name,
            callback,
        });
    }
}
export default HubService;
