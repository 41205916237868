<!-- @author giligili -->
<!-- @email zhimingwang@yangqianguan.com -->
<!-- @date 2021-05-25 15:20:24.221 -->
<!-- @desc generated by yqg-cli@4.0.7 -->

<script type="text/babel">
    import SelectLayout from '../select';

    export default {
        name: 'MultiSelectLayout',

        extends: SelectLayout,

        props: {
            multi: {
                type: Boolean,
                default: true
            },

            value: {
                type: [Array, String, Number],
                default: () => []
            }
        },

        mounted() {
            this.$emit('change', this.value);
        }
    };
</script>
