/**
 * @author giligili
 * @date 2022-04-01
 * @email zhimingwang@yangqianguan.com
 * @desc 一些校验规则
 */
function getMsg({maxAmount = Infinity, label = '金额', value} = {}) {
    maxAmount = maxAmount || 0; // eslint-disable-line

    // 除了0的空值
    if (value !== 0 && !value) {
        return `请输入${label}`;
    }

    value = Number(value); // eslint-disable-line

    if (typeof value !== 'number' || isNaN(value)) {
        return `${label}应该是数字`;
    }

    if (/\.\d{3,}/.test(value)) {
        return `${label}最多2位小数`;
    }

    if (value < 0) {
        return `${label}应该不小于0`;
    }

    if (value > maxAmount) {
        return `${label}应该不大于${maxAmount}`;
    }

    return undefined;
}

export const validatorAmount = ({maxAmount = Infinity, label = '金额'} = {}) => (_, value, callback) => {
    const msg = getMsg({maxAmount, label, value});

    if (callback) {
        callback(msg);
    }

    return msg;
};
