/*
 * @Author: kaiwang 
 * @Date: 2019-07-22 10:40:30 
 * @Last Modified by: kaiwang
 * @Last Modified time: 2019-07-22 10:41:57
 */

export default {
    OUTGOING: 'outgoing',
    INCOMING: 'incoming'
};
