/*
 * Author: giligili
 * Created Date: 2021-05-13
 * email: zhimingwang@yangqianguan.com
 * desc: 账号审批的map
 */

import AccountReviewType from '../types/account-review';

export default {
    [AccountReviewType.PENDING]: '处理中',
    [AccountReviewType.ACCEPTED]: '同意',
    [AccountReviewType.REJECTED]: '拒绝'
};
