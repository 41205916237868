var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "application-content" },
    [
      _c("yqg-simple-form", {
        attrs: {
          options: _vm.$options.QueryOptions,
          "confirm-label": "搜索",
          values: _vm.defaultCond,
        },
        on: { reset: _vm.onReset, confirm: _vm.onConfirm },
        scopedSlots: _vm._u([
          {
            key: "sort",
            fn: function () {
              return [
                _c("sort-button-group", {
                  ref: "sortButtonGroup",
                  on: { change: _vm.handleSortChange },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "a-spin",
            { attrs: { spinning: _vm.isLoading } },
            [
              _vm.qaList.length
                ? _c("qa-display", {
                    attrs: {
                      type: _vm.type,
                      keywords: _vm.keywords,
                      "qa-list": _vm.qaList,
                    },
                    on: {
                      collect: function ($event) {
                        return _vm.fetchQa(_vm.pagination)
                      },
                    },
                  })
                : [
                    !_vm.isLoading
                      ? _c("a-empty", { staticClass: "mt40" })
                      : _c("div", { staticStyle: { height: "100px" } }),
                  ],
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.pagination.total
        ? _c("yqg-pagination", {
            staticClass: "mt10",
            attrs: { pagination: _vm.pagination },
            on: { change: _vm.fetchQa },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }