var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-dialog",
    {
      attrs: {
        open: _vm.visible,
        title: _vm.title,
        "close-on-click-outside": false,
        "dialog-class": "lg",
      },
      on: { close: _vm.cancel },
    },
    [
      _vm.editing
        ? _c(
            "div",
            { staticClass: "b-form form-block" },
            [
              _c(
                "b-form-group",
                { attrs: { label: "类型" } },
                [
                  _c("b-select", {
                    attrs: { map: _vm.TYPE_MAP },
                    on: {
                      change: function ($event) {
                        return _vm.onTypeChange()
                      },
                    },
                    model: {
                      value: _vm.editing.type,
                      callback: function ($$v) {
                        _vm.$set(_vm.editing, "type", $$v)
                      },
                      expression: "editing.type",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.editing.type && _vm.parentProperty.type !== _vm.TYPE.LIST
                ? _c(
                    "b-form-group",
                    { attrs: { label: "Key" } },
                    [
                      _c(
                        "b-input",
                        {
                          model: {
                            value: _vm.editing.key,
                            callback: function ($$v) {
                              _vm.$set(_vm.editing, "key", $$v)
                            },
                            expression: "editing.key",
                          },
                        },
                        [
                          _vm.prefix
                            ? _c(
                                "span",
                                {
                                  staticClass: "b-input-slot-left",
                                  attrs: { slot: "left" },
                                  slot: "left",
                                },
                                [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(_vm.prefix) +
                                      ".\n                "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.valueEditor && _vm.valueEditor.name
                ? _c(
                    "b-form-group",
                    { attrs: { label: "" } },
                    [
                      _c(
                        _vm.valueEditor.name,
                        _vm._b(
                          {
                            tag: "component",
                            model: {
                              value: _vm.editing.value,
                              callback: function ($$v) {
                                _vm.$set(_vm.editing, "value", $$v)
                              },
                              expression: "editing.value",
                            },
                          },
                          "component",
                          _vm.valueEditor.props,
                          false
                        )
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "simple lg",
          attrs: { slot: "actions" },
          on: {
            click: function ($event) {
              return _vm.cancel()
            },
          },
          slot: "actions",
        },
        [_vm._v("\n        取消\n    ")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "simple lg",
          attrs: { slot: "actions" },
          on: {
            click: function ($event) {
              return _vm.confirm()
            },
          },
          slot: "actions",
        },
        [_vm._v("\n        确定\n    ")]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }