/*
 * Author: giligili
 * Created Date: 2021-05-20
 * email: zhimingwang@yangqianguan.com
 */

export default {
    STR: 'STR', // string
    JSON: 'JSON', // json
    FILE: 'FILE', // file upload
    SELECT: 'SELECT', // select 前端定义
    STR_ARRAY: 'STR_ARRAY', // stringArray
    JSON_ARRAY: 'JSON_ARRAY', // jsonArray
    MULTI_SELECT: 'MULTI_SELECT', // multiSelect 前端定义
    BOOLEAN_TYPE: 'BOOLEAN_TYPE' // BooleanType
};
