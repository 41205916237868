/*
 * @Author: chengyuzhang
 * @Date: 2021-04-07 15:27:57
 * @Last Modified by: yuhaoyang
 * @Last Modified time: 2023-05-23 12:06:16
 */

import Vue from 'vue';
import {mapGetters} from 'vuex';

import Enum from '@yqg/enum';

import DefType from '../constant/def-type';

const getText = ({map, enumType, value, seperator = '\n'}) => {
    if (value === null || value === undefined) return value;

    if (value.constructor === Array) {
        return value.map(val => (enumType?.getText(val) || map?.[val] || val)).join(seperator);
    }

    return enumType?.getText(value) || map?.[value] || value;
};

const getTreeNodeLabel = (value, tree, idKey = 'value', nameKey = 'name') => {
    let str;
    for (const node of tree) {
        if (node[idKey] === value) {
            str = node[nameKey];
            break;
        }

        if (node.children?.length) {
            const tmp = getTreeNodeLabel(value, node.children, idKey, nameKey);
            if (tmp) {
                str = tmp;
                break;
            }
        }
    }

    return str;
};

const getTreeText = ({enumType, value, seperator = '\n', idKey, nameKey}) => {
    if (value === null || value === undefined) return value;
    const tree = enumType.LIST;
    if (value.constructor === Array) {
        return value.map(val => getTreeNodeLabel(val, tree, idKey, nameKey)).join(seperator);
    }

    return getTreeNodeLabel(value, tree, idKey, nameKey) || value;
};

export default {
    inject: {
        timestamp: {
            default: undefined
        }
    },

    computed: {
        ...mapGetters(['timezone']),

        isTimestamp() {
            return this.def.timestamp ?? ((this.$app?.timestamp ?? this.timestamp) !== false);
        }
    },

    methods: {
        genText({
            fallback, enumType, mapKey, value: text,
            def: {type, seperator, enumOptions, timezone: defTz, filter, nameKey, idKey, filterParams}
        }) {
            const {timezone, isTimestamp} = this;
            if (enumType) {
                text = type === 'tree'
                    ? getTreeText({enumType, value: text, seperator, nameKey, idKey})
                    : getText({enumType, value: text, seperator});
            }

            if (mapKey) {
                let tMap = this.$t(mapKey);
                if (tMap.constructor === Array) {
                    tMap = Enum.fromArray(mapKey, enumOptions).MAP;
                }

                text = getText({map: tMap, value: text, seperator});
            }

            if (isTimestamp) {
                // date
                if (type === DefType.date) {
                    filter = filter || 'date';
                }

                // dateTime
                if (type === DefType.dateTime) {
                    filter = 'dateTime';
                }

                // time
                if (type === DefType.time) {
                    filter = 'time';
                }
            }

            if (filter) {
                if (['time', 'dateTime', 'date'].includes(filter)) {
                    text = Vue.filter(filter)(text, defTz || timezone);
                } else {
                    text = Vue.filter(filter)(text, filterParams);
                }
            }

            return (text || text === 0 || text === false) ? text : fallback;
        }
    }
};
