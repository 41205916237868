var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "contact-list" },
    _vm._l(_vm.dataSource, function (data) {
      return _c(
        "div",
        { key: data.caseId, staticClass: "contact-group" },
        [
          _c(
            "div",
            { staticClass: "group-header mb10" },
            [
              _vm._v(
                "\n            " + _vm._s(data.userName) + "\n            "
              ),
              data.overdueDays
                ? _c("span", [
                    _vm._v("\n                （逾期"),
                    _c("span", { staticClass: "yqg-text-danger" }, [
                      _vm._v(_vm._s(data.overdueDays)),
                    ]),
                    _vm._v("天）\n            "),
                  ])
                : _vm._e(),
              _vm._v("\n            :  \n            "),
              _c("yqg-order-numbers-cell", {
                attrs: { text: "查看案件详情", "case-id": data.caseId },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("yqg-simple-table", {
            staticClass: "mt0 mb20",
            attrs: {
              pagination: false,
              records: data.numberList,
              options: _vm.tableOptions,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "displayMobileNumber",
                  fn: function (ref) {
                    var value = ref.value
                    var record = ref.record
                    return [
                      _vm._v(
                        "\n                " +
                          _vm._s(value) +
                          "\n\n                "
                      ),
                      _c("yqg-surplus-call-cnt", {
                        attrs: {
                          "surplus-call-cnt": record.surplusCallCount,
                          "manual-call-cnt": record.manualCallCnt,
                          "ivr-call-cnt": record.ivrCallCnt,
                        },
                      }),
                    ]
                  },
                },
                {
                  key: "validCntRatio",
                  fn: function (ref) {
                    var record = ref.record
                    return [
                      _vm._v(
                        "\n                " +
                          _vm._s(record.validCnt || 0) +
                          "/" +
                          _vm._s(record.cnt || 0) +
                          "\n            "
                      ),
                    ]
                  },
                },
                {
                  key: "flowResponses",
                  fn: function (ref) {
                    var value = ref.value
                    return [
                      value && value.length
                        ? _c(
                            "a-popover",
                            {
                              attrs: { placement: "left" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "content",
                                    fn: function () {
                                      return [
                                        _c(
                                          "yqg-layout-collection-flows-table",
                                          {
                                            attrs: {
                                              title: "最近5通催记",
                                              fields: [
                                                "timeCreated",
                                                "result",
                                                "remark",
                                              ],
                                              "collection-flows": value,
                                            },
                                          }
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "last-collection-flow" },
                                [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(
                                        _vm._f("dateTime")(value[0].timeCreated)
                                      ) +
                                      "\n                        " +
                                      _vm._s(
                                        _vm.collectionResultMap[value[0].result]
                                      ) +
                                      "\n                        ...\n                    "
                                  ),
                                ]
                              ),
                            ]
                          )
                        : _c("span", [_vm._v("/")]),
                    ]
                  },
                },
                {
                  key: "op",
                  fn: function (scope) {
                    return [_vm._t("op", null, { data: data }, scope)]
                  },
                },
              ],
              null,
              true
            ),
          }),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }