/*
 * Author: giligili
 * Created Date: 2021-05-13
 * email: zhimingwang@yangqianguan.com
 */

import SimpleModal from './simple-modal';
import SimpleUploadModal from './simple-upload-modal';
import UserInfoModal from './user-info-modal';

export default {
    install(Vue) {
        Object.assign(Vue.prototype.$modal, {
            SimpleModal(...argvs) {
                return this.open(SimpleModal, ...argvs);
            },

            UserInfoModal(...argvs) {
                return this.open(UserInfoModal, ...argvs);
            },

            SimpleUploadModal(...argvs) {
                return this.open(SimpleUploadModal, ...argvs);
            }
        });
    }
};
