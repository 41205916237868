<!-- @Author: xymo -->
<!-- @Date: 2024-01-04 14:45:38 -->
<!-- @Last Modified by: xymo -->
<!-- @Last Modified time: 2024-01-04 14:45:38 -->

<template>
    <div
        v-if="showSwitch"
        class="yqg-environment-switch"
    >
        <a-button
            type="primary"
            @click="switchEnv"
        >
            {{ buttonText }}
        </a-button>
    </div>
</template>

<script type="text/babel">
    import StageUtil from 'yqg-common/core/StageUtil';

    export default {
        name: 'YqgEnvSwitch',

        props: {
            // 用于指定测试环境和生产环境的域名
            // 例如：{prod: 'https://prod.yqg.com', test: 'https://test.yqg.com'}
            hostMap: {
                type: Object,
                required: true
            }
        },

        data() {
            return {
                isProd: StageUtil.isProdEnv(),
                isTest: StageUtil.isTestEnv()
            };
        },

        computed: {
            buttonText() {
                return this.isProd ? '切换到测试环境' : '切换到生产环境';
            },

            showSwitch() {
                return this.isProd || this.isTest;
            }
        },

        methods: {
            switchEnv() {
                const {$route: {fullPath = ''}} = this;
                const host = this.isProd ? this.hostMap.test : this.hostMap.prod;

                window.location.href = `${host}${fullPath}`;
            }

        }
    };

</script>
