<!-- @author giligili -->
<!-- @email zhimingwang@yangqianguan.com -->
<!-- @date 2021-08-31 14:31:09.252 -->
<!-- @desc generated by yqg-cli@5.1.2 -->

<template>
    <div class="yqg-antv-upload-file-url">
        <a-upload
            v-if="defaultFileList"
            :custom-request="customRequest"
            :multiple="isMultiple"
            :disabled="disabled"
            :default-file-list="defaultFileList"
            v-bind="props"
            :remove="onRemove"
            @change="onFileChange"
        >
            <div v-if="canUpload">
                <slot>
                    <template v-if="props.listType === 'picture-card'">
                        <a-icon type="plus" />
                        <p>上传图片</p>

                        <div
                            v-if="byteWithUnit"
                            class="yqg-text-danger"
                        >
                            最大{{ byteWithUnit }}
                        </div>
                    </template>

                    <template v-else>
                        <a-button icon="plus">
                            上传图片
                        </a-button>

                        <div
                            v-if="byteWithUnit"
                            class="yqg-text-danger"
                        >
                            最大{{ byteWithUnit }}
                        </div>
                    </template>
                </slot>
            </div>
        </a-upload>
    </div>
</template>

<script type="text/babel">

    import handleUpload from '../mixins/handle-upload';

    export default {
        name: 'YqgAntvUploadFileImage',

        mixins: [handleUpload],

        inheritAttrs: false,

        computed: {
            props() {
                const {$attrs} = this;

                return {
                    accept: 'image/*',
                    listType: 'picture-card',
                    ...$attrs
                };
            }
        }
    };
</script>
