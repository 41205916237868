/*
 * @Author: yefenghan 
 * @Date: 2024-08-13 17:31:34 
 * @Last Modified by: yefenghan
 * @Last Modified time: 2024-08-13 19:03:50
 */
export function parseCatch (error) {
    try {
        if (typeof error === 'string') return error;
        if (!error || typeof error !== 'object') return '';

        // catch捕获信息
        if (typeof error.message === 'string') return error.message;

        // 接口错误信息
        if (error.data?.status) {
            const {code, detail} = error.data.status;

            return `[${code}]${detail}`;
        }

        return JSON.stringify(error);
    } catch {
        return '';
    }
}
