var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "yqg-layout-md-preview" },
    [
      _vm.title
        ? _c("h4", { domProps: { textContent: _vm._s(_vm.title) } })
        : _vm._e(),
      _vm._v(" "),
      _vm.value
        ? _c("yqg-md-view", { attrs: { "md-text": _vm.value } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }