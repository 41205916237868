/*
 * @Author: qxy
 * @Date: 2020-07-08 16:27:07
 * @Last Modified by: qxy
 * @Last Modified time: 2020-07-08 16:29:26
 */

/* eslint-disable max-len */

export default {
    name: 'custom-mute',
    theme: 'outline',
    icon: {
        tag: 'svg',
        attrs: {viewBox: '0 0 1024 1024', x: '0', y: '0'},
        children: [
            {
                tag: 'path',
                attrs: {
                    d: 'M955.077 110.277l-41.354-41.354c-11.815-11.815-33.477-9.846-47.261 5.908L669.538 271.754v-76.8c0-86.646-68.923-155.57-155.569-155.57h-3.938c-86.646 0-155.57 68.924-155.57 155.57v285.538c0 29.539 7.877 59.077 23.631 82.708l-68.923 68.923C275.692 590.77 256 537.6 256 480.493v-78.77c0-27.57-21.662-47.261-49.23-47.261s-49.232 19.692-49.232 47.261v78.77c0 84.676 31.508 161.476 82.708 220.553L74.831 866.462c-13.785 13.784-15.754 35.446-5.908 47.261l41.354 41.354c11.815 11.815 33.477 9.846 47.261-5.908L949.17 157.54c15.754-13.785 17.723-35.447 5.908-47.262zM768 456.862v23.63c0 137.846-114.215 252.062-256 252.062h-17.723l-76.8 80.738c15.754 3.939 35.446 7.877 55.138 11.816v61.046H384c-27.57 0-49.23 21.661-49.23 49.23s21.66 49.231 49.23 49.231h257.97c27.568 0 49.23-21.661 49.23-49.23s-23.63-49.231-51.2-49.231h-88.615v-63.016c177.23-23.63 315.077-169.353 315.077-342.646v-78.769c0-11.815-5.908-23.63-11.816-31.508L768 456.862z'
                }
            }
        ]
    }
};
