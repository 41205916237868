var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "application" },
    [
      _c(
        "a-tabs",
        {
          attrs: { "active-key": _vm.tabKey, type: "card" },
          on: { change: _vm.onChange },
        },
        _vm._l(_vm.tabList, function (ref) {
          var type = ref.type
          var description = ref.description
          return _c(
            "a-tab-pane",
            { key: type, attrs: { tab: description } },
            [
              _vm.tabKey === type
                ? _c("application-content", { attrs: { type: type } })
                : _vm._e(),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }