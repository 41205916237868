/*
 * Author: giligili
 * Created Date: 2021-06-09
 * email: zhimingwang@yangqianguan.com
 * desc: 静态文案管理下的枚举
 */

import AnnouncementResource from 'collection-admin-web/common/resource/advanced/announcement';
import Enum from 'collection-admin-web/common/util/enum';

export const TextType = Enum.query(AnnouncementResource.getStaticTextTypeMap);
