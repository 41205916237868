<!-- @author giligili -->
<!-- @email zhimingwang@yangqianguan.com -->
<!-- @date 2021-09-09 17:11:57.220 -->
<!-- @desc generated by yqg-cli@5.1.2 -->

<template>
    <a-form-item
        :colon="false"
        :label="label"
        :label-col="{span: 4}"
        :wrapper-col="{span: 20}"
    >
        <yqg-antv-upload
            ui="url"
            :value="value"
            @change="onChange"
        />
    </a-form-item>
</template>

<script type="text/babel">
    export default {
        name: 'FileLayout',

        props: {
            value: {
                type: [String, Number],
                default: ''
            },

            label: {
                type: String,
                required: true
            }
        },

        methods: {
            onChange(value) {
                this.$emit('change', value);
            }
        }
    };
</script>
