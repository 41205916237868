import { jsonParse } from '../../helper';
import RTT from './rtt';
const KEY = '_YTALK_SIP_RTT_';
class SipRTTStat {
    constructor(options) {
        this.handleStorageChange = (event) => {
            var _a;
            if (event.key !== KEY)
                return;
            const rtt = jsonParse(event.newValue, null);
            if (!rtt)
                return;
            (_a = this.options) === null || _a === void 0 ? void 0 : _a.onRTTChange(rtt);
        };
        this.init = () => {
            window.addEventListener('storage', this.handleStorageChange);
        };
        this.onRTTChange = (rtt) => {
            var _a;
            (_a = this.options) === null || _a === void 0 ? void 0 : _a.onRTTChange(rtt);
            window.localStorage.setItem(KEY, JSON.stringify(rtt));
        };
        this.apply = (options) => {
            const { hubService } = options;
            // this.sipRTTClient = new SipRTTHubClient({hubService});
            hubService.getClient('registerClient', (client) => {
                this.register = client.getClient();
                this.listenRegister();
            });
            hubService.getClient('deviceClient', (client) => {
                this.device = client.device;
            });
        };
        this.listenRegister = () => {
            const { register } = this;
            if (!register)
                return;
            register.addListener('REGISTER_SUCCESSED', this.startStat);
            register.addListener('UN_REGISTER_SUCCESSED', this.end);
            register.addListener('REGISTER_DISTROY', this.end);
        };
        this.removeRegister = () => {
            const { register } = this;
            if (!register)
                return;
            register.removeListener('REGISTER_SUCCESSED', this.startStat);
            register.removeListener('UN_REGISTER_SUCCESSED', this.end);
            register.removeListener('REGISTER_DISTROY', this.end);
        };
        this.startStat = () => {
            this.sendMessage();
        };
        this.next = () => {
            this.timeout = setTimeout(this.sendMessage, 3000);
        };
        this.end = () => {
            if (!this.timeout)
                return;
            clearTimeout(this.timeout);
        };
        this.sendMessage = () => {
            this.end();
            const { device } = this;
            if (!device)
                return this.next();
            this.rtt = new RTT({ prevRTT: this.rtt, onRTTChange: this.onRTTChange }, device);
            this.next();
        };
        this.destroy = () => {
            var _a;
            this.end();
            (_a = this.rtt) === null || _a === void 0 ? void 0 : _a.destroy();
            this.removeRegister();
        };
        this.options = options;
        this.init();
    }
}
export default SipRTTStat;
