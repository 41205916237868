<!-- @Author: xymo -->
<!-- @Date: 2024-02-21 14:57:43 -->
<!-- @Last Modified by: xymo -->
<!-- @Last Modified time: 2024-02-21 14:57:43 -->

<template>
    <yqg-simple-form
        title="选择微信主体"
        :options="FormOptions"
        @confirm="onConfirm"
        @cancel="dismiss"
    />
</template>

<script type='text/babel'>

    import {modal} from 'collection-admin-web/common/mixin';

    import FormOptions from './constant/add-enterprise-we-chat-form-options';

    export default {
        name: 'AddEnterpriseWeChatSymbolModal',

        mixins: [modal],

        data() {
            return {
                FormOptions
            };
        },

        methods: {
            onConfirm({values}) {
                this.close(values);
            }

        }

    };
</script>

