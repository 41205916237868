var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "yqg-md-editor", class: { "full-screen": _vm.fullScreen } },
    [
      _c("b-md-editor", {
        attrs: { value: _vm.value, options: _vm.options },
        on: { change: _vm.onChange },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }