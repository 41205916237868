<!-- @author giligili -->
<!-- @email zhimingwang@yangqianguan.com -->
<!-- @date 2021-05-25 12:56:37.786 -->
<!-- @desc generated by yqg-cli@4.0.7 -->

<template>
    <a-form-item
        :colon="false"
        :label="label"
        :label-col="{span: 4}"
        :wrapper-col="{span: 20}"
    >
        <yqg-json-visual
            :value="value"
            :field-defs="fieldDefs"
            :field-format="fieldFormat"
        />
    </a-form-item>
</template>

<script type="text/babel">
    export default {
        name: 'JsonLayout',

        props: {
            value: {
                type: Object,
                default: () => ({})
            },

            label: {
                type: String,
                required: true
            },

            fieldDefs: {
                type: Array,
                default: () => []
            },

            fieldFormat: {
                type: Function,
                default: field => field
            }
        },

        mounted() {
            this.$emit('change', this.value);
        }
    };
</script>
