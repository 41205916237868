/*
 * @Author: yefenghan 
 * @Date: 2024-08-09 18:25:02 
 * @Last Modified by:   yefenghan 
 * @Last Modified time: 2024-08-09 18:25:02 
 */

const ALERT = 'ALERT'; // 给坐席提示

export default {
    ALERT
};
