import { render, staticRenderFns } from "./call-list.vue?vue&type=template&id=7c112e8b&scoped=true&"
import script from "./call-list.vue?vue&type=script&lang=js&"
export * from "./call-list.vue?vue&type=script&lang=js&"
import style0 from "./call-list.vue?vue&type=style&index=0&id=7c112e8b&lang=scss&rel=stylesheet%2Fscss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c112e8b",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/jenkins/agent/workspace/fintopia-web7chz7/fintopia-web-ci/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7c112e8b')) {
      api.createRecord('7c112e8b', component.options)
    } else {
      api.reload('7c112e8b', component.options)
    }
    module.hot.accept("./call-list.vue?vue&type=template&id=7c112e8b&scoped=true&", function () {
      api.rerender('7c112e8b', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/common/component/yqg-layout/component/header-multi-call/components/call-list/call-list.vue"
export default component.exports