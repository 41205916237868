/*
 * Author: giligili
 * Created Date: 2021-05-13
 * email: zhimingwang@yangqianguan.com
 * desc: 账号权限的枚举
 */

import AccountCertifyStatus from 'collection-admin-web/common/constant/maps/account-certify';
import AccountReviewStatus from 'collection-admin-web/common/constant/maps/account-review';
import Enum from 'collection-admin-web/common/util/enum';

export const ReviewStatus = Enum.from(AccountReviewStatus);
export const CertifyStatus = Enum.from(AccountCertifyStatus);
