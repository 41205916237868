/**
 * @author weisun
 * @date 2022/5/30-12:44
 * @file percent-fixed4-filter
 */

/* eslint-disable max-len */
import {decimalToPercent} from '../../../core/ToolFunction/StringUtil';

export default () => input => {
    // 不知为啥有的数据会报错，所以加上try
    // TypeError: (0 , _core_ToolFunction_StringUtil__WEBPACK_IMPORTED_MODULE_0__.decimalToPercent)(...).filter(...).toFixed is not a function

    try {
        return (isFinite(input) && input !== null && (input === +input || !isNaN(input)))
            ? `${decimalToPercent(4).filter(input).toFixed(4)}%` : '/';
    } catch (err) {
        return '/';
    }
};

