<!-- @Author: xymo -->
<!-- @Date: 2023-07-19 -->
<!-- @Last Modified by: xymo -->
<!-- @Last Modified time: 2023-07-20 13:24:27 -->

<template>
    <div class="collapse">
        <div
            class="title-wrapper"
            @click="handleClick"
        >
            <a-icon
                :type="expand ? 'minus' : 'plus'"
                class="mr10"
            />
            <slot name="title" />
        </div>
        <div
            v-if="expand || rendered"
            v-show="expand"
            class="content"
        >
            <slot />
        </div>
        <slot
            class="footer"
            name="footer"
        />
    </div>
</template>

<script>
export default {
    name: 'Collapse',

    props: {
        defaultExpand: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            expand: false,
            rendered: false
        };
    },

    created() {
        if (this.defaultExpand) {
            this.handleClick();
        }
    },

    methods: {
        handleClick() {
            this.expand = !this.expand;
            if (this.expand === true) {
                this.$emit('open');
            }

            this.rendered = true;
        }
    }
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.collapse {
    margin-top: 10px;
    padding: 5px 10px;
    border: 1px solid #e1e6f5;
    background: #f7f9fd;

    .title-wrapper {
        display: flex;
        align-items: center;
        padding: 5px 0;
        cursor: pointer;
    }

    .content {
        padding-top: 5px;
        border-top: 1px solid #e1e6f5;
    }
}
</style>
