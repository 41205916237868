<!-- @author giligili -->
<!-- @email zhimingwang@yangqianguan.com -->
<!-- @date 2021-07-30 16:05:59.762 -->
<!-- @desc generated by yqg-cli@5.1.1 -->

<template>
    <div class="call-list">
        <div class="container mb20">
            <p>
                当前已选择 {{ numberList.length }} 个号码,
                最多选择 {{ multiListMaxSize }} 个
                <a-button
                    size="small"
                    @click="onDeleteAll"
                    v-text="'清空'"
                />
            </p>

            <contact-list
                v-if="caseList.length"
                :data-source="caseList"
            >
                <template #op="{data, record}">
                    <a-button
                        size="small"
                        type="danger"
                        icon="delete"
                        @click="onDelete(data, record)"
                    />
                </template>
            </contact-list>

            <a-empty v-else />
        </div>

        <div class="footer">
            <yqg-layout-multi-call-btn
                v-if="caseList.length"
                :data-source="numberList"
                :value="encryptedMobileNumberList"
            />
        </div>
    </div>
</template>

<script type="text/babel">

    import {mapGetters, mapActions} from 'vuex';

    import CallMulti from 'collection-admin-web/common/resource/call/multi';

    import ContactList from '../contact-list';

    export default {
        name: 'CallList',

        components: {
            ContactList
        },

        computed: {
            ...mapGetters('multiCall', ['multiListMaxSize', 'caseList']),

            numberList() {
                const {caseList} = this;

                return caseList.reduce((accu, curr) => [...accu, ...curr.numberList], []);
            },

            encryptedMobileNumberList() {
                const {numberList} = this;

                return numberList.map(({encryptedMobileNumber}) => encryptedMobileNumber);
            }
        },

        methods: {
            ...mapActions('multiCall', ['fetchMultiCallList', 'fetchMultiCallCount']),

            onDelete({caseId}, {encryptedMobileNumber}) {
                CallMulti.deleteList({params: {caseId, encryptedMobileNumber}})
                    .then(({data: {body}}) => {
                        if (body) {
                            this.$message.success('删除成功!');

                            this.fetchMultiCallList();
                            this.fetchMultiCallCount();
                        } else {
                            this.$message.error('删除失败!');
                        }
                    })
                    .catch(err => err);
            },

            onDeleteAll() {
                CallMulti.deleteAllList().then(({data: {body}}) => {
                    if (body) {
                        this.$message.success('删除成功!');

                        this.fetchMultiCallList();
                        this.fetchMultiCallCount();
                    } else {
                        this.$message.error('删除失败!');
                    }
                });
            }

        }
    };

</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    @import "collection-admin-web/common/style/mixin.scss";

    .call-list {
        .container {
            @include scroll-y(calc(100vh - 350px));
        }

        .footer {
            text-align: right;
        }
    }
</style>
