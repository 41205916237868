<!-- @Author: yanglan -->
<!-- @Date: 2018-04-24 12:47:57.923 -->
<!-- @Last Modified by: mengpeng -->
<!-- @Last Modified time: 2023-11-30 14:21:35 -->

<script type="text/babel">

import enumOptionsConvert, {handleEnumRefresh} from '../../constant/enum/enum-options-convert';

export default {
    name: 'YqgForm',

    model: {
        prop: 'data',
        event: 'change'
    },

    props: {
        data: {
            type: Object,
            required: true
        },

        options: {
            type: Object,
            required: true
        },

        emitInputAsChange: {
            type: Boolean,
            default: true
        }
    },

    data() {
        return {
            innerOptions: {
                fieldDefs: []
            }
        };
    },

    watch: {
        options() {
            const vm = this;
            handleEnumRefresh(vm.options, vm.updateInnerOptions);
            vm.updateInnerOptions();
        }
    },

    created() {
        const vm = this;
        handleEnumRefresh(vm.options, vm.updateInnerOptions);
        vm.updateInnerOptions();
    },

    beforeDestroy() {
        const vm = this;
        handleEnumRefresh(vm.options, vm.updateInnerOptions, 'unbind');
    },

    methods: {
        updateInnerOptions() {
            const vm = this;

            enumOptionsConvert(vm.options)
                .then(options => {
                    const {fieldDefs, btnDefs = []} = options;
                    vm.innerOptions = {
                        ...options,
                        fieldDefs: fieldDefs.filter(({isHide}) => !isHide),
                        btnDefs: btnDefs.filter(({isHide}) => !isHide)
                    };
                    vm.$emit('enum-fetched');
                })
                .catch(err => err);
        }
    },

    render(createElement) {
        const vm = this;
        const {data, innerOptions, emitInputAsChange, $scopedSlots, $slots, $listeners} = vm;

        return createElement('b-form', {
                props: {
                    data,
                    options: innerOptions,
                    emitInputAsChange
                },
                scopedSlots: {
                    ...$scopedSlots,
                    ...Object.keys($slots).reduce((acc, key) => {
                        acc[key] = () => $slots[key];

                        return acc;
                    }, {})
                },
                ref: 'bform',
                on: {
                    ...$listeners
                }
            });
    }
};
</script>
