/*
 * @Author: ruiwang
 * @Date: 2024-08-15 17:20:14
 * @Last Modified by: ruiwang
 * @Last Modified time: 2024-08-15 17:21:07
 */
export const CountryMap = {
    'prod': 'CN',
    'test': 'CN',
    'prod-indo': 'IDN',
    'prod-mex-huawei': 'MEX',
    'prod-phi': 'PHI',
    'prod-sea': 'THA',
    'prod-eu': 'POL',
    'prod-indo-rta': 'IDN',
    'prod-hill': 'IDN',
    'test-indo': 'IDN',
    'test-mex': 'MEX',
    'test-sea': 'PHI',
    'test-eu': 'POL'
};
