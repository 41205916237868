var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "pre",
    { staticClass: "yqg-code-preview", class: "lang-" + _vm.lang },
    [_c("code", { domProps: { textContent: _vm._s(_vm.code) } })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }