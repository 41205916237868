/**
 * @Author: xymo
 * @Date: 2021-04-13
 * @Last Modified by: xymo
 * @Last Modified time: 2023-08-23 12:00:26
 */
import { assign, customGet, customPost } from '@yqg/resource';
const api = {
    urlPrefix: '/admin/operation/col/admin/',
    // 登录
    login: customPost('login'),
    // 获取用户信息
    fetchUserInfo: customGet('fetchUserInfo'),
    // 修改密码
    modifyPassword: customPost('modifyPassword'),
    // 获取验证码key
    getCaptchaKey: customGet('getCaptchaKey'),
    // 获取下属所有催收员
    getCollectorMap: customGet('getCollectorMap'),
    // 获取兄弟和下属所有催收员
    getCollectorMapWithBrotherAreas: customGet('getCollectorMapWithBrotherAreas'),
    // 获取投诉岗名单
    listComplaintManager: customGet('listComplaintManager'),
    // 生成验证码图片链接
    getCaptchaImageUrl: customGet({ url: 'getCaptchaImage/:key', link: true }),
    // 发送验证码
    sendVerifySms: customPost('sendVerifySms')
};
export default assign(api);
