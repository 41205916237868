import EventEmitter from 'eventemitter3';
class HubClient {
    constructor(options) {
        const { name, hubService } = options;
        this.name = name;
        this.hubService = hubService;
        this.client = new EventEmitter();
    }
    getClient() {
        return this.client;
    }
    register() {
        this.hubService.register(this.name, this);
    }
}
export default HubClient;
