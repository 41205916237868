/**
 * @Author: xymo
 * @Date: 2023-09-19
 * @Last Modified by: shaotanliang@fintopia.tech
 * @Last Modified time: 2023-10-31 16:44:19
 */

const TagPrefix = 'Collection_';
const businessEventPrefix = 'Collection_';

export default {
    KNOWLEDGE: {
        tag: `${TagPrefix}Knowledge_Operation_Btns`,
        businessEvent: `${businessEventPrefix}Knowledge_Operation_Btns`
    },
    // 企微收款流程操作成功
    WECHATCOLLECT: {
        tag: `${TagPrefix}Wechat_Collection_SENDED`,
        businessEvent: `${businessEventPrefix}Wechat_Collection_SENDED`
    },
    // 企微收款-点击唤起企微收款弹窗按钮
    CLICKWECHATCOLLECTBTN: {
        tag: `${TagPrefix}Click_Wechat_Collection_Btn`,
        businessEvent: `${businessEventPrefix}Click_Wechat_Collection_Btn`
    },
    // 企微收款-点击关联案件按钮
    WECHATCOLLECTLINKCASE: {
        tag: `${TagPrefix}Wechat_Collection_LINK_CASE`,
        businessEvent: `${businessEventPrefix}Wechat_Collection_LINK_CASE`
    },
};
