<!-- @author huayizhang -->
<!-- @email huayizhang@yangqianguan.com -->
<!-- @date 2021-06-30 11:39:29.515 -->
<!-- @desc generated by yqg-cli@1.2.7 -->

<template>
    <div class="yqg-call-quality-detail-link">
        <a
            v-if="canLink"
            @click="to(query)"
        >
            {{ text || qualityId }}
        </a>

        <span v-else-if="qualityId">
            {{ text || qualityId }}
        </span>
    </div>
</template>

<script type="text/babel">

    import * as RouterName from 'collection-admin-web/common/constant/router-name';
    import RouterLink from 'collection-admin-web/common/mixin/router-link';

    export default {
        name: 'YqgCallQualityDetailLink', // angular里的yqg-quality-id-link

        mixins: [RouterLink(RouterName.QualityDetail)],

        props: {
            qualityId: {
                type: [Number, String],
                default: null
            },

            cond: {
                type: Object,
                default: () => null
            },

            text: {
                type: String,
                default: ''
            }
        },

        computed: {
            canLink() {
                const vm = this;

                return vm.qualityId && vm.routerLink;
            },

            query() {
                const vm = this;
                const {qualityId, cond} = vm;

                return {
                    callQualityId: qualityId && window.btoa(qualityId),
                    queryCond: cond && window.encodeURIComponent(JSON.stringify(cond))
                };
            }
        }
    };

</script>
