<!-- @author giligili -->
<!-- @email zhimingwang@fintopia.tech -->
<!-- @date 2022-03-21 18:40:50.347 -->
<!-- @desc generated by yqg-cli@5.2.0 -->

<template>
    <div class="yqg-check-wrap">
        <div class="check">
            <a-checkbox-group
                :value="selectKeys"
                @change="onChange"
            >
                <a-checkbox :value="value" />
            </a-checkbox-group>
        </div>

        <div class="container">
            <slot />
        </div>
    </div>
</template>

<script type="text/babel">

    import {isDef} from 'collection-admin-web/common/util/object';

    export default {
        name: 'YqgCheckWrap',

        model: {
            prop: 'selectKeys',
            event: 'change'
        },

        props: {
            value: {
                type: [String, Number],
                required: true
            },

            selectKeys: {
                type: Array,
                default: () => []
            }
        },

        methods: {
            onChange([value]) {
                if (!isDef(value)) {
                    this.$emit('change', this.selectKeys.filter(key => key !== this.value));

                    return;
                }

                this.$emit('change', [...this.selectKeys, this.value]);
            }
        }
    };

</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .yqg-check-wrap {
        display: flex;

        .check {
            margin-right: 10px;
        }

        .container {
            flex: 1;
        }
    }
</style>
