<!-- @author giligili -->
<!-- @email zhimingwang@yangqianguan.com -->
<!-- @date 2021-07-30 17:54:17.325 -->
<!-- @desc generated by yqg-cli@5.1.1 -->

<template>
    <div class="contact-list">
        <div
            v-for="data in dataSource"
            :key="data.caseId"
            class="contact-group"
        >
            <div class="group-header mb10">
                {{ data.userName }}
                <span v-if="data.overdueDays">
                    （逾期<span class="yqg-text-danger">{{ data.overdueDays }}</span>天）
                </span>
                : &nbsp;
                <yqg-order-numbers-cell
                    text="查看案件详情"
                    :case-id="data.caseId"
                />
            </div>

            <yqg-simple-table
                class="mt0 mb20"
                :pagination="false"
                :records="data.numberList"
                :options="tableOptions"
            >
                <template #displayMobileNumber="{value, record}">
                    {{ value }}

                    <yqg-surplus-call-cnt
                        :surplus-call-cnt="record.surplusCallCount"
                        :manual-call-cnt="record.manualCallCnt"
                        :ivr-call-cnt="record.ivrCallCnt"
                    />
                </template>

                <template #validCntRatio="{record}">
                    {{ record.validCnt || 0 }}/{{ record.cnt || 0 }}
                </template>

                <template #flowResponses="{value}">
                    <a-popover
                        v-if="value && value.length"
                        placement="left"
                    >
                        <template #content>
                            <yqg-layout-collection-flows-table
                                title="最近5通催记"
                                :fields="['timeCreated', 'result', 'remark']"
                                :collection-flows="value"
                            />
                        </template>

                        <span class="last-collection-flow">
                            {{ value[0].timeCreated | dateTime }}
                            {{ collectionResultMap[value[0].result] }}
                            ...
                        </span>
                    </a-popover>

                    <span v-else>/</span>
                </template>

                <template #op="scope">
                    <slot
                        name="op"
                        v-bind="scope"
                        :data="data"
                    />
                </template>
            </yqg-simple-table>
        </div>
    </div>
</template>

<script type="text/babel">

    import EnumAll from 'collection-admin-web/common/constant/enum';

    import GenTableOptions from './constant/table-options';

    export default {
        name: 'ContactList',

        props: {
            dataSource: {
                type: Array,
                default: () => []
            }
        },

        data() {
            return {
                allNumberList: [],
                selectedRowKeys: [],

                collectionResultMap: {}
            };
        },

        computed: {
            tableOptions() {
                const onChange = this.$listeners.change;

                if (onChange) {
                    return GenTableOptions({
                        rowSelection: {
                            columnWidth: 37,
                            onChange: this.onSelectChange,
                            selectedRowKeys: this.selectedRowKeys
                        }
                    });
                }

                return GenTableOptions();
            }
        },

        watch: {
            dataSource: {
                immediate: true,
                handler(nextValue) {
                    const {allNumberList: prevNumberList = []} = this;

                    const nextNumberList = nextValue.reduce((accu, curr) => [...accu, ...curr.numberList], []);

                    this.allNumberList = nextNumberList;

                    if (
                        prevNumberList.length !== nextNumberList.length
                        || nextNumberList.some(
                            (list, index) => list.encryptedMobileNumber !== prevNumberList[index].encryptedMobileNumber
                        )
                    ) {
                        this.selectedRowKeys = this.allNumberList
                            .map(({encryptedMobileNumber}) => encryptedMobileNumber);
                    }
                }
            },

            selectedRowKeys: {
                immediate: true,
                handler(rowKeys) {
                    const {allNumberList} = this;

                    const hashMap = rowKeys.reduce((accu, curr) => ({
                        ...accu,
                        [curr]: curr
                    }), {});

                    this.$emit(
                        'change',
                        allNumberList.filter(({encryptedMobileNumber}) => hashMap[encryptedMobileNumber])
                    );
                }
            }
        },

        mounted() {
            this.fetchCollectionResult();
        },

        methods: {
            onSelectChange(selectedRowKeys) {
                this.selectedRowKeys = selectedRowKeys;
                // 注意这里不能透传 onSelectChange 的第二个参数
                // 因为几个 a-table 公用了一个 selectedRowKeys (比较hack)
                // 但是 dataSource 不是公用的
                // 🌰 1个a-table 可能有4个selectedRowKeys 但是只有2个record(另外2个key给另外的table使用)
                // rowkeys.length >= record.length
                // 所以需要用selectRowKeys filter 出 record 传出去 而不能直接用 record
            },

            async fetchCollectionResult() {
                await EnumAll.Call.CollectionResult.query();

                this.collectionResultMap = EnumAll.Call.CollectionResult.MAP;
            }
        }
    };

</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .contact-list {
        .contact-group {
            .group-header {
                display: flex;
                align-items: center;
            }
        }

        .last-collection-flow {
            color: #5bc0de;
            cursor: pointer;
        }
    }
</style>
