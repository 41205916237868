/**
 * @author giligili
 * @date 2021-07-30
 * @email zhimingwang@yangqianguan.com
 * @desc fields
 */

import {genColumnStyle} from 'collection-admin-web/common/constant/fields';
import {genField} from 'collection-admin-web/common/util/tool';

const COMMIN_WIDTH = 100;

export const name = genField('姓名', 'name', 'input', {
    staticProps: genColumnStyle({minWidth: COMMIN_WIDTH, maxWidth: COMMIN_WIDTH})
});
export const relationship = genField('类型', 'relationship', 'input', {
    staticProps: genColumnStyle({minWidth: COMMIN_WIDTH, maxWidth: COMMIN_WIDTH})
});
export const displayMobileNumber = genField('号码', 'displayMobileNumber', 'input', {
    staticProps: genColumnStyle({minWidth: COMMIN_WIDTH + 20, maxWidth: COMMIN_WIDTH + 20})
});
export const validCntRatio = genField('接通/总次数', 'validCntRatio', 'input', {
    staticProps: genColumnStyle({minWidth: COMMIN_WIDTH, maxWidth: COMMIN_WIDTH})
});
export const flowResponses = genField('最近催记', 'flowResponses', 'input', {
    staticProps: genColumnStyle({minWidth: 2 * COMMIN_WIDTH + 50, maxWidth: 2 * COMMIN_WIDTH + 50})
});
export const op = genField('操作', 'op', 'input');
