class RTT {
    constructor(options, device) {
        this.getBody = () => {
            const { prevRTT } = this.options;
            if (!prevRTT)
                return { currentTime: this.timestamp };
            const { status, mill, timestamp } = prevRTT.getRaw();
            return {
                isLinked: status === 'END',
                mill,
                lastTime: timestamp,
                currentTime: this.timestamp
            };
        };
        this.send = () => {
            const { device } = this;
            const target = device.configuration.uri;
            const body = this.getBody();
            const options = { extraHeaders: ['Network_Quality_Check: true'] };
            this.message = device.sendMessage(target, JSON.stringify(body), options);
            const transactions = device._transactions['nict']; // eslint-disable-line
            Object.values(transactions).forEach((transaction) => {
                this.addTransaction(transaction);
            });
            setTimeout(() => {
                if (this.status !== 'ING')
                    return;
                this.status = 'LOSS';
                this.options.onRTTChange({
                    ms: 'unknow',
                    loss: true,
                });
                return;
            }, 2000);
        };
        this.listenTransaction = () => {
            const { transaction, status } = this;
            if (!transaction)
                return;
            this.handleStateChange = () => {
                if (transaction.state !== 5)
                    return;
                const time = new Date().getTime() - this.timestamp;
                if (status === 'LOSS')
                    return;
                const level = this.getRTTLevel(time);
                this.status = 'END';
                this.mill = time;
                this.options.onRTTChange({
                    time,
                    ms: `${time} ms`,
                    level,
                    loss: false
                });
            };
            transaction.addListener('stateChanged', this.handleStateChange);
        };
        this.removeTransaction = () => {
            const { transaction } = this;
            if (!transaction || !this.handleStateChange)
                return;
            transaction.removeListener('stateChanged', this.handleStateChange);
        };
        this.checkTransaction = (transaction) => {
            if (!this.message)
                return;
            if (transaction.request !== this.message._request)
                return; // eslint-disable-line
            this.transaction = transaction;
            this.listenTransaction();
        };
        this.addTransaction = (transaction) => {
            var _a;
            const { type, request } = transaction;
            if (type !== 'nict' || request.method !== 'MESSAGE')
                return;
            if (!((_a = request.extraHeaders) === null || _a === void 0 ? void 0 : _a.some((extraHeader) => /Network_Quality_Check/.test(extraHeader))))
                return;
            this.checkTransaction(transaction);
        };
        this.getRaw = () => {
            const { timestamp, status, mill } = this;
            return {
                timestamp,
                status,
                mill,
            };
        };
        this.destroy = () => {
            this.removeTransaction();
        };
        this.options = options;
        this.status = 'ING';
        this.device = device;
        this.timestamp = new Date().getTime();
        const { prevRTT } = options;
        if (prevRTT)
            prevRTT.destroy();
        this.send();
    }
    getRTTLevel(mill) {
        if (mill <= 200)
            return 0;
        if (mill <= 500)
            return 1;
        return 2;
    }
}
export const lossColor = '#ef4444';
export const levelColors = [
    '#22c55e',
    '#eab308',
    '#ef4444'
];
export default RTT;
