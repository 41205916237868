/**
 * @Author: giligili
 * @Date: 2021-09-01
 * @Last Modified by: giligili
 * @Last Modified time: 2023-04-13 19:43:24
 */

import {formatBytes} from 'yqg-common/core/ToolFunction/StringUtil';

import {createCustomRequest} from 'collection-admin-web/common/constant/fields';
import UploadResource from 'collection-admin-web/common/resource/upload';

import * as BucketType from '../constant/bucket-type';
import TokenMap from '../constant/token-map';

let uuid = -1;

export default {
    data() {
        return {
            backupFileList: [],
            defaultFileList: null
        };
    },

    props: {
        value: {
            type: [String, Array],
            default: undefined
        },

        /* @desc 最大上传数量 */
        limit: {
            type: Number,
            default: 1
        },

        disabled: {
            type: Boolean,
            default: false
        },

        bucket: {
            type: String,
            default: BucketType.WEB // web or collection
        },

        maxByte: {
            type: Number,
            default: undefined
        }
    },

    computed: {
        isMultiple() {
            const {limit} = this;

            return limit > 1;
        },

        canUpload() {
            const {limit, backupFileList, disabled} = this;

            return !disabled && backupFileList.length < limit;
        },

        byteWithUnit() {
            const {maxByte} = this;

            return typeof maxByte === 'number' ? formatBytes(maxByte) : '';
        }
    },

    watch: {
        value: {
            handler(value) {
                /**
                 * @desc 判断组件的value是非受控改变，还是受控改变
                 */
                if (value !== this.prevBubbleValue) {
                    this.$parent.key += '1';
                }
            }
        }
    },

    mounted() {
        this.initDefaultFileList();
    },

    methods: {
        isUrl(string) {
            return /^https?:\/\/.+$/.test(string);
        },

        initFile(url, rest = {}) {
            const fileName = /\/([^/]+?)(\?.+)*$/.exec(url)?.[1] || url;

            return {
                url,
                uid: uuid -= 1,
                name: fileName,
                status: 'done',
                ...rest
            };
        },

        initDefaultFileList() {
            const {isMultiple, value: fileString, bucket} = this;

            const fileStrings = isMultiple ? (fileString || []) : [fileString];

            if (bucket !== BucketType.COLLECTION) {
                this.defaultFileList = fileStrings.filter(fileString => fileString).map(url => this.initFile(url));

                this.backupFileList = this.defaultFileList;

                this.updatePrevBubbleValue();

                return;
            }

            const fileUrlRequests = fileStrings.filter(fileString => fileString).map(fileString => {
                if (this.isUrl(fileString)) return Promise.resolve({key: fileString, url: fileString});

                return UploadResource.getQiniuPrivateFileUrl({params: {fileKey: fileString}})
                    .then(({data: {body: {url}}}) => ({url, key: fileString}));
            });

            Promise.allSettled(fileUrlRequests)
                .then(results => {
                    this.defaultFileList = results
                        .map(({status, value: {url, key}}) => this.initFile(
                            url,
                            {
                                status: status === 'fulfilled'
                                    ? 'done'
                                    : 'error',
                                bucketFileKey: key
                            }
                        ));

                    this.backupFileList = this.defaultFileList;

                    this.updatePrevBubbleValue();
                })
                .catch(err => err);
        },

        updatePrevBubbleValue() {
            const {isMultiple, backupFileList} = this;

            const urls = backupFileList.map(({url, bucketFileKey}) => bucketFileKey || url)
                .filter(url => url);

            this.prevBubbleValue = isMultiple ? urls : (urls[0] || null);
        },

        /**
         * 
         * @param {Object} options
         *  
         * @param {Function} options.onError
         * @param {Function} options.onProgress
         * @param {Function} options.onSuccess
         * 上面这3个方法执行的时候都会触发 antd upload 的 change 事件, 其中 file.status == 'done'|'error'|'uploading' 
         */
        customRequest(options = {}) {
            const {canUpload, limit, bucket, maxByte, byteWithUnit} = this;
            const {file: {size = 0} = {}} = options;

            if (typeof maxByte === 'number' && size > maxByte) {
                const errorMsg = `大小超过最大限制${byteWithUnit}`;
                setTimeout(() => options.onError(errorMsg, errorMsg));

                return;
            }

            if (!canUpload) {
                // 这里要异步执行onError, 同步执行 antd 中 fileList 还没有新添加的文件
                // 会被 return 掉
                setTimeout(() => {
                    this.$message.error(`最多上传${limit}张图片`);

                    options.onError();
                });

                return;
            }

            const {getToken, tokenFilter, getFileName} = TokenMap[bucket];

            createCustomRequest(getToken, tokenFilter, getFileName)(options);
        },

        done(file, fileList) {
            const {bucket} = this;

            this.$message.success('上传成功');

            const {fileFilter} = TokenMap[bucket];

            const nextFile = fileList.find(({uid}) => uid === file.uid);
            nextFile.url = fileFilter(file.response);

            this.onChange();
        },

        error(file) {
            this.$message.error(file.error || '上传失败');
        },

        onFileChange({file, fileList}) {
            this.backupFileList = fileList;

            const {status} = file;
            const func = this[status] || (() => ({}));
            func.call(this, file, fileList);
        },

        onRemove(file) {
            const index = this.backupFileList.findIndex(({uid}) => uid === file.uid);

            this.backupFileList.splice(index, 1);

            this.onChange();
        },

        onChange() {
            this.updatePrevBubbleValue();

            this.$emit('change', this.prevBubbleValue);
        }
    }
};
