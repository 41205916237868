var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "yqg-order-numbers-cell" }, [
    _vm.vertical
      ? _c("div", { staticClass: "veritical-layout" }, [
          _vm.canLink
            ? _c("div", { on: { click: _vm.toCaseDetail } }, [
                _c(
                  "a",
                  { staticClass: "order-number-box" },
                  _vm._l(_vm.uniqOrderNumber, function (orderNumber, idx) {
                    return _c(
                      "p",
                      {
                        key: orderNumber,
                        staticClass: "order-number",
                        class: { mb0: idx === _vm.uniqOrderNumber.length - 1 },
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(orderNumber) +
                            "\n                "
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ])
            : _c("div", [
                _c(
                  "span",
                  { staticClass: "order-number-box" },
                  _vm._l(_vm.uniqOrderNumber, function (orderNumber, idx) {
                    return _c(
                      "p",
                      {
                        key: orderNumber,
                        staticClass: "order-number",
                        class: { mb0: idx === _vm.uniqOrderNumber.length - 1 },
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(orderNumber) +
                            "\n                "
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ]),
        ])
      : _c("div", { staticClass: "horizontal-layout" }, [
          _vm.canLink
            ? _c("div", { on: { click: _vm.toCaseDetail } }, [
                _c("a", { staticClass: "order-number-box" }, [
                  _vm._v(
                    _vm._s(_vm.text || _vm.uniqOrderNumber.join(_vm.hyphen))
                  ),
                ]),
              ])
            : _c("div", [
                _c("span", { staticClass: "order-number-box" }, [
                  _vm._v(
                    _vm._s(_vm.text || _vm.uniqOrderNumber.join(_vm.hyphen))
                  ),
                ]),
              ]),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }