var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.single
        ? [
            !_vm.def.checkAllInvisible
              ? _c(
                  "a-checkbox",
                  _vm._b(
                    {
                      class: { "block-item": !!_vm.def.checkAllLabel },
                      attrs: {
                        indeterminate: _vm.indeterminate,
                        checked: _vm.checkAll,
                      },
                      on: { change: _vm.onCheckAllChange },
                    },
                    "a-checkbox",
                    _vm.def.props,
                    false
                  ),
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.def.checkAllLabel || _vm.$t("common.checkAll")
                        ) +
                        "\n        "
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "a-checkbox-group",
              _vm._b(
                {
                  attrs: { options: _vm.options },
                  on: { change: _vm.onChange },
                  model: {
                    value: _vm.checkedList,
                    callback: function ($$v) {
                      _vm.checkedList = $$v
                    },
                    expression: "checkedList",
                  },
                },
                "a-checkbox-group",
                _vm.def.props,
                false
              )
            ),
          ]
        : _c(
            "a-checkbox",
            _vm._b(
              { attrs: { checked: _vm.checked }, on: { change: _vm.onChange } },
              "a-checkbox",
              _vm.def.props,
              false
            ),
            [_vm._v("\n        " + _vm._s(_vm.$t(_vm.single)) + "\n    ")]
          ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }