<!-- @Author: yefenghan -->
<!-- @Date: 2024-7-18 11:28:26 -->
<!-- @Last Modified by: yefenghan -->
<!-- @Last Modified time: 2024-07-18 11:28:26 -->

<template>
    <div class="alarm-oa-add-account">
        【新增账号提醒】

        <span class="mr10">{{ notification.data.remark }}</span>

        <span
            @click="finishNotification(notification.data)"
        >
            <yqg-router-link
                text="请查看"
                to="accountManager"
                :query="{isPending: $options.BooleanType.TRUE}"
            />
        </span>
    </div>
</template>

<script type="text/babel">
    import {mapActions} from 'vuex';

    import BooleanType from 'collection-admin-web/common/constant/types/boolean';

    export default {
        name: 'AlarmOaAddAccount',

        BooleanType,

        props: {
            notification: {
                type: Object,
                default: () => ({})
            }
        },

        methods: {
            ...mapActions('notification', ['finishNotification'])
        }
    };
</script>
