import { render, staticRenderFns } from "./upload-url.vue?vue&type=template&id=5836149e&scoped=true&"
import script from "./upload-url.vue?vue&type=script&lang=js&"
export * from "./upload-url.vue?vue&type=script&lang=js&"
import style0 from "./upload-url.vue?vue&type=style&index=0&id=5836149e&lang=scss&rel=stylesheet%2Fscss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5836149e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/jenkins/agent/workspace/fintopia-web7chz7/fintopia-web-ci/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5836149e')) {
      api.createRecord('5836149e', component.options)
    } else {
      api.reload('5836149e', component.options)
    }
    module.hot.accept("./upload-url.vue?vue&type=template&id=5836149e&scoped=true&", function () {
      api.rerender('5836149e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/common/component/yqg-antv-upload/components/upload-url.vue"
export default component.exports