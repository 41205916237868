import { render, staticRenderFns } from "./yqg-info-view.vue?vue&type=template&id=0d9c8fe8&"
import script from "./yqg-info-view.vue?vue&type=script&lang=js&"
export * from "./yqg-info-view.vue?vue&type=script&lang=js&"
import style0 from "./yqg-info-view.vue?vue&type=style&index=0&lang=scss&rel=stylesheet%2Fscss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/jenkins/agent/workspace/fintopia-web7chz7/fintopia-web-ci/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0d9c8fe8')) {
      api.createRecord('0d9c8fe8', component.options)
    } else {
      api.reload('0d9c8fe8', component.options)
    }
    module.hot.accept("./yqg-info-view.vue?vue&type=template&id=0d9c8fe8&", function () {
      api.rerender('0d9c8fe8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "common/vue/component/yqg-info-view/yqg-info-view.vue"
export default component.exports