const capitalizeFirstLetter = (str) => {
    if (!str)
        return ''; // 防止空字符串报错
    return str.charAt(0).toUpperCase() + str.slice(1);
};
const flattenObject = (obj, parentKey = '', result = {}) => {
    for (const key in obj) {
        const value = obj[key];
        const newKey = parentKey ? `${parentKey}${capitalizeFirstLetter(key)}` : key;
        if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
            flattenObject(value, newKey, result);
        }
        else {
            result[newKey] = value;
        }
    }
    return result;
};
const createMetricXhr = (options) => (datas) => {
    return new Promise((resolve, reject) => {
        const { 
        // 必填
        appId, appVersion, country, reportUrl, measurement, 
        // 选填
        osType, build, deviceToken, userToken, terminalInfo, environmentInfo } = Object.assign({}, options);
        const xhr = new XMLHttpRequest();
        xhr.open('POST', reportUrl, true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.setRequestHeader('YQG-PLATFORM-SDK-TYPE', appId);
        xhr.setRequestHeader('Country', country);
        if (terminalInfo) {
            xhr.setRequestHeader('YQG-PLATFORM-TERMINAL-INFO', terminalInfo);
        }
        if (environmentInfo) {
            xhr.setRequestHeader('YQG-PLATFORM-ENVIRONMENT-INFO', environmentInfo);
        }
        if (deviceToken) {
            xhr.setRequestHeader('YQG-PLATFORM-DEVICE-TOKEN', deviceToken);
        }
        if (userToken) {
            xhr.setRequestHeader('userToken', userToken);
        }
        if (build) {
            xhr.setRequestHeader('build', build);
        }
        const data = {
            level: 'INFO',
            logs: datas.map(data => ({
                appId,
                measurement,
                appVersion,
                osType: osType || window.navigator.userAgent,
                time: new Date().getTime(),
                metricsType: data.type,
                parameter: flattenObject(data),
                uuid: data.uuid || null,
            }))
        };
        xhr.send(JSON.stringify(data));
        xhr.timeout = 5000;
        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                    resolve(true);
                }
                else {
                    reject(false);
                }
            }
        };
        xhr.onabort = () => reject(false);
        xhr.ontimeout = () => reject(false);
    });
};
export default createMetricXhr;
