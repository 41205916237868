<!-- @author giligili -->
<!-- @email zhimingwang@yangqianguan.com -->
<!-- @date 2021-08-31 14:31:09.252 -->
<!-- @desc generated by yqg-cli@5.1.2 -->

<template>
    <div class="yqg-antv-upload-file-url">
        <a-upload
            v-if="defaultFileList"
            :custom-request="customRequest"
            :default-file-list="defaultFileList"
            list-type="text"
            :disabled="disabled"
            v-bind="$attrs"
            :remove="onRemove"
            @change="onFileChange"
        >
            <div
                class="upload-control"
                :class="{disabled: !canUpload}"
                @click="upload"
            >
                {{ urls || '请上传文件' }}
            </div>
        </a-upload>
    </div>
</template>

<script type="text/babel">

    import handleUpload from '../mixins/handle-upload';

    export default {
        name: 'YqgAntvUploadFileUrl',

        mixins: [handleUpload],

        inheritAttrs: false,

        computed: {
            urls() {
                const {fileList} = this;

                return fileList.map(({url}) => url).join(', ');
            }
        },

        methods: {
            upload(event) {
                const {canUpload} = this;

                if (!canUpload) event.stopPropagation();
            }
        }
    };
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .yqg-antv-upload-file-url {
        ::v-deep .ant-upload-select {
            display: block;
        }

        .upload-control {
            border-bottom: 1px solid #bdbdbd;
            color: #ccc;
            cursor: pointer;

            &.disabled {
                cursor: not-allowed;
            }
        }
    }
</style>
