/**
 * @Author: giligili
 * @Date: 2023-03-22
 * @Last Modified by: giligili
 * @Last Modified time: 2023-03-31 下午05:13:29
 */
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { BroadcastChannel } from 'broadcast-channel';
import bus, { OnYtalkUserAgentCallRelease } from 'src/common/constant/event-bus';
import Storage from 'src/common/util/local-storage';
// 要在js中使用, 这里定义一下value
export var FlowType;
(function (FlowType) {
    FlowType["COMPLAINT"] = "COMPLAINT";
    FlowType["NORMAL"] = "NORMAL";
})(FlowType || (FlowType = {}));
var MessageType;
(function (MessageType) {
    MessageType["OPEN_MODAL"] = "OPEN_MODAL"; // 打开弹窗
})(MessageType || (MessageType = {}));
/**
 * @desc 数据放在 localStorage 中，防止出现数据不一致的情况
 *       通过 BroadcastChannel 触发行为
 */
class AutoFlowModalController {
    constructor() {
        this.channel = null;
        this.messageRepayMap = {
            [MessageType.OPEN_MODAL]: null
        };
        this.subscribeCallbackMap = {
            [FlowType.NORMAL]: null,
            [FlowType.COMPLAINT]: null
        };
        this.onChannelMessage = (message) => {
            const { type } = message, payload = __rest(message, ["type"]);
            const func = this.messageRepayMap[type];
            if (func)
                func(payload);
        };
        this.openChannel = () => {
            this.channel = new BroadcastChannel('AutoFlowModalController');
            this.channel.onmessage = this.onChannelMessage;
        };
        this.postMessage = (message) => {
            // ref: https://github.com/pubkey/broadcast-channel/blob/master/src/broadcast-channel.js
            if (this.channel.closed) {
                this.openChannel(); // 意外close, 重新open下
            }
            this.onChannelMessage(message);
            try {
                this.channel.postMessage(message);
            }
            catch (err) {
                if (typeof YqgReporter !== 'undefined') {
                    YqgReporter.fatalException(message, err);
                    return;
                }
                throw err;
            }
        };
        this.openModal = ({ modalType, callEndPayload }) => {
            // if (modalType)
            const func = this.subscribeCallbackMap[modalType];
            if (!func)
                return;
            func(callEndPayload);
        };
        this.subscribe = (type, callback) => {
            this.subscribeCallbackMap[type] = callback;
        };
        this.start = () => {
            Storage.setFlowModalContro({ start: true });
        };
        this.addFlow = (flow) => {
            const controll = Storage.getFlowModalContro({ start: false, flows: [] });
            const { start = false, flows = [] } = controll;
            if (!start)
                return;
            Storage.setFlowModalContro({ start, flows: [...flows, flow] });
        };
        this.run = (payload) => {
            const { extraData: { contactInfo = {} } = {} } = payload;
            const { flows = [] } = Storage.getFlowModalContro({ flows: [] });
            Storage.setFlowModalContro({ start: false });
            const types = flows
                .filter(({ encryptedMobileNumber }) => encryptedMobileNumber === contactInfo.encryptedCollecteeMobile)
                .map(({ type }) => type);
            const unExistTypes = [FlowType.NORMAL, FlowType.COMPLAINT]
                .filter(type => !types.includes(type));
            unExistTypes.forEach(type => this.postMessage({ type: MessageType.OPEN_MODAL, modalType: type, callEndPayload: payload }));
        };
        this.openChannel();
        this.messageRepayMap[MessageType.OPEN_MODAL] = this.openModal;
        bus.$on(OnYtalkUserAgentCallRelease, this.run);
    }
}
let controller = null;
// 单例
export default () => controller || (controller = new AutoFlowModalController());
