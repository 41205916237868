/*
 * @Author: yefenghan 
 * @Date: 2024-08-13 22:50:37 
 * @Last Modified by: yefenghan
 * @Last Modified time: 2024-08-14 16:27:06
 */

/** 
 * 添加催记来源枚举
 */
export const FLOW_SOURCE = {
    /** @desc 案件详情通知 催记提醒 */
    ALARM_ADD_FLOW: 'ALARM_ADD_FLOW',
    /** @desc 案件详情通知 回拨提醒 */
    ALARM_DIAL_BACK: 'ALARM_DIAL_BACK',
    /** @desc 案件详情通知 机器人转人工未接通提醒 */
    ALARM_SMART_IVR_TRANSFER_MANUAL: 'ALARM_SMART_IVR_TRANSFER_MANUAL',
    /** @desc 案件详情通知 雅安一键多呼转IVR回电 */
    ALARM_MULTI_DIAL_TRANSFER: 'ALARM_MULTI_DIAL_TRANSFER',
    /** @desc 案件详情通知 400进线需回电用户提醒 */
    ALARM_TRANSFER_CALL: 'ALARM_TRANSFER_CALL',
    /** @desc 案件详情通知 客服反馈提醒 */
    ALARM_FEEDBACK: 'ALARM_FEEDBACK',
    /** @desc 案件详情 借款人信息 */
    USER_INFO: 'USER_INFO',
    /** @desc 案件详情 标签列表 */
    LIST_CONTACT: 'LIST_CONTACT',
    /** @desc 案件详情  反馈记录 */
    FEEDBACK_LIST: 'FEEDBACK_LIST',
    /** @desc 诉讼委案详情 反馈记录 */
    FEEDBACK_LIST_LAWSUIT: 'FEEDBACK_LIST_LAWSUIT',
};

/** 
 * 添加投诉催记来源枚举
 */
export const COMPLAINT_FLOW_SOURCE = {
    /** @desc 案件详情通知 投诉催记 */
    ALARM_COMPLAINT: 'ALARM_COMPLAINT',
    /** @desc 案件详情 投诉催记模块 */
    COMPLAINT_FLOW: 'COMPLAINT_FLOW',
    /** @desc 案件详情 标签列表 */
    LIST_CONTACT: 'LIST_CONTACT',
    /** @desc 案件详情  反馈记录 */
    FEEDBACK_LIST: 'FEEDBACK_LIST',
    /** @desc 诉讼委案详情 反馈记录 */
    FEEDBACK_LIST_LAWSUIT: 'FEEDBACK_LIST_LAWSUIT',
};

/**
 * 两种催记通用来源
 */
export const COMMON_FLOW_SOURCE = {
    /** @desc 话后处理 */
    CALL_END: 'CALL_END',
};

