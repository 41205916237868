var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-popover",
    { attrs: { title: _vm.title, placement: "bottom" } },
    [
      _c(
        "ul",
        {
          staticClass: "feature-tips",
          attrs: { slot: "content" },
          slot: "content",
        },
        _vm._l(_vm.wechatHashCodeTipsText, function (tipText, index) {
          return _c("li", { key: index }, [
            _vm._v("\n            " + _vm._s(tipText) + "\n        "),
          ])
        }),
        0
      ),
      _vm._v(" "),
      _vm.$app.privileges.CASE.DETAIL.GET.MOBILE.HASH.CODE
        ? _c("a-icon", {
            staticClass: "yqg-add-official-wechat-friend",
            attrs: { type: "copy" },
            on: { click: _vm.openSelectSymbolModal },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }