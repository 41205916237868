var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mediate-instalment-list" },
    [
      _c("yqg-simple-table", {
        attrs: {
          records: _vm.records,
          pagination: false,
          options: _vm.$options.TableOptions,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }