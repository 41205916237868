/*
 * @Author: huayizhang 
 * @Date: 2021-06-29 17:34:08 
 * @Last Modified by:   huayizhang 
 * @Last Modified time: 2021-06-29 17:34:08 
 */

import Enum from 'collection-admin-web/common/util/enum';

export default Enum.from({
    FAIL: '违规',
    INIT: '待处理',
    WARN: '提示',
    PASS: '合规',
    EXPIRE: '过期'
});

export const ManualResultComplaintLevel = Enum.from({
    PASS: '正常',
    BONUS: '加分',
    DEDUCT: '扣分',
    INIT: '待处理'
});
