<!-- @author giligili -->
<!-- @email zhimingwang@yangqianguan.com -->
<!-- @date 2021-07-06 19:48:45.157 -->
<!-- @desc generated by yqg-cli@5.0.9 -->

<template>
    <div
        :class="['yqg-call-record-link', {'can-link': routerLink}]"
        @click="to(query)"
    >
        <component
            :is="is"
            v-if="text"
        >
            {{ text }}
        </component>

        <slot v-else />
    </div>
</template>

<script type="text/babel">
    import * as RouterName from 'collection-admin-web/common/constant/router-name';
    import RouterLink from 'collection-admin-web/common/mixin/router-link';

    export default {
        name: 'YqgCallRecordLink',

        mixins: [RouterLink(RouterName.CallRecord)],

        props: {
            text: {
                type: String,
                default: ''
            },

            query: {
                type: Object,
                default: () => ({})
            }
        },

        computed: {
            is() {
                return this.routerLink ? 'a' : 'span';
            }
        }
    };

</script>

<style lang="scss" scoped>
    .yqg-call-record-link {
        &.can-link {
            cursor: pointer;
        }
    }
</style>
