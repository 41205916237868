/*
 * @Author: chengyuzhang
 * @Date: 2020-05-12 10:43:56
 * @Last Modified by:   chengyuzhang
 * @Last Modified time: 2020-05-12 10:43:56
 */

export default {
    esc: 27,
    tab: 9,
    enter: 13,
    up: 38,
    down: 40,
    backSpace: 8
};
