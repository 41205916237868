/*
 * @Author: ruiwang
 * @Date: 2024-08-12 14:04:04
 * @Last Modified by: ruiwang
 * @Last Modified time: 2024-09-09 11:48:21
 */
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
const reportUrlMap = {
    'test': 'https://event-tracking-api-test.yangqianguan.com/logMetrics',
    'prod': 'https://event-tracking-api.yangqianguan.com/logMetrics',
    'prod-indo': 'https://event-tracking-api.easycash.id/logMetrics'
};
export default class MetricReporter {
    constructor(config) {
        this.reportConfig = config;
    }
    send(data, config, callback) {
        const { countryCode, isProd } = this.reportConfig;
        const urlKey = (!isProd && 'test') || (countryCode === 'IDN' && 'prod-indo') || 'prod';
        const reportUrl = reportUrlMap[urlKey];
        const xhr = new XMLHttpRequest();
        xhr.withCredentials = true;
        xhr.open('POST', reportUrl, true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.setRequestHeader('YQG-PLATFORM-SDK-TYPE', config.appId);
        if (config.userToken) {
            xhr.setRequestHeader('userToken', config.userToken);
        }
        xhr.setRequestHeader('Country', config.countryCode);
        xhr.send(JSON.stringify(data));
        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                    callback === null || callback === void 0 ? void 0 : callback(null, xhr);
                }
                else {
                    callback === null || callback === void 0 ? void 0 : callback(new Error(xhr.statusText), xhr);
                }
            }
        };
    }
    report(reportData, callback) {
        const { reportConfig } = this;
        const { appId, countryCode, size = 20, time = 1000, measurement, limitRate, postUplaod = false } = reportConfig;
        if (typeof limitRate !== 'undefined') {
            const random = Math.random();
            if (random > limitRate)
                return;
        }
        const { metricsType, userToken } = reportData, parameter = __rest(reportData, ["metricsType", "userToken"]);
        this.metricData = this.metricData || [];
        const metric = {
            appId,
            metricsType,
            uuid: userToken,
            measurement,
            parameter
        };
        if (!postUplaod) {
            this.send({
                level: 'INFO',
                logs: [metric]
            }, {
                appId,
                countryCode,
                userToken
            }, (err, res) => {
                if (!err) {
                    callback === null || callback === void 0 ? void 0 : callback(err, res);
                }
                else {
                    callback === null || callback === void 0 ? void 0 : callback(err, res);
                }
            });
            return;
        }
        this.metricData.push(metric);
        const processor = () => {
            var _a;
            if ((_a = this.metricData) === null || _a === void 0 ? void 0 : _a.length) {
                const dataSlice = this.metricData.splice(0, size);
                this.send({
                    level: 'INFO',
                    logs: dataSlice
                }, {
                    appId,
                    countryCode,
                    userToken
                }, (err, res) => {
                    var _a;
                    if (!err) {
                        this.metricTimer = setTimeout(processor, time);
                        callback === null || callback === void 0 ? void 0 : callback(err, res);
                    }
                    else {
                        (_a = this.metricData) === null || _a === void 0 ? void 0 : _a.unshift(...dataSlice);
                        callback === null || callback === void 0 ? void 0 : callback(err, res);
                    }
                });
            }
            else {
                if (this.metricTimer) {
                    clearTimeout(this.metricTimer);
                    this.metricTimer = null;
                }
            }
        };
        if (!this.metricTimer) {
            this.metricTimer = setTimeout(processor, time);
        }
    }
}
