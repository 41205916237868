/*
 * @Author: xiaodongyu
 * @Date 2020-08-25 09:55:26
 * @Last Modified by: zhengwang1 
 * @Last Modified time: 2023-09-14 19:11:11 
 */

export default {
    // tabs -> ua
    request: 'request', // 新开tab初始化请求 sync or tab添加共享数据
    // jssip ua method
    unregister: 'unregister', // 登出
    call: 'call', // 拨打
    terminateSessions: 'terminateSessions', // 挂断
    // jssip RTCSession method
    answer: 'answer', // 接听
    mute: 'mute', // 静音
    unmute: 'unmute',
    sendDTMF: 'sendDTMF', // 发送按键

    // ua -> tabs
    sync: 'sync', // 共享数据同步
    toast: 'toast',

    // ua -> active tab
    callConnected: 'callConnected',
    callEnd: 'callEnd',
    newMessage: 'newMessage',
    linkToCaseDetail: 'linkToCaseDetail',
    cancelActiveAction: 'cancelActiveAction',
    activeToast: 'activeToast'
};
