/**
 * @author center
 * @date 2019/6/13-下午1:21
 * @file worker-events
 */

export const PortEvents = {
    SetWorkerAgentInfo: 'YTalkSetWorkerAgentInfo',
    SetWorkerCallInfo: 'YTalkSetWorkerCallInfo',
    CloseActivePort: 'YTalkCloseActivePort',
    Answer: 'YTalkAnswer',
    Call: 'YTalkCall',
    mute: 'mute',
    unmute: 'unmute',
    HangUp: 'YTalkHangUp',
    SendDtmf: 'YTalkSendDtmf',
    UnRegister: 'YTalkUnRegister',
    CallReleased: 'YTalkCallReleased',
    SetCustomData: 'YTalkSetCustomData',
    SetSceneMap: 'YTalkSetSceneMap',
    TransferCallReleased: 'YTalkTransferCallReleased'
};

export const WorkerEvents = {
    SetPortAgentInfo: 'YTalkSetPortAgentInfo',
    SetPortCallInfo: 'YTalkSetPortCallInfo',
    AgentAnswer: 'YTalkAgentAnswer',
    AgentCall: 'YTalkAgentCall',
    AgentMute: 'AgentMute',
    AgentUnmute: 'AgentUnmute',
    AgentHangUp: 'YTalkAgentHangUp',
    AgentSendDtmf: 'YTalkAgentSendDtmf',
    AgentUnRegister: 'YTalkAgentUnRegister',
    OnCallReleased: 'YTalkOnCallReleased',
    OnTransferCallReleased: 'YTalkOnTransferCallReleased',
    GetCustomDataSuccess: 'YTalkGetCustomDataSuccess',
    SetPortSceneMap: 'YTalkSetPortSceneMap'
};
