var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c(
        "a-badge",
        {
          staticClass: "header-multi-call",
          attrs: { count: _vm.multiCallCount, offset: [3, -3] },
          on: { click: _vm.openMultiCallModal },
        },
        [
          _c(
            "svg",
            { attrs: { viewBox: "0 0 1024 1024", width: "16", height: "16" } },
            [
              _vm.multiCallCount
                ? _c("path", { attrs: { d: _vm.$options.CallSvg } })
                : _c("path", { attrs: { d: _vm.$options.CallNotSvg } }),
            ]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }