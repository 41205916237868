/**
 * @author giligili
 * @date 2021-07-05
 * @email zhimingwang@yangqianguan.com
 * @desc 毫秒 转换 为 00:01
 */

export default millis => {
    const minutes = Math.floor(millis / 60000);
    const seconds = ((millis % 60000) / 1000).toFixed(0);

    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
};
