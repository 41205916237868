/*
 * @Author: huayizhang 
 * @Date: 2023-03-27 17:11:44 
 * @Last Modified by: huayizhang
 * @Last Modified time: 2023-03-27 17:52:51
 */

/**
 * 把一个代码编辑器中的 Javascript 原生对象字符串转成 JSON 字符串
 * 实现方法是创造真正的 Javascript 执行环境将完整的对象 return 出去，再 stringify
 * 为了防止坏人在代码块中植入可执行代码，强制代码块必须被包裹在花括号中，且代码中的括号将被去除
 * @param str 需要处理的字符串
 * @returns {*}
 */
export const pureJSONString = (str = '') => {
    str = str.trim();
    if (!((str[0] === '{' && str.slice(-1) === '}') || (str[0] === '[' && str.slice(-1) === ']'))) return '';

    const SYMBOLS = {
        LEFT_BRACE: '&#40;',
        RIGHT_BRACE: '&#41;'
    };

    // replace braces to prevent function call
    str = str.replace('(', SYMBOLS.LEFT_BRACE)
        .replace(')', SYMBOLS.RIGHT_BRACE);

    return JSON.stringify((new Function('', `return ${str};`))()) // eslint-disable-line
        .replace(SYMBOLS.LEFT_BRACE, '(')
        .replace(SYMBOLS.RIGHT_BRACE, ')');
};

// vm-用来调$toast
export const reformatJson = (code, vm, indent = 4, isShowToast = true) => {
    try {
        code = JSON.stringify(JSON.parse(code), null, indent);
        if (isShowToast) {
            vm?.$toast?.success?.('Reformat 成功');
        }
    } catch (err) {
        vm?.$toast?.error?.('Reformat 失败，只支持对严格的 JSON 格式进行 Reformat');
        throw err;
    }

    return code;
};
