/**
 * @author giligili
 * @date 2021-08-27
 * @email zhimingwang@yangqianguan.com
 * @desc 下载功能, 替代resource.download
 *
 * 处理返回值是 json 的特殊情况(导出报错用 弹窗提示)
 */

import {CODE_SUCCESS} from 'collection-admin-web/common/constant/yqg-status';
import {saveFileFromResponse} from 'collection-admin-web/common/util/tool';

/**
 * @param {Object} options - 配置
 * @param {Function} options.resource - 请求方法
 * @param {Object} options.params - get 请求payload
 * @param {Object} options.data - post 请求payload
 * @return {Promise} reject - 下载异常, fulfilled - 下载成功/需要特殊处理的逻辑(异步导出的弹窗)
 */
export default function download({resource, params = {}, data}, {fileName, hideLoading = false} = {}) {
    const vals = data
        ? [data, {responseType: 'blob', hideLoading}]
        : [{responseType: 'blob', hideLoading, params}];

    return resource(...vals)
        .then(response => {
            const {data: {type}} = response;

            if (type !== 'application/json') {
                saveFileFromResponse(response, fileName);

                return Promise.resolve({data: {body: true}});
            }

            let reject = null;
            let resolve = null;

            const promise = new Promise((res, rej) => {
                reject = rej;
                resolve = res;
            });

            const reader = new FileReader();

            reader.onload = ({target: {result}}) => {
                const {status: {code, detail = '未知错误'}} = JSON.parse(result);

                if (code !== CODE_SUCCESS) {
                    this.$error({title: code, content: detail});

                    reject();
                }

                resolve({data: {...JSON.parse(result)}});
            };

            reader.onerror = () => {
                this.$error({content: '系统异常(数据无法解析)'});

                reject();
            };

            reader.readAsText(response.data, 'utf-8');

            return promise;
        });
}
