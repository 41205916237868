var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { CountryMap } from '@shared/common/constant/country';
import { STAGE } from '@shared/common/constant/stage';
import ReportUrlMap from './constant/report-url';
import DB from './db';
import createMetricXhr from './xhr';
const sleep = (time) => new Promise(resolve => setTimeout(resolve, time));
class MetricStrategy {
    constructor(options, config) {
        this.loadConfig = (options) => {
            let { stage, country, } = options;
            const { url } = options;
            stage = stage || STAGE === 'dev' ? 'test' : STAGE;
            country = country || CountryMap[stage];
            if (!country)
                throw new Error('country is required in MetricStrategy');
            const urlStage = this.getUrlStage(stage);
            const reportUrl = url || (stage ? ReportUrlMap[urlStage] : '');
            if (!reportUrl)
                throw new Error('url is required in MetricStrategy');
            return {
                country,
                reportUrl,
            };
        };
        this.getUrlStage = (stage) => {
            if (!stage)
                return 'TEST';
            if (/test/.test(stage))
                return 'TEST';
            if (/prod-indo/.test(stage))
                return 'INDO_PROD';
            if (/prod/.test(stage))
                return 'PROD';
            return 'TEST';
        };
        // 写入数据
        this.report = (data) => {
            var _a, _b;
            if (Math.random() > this.limitRate)
                return;
            this.logSource.write(Object.assign(Object.assign({}, (_b = (_a = this.options).getDefaultData) === null || _b === void 0 ? void 0 : _b.call(_a)), data));
            this.start();
        };
        // 初始化 20s 后开始上报
        this.init = () => {
            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                this.push();
            }, 20e3);
        };
        this.start = () => {
            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                this.push();
            }, 5e3);
        };
        this.stop = () => {
            clearTimeout(this.timeout);
        };
        this.push = () => {
            this.promise = this.promise.then(() => this.send())
                .then(() => sleep(2e3));
        };
        this.exception = (kind, error) => {
            var _a, _b;
            this.metricReport([Object.assign(Object.assign({}, (_b = (_a = this.options).getDefaultData) === null || _b === void 0 ? void 0 : _b.call(_a)), { type: 'DB_ERROR', kind, message: error.message, reason: error.name })]);
        };
        this.send = () => __awaiter(this, void 0, void 0, function* () {
            const { limit } = this.options;
            try {
                // 断网停止
                if (!window.navigator.onLine)
                    return;
                const { data, resolve, reject } = yield this.logSource.read(limit || 10);
                if (data.length === 0)
                    return;
                try {
                    yield this.metricReport(data);
                    resolve();
                }
                catch (_a) {
                    reject();
                }
                this.start();
            }
            catch (err) {
                this.start();
            }
        });
        const { limitRate } = options;
        this.options = Object.assign({}, {
            getDefaultData: () => ({})
        }, options);
        this.limitRate = limitRate || 0.1;
        this.reportConfig = Object.assign(Object.assign({}, this.loadConfig(options)), config);
        this.metricReport = createMetricXhr(this.reportConfig);
        const { dbName, tbName, version } = this.options;
        this.logSource = new DB({
            name: dbName || 'ytalk',
            tbName: tbName || 'metric',
            version: version || 1,
            onError: ({ type, error }) => {
                this.exception(type, error);
            },
        });
        this.promise = Promise.resolve();
        // 暂时先不错峰少报了
        // window.addEventListener('online', () => {
        //     this.start();
        // });
        window.addEventListener('offline', () => {
            this.stop();
        });
        this.init();
    }
}
export default MetricStrategy;
