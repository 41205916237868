<!-- @Author: xymo -->
<!-- @Date: 2021-04-13 -->
<!-- @Last Modified by: yefenghan -->
<!-- @Last Modified time: 2024-08-08 17:07:59 -->

<template>
    <a-layout-content class="content">
        <a-breadcrumb
            v-if="isShowBreadcrumb"
            class="mb10"
        >
            <template v-for="({name, meta: {navTitle, hideInBreadcrumb}}, idx) in $route.matched">
                <a-breadcrumb-item
                    v-if="!hideInBreadcrumb"
                    :key="name"
                >
                    <router-link :to="idx === $route.matched.length - 1 ? $route : {name}">
                        {{ navTitle }}
                    </router-link>
                </a-breadcrumb-item>
            </template>
        </a-breadcrumb>

        <template v-if="isShowContentHead">
            <h2 v-text="$route.meta.navTitle" />
            <a-divider class="yqg-divider" />
        </template>
        <router-view />
    </a-layout-content>
</template>

<script type="text/babel">
    export default {
        name: 'Content',
        computed: {
            isYchatQuoted() {
                return this.$app.isYchatQuoted;
            },
            isShowBreadcrumb() {
                return !this.isYchatQuoted && !this.$route.meta.customBreadcrumb;
            },
            isShowContentHead() {
                return !this.isYchatQuoted && !this.$route.meta.customContentHead;
            },
        }
    };
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.content {
    background: transparent;
}
</style>
