/*
 * Author: xymo
 * Created Date: 2023-10-17
 * email: xymo@yangqianguan.com
 */
<!-- @Author: giligili -->
<!-- @Date: 2021-08-02 16:03:50.148 -->
<!-- @Last Modified by: giligili -->
<!-- @Last Modified time: 2023-05-18 14:46:35 -->

<template>
    <div class="header-notification">
        <a-popover
            v-model="popoverVisible"
            class="header-notification"
            placement="leftBottom"
            trigger="none"
            :get-popup-container="getPopupContainer"
            :overlay-class-name="overlayClassName"
        >
            <a-badge
                :number-style="numberStyle"
                :count="notificationCount"
                :offset="[3, -3]"
            >
                <a-icon type="bell" />
            </a-badge>

            <template #content>
                <div class="popover">
                    <div class="title">
                        <h4 class="mb0">
                            通知
                        </h4>
                        <div class="btns">
                            <a-tooltip
                                v-if="$app.permissions.NOTIFICATION_MUTATE"
                                placement="top"
                                :get-popup-container="triggerNode => triggerNode"
                            >
                                <template slot="title">
                                    <div>清理非账号案件下的提醒</div>
                                    <div>过程可能会有延迟, 请耐心等待</div>
                                </template>

                                <a-button
                                    size="small"
                                    type="primary"
                                    @click="clearNotification"
                                    v-text="'消息清理'"
                                />
                            </a-tooltip>

                            <yqg-router-link
                                v-if="$app.permissions.WORK_STATUS_NOTIFICATION"
                                to="workStatusNotification"
                            >
                                <a-button
                                    size="small"
                                    type="primary"
                                    v-text="'状态提醒'"
                                />
                            </yqg-router-link>

                            <yqg-router-link
                                v-if="$app.permissions.CASE_FEEDBACK_FLOW_RELATIVE_QUERY"
                                to="feedbackRecord"
                            >
                                <a-button
                                    size="small"
                                    type="primary"
                                    v-text="'工单反馈'"
                                />
                            </yqg-router-link>

                            <yqg-router-link to="remind">
                                <a-button
                                    size="small"
                                    type="primary"
                                    v-text="'案件提醒'"
                                />
                            </yqg-router-link>

                            <yqg-router-link to="app">
                                <a-button
                                    size="small"
                                    v-text="'APP登录历史'"
                                />
                            </yqg-router-link>
                        </div>
                    </div>

                    <div class="container mt20">
                        <template v-if="notifications.length">
                            <div
                                v-for="(notification, index) in notifications"
                                :key="index"
                                class="notification"
                            >
                                <component
                                    :is="$options.ComponentMap[notification.type]"
                                    :notification="notification"
                                />

                                <div class="mt20 received-time">
                                    {{ notification.timeReceived | dateTime }}
                                </div>
                            </div>
                        </template>

                        <a-empty v-else />
                    </div>
                </div>
            </template>
        </a-popover>
    </div>
</template>

<script type="text/babel">

    import {mapGetters, mapActions} from 'vuex';

    import popover from 'src/common/mixin/popover';

    import AlarmAddFlow from './components/alarm-add-flow';
    import AlarmAuditAbnormal from './components/alarm-audit-abnormal'; // eslint-disable-line
    // 协催相关
    import AlarmAssistCase from './components/alarm-assist-case';
    import AlarmAssistCaseAgree from './components/alarm-assist-case-agree';
    import AlarmAssistCaseFinish from './components/alarm-assist-case-finish';
    import AlarmAssistCaseRefuse from './components/alarm-assist-case-refuse';
    import AlarmAssistCaseStop from './components/alarm-assist-case-stop';
    import AlarmCase from './components/alarm-case';
    import AlarmComplaint from './components/alarm-complaint';
    import AlarmDialBack from './components/alarm-dial-back';
    import AlarmFeedback from './components/alarm-feedback';
    import AlarmFeedbackFlow from './components/alarm-feedback-flow';
    import AlarmFlowEnd from './components/alarm-flow-end';
    import AlarmFlowReceived from './components/alarm-flow-received';
    import AlarmIvrTransferManual from './components/alarm-ivr-transfer-manual';
    import AlarmMultiDialTransfer from './components/alarm-multi-dial-transfer';
    import AlarmOaAddAccount from './components/alarm-oa-add-account';
    import AlarmRemindCase from './components/alarm-remind-case';
    import AlarmRemoteLogin from './components/alarm-remote-login';
    import AlarmRepayReceiptResult from './components/alarm-repay-receipt-result';
    import AlarmRiskUpgradeAssign from './components/alarm-risk-upgrade-assign';
    import AlarmSensitiveAssignCase from './components/alarm-sensitive-assign-case';
    import AlarmTransferCall from './components/alarm-transfer-call';
    import AlarmUrgeSensitiveAssignCase from './components/alarm-urge-sensitive-assign-case';
    import AlarmUserLogin from './components/alarm-user-login';
    import AlarmWechatMessage from './components/alarm-wechat-message';
    import BlackProductionUserAssign from './components/black-production-user-assign';
    import ExpertCaseEntryMessage from './components/expert-case-entry-message';
    import RepayFailMessage from './components/repay-fail-message';
    import WorkStatusWarnDuration from './components/work-status-warn-duration';
    import ComponentMap from './constant/component-map';

    const REQUEST_TIME_INTERVAL = 60 * 1e3;

    const ANIMATION_PERIOD = 3 * 1e3;

    export default {
        name: 'HeaderNotification',

        ComponentMap,

        components: {
            AlarmCase,
            AlarmAddFlow,
            AlarmFlowEnd,
            AlarmFeedback,
            AlarmComplaint,
            AlarmDialBack,
            AlarmUserLogin,
            AlarmRemindCase,
            AlarmRemoteLogin,
            AlarmFeedbackFlow,
            AlarmFlowReceived,
            AlarmAuditAbnormal,
            AlarmRepayReceiptResult,
            AlarmMultiDialTransfer,
            AlarmTransferCall,
            AlarmRiskUpgradeAssign,
            AlarmIvrTransferManual,

            AlarmAssistCase,
            AlarmAssistCaseStop,
            AlarmAssistCaseAgree,
            AlarmAssistCaseRefuse,
            AlarmAssistCaseFinish,

            AlarmWechatMessage,

            AlarmSensitiveAssignCase,
            AlarmUrgeSensitiveAssignCase,

            // 还款失败提醒
            RepayFailMessage,

            // 敏感词撤案提醒
            BlackProductionUserAssign,

            // 专家案件录入提醒
            ExpertCaseEntryMessage,

            // 工作状态提醒
            WorkStatusWarnDuration,

            // 新增账号提醒
            AlarmOaAddAccount
        },

        mixins: [popover(['.bubble-modal', '.feedback-alert-modal'])],

        data() {
            return {
                numberStyle: {}
            };
        },

        computed: {
            ...mapGetters('notification', ['notificationList', 'newNotificationCount']),

            notificationCount() {
                return this.notifications.length;
            },

            notifications() {
                const {notificationList} = this;

                return notificationList.filter(({type}) => ComponentMap[type]);
            }
        },

        watch: {
            newNotificationCount(nextValue) {
                if (!nextValue) {
                    this.numberStyle = {};

                    return;
                }

                this.popoverOpen();

                this.numberStyle = {
                    animation: 'header-menu-notification-trembling 0.5s infinite linear',
                    top: '-8px', // 这里 -8 而不是 -11 是因为badge.offset.y = -3 (margin-top: -3px)
                    right: '-11px'
                };

                setTimeout(() => (this.numberStyle = {}), ANIMATION_PERIOD);
            }
        },

        mounted() {
            // 延迟50ms 防止抢占页面并发限制
            setTimeout(this.fetchNotificationList, 50);

            this.notificationListPollingInit();
        },

        beforeDestroy() {
            this.notificationListPollingEnd();
        },

        methods: {
            ...mapActions('notification', ['fetchNotificationList', 'clearNotification']),

            notificationListPollingInit() {
                this.timer = setInterval(this.fetchNotificationList, REQUEST_TIME_INTERVAL);
            },

            notificationListPollingEnd() {
                clearInterval(this.timer);
            }
        }
    };

</script>

<style lang="scss" rel="stylesheet/scss">
    @import "collection-admin-web/common/style/mixin.scss";
    @import "collection-admin-web/common/style/variables.scss";

    @keyframes header-menu-notification-trembling {
        0% {
            transform: rotate(0deg);
        }

        25% {
            transform: rotate(15deg);
        }

        50% {
            background-color: $red-base;
            box-shadow: 0 0 10px $red-light;
            transform: rotate(0deg);
        }

        75% {
            transform: rotate(-15deg);
        }

        100% {
            transform: rotate(0deg);
        }
    }

    .header-notification {
        .popover {
            width: 480px;
            padding: 15px;

            .title {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            .container {
                @include scroll-y(520px);

                .notification {
                    padding: 10px;
                    margin-bottom: 5px;
                    border: 1px solid $gray-base;
                    border-radius: 2px;

                    .received-time {
                        font-size: 0.8em;
                        color: $gray-base;
                    }
                }
            }
        }
    }
</style>
