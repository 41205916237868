<!-- @author xiaodongyu -->
<!-- @email xiaodongyu@yangqianguan.com -->
<!-- @date 2019-12-12 18:30:12 -->
<!-- @desc field-child-form.vue -->

<script type="text/babel">
export default {
    name: 'FieldGroupForm',

    props: {
        def: {
            type: Object,
            required: true
        },

        value: {
            type: Object,
            default: () => ({})
        },

        ctx: {
            type: Object,
            default: () => ({})
        }
    },

    data() {
        return {
            curValue: this.value
        };
    },

    methods: {
        onChange({record} = {}) {
            this.curValue = record;
        },

        getFormValues() {
            return this.$refs.groupForm.getFormValues();
        },

        async onConfirm() {
            const {def, $refs} = this;
            const {err, values, record} = await $refs.groupForm.onConfirm();

            this.$emit('change', record);

            return {err, values, record, def};
        },

        renderSlot(name, props = {}) {
            const {$scopedSlots} = this;

            return $scopedSlots[name] && $scopedSlots[name](props);
        },

        renderForm() {
            const {
                ctx, onChange, curValue, def: {title, fields}
            } = this;
            const formOptions = {
                layout: 'vertical',
                fieldDefs: fields
            };

            return (
                <a-card title={title}>
                    <yqg-simple-form
                        ref="groupForm"
                        ctx={ctx}
                        values={curValue}
                        options={formOptions}
                        vOn:change={formValues => onChange(formValues)}
                    />
                </a-card>
            );
        }
    },

    render() {
        const {renderSlot, renderForm} = this;

        return (
            <a-config-provider autoInsertSpaceInButton={false}>
                <div class="field-group-form">
                    {renderSlot('head')}
                    {renderForm()}
                    {renderSlot('foot')}
                </div>
            </a-config-provider>
        );
    }
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .field-group-form {
        .yqg-simple-form {
            ::v-deep .ant-form {
                .ant-form-item-label {
                    justify-content: flex-start;
                }
            }
        }
    }
</style>
