/**
 * @author giligili
 * @date 2023-03-14
 * @email zhimingwang@fintopia.tech
 * @desc enums
 */

import Enum from 'collection-admin-web/common/util/enum';

export const StatusEnum = Enum.from({
    PROCEEDING: '未还款',
    FINISHED: '已结清',
    INVALID: '失效'
});
