/*
 * @Author: xiaodongyu
 * @Date 2019-11-22 12:56:05
 * @Last Modified by: huayizhang
 * @Last Modified time: 2022-10-10 13:47:11
 */

export default {
    text: 'text', // default field type
    number: 'number',
    color: 'color',
    textarea: 'textarea',
    date: 'date',
    dateTime: 'dateTime',
    dateRange: 'dateRange',
    month: 'month',
    switch: 'switch',
    select: 'select',
    virtualSelect: 'virtualSelect',
    tree: 'tree',
    checkbox: 'checkbox',
    radio: 'radio',
    file: 'file',
    code: 'code',
    sql: 'sql',
    buttonGroup: 'buttonGroup',
    markdown: 'markdown',
    time: 'time',
    rich: 'rich',
    autoComplete: 'autoComplete',
    group: 'group'
};
