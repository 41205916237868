/**
 * @author giligili
 * @date 2022-03-04
 * @email zhimingwang@yangqianguan.com
 * @desc 版本检查
 */

import CommonApiRouter from 'yqg-common/constant/CommonApiRouter';
import http from 'yqg-common/vue/vue-http';

import getVersionClient from 'collection-admin-web/common/worker/version/client';

const NOOP = () => {};

const DEFAULT_OPTIONS = {
    timeInterval: 10 * 60 * 1000
};

let versionWorker = null;
let lastValidClickTimestamp = 0;

export default function checkVersion({
    options: {
        timeInterval
    } = DEFAULT_OPTIONS,
    upgradeCallback = NOOP,
    errorCallback = NOOP
}) {
    if (!window.SharedWorker) {
        errorCallback();

        return;
    }

    if (!versionWorker) {
        versionWorker = getVersionClient('VersionWorker');
    }

    function check() {
        const timestamp = new Date().getTime();

        if (timestamp - lastValidClickTimestamp < timeInterval) {
            return;
        }

        lastValidClickTimestamp = timestamp;

        http.get(CommonApiRouter.apiWeb.getVersion, {hideLoading: true})
            .then(({data: {body}}) => body)
            .then(remoteVersionInfo => {
                window.VERSION_INFO = remoteVersionInfo;

                versionWorker.get()
                    .then(localVersionInfo => {
                        // 第一个页面刚被打开
                        if (!localVersionInfo || !localVersionInfo.version) {
                            versionWorker.update(remoteVersionInfo);

                            return;
                        }

                        if (localVersionInfo.version === remoteVersionInfo.version) {
                            return;
                        }

                        upgradeCallback(remoteVersionInfo);
                    });
            })
            .catch(err => err);
    }

    check();

    document.addEventListener('click', check);
}
