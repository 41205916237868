<!--
 * @Author: xiaoduoshu
 * @Email: xiaoduoshu@fintopia.tech
 * @Date: 2022-08-24 14:21:23
-->

<template>
    <div
        v-if="Number.isInteger(surplusCallCnt)"
        class="wrap-surplus-call-cnt"
    >
        <a-popover
            :placement="placement"
        >
            <template #content>
                <div>
                    手拨次数：{{ manualCallCnt }}
                    <br>
                    <span v-if="ivrCallCnt">机器人拨打次数：{{ ivrCallCnt }}</span>
                </div>
            </template>

            <slot>
                <span class="surplus-call-cnt">
                    ({{ surplusCallCnt }})
                </span>
            </slot>
        </a-popover>
    </div>
</template>

<script type="text/babel">
    export default {
        name: 'YqgSurplusCallCnt',

        props: {
            surplusCallCnt: {
                type: Number,
                required: true
            },

            manualCallCnt: {
                type: Number,
                required: true
            },

            ivrCallCnt: {
                type: Number,
                default: 0
            },

            placement: {
                type: String,
                default: 'bottom'
            }
        }
    };
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .wrap-surplus-call-cnt {
        display: inline;

        .surplus-call-cnt {
            cursor: pointer;
        }
    }
</style>
