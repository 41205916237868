var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "yqg-simple-drawer yqg-drawer-wrapper" },
    _vm._l(_vm.drawerMap, function (ref, key) {
      var visible = ref.visible
      var drawerComponent = ref.drawerComponent
      var componentProps = ref.componentProps
      var dialogProps = ref.dialogProps
      var close = ref.close
      var dismiss = ref.dismiss
      var afterVisibleChange = ref.afterVisibleChange
      var getContainer = ref.getContainer
      return _c(
        "a-drawer",
        _vm._b(
          { key: key, on: { close: dismiss } },
          "a-drawer",
          Object.assign(
            {},
            {
              visible: visible,
              afterVisibleChange: afterVisibleChange,
              destroyOnClose: true,
              width: 500,
              getContainer: getContainer,
            },
            drawerComponent.dialogProps,
            dialogProps
          ),
          false
        ),
        [
          _c(
            drawerComponent,
            _vm._g(
              _vm._b(
                { tag: "component" },
                "component",
                _vm.getDrawerComponentProps({
                  drawerComponent: drawerComponent,
                  componentProps: componentProps,
                  close: close,
                  dismiss: dismiss,
                }),
                false
              ),
              Object.assign(
                {},
                { close: close, dismiss: dismiss },
                componentProps && componentProps.vOn
              )
            )
          ),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }