/**
 * @Author: giligili
 * @Date: 2021-07-27
 * @Last Modified by: giligili
 * @Last Modified time: 2023-05-05 11:34:23
 */

import EnumAll from 'collection-admin-web/common/constant/enum';
import {getSdTextStaticProps} from 'collection-admin-web/common/constant/fields';
import {genField} from 'collection-admin-web/common/util/tool';

export const timeCreated = genField('申请时间', 'timeCreated', 'dateTime');
export const collectorName = genField('申请人', 'collectorName', 'input');
export const type = genField('减免方式', 'type', 'input', {
    enumType: EnumAll.Trial.CouponAndReduceTypeEnum
});
export const reduceAmount = genField('减免金额', 'reduceAmount', 'numberCommasFixed2', {
    filter: 'numberCommasFixed2'
});
export const status = genField('结果', 'status', 'input', {
    enumType: EnumAll.Trial.CouponAndReduceStatusEnum,
    staticProps: getSdTextStaticProps(val => ['SUCCESS', 'VALID'].includes(val))
});
