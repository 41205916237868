<!-- @Author: kaiwang -->
<!-- @Date: 2022-3-24 17:46:10 -->
<!-- @Last Modified by: kaiwang -->
<!-- @Last Modified time: 2023-05-22 18:06:22 -->

<template>
    <a-select
        v-model="compValue"
        show-search
        v-bind="def.props"
        :filter-option="filterOption"
        @change="onChange"
        @search="onSearch"
    >
        <div
            slot="dropdownRender"
            ref="list"
            slot-scope="menu"
            class="infinite-list-container"
            @scroll="scrollEvent($event)"
        >
            <div
                class="infinite-list-fake"
                :style="{ height: fakeListHeight + 'px' }"
            />
            <div
                class="infinite-list"
                :style="{ transform: getTransform }"
            >
                <v-nodes :vnodes="restructure(menu)" />
            </div>
        </div>

        <a-select-option
            v-for="{label, value: val, pinyin, ...rest} in selectOptions"
            v-bind="rest"
            :key="val"
            :value="val"
            :pinyin="pinyin"
        >
            <a-tooltip
                v-if="def.tooltipOption"
                :title="label"
            >
                {{ label }}
            </a-tooltip>
            <template v-else>
                {{ label }}
            </template>
        </a-select-option>
    </a-select>
</template>

<script>
import {enumType} from '../../../../mixin';
import {numbersToStr} from '../../../../util/object';

export default {
    name: 'FieldSelect',

    components: {
        VNodes: {
            functional: true,
            render: (h, ctx) => ctx.props.vnodes,
        }
    },

    mixins: [enumType],

    props: {
        value: {
            type: [Number, String, Array],
            default: undefined
        },

        def: {
            type: Object,
            required: true
        }
    },

    data() {
        const {def: {props: {mode}}, value} = this;
        const compValue = mode === 'multiple' && !value ? undefined : numbersToStr(value);

        return {
            compValue,
            searchText: undefined,
            changed: false,
            itemHeight: 32,
            screenHeight: 256,
            startOffset: 0,
            start: 0,
            end: null,
            validOptionsLength: 0
        };
    },

    computed: {
        selectOptions() {
            const {
                def: {validateSearch, autoSearchMinLen = Infinity},
                compValue,
                searchText,
                options
            } = this;

            if (validateSearch || options.length > autoSearchMinLen) {
                return options.filter(({value, pinyin}) => {
                    if (searchText) {
                        return pinyin.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
                    }

                    return [].concat(compValue).includes(value);
                });
            }

            return options;
        },
        fakeListHeight() {
            return (this.validOptionsLength || this.selectOptions.length) * this.itemHeight;
        },
        visibleCount() {
            return Math.ceil(this.screenHeight / this.itemHeight);
        },
        getTransform() {
            return `translate3d(0,${this.startOffset}px,0)`;
        }
    },

    watch: {
        value(val) {
            if (val === this.compValue) return;

            const {def: {props: {mode}}} = this;
            if (mode === 'multiple' && !val) val = undefined;

            this.getSuperText(val);
        },

        wrappedEnumType() {
            const {value} = this;
            this.getSuperText(value);
        }
    },

    mounted() {
        this.end = this.start + this.visibleCount;
    },

    methods: {
        filterOption(input, option) {
            return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        },

        onChange(val) {
            const {wrappedEnumType, changed} = this;

            if (!changed && Array.isArray(val) && wrappedEnumType) {
                val = val.filter(item => wrappedEnumType.MAP[item]);
                this.changed = true;
            }

            this.$emit('change', val);
        },

        onSearch(val) {
            this.searchText = val;
            this.$emit('search', val);
            this.start = 0;
            this.end = this.start + this.visibleCount;
            this.startOffset = 0;
            const scrollEle = this?.$refs?.list;
            scrollEle?.scrollTo(0, 0);
        },

        getSuperText(value) {
            const {wrappedEnumType} = this;
            let res;
            const getText = key => {
                if (wrappedEnumType && !wrappedEnumType.MAP[key]) {
                    return wrappedEnumType.getText(key);
                }

                return key;
            };

            if (Array.isArray(value)) {
                res = value.map(item => getText(item));
            } else {
                res = getText(value);
            }

            this.compValue = numbersToStr(res) ?? undefined;
        },

        scrollEvent() {
            const scrollEle = this?.$refs?.list;
            const scrollTop = scrollEle?.scrollTop;
            this.start = Math.floor(scrollTop / this.itemHeight);
            this.end = this.start + this.visibleCount;
            this.startOffset = scrollTop;
        },

        restructure(menu) {
            this.validOptionsLength = menu?.data?.props?.menuItems?.length;
            const menuItems = menu?.componentOptions?.propsData?.menuItems.slice(this.start, Math.min(this.end, this.validOptionsLength || this.selectOptions.length));
            this.$set(menu?.componentOptions?.propsData, 'menuItems', menuItems);

            return menu;
        }
    }
};
</script>

<style lang="scss" scoped>
    .infinite-list-container {
        height: 100%;
        overflow: auto;
        position: relative;
        -webkit-overflow-scrolling: touch;

        .infinite-list-fake {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            z-index: -1;
        }

        ::v-deep .ant-select-dropdown-menu {
            overflow: hidden;
            max-height: 256px;
            padding: 0;
        }
    }
</style>
