/**
 * @author giligili
 * @date 2023-03-17
 * @email zhimingwang@fintopia.tech
 * @desc form-options
 */

import * as Fields from 'src/common/layout/constant/reduce/fields';

export default {
    layout: 'horizontal',
    fieldDefs: [
        Fields.timeCreated,
        Fields.collectorName,
        Fields.type,
        Fields.reduceAmount,
        Fields.status
    ]
};
