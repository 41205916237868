var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "fileEl", staticClass: "header-file-list" },
    [
      _c(
        "a-popover",
        {
          attrs: {
            title: "文件列表",
            placement: "leftBottom",
            trigger: "click",
            "get-popup-container": _vm.getPopupContainer,
          },
          scopedSlots: _vm._u([
            {
              key: "content",
              fn: function () {
                return [
                  _c("yqg-simple-table", {
                    attrs: {
                      options: _vm.$options.TableOptions,
                      records: _vm.records,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "op",
                        fn: function (ref) {
                          var record = ref.record
                          return [
                            record.downloadable ===
                              _vm.$options.BooleanType.TRUE && record.fileUrl
                              ? _c(
                                  "a-button",
                                  {
                                    staticClass: "yqg-btn-info",
                                    attrs: { size: "small" },
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          download: "",
                                          href: record.fileUrl,
                                        },
                                      },
                                      [_vm._v("下载")]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("a-icon", {
            staticClass: "header-file-list",
            attrs: { type: "file" },
            on: { click: _vm.fetchFileList },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }