var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "edit-mediate-plan-modal" },
    [
      _vm.isEdit
        ? _c("yqg-simple-form", {
            ref: "ysf",
            attrs: {
              title: "协商方案申请",
              values: _vm.defaultCond,
              options: _vm.$options.FormOptions,
            },
            on: {
              cancel: _vm.onCancel,
              reset: _vm.onReset,
              confirm: _vm.onConfirm,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "orderId",
                  fn: function () {
                    return [
                      _c("span", [_vm._v(_vm._s(_vm.orderId))]),
                      _vm._v(" "),
                      _vm.remainAmountVisible
                        ? _c("span", [
                            _vm._v("（" + _vm._s(_vm.remainAmount) + "元）"),
                          ])
                        : _vm._e(),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              1420008814
            ),
          })
        : _c("yqg-static-form", {
            attrs: {
              title: "协商方案查看",
              "dynamic-props": "",
              values: _vm.defaultCond,
              options: _vm.$options.FormOptions,
            },
            scopedSlots: _vm._u([
              {
                key: "orderId",
                fn: function () {
                  return [
                    _c("span", [_vm._v(_vm._s(_vm.orderId))]),
                    _vm._v(" "),
                    _vm.remainAmountVisible
                      ? _c("span", [
                          _vm._v("（" + _vm._s(_vm.remainAmount) + "元）"),
                        ])
                      : _vm._e(),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }