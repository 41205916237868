<!-- @Author: giligili -->
<!-- @Date: 2023-03-14 23:34:31 -->
<!-- @Last Modified by: giligili -->
<!-- @Last Modified time: 2023-03-14 23:34:31 -->

<template>
    <div class="mediate-instalment-list">
        <yqg-simple-table
            :records="records"
            :pagination="false"
            :options="$options.TableOptions"
        />
    </div>
</template>

<script type="text/babel">

    import Mediate from 'src/common/resource/mediate';

    import TableOptions from './constant/table-options';

    export default {
        name: 'MediateInstalmentList',

        TableOptions,

        props: {
            orderId: {
                type: String,
                required: true
            },

            mediationSchemeId: {
                type: [Number, String],
                required: true
            }
        },

        data() {
            return {
                records: []
            };
        },

        watch: {
            mediationSchemeId: {
                handler() {
                    this.fetchMediationInstalments();
                }
            }
        },

        mounted() {
            this.fetchMediationInstalments();
        },

        methods: {
            fetchMediationInstalments() {
                const {orderId, mediationSchemeId} = this;

                Mediate.listMediationInstalments({params: {orderId, mediationSchemeId}})
                    .then(({data: {body: {instalments = []}}}) => {
                        this.records = instalments;
                    })
                    .catch(err => err);
            }
        }
    };

</script>
