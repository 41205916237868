import { HubClient } from '../hub';
class MessageHubClient extends HubClient {
    constructor(options) {
        super(Object.assign(Object.assign({}, options), { name: MessageHubClient.clientName }));
        this.handleLoginCheck = (body) => {
            var _a;
            const { sipMessageType, callId } = body;
            if (sipMessageType !== 'LOGIN_CHECK')
                return;
            const page = (_a = this.pageClient) === null || _a === void 0 ? void 0 : _a.getRaw();
            if (!page || !this.registerCallId)
                return;
            if (this.registerCallId !== callId) {
                const [browserId, pageId] = callId.split('_');
                if (browserId !== page.browserId) {
                    this.client.emit('LOGIN_CHECK', {
                        reason: 'DIFFERENT_BROWSER'
                    });
                    return;
                }
                if (pageId !== page.id) {
                    this.client.emit('LOGIN_CHECK', {
                        reason: 'DIFFERENT_TAB'
                    });
                    return;
                }
                this.client.emit('LOGIN_CHECK', {
                    reason: 'SAME_TAB'
                });
            }
        };
        this.onMessage = (messageData) => {
            const body = JSON.parse(messageData.request.body);
            this.handleLoginCheck(body);
        };
        this.listenMessage = () => {
            this.device.addListener('newMessage', this.onMessage);
        };
        this.destroy = () => {
            this.device.removeListener('newMessage', this.onMessage);
        };
        const { device, hubService } = options;
        this.device = device;
        hubService.getClient('pageClient', (client) => {
            this.pageClient = client;
        });
        hubService.getClient('registerClient', (client) => {
            this.registerCallId = client.getRaw().callId;
        });
        this.listenMessage();
        super.register();
    }
}
MessageHubClient.clientName = 'messageClient';
export default MessageHubClient;
