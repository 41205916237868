<!-- @author wenxiujiang -->
<!-- @email wenxiujiang@yangqianguan.com -->
<!-- @date 2020-09-09 16:06:45 -->
<!-- @desc ellipsis-tooltip.vue -->

<template>
    <div
        v-if="!value || value.length <= maxLength"
        class="base-text"
        :style="{maxWidth: def.width || width}"
    >
        {{ value }}
    </div>
    <a-tooltip
        v-else
        :title="value"
        :overlay-style="{maxWidth: '70%'}"
    >
        <div
            class="tooltip-text"
            :style="{maxWidth: def.width || width}"
        >
            {{ maxLength && value.length > maxLength ? value.substring(0, maxLength) : value }}
        </div>
    </a-tooltip>
</template>

<script>
export default {
    name: 'YqgEllipsisTooltip',

    props: {
        value: {
            type: String,
            default: ''
        },

        width: {
            type: String,
            default: '300px'
        },

        def: {
            type: Object,
            default: () => ({})
        },

        maxLength: {
            type: Number,
            default: 0
        }
    }
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.base-text {
    text-align: center;
}

.tooltip-text {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    white-space: nowrap;
    cursor: default;
    vertical-align: bottom;
}
</style>
