/**
 * @Author: xymo
 * @Date: 2023-09-22
 * @Last Modified by: xymo
 * @Last Modified time: 2023-09-22 14:55:58
 */

export default {
    layout: 'horizontal',
    fieldDefs: [
        // 手机号
        {
            label: '掩码手机号',
            field: 'maskedPhone',
            type: 'input',
            required: true,
            trim: true,
            props: {
                placeholder: '掩码手机号'
            },
            rules: [
                {
                    pattern: /^\s*\d{3}\*{6}\d{2}|\d{3}\*{5}\d{3}\s*$/,
                    message: '请输入正确的掩码手机号'
                }
            ],
        },

        // 订单号
        {
            label: '订单号',
            field: 'orderId',
            type: 'input',
            required: true,
            trim: true,
            props: {
                placeholder: '请输入订单号'
            }
        }
    ]
};
