/**
 * @author giligili
 * @date 2022-03-07
 * @email zhimingwang@yangqianguan.com
 * @desc 事件类型
 */

// 页面发送的事件
export const CLIENT_TYPE = {
    GET_VERSION: 'GET_VERSION',
    UPDATE_VERSION: 'UPDATE_VERSION'
};

// worker发送的事件
export const WORKER_TYPE = {
    ACK: 'ACK'
};
