/**
 * @author center
 * @date 2019/5/31-下午2:42
 * @file server-config
 */

import StageType from 'yqg-common/constant/StageType';

const featOverseas = {
    socket: 'wss://opensips-feat.geteasycash.asia:443',
    uriHost: 'opensips-feat.geteasycash.asia'
};
const prodBrazil = {
    socket: 'wss://opensips.geteasycash.asia:443',
    uriHost: 'opensips.geteasycash.asia'
};

export default {
    [StageType.test]: {
        socket: 'wss://opensips-test.yangqianguan.com',
        uriHost: 'opensips-test.yangqianguan.com'
    },
    [StageType.feat]: {
        socket: 'wss://opensips-feat.yangqianguan.com',
        uriHost: 'opensips-feat.yangqianguan.com'
    },
    [StageType.prod]: {
        socket: 'wss://opensips.yangqianguan.com',
        uriHost: 'opensips.yangqianguan.com'
    },

    [StageType.testOverseas]: featOverseas,
    [StageType.featOverseas]: featOverseas,
    [StageType.prodIndo]: {
        socket: 'wss://opensips-indo.geteasycash.asia',
        uriHost: 'opensips-indo.geteasycash.asia'
    },
    [StageType.prodBrazil]: prodBrazil,
    [StageType.prodPhi]: prodBrazil,
    [StageType.prodIndia]: {
        socket: 'wss://opensips-mumbai.fintopia.tech:7443',
        uriHost: 'opensips-mumbai.fintopia.tech'
    },
    [StageType.testLocal]: {
        socket: 'wss://opensips-test.digitforest.cn:7443',
        uriHost: 'opensips-test.digitforest.cn'
    },
    [StageType.prodLocal]: {
        socket: 'wss://opensips.fengtai.tech:7443',
        uriHost: 'opensips.fengtai.tech'
    }
};
