
/**
 * @author giligili
 * @date 2021-08-18
 * @email zhimingwang@yangqianguan.com
 * @desc 转接
 */

import EnumAll from 'collection-admin-web/common/constant/enum';
import {aesEncryptCallSecret} from 'collection-admin-web/common/util/encryption';

import mixinGetCaseDetail from '../../mixin/get-case-detail';
import BasicType from './basic-type';

const CallDialType = EnumAll.Call.DialType.TYPE;

export default class TransferInboundType extends BasicType {

    type = 'TRANSFER_INBOUND';

    constructor(props) {
        super(props);

        this.title = '【客服转接】';
        this.encryptedMobileNumber = aesEncryptCallSecret(
            this.sharedData.incomingMobileNumber
        );

        this.extraData.dialType = CallDialType.TRANSFER;
        this.extraData.encryptedMobileNumber = this.encryptedMobileNumber;
    }

}

Object.assign(TransferInboundType.prototype, mixinGetCaseDetail);
