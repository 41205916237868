/**
 * @author giligili
 * @date 2021-06-22
 * @email zhimingwang@yangqianguan.com
 * @desc 不同业务的配置
 */

import AnnouncementResource from 'collection-admin-web/common/resource/advanced/announcement';

export default {
    credit: {
        api: () => AnnouncementResource.getLatestStaticText({params: {
            staticTextType: 'CREDIT_REPORT_SPEECH_CRAFT'
        }}).then(({data: {body: {text}}}) => ({text}))
    },

    home: {
        api: () => AnnouncementResource.getLatestStaticText({params: {
            staticTextType: 'VERSION_UPDATE_ANNOUNCEMENT'
        }}).then(({data: {body: {text, title}}}) => ({text, title}))
    }
};
