<!-- @Author: giligili -->
<!-- @Date: 2021-06-25 13:29:12.926 -->
<!-- @Last Modified by: giligili -->
<!-- @Last Modified time: 2023-07-05 13:28:29 -->

<template>
    <div class="yqg-order-numbers-cell">
        <!-- 垂直布局 -->
        <div
            v-if="vertical"
            class="veritical-layout"
        >
            <div
                v-if="canLink"
                @click="toCaseDetail"
            >
                <a class="order-number-box">
                    <p
                        v-for="(orderNumber, idx) in uniqOrderNumber"
                        :key="orderNumber"
                        class="order-number"
                        :class="{mb0: idx === uniqOrderNumber.length - 1}"
                    >
                        {{ orderNumber }}
                    </p>
                </a>
            </div>

            <div v-else>
                <span class="order-number-box">
                    <p
                        v-for="(orderNumber, idx) in uniqOrderNumber"
                        :key="orderNumber"
                        class="order-number"
                        :class="{mb0: idx === uniqOrderNumber.length - 1}"
                    >
                        {{ orderNumber }}
                    </p>
                </span>
            </div>
        </div>

        <div
            v-else
            class="horizontal-layout"
        >
            <div
                v-if="canLink"
                @click="toCaseDetail"
            >
                <a class="order-number-box">{{ text || uniqOrderNumber.join(hyphen) }}</a>
            </div>

            <div v-else>
                <span class="order-number-box">{{ text || uniqOrderNumber.join(hyphen) }}</span>
            </div>
        </div>
    </div>
</template>

<script type="text/babel">

    import * as RouterName from 'collection-admin-web/common/constant/router-name';
    import RelativeType from 'collection-admin-web/common/constant/types/relative-type';
    import RouterLink from 'collection-admin-web/common/mixin/router-link';

    export default {
        name: 'YqgOrderNumbersCell',

        mixins: [RouterLink()],

        props: {
            relativeType: {
                type: String,
                default: RelativeType.CASE
            },

            link: {
                type: Boolean,
                default: true
            },

            caseId: {
                type: [Number, String],
                default: ''
            },

            orderNumbers: {
                type: Array,
                default: undefined
            },

            vertical: { // 垂直布局
                type: Boolean,
                default: false
            },

            text: {
                type: String,
                default: ''
            },

            hyphen: {
                type: String,
                default: ', '
            },

            queryCond: {
                type: Object,
                default: null
            }
        },

        computed: {
            routerName() {
                const routerName = this.relativeType === RelativeType.CASE
                    ? RouterName.CaseDetail
                    : RouterName.RemindCaseDetail;

                return routerName;
            },
            computedRouterLink() {
                const {route: {meta: {yqgPrivileges = []}}} = this.$router.resolve({name: this.routerName});

                return this.$app.isAuthorized(yqgPrivileges);
            },
            canLink() {
                const {link, caseId, computedRouterLink} = this;

                return link && caseId && computedRouterLink;
            },

            uniqOrderNumber() {
                const {orderNumbers} = this;

                return [...new Set(orderNumbers || [])];
            }
        },

        methods: {
            toCaseDetail() {
                // 返回 undefined url 上不会有 queryCond
                const queryCond = this.queryCond ? window.encodeURIComponent(JSON.stringify(this.queryCond)) : undefined;

                // to from mixin
                const query = this.relativeType === RelativeType.CASE
                    ? {
                        caseId: window.btoa(this.caseId),
                        queryCond
                    }
                    : {remindCaseId: window.btoa(this.caseId)};
                this.to(query);
            }
        }
    };
</script>
