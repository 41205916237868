<!-- @author weisun -->
<!-- @email weisun@yangqianguan.com -->
<!-- @date 2018-08-02 16:18:49.761 -->
<!-- @desc generated by yqg-cli@0.1.8 -->

<template>
    <div class="yqg-marked">
        <span v-html="html" />
    </div>
</template>

<script type="text/babel">

import marked from 'marked';

export default {
    name: 'YqgMarked',

    props: {
        md: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            html: ''
        };
    },

    watch: {
        md() {
            this.renderHtml();
        }
    },

    created() {
        this.renderHtml();
    },

    methods: {
        renderHtml() {
            const vm = this;
            const {md} = vm;
            try {
                vm.html = marked(md, {
                    breaks: true
                });
            } catch (err) {
                // ignore err
            }
        }
    }
};

</script>

<style lang="scss" rel="stylesheet/scss">

    .yqg-marked {
        font-family: PingFangSc-Regular, sans-serif;
        line-height: 32px;

        h1,
        h2,
        h3 {
            margin: 25px 0 20px;
        }

        h4 {
            margin: 20px 0 15px;
            font-size: 16px;
            line-height: 32px;
        }

        h5 {
            margin: 10px 0 8px;
            font-size: 14px;
            line-height: 28px;
        }

        p {
            margin: 24px 0;
        }

        h1 {
            font-size: 36px;
            line-height: 54px;
        }

        h2 {
            font-size: 24px;
            line-height: 48px;
        }

        h3 {
            font-size: 18px;
            line-height: 36px;
        }

        hr {
            margin: 30px 0;
            border-color: #d8d8d8;
        }

        ul,
        ol {
            list-style-position: inside;
            margin-bottom: 10px;
        }

        img {
            margin: 10px 0;
            width: 100%;
            vertical-align: bottom;
        }

        blockquote {
            padding: 5px 20px;
            border-left: 5px solid #013afd;
            background-color: rgba(1, 58, 253, .08);
            border-radius: 0 4px 4px 0;

            p {
                margin: 15px 0;
            }
        }

        a {
            color: #013afd;
            text-decoration: none;
        }

        table {
            margin-top: 50px;
            width: 100%;
            max-width: 100%;
            table-layout: fixed;
            word-break: break-all;
            word-wrap: break-word;
            border-radius: 4px;
            border-spacing: 0;
            border-collapse: collapse;
            box-shadow: 4px 8px 14px -6px rgba(220, 222, 236, .33);

            tr {
                height: 50px;
                border-radius: 4px;
                background-color: #fff;

                transition: background .3s cubic-bezier(.25, .8, .5, 1);
                will-change: background;

                &:hover {
                    background-color: #fcfcfc;
                }

                th,
                td {
                    min-width: 150px;
                    padding: 10px 20px;
                    user-select: none;
                    font-weight: 300;
                    line-height: 2;
                }
            }
        }

        .vertical {
            table {
                border-top: 5px solid #c8cfdd;

                tr th,
                tr td {
                    &:first-child {
                        text-align: left;
                    }

                    &:last-child {
                        text-align: right;
                    }
                }
            }
        }

        .horizontal {
            table {
                text-align: center;

                tr {
                    border-bottom: 6px solid #f6f7f8;
                }

                thead tr {
                    background: linear-gradient(-90deg, #9299ae, #c8cfdd);
                }
            }
        }

        .horizontal,
        .vertical {
            table {
                tbody tr:last-child {
                    border: none;
                }
            }
        }
    }

</style>
