/**
* @author giligili
* @date 2021/02/08
* @file assistStatusMap
*/

import Enum from 'collection-admin-web/common/util/enum';

export default Enum.from({
    INIT: '请求中',
    AGREE: '已同意',
    PROCESSING: '处理中',
    AUTO_FINISHED: '自动结束',
    MANUAL_FINISHED: '手动结束',
    AUTO_INTERRUPT: '自动中止',
    MANUAL_INTERRUPT: '手动中止',
    REFUSE: '已拒绝'
});
