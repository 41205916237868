/*
 * @Author: huayizhang 
 * @Date: 2021-07-12 14:47:27 
 * @Last Modified by: huayizhang
 * @Last Modified time: 2021-07-13 12:53:26
 */

import Resource from '@yqg/resource';

const api = {
    urlPrefix: '/admin/operation/col/assist/',

    listRelativeCaseAssistFlow: {
        url: 'listRelativeCaseAssistFlow',
        method: 'get'
    },

    exportRelativeCaseAssistFlow: {
        url: 'exportRelativeCaseAssistFlow',
        method: 'get'
    },

    listOwnCaseAssistFlow: {
        url: 'listOwnCaseAssistFlow',
        method: 'get'
    },

    interrupt: {
        url: 'interrupt',
        method: 'post'
    },

    updateStatus: {
        url: 'updateStatus',
        method: 'post'
    },

    listAssistAdminUser: {
        url: 'listAssistAdminUser',
        method: 'get'
    },

    getCaseAssistFlowDetail: {
        url: 'getCaseAssistFlowDetail',
        method: 'get'
    },

    create: {
        url: 'create',
        method: 'post'
    }
};

export default Resource.create(api);
