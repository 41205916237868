/**
 * @author giligili
 * @date 2023-03-10
 * @email zhimingwang@fintopia.tech
 * @desc table-options
 */

import {fixedRight} from 'src/common/constant/fields';

import * as Fields from './fields';

export default {
    rowKey: 'id',
    colDefs: [
        Fields.orderId,
        Fields.timeCreated,
        Fields.operatorName,
        Fields.isReduce,
        Fields.reduceType,
        Fields.reduceAmount,
        Fields.terms,
        Fields.remainAmount,
        Fields.statusDesc,
        Fields.comments,
        Fields.imageUrls,
        fixedRight(Fields.op)
    ]
};
