/*
 * @Author: xiaodongyu
 * @Date 2020-10-28 22:18:59
 * @Last Modified by: xiaodongyu
 * @Last Modified time: 2020-10-28 22:38:14
 */
/* eslint-disable max-len */

export default {
    name: 'custom-table',
    theme: 'outline',
    icon: {
        tag: 'svg',
        attrs: {viewBox: '0 0 24 24', x: '0', y: '0'},
        children: [
            {
                tag: 'path',
                attrs: {
                    d: 'M17 17v5h2a3 3 0 0 0 3-3v-2h-5zm-2 0H9v5h6v-5zm2-2h5V9h-5v6zm-2 0V9H9v6h6zm2-8h5V5a3 3 0 0 0-3-3h-2v5zm-2 0V2H9v5h6zm9 9.18V19a5 5 0 0 1-5 5H5a5 5 0 0 1-5-5V5a5 5 0 0 1 5-5h14a5 5 0 0 1 5 5v2.82a.84.84 0 0 1 0 .36v7.64a.84.84 0 0 1 0 .36zM7 2H5a3 3 0 0 0-3 3v2h5V2zM2 9v6h5V9H2zm0 8v2a3 3 0 0 0 3 3h2v-5H2z'
                }
            }
        ]
    }
};
