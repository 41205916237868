<!-- @author huayizhang -->
<!-- @email huayizhang@yangqianguan.com -->
<!-- @date 2021-07-21 18:53:52.243 -->
<!-- @desc generated by yqg-cli@1.2.7 -->

<template>
    <yqg-static-form
        title="手机号随机码"
        :options="$options.FormOptions"
        :values="{code}"
        @cancel="dismiss"
    />
</template>

<script type="text/babel">

    import {modal} from 'collection-admin-web/common/mixin';

    export default {
        name: 'WechatHashCodeModal',

        mixins: [modal],

        FormOptions: {
            layout: 'horizontal',
            fieldDefs: [
                {label: '手机号随机码', field: 'code'}
            ]
        },

        props: {
            code: {
                type: String,
                required: true
            }
        }
    };

</script>
