/**
 * @author giligili
 * @date 2021-06-24
 * @email zhimingwang@yangqianguan.com
 * @desc 联系人类型
 */

import ContactType from '../types/call-contact';

export default {
    [ContactType.SELF]: '本人',
    [ContactType.NEW]: '新建联系人',
    [ContactType.NEW_SELF]: '新建本人',
    [ContactType.IMMEDIATE]: '紧急联系人',
    [ContactType.CONTACT]: '通讯录联系人',
    [ContactType.CALL_RECORD]: '通话记录联系人',
    [ContactType.BOTH]: '通讯录与通话记录联系人',
    [ContactType.STRONG]: '强相关号码',
    [ContactType.REPAIRED_SELF]: '修复本人'
};

// 质检联系人类型
export const QualityContactMap = {
    [ContactType.SELF]: '本人',
    [ContactType.IMMEDIATE]: '紧急联系人',
    [ContactType.NEW]: '新建联系人',
    [ContactType.REPAIRED_SELF]: '修复本人',
    [ContactType.CONTACT]: '其他'
};
