/**
 * @author giligili
 * @date 2023-03-15
 * @email zhimingwang@fintopia.tech
 * @desc area 机构相关接口
 */

import {assign, customGet} from '@yqg/resource';

const api = {
    urlPrefix: '/admin/operation/col/area',

    getAreaTagsByCaseId: customGet('getAreaTagsByCaseId')
};

export default assign(api);

