/*
 * Author: xymo
 * Created Date: 2020-07-08
 * email: xymo@yangqianguan.com
 */

export default {
    LOGOUT_USER: 'LOGOUT_USER',
    HANGUP_CALL: 'HANGUP_CALL',
    LINE_FLASH: 'LINE_FLASH'
};
