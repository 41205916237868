/*
 * @Author: huayizhang 
 * @Date: 2021-07-13 18:26:25 
 * @Last Modified by: huayizhang
 * @Last Modified time: 2021-07-13 18:31:12
 */

import _ from 'underscore';

import ConfigTag from 'collection-admin-web/common/resource/config/tag';
import Enum from 'collection-admin-web/common/util/enum';

export default Enum.query(() => ConfigTag.getCaseTagGroupList().then(resp => {
    const {data: {body: {tagGroupList = []}}} = resp;

    resp.data.body.$caseTagMap = _.chain(tagGroupList)
        .pluck('tags')
        .flatten()
        .value();

    return resp;
}), {
    dataBodyField: '$caseTagMap',
    valueField: 'tag',
    textField: 'name',
    queryOnce: true
});
