var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.records
    ? _c("a-config-provider", { attrs: { "render-empty": _vm.renderEmpty } }, [
        _c(
          "div",
          {
            class: [
              "yqg-simple-table",
              _vm.renderEmpty ? "custom-empty-table" : "",
            ],
          },
          [
            _c(
              "div",
              { staticClass: "yst-header yqg-clear-float" },
              [
                _vm.enableClientSearch
                  ? _c("a-input-search", {
                      staticClass: "search-input",
                      attrs: {
                        value: _vm.searchText,
                        placeholder: _vm.isSearchTableRendering
                          ? _vm.$t("table.searchLoading")
                          : _vm.$t("table.searchPlaceholder"),
                        disabled: _vm.isSearchTableRendering,
                        loading: _vm.isSearchTableRendering,
                      },
                      on: { change: _vm.onSearch },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm._t("yst-extra-header"),
                _vm._v(" "),
                _vm.$listeners["export-current-page"]
                  ? _c(
                      "a-button",
                      {
                        staticStyle: { float: "right" },
                        attrs: { size: "small" },
                        on: { click: _vm.onExportCurrentPage },
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("table.exportCurrentPage")) +
                            "\n            "
                        ),
                      ]
                    )
                  : _vm._e(),
              ],
              2
            ),
            _vm._v(" "),
            _vm.options.enableClientSort && _vm.$listeners.change
              ? _c("p", { staticClass: "client-sort-tip" }, [
                  _vm._v(
                    "\n            *" +
                      _vm._s(_vm.$t("table.clientSortTip")) +
                      "\n        "
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.enableSelectColumn
              ? _c("field-checkbox", {
                  staticClass: "mb10",
                  attrs: { "enum-type": _vm.columnsMap, def: {} },
                  on: { change: _vm.onCheckedColumnsChange },
                  model: {
                    value: _vm.checkedColumns,
                    callback: function ($$v) {
                      _vm.checkedColumns = $$v
                    },
                    expression: "checkedColumns",
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "a-table",
              _vm._b(
                {
                  ref: "mainTable",
                  attrs: { "data-source": _vm.renderRecords },
                  on: {
                    change: _vm.onChange,
                    expand: _vm.onExpand,
                    expandedRowsChange: _vm.onExpandedRowsChange,
                  },
                },
                "a-table",
                _vm.tableOptions,
                false
              )
            ),
            _vm._v(" "),
            _vm.isPaginationInfiniteVisible
              ? _c("yqg-infinite-pagination", {
                  attrs: {
                    pagination: _vm.pagination,
                    "data-size": (_vm.records && _vm.records.length) || 0,
                  },
                  on: { change: _vm.onChange },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.enableClientSearch && _vm.isSearchTableRendering
              ? _c(
                  "a-table",
                  _vm._b(
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: false,
                          expression: "false",
                        },
                      ],
                      ref: "innerTextTable",
                      attrs: {
                        "data-source": _vm.records.slice(
                          _vm.searchSliceStart,
                          _vm.searchSliceEnd
                        ),
                        pagination: false,
                      },
                    },
                    "a-table",
                    _vm.tableOptions,
                    false
                  )
                )
              : _vm._e(),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }