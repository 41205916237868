/*
 * @Author: huayizhang 
 * @Date: 2021-07-22 18:50:14 
 * @Last Modified by: huayizhang
 * @Last Modified time: 2021-11-05 14:32:19
 */

import Vue from 'vue';

export const OnOpenAddComplaintFlowModal = 'OnOpenAddComplaintFlowModal'; // 打开投诉催记弹窗
export const OnAddExtendCollectionSuccess = 'OnAddExtendCollectionSuccess'; // 添加展期催记成功
export const OnAddCollectionSuccess = 'OnAddCollectionSuccess'; // 添加催记成功
export const OnAddRemindFlowSuccess = 'OnAddRemindFlowSuccess'; // 添加催告成功

export const OnRefreshMyReceiveAssistList = 'OnRefreshMyReceiveAssistList'; // 刷新我收到的协催列表

export const OnCloseMultiCallModal = 'onCloseMultiCallModal'; // 关闭一键多呼弹窗

export const OnCloseMessageModal = 'OnCloseMessageModal'; // 关闭电话呼入弹窗

export const OnYtalkCallRelease = 'OnYtalkCallRelease'; // ytalk 挂断电话
export const OnYtalkUserAgentCallRelease = 'OnYtalkUserAgentCallRelease'; // ytalk 挂断电话(区别在于只在等于外呼的那个页面触发)
export const OnYtalkAgentStatusCallRelease = 'OnYtalkAgentStatusCallRelease'; // ytalk 坐席状态改变(通话后)

export const OnUploadRepayReceipt = 'onUploadRepayReceipt'; // 案件详情其他渠道还款登记，上传还款凭证成功

export const OnAddLawsuitRecord = 'OnAddLawsuitRecord'; // 诉讼详情诉讼状态，修改状态成功

export const OnAddReduceSuccess = 'OnAddReduceSuccess'; // 成功添加减免记录

export const OnRefreshMediateSchemeList = 'OnRefreshMediateSchemeList'; // 刷新协商方案列表

export const OnRefreshCouponList = 'OnRefreshCouponList'; // 刷新优惠券列表

export const OnRefreshAgreementList = 'OnRefreshAgreementList'; // 刷新协议发起列表

export const OnRefreshOrderList = 'OnRefreshOrderList'; // 刷新订单列表

export default new Vue();
