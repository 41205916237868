var render, staticRenderFns
import script from "./yqg-static-form.vue?vue&type=script&lang=js&"
export * from "./yqg-static-form.vue?vue&type=script&lang=js&"
import style0 from "./yqg-static-form.vue?vue&type=style&index=0&lang=scss&rel=stylesheet%2Fscss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/jenkins/agent/workspace/fintopia-web7chz7/fintopia-web-ci/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6b507eee')) {
      api.createRecord('6b507eee', component.options)
    } else {
      api.reload('6b507eee', component.options)
    }
    
  }
}
component.options.__file = "packages/vue/antd/component/yqg-static-form.vue"
export default component.exports