<!-- @author giligili -->
<!-- @email zhimingwang@yangqianguan.com -->
<!-- @date 2021-07-28 14:47:41.468 -->
<!-- @desc generated by yqg-cli@5.1.1 -->

<template>
    <div class="yqg-layout-md-preview">
        <h4
            v-if="title"
            v-text="title"
        />

        <yqg-md-view
            v-if="value"
            :md-text="value"
        />
    </div>
</template>

<script type="text/babel">

    import BizTypeMap from './constant/biz-type-map';

    export default {
        name: 'YqgLayoutMdPreview',

        props: {
            /* @desc 业务类型 ['home(首页)', 'credit(征信话术)'] */
            type: {
                type: String,
                default: 'home'
            }
        },

        data() {
            return {
                title: '',
                value: ''
            };
        },

        watch: {
            type: {
                immediate: true,
                async handler(nextVal) {
                    try {
                        const options = BizTypeMap[nextVal];

                        const {text, title} = await options.api();

                        this.value = text;
                        this.title = title;
                    } catch (err) {
                        // ignore
                    }
                }
            }
        }
    };

</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .yqg-layout-md-preview {
        ::v-deep .md-view {
            table {
                font-size: 16px;
            }
        }
    }
</style>
