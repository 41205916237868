import ComUploadLog from '@shared/client/util/log';
import {STAGE} from '@shared/common/constant/stage';

import SpeedSharedWorker from './speed.shared-worker'; // eslint-disable-line

const isProd = /prod/i.test(STAGE);

const measurement = 'app_metrics_for_speed_test';

const DEFAULT_CONFIG = {
    fileSize: 1,
    intervalTime: 60000
};

// 使用 Web Worker 进行网速检测
export default class SpeedTest {

    constructor(reportConfig, config) {
        this.reportConfig = reportConfig;
        this.config = config;
        this.sharedWorker = null;
        this.timeStamp = null;
    }

    async start() {
        const callAccount = this.config?.callAccount;
        const {testFileMap, countryFileKey = this.reportConfig?.country} = this.config || {};

        if (window.SharedWorker) {
            let speedTestDefault = {};

            if (!isProd) {
                // 请求配置中心-现在只有测试环境支持
                const configCenter = await fetch('https://octopus-api-test.yangqianguan.com/api-web/hostCond?app=web-admin-call').then(res => res.json());

                if (configCenter?.status?.code === 0) {
                    speedTestDefault = configCenter?.body?.speedTestDefault ? JSON.parse(configCenter?.body?.speedTestDefault) : {};
                }
            }

            // 如果业务配置了callAccount, 配置中心配置了allowCallAccounts, 且callAccount不在allowCallAccounts中, 则不执行
            const allowCallAccounts = speedTestDefault?.allowCallAccounts || [];
            if (callAccount && (allowCallAccounts.length > 0) && !allowCallAccounts.includes(callAccount)) {
                return;
            }

            // 自己配置 > 配置中心配置 > 默认配置
            const fileSize = this.config?.fileSize || speedTestDefault?.fileSize || DEFAULT_CONFIG.fileSize;
            const intervalTime = this.config?.intervalTime || speedTestDefault?.intervalTime || DEFAULT_CONFIG.intervalTime;

            const sharedWorker = new SpeedSharedWorker();
            this.sharedWorker = sharedWorker;
            this.timeStamp = Date.now();

            sharedWorker.port.start();

            sharedWorker.port.postMessage({type: 'INIT', payload: {fileSize}});

            // 监听 Worker 线程发送的消息
            sharedWorker.port.onmessage = e => {
                const message = e.data;
                if (message.type === 'RUN') {
                    this.timer = setInterval(() => {
                        sharedWorker.port.postMessage({
                            type: 'GET_SPEED',
                            payload: {
                                timeStamp: this.timeStamp,
                                countryFileKey,
                                testFileMap
                            }
                        });
                    }, intervalTime);
                } else if (message.type === 'RETURN_SPEED') {
                    const speedInMbps = parseFloat(message.payload?.speedInMbps || 0);
                    // eslint-disable-next-line no-console
                    console.log('网速:', speedInMbps, typeof speedInMbps, message.payload?.curSpeedRange?.label);

                    ComUploadLog('SPEED_TEST_RETURN_SPEED', {
                        tab: this.timeStamp,
                        time: new Date().toString(),
                        href: window.location.href,
                        measurement,
                        speedInMbps,
                        curSpeedRange: message.payload?.curSpeedRange?.label,
                        ...(this.reportConfig?.payload || {})
                    }, {
                        appId: this.reportConfig?.appId,
                        country: this.reportConfig?.country,
                    }, true);
                } else if (message.type === 'RETURN_SPEED_ERROR') {
                    ComUploadLog('SPEED_TEST_RETURN_SPEED_ERROR', {
                        tab: this.timeStamp,
                        time: new Date().toString(),
                        href: window.location.href,
                        measurement,
                        error: message.payload?.err,
                        ...(this.reportConfig?.payload || {})
                    }, {
                        appId: this.reportConfig?.appId,
                        country: this.reportConfig?.country,
                    }, true);

                    this.stop();
                }
            };

            window.addEventListener('unload', () => {
                // 这里执行清理操作
                this.stop();
            });
        }
    }

    stop() {
        // 关闭 Worker 线程
        this.sharedWorker?.port && this.sharedWorker.port.postMessage({type: 'CLOSE', payload: {timeStamp: this.timeStamp}});
        this.sharedWorker?.port && this.sharedWorker.port.close();
        this.timer && clearInterval(this.timer);

        ComUploadLog('SPEED_TEST_RETURN_SPEED_CLOSE', {
            tab: this.timeStamp,
            time: new Date().toString(),
            href: window.location.href,
            measurement,
            ...(this.reportConfig?.payload || {})
        }, {
            appId: this.reportConfig?.appId,
            country: this.reportConfig?.country,
        }, true);
    }

}
