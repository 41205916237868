/**
 * @author giligili
 * @date 2021-07-20
 * @email zhimingwang@yangqianguan.com
 * @desc caseDetail store
 */

import {deepClone} from 'yqg-common/core/ToolFunction';

import SmsType from 'collection-admin-web/common/constant/types/sms-types';
import CaseDetail from 'collection-admin-web/common/resource/case/detail';
import Notification from 'collection-admin-web/common/resource/notification';

const NOTIFICATIONS_UPDATE = 'NOTIFICATIONS_UPDATE';

const AVAILABLEMESSAGES_UPDATE = 'AVAILABLEMESSAGES_UPDATE';

const ADD_STASH_COLLECTION_FLOW = 'ADD_STASH_COLLECTION_FLOW';
const CLEAR_STASH_COLLECTION_FLOW = 'CLEAR_STASH_COLLECTION_FLOW';

export default {
    namespaced: true,

    state: {
        notifications: [],
        availableMessages: {
            map: {},
            type: SmsType
        },
        stashCollectionFlow: null // 缓存的催记弹窗信息
    },

    getters: {
        notifications: state => state.notifications,
        stashCollectionFlow: state => state.stashCollectionFlow,
        availableMessages: state => state.availableMessages.map,
        availableMessagesType: state => state.availableMessages.type
    },

    mutations: {
        [NOTIFICATIONS_UPDATE](state, payload) {
            state.notifications = payload;
        },

        [AVAILABLEMESSAGES_UPDATE](state, payload) {
            state.availableMessages.map = payload;
        },

        [ADD_STASH_COLLECTION_FLOW](state, payload) {
            const {contactInfo: {sessionInfo}} = payload;

            const nextStashCollectionFlow = deepClone(payload);
            state.stashCollectionFlow = {
                ...nextStashCollectionFlow,
                contactInfo: {
                    ...payload.contactInfo,
                    sessionInfo
                }
            };
        },

        [CLEAR_STASH_COLLECTION_FLOW](state) {
            state.stashCollectionFlow = null;
        }
    },

    actions: {
        fetchNotification({commit}, caseId) {
            Notification.listNotificationsByCaseId({params: {caseId}})
                .then(({data: {body: {notificationList}}}) => {
                    commit(NOTIFICATIONS_UPDATE, notificationList || []);
                })
                .catch(err => err);
        },

        fetchAvailableMessage({commit, rootGetters: {permissions}}, caseId) {
            if (!permissions.CASE_DETAIL_SEND_MESSAGE) return;

            CaseDetail.getAvailableMessage({params: {caseId}})
                .then(({data: {body: {availableMessages = {}}}}) => {
                    commit(AVAILABLEMESSAGES_UPDATE, availableMessages);
                })
                .catch(err => err);
        },

        addStashCollectionFlow({commit}, payload) {
            commit(ADD_STASH_COLLECTION_FLOW, payload);
        },

        clearStashCollectionFlow({commit}) {
            commit(CLEAR_STASH_COLLECTION_FLOW);
        },

        initStore({dispatch}, caseId) {
            dispatch('fetchNotification', caseId);
            dispatch('fetchAvailableMessage', caseId);
        }
    }
};
