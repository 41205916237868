var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ytalk-status mb10" },
    [
      _c("span", { class: _vm.descClass }, [_vm._v("当前坐席状态: ")]),
      _vm._v(" "),
      _c(
        "a-spin",
        { staticClass: "spin", attrs: { spinning: _vm.isLoading } },
        [
          _c("a-select", {
            staticStyle: { width: "100%" },
            attrs: {
              value: _vm.workStatus,
              "dropdown-menu-style": { maxHeight: "218px" },
              options: _vm.workStatusMap,
              "get-popup-container": _vm.getPopupContainer,
            },
            on: { change: _vm.onWorkStatusChange },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }