<!-- @Author: ruiwang -->
<!-- @Date: 2021-08-02 14:40:16 -->
<!-- @Last Modified by: ruiwang -->
<!-- @Last Modified time: 2023-07-17 10:53:44 -->

<script>
import _ from 'underscore';

import DefType from '../../../constant/def-type';
import getFormat from '../../../util/format-map';
import {evalProp, pickValue, setValue, spreadProps} from '../../../util/object';
import {isComponent} from '../../util';
import FieldMap, {defaultPropsMap} from '../constant/field-map';
import FieldChildForm from './field-child-form';
import FieldDate from './field-date';

export default {
    name: 'FieldTableForm',

    props: {
        def: {
            type: Object,
            required: true
        },

        value: {
            type: Array,
            default: () => [{}]
        },

        ctx: {
            type: Object,
            default: () => ({})
        }
    },

    data() {
        return {
            form: this.$form.createForm(this),
            curValues: []
        };
    },

    computed: {
        canAdd() {
            const {def: {hasAdd = true, maxLen = Infinity}, ctx, curValues = []} = this;

            return evalProp(hasAdd, {ctx}) && curValues.length < maxLen;
        },

        canDelete() {
            const {def: {hasDelete = true, minLen = 0}, ctx, curValues = []} = this;

            return evalProp(hasDelete, {ctx}) && curValues.length > evalProp(minLen, {ctx});
        }
    },

    watch: {
        value(value) {
            if (this.def.tableOptions.sync && !_.isEqual(value, this.curValues)) {
                this.initCurValues(value);
            }
        }
    },

    mounted() {
        this.initCurValues(this.value);
    },

    methods: {
        renderSlot(name, props, fallback) {
            return this.$scopedSlots[name]?.(props) || fallback;
        },

        hasSlot(name) {
            return !!this.$scopedSlots[name];
        },

        singleHasDelete({record, index}) {
            const {def: {tableOptions: {hasDelete = true}}} = this;

            return evalProp(hasDelete, {value: record, extraData: {idx: index, curValues: this.curValues}});
        },

        formatValues(values, needTrim) {
            const {colDefs} = this.def.tableOptions;
            colDefs.forEach(({field, format, notrim}) => {
                const formattor = getFormat(format);
                let value = pickValue(values, field);
                let handled = false;
                if (needTrim && !notrim && value && typeof value === 'string') {
                    value = value.trim();
                    handled = true;
                }

                if (formattor) {
                    value = formattor.unformat(value);
                    handled = true;
                }

                if (handled) values = setValue(values, field, value);
            });

            return values;
        },

        getFormCtx() {
            const {ctx, form, curValues} = this;
            const values = curValues.reduce((acc, cur) => ({
                ...acc,
                [cur.feID]: cur
            }), {});
            let formValues = values;
            if (form.isFieldsTouched()) {
                const curFormValues = form.getFieldsValue();
                formValues = Object.keys(curFormValues).reduce((acc, feID) => {
                    return {
                        ...acc,
                        [feID]: {...this.formatValues({...formValues[feID], ...curFormValues[feID]}), feID}
                    };
                }, {});
            }

            return {
                ctx: ctx || this.$parent,
                ysf: this,
                form,
                values: formValues,
                initialValues: values,
                record: {
                    ...values,
                    ...formValues
                }
            };
        },

        getDecorator(def) {
            if (!def) return [];

            const {field, required = false, rules: originRules = [], format} = def;
            const formCtx = this.getFormCtx();
            const formattor = getFormat(format);
            const [rowKey, colField] = field.split('.');
            const {curValues} = this;
            const values = curValues.find(item => rowKey === item.feID);
            let initialValue = pickValue(values, colField);
            const valuePropName = def.type === DefType.switch ? 'checked' : 'value';

            if (formattor) {
                initialValue = formattor.format(initialValue);
            }

            const rules = [
                {required: evalProp(required, {...formCtx, rowValues: values}), message: this.$t('rule.required')},
                ...originRules.map(rule => {
                    const {validator, message} = rule;
                    if (validator) {
                        return {
                            ...rule,
                            validator: (curRule, value, callback) => {
                                const msgKey = validator({
                                    rule: curRule, value, rowKey,
                                    rowValues: formCtx.values[rowKey],
                                    ...formCtx
                                });
                                if (msgKey) {
                                    callback(this.$t(msgKey));

                                    return;
                                }

                                callback();
                            }
                        };
                    }

                    if (message) {
                        return {
                            ...rule,
                            message: message.constructor === Function ? message(rule) : this.$t(message)
                        };
                    }

                    return rule;
                })
            ];

            return [field, {initialValue, rules, valuePropName}];
        },

        getListeners(def) {
            const {field, enumType, type} = def;
            const {select} = DefType;
            const listeners = {};
            if ((!type && enumType) || select === type) {
                listeners.search = val => {
                    this.$emit(`${field}Search`, val);
                };
            }

            return listeners;
        },

        renderFormItem(def, formCtx, rowKey) {
            const {
                tableOptions: {commonItemProps}
            } = this.def;
            const field = `${rowKey}.${def.field}`;
            const curDef = {...def, field, rowKey};
            const rowFormCtx = {...formCtx, rowValues: formCtx.values[rowKey]};
            const itemProps = {
                key: field,
                ...commonItemProps,
                ...def.itemProps
            };
            if (evalProp(curDef.hideCell, rowFormCtx)) {
                return (
                    <a-form-item {...spreadProps(itemProps)}>
                        <div>/</div>
                    </a-form-item>
                );
            }

            const Comp = this.is(curDef, rowFormCtx);
            const scopedSlots = [
                'head', 'foot', 'prefix', 'suffix', 'addonAfter', 'addonBefore', 'childFormExtraBtn', 'optionLabel'
            ].reduce((acc, name) => {
                const hasSlot = this.hasSlot(`${def.field}.${name}`);
                if (hasSlot) {
                    acc[name] = args => {
                        if (!Object.keys(args).length) return this.renderSlot(`${def.field}.${name}`, rowFormCtx);

                        return this.renderSlot(`${def.field}.${name}`, {parent: rowFormCtx, child: args});
                    };
                }

                return acc;
            }, {});

            const {props: {ref: defRef} = {}} = def;
            const ref = defRef && `${defRef}-${rowKey}`;
            const fallbackEl = (
                <Comp
                    {...{
                        directives: [{
                            name: 'decorator',
                            value: this.getDecorator(curDef)
                        }],
                        ...spreadProps(this.getProps(curDef)),
                        ref,
                        scopedSlots,
                        on: {
                            ...this.getListeners(curDef),
                            change: $event => {
                                this.onFieldChange({$event, def, rowKey});
                            }
                        }
                    }}
                >
                </Comp>
            );
            const itemSuffixEl = this.renderSlot(`${def.field}.itemSuffix`, formCtx);
            if (itemSuffixEl) {
                const itemSuffixClass = 'yqg-form-item-has-suffix';
                const {class: className} = itemProps;
                if (!className) {
                    itemProps.class = itemSuffixClass;
                } else {
                    if (className.constructor === String) {
                        itemProps.class = `${itemProps.class} ${itemSuffixClass}`;
                    }

                    if (className.constructor === Object) {
                        className[itemSuffixClass] = true;
                    }

                    if (className.constructor === Array) {
                        className.push(itemSuffixClass);
                    }
                }
            }

            return (
                <a-form-item {...spreadProps(itemProps)}>
                    {this.renderSlot(def.field, formCtx, fallbackEl)}
                    {itemSuffixEl}
                </a-form-item>
            );
        },

        async onConfirm() {
            const {curValues, def} = this;
            if (!curValues.length) return {err: null, def, values: [], record: []};

            const refValidPromises = Object.values(this.$refs).filter(ref => ref && ref.onConfirm)
                .map(ref => ref.onConfirm());
            const selfValidPromise = new Promise(resolve => {
                this.form.validateFields((err, values) => {
                    resolve({err, values, record: {...values}});
                });
            });

            const results = await Promise.all([selfValidPromise, ...refValidPromises]);
            let [{values, record}] = results;
            const err = results.reduce((acc, cur) => acc || cur.err, null);
            results.forEach(({def: curDef, record: refRecord, values: refValues}) => {
                if (curDef) {
                    values = setValue(values, curDef.field, refValues);
                    record = setValue(record, curDef.field, refRecord);
                }
            });

            const valueArr = [];
            const recordArr = [];
            Object.keys(values).forEach(key => {
                const initValue = curValues.find(({feID}) => feID === key);
                const curValue = this.formatValues(values[key], true);
                valueArr.push(curValue);
                recordArr.push({...initValue, ...curValue});
            });

            return {err, values: valueArr, record: recordArr, def};
        },

        initCurValues(value) {
            this.curValues = value?.map(val => ({...val, feID: _.uniqueId('row_id_')})) ?? [];
        },

        onAdd() {
            const {ctx, def: {getInitialValue, extendAdd}} = this;
            const initialValue = evalProp(getInitialValue, {ctx}) || {};
            initialValue.feID = _.uniqueId('row_id_');

            if (extendAdd) {
                extendAdd(this.curValues, {initialValue, ctx});
            } else {
                this.curValues.push(initialValue);
            }

            this.$nextTick(() => {
                this.onChange();
            });
        },

        onFieldChange({def, def: {onChange, field}, rowKey, $event}) {
            const {tableOptions: {sync}} = this.def;
            if (!sync && !onChange) return;

            const formCtx = this.getFormCtx();
            if (sync) {
                const {record} = formCtx;
                const value = $event?.target?.value ?? $event;
                const rowData = {...record[rowKey], [field]: value};
                const idx = this.curValues.findIndex(item => item.feID === rowKey);
                this.curValues.splice(idx, 1, rowData);
                this.$emit('change', this.curValues);
            }

            if (onChange) {
                const rowFormCtx = {...formCtx, rowKey, rowValues: formCtx.values[rowKey], def};
                onChange({value: $event, ...rowFormCtx});
            }
        },

        onChange(idx, {record} = {}) {
            if (idx >= 0) {
                this.curValues.splice(idx, 1, record);
            }

            if (this.def.tableOptions.sync) {
                this.$emit('change', this.curValues);
            }
        },

        onDelete(idx) {
            this.curValues.splice(idx, 1);
            this.$nextTick(() => {
                this.onChange();
            });
        },

        getProps(def) {
            const {enumType, disabled, formOptions, field, rowKey, optionDisabled} = def;
            const {values, ...restFormCtx} = this.getFormCtx();
            const formCtx = {
                ...restFormCtx,
                values,
                rowValues: values[rowKey],
                value: pickValue(values, field)
            };
            let {type, props = {}} = def;
            if (!type) {
                type = enumType ? DefType.select : DefType.text;
            }

            props = {
                ...defaultPropsMap[type],
                ...evalProp(props, formCtx),
                disabled: evalProp(disabled, formCtx),
                optionDisabled: optionDisabled?.bind(null, formCtx)
            };
            if (enumType) {
                props.enumType = evalProp(enumType, formCtx);
            }

            if (formOptions) {
                props.ctx = formCtx.ctx;
            }

            const {placeholder} = props;
            if (placeholder) {
                props.placeholder = this.$t(placeholder);
            }

            if (type === DefType.file) {
                const {data, previewValue} = props;
                if (data) props.data = file => evalProp(data, {...formCtx, file});

                if (previewValue) {
                    props.previewValue = evalProp(previewValue, formCtx);
                }
            }

            return {
                ...props,
                def: {
                    ...def,
                    props
                }
            };
        },

        is({type: originType, component, enumType, formOptions, tableOptions}, rowFormCtx) {
            let type = originType;
            if (!type && enumType) {
                type = 'select';
            }

            if (evalProp(component, rowFormCtx)) {
                return evalProp(component, rowFormCtx);
            }

            if (formOptions) {
                return FieldChildForm;
            }

            if (tableOptions) {
                return 'field-table-form';
            }

            const {date, dateTime, dateRange, time, month} = DefType;
            if ([date, dateTime, dateRange, time, month].includes(type)) {
                return FieldDate;
            }

            return FieldMap[type] || FieldMap.text;
        },

        getColSlots(defs) {
            const formCtx = this.getFormCtx();

            return defs.reduce((acc, def) => {
                const {field} = def;
                const slot = def.children
                    ? this.getColSlots(def.children)
                    : {
                        [field]: props => {
                            if (field === 'op') {
                                const opFragments = [];
                                const tableFormExtraBtnNode = this.renderSlot('childFormExtraBtn', {def, ...formCtx, feID: props.record.feID});
                                if (tableFormExtraBtnNode) {
                                    opFragments.push(tableFormExtraBtnNode);
                                }

                                if (this.canDelete && this.singleHasDelete(props)) {
                                    opFragments.push((
                                        <a-button
                                            type='danger'
                                            icon='delete'
                                            size='small'
                                            vOn:click={this.onDelete.bind(null, props.index)}
                                        />
                                    ));
                                }

                                return opFragments?.length ? opFragments : '-';
                            }

                            return (field === '_idx' && (<span>{props.index + 1}</span>))
                                || this.renderFormItem(def, formCtx, props.record.feID);
                        }
                    };

                return {
                    ...acc,
                    ...slot,
                };
            }, {});
        }
    },

    render() {
        const {
            form, curValues, canAdd, canDelete, getColSlots,
            def: {
                tableOptions: {formProps, colDefs: defs, ...rest},
                idxLabel = '#',
                addBtnText = 'common.add',
                hideIdx,
                idxProps
            }
        } = this;
        const hasExtraBtns = this.hasSlot('childFormExtraBtn');
        const formCtx = this.getFormCtx();
        const rowKey = 'feID';
        const op = {field: 'op', label: 'common.op'};
        const colDefs = [
            ...(hideIdx ? [] : [{field: '_idx', label: this.$t(idxLabel), ...idxProps}]),
            ...defs.filter(def => !evalProp(def.hide, formCtx)),
            ...(canDelete || hasExtraBtns ? [op] : [])
        ];
        const tableOptions = {rowKey, scroll: {x: 0}, colDefs, ...rest};

        const titleSlot = ({title, def}) => {
            const {labelComp} = def;
            const labelClass = `d-flex align-items-center ${def.required ? 'required-title' : ''}`;

            return (
                <div class={labelClass}>{isComponent(labelComp) ? <labelComp {...spreadProps({...formCtx, def})} /> : title}</div>
            );
        };

        return (
            <div class='field-table-form'>
                <a-form {...{props: {...formProps, form}, attrs: {autocomplete: 'off'}}}>
                    <yqg-simple-table
                        options={tableOptions}
                        records={curValues}
                        scopedSlots={{...getColSlots(colDefs), title: titleSlot}}
                        pagination={false}
                    />
                </a-form>
                {canAdd ? (
                    <div class="field-table-form-add-btn-wrapper">
                        <a-button class="field-table-form-add-btn mt10" icon='plus' vOn:click={this.onAdd}>
                            {this.$t(addBtnText)}
                        </a-button>
                    </div>
                ) : null}
            </div>
        );
    }
};
</script>

<style lang="scss" scoped>
.field-table-form {
    .ant-form-item {
        margin-bottom: 0;
    }

    .required-title {
        &::before {
            content: "*";
            color: #f5222d;
            margin-right: 4px;
            font-family: SimSun, sans-serif;
            display: inline-block;
            line-height: 1;
        }
    }

    &-add-btn-wrapper {
        text-align: left;
    }
}
</style>
