/**
 * @author giligili
 * @date 2021-12-20
 * @email zhimingwang@yangqianguan.com
 * @desc fields
 */

import {genField} from 'collection-admin-web/common/util/tool';

export const fileName = genField('文件名称', 'fileName', 'input');
export const displayContent = genField('处理状态', 'displayContent', 'input');
export const timeCreated = genField('创建时间', 'timeCreated', 'dateTime');
export const op = genField('操作', 'op');
