/*
 * Author: giligili
 * Created Date: 2021-05-20
 * email: zhimingwang@yangqianguan.com
 */

import FieldType from 'collection-admin-web/common/constant/types/general-config';

import FileLayout from '../layout/file';
import JsonLayout from '../layout/json';
import JsonArrayLayout from '../layout/json-array';
import MultiSelectLayout from '../layout/multi-select';
import SelectLayout from '../layout/select';
import StringLayout from '../layout/string';
import StringArrayLayout from '../layout/string-array';

export default {
    [FieldType.STR]: StringLayout,
    [FieldType.JSON]: JsonLayout,
    [FieldType.FILE]: FileLayout,
    [FieldType.SELECT]: SelectLayout,
    [FieldType.STR_ARRAY]: StringArrayLayout,
    [FieldType.JSON_ARRAY]: JsonArrayLayout,
    [FieldType.MULTI_SELECT]: MultiSelectLayout
};
