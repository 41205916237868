/*
 * @Author: xiaodongyu
 * @Date 2019-12-07 14:00:32
 * @Last Modified by: mengpeng 
 * @Last Modified time: 2022-11-10 15:39:31 
 */

export const evalProp = (value, values) => {
    if (typeof value === 'function') {
        return value(values);
    }

    return value;
};

const getKeys = (keyString = '') => keyString.split(/\]?[[.]/);

export const pickValue = (obj, keyString) => {
    const keys = getKeys(keyString);
    let value = obj;
    try {
        keys.forEach(key => (value = value[key]));
    } catch (err) {
        value = undefined;
    }

    return value;
};

export const setValue = (obj = {}, keyString, value) => {
    const keys = getKeys(keyString);
    const result = {...obj};
    let target = result;
    keys.forEach((key, idx) => {
        if (idx === keys.length - 1) {
            target[key] = value;
        } else {
            if (!target[key]) {
                const nextKey = keys[idx + 1];
                if (/^\d+$/.test(nextKey) && new RegExp(`${key}]?[${nextKey}]`).test(keyString)) {
                    target[key] = [];
                } else {
                    target[key] = {};
                }
            }

            target = target[key];
        }
    });

    return result;
};

export const spreadObjectKeys = origin => {
    if (!origin) return origin;

    return Object.entries(origin)
        .reduce((acc, [key, value]) => setValue(acc, key, value), {});
};

const numberToStr = val => {
    if ((val || val === 0 || val === false) && (val.constructor === Number || val.constructor === Boolean)) {
        return val.toString();
    }

    return val;
};

export const numbersToStr = val => {
    if (val && val.constructor === Array) {
        return val.map(numberToStr);
    }

    return numberToStr(val);
};

export const isMobile = /mobile/i.test(navigator.userAgent);

export function spreadProps({class: className, style, ref, key, ...props} = {}) {
    return {
        key,
        class: className,
        style,
        ref,
        props
    };
}

export function appendClass(className, appendix) {
    // 目前只支持append string
    if (typeof appendix !== 'string') return className;

    if (!className) return appendix;

    switch (className.constructor) {
        case String:
            return `${className} ${appendix}`;
        case Object:
            return {...className, [appendix]: true};
        case Array:
            return [...className, appendix];
    }

    return className;
}

const isPlainObject = obj => {
    if (obj === null || Array.isArray(obj)) {
        return false;
    }

    return typeof obj === 'object';
};

const customizer = (target, source) => {
    //对象需要考虑深层对象
    if (isPlainObject(target) && isPlainObject(target)) {
        return merge(target, source);
    }

    //其他格式一律返回新值
    return source;
};

//两两合并
export const merge = (target = {}, source = {}) => {
    const res = {};
    [...Object.keys(target), ...Object.keys(source)]
        .forEach(key => {
            const val = customizer(target[key], source[key]);

            res[key] = typeof val === 'undefined' ? target[key] : val;
        });

    return res;
};
