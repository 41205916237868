var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "alarm-flow-end" }, [
    _vm._v(
      "\n    您于 " +
        _vm._s(_vm._f("dateTime")(_vm.notification.data.timeRemind)) +
        " 收到一个 " +
        _vm._s(_vm.notification.data.remark) +
        " 提醒\n\n    "
    ),
    _vm.processFlowType !== "CREATE_USER"
      ? _c(
          "span",
          {
            on: {
              click: function ($event) {
                return _vm.finishNotification(_vm.notification.data)
              },
            },
          },
          [
            _c("yqg-router-link", {
              attrs: {
                text: "请查看",
                to: "qualityReviewFlow",
                query: { processFlowId: _vm.processFlowId },
              },
            }),
          ],
          1
        )
      : _c(
          "span",
          {
            on: {
              click: function ($event) {
                return _vm.finishNotification(_vm.notification.data)
              },
            },
          },
          [
            _c("yqg-router-link", {
              attrs: {
                text: "请查看",
                to: "account",
                query: { processFlowId: _vm.processFlowId },
              },
            }),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }