var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-input",
    _vm._b(
      {
        model: {
          value: _vm.filterValue,
          callback: function ($$v) {
            _vm.filterValue = $$v
          },
          expression: "filterValue",
        },
      },
      "b-input",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }