var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("yqg-simple-form", {
    attrs: { options: _vm.FormOptions, title: "意见反馈" },
    on: { confirm: _vm.onConfirm, cancel: _vm.dismiss },
    scopedSlots: _vm._u([
      {
        key: "topBtns",
        fn: function () {
          return [
            _c(
              "router-link",
              {
                staticClass: "my-feedback-link",
                attrs: {
                  to: { name: _vm.RouterName.MyFeedback },
                  target: "_blank",
                  rel: "opener",
                },
              },
              [_vm._v("\n            我的反馈\n        ")]
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "relatedPic",
        fn: function (ref) {
          var form = ref.form
          return _c("yqg-upload-images", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: ["relatedPic", { initialValue: [] }],
                expression: "['relatedPic', {initialValue: []}]",
              },
            ],
            attrs: { limit: 5, field: "relatedPic", form: form },
          })
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }