/*
 * @Author: yefenghan 
 * @Date: 2024-08-13 15:55:50 
 * @Last Modified by: yefenghan
 * @Last Modified time: 2024-08-23 18:22:32
 */
import ComUploadLog from '@shared/client/util/log';

import store from 'collection-admin-web/common/store';

import Event from './constant/event';
import EventMap from './constant/event-map';
import Status from './constant/status';

export * from './helper/parse-catch';

export const EVENT = Event;

export const STATUS = Status;

export default {
    uploadEventLog(eventName, info) {
        const desc = EventMap[eventName];

        if (!desc) {
            // eslint-disable-next-line
            console.error(`${eventName}埋点信息未定义, 上报失败`)

            return;
        }

        ComUploadLog(eventName, {
            // 统一上报催员ID
            collectorId: store.getters.user.collectorId,
            // 描述信息
            desc,
            // 催收默认measurement信息
            measurement: 'app_metrics_for_cn_web_collection',
            // 默认成功
            status: STATUS.SUCCESS,
            // 默认来源
            source: 'UNKNOWN',
            ...info,
        }, {
            appId: 'Web_COLLECTION',
            country: 'CN',
        }, true);
    }
};
