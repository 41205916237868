var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "alarm-add-flow" },
    [
      _vm._v("\n    【催记提醒】您有一条未下催记的通话\n\n    "),
      _c("yqg-case-link", {
        attrs: { text: "请及时补充", "case-id": _vm.notification.data.caseId },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }