var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("yqg-simple-form", {
    attrs: { title: "申请减免", options: _vm.FormOptions, values: _vm.editing },
    on: { confirm: _vm.onConfirm, cancel: _vm.dismiss },
    scopedSlots: _vm._u([
      {
        key: "remainAmount",
        fn: function (ref) {
          var reduceAmount = ref.values.reduceAmount
          return [
            _vm._v(
              "\n        " +
                _vm._s(
                  _vm._f("numberCommasFixed2")(
                    _vm.remainAmount && _vm.remainAmount - reduceAmount
                  )
                ) +
                "\n    "
            ),
          ]
        },
      },
      {
        key: "imageUrls",
        fn: function (ref) {
          var form = ref.form
          return _c("yqg-upload-images", {
            directives: [
              {
                name: "decorator",
                rawName: "v-decorator",
                value: ["imageUrls", { initialValue: [] }],
                expression: "['imageUrls', {initialValue: []}]",
              },
            ],
            attrs: { size: 5 * 1024 * 1024, field: "imageUrls", form: form },
          })
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }