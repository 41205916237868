<!-- @author giligili -->
<!-- @email zhimingwang@yangqianguan.com -->
<!-- @date 2021-08-25 16:43:38.060 -->
<!-- @desc generated by yqg-cli@5.1.1 -->

<template>
    <span class="yqg-enum">
        <slot
            :value="map[value]"
            :map="map"
        >
            {{ map[value] || defaultText || value }}
        </slot>
    </span>
</template>

<script type="text/babel">

    import {enumType} from 'collection-admin-web/common/mixin';

    export default {
        name: 'YqgEnum',

        mixins: [enumType],

        props: {
            value: {
                type: [Number, String],
                default: undefined
            },

            defaultText: {
                type: [Number, String],
                default: '/'
            }
        }
    };
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .yqg-enum {
        white-space: nowrap;
    }
</style>
