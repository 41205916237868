<!-- @author giligili -->
<!-- @email zhimingwang@yangqianguan.com -->
<!-- @date 2021-07-21 12:51:06.029 -->
<!-- @desc generated by yqg-cli@5.1.1 -->

<template>
    <div class="alarm-add-flow">
        【催记提醒】您有一条未下催记的通话

        <yqg-case-link
            text="请及时补充"
            :case-id="notification.data.caseId"
        />
    </div>
</template>

<script type="text/babel">
    export default {
        name: 'AlarmAddFlow',

        props: {
            notification: {
                type: Object,
                default: () => ({})
            }
        }
    };
</script>
