<!-- @author panezhang -->
<!-- @email panezhang@yangqianguan.com -->
<!-- @date 2018-07-27 15:07:17.314 -->
<!-- @desc generated by yqg-cli@0.3.0 -->

<template>
    <div class="yqg-upload">
        <div class="yqg-upload-input-wrapper">
            <div v-if="uploadLocal">
                <b-input :placeholder="hintText" />
                <input
                    :multiple="multiple"
                    :accept="accept"
                    :disabled="uploading"
                    type="file"
                    @change="onSelect"
                >
            </div>
            <div v-else>
                <b-input
                    :value="value"
                    :placeholder="hintText"
                    @input="onInput"
                />
            </div>
        </div>

        <div class="yqg-upload-value-wrapper">
            <label v-if="!multiple">
                <b-switch
                    v-model="uploadLocal"
                    :disabled="uploading"
                />
                <span>本地上传</span>
            </label>

            <div
                v-if="multiple"
                class="value-list"
            >
                <draggable
                    :list="value"
                    handle=".sortable-handle"
                    @start="dragging = true"
                    @end="dragging = false"
                >
                    <file-link
                        v-for="(url, index) in value"
                        :key="index"
                        :url="url"
                        class="file-item"
                        sortable
                        @delete="onDeleteValue(index)"
                    />
                </draggable>
            </div>

            <file-link
                v-else-if="value"
                :url="value"
                @delete="onReset()"
            />
        </div>

        <div
            v-if="uploadLocal && fileWrappers && fileWrappers.length"
            class="yqg-upload-file-wrapper"
        >
            <file-wrapper
                v-for="(wrapper, index) in fileWrappers"
                :key="wrapper.file.name"
                :wrapper="wrapper"
                :disabled="uploading"
                class="file-item"
                @upload="onUpload([wrapper])"
                @delete="onDeleteWrapper(index)"
            />

            <button
                v-if="multiple"
                :disabled="uploading"
                class="normal"
                @click="onUpload()"
            >
                上传全部
            </button>
        </div>
    </div>
</template>

<script type="text/babel">

    /* eslint-disable */

    import {upload as qiniuUpload} from 'qiniu-js';
    import Draggable from 'vuedraggable';

    import {axios as http} from '@yqg/resource';

    import CommonApiRouter from 'yqg-common/constant/CommonApiRouter';
    import {QiniuGetTokenUrlMap} from 'yqg-common/constant/qiniu-config';

    import {STATE, STATE_MAP} from './constant';

    import FileLink from './file-link.vue';
    import FileWrapper from './file-wrapper.vue';

    export default {
        name: 'YqgUploadImage',

        components: {
            Draggable,
            FileLink,
            FileWrapper
        },

        props: {
            tokenGenUrl: {
                type: String,
                default: ''
            },

            bucket: {
                type: String,
                default: 'default'
            },

            placeholder: {
                type: String,
                default: '选择文件进行上传'
            },

            value: {
                type: [String, Array],
                default: null
            },

            accept: {
                type: String,
                default: '*'
            },

            multiple: {
                type: Boolean,
                default: false
            },

            filename: {
                type: [Boolean, String],
                default: false
            },

            autoUpload: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                STATE_MAP,

                dragging: false,
                uploadLocal: true, // true 本地上传; false 输入 url
                fileWrappers: null
            };
        },

        computed: {
            hintText() {
                const {placeholder, value, multiple, uploadLocal, uploading, fileWrappers} = this;
                if (uploading) {
                    return '上传中，请稍后';
                }

                if (multiple) {
                    return fileWrappers && fileWrappers.length ? '继续添加文件' : placeholder;
                }

                // single without value
                if (!value) {
                    return uploadLocal ? placeholder : '请输入文件 URL';
                }

                return value;
            },

            uploading() {
                const {fileWrappers} = this;
                return fileWrappers && fileWrappers.some(({state}) => (state === STATE.UPLOADING));
            },

            tokenUrl() {
                const {bucket, tokenGenUrl} = this;

                return tokenGenUrl || QiniuGetTokenUrlMap[bucket] || CommonApiRouter.apiWeb.getToken;
            }
        },

        methods: {
            onDeleteValue(index) {
                if (this.value) {
                    this.value.splice(index, 1);
                }
            },

            onDeleteWrapper(index) {
                if (this.fileWrappers) {
                    this.fileWrappers.splice(index, 1);
                }
            },

            onReset() {
                this.$emit('input', this.multiple ? [] : '');
            },

            onInput(text) {
                this.$emit('input', text);
            },

            onSelect({target: {files}}) {
                const fileWrappers = files && [].map.call(files, file => ({
                    file,
                    state: STATE.INITIAL,
                    loaded: 0,
                    total: file.size
                }));

                if (!this.fileWrappers) this.fileWrappers = [];

                const {multiple, autoUpload} = this;
                if (multiple) { // append list
                    this.fileWrappers.push(...fileWrappers);
                } else { // replace list
                    this.fileWrappers = fileWrappers;
                }

                if (autoUpload && this.fileWrappers.length) {
                    this.onUpload();
                }
            },

            async onUpload() {
                const {tokenUrl, fileWrappers} = this;

                for (const fileWrapper of fileWrappers) { // eslint-disable-line no-restricted-syntax
                    if (fileWrapper.state === STATE.SUCCESS) { // check state
                        continue; // eslint-disable-line no-continue
                    }

                    fileWrapper.state = STATE.UPLOADING; // start upload file

                    try {
                        const {data: {body: token}} = await http.get(tokenUrl);

                        const observable = qiniuUpload(fileWrapper.file, null, token);

                        const props = await new Promise((resolve, reject) => {
                            observable.subscribe({
                                next: ({total: {loaded, size}}) => {
                                    fileWrapper.loaded = loaded;
                                    fileWrapper.total = size;
                                },
                                error: reject,
                                complete: async ({body: {url}}) => {
                                    if (url) return resolve({url});

                                    reject();
                                }
                            });
                        });

                        Object.assign(fileWrapper, {state: STATE.SUCCESS, ...props});
                    } catch(_){
                        fileWrapper.state = STATE.FAILED;
                    }
                }

                this.onUploadFinish();
            },

            onUploadFinish() {
                const {value, fileWrappers, isPrivate} = this;
                const uploadedFileWrappers = fileWrappers.filter(({state}) => (state === STATE.SUCCESS));

                this.$emit('input', this.multiple
                    ? [...(value || []), ...uploadedFileWrappers.map(({url}) => url)]
                    : uploadedFileWrappers[0] && uploadedFileWrappers[0].url);

                this.fileWrappers = fileWrappers.filter(({state}) => (state !== STATE.SUCCESS));
            }
        }
    };

</script>

<style lang="scss">
    .yqg-upload {
        &.inline {
            display: flex;

            .yqg-upload-value-wrapper {
                display: flex;
                margin-left: 5px;
                margin-top: -14px;
                align-items: flex-end;

                .file-link {
                    display: flex;
                    align-items: flex-end;
                    margin: 0;
                    margin-left: 5px;
                    padding: 0;
                    width: auto;
                    height: 48px;
                    box-shadow: none;

                    img {
                        max-height: 60px;
                    }

                    .btn-delete {
                        position: static;
                        margin-left: 5px;
                    }
                }
            }
        }

        .yqg-upload-input-wrapper {
            position: relative;

            input[type="file"] {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                margin: 0;
                border: 1px solid #bdbdbd;
                opacity: 0%;
                cursor: pointer;
            }
        }

        .file-item {
            margin: 10px 10px 10px 0;
        }

        .value-list {
            display: flex;
            flex-wrap: wrap;
        }

        .sortable-handle {
            cursor: move;
        }
    }

</style>
