var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-auto-complete",
    _vm._b(
      {
        attrs: {
          value: _vm.value,
          "filter-option": _vm.filterOption,
          "data-source": _vm.list,
          "allow-clear": "",
          "option-label-prop": "value",
        },
        on: { change: _vm.onChange },
      },
      "a-auto-complete",
      _vm.def.props,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }