/*
 * @Author: xiaodongyu
 * @Date 2020-02-20 20:46:42
 * @Last Modified by: jintingliu
 * @Last Modified time: 2023-06-06 16:37:03
 */

/* eslint-disable vue/component-definition-name-casing*/

import {
    AutoComplete,
    Breadcrumb,
    Badge,
    Button,
    Card,
    Col,
    ConfigProvider,
    Divider,
    Drawer,
    Dropdown,
    Descriptions,
    Form,
    Icon,
    Input,
    Layout,
    LocaleProvider,
    Menu,
    message,
    Modal,
    notification,
    Popconfirm,
    Popover,
    Select,
    Spin,
    Tabs,
    Table,
    Upload,
    Checkbox,
    Tree,
    Rate,
    Radio,
    Row,
    Switch,
    Collapse,
    Tag,
    TreeSelect,
    InputNumber,
    DatePicker,
    TimePicker,
    Tooltip,
    List
} from 'ant-design-vue';
import VueClipboard from 'vue-clipboard2';

import Filters from '../filter';
import DefValue from './def-value';
import {hasBaseUi} from './util';
import YqgCardList from './yqg-card-list';
import YqgCodeTextarea from './yqg-code-textarea';
import YqgEnvSwitch from './yqg-env-switch';
import YqgInfinitePagination from './yqg-infinite-pagination';
import YqgInfo from './yqg-info';
import YqgInfoTable from './yqg-info-table';
import YqgSimpleDrawer from './yqg-simple-drawer';
import YqgSimpleForm from './yqg-simple-form';
import FieldAutoComplete from './yqg-simple-form/component/field-auto-complete';
import FieldButtonGroup from './yqg-simple-form/component/field-button-group';
import FieldCheckbox from './yqg-simple-form/component/field-checkbox';
import FieldInput from './yqg-simple-form/component/field-input';
import FieldMdEditor from './yqg-simple-form/component/field-md-editor';
import YqgMdView from './yqg-simple-form/component/field-md-editor/component/md-view';
import FieldRadio from './yqg-simple-form/component/field-radio';
import FieldRich from './yqg-simple-form/component/field-rich';
import FieldSelect from './yqg-simple-form/component/field-select';
import FieldTableForm from './yqg-simple-form/component/field-table-form';
import FieldTextarea from './yqg-simple-form/component/field-textarea';
import FieldVirtualSelect from './yqg-simple-form/component/field-virtual-select';
import YqgSimpleModal from './yqg-simple-modal';
import YqgSimpleTable from './yqg-simple-table';
import YqgStaticForm from './yqg-static-form';
import YqgSuggestionShortcuts from './yqg-suggestion-shortcuts';
import YqgSwimLaneInput from './yqg-swim-lane-input';
import yqgTransfer from './yqg-transfer';

function globalUse(plugins) {
    const hasB = hasBaseUi();
    Object.keys(plugins).forEach(key => {
        this[`$${hasB ? 'a' : ''}${key}`] = plugins[key];
    });
}

export default {
    install(Vue) {
        [
            AutoComplete,
            Breadcrumb,
            Badge,
            Button,
            Card,
            Col,
            ConfigProvider,
            Divider,
            Drawer,
            Dropdown,
            Descriptions,
            Form,
            Icon,
            Input,
            Layout,
            LocaleProvider,
            Menu,
            Modal,
            Popconfirm,
            Popover,
            Select,
            Spin,
            Tabs,
            Table,
            Upload,
            Checkbox,
            Tree,
            Rate,
            Radio,
            Row,
            Switch,
            Collapse,
            Tag,
            TreeSelect,
            InputNumber,
            DatePicker,
            TimePicker,
            Tooltip,
            List
        ].forEach(plugin => Vue.use(plugin));

        const {info, success, error, warning, confirm, destroyAll} = Modal;
        globalUse.call(Vue.prototype, {
            message,
            notification,
            info,
            success,
            error,
            warning,
            confirm,
            destroyAll
        });

        Vue.use(VueClipboard);
        Vue.use(Filters);
        Vue.component('def-value', DefValue);
        Vue.component('field-checkbox', FieldCheckbox);
        Vue.component('field-md-editor', FieldMdEditor);
        Vue.component('field-radio', FieldRadio);
        Vue.component('field-rich', FieldRich);
        Vue.component('field-auto-complete', FieldAutoComplete);
        Vue.component('field-table-form', FieldTableForm);
        Vue.component('field-input', FieldInput);
        Vue.component('field-textarea', FieldTextarea);
        Vue.component('field-button-group', FieldButtonGroup);
        Vue.component('field-select', FieldSelect);
        Vue.component('field-virtual-select', FieldVirtualSelect);
        Vue.component('yqg-simple-form', YqgSimpleForm);
        Vue.component('yqg-simple-modal', YqgSimpleModal);
        Vue.component('yqg-simple-drawer', YqgSimpleDrawer);
        Vue.component('yqg-simple-table', YqgSimpleTable);
        Vue.component('yqg-swim-lane-input', YqgSwimLaneInput);
        Vue.component('yqg-env-switch', YqgEnvSwitch);
        Vue.component('yqg-suggestion-shortcuts', YqgSuggestionShortcuts);
        Vue.component('yqg-info', YqgInfo);
        Vue.component('yqg-static-form', YqgStaticForm);
        Vue.component('yqg-info-table', YqgInfoTable);
        Vue.component('yqg-md-view', YqgMdView);
        Vue.component('yqg-transfer', yqgTransfer);
        Vue.component('yqg-infinite-pagination', YqgInfinitePagination);
        Vue.component('yqg-card-list', YqgCardList);
        Vue.component('yqg-firework', () => import('./yqg-firework'));

        if (!Vue.component('yqg-code-textarea')) {
            Vue.component('yqg-code-textarea', YqgCodeTextarea);
        }
    }
};
