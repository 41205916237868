var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "work-status-warn-duration" }, [
    _vm._v("\n    【工作状态异常提醒】\n    "),
    _c(
      "div",
      { staticClass: "mt10" },
      _vm._l(_vm.descriptionList, function (item) {
        return _c("p", { key: item.label, attrs: { label: item.label } }, [
          _vm._v(
            "\n            " +
              _vm._s(item.label) +
              ": " +
              _vm._s(item.value) +
              "\n        "
          ),
        ])
      }),
      0
    ),
    _vm._v(" "),
    _c("div", { staticClass: "control" }, [
      _c(
        "a",
        {
          on: {
            click: function ($event) {
              return _vm.finishNotification(_vm.notification.data)
            },
          },
        },
        [_vm._v("我知道了")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }