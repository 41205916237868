<!-- @Author: xymo -->
<!-- @Date: 2021-04-13 -->
<!-- @Last Modified by: giligili -->
<!-- @Last Modified time: 2023-08-04 12:12:11 -->

<template>
    <a-layout class="yqg-layout">
        <a-layout-header
            v-if="!$app.isYchatQuoted"
            id="yqg-collection-header"
            :class="['yqg-header', headerClassName]"
        >
            <div class="yqg-header-left">
                <router-link
                    :to="{name: 'index'}"
                    class="yqg-header-logo"
                >
                    天网资产保全
                </router-link>
                <a-icon
                    v-if="!$route.meta.hideSider"
                    class="yqg-header-trigger"
                    :type="collapsed ? 'menu-unfold' : 'menu-fold'"
                    @click="()=> collapsed = !collapsed"
                />

                <header-search
                    v-if="siderVisible"
                    :routes="siderRoutes"
                    class="header-search"
                />
            </div>

            <div
                v-if="isLogin"
                class="yqg-header-icons"
            >
                <!-- 一键多呼 -->
                <header-multi-call
                    v-if="
                        showYtalk
                            && (
                                $app.permissions.CALL_MULTI_DIAL
                                || $app.privileges.CALL.MULTI.DIAL.RECOMMEND
                            )
                    "
                />

                <!-- 泳道 -->
                <yqg-canary-input v-if="canUseCanary" />

                <!-- 泳道 -->
                <yqg-swim-lane-input v-if="!isProd" />

                <!-- 预测外呼 -->
                <header-predict-call v-if="$app.privileges.EXTRA.PINGAN.AUTO.DIAL.STATUS" />

                <!-- 定时短信 -->
                <header-sms />

                <!-- 异步导出的文件 -->
                <header-file-list v-if="$app.privileges.EXTRA.SHOW.FILE.LIST" />

                <!-- 工具 -->
                <header-tool v-if="$app.permissions.CALL_QUERY_UNMASK_PHONE" />

                <!-- 用户反馈 -->
                <header-feedback />

                <!-- 催回率排名 -->
                <header-rank />

                <!-- 通知 -->
                <header-notification />

                <!-- 知识库 -->
                <header-knowledge v-if="$app.permissions.KNOWLEDGE_TEXT_QUERY" />

                <!-- 用户, 登出, 修改密码 -->
                <header-user />
            </div>
        </a-layout-header>

        <a-layout-sider
            v-show="!$route.meta.hideSider && !$app.isYchatQuoted"
            class="yqg-sider"
            collapsible
            :collapsed="collapsed"
            collapsed-width="70"
            :trigger="null"
            width="230"
        >
            <a-menu
                v-if="siderVisible"
                :default-open-keys="siderRoutes.map(({name}) => name)"
                :selected-keys="[siderSelectKeys || '']"
                theme="dark"
                mode="inline"
                class="yqg-sider-menu"
            >
                <a-sub-menu
                    v-for="{name: subName, meta, subRoutes} in siderRoutes"
                    :key="subName"
                    class="sub-menu"
                >
                    <div
                        slot="title"
                        class="sider-title"
                    >
                        <a-icon
                            v-if="meta.icon"
                            :type="meta.icon"
                        />
                        <span>{{ meta.navTitle }}</span>
                    </div>
                    <a-menu-item
                        v-for="{name, path, meta: {navTitle, external}} in subRoutes"
                        :key="name"
                        class="sider-menu"
                    >
                        <template v-if="external">
                            <a
                                :href="path"
                                target="_blank"
                            >
                                {{ navTitle }}
                            </a>
                        </template>
                        <template v-else>
                            <router-link :to="{name}">
                                {{ navTitle }}
                            </router-link>
                        </template>
                    </a-menu-item>
                </a-sub-menu>
            </a-menu>
        </a-layout-sider>

        <a-layout class="yqg-layout yqg-container">
            <div id="yqg-collection-sticky">
                <placard
                    v-if="isLogin && !$app.isYchatQuoted"
                    :style="$app.isYchatQuoted ? {top: 0} : {}"
                />

                <div id="yqg-collection-placehold" />
            </div>

            <yqg-content
                class="yqg-content"
                :style="$app.isYchatQuoted ? {paddingTop: 0} : {}"
            />

            <yqg-footer v-if="!$app.isYchatQuoted" />
        </a-layout>

        <yqg-layout-ytalk v-if="showYtalk" />
    </a-layout>
</template>

<script type="text/babel" lang="js">
import {mapGetters} from 'vuex';

import {STAGE} from 'yqg-common/constant/AppConfig';
import StageUtil from 'yqg-common/core/StageUtil';

import EnumAll from 'collection-admin-web/common/constant/enum';
import Routes from 'collection-admin-web/common/router/routes';
import checkVersion from 'collection-admin-web/common/util/check-version';
import {isMobile} from 'collection-admin-web/common/util/object';
import {singleFactory, genPromise} from 'collection-admin-web/common/util/tool';

import YqgContent from './component/content';
import YqgFooter from './component/footer';
import HeaderFeedback from './component/header-feedback';
import HeaderFileList from './component/header-file-list';
import HeaderKnowledge from './component/header-knowledge';
import HeaderMultiCall from './component/header-multi-call';
import HeaderNotification from './component/header-notification';
import HeaderPredictCall from './component/header-predict-call';
import HeaderRank from './component/header-rank';
import HeaderSearch from './component/header-search';
import HeaderSms from './component/header-sms';
import HeaderTool from './component/header-tool';
import HeaderUser from './component/header-user';
import Placard from './component/placard';

export default {
    name: 'YqgLayout',

    components: {
        YqgContent,
        YqgFooter,

        HeaderSms,
        HeaderUser,
        HeaderRank,
        HeaderSearch,
        HeaderFeedback,
        HeaderFileList,
        HeaderMultiCall,
        HeaderPredictCall,
        HeaderNotification,
        HeaderKnowledge,
        HeaderTool,
        Placard
    },

    data() {
        return {
            collapsed: false,
            headerClassName: /prod/.test(STAGE) ? 'prod' : STAGE,
            isProd: StageUtil.isProdEnv()
        };
    },

    computed: {
        ...mapGetters(['user', 'fetched', 'isLogin', 'businessName']),

        showYtalk() {
            const {isLogin, user} = this;

            return isLogin && user.thirdPartyType === EnumAll.Call.Channel.TYPE.YQG;
        },

        siderVisible() {
            const {fetched, $route: {meta: {hideSider}}} = this;

            return fetched && !hideSider;
        },

        siderRoutes() {
            if (!this.siderVisible) return [];

            // 移动端默认折叠
            if (isMobile) {
                this.collapsed = true; // eslint-disable-line vue/no-side-effects-in-computed-properties
            }

            return this.filterRoutes(Routes, this.businessName).filter(({subRoutes}) => subRoutes && subRoutes.length);
        },

        siderSelectKeys() {
            return this.$route?.meta?.highlightRouterName || this.$route.name;
        },

        canUseCanary() {
            const isCanaryEnv = /canary/.test(window.location.host);

            return this.$app.permissions.EXTRA_NEW_FEATURE_TRIAL && isCanaryEnv;
        }
    },

    watch: {
        $route(route) {
            if (route.name === 'login') {
                this.collapsed = false;
            }
        }
    },

    mounted() {
        checkVersion({
            errorCallback: () => {
                this.$error({title: '当前浏览器版本过低, 请及时更新!'});
            },

            upgradeCallback: singleFactory(() => {
                const {resolve, promise} = genPromise();

                this.$info({
                    title: '发现新版本',
                    content: '请在完成当前工作后重启浏览器以加载新版本!',
                    onOk() {
                        setTimeout(resolve);
                    }
                });

                return promise;
            })
        });
    },

    methods: {
        filterRoutes(routes, businessName) {
            return routes.filter(route => {
                const {meta: {navTitle, hideInSider, yqgPrivileges, yqgBusinessName} = {}, children} = route;
                const showInSider = navTitle && !hideInSider && this.$app.isAuthorized(yqgPrivileges)
                    && (!yqgBusinessName || [].concat(yqgBusinessName).includes(businessName));
                if (showInSider && children) {
                    route.subRoutes = this.filterRoutes(children, businessName);
                }

                return showInSider;
            });
        }
    }
};
</script>

<style lang="scss" rel="stylesheet/scss">
@import "yqg-common/vue/style/variables.scss";
@import "@yqg/vue/antd/style/variable.scss";
@import "@yqg/vue/antd/style/mixin.scss";

$white: #fff;
$header-height: 60px;

.yqg-layout {
    position: relative;
    min-height: 100vh;
    background-color: $white;

    .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
        overflow: visible;
    }

    .yqg-sider {
        position: sticky;
        top: $header-height;
        height: calc(100vh - #{$header-height});
        overflow-y: scroll;

        &-menu {
            padding-bottom: 48px;

            ul {
                margin: 0 0 10px;

                li {
                    padding: 0;
                    margin: 0;
                }
            }
        }

        .sider-title {
            display: flex;
            align-items: center;
            margin: 0 -15px;
            height: 40px;

            span {
                display: block;
                white-space: normal;
                padding-right: 10px;
                line-height: 20px !important;
            }
        }

        .sider-menu {
            display: flex;
            align-items: center;
            padding-left: 34px !important;
            white-space: normal;

            a {
                margin: auto 0;
                line-height: 20px;
            }
        }

        .ant-menu-inline-collapsed {
            .sider-title {
                span {
                    display: none;
                }
            }
        }
    }

    .yqg-header {
        position: fixed;
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding-left: 10px;
        background-color: $white;
        height: $header-height;
        line-height: $header-height;
        border-top: 3px solid #0c8dfd;
        border-bottom: 1px solid #e8e8e8;
        z-index: 900;

        &.dev {
            border-top-color: #0c8dfd;
        }

        &.test {
            border-top-color: #67c65a;
        }

        &.feat {
            border-top-color: #fc0;
        }

        &.prod {
            border-top-color: #ff1338;
        }

        &-logo {
            min-width: 150px;
            color: #999;
            text-decoration: none;
            font-size: 25px;
        }

        &-trigger {
            font-size: 18px;
            line-height: $header-height;
            padding: 0 24px;
            cursor: pointer;
            transition: color 0.3s;

            &:hover {
                color: #1890ff;
            }
        }

        &-left {
            display: flex;
            align-items: center;

            .yqg-header-trigger {
                font-size: 18px;
                padding: 0 24px;
                cursor: pointer;
                transition: color 0.3s;

                &:hover {
                    color: #1890ff;
                }
            }

            .header-search {
                margin-left: 20px;
                width: 300px;

                @media #{$phone-screen} {
                    margin-left: 5px;
                    width: 80px;
                }
            }
        }

        &-icons {
            display: flex;
            align-items: center;

            > * {
                margin: 0 10px;
                font-size: 16px;
                color: #1890ff;
                fill: #1890ff;
                cursor: pointer;

                &:last-child {
                    margin-right: 0;
                }
            }

            .ant-badge-count {
                min-width: 15px;
                height: 15px;
                padding: 0;
                border-radius: 50%;
                line-height: 15px;
            }
        }

        .menu-profile {
            margin-top: 90px;
        }
    }

    .yqg-content {
        padding: $header-height + 24px 24px 24px;
    }

    .yqg-container {
        flex: 1;
        width: 0;
        min-width: 0;

        // 去掉 bfc
        overflow: unset !important;
    }

    #yqg-collection-sticky {
        position: sticky;
        top: $header-height;
        padding: 0 24px;
        z-index: 850;
        background: $white;

        & + .yqg-content {
            padding-top: 60px;
        }
    }
}
</style>

