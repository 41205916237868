var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c(
        "span",
        { staticClass: "dial-btn", on: { click: _vm.dial } },
        [
          _vm._t("default", function () {
            return [
              _c(
                "a-button",
                {
                  staticClass: "dial-btn yqg-btn-info",
                  attrs: { size: "small", disabled: _vm.disabledDial },
                },
                [
                  _c("a-icon", { attrs: { type: "phone" } }),
                  _vm._v("外呼\n        "),
                ],
                1
              ),
            ]
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }