<!-- @author huayizhang -->
<!-- @email huayizhang@yangqianguan.com -->
<!-- @date 2021-07-30 15:33:02.994 -->
<!-- @desc generated by yqg-cli@1.2.7 -->

<template>
    <a-upload
        list-type="picture-card"
        class="yqg-upload-images"
        :custom-request="customRequest"
        :file-list="fileList"
        :accept="accept"
        :disabled="isUploading"
        :remove="onRemove"
        @preview="handlePreview"
    >
        <div v-if="canAdd">
            <a-icon type="plus" />
            <div class="ant-upload-text">
                {{ isUploading ? '上传中...' : '上传图片' }}
            </div>
            <div
                v-if="!!formattedSize"
                class="yqg-text-danger size-tip"
            >
                最大{{ formattedSize }}
            </div>
        </div>
    </a-upload>
</template>

<script type="text/babel">

    import _ from 'underscore';

    import {formatBytes} from 'yqg-common/core/ToolFunction/StringUtil';

    import {customRequest} from 'collection-admin-web/common/constant/fields';

    /* 只返回图片url数组的上传方式 */

    export default {
        name: 'YqgUploadImages',

        props: {
            field: {
                type: String,
                required: true
            },

            form: { // 用来修改外层form的值
                type: Object,
                default: null
            },

            limit: {
                type: [Number, Boolean],
                default: false
            },

            size: {
                type: [Number, Boolean],
                default: false
            },

            accept: {
                type: String,
                default: 'image/*'
            }
        },

        data() {
            const {size} = this;

            return {
                isUploading: false,
                formattedSize: _.isNumber(size) ? formatBytes(size) : null,
                fileList: []
            };
        },

        computed: {
            canAdd() {
                const vm = this;
                const {limit, fileList} = vm;

                // 是布尔的话，false为不限制
                return !limit || fileList?.length < limit;
            }
        },

        watch: {
            fileList(val) {
                this.form?.setFieldsValue({
                    [this.field]: val.map(({url}) => url)
                });
            }
        },

        methods: {
            customRequest(options) {
                const vm = this;
                const {fileList, size, formattedSize} = vm;

                if (size && options?.file?.size > size) {
                    vm.$app.opFail(`图片最大${formattedSize}，上传`);

                    return;
                }

                vm.isUploading = true;
                customRequest({
                    ...options,
                    onSuccess: ({body}) => {
                        if (body) {
                            vm.fileList.push({
                                uid: -(fileList.length + 1), // 文件唯一标识，建议设置为负数，防止和内部产生的 id 冲突
                                name: options.file.name,
                                status: 'done',
                                url: body.url
                            });
                        }

                        vm.isUploading = false;
                    },
                    onError: () => {
                        vm.$app.opFail('图片上传');
                        vm.isUploading = false;
                    }
                });
            },

            onRemove(file) {
                const vm = this;
                const index = vm.fileList.findIndex(({uid}) => uid === file.uid);

                vm.fileList.splice(index, 1);
            },

            handlePreview: ({url}) => window.open(url)
        }
    };

</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .yqg-upload-images {
        ::v-deep .ant-upload {
            width: 104px;
        }

        .size-tip {
            font-size: 12px;
        }
    }

</style>
