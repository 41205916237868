var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "multi-call-btn" },
    [
      _vm.showCallStatus && _vm.dataSource.length
        ? _c(
            "div",
            { staticClass: "call-status mr10" },
            [
              _vm._v("\n        最近结果：\n        "),
              _c(
                "a-tooltip",
                { staticClass: "yqg-text-success", attrs: { title: "接起" } },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.callCount.connected) +
                      "\n        "
                  ),
                ]
              ),
              _vm._v("\n        /\n        "),
              _c(
                "a-tooltip",
                {
                  staticClass: "yqg-text-warning",
                  attrs: { title: "接通未接起" },
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.callCount.ringHangup) +
                      "\n        "
                  ),
                ]
              ),
              _vm._v("\n        /\n        "),
              _c(
                "a-tooltip",
                { staticClass: "yqg-text-danger", attrs: { title: "未接通" } },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.callCount.disconnected) +
                      "\n        "
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.$app.ytalk.canCall
        ? _c("a-button", {
            staticClass: "yqg-btn-info",
            attrs: { disabled: !_vm.$app.ytalk.isLogin },
            domProps: { textContent: _vm._s("一键呼叫") },
            on: { click: _vm.multiDial },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.$app.ytalk.canHangUp
        ? _c("a-button", {
            attrs: { type: "danger" },
            domProps: { textContent: _vm._s("全部挂断") },
            on: {
              click: function ($event) {
                return _vm.$app.ytalk.handleUserAction("terminateSessions")
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }