var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "alarm-oa-add-account" }, [
    _vm._v("\n    【新增账号提醒】\n\n    "),
    _c("span", { staticClass: "mr10" }, [
      _vm._v(_vm._s(_vm.notification.data.remark)),
    ]),
    _vm._v(" "),
    _c(
      "span",
      {
        on: {
          click: function ($event) {
            return _vm.finishNotification(_vm.notification.data)
          },
        },
      },
      [
        _c("yqg-router-link", {
          attrs: {
            text: "请查看",
            to: "accountManager",
            query: { isPending: _vm.$options.BooleanType.TRUE },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }