/*
 * Author: giligili
 * Created Date: 2021-05-13
 * email: zhimingwang@yangqianguan.com
 * desc: 将所有的枚举挂在一起方便引用
 */

import * as Account from './account';
import * as Basic from './basic';
import * as Call from './call';
import * as CallQuality from './call-quality';
import * as Case from './case';
import * as Feedback from './feedback';
import * as GeneralConfig from './general-config';
import * as Lawsuit from './lawsuit';
import * as Privilege from './privilege';
import * as Sms from './sms';
import * as StaticText from './static-text';
import * as Trial from './trial';

export default {
    Basic, // 基础

    Sms, // 短信
    Case, // 案件
    Call, // 打电话
    Trial, // 试算
    Lawsuit, // 诉讼
    Account, // 账号
    Feedback, // 反馈
    Privilege, // 权限
    StaticText, // 静态文案
    CallQuality, // 质检
    GeneralConfig // 通用配置
};
