<!-- @author panezhang -->
<!-- @email panezhang@yangqianguan.com -->
<!-- @date 2018-07-29 23:09:19.787 -->
<!-- @desc generated by yqg-cli@0.3.1 -->
<!-- 用于编辑一个有类型的 json，v-model 绑定的是一个 typed property 数组 -->

<template>
    <div class="yqg-json-typed-editor">
        <div v-if="level === 0">
            <button
                class="normal"
                @click="onAddProperty(prefix)"
            >
                <i class="b-icon-create" /> 添加数据
            </button>
            <button
                class="normal"
                @click="expandAll()"
            >
                全部展开
            </button>
            <button
                class="normal"
                @click="collapseAll()"
            >
                全部折叠
            </button>
        </div>

        <div
            v-if="value && value.length > 0"
            :class="dragging ? 'dragging': ''"
            class="list"
        >
            <draggable
                :list="value"
                handle=".sortable-handle"
                @change="onChange()"
                @start="dragging = true"
                @end="dragging = false"
            >
                <div
                    v-for="(property, index) in value"
                    :key="property.key || index"
                >
                    <typed-property
                        :property="property"
                        :prefix="prefix"
                        :level="level"
                        :index="index"
                        :bucket="bucket"
                        :dir="dir"
                        :root-dir="rootDir"
                        @add="onAddProperty"
                        @edit="onEditProperty"
                        @clone="onCloneProperty"
                        @remove="onRemoveProperty"
                        @change="onChange()"
                    />
                </div>
            </draggable>
        </div>
    </div>
</template>

<script type="text/babel">

/* eslint-disable */
    import Draggable from 'vuedraggable';

    import TYPE from './constant/property-type';

    import TypedProperty from './typed-property.vue';
    import TypedPropertyEditModal from './typed-property-edit-modal.vue';

    import bus, {EXPAND_ALL, COLLAPSE_ALL} from './event-bus';
    import {isValidType, toRaw} from './lib';

    export default {
        name: 'YqgJsonTypedEditor',

        components: {
            Draggable,
            TypedProperty
        },

        props: {
            type: {
                type: String,
                default: TYPE.JSON_TYPED,
                validator: isValidType
            },

            value: {
                type: Array,
                default: () => []
            },

            prefix: {
                type: String,
                default: ''
            },

            level: {
                type: Number,
                default: 0
            },

            bucket: null,

            dir: null,

            rootDir: null
        },

        data() {
            return {
                dragging: false
            };
        },

        methods: {
            onAddProperty(prefix, parentProperty = this) {
                const {bucket, dir, rootDir} = this;
                this.$modal.open(TypedPropertyEditModal, {prefix, parentProperty, bucket, dir, rootDir})
                    .then((childProperty) => {
                        parentProperty.value = parentProperty.value || [];
                        parentProperty.value.push(childProperty);
                        this.onChange();
                    })
                    .catch(x => x);
            },

            onEditProperty(prefix, property) {
                if (!property.timeCreated) {
                    property.timeCreated = property.timeUpadated = Date.now();
                }

                this.$modal.open(TypedPropertyEditModal, {prefix, parentProperty: this, property})
                    .then((newProperty) => {
                        Object.assign(property, newProperty);
                        this.onChange();
                    })
                    .catch(x => x);
            },

            onCloneProperty(prefix, property) {
                const clonedProperty = JSON.parse(JSON.stringify(property));
                delete clonedProperty.timeCreated;
                delete clonedProperty.timeUpdated;

                this.$modal.open(TypedPropertyEditModal, {prefix, parentProperty: this, property: clonedProperty})
                    .then((newProperty) => {
                        this.value.push(newProperty);
                        this.onChange();
                    })
                    .catch(x => x);
            },

            onRemoveProperty(propertyKey, property) {
                const {value} = this;
                this.$modal
                    .openMessageModal(`确认删除 ${propertyKey} 吗？`, '注意')
                    .then(() => {
                        value.splice(value.indexOf(property), 1);
                        this.onChange();
                    })
                    .catch(x => x);
            },

            onChange() {
                const {type, value} = this;
                this.$emit('input', value);
                this.$emit('change', toRaw(type, value));
            },

            expandAll() {
                bus.$emit(EXPAND_ALL);
            },

            collapseAll() {
                bus.$emit(COLLAPSE_ALL);
            }
        }
    };

</script>

<style lang="scss" rel="stylesheet/scss" scoped>

    @import "~base-ui/src/style/variables";

    .yqg-json-typed-editor {
        .list {
            margin-top: 20px;

            &.dragging {
                cursor: move;
            }
        }

        .sortable-chosen {
            outline: 2px dashed $green;
        }
    }

</style>
