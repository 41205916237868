/**
 * @author giligili
 * @date 2021-07-05
 * @email zhimingwang@yangqianguan.com
 * @desc 对应后端 /admin/operation/col/flow 
 */

import Resource from '@yqg/resource';

const api = {
    urlPrefix: '/admin/operation/col/flow/',

    /** @desc 催记结果的MAP, 分为企业微信，接通，未接通，后端自动添加的催记 */
    listFlowResults: {
        url: 'listFlowResults',
        method: 'get'
    },

    /** @desc listFlowResults 返回的几个字段不满足业务需求，还需要组合一下 e.g. 把后端某些自动添加的催记，放在未接通里，让坐席查询 */
    listFlowResultsByConfig: {
        url: 'listFlowResultsByConfig',
        method: 'get'
    },

    listFlowByCaseIdAndMobileNumber: {
        url: 'listFlowByCaseIdAndMobileNumber',
        method: 'post'
    },

    listLastFlow: {
        url: 'listLastFlow',
        method: 'get'
    },

    listFlow: {
        url: 'listFlow',
        method: 'get'
    },

    mutateFlowReview: {
        url: 'mutateFlowReview',
        method: 'post'
    },

    listExtendFlow: {
        url: 'listExtendFlow',
        method: 'get'
    },

    listComplaintFlow: {
        url: 'listComplaintFlow',
        method: 'get'
    },

    listComplaintFlowResults: {
        url: 'listComplaintFlowResults',
        method: 'get'
    },

    queryComplaintFlowRemark: {
        url: 'queryComplaintFlowRemark',
        method: 'get'
    },

    addComplaintFlow: {
        url: 'addComplaintFlow',
        method: 'post'
    },

    addFlow: {
        url: 'addFlow',
        method: 'post'
    },

    autoDialAddFlow: {
        url: '/admin/operation/col/call/pingan/autoDialAddFlow',
        method: 'post'
    },

    addExtendFlow: {
        url: 'addExtendFlow',
        method: 'post'
    },

    listDetailFlowResults: {
        url: 'listDetailFlowResults',
        method: 'get'
    },

    excelCaseFlow: {
        url: 'excelCaseFlow',
        method: 'post',
        formData: true
    }
};

export default Resource.create(api);
