var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "yqg-json-array-visual" },
    [
      _vm._l(_vm.values, function (val, idx) {
        return [
          _c(
            "yqg-json-visual",
            _vm._b(
              {
                key: val.componentId,
                attrs: { value: val },
                on: {
                  delete: function () {
                    return _vm.deleteJson(idx)
                  },
                  change: function (nextValue) {
                    return _vm.changeJson(idx, nextValue)
                  },
                },
              },
              "yqg-json-visual",
              _vm.jsonVisualProps,
              false
            )
          ),
        ]
      }),
      _vm._v(" "),
      _c(
        "a-button",
        {
          attrs: { size: "small", type: "primary" },
          on: { click: _vm.addJson },
        },
        [_vm._v("\n        添加\n    ")]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }