<!-- @Author: huayizhang -->
<!-- @Date: 2021-07-30 15:18:46.670 -->
<!-- @Last Modified by: yefenghan -->
<!-- @Last Modified time: 2024-08-30 12:50:56 -->

<template>
    <yqg-simple-form
        :options="FormOptions"
        title="意见反馈"
        @confirm="onConfirm"
        @cancel="dismiss"
    >
        <template #topBtns>
            <router-link
                class="my-feedback-link"
                :to="{name: RouterName.MyFeedback}"
                target="_blank"
                rel="opener"
            >
                我的反馈
            </router-link>
        </template>

        <yqg-upload-images
            slot="relatedPic"
            slot-scope="{form}"
            v-decorator="['relatedPic', {initialValue: []}]"
            :limit="5"
            field="relatedPic"
            :form="form"
        />
    </yqg-simple-form>
</template>

<script type="text/babel">

    import * as RouterName from 'collection-admin-web/common/constant/router-name';
    import modal from 'collection-admin-web/common/mixin/modal';
    import Feedback from 'collection-admin-web/common/resource/advanced/feedback';

    import FormOptions from './constant/form-options';

    export default {
        name: 'FeedbackModal',

        mixins: [modal],

        data() {
            return {
                FormOptions,
                RouterName
            };
        },

        dialogProps: {
            closable: false
        },

        methods: {
            onConfirm({record}) {
                const vm = this;

                Feedback.add(record).then(({data: {body}}) => {
                    if (body) {
                        vm.$app.opSuccess('反馈');
                        vm.close();
                    }
                });
            }
        }
    };

</script>

<style lang="scss" scoped>
    .my-feedback-link {
        position: absolute;
        top: 24px;
        right: 24px;
        display: block;
    }
</style>
