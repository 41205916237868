var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("def-value", {
    attrs: { value: _vm.content, def: { type: "rich" } },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }