/*
 * @Author: huayizhang 
 * @Date: 2021-10-22 14:00:17 
 * @Last Modified by: huayizhang
 * @Last Modified time: 2021-10-26 11:41:00
 */

export default {
    layout: 'horizontal',
    fieldDefs: [
        {
            label: '减免方式', field: 'reduceType', type: 'select',
            enumType: ({ctx}) => ctx.CaseReduceTypeMap, required: true,
            onChange: ({ctx, form, value}) => ctx.trialCaseByReduceType(form, value)
        },
        {
            label: '减免上限（元）', field: 'reduceAmount', type: 'number', required: true,
            disabled: ({values: {reduceType}}) => !reduceType
        },
        {label: '剩余待还（元）', field: 'remainAmount'},
        {label: '备注', field: 'comment', type: 'textarea', required: true},
        {label: '图片', field: 'imageUrls'}
    ]
};
