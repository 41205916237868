/**
 * @author panezhang
 * @date 2018/7/29-23:10
 * @file property-type
 */

export default {
    TEXT: 'TEXT',
    IMAGE: 'IMAGE',
    IMAGE_LIST: 'IMAGE_LIST',
    FILE: 'FILE',
    FILE_LIST: 'FILE_LIST',
    MARKDOWN: 'MARKDOWN',
    JSON_RAW: 'JSON_RAW',
    JSON_TYPED: 'JSON_TYPED',
    HTML: 'HTML',
    CSS: 'CSS',
    LIST: 'LIST'
};
