var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-dialog",
    {
      attrs: {
        open: _vm.visible,
        "close-on-click-outside": false,
        title: "外呼平台",
        "dialog-class": "sm",
      },
      on: { close: _vm.cancel },
    },
    [
      _c("div", { staticClass: "login-modal-body" }, [
        _vm.accountInfo.account
          ? _c(
              "div",
              [
                _c(
                  "b-form-group",
                  { attrs: { label: "账号", "label-horizontal": "" } },
                  [
                    _c("span", { staticClass: "group-pick" }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.accountInfo.account) +
                          "\n                "
                      ),
                    ]),
                  ]
                ),
              ],
              1
            )
          : _c(
              "div",
              [
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      label: "账号",
                      "label-horizontal": "",
                      "has-star": "",
                    },
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.editing.account,
                          expression: "editing.account",
                        },
                      ],
                      attrs: { type: "text" },
                      domProps: { value: _vm.editing.account },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.editing, "account", $event.target.value)
                        },
                      },
                    }),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      label: "密码",
                      "label-horizontal": "",
                      "has-star": "",
                    },
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.editing.password,
                          expression: "editing.password",
                        },
                      ],
                      attrs: { type: "password" },
                      domProps: { value: _vm.editing.password },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.editing, "password", $event.target.value)
                        },
                      },
                    }),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      label: "socket",
                      "label-horizontal": "",
                      "has-star": "",
                    },
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.editing.socket,
                          expression: "editing.socket",
                        },
                      ],
                      attrs: { type: "text" },
                      domProps: { value: _vm.editing.socket },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.editing, "socket", $event.target.value)
                        },
                      },
                    }),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      label: "uriHost",
                      "label-horizontal": "",
                      "has-star": "",
                    },
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.editing.uriHost,
                          expression: "editing.uriHost",
                        },
                      ],
                      attrs: { type: "text" },
                      domProps: { value: _vm.editing.uriHost },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.editing, "uriHost", $event.target.value)
                        },
                      },
                    }),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "b-form-group",
                  { attrs: { label: "Invite Header", "label-horizontal": "" } },
                  [
                    _c("yqg-code-json", {
                      model: {
                        value: _vm.editing.inviteHeaderString,
                        callback: function ($$v) {
                          _vm.$set(_vm.editing, "inviteHeaderString", $$v)
                        },
                        expression: "editing.inviteHeaderString",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "simple lg",
          attrs: { slot: "actions" },
          on: {
            click: function ($event) {
              return _vm.cancel()
            },
          },
          slot: "actions",
        },
        [_vm._v("\n        取消\n    ")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "simple lg",
          attrs: { slot: "actions" },
          on: {
            click: function ($event) {
              return _vm.confirm()
            },
          },
          slot: "actions",
        },
        [_vm._v("\n        登录\n    ")]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }