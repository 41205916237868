/**
 * @author KylesLight
 * @date 03/04/2018-3:02 PM
 * @file directive.js
 */

import YqgAffix from './yqg-affix';

export default {

    install(Vue) {
        Vue.directive('yqg-affix', YqgAffix);
    }

};
