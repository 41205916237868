<!-- @Author: huayizhang -->
<!-- @Date: 2021-10-22 11:41:45.531 -->
<!-- @Last Modified by: yefenghan -->
<!-- @Last Modified time: 2024-08-13 19:51:47 -->

<template>
    <div>
        <h2 v-text="'发券'" />

        <a-descriptions>
            <a-descriptions-item label="剩余待还">
                <span class="yqg-text-danger">
                    {{ couponInfo.totalRemainAmount || 0 | numberCommasFixed2 }}元
                </span>
            </a-descriptions-item>

            <a-descriptions-item label="发券上限">
                <span class="yqg-text-danger">
                    {{ couponInfo.maxCouponAmount || 0 | numberCommasFixed2 }}元
                </span>
            </a-descriptions-item>

            <a-descriptions-item label="有效期">
                <span class="yqg-text-danger">
                    {{ couponInfo.couponExpiredDays }}天
                </span>
            </a-descriptions-item>
        </a-descriptions>

        <div class="yqg-atlantis yqg-reduce-rule mb10">
            {{ ReduceRule }}
        </div>

        <yqg-simple-form
            :options="FormOptions"
            :values="editing"
            @confirm="onConfirm"
            @cancel="dismiss"
        />
    </div>
</template>

<script type="text/babel">

    import modal from 'collection-admin-web/common/mixin/modal';
    import CaseDetail from 'collection-admin-web/common/resource/case/detail';
    import EventLog, {EVENT, STATUS, parseCatch} from 'collection-admin-web/common/util/event-log';

    import FormOptions from './constant/form-options';
    import ReduceRule from './constant/reduce-rule';

    export default {
        name: 'SendCouponModal',

        mixins: [modal],

        props: {
            caseId: {
                type: [Number, String],
                required: true
            },

            orderNumber: {
                type: [Number, String],
                required: true
            }
        },

        data() {
            return {
                ReduceRule,
                FormOptions,
                couponInfo: {},
                couponInfoVisibility: false,
                editing: {
                    caseId: this.caseId,
                    orderNumber: this.orderNumber
                }
            };
        },

        mounted() {
            this.advanceReduceTrial();
        },

        methods: {
            advanceReduceTrial() {
                const vm = this;
                const {caseId, orderNumber} = vm;

                CaseDetail.trialSettleInAdvanceFee({params: {caseId, orderNumber}})
                    .then(({data: {body = {}}}) => {
                        vm.couponInfo = body;
                        vm.couponInfoVisibility = true;
                    })
                    .catch(err => err);
            },

            onConfirm({record}) {
                const vm = this;

                CaseDetail.openSettleInAdvance(record)
                    .then(({data: {body}}) => {
                        EventLog.uploadEventLog(EVENT.TOUCH_SEND_SETTLE_IN_ADVANCE_COUPON, {
                            status: body ? STATUS.SUCCESS : STATUS.FAIL,
                            extra: {
                                ...record
                            }
                        });
                        if (body) {
                            vm.$message.success('已发券!');
                            vm.close();
                        } else {
                            vm.$message.error('发券失败!');
                        }
                    }).catch(err => {
                        EventLog.uploadEventLog(EVENT.TOUCH_SEND_SETTLE_IN_ADVANCE_COUPON, {
                            status: STATUS.FAIL,
                            reason: parseCatch(err),
                            extra: {
                                ...record
                            }
                        });
                    });
            }
        }
    };

</script>
