var LEVEL = {
    dev: 10,
    test: 20,
    feat: 30,
    prod: 40
};
export default (function (CURRENT_STAGE) { return ({
    isTestEnv: function () { return /test/.test(CURRENT_STAGE); },
    isFeatEnv: function () { return /feat/.test(CURRENT_STAGE); },
    isProdEnv: function () { return /prod/.test(CURRENT_STAGE); },
    isDevEnv: function () { return !/test|feat|prod/.test(CURRENT_STAGE); },
    isBrazil: function () { return /brazil/.test(CURRENT_STAGE); },
    checkStageAvailable: function (exp) {
        if (!exp || this.isDevEnv())
            return true;
        var testReg = /^(<=|=|>=)?(dev|test|feat|prod)/;
        var _a = exp.match(testReg) || [], symbol = _a[1], stage = _a[2];
        var _b = CURRENT_STAGE.match(/(dev|test|feat|prod)/) || [], currentStage = _b[1];
        var targetLevel = LEVEL[stage] || 0;
        var currentLevel = LEVEL[currentStage] || LEVEL.dev;
        if (!stage || !targetLevel)
            return true;
        switch (symbol) {
            case '>=':
                return currentLevel >= targetLevel;
            case '=':
                return currentLevel === targetLevel;
            case '<=':
            default:
                return currentLevel <= targetLevel;
        }
    },
    getCurrentStage: function () { return CURRENT_STAGE; }
}); });
