/**
 * @author giligili
 * @date 2021-08-24
 * @email zhimingwang@yangqianguan.com
 * @desc 催收结果类型 - [电话催记, 投诉催记, 企业微信催记]
 */

export default {
    CALL: 'CALL', // 电催
    WECHAT: 'WECHAT', // 企业微信催记
    COMPLAINT: 'COMPLAINT' // 投诉催记
};
