/*
 * @Author: xiaodongyu
 * @Date 2020-04-17 18:34:26
 * @Last Modified by: youboli
 * @Last Modified time: 2022-11-11 15:57:28
 */

import _ from 'underscore';
import Vue from 'vue';

export function hasBaseUi() {
    return !!Vue.component('b-button');
}

export const isComponent = component => {
    if (!component) return false;

    return typeof component === 'string' // 全局定义组件
        || typeof component.template === 'string' // 定义 template 的组件
        || typeof component.render === 'function'; // 定义 render 函数的组件（比如 .jsx）
};

export const isStorageValuesEqual = (storageValues, values) => {
    return _.isEqual(
        storageValues,
        {
            ..._.pick(values, _.keys(storageValues)), // pick出values和storage的key一致的数据
            ..._.pick(values, value => value !== undefined)
        }
    );
};
