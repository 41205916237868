/**
 * @author giligili
 * @date 2021-06-24
 * @email zhimingwang@yangqianguan.com
 * @desc 挂断方
 */

export default {
    USER: 'USER',
    AGENT: 'AGENT'
};
