<!-- @author center -->
<!-- @date 2022/12/28-4:29 下午 -->
<template>
    <div
        class="yqg-swim-lane-id"
        style="margin-right: 25px;"
    >
        <a-input-search
            v-model="swimLaneId"
            style="display: block;"
            placeholder="设置泳道ID"
            @blur="setSwimLane"
            @search="setSwimLane"
        >
            <a-icon
                slot="enterButton"
                type="setting"
            />
        </a-input-search>
    </div>
</template>

<script>

    import {axios as http} from '@yqg/resource';

    import SwimLaneStorage from '../../util/storage/swim-lane';

    export default {
        name: 'YqgSwimLaneInput',

        data() {
            return {
                swimLaneId: SwimLaneStorage.get()
            };
        },

        created() {
            if (this.swimLaneId) {
                http.defaults.headers.common['fintopia-swim-lane-id'] = this.swimLaneId;
            }
        },

        methods: {
            setSwimLane() {
                const swimLaneId = (this.swimLaneId || '').trim();
                http.defaults.headers.common['fintopia-swim-lane-id'] = swimLaneId;
                SwimLaneStorage.set(swimLaneId);
                this.$router.go(this.$route.currentRoute);
            },
        }
    };
</script>
