/**
 * @Author: giligili
 * @Date: 2021-07-30
 * @Last Modified by: xymo
 * @Last Modified time: 2023-06-29 13:59:43
 */
import { assign, customGet, customPost, customDelete } from '@yqg/resource';
const api = {
    urlPrefix: '/admin/operation/col/call/multi',
    addList: customPost('addList'),
    getList: customGet('getList'),
    deleteList: customDelete('deleteList'),
    deleteAllList: customDelete('deleteAllList'),
    refreshRecommendList: customGet('refreshRecommendList'),
    getCount: customGet('getCount')
};
export default assign(api);
