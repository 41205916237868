/**
 * @Author: giligili
 * @Date: 2023-03-13
 * @Last Modified by: giligili
 * @Last Modified time: 2023-03-31 下午05:48:52
 */
import { assign, customGet, customPost } from '@yqg/resource';
const api = {
    urlPrefix: '/admin/operation/mediate/',
    // 查询调解
    listMediationSchemes: customGet('listMediationSchemes'),
    // 是否支持生成协商方案
    supportCreateMediationScheme: customPost('supportCreateMediationScheme'),
    // 试算订单金额
    trialOrderAmount: customPost('trialOrderAmount'),
    // 获取调解分期付款    
    listMediationInstalments: customGet('listMediationInstalments'),
    // 创建调解计划
    createMediationScheme: customPost('createMediationScheme'),
    // 取消调解计划
    cancelMediationScheme: customPost('cancelMediationScheme'),
    // 获取减免类型
    getReduceTypes: customGet('getReduceTypes'),
    // 通过订单号获取可用的协商方案
    listValidMediationInstalmentsByOrderId: customGet('listValidMediationInstalmentsByOrderId')
};
export default assign(api);
