/**
 * @Author: giligili
 * @Date: 2021-06-24
 * @Last Modified by: giligili
 * @Last Modified time: 2023-05-23 13:36:58
 */

import DialType from '../types/call-dial';

export default {
    [DialType.NORMAL]: '手拨', // 手拨(预测式外呼)
    [DialType.DIAL_BACK]: '回拨', // 回拨
    [DialType.MULTI]: '一键多呼', // 一键多呼
    [DialType.RECOMMEND_MULTI]: '一键多呼_推荐', // 推荐的一键多呼
    [DialType.TRANSFER]: '转接', // 转接
    [DialType.COMPLAINT_DIAL_BACK]: '投诉进线', // 一种特殊的回拨
    [DialType.SMART_IVR_TRANSFER_MANUAL]: '机器人转人工' // 机器人转人工
};
