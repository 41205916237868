var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "yqg-collect-result-select",
      class: { border: _vm.options.length > 1 },
    },
    [
      _vm._l(_vm.options, function (option, index) {
        return [
          _c(
            "div",
            {
              key: index,
              class: {
                "collection-result-select-wrap": _vm.options.length > 1,
              },
            },
            [
              _vm.options.length > 1
                ? _c("div", { staticClass: "title" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(option.title) +
                        "\n            "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("yqg-antv-radio", {
                attrs: { value: _vm.value, "enum-type": option.enumType },
                on: { change: _vm.onChange },
              }),
            ],
            1
          ),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }