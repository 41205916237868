/**
 * @author giligili
 * @date 2022-02-10
 * @email zhimingwang@yangqianguan.com
 * @desc 反馈记录类型
 */

import FeedbackRecord from 'collection-admin-web/common/resource/case/feedback-record';
import Feedback from 'collection-admin-web/common/resource/feedback';
import Enum from 'collection-admin-web/common/util/enum';

export const FeedbackType = Enum.query(FeedbackRecord.listFeedbackType, {
    valueField: 'name',
    textField: 'desc',
    queryOnce: true
});

export const FeedbackClass = Enum.query(Feedback.listFeedbackOperationType, {
    valueField: 'name',
    textField: 'desc',
    queryOnce: true
});

const fetchFeedbackList = caseId => () => Feedback.listFeedbackFlow({params: {caseId}})
    .then(({data: {body}}) => {
        return {data: {body: (body || []).reduce((accu, {id, ticketId, description}) => ({
            ...accu,
            [id]: `${ticketId}(${description})`
        }), {})}};
    });

export const getFeedbackList = caseId => Enum.query(fetchFeedbackList(caseId), {
    queryOnce: false
});
