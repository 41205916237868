/**
 * @author giligili
 * @date 2022-03-03
 * @email zhimingwang@yangqianguan.com
 * @desc 短信相关枚举
 */

import Sms from 'collection-admin-web/common/resource/sms';
import Enum from 'collection-admin-web/common/util/enum';

export const BatchMessageTemplate = Enum.query(Sms.getCanManualBatchSendMessageGroups, {
    queryOnce: true
});

// 短信签名
export const AppName = Enum.query(Sms.getAccessibleAppNames, {
    queryOnce: true
});
