<!-- @author chengyuzhang -->
<!-- @email chengyuzhang@yangqianguang.com -->
<!-- @date 2020-05-11 19:39:23.260 -->
<!-- @desc generated by yqg-cli@1.2.8-alpha.9 -->

<template>
    <!-- eslint-disable-next-line vue/no-v-html -->
    <div
        class="md-view"
        v-html="mdHtml"
    />
</template>

<script type="text/babel">
import DOMPurify from 'dompurify';
import marked from 'marked';

export default {
    name: 'MdView',

    props: {
        mdText: {
            type: String,
            required: true
        }
    },

    computed: {
        mdHtml() {
            return DOMPurify.sanitize(marked(this.mdText ?? ''));
        }
    }
};
</script>

<style lang="scss" rel="stylesheet/scss">
.md-view {
    max-width: 100%;
    padding: 10px 20px;
    overflow-x: auto;
    color: #616161;

    p,
    li,
    table,
    blockquote,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        line-height: 1.6;

        code {
            padding: 0 5px;
            border-radius: 3px;
            background-color: #f3f3f3;
            color: #3f51b5;
            font-family: Menlo, Courier, monospace;
        }
    }

    ul,
    ol {
        padding-left: 20px;
        margin-bottom: 20px;
        list-style-position: outside;

        li {
            list-style: inherit;
            line-height: 1.3em;
            margin-bottom: 5px;

            ul,
            ol {
                margin-top: 8px;
            }
        }
    }

    hr {
        margin-bottom: 20px;
    }

    h1:first-child {
        margin-top: .5em;
    }

    table {
        font-size: 12px;
        color: rgba(0, 0, 0, .9);
        text-align: center;
        border-spacing: 0;
        border-collapse: collapse;
        background-color: #fff;
        border-radius: 2px;

        thead {
            tr {
                height: 56px;
                line-height: 56px;
                vertical-align: middle;
                border-bottom: 1px solid #bdbdbd;

                th {
                    min-width: 120px;
                    padding: 0 15px;
                    color: rgba(0, 0, 0, .54);
                    user-select: none;
                }
            }
        }

        tbody {
            tr {
                height: 40px;
                line-height: 40px;
                vertical-align: middle;
                border-bottom: 1px solid rgba(0, 0, 0, .12);
                transition: background .3s cubic-bezier(.25, .8, .5, 1);

                will-change: backgaound;

                &:hover {
                    background-color: #f3f3f3;
                }

                td {
                    padding: 10px;
                    line-height: normal;
                }
            }
        }
    }

    blockquote {
        font-style: italic;
        margin: 1em 0;
        padding: 1em 20px;
        position: relative;
        color: #616161;
        border-left: 5px solid;
        background-color: #f3f3f3;

        p {
            margin: 0;
        }
    }

    img {
        max-width: 100%;
    }
}
</style>
