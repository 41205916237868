var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "yqg-json-visual yqg-card" },
    [
      _vm._l(_vm.fields, function (field) {
        return [
          _c(
            _vm.$options.FieldCompMap[field.type],
            _vm._b(
              {
                key: field.field,
                tag: "component",
                attrs: {
                  value: _vm.values[field.field],
                  "field-format": _vm.fieldFormat,
                },
                on: {
                  change: function (nextValue) {
                    return _vm.onChange(field.field, nextValue)
                  },
                },
              },
              "component",
              field,
              false
            )
          ),
        ]
      }),
      _vm._v(" "),
      _vm.$listeners.delete
        ? _c(
            "a-button",
            {
              attrs: { size: "small", type: "danger" },
              on: {
                click: function ($event) {
                  return _vm.$emit("delete")
                },
              },
            },
            [_vm._v("\n        删除\n    ")]
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }