var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "yqg-transfer" }, [
    _vm.sourceRecords.length
      ? _c(
          "div",
          { staticClass: "select-wrapper" },
          [
            _c(
              "div",
              { staticClass: "source-wrapper" },
              [
                _vm._t("sourceHeader"),
                _vm._v(" "),
                _c("yqg-simple-table", {
                  attrs: {
                    options: _vm.processSourceTableOptions,
                    records: _vm.sourceRecords,
                  },
                }),
              ],
              2
            ),
            _vm._v(" "),
            _c("a-icon", {
              staticClass: "icon",
              attrs: { type: "caret-right" },
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "target-wrapper" },
              [
                _vm._t("targetHeader"),
                _vm._v(" "),
                _c("yqg-simple-table", {
                  attrs: {
                    options: _vm.processTargetTableOptions,
                    records: _vm.targetRecords,
                    "simple-empty": "",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "op",
                        fn: function (record) {
                          return [
                            _c(
                              "a-popconfirm",
                              {
                                attrs: { title: "是否确认删除" },
                                on: {
                                  confirm: function ($event) {
                                    return _vm.deleteItem(record)
                                  },
                                },
                              },
                              [
                                _c("a-button", {
                                  attrs: {
                                    size: "small",
                                    type: "danger",
                                    icon: "delete",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2771535077
                  ),
                }),
              ],
              2
            ),
          ],
          1
        )
      : _c("div", { staticClass: "no-data" }, [
          _vm._v("\n        暂无数据\n    "),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }