<!-- @author gigigili -->
<!-- @email zhimingwang@yangqianguan.com -->
<!-- @date 2021-05-13 14:47:22 -->
<!-- @desc 封装antdv 的 radio组件 -->

<template>
    <div class="yqg-radio">
        <a-radio-group
            :value="value"
            @change="onChange"
        >
            <template v-for="option in filterOptions">
                <a-radio
                    :key="option.value"
                    :value="option.value"
                >
                    {{ option.label }}
                </a-radio>
            </template>
        </a-radio-group>
    </div>
</template>

<script type="text/babel">

    import {enumType} from 'collection-admin-web/common/mixin';

    export default {
        name: 'YqgAntvRadio',

        mixins: [enumType],

        model: {
            prop: 'value',
            event: 'change'
        },

        props: {
            value: {
                type: [Number, String, Boolean],
                default: undefined
            },

            enumFilter: {
                type: Function,
                default: val => val
            }
        },

        computed: {
            filterOptions() {
                const {options} = this;

                return this.enumFilter(options);
            }
        },

        methods: {
            onChange(event) {
                this.$emit('change', event.target.value);
            }
        }
    };
</script>
