var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-input",
    _vm._b(
      {
        attrs: {
          rows: _vm.rows,
          "rows-max": _vm.rowsMax,
          placeholder: _vm.placeholder,
          filter: _vm.filter,
          "multi-line": "",
        },
        on: { change: _vm.onChange },
        model: {
          value: _vm.editing,
          callback: function ($$v) {
            _vm.editing = $$v
          },
          expression: "editing",
        },
      },
      "b-input",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }