/*
 * @Author: xiaodongyu
 * @Date 2020-08-25 10:30:22
 * @Last Modified by: zhaoyang
 * @Last Modified time: 2021-07-07 15:00:40
 */

export default {
    INIT: 'INIT',
    REGISTERING: 'REGISTERING',
    LOGIN: 'LOGIN',
    LOGOUT: 'LOGOUT',
    AVAILABLE: 'IDLE',
    BUSY: 'BUSY',
    CALLING: 'CALLING',
    DIALING: 'DIALING',
    OFFERING: 'OFFERING',
    CONNECTED: 'CONNECTED'
};
