/*
 * Author: xymo
 * Created Date: 2021-04-21
 * email: xymo@yangqianguan.com
 */

import {upload as qiniuUpload} from 'qiniu-js';

import EnumAll from 'collection-admin-web/common/constant/enum';
import CollectorMap, {CollectorMapWithoutAll} from 'collection-admin-web/common/constant/enum/collector-map';
import UploadResource from 'collection-admin-web/common/resource/upload';
import {aSelectFilterOption} from 'collection-admin-web/common/util/tool';

export * from '@yqg/vue/antd/constant/fields';

// 催收员选择框不搜索拼音
export const collectorId = {
    field: 'collectorId',
    label: '催收员',
    enumType: CollectorMap,
    autoSearchMinLen: 100,
    props: {
        filterOption: aSelectFilterOption,
        dropdownMatchSelectWidth: false,
        notFoundContent: null
    },
    hide: ({ctx}) => !ctx.$store.getters.privileges.EXTRA.GET.COLLECTOR.MAP
};
export const collectorIdWithoutAll = {
    field: 'collectorId',
    label: '催收员',
    enumType: CollectorMapWithoutAll,
    autoSearchMinLen: 100,
    props: {
        filterOption: aSelectFilterOption,
        dropdownMatchSelectWidth: false,
        notFoundContent: null
    },
    hide: ({ctx}) => !ctx.$store.getters.privileges.EXTRA.GET.COLLECTOR.MAP
};
// 同机构 + 下级机构
export const collectorIdWithBrotherAreasWithoutAll = {
    field: 'collectorId',
    label: '催收员',
    enumType: EnumAll.Basic.CollectorMapWithBrotherAreasWithoutAll,
    autoSearchMinLen: 100,
    props: {
        filterOption: aSelectFilterOption,
        dropdownMatchSelectWidth: false,
        notFoundContent: null
    },
    hide: ({ctx}) => !ctx.$store.getters.privileges.EXTRA.GET.COLLECTOR.MAP
};
// 预测外呼帐号
export const genPredictCollectorId = ({
    field = 'adminUserId',
    label = '预测外呼帐号',
    props = {}
}) => ({
    field,
    label,
    type: 'select',
    enumType: EnumAll.Basic.PredictCollectorMap,
    autoSearchMinLen: 100,
    props: {
        filterOption: aSelectFilterOption,
        dropdownMatchSelectWidth: false,
        notFoundContent: null,
        ...props
    },
    hide: ({ctx}) => !ctx.$store.getters.permissions.AUTO_DIAL_TRIGGER
});

export const timeStart = {field: 'timeStart', label: '开始时间', type: 'date', roundMode: 'dayStart'};
export const timeEnd = {field: 'timeEnd', label: '结束时间', type: 'date', roundMode: 'dayEnd'};

/* @desc 给table column设置行间style */
export const genColumnStyle = ({minWidth, maxWidth, wrap}) => () => ({
    style: {
        display: 'inline-block',
        minWidth: `${minWidth}px`,
        maxWidth: `${maxWidth}px`,
        whiteSpace: wrap && 'normal',
        wordBreak: wrap && 'break-all'
    }
});

const onUploadProgress = (event, onProgress) => {
    const {total: {percent}} = event;

    onProgress({percent});
};

export const customRequest = async options => {
    const {file, onError, onProgress, onSuccess} = options;
    const {data: {body: token}} = await UploadResource.getToken();
    const observable = qiniuUpload(file, null, token);
    observable.subscribe({
        next: event => onUploadProgress(event, onProgress),
        error: onError,
        complete: onSuccess
    });
};

export const createCustomRequest = (tokenResource, tokenFilter, getFileName) => async options => {
    const {file, onError, onProgress, onSuccess} = options;
    const {token, serverResponseTime} = tokenFilter(await tokenResource());

    const observable = qiniuUpload(file, getFileName(file, serverResponseTime), token);
    observable.subscribe({
        next: event => onUploadProgress(event, onProgress),
        error: onError,
        complete: onSuccess
    });
};

/* @desc imageDef和fileDef 是上传图片和文件的2个field, 上传到七牛云 */
export const imageDef = {
    filed: 'file',
    label: '上传图片',
    type: 'file',
    props: {
        customRequest, listType: 'picture', valueField: 'url', urlField: 'url',
        accept: '.png,.jpg,.jpeg,.webp,.gif'
    }
};

export const fileDef = {
    filed: 'file',
    label: '上传文件',
    type: 'file',
    props: {customRequest, listType: 'text', valueField: 'url', urlField: 'url'}
};

export const setFields = fields => ({form}) => form.setFieldsValue(fields);
