/**
 * @author giligili
 * @date 2021-07-02
 * @email zhimingwang@yangqianguan.com
 * @desc Color rgb 2 hsv 2 hsl
 */

/* eslint id-length: ["error", { "min": 1 }]*/
export default class Color {

    constructor({hexa}) {
        this.a = 1;

        this.hexa = '';
        this.rgb = {};
        this.hsv = {};
        this.hsl = {};

        if (hexa) {
            this.parseHexa(hexa);
        }
    }

    static rgb2Hsv(r, g, b) {
        r /= 255;
        g /= 255;
        b /= 255;

        let h; let
            s;
        const minVal = Math.min(r, g, b);
        const maxVal = Math.max(r, g, b);
        const delta = maxVal - minVal;

        const v = maxVal;
        if (delta === 0) {
            s = 0;
            h = s;
        } else {
            s = delta / maxVal;
            const dr = (((maxVal - r) / 6) + (delta / 2)) / delta;
            const dg = (((maxVal - g) / 6) + (delta / 2)) / delta;
            const db = (((maxVal - b) / 6) + (delta / 2)) / delta;

            if (r === maxVal) {
                h = db - dg;
            } else if (g === maxVal) {
                h = (1 / 3) + dr - db;
            } else if (b === maxVal) {
                h = (2 / 3) + dg - dr;
            }

            if (h < 0) {
                h += 1;
            } else if (h > 1) {
                h -= 1;
            }
        }

        return [
            h * 360,
            s * 100,
            v * 100
        ];
    }

    static hsv2Rgb(h, s, v) {
        h = (h / 360) * 6;
        s /= 100;
        v /= 100;

        const i = Math.floor(h);

        const f = h - i;
        const p = v * (1 - s);
        const q = v * (1 - f * s);
        const t = v * (1 - (1 - f) * s);

        const mod = i % 6;
        const r = [v, q, p, p, t, v][mod];
        const g = [t, v, v, q, p, p][mod];
        const b = [p, p, t, v, v, q][mod];

        return [
            r * 255,
            g * 255,
            b * 255
        ];
    }

    static num2Hex(num) {
        return num <= 15 ? `0${num.toString(16)}` : num.toString(16);
    }

    static rgba2Hexa(r, g, b, a) {
        const rHex = Color.num2Hex(r);
        const gHex = Color.num2Hex(g);
        const bHex = Color.num2Hex(b);
        const aHex = Color.num2Hex(a);

        return a === 255
            ? `#${rHex}${gHex}${bHex}`
            : `#${rHex}${gHex}${bHex}${aHex}`;
    }

    parseHexa(hexa) {
        const [rHex, gHex, bHex, aHex = 'FF'] = hexa
            .substring(1)
            .match(/.{2}/g);

        this.hexa = hexa;
        this.a = +`0x${aHex}` / 255;

        const r = +`0x${rHex}`;
        const g = +`0x${gHex}`;
        const b = +`0x${bHex}`;

        this.rgb = {r, g, b};

        const [h, s, v] = Color.rgb2Hsv(r, g, b);

        this.hsv = {h, s, v};
    }

    parseHsv(h, s, v) {
        this.hsv = {h, s, v};

        let {a} = this;
        let [r, g, b] = Color.hsv2Rgb(h, s, v);

        r = Math.round(r);
        g = Math.round(g);
        b = Math.round(b);
        a = Math.round(a * 255);

        this.rgb = {r, g, b};

        this.hexa = Color.rgba2Hexa(r, g, b, a);
    }

    parseOpacity(a) {
        this.a = a;

        const {r, g, b} = this.rgb;

        this.hexa = Color.rgba2Hexa(r, g, b, Math.floor(a * 255));
    }

}
