/*
 * @Author: xiaodongyu
 * @Date 2019-12-05 15:12:11
 * @Last Modified by: huayizhang
 * @Last Modified time: 2022-08-25 11:45:18
 */

import _ from 'underscore';

// 使用这个的话，TableOptions没法放在$options里
export const tableSelection = (
    TableOptions,
    {disabled, title, width = 37, ...rowSelection} = {}
) => ({
    data() {
        return {
            selectedRowKeys: [],
            selectedRows: []
        };
    },

    computed: {
        TableOptions() {
            return {
                rowSelection: {
                    fixed: true,
                    columnTitle: title,
                    columnWidth: width,
                    selectedRowKeys: this.selectedRowKeys,
                    onChange: this.onTableSelectChange,
                    getCheckboxProps: record => ({props: {disabled: disabled ? disabled(record) : false}}),
                    ...rowSelection
                },
                ...TableOptions
            };
        }
    },

    methods: {
        onTableSelectChange(selectedRowKeys, selectedRows) {
            this.selectedRowKeys = selectedRowKeys;
            this.selectedRows = selectedRows;
        },

        clearSelectedRows() {
            this.selectedRowKeys = [];
            this.selectedRows = [];
        }
    }
});

export default {
    data() {
        return {
            cond: null,
            pagination: {
                pageNo: 1,
                pageSize: 10,
                total: 0
            },
            sorter: {}
        };
    },

    computed: {
        params() {
            return {
                ...this.pagination,
                total: undefined,
                ...this.cond
            };
        }
    },

    methods: {
        onSearch({values} = {}) {
            this.pagination.pageNo = 1;
            this.cond = _.omit(values, value => value === '');
            this.onRefresh();
        },

        onTableChange({pageSize, pageNo}, filters, sorter) {
            Object.assign(this.pagination, {pageSize, pageNo});
            this.sorter = sorter;
            this.onRefresh();
        }
    }
};
