var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "instalment-list-tab mb20" }, [
    _c("p", { staticClass: "yqg-island-title" }, [
      _vm._v("\n        分期记录\n    "),
    ]),
    _vm._v(" "),
    !_vm.mediateInstalmentVisible
      ? _c("div", [_vm._t("instalment")], 2)
      : _c(
          "div",
          [
            _c(
              "a-tabs",
              [
                _c(
                  "a-tab-pane",
                  { key: "instalment", attrs: { tab: "分期记录" } },
                  [_vm._t("instalment")],
                  2
                ),
                _vm._v(" "),
                _c(
                  "a-tab-pane",
                  { key: "mediateInstalment", attrs: { tab: "调解分期记录" } },
                  [
                    _vm._t("mediateInstalment", null, {
                      orderId: _vm.orderNumber,
                      mediationSchemeId: _vm.mediationSchemeId,
                    }),
                  ],
                  2
                ),
              ],
              1
            ),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }