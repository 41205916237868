/**
 * @author giligili
 * @date 2021-02-22
 * @email zhimingwang@yangqianguan.com
 * @desc 案件详情的hash参数
 */

export const ModuleRouterType = 'ModuleRouterType';

export const ModuleNameMap = {
    FeedbackList: 'FeedbackList' // 反馈记录
};
