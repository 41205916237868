<!-- @author chengyuzhang -->
<!-- @email chengyuzhang@yangqianguang.com -->
<!-- @date 2020-05-12 15:09:07.480 -->
<!-- @desc generated by yqg-cli@1.2.8-alpha.9 -->

<template>
    <yqg-simple-form
        :title="$t('common.uploadImage')"
        :options="formOptions"
        :values="{isLocal: true}"
        @cancel="dismiss"
        @confirm="onConfirm"
    />
</template>

<script type="text/babel">
import {modal} from '../../../mixin';
import getFormOptions from '../constant/image-upload-form-options';

export default {
    name: 'ImageUploadModal',

    mixins: [modal],

    props: {
        imageDef: {
            type: Object,
            default: undefined
        },

        multiple: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        formOptions() {
            return getFormOptions(this);
        }
    },

    methods: {
        onConfirm({record}) {
            this.close(record);
        }
    }
};
</script>
