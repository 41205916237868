<!-- @author giligili -->
<!-- @email zhimingwang@yangqianguan.com -->
<!-- @date 2021-05-25 14:28:16.480 -->
<!-- @desc generated by yqg-cli@4.0.7 -->

<template>
    <div class="yqg-antv-select">
        <a-select
            placeholder="请选择"
            :value="value"
            :mode="mode"
            :size="size"
            :allow-clear="allowClear"
            @change="onChange"
        >
            <a-select-option
                v-for="option in options"
                :key="option.value"
            >
                {{ option.label }}
            </a-select-option>
        </a-select>
    </div>
</template>

<script type="text/babel">
    export default {
        name: 'YqgAntvSelect',

        model: {
            prop: 'value',
            event: 'change'
        },

        props: {
            allowClear: {
                type: Boolean,
                default: false
            },

            value: {
                type: [Number, String, Boolean, Array],
                default: undefined
            },

            multi: {
                type: Boolean,
                default: false
            },

            /* @desc [{value: xx, label: xx}] */
            enumType: {
                type: Array,
                default: () => []
            },

            enumFormat: {
                type: Function,
                default: value => (typeof value === 'object' ? value : ({value, label: value}))
            },

            size: {
                type: String,
                default: 'default'
            }
        },

        computed: {
            options() {
                return this.enumType.map(this.enumFormat);
            },

            mode() {
                return this.multi ? 'multiple' : 'default';
            }
        },

        methods: {
            onChange(value) {
                this.$emit('change', value);
            }
        }
    };
</script>
