var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$app.permissions.EXTRA_ADMIN_SYSTEM_JUMP
    ? _c("img", {
        staticClass: "loan-admin-link",
        attrs: { src: _vm.$options.icon },
        on: { click: _vm.jump2LoanAdmin },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }