/**
 * @author yanglan
 * @date 10/11/2017-1:10 PM
 * @file component
 */

import YqgCodeTextarea from '@yqg/vue/antd/component/yqg-code-textarea';

import YqgArrayInput from './yqg-array-input';
import YqgCallCenter from './yqg-call-center';
import YqgChart from './yqg-chart';
import YqgCodePreview from './yqg-code-preview';
import YqgColorSelect from './yqg-color-select';
import YqgEditorContainer from './yqg-editor-container';
import YqgEllipsisTooltip from './yqg-ellipsis-toolip';
import YqgForm from './yqg-form';
import YqgHtmlEditor from './yqg-html-editor';
import YqgInfoView from './yqg-info-view';
import YqgInput from './yqg-input';
import YqgJsonEditor from './yqg-json-editor';
import YqgJsonTypedEditor from './yqg-json-typed-editor';
import YqgMarked from './yqg-marked';
import YqgMdEditor from './yqg-md-editor';
import YqgNavLoading from './yqg-nav-loading';
import YqgNavMenu from './yqg-nav-menu';
import YqgNavPopover from './yqg-nav-popover';
import YqgNetworkLoading from './yqg-network-loading';
import YqgStageTag from './yqg-stage-tag';
import YqgStaticCompFormat from './yqg-static-comp-format';
import YqgStaticCompLink from './yqg-static-comp-link';
import YqgStyleEditor from './yqg-style-editor';
import YqgTable from './yqg-table';
import YqgUpload from './yqg-upload';

export default {

    install(Vue) {
        Vue.component('YqgArrayInput', YqgArrayInput);
        Vue.component('YqgYtalk', YqgCallCenter);
        Vue.component('YqgChart', YqgChart);
        Vue.component('YqgCodePreview', YqgCodePreview);
        Vue.component('YqgCodeTextarea', YqgCodeTextarea);
        Vue.component('YqgColorSelect', YqgColorSelect);
        Vue.component('YqgEditorContainer', YqgEditorContainer);
        Vue.component('YqgForm', YqgForm);
        Vue.component('YqgHtmlEditor', YqgHtmlEditor);
        Vue.component('YqgInfoView', YqgInfoView);
        Vue.component('YqgInput', YqgInput);
        Vue.component('YqgJsonEditor', YqgJsonEditor);
        Vue.component('YqgJsonTypedEditor', YqgJsonTypedEditor);
        Vue.component('YqgMarked', YqgMarked);
        Vue.component('YqgMdEditor', YqgMdEditor);
        Vue.component('YqgNavLoading', YqgNavLoading);
        Vue.component('YqgNavMenu', YqgNavMenu);
        Vue.component('YqgNavPopover', YqgNavPopover);
        Vue.component('YqgNetworkLoading', YqgNetworkLoading);
        Vue.component('YqgStageTag', YqgStageTag);
        Vue.component('YqgStaticCompFormat', YqgStaticCompFormat);
        Vue.component('YqgStaticCompLink', YqgStaticCompLink);
        Vue.component('YqgStyleEditor', YqgStyleEditor);
        Vue.component('YqgTable', YqgTable);
        Vue.component('YqgUpload', YqgUpload);
        Vue.component('YqgEllipsisTooltip', YqgEllipsisTooltip);
    }

};
