<!-- @Author: giligili -->
<!-- @Date: 2022-02-10 12:51:06.029 -->
<!-- @Last Modified by: yefenghan -->
<!-- @Last Modified time: 2024-04-17 16:02:59 -->

<template>
    <div class="alarm-feedback-flow">
        【反馈提醒】

        <span class="mr10">{{ notification.data.timeRemind | dateTime }}</span>
        <span class="mr10">{{ notification.data.remark }}</span>

        <yqg-router-link
            class="mr10"
            text="请及时处理"
            :hash="hash"
            :to="to"
            :query="query"
        />
    </div>
</template>

<script type="text/babel">

    import {
        ModuleNameMap,
        ModuleRouterType
    } from 'collection-admin-web/common/constant/case-detail-hash';

    export default {
        name: 'AlarmFeedbackFlow',

        props: {
            notification: {
                type: Object,
                default: () => ({})
            }
        },

        data() {
            return {
                hash: encodeURIComponent(JSON.stringify({
                    type: ModuleRouterType,
                    params: {
                        moduleName: ModuleNameMap.FeedbackList,
                        feedbackId: this.notification?.data?.extraData?.feedbackId
                    }
                }))
            };
        },

        computed: {
            isLawsuitCase() {
                // 后端返回的extraData里的字段类型都是字符串，因此需要用字符串true判断
                return this.notification?.data?.extraData?.isLawsuitCase === 'true';
            },
            query() {
                // 诉讼详情页需要orderNumber参数，且caseId不需要加密
                return this.isLawsuitCase
                ? {
                      orderNumber: this.notification?.data?.orderNumber,
                      caseId: this.notification?.data?.caseId
                  }
                : {
                      caseId: this.encryCaseId
                  };
            },
            to() {
                return this.isLawsuitCase ? 'lawsuit' : 'case';
            },
            encryCaseId() {
                return window.btoa(this.notification.data.caseId);
            }
        }
    };
</script>
