
/**
 * @author giligili
 * @date 2021-08-18
 * @email zhimingwang@yangqianguan.com
 * @desc 呼出
 */

import EnumAll from 'collection-admin-web/common/constant/enum';
import Ytalk from 'collection-admin-web/common/resource/call/ytalk';
import {aesEncryptCallSecret} from 'collection-admin-web/common/util/encryption';

import BasicType from './basic-type';

const CallDialType = EnumAll.Call.DialType.TYPE;

export default class MultiType extends BasicType {

    type = 'MULTI';

    constructor(props) {
        super(props);

        this.extraData.dialType = this.extraData.dialType || CallDialType.MULTI;
    }

    /* @desc override */
    getCaseDetail() {
        const {caseDetailRet, sharedDataRet, callDirection} = this;

        const {customData} = this.sharedData;

        return caseDetailRet.promise
            .then(({encryptedMobileNumber}) => {
                const payload = {
                    customData,
                    encryptedMobileNumber
                };

                return Ytalk.getCase(payload)
                    .then(({data: {body}}) => {
                        if (!body) return {link: false};

                        const {
                            caseId,
                            message,
                            sdkType,
                            collectionType,
                            maskedMobileNumber
                        } = body;

                        sharedDataRet.resolve({maskedMobileNumber});

                        const modalInfo = {caseId, message, sdkType};
                        const contactInfo = {
                            ...body,
                            callDirection,
                            contactType: collectionType,
                            encryptMobileNumber: encryptedMobileNumber
                        };

                        this.extraData.contactInfo = contactInfo;

                        return {
                            link: true,
                            caseId,
                            message,
                            modalInfo,
                            contactInfo
                        };
                    });
            });
    }

    /* @desc override */
    getSharedData() {
        const {sharedDataRet} = this;

        return sharedDataRet.promise;
    }

    // 一键多呼 ytalk 先帮我们接通坐席, 此时已经创建了会话
    // 而用户信息要等待用户接通后通过 message 传递给我们
    peerAccept({multiAnswerMobile}) {
        const {caseDetailRet} = this;

        if (multiAnswerMobile) {
            const encryptedMobileNumber = aesEncryptCallSecret(
                multiAnswerMobile
            );

            this.extraData.encryptedMobileNumber = encryptedMobileNumber;

            caseDetailRet.resolve({
                encryptedMobileNumber
            });
        }
    }

}
