var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-upload",
    {
      staticClass: "yqg-upload-images",
      attrs: {
        "list-type": "picture-card",
        "custom-request": _vm.customRequest,
        "file-list": _vm.fileList,
        accept: _vm.accept,
        disabled: _vm.isUploading,
        remove: _vm.onRemove,
      },
      on: { preview: _vm.handlePreview },
    },
    [
      _vm.canAdd
        ? _c(
            "div",
            [
              _c("a-icon", { attrs: { type: "plus" } }),
              _vm._v(" "),
              _c("div", { staticClass: "ant-upload-text" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.isUploading ? "上传中..." : "上传图片") +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              !!_vm.formattedSize
                ? _c("div", { staticClass: "yqg-text-danger size-tip" }, [
                    _vm._v(
                      "\n            最大" +
                        _vm._s(_vm.formattedSize) +
                        "\n        "
                    ),
                  ])
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }