import EventEmitter from 'eventemitter3';
import JsSIP from 'jssip';
import { createUuid } from '../helper';
import Stats from './stats';
const SIPConstant = JsSIP.C;
class Session extends EventEmitter {
    constructor(data, client) {
        super();
        this.handleProcess = ({ response }) => {
            if (!response)
                return;
            this.callUuid = response.getHeader('X-Call-Uuid');
        };
        // 未接通
        this.handleCallFailed = ({ originator, cause }) => {
            this.stats.end();
            // 用户挂断
            if (originator === 'remote') {
                const systemError = [
                    SIPConstant.causes.INTERNAL_ERROR,
                    SIPConstant.causes.BAD_MEDIA_DESCRIPTION
                ];
                if (systemError.includes(cause)) {
                    this.status = 'ERROR';
                    // this.errorMessage = cause;
                    this.client.emit('SESSION_EXCEPTION', {
                        session: this,
                        reason: 'REMOTE_REASON',
                        message: cause,
                    });
                    return;
                }
                // 用户取消
                this.status = 'FAILED_REMOTE';
                this.client.emit('SESSION_FAILED', {
                    session: this,
                    reason: 'REMOTE_REJECT',
                    message: cause,
                });
                return;
            }
            if (originator === 'local') {
                const normalCauses = [
                    SIPConstant.causes.CANCELED,
                    SIPConstant.causes.REJECTED
                ];
                // 异常
                if (!normalCauses.includes(cause)) {
                    this.status = 'ERROR';
                    // this.errorMessage = cause;
                    this.client.emit('SESSION_EXCEPTION', {
                        session: this,
                        reason: 'LOCAL_REASON',
                        message: cause,
                    });
                    return;
                }
                // 异常（可能是没有发 INVITE）
                if (!this.callUuid) {
                    this.client.emit('SESSION_EXCEPTION', {
                        session: this,
                        reason: 'NO_CALL_UUID',
                        message: cause,
                    });
                    return;
                }
                this.status = 'FAILED_LOCAL';
                this.client.emit('SESSION_FAILED', {
                    session: this,
                    reason: 'LOCAL_REJECT',
                    message: cause,
                });
            }
        };
        this.callEnded = () => {
            this.stats.end();
        };
        this.callAccepted = () => {
            this.connected = true;
        };
        this.callConfirmed = () => {
            this.connected = true;
        };
        this.getConnection = ({ peerconnection }) => {
            this.stats.start(peerconnection);
        };
        this.rtpStats = (stats) => {
            this.client.emit('RTP_STATS_END', {
                session: this,
                stats,
            });
        };
        this.listen = () => {
            this.stats.addListener('RTP_STATS_END', this.rtpStats);
            this.session.addListener('failed', this.handleCallFailed);
            this.session.addListener('ended', this.callEnded);
            this.session.addListener('accepted', this.callAccepted);
            this.session.addListener('confirmed', this.callConfirmed);
            this.session.addListener('peerconnection', this.getConnection);
            this.session.addListener('progress', this.handleProcess);
        };
        this.destroy = () => {
            this.stats.end();
            this.stats.removeListener('RTP_STATS_END', this.rtpStats);
            this.session.removeListener('failed', this.handleCallFailed);
            this.session.removeListener('ended', this.callEnded);
            this.session.removeListener('accepted', this.callAccepted);
            this.session.removeListener('confirmed', this.callConfirmed);
            this.session.removeListener('peerconnection', this.getConnection);
            this.session.removeListener('progress', this.handleProcess);
        };
        const { originator, session, request } = data;
        this.client = client;
        this.id = request._call_id; // eslint-disable-line
        this.uuid = createUuid();
        this.callUuid = '';
        this.status = 'INIT';
        this.connected = false;
        this.session = session;
        this.request = request;
        this.originator = originator;
        this.callType = originator === 'local'
            ? 'NORMLA'
            : request.getHeader('X-Call-Type');
        this.stats = new Stats();
        if (this.originator === 'remote') {
            this.callUuid = request.getHeader('X-Call-Uuid');
        }
        if (this.originator === 'local') {
            this.stats.start(session._connection); // eslint-disable-line
        }
        this.listen();
    }
}
export default Session;
