var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-textarea",
    _vm._b(
      { attrs: { value: _vm.value }, on: { change: _vm.onChange } },
      "a-textarea",
      _vm.def.props,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }