var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("yqg-modal", {
    attrs: { title: "一键多呼" },
    scopedSlots: _vm._u([
      {
        key: "container",
        fn: function () {
          return [
            _c(
              "a-tabs",
              [
                _vm.$app.privileges.CALL.MULTI.DIAL.RECOMMEND
                  ? _c(
                      "a-tab-pane",
                      {
                        key: "recommendCaseList",
                        attrs: { tab: "自动推荐列表" },
                      },
                      [
                        _c("recommend-call-list", {
                          on: {
                            "call-connect": function ($event) {
                              return _vm.$emit("dismiss")
                            },
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.$app.privileges.CALL.MULTI.DIAL.self
                  ? _c(
                      "a-tab-pane",
                      { key: "caseList", attrs: { tab: "手动添加列表" } },
                      [
                        _c("call-list", {
                          on: {
                            "call-connect": function ($event) {
                              return _vm.$emit("dismiss")
                            },
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }