<!-- @Author: xiaodongyu -->
<!-- @Date: 2019-12-16 14:46:43 -->
<!-- @Last Modified by: wenxiujiang -->
<!-- @Last Modified time: 2023-06-16 18:05:56 -->

<template>
    <div :class="{'preview-file-wrap': customPreview}">
        <component
            :is="component"
            class="field-file"
            v-bind="antProp"
            @change="onChange"
            @preview="onPreview"
        >
            <component
                :is="def.props.draggable ? 'p' : 'a-button'"
            >
                <a-icon type="upload" />
                <component
                    :is="draggableTextComp"
                    v-if="draggableTextComp"
                />
                <span
                    v-else
                    v-html="draggableText || $t('common.chooseFile')"
                />
                <div v-if="def.props.draggable && acceptTips">
                    {{ acceptTips }}
                </div>
            </component>
        </component>
        <div
            v-if="message"
            class="yqg-text-danger"
        >
            {{ message }}
        </div>
        <template v-if="customPreview">
            <div
                v-for="file in fileList"
                :key="file.uid"
            >
                <div v-if="file.response">
                    <p>
                        <a-icon type="link" />
                        <a @click="onPreview(file)">{{ decodeURIComponentSafe(file.name) }}</a>
                        <a-button
                            v-if="isPdf(file.name) || (def.docxPreview && isDocx(file.name))"
                            size="small"
                            @click="onPreviewPdf(file)"
                        >
                            {{ $t('file.preview') }}
                        </a-button>
                        <a-button
                            v-if="(isPdf(file.name) || isDocx(file.name)) && def.print"
                            size="small"
                            @click="def.print(file.response.body)"
                        >
                            {{ $t('common.print') }}
                        </a-button>
                        <a-icon
                            type="delete"
                            class="danger"
                            @click="remove(file)"
                        />
                    </p>
                    <img
                        v-if="isImage(file.name)"
                        class="preview-img"
                        :src="getFileUrl(file)"
                    >
                    <audio
                        v-if="isAudio(file.name)"
                        :src="getFileUrl(file)"
                        controls
                    />
                </div>
            </div>
        </template>
    </div>
</template>

<script type="text/babel">
import _ from 'underscore';

import {pickValue, evalProp} from '../../../util/object';

const getScope = (min, max, filter = x => x, $t) => {
    const isExact = min === max;
    if (isExact) return `${filter(min)}`;
    if (min === 0) return $t('file.noGreaterThan', [filter(max)]);
    if (max === Infinity) return $t('file.noLessThan', [filter(min)]);

    return `${filter(min)}-${filter(max)}`;
};

const unitByte = size => {
    if (typeof size !== 'number' || size < 0) return 0;

    const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const unitFactor = 1024;
    let result = `${size} B`;
    let index = 0;

    while (size >= unitFactor && index !== units.length - 1) {
        index += 1;
        size /= unitFactor;
        if (!Number.isInteger(size)) size = size.toFixed(2);
        result = `${size}${units[index]}`;
    }

    return result;
};

export default {
    name: 'FieldFile',

    props: {
        def: {
            type: Object,
            required: true
        },

        value: {
            type: [File, Array, Number, String, Object],
            default: undefined
        },

        previewValue: {
            type: [File, Array, Number, String],
            default: undefined
        },

        ctx: {
            type: Object,
            default: () => ({})
        }
    },

    data() {
        return {
            fileList: [].concat(this.previewValue || []),
            isValid: true,
            message: null
        };
    },

    computed: {
        multiple() {
            const {def: {props: {single = true} = {}}} = this;

            return !single;
        },

        component() {
            return this.def.props.draggable ? 'a-upload-dragger' : 'a-upload';
        },

        acceptTips() {
            const {def: {props: {accept, acceptTipsInvisible} = {}}} = this;

            return (accept && !acceptTipsInvisible)
                ? this.$t('file.acceptTypes', [this.isZh ? accept.replace(/,/g, '、') : accept])
                : '';
        },

        antProp() {
            const {
                def: {props = {}},
                fileList, multiple, beforeUpload, remove
            } = this;

            return {
                ...props,
                draggable: undefined,
                raw: undefined,
                fileList,
                multiple,
                beforeUpload,
                remove
            };
        },

        draggableText() {
            const {def: {props}} = this;

            return evalProp(props.draggableText, props);
        },

        draggableTextComp() {
            return this.def.props.draggableTextComp;
        },

        customPreview() {
            return this.def.props.preview || false;
        },

        isZh() {
            return !this.$i18n || this.$i18n.locale === 'zh';
        }
    },

    watch: {
        value(val) {
            if (!val) {
                this.fileList = [];
            }
        },

        previewValue(val) {
            if (val && !this.fileList?.length) {
                this.fileList = [].concat(val);
            }
        }
    },

    methods: {
        validateFile(file) {
            const {name, size} = file;
            const {$t} = this;
            if (size === 0) {
                return {isValid: false, message: $t('file.empty')};
            }

            const {def: {props: {minSize = 0, maxSize = Infinity, accept, imageOnly = false} = {}}, isZh} = this;
            const isImg = this.isImage(name);
            const accepts = accept?.split(',') || [];

            if (accepts.length && !accepts.some(ext => new RegExp(`${ext}$`, 'i').test(name))) {
                return {isValid: false, message: $t('file.acceptTypes', [accepts.join(isZh ? '、' : ',')])};
            }

            if (imageOnly && !isImg) {
                return {isValid: false, message: $t('file.onlyImage')};
            }

            if (size < minSize || size > maxSize) {
                const fileType = $t(isImg ? 'file.image' : 'file.file');

                return {
                    isValid: false,
                    // eslint-disable-next-line max-len
                    message: $t('file.size', {fileType, curSize: unitByte(size), size: getScope(minSize, maxSize, unitByte, $t)})
                };
            }

            return {isValid: true, message: null};
        },

        beforeUpload(file) {
            const {def: {props: {raw, imageOnly = false}, customFileName, customValidateFile}, validateFile, multiple, $t, ctx} = this;

            // eslint-disable-next-line
            return new Promise(async (resolve, reject) => {
                if (customValidateFile) {
                    const {isValid: isCustomValid, message: customMessage} = await customValidateFile(file, ctx);
                    if (!isCustomValid) {
                        this.message = customMessage;
                        reject();

                        return;
                    }
                }

                const {isValid, message} = validateFile(file);

                if (!isValid) {
                    this.message = message;
                    reject();

                    return;
                }

                if (imageOnly) {
                    const reader = new FileReader();
                    await new Promise((imgResolve, imgReject) => {
                        reader.onload = ({target: {result}}) => {
                            const image = new Image();
                            image.onerror = () => {
                                this.message = $t('file.error');
                                imgReject();
                            };

                            image.onload = imgResolve;
                            image.src = result;
                        };

                        reader.readAsDataURL(file);
                    });
                }

                this.message = null;

                if (customFileName) {
                    customFileName(file, ctx);
                }

                if (raw) {
                    this.fileList = multiple ? [...this.fileList, file] : [file];
                    this.$emit('change', multiple ? this.fileList : file);
                    reject();

                    return;
                }

                resolve();
            });
        },

        onChange(info) {
            const {multiple, def: {props: {raw}}} = this;
            const {file, file: {uid, status}} = info;

            if (multiple) {
                this.fileList = _.find(this.fileList, item => item.uid === uid)
                    ? this.fileList : [...this.fileList, file];
            } else {
                this.fileList = [file];
            }

            if (!raw && status === 'done') {
                // 刷新预览
                this.fileList = [...this.fileList];
                const values = this.getFileListValues();
                this.$emit('change', multiple ? values : values[0]);
            }
        },

        onPreview({response}) {
            const {def: {props = {}}} = this;
            if (props.urlField) {
                window.open(pickValue(response.body || response, props.urlField));
            }
        },

        async onPreviewPdf(file) {
            const url = await this.getPreviewUrl(file);
            window.open(url);
        },

        getFileListValues() {
            const {def: {props: {valueField} = {}}, fileList} = this;

            return fileList
                .filter(item => item.response)
                .map(item => {
                    const {response} = item;
                    const {body = response} = response;
                    if (valueField) return pickValue(body, valueField);

                    // 方便一些中间组件如field-rich的上传，既能拿到url又能拿到id 还能拿到file name
                    return {...item, ...body};
                });
        },

        remove(file) {
            const {multiple, def: {props = {}}} = this;
            const index = this.fileList.indexOf(file);
            this.fileList.splice(index, 1);
            if (multiple) {
                this.$emit('change', props.raw ? this.fileList : this.getFileListValues());
            } else {
                this.$emit('change', undefined);
            }
        },

        getFileUrl({response}) {
            const {def: {props: {urlField} = {}}} = this;

            return pickValue(response.body, urlField);
        },

        async getPreviewUrl({response}) {
            const {def: {props: {previewField, urlField, getCustomPreviewUrl} = {}}} = this;
            const previewUrl = pickValue(response.body, previewField);
            const url = pickValue(response.body, urlField);

            if (!previewUrl && getCustomPreviewUrl) {
                const previewPdfUrl = await getCustomPreviewUrl({url, previewField, body: response.body});

                return previewPdfUrl;
            }

            return previewUrl || url;
        },

        decodeURIComponentSafe(encodedURIComponent) {
            try {
                return decodeURIComponent(encodedURIComponent);
            } catch (err) {
                return encodedURIComponent;
            }
        },

        isImage(name) {
            const IMG_TESTER = /\.(img|jpg|jpeg|png|gif|bmp)$/i;

            return IMG_TESTER.test(name);
        },

        isPdf(name) {
            return /\.pdf$/i.test(name);
        },

        isDocx(name) {
            return /\.docx?$/i.test(name);
        },

        isAudio(name) {
            return /\.wav$/i.test(name);
        }
    }
};
</script>

<style lang="scss" rel="stylesheet/scss">
.field-file {
    .ant-upload-drag {
        padding: 20px 0;
    }
}

.preview-file-wrap {
    .ant-upload-list {
        display: none;
    }
}

.preview-img {
    max-width: 100%;
    max-height: 200px;
    object-fit: scale-down;
}
</style>
