var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    { staticClass: "yqg-layout-add-flow-btn" },
    [
      _vm.$app.privileges.CASE.DETAIL.FLOW.MUTATE
        ? _c(
            "a-button",
            {
              attrs: { type: "primary", size: "small", icon: "plus" },
              on: { click: _vm.addCollectionFlow },
            },
            [_vm._v("\n        添加催记\n    ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.$app.privileges.COMPLAINT.FLOW.MUTATE
        ? _c(
            "a-button",
            {
              attrs: { type: "primary", size: "small", icon: "plus" },
              on: { click: _vm.addComplaintCollectionFlow },
            },
            [_vm._v("\n        添加投诉催记\n    ")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }