/**
 * @Author: xinzhong
 * @Date: 2020-11-25 16:16:08.762
 * @Last Modified by: yefenghan
 * @Last Modified time: 2024-08-30 15:08:39
 */

/* eslint-disable import/extensions */

import 'core-js/stable';
import 'regenerator-runtime/runtime';

import {setIgnoreError} from '@yqg/reporter';

import ApiMetrics from '@shared/client/util/api-metrics';
import fmp from '@shared/client/util/fmp';
import {fmpCustomReport, customBeforeSendTransaction} from '@shared/client/util/fmp-report';
import {initSentry} from '@shared/client/util/sentry';
import whiteScreenCheck from '@shared/client/util/white-screen-check';
import {STAGE} from '@shared/common/constant/stage';

import '@shared/client/vue-error-handler';

import {ignoreRouteError} from 'yqg-common/core/report';
import Vue from 'yqg-common/vue';

import store from 'collection-admin-web/common/store';

import router from 'src/common/router';

import App from './app.vue';

import 'collection-admin-web/common/style/index.scss';

ignoreRouteError();
// ignore jssip status error
setIgnoreError(err => !err || /^(Invalid status)/.test(err.message));

const tracesSampleRate = /prod/i.test(STAGE) ? 1 : 1;

initSentry({
    router,
    dsn: 'https://02c4323a0cdbd7f2c718ed22a9bc73d2@sentry.fintopia.tech/66',
    tracesSampleRate,
    beforeSendTransaction: customBeforeSendTransaction(fmp)
});

fmp.init(res => {
    try {
        const userName = store?.getters?.user?.name || '';
        const data = {
            ...res,
            userName
        };

        fmpCustomReport(data);
    } catch (error) {
        //ignore
    }
});

// 白屏检测
whiteScreenCheck.init({
    container: ['html', 'body', '#app', '#app-content', '.network-loading'],
    reportConfig: {
        appId: 'Web_COLLECTION',
        country: 'CN',
        isReportAll: true
    }
});

// 接口性能采集
new ApiMetrics({
    stage: STAGE,
    appId: 'Web_COLLECTION',
    limitRate: 0.1
});

const app = new Vue(App);
app.$mount('#app');
