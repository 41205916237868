/*
 * @Author: wenxiujiang
 * @Date: 2021-04-13 8:28:00
 * @Last Modified by: wenxiujiang
 * @Last Modified time: 2021-04-13 8:28:00
 */

/* eslint-disable max-len */

export default {
    name: 'beauty-eye',
    theme: 'outline',
    icon: {
        tag: 'svg',
        attrs: {viewBox: '0 0 1025 1024', x: '0', y: '0'},
        children: [
            {
                tag: 'path',
                attrs: {
                    d: 'M950.930286 548.571429q-86.857143-134.838857-217.709714-201.728 34.852571 59.428571 34.852571 128.585143 0 105.728-75.154286 180.845714t-180.845714 75.154286-180.845714-75.154286-75.154286-180.845714q0-69.156571 34.852571-128.585143-130.852571 66.852571-217.709714 201.728 75.995429 117.138286 190.573714 186.587429t248.283429 69.412571 248.283429-69.412571 190.573714-186.587429zM539.501714 329.142857q0-11.446857-8.009143-19.419429t-19.419429-8.009143q-71.424 0-122.587429 51.126857t-51.126857 122.587429q0 11.446857 8.009143 19.419429t19.419429 8.009143 19.419429-8.009143 8.009143-19.419429q0-49.152 34.852571-84.004571t84.004571-34.852571q11.446857 0 19.419429-8.009143t8.009143-19.419429zM1024.073143 548.571429q0 19.419429-11.446857 39.424-80.018286 131.437714-215.149714 210.578286t-285.44 79.140571-285.44-79.433143-215.149714-210.285714q-11.446857-20.004571-11.446857-39.424t11.446857-39.424q80.018286-130.852571 215.149714-210.285714t285.44-79.433143 285.44 79.433143 215.149714 210.285714q11.446857 20.004571 11.446857 39.424z'
                }
            }
        ]
    }
};
