/**
 * @author giligili
 * @date 2021-08-02
 * @email zhimingwang@yangqianguan.com
 * @desc multi call list
 */

import CallMulti from 'collection-admin-web/common/resource/call/multi';

const UPDATE_MULTI_CALL = 'UPDATE_MULTI_CALL';
const UPDATE_MULTI_CALL_COUNT = 'UPDATE_MULTI_CALL_COUNT';

export default {
    namespaced: true,

    state: {
        caseList: [],
        recommendCaseList: [],

        multiCallCount: 0,
        multiListMaxSize: 0
    },

    getters: {
        caseList: state => state.caseList,
        recommendCaseList: state => state.recommendCaseList,

        multiCallCount: state => state.multiCallCount,
        multiListMaxSize: state => state.multiListMaxSize
    },

    mutations: {
        [UPDATE_MULTI_CALL](state, payload) {
            Object.assign(state, payload);
        },

        [UPDATE_MULTI_CALL_COUNT](state, multiCallCount) {
            Object.assign(state, {multiCallCount});
        }
    },

    actions: {
        fetchMultiCallList({commit}, params = {}) {
            CallMulti.getList({params})
                .then(({data: {body}}) => {
                    const {
                        caseList = [],
                        recommendCaseList = [],
                        multiListMaxSize
                    } = body;

                    commit(UPDATE_MULTI_CALL, {
                        caseList,
                        recommendCaseList,

                        multiListMaxSize
                    });
                })
                .catch(err => err);
        },

        fetchMultiCallCount({commit}) {
            CallMulti.getCount()
                .then(({data: {body = 0}}) => commit(UPDATE_MULTI_CALL_COUNT, body))
                .catch(err => err);
        }
    }
};
