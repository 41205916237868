/*
 * @Author: xiaodongyu
 * @Date 2020-05-15 15:42:25
 * @Last Modified by: xiaodongyu
 * @Last Modified time: 2020-06-29 22:55:40
 */

import Resource from '@yqg/resource';

const api = {
    getToken: {
        url: '/api-web/yqg-upload/get-token',
        method: 'get'
    }
};

export default Resource.create(api);
