<!-- @Author: xymo -->
<!-- @Date: 2023-07-20 19:01:32 -->
<!-- @Last Modified by: xymo -->
<!-- @Last Modified time: 2023-07-20 19:01:32 -->

<template>
    <div class="header-knowledge">
        <a-button
            type="link"
            icon="book"
            @click="showDrawer"
        />

        <a-drawer
            title="知识库"
            placement="right"
            width="60%"
            :visible="visible"
            :z-index="900"
            :mask-closable="true"
            @close="onClose"
        >
            <knowledge-application />
        </a-drawer>
    </div>
</template>

<script type="text/babel">
import knowledgeApplication from 'collection-admin-web/app/knowledge/application';

export default {
    name: 'HeaderKnowledge',

    components: {
        knowledgeApplication
    },

    data() {
        return {
            visible: false
        };
    },

    methods: {
        showDrawer() {
            this.visible = true;
        },

        onClose() {
            this.visible = false;
        }
    }
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.header-knowledge {
    display: flex;
}
</style>
