var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "alarm-user-login" },
    [
      _vm._v(
        "\n    " +
          _vm._s(_vm.notification.data.name) +
          " 于 " +
          _vm._s(_vm._f("dateTime")(_vm.notification.data.timeRemind)) +
          " 登录了APP\n\n    "
      ),
      _c("yqg-case-link", {
        attrs: { text: "案件详情 >", "case-id": _vm.notification.data.caseId },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }