/**
 * @Author: xymo
 * @Date: 2023-07-19
 * @Last Modified by: xymo
 * @Last Modified time: 2023-07-19 14:27:45
 */
import { defineFields } from '@yqg/type';
import { CategoryEnum } from './enum/category';
export default defineFields({
    keyword: { field: 'keyword', label: '关键字' },
    questionCategoryIds: { field: 'questionCategoryIds', label: '类别筛选',
        type: 'tree',
        enumType: CategoryEnum,
        props: { multiple: true, treeDefaultExpandAll: false }
    },
    sort: { field: 'sort', label: ' ' }
});
