var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "md-editor" }, [
    _c(
      "div",
      { staticClass: "app-bar" },
      _vm._l(_vm.actionList, function (action) {
        return _c(
          "a-button",
          {
            key: action.name,
            staticClass: "button",
            attrs: { disabled: _vm.disabled },
            on: {
              click: function ($event) {
                return _vm.callAction(action)
              },
            },
          },
          [
            action.icon.type === "icon"
              ? _c("a-icon", { attrs: { type: action.icon.value } })
              : _c("span", [_vm._v(" " + _vm._s(action.icon.value) + " ")]),
          ],
          1
        )
      }),
      1
    ),
    _vm._v(" "),
    _c("div", { style: _vm.customizedStyle }, [
      _c("textarea", {
        ref: "textarea",
        staticClass: "textarea",
        style: { cursor: "" + (_vm.disabled ? "not-allowed" : "pointer") },
        attrs: { disabled: _vm.disabled },
        domProps: { value: _vm.value },
        on: { input: _vm.onInput, keydown: _vm.handleKeyDown },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }