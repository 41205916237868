/**
 * @author center
 * @date 2018/11/1-上午11:29
 * @file PercentFixed2Filter
 */
/* eslint-disable max-len */
import {decimalToPercent} from '../../../core/ToolFunction/StringUtil';

export default () => (input, {sign = false} = {}) => {
    // 不知为啥有的数据会报错，所以加上try
    // TypeError: (0 , _core_ToolFunction_StringUtil__WEBPACK_IMPORTED_MODULE_0__.decimalToPercent)(...).filter(...).toFixed is not a function

    try {
        return (isFinite(input) && input !== null && (input === +input || !isNaN(input)))
            ? decimalToPercent().filter(input).toFixed(2) + (sign ? '%' : '')
            : '/';
    } catch (err) {
        return '/';
    }
};
