var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "yqg-radio" },
    [
      _c(
        "a-radio-group",
        { attrs: { value: _vm.value }, on: { change: _vm.onChange } },
        [
          _vm._l(_vm.filterOptions, function (option) {
            return [
              _c(
                "a-radio",
                { key: option.value, attrs: { value: option.value } },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(option.label) +
                      "\n            "
                  ),
                ]
              ),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }