<!-- @author giligili -->
<!-- @email zhimingwang@fintopia.tech -->
<!-- @date 2022-10-31 11:46:30.052 -->
<!-- @desc generated by yqg-cli@1.2.7 -->

<template>
    <div class="yqg-resize-observe">
        <slot />
    </div>
</template>

<script type="text/babel">

    import YqgResizeObserver from 'collection-admin-web/common/util/browser-api/resize-observer';

    export default {
        name: 'YqgResizeObserve',

        mounted() {
            this.resizeObserver = new YqgResizeObserver();

            this.resizeObserver.addEventListener(this.$el, entry => {
                this.$emit('change', entry);
            });
        },

        beforeDestroy() {
            this.resizeObserver.disconnect();
        }
    };

</script>
