<!-- @author giligili -->
<!-- @email zhimingwang@yangqianguan.com -->
<!-- @date 2021-07-30 15:20:58.685 -->
<!-- @desc generated by yqg-cli@5.1.1 -->

<template>
    <yqg-modal
        title="一键多呼"
    >
        <template #container>
            <a-tabs>
                <a-tab-pane
                    v-if="$app.privileges.CALL.MULTI.DIAL.RECOMMEND"
                    key="recommendCaseList"
                    tab="自动推荐列表"
                >
                    <recommend-call-list @call-connect="$emit('dismiss')" />
                </a-tab-pane>

                <a-tab-pane
                    v-if="$app.privileges.CALL.MULTI.DIAL.self"
                    key="caseList"
                    tab="手动添加列表"
                >
                    <call-list
                        @call-connect="$emit('dismiss')"
                    />
                </a-tab-pane>
            </a-tabs>
        </template>
    </yqg-modal>
</template>

<script type="text/babel">

    import {mapActions} from 'vuex';

    import bus, {OnCloseMultiCallModal} from 'collection-admin-web/common/constant/event-bus';
    import modal from 'collection-admin-web/common/mixin/modal';

    import CallList from '../../components/call-list';
    import RecommendCallList from '../../components/recommend-call-list';

    export default {
        name: 'MultiCallModal',

        components: {
            CallList,
            RecommendCallList
        },

        mixins: [modal],

        mounted() {
            this.fetchMultiCallList();

            bus.$on(OnCloseMultiCallModal, this.dismiss);
        },

        beforeDestroy() {
            bus.$off(OnCloseMultiCallModal, this.dismiss);
        },

        methods: {
            ...mapActions('multiCall', ['fetchMultiCallList'])
        }
    };

</script>
