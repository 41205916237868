var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.innerOptions
    ? _c(
        "div",
        { staticClass: "yqg-info-view" },
        [
          _vm._l(_vm.innerOptions.fieldDefs, function (fieldDef, index) {
            return [
              fieldDef.field
                ? _vm._t(
                    fieldDef.field,
                    function () {
                      return [
                        _c(
                          "div",
                          { key: index, staticClass: "yqg-info-view-field" },
                          [
                            _c("label", [
                              _vm._v(_vm._s(fieldDef.label) + "："),
                            ]),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticClass: "yqg-info-view-field-value" },
                              [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(
                                      _vm._f("nullFilter")(
                                        _vm.getValue(fieldDef)
                                      )
                                    ) +
                                    "\n                "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    },
                    { fieldDef: fieldDef }
                  )
                : fieldDef.type === "br"
                ? _c("br", { key: index })
                : _vm._e(),
            ]
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }