var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "yqg-antv-date-picker" },
    [
      _vm.showPreset && _vm.presets.length
        ? _c(
            "a-radio-group",
            {
              staticClass: "presets mr10",
              attrs: { value: _vm.presetTime },
              on: { change: _vm.presetTimeChange },
            },
            _vm._l(_vm.presets, function (preset, index) {
              return _c(
                "a-radio-button",
                { key: index, attrs: { value: preset.value } },
                [_vm._v("\n            " + _vm._s(preset.text) + "\n        ")]
              )
            }),
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("a-date-picker", {
        attrs: { "show-time": "", value: _vm.momentValue },
        on: { change: _vm.timeChange },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }