var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "alarm-flow-received" },
    [
      _vm._v(
        "\n    你于 " +
          _vm._s(_vm._f("dateTime")(_vm.notification.data.timeRemind)) +
          " 收到了一个 " +
          _vm._s(_vm.notification.data.remark) +
          " 提醒\n\n    "
      ),
      _vm.processFlowType === "CREATE_USER"
        ? _c("yqg-router-link", {
            attrs: {
              to: "account",
              text: "请查看",
              query: { processFlowId: _vm.processFlowId },
            },
          })
        : _c("yqg-quality-review-link", {
            attrs: {
              text: "请查看",
              query: { processFlowType: _vm.processFlowType },
              "process-flow-id": _vm.processFlowId,
            },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }