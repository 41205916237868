var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("a-layout-footer", { staticClass: "footer" }, [
    _vm._v(
      "\n    Copyright © " +
        _vm._s(_vm.year) +
        " " +
        _vm._s(_vm.copyright) +
        ". All rights reserved.\n"
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }