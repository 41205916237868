/**
 * @author giligili
 * @date 2021-08-02
 * @email zhimingwang@yangqianguan.com
 * @desc table-options
 */

import * as Fields from './fields';

export default {
    colDefs: [
        Fields.collectorName,
        Fields.displayMobileNumber,
        Fields.messageName,
        Fields.sendTime,
        Fields.op
    ]
};
