/**
 * @Author: giligili
 * @Date: 2023-03-12
 * @Last Modified by: giligili
 * @Last Modified time: 2023-05-05 11:34:23
 */

import Trial from 'collection-admin-web/common/resource/trial';
import Enum from 'collection-admin-web/common/util/enum';

// 后端把券和减免当作一种东西，放在了一张表里面

// 减免类型枚举
export const ReduceTypeEnum = Enum.query(Trial.listReduceTypes, {
    valueField: 'name',
    textField: 'desc',
    queryOnce: true
});

// 减免类型和券类型的枚举
export const CouponAndReduceTypeEnum = Enum.query(Trial.listCaseTrialTypes, {
    valueField: 'name',
    textField: 'desc',
    queryOnce: true
});

// 减免类型和券状态的枚举
export const CouponAndReduceStatusEnum = Enum.query(Trial.listCaseTrialStatuses, {
    valueField: 'name',
    textField: 'desc',
    queryOnce: true
});
