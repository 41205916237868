var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: ["yqg-call-record-link", { "can-link": _vm.routerLink }],
      on: {
        click: function ($event) {
          return _vm.to(_vm.query)
        },
      },
    },
    [
      _vm.text
        ? _c(_vm.is, { tag: "component" }, [
            _vm._v("\n        " + _vm._s(_vm.text) + "\n    "),
          ])
        : _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }