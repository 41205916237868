var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "yqg-check-wrap" }, [
    _c(
      "div",
      { staticClass: "check" },
      [
        _c(
          "a-checkbox-group",
          { attrs: { value: _vm.selectKeys }, on: { change: _vm.onChange } },
          [_c("a-checkbox", { attrs: { value: _vm.value } })],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "container" }, [_vm._t("default")], 2),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }