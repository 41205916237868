<!-- @Author: xymo -->
<!-- @Date: 2023-09-22 14:35:13 -->
<!-- @Last Modified by: xymo -->
<!-- @Last Modified time: 2023-09-22 14:35:13 -->

<template>
    <div class="header-tool">
        <a-button
            type="link"
            icon="search"
            @click="showDrawer"
        />

        <a-drawer
            title="查询明文手机号"
            placement="right"
            width="570"
            :visible="visible"
            :z-index="900"
            :mask-closable="true"
            @close="onClose"
        >
            <yqg-simple-form
                :options="$options.FormOptions"
                confirm-label="查询"
                @confirm="onSearch"
                @reset="onSearch"
            />

            <yqg-simple-table
                :options="$options.TableOptions"
                :records="list"
            />
        </a-drawer>
    </div>
</template>

<script type="text/babel">

    import table from 'collection-admin-web/common/mixin/table';
    import Common from 'collection-admin-web/common/resource/common';

    import FormOptions from './constant/form-options';
    import TableOptions from './constant/table-options';

    export default {
        name: 'HeaderTool',

        FormOptions,
        TableOptions,

        mixins: [table],

        data() {
            return {
                visible: false,
                list: null
            };
        },

        methods: {
            showDrawer() {
                this.visible = true;
            },

            onClose() {
                this.visible = false;
            },

            onRefresh() {
                const {cond: {
                    maskedPhone,
                    orderId
                }} = this;

                if (!maskedPhone || !orderId) {
                    this.list = null;

                    return;
                }

                Common.getUnmaskPhoneNum({
                    maskedPhone,
                    orderId
                }).then(({data: {body = []}}) => {
                    this.list = body;
                });
            }
        }
    };

</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .header-tool {
        color: red;
    }

</style>
