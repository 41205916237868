<!-- @Author: giligili -->
<!-- @Date: 2021-07-30 13:52:19.408 -->
<!-- @Last Modified by: yefenghan -->
<!-- @Last Modified time: 2024-06-21 15:44:07 -->

<template>
    <div
        v-if="autoDialStatus"
        class="header-predict-call"
    >
        <span
            class="progress-text"
            :class="{'yqg-text-success': autoDialStatus === $options.AutoDialStatus.TYPE.PROCEEDING}"
        >
            {{ $options.AutoDialStatus.MAP[autoDialStatus] }}
        </span>

        <span v-if="batchNumber">呼叫轮次: {{ batchNumber }}</span>
        <span v-if="progress">呼叫进度: {{ progress }}</span>
    </div>
</template>

<script type="text/babel">

    import CommonResource from 'collection-admin-web/common/resource/common';

    import {AutoDialStatus} from './constant/enums';

    const REQUEST_TIME_INTERVAL = 60e3;

    export default {
        name: 'HeaderPredictCall',

        AutoDialStatus,

        data() {
            return {
                progress: '',
                batchNumber: 0,
                autoDialStatus: ''
            };
        },

        mounted() {
            // 延迟50ms 防止抢占页面并发限制
            setTimeout(this.progressPollingInit, 50);
        },

        beforeDestroy() {
            this.progressPollingEnd();
        },

        methods: {
            fetchPredictProgress() {
                CommonResource.getAutoDialStatus({hideLoading: true})
                    .then(({data: {body}}) => {
                        const {autoDialStatus, batchNumber, progress} = body;

                        this.progress = progress;
                        this.batchNumber = batchNumber;
                        this.autoDialStatus = autoDialStatus;
                    })
                    .catch(err => err);
            },

            progressPollingInit() {
                this.fetchPredictProgress();
                this.timer = setInterval(this.fetchPredictProgress, REQUEST_TIME_INTERVAL);
            },

            progressPollingEnd() {
                clearInterval(this.timer);
            }
        }
    };
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .header-predict-call {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        color: black !important;
        font-size: 14px !important;
        white-space: nowrap;
    }
</style>
