/*
 * @Author: huayizhang
 * @Date: 2021-06-30 15:14:10
 * @Last Modified by: huayizhang
 * @Last Modified time: 2021-06-30 15:15:01
 */
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
// TODO: 这个文件里内的方法都没用到... 后面对比一下 angular 看看干嘛了
/* eslint-disable consistent-return */
// 根据value查找树中符合的节点node
export const SearchTree = (tree, value, key = 'value') => {
    if (!tree || !value)
        return {};
    let isGet = false;
    let result = null;
    const loop = array => {
        for (let i = 0; i < array.length; i += 1) {
            const element = array[i];
            if (element.children && element.children.length > 0)
                loop(element.children);
            if (value === element[key] || isGet) {
                if (!isGet)
                    result = element;
                isGet = true;
                break;
            }
        }
    };
    loop(tree);
    return result;
};
// 从树对象中拿到某一个指定的节点
export const getTreeNode = (root, targetKey, targetValue) => {
    const isInValid = value => [null, undefined].includes(value);
    if (isInValid(root) || isInValid(targetValue))
        return;
    if (root[targetKey] === targetValue)
        return root;
    if (root.children && root.children.length) {
        const childrenLength = root.children.length;
        for (let index = 0; index < childrenLength; index += 1) {
            const targetNode = getTreeNode(root.children[index], targetKey, targetValue);
            if (targetNode)
                return targetNode;
        }
    }
};
// 获取node和node子元素中的key的值
export const TraverseChildren = (node, key = 'value') => {
    if (!node || !key)
        return [];
    const { children } = node;
    const results = [node[key]];
    const loop = array => {
        (array || []).forEach(element => {
            results.push(element[key]);
            if (element.children)
                loop(element.children);
        });
    };
    loop(children);
    return results.filter(result => !!result); // 过滤掉undefiend或null
};
export const formatTreeData = ({ tree, key = 'id', extraKey = '', replacementKey = '', filterFun, parent, childrenKey = 'children', nodeBodyField, level = 0 }) => {
    if (!tree.length) {
        return tree;
    }
    let res;
    res = tree.map((_a) => {
        var _b = childrenKey, children = _a[_b], rest = __rest(_a, [typeof _b === "symbol" ? _b : _b + ""]);
        const nodeData = rest[nodeBodyField] || rest;
        let keyValue = nodeData[key];
        if (!keyValue && replacementKey) {
            keyValue = `replacementKey-${nodeData[replacementKey]}`;
        }
        if (extraKey) {
            keyValue = `${keyValue}-${nodeData[extraKey]}`;
        }
        return Object.assign({ level, key: `${keyValue}`, scopedSlots: { title: 'title' }, parent, children: children
                ? formatTreeData({
                    tree: children,
                    parent: Object.assign({}, nodeData),
                    key,
                    extraKey,
                    replacementKey,
                    filterFun,
                    childrenKey,
                    nodeBodyField,
                    level: level + 1
                })
                : null }, nodeData);
    });
    if (filterFun) {
        res = res.filter(filterFun);
    }
    return res;
};
export const arrayToTree = ({ list, key = 'id', valueKey = 'id', titleKey = 'name', childKey = 'children', parentKey = 'parentId', filterFun }) => {
    if (!list)
        return [];
    let formatList = list;
    if (filterFun) {
        formatList = formatList.filter(filterFun);
    }
    formatList = list.map(item => (Object.assign({ value: `${item[valueKey]}`, title: `${item[titleKey]}`, label: `${item[titleKey]}`, key: `${item[key]}` }, item)));
    const tree = formatList.filter(i => Number(i[parentKey]) === -1);
    const pushChildren = treeList => {
        treeList.forEach(item => {
            const childList = formatList.filter(listItem => (listItem[parentKey] === item[key]));
            if (childList.length) {
                item[childKey] = childList;
                pushChildren(childList);
            }
        });
    };
    pushChildren(tree);
    return tree;
};
