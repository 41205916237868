var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "yqg-sdk-icon" }, [
    _vm.sdkType && _vm.SdkIconMap[_vm.sdkType]
      ? _c("img", {
          style: { width: (_vm.width || 20) + "px" },
          attrs: { src: _vm.SdkIconMap[_vm.sdkType] },
        })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }