<!-- @author xinzhong -->
<!-- @email xinzhong@yangqianguan.com -->
<!-- @date 2019-05-30 11:50:25.209 -->
<!-- @desc generated by yqg-cli@1.1.1 -->

<template>
    <b-dialog
        :open="visible"
        :close-on-click-outside="false"
        title="外呼平台"
        dialog-class="sm"
        @close="cancel"
    >
        <div class="login-modal-body">
            <!-- 保险 -->
            <div v-if="accountInfo.account">
                <b-form-group
                    label="账号"
                    label-horizontal
                >
                    <span class="group-pick">
                        {{ accountInfo.account }}
                    </span>
                </b-form-group>
            </div>

            <!-- call-center -->
            <div v-else>
                <b-form-group
                    label="账号"
                    label-horizontal
                    has-star
                >
                    <input
                        v-model="editing.account"
                        type="text"
                    >
                </b-form-group>

                <b-form-group
                    label="密码"
                    label-horizontal
                    has-star
                >
                    <input
                        v-model="editing.password"
                        type="password"
                    >
                </b-form-group>

                <b-form-group
                    label="socket"
                    label-horizontal
                    has-star
                >
                    <input
                        v-model="editing.socket"
                        type="text"
                    >
                </b-form-group>

                <b-form-group
                    label="uriHost"
                    label-horizontal
                    has-star
                >
                    <input
                        v-model="editing.uriHost"
                        type="text"
                    >
                </b-form-group>

                <b-form-group
                    label="Invite Header"
                    label-horizontal
                >
                    <yqg-code-json v-model="editing.inviteHeaderString" />
                </b-form-group>
            </div>
        </div>

        <button
            slot="actions"
            class="simple lg"
            @click="cancel()"
        >
            取消
        </button>
        <button
            slot="actions"
            class="simple lg"
            @click="confirm()"
        >
            登录
        </button>
    </b-dialog>
</template>

<script type="text/babel">

import {STAGE} from 'yqg-common/constant/AppConfig';
import StageType from 'yqg-common/constant/StageType';

import ServerConfig from '../../constant/server-config';
import ValidOptions from './constant/valid-options';

export default {
    name: 'LoginModal',

    props: {
        accountInfo: {
            type: Object,
            required: true
        },

        socketConf: {
            type: Object,
            default: () => ({})
        }
    },

    data() {
        const {socketConf} = this;

        return {
            visible: false,
            editing: {
                ...Object.assign(ServerConfig[STAGE] || ServerConfig[StageType.test], socketConf),
                ...this.accountInfo
            }
        };
    },

    methods: {
        cancel() {
            const vm = this;
            vm.reject();
        },

        confirm() {
            const vm = this;
            if (!vm.$valid.checkOptions(vm.editing, ValidOptions)) return;
            vm.resolve(vm.editing);
        }
    }
};

</script>
