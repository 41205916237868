<!-- @author huayizhang -->
<!-- @email huayizhang@yangqianguan.com -->
<!-- @date 2019-05-31 15:38:21.060 -->
<!-- @desc generated by yqg-cli@1.1.1 -->

<template>
    <div
        class="yqg-chart"
        :style="{minHeight: `${minHeight}px`}"
    />
</template>

<script type="text/babel">

/* eslint-disable consistent-return,no-underscore-dangle */

import debounce from 'lodash/debounce';
import {addListener, removeListener} from 'resize-detector/dist';

// enumerating ECharts events for now
import ChartEvents from './constant/chart-events';

export default {
    name: 'YqgChart',

    props: {
        options: Object,
        theme: [String, Object],
        initOptions: Object,
        group: String,
        autoResize: Boolean,
        watchShallow: Boolean,
        minHeight: {
            type: [Number, String],
            default: 400
        }
    },

    data() {
        return {
            chart: null,
            lastArea: 0
        };
    },

    watch: {
        group(group) {
            this.chart.group = group;
        }
    },

    mounted() {
        this.$watch('options', options => {
            if (!this.chart && options) {
                this.init();
            } else {
                this.chart.setOption(this.options, true);
            }
        }, {deep: !this.watchShallow});

        const watched = ['theme', 'initOptions', 'autoResize', 'watchShallow'];
        watched.forEach(prop => this.$watch(prop, () => this.refresh(), {deep: true}));

        if (this.options) this.init();
    },

    activated() {
        if (this.autoResize && this.chart) this.chart.resize();
    },

    beforeDestroy() {
        if (!this.chart) return;

        this.destroy();
    },

    methods: {
        // provide a explicit merge option method
        mergeOptions(options, notMerge, lazyUpdate) {
            this.delegateMethod('setOption', options, notMerge, lazyUpdate);
        },

        // just delegates ECharts methods to Vue component
        // use explicit params to reduce transpiled size for now
        appendData(params) {
            this.delegateMethod('appendData', params);
        },

        resize(options) {
            this.delegateMethod('resize', options);
        },

        dispatchAction(payload) {
            this.delegateMethod('dispatchAction', payload);
        },

        convertToPixel(finder, value) {
            return this.delegateMethod('convertToPixel', finder, value);
        },

        convertFromPixel(finder, value) {
            return this.delegateMethod('convertFromPixel', finder, value);
        },

        containPixel(finder, value) {
            return this.delegateMethod('containPixel', finder, value);
        },

        showLoading(type, options) {
            this.delegateMethod('showLoading', type, options);
        },

        hideLoading() {
            this.delegateMethod('hideLoading');
        },

        getDataURL(options) {
            return this.delegateMethod('getDataURL', options);
        },

        getConnectedDataURL(options) {
            return this.delegateMethod('getConnectedDataURL', options);
        },

        clear() {
            this.delegateMethod('clear');
        },

        dispose() {
            this.delegateMethod('dispose');
        },

        delegateMethod(name, ...args) {
            if (this.chart) return this.chart[name](...args);
        },

        delegateGet(name, method) {
            if (this.chart) return this.chart[method]();
        },

        getArea() {
            return this.$el.offsetWidth * this.$el.offsetHeight;
        },

        async init() {
            if (this.chart) return;

            import('echarts/lib/echarts').then(echarts => {
                Promise.all([
                    import('echarts/lib/chart/bar'),
                    import('echarts/lib/chart/line'),
                    import('echarts/lib/chart/pie'),
                    import('echarts/lib/component/title'),
                    import('echarts/lib/component/grid'),
                    import('echarts/lib/component/tooltip'),
                    import('echarts/lib/component/legend'),
                    import('echarts/lib/component/legendScroll'),
                    import('echarts/lib/component/dataZoom')
                ]).then(() => {
                    const chart = echarts.init(this.$el, this.theme, this.initOptions);

                    if (this.group) chart.group = this.group;

                    chart.setOption(this.options, true);
                    // expose ECharts events as custom events
                    ChartEvents.forEach(event => chart.on(event, params => this.$emit(event, params, chart)));

                    if (this.autoResize) {
                        this.lastArea = this.getArea();

                        this.__resizeHandler = debounce(() => {
                            if (this.lastArea === 0) {
                                // emulate initial render for initially hidden charts
                                this.mergeOptions({}, true);
                                this.resize();
                                this.mergeOptions(this.options, true);
                            } else {
                                this.resize();
                            }

                            this.lastArea = this.getArea();
                        }, 100, {leading: true});

                        addListener(this.$el, this.__resizeHandler);
                    }

                    this.chart = chart;
                });
            });
        },

        destroy() {
            if (this.autoResize) removeListener(this.$el, this.__resizeHandler);

            this.dispose();
            this.chart = null;
        },

        refresh() {
            this.destroy();
            this.init();
        }
    }
};

</script>
