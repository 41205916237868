var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "alarm-assist-case" }, [
    _vm._v(
      "\n    你于 " +
        _vm._s(_vm._f("dateTime")(_vm.notification.data.timeRemind)) +
        " 收到 " +
        _vm._s(_vm.notification.data.extraData.operatorName) +
        " 发起的协催任务\n\n    "
    ),
    _c(
      "p",
      [
        _c("yqg-router-link", {
          attrs: { hash: _vm.hash, to: "assistReceive" },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "btn-wrap" },
      [
        _c("a-button", {
          staticClass: "yqg-btn-warning",
          domProps: { textContent: _vm._s("拒绝") },
          on: { click: _vm.abortAssit },
        }),
        _vm._v(" "),
        _c("a-button", {
          attrs: { type: "primary" },
          domProps: { textContent: _vm._s("同意") },
          on: { click: _vm.acceptAssit },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }