/*
 * @Author: huayizhang 
 * @Date: 2021-07-13 11:33:37 
 * @Last Modified by: huayizhang
 * @Last Modified time: 2021-07-13 11:36:28
 */

import CaseAssist from 'collection-admin-web/common/resource/case/assist';
import Enum from 'collection-admin-web/common/util/enum';

export default Enum.query(CaseAssist.listAssistAdminUser, {
    valueField: 'id',
    textField: 'name',
    queryOnce: true
});
