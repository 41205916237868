function objectWithoutProperties(obj, exclude) {
  var target = {}
  for (var k in obj)
    if (
      Object.prototype.hasOwnProperty.call(obj, k) &&
      exclude.indexOf(k) === -1
    )
      target[k] = obj[k]
  return target
}
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-select",
    _vm._b(
      {
        attrs: { "auto-clear-search-value": !_vm.isMultipleSelectAll },
        on: {
          change: _vm.onChange,
          search: _vm.onSearch,
          dropdownVisibleChange: _vm.onDropdownVisibleChange,
        },
        scopedSlots: _vm._u(
          [
            _vm.isMultipleSelectAll
              ? {
                  key: "dropdownRender",
                  fn: function (menu) {
                    return [
                      _c("v-nodes", { attrs: { vnodes: menu } }),
                      _vm._v(" "),
                      _c("a-divider", { staticStyle: { margin: "4px 0" } }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "select-all-btn-groups",
                          on: {
                            mousedown: function (e) {
                              return e.preventDefault()
                            },
                          },
                        },
                        [
                          _c(
                            "a-button",
                            {
                              attrs: { size: "small" },
                              on: { click: _vm.clearAll },
                            },
                            [_vm._v("\n                清空\n            ")]
                          ),
                          _vm._v(" "),
                          _c(
                            "a-button",
                            {
                              attrs: { size: "small", type: "primary" },
                              on: { click: _vm.selectAll },
                            },
                            [_vm._v("\n                全选\n            ")]
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                }
              : null,
          ],
          null,
          true
        ),
        model: {
          value: _vm.compValue,
          callback: function ($$v) {
            _vm.compValue = $$v
          },
          expression: "compValue",
        },
      },
      "a-select",
      _vm.def.props,
      false
    ),
    [
      _vm._v(" "),
      _vm._l(_vm.selectOptions, function (ref) {
        var label = ref.label
        var val = ref.value
        var rest$1 = objectWithoutProperties(ref, ["label", "value"])
        var rest = rest$1
        return _c(
          "a-select-option",
          _vm._b(
            {
              key: val,
              attrs: { value: val, title: _vm.tooltipVisible ? label : "" },
            },
            "a-select-option",
            rest,
            false
          ),
          [_vm._v("\n        " + _vm._s(label) + "\n    ")]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }