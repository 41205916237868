/*
 * @Author: huayizhang 
 * @Date: 2021-07-23 14:11:20 
 * @Last Modified by: huayizhang
 * @Last Modified time: 2021-11-05 15:50:31
 */

export default {
    ORDER: 'ORDER',
    HISTORY_ORDER: 'HISTORY_ORDER',
    HISTORY_REPAYMENT: 'HISTORY_REPAYMENT',
    REMIND_USER_INFO: 'REMIND_USER_INFO',
    REMIND_COUPON_INFO: 'REMIND_COUPON_INFO',
    REMIND_INSTALMENT_INFO: 'REMIND_INSTALMENT_INFO'
};
