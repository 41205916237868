<!-- @author huayizhang -->
<!-- @email huayizhang@yangqianguan.com -->
<!-- @date 2021-07-14 11:46:30.052 -->
<!-- @desc generated by yqg-cli@1.2.7 -->

<template>
    <div class="yqg-sdk-icon">
        <img
            v-if="sdkType && SdkIconMap[sdkType]"
            :src="SdkIconMap[sdkType]"
            :style="{width: (width || 20) + 'px'}"
        >
    </div>
</template>

<script type="text/babel">

    import SdkTypes from 'collection-admin-web/common/constant/types/sdk-types';

    import GodDuckIcon from './img/god-duck.png';
    import ZebraIcon from './img/zebra.png';

    export default {
        name: 'YqgSdkIcon',

        props: {
            sdkType: {
                type: String,
                default: ''
            },

            width: {
                type: Number,
                default: null
            }
        },

        data() {
            return {
                SdkIconMap: {
                    [SdkTypes.SDK_TYPE_ZEBRA]: ZebraIcon,
                    [SdkTypes.SDK_TYPE_ZEBRA_OLD]: ZebraIcon,
                    [SdkTypes.SDK_TYPE_GOD_DUCK]: GodDuckIcon
                }
            };
        }
    };

</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .yqg-sdk-icon {
        display: inline-block;
    }

</style>
