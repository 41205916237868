<!--
 * @Author: xiaoduoshu
 * @Email: xiaoduoshu@fintopia.tech
 * @Date: 2022-06-13 11:08:49
-->

<template>
    <div class="alarm-repay-receipt-result">
        订单:
        <yqg-case-link
            :text="notification.data.orderNumber"
            :case-id="notification.data.caseId"
        />
        的对公还款被拒绝，请重新提交。
    </div>
</template>

<script type="text/babel">
    export default {
        name: 'AlarmRepayReceiptResult',

        props: {
            notification: {
                type: Object,
                default: () => ({})
            }
        }
    };
</script>
