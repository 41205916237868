var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "yqg-antv-upload-file-url" },
    [
      _vm.defaultFileList
        ? _c(
            "a-upload",
            _vm._b(
              {
                attrs: {
                  "custom-request": _vm.customRequest,
                  multiple: _vm.isMultiple,
                  disabled: _vm.disabled,
                  "default-file-list": _vm.defaultFileList,
                  remove: _vm.onRemove,
                },
                on: { change: _vm.onFileChange },
              },
              "a-upload",
              _vm.props,
              false
            ),
            [
              _vm.canUpload
                ? _c(
                    "div",
                    [
                      _vm._t("default", function () {
                        return [
                          _vm.props.listType === "picture-card"
                            ? [
                                _c("a-icon", { attrs: { type: "plus" } }),
                                _vm._v(" "),
                                _c("p", [_vm._v("上传图片")]),
                                _vm._v(" "),
                                _vm.byteWithUnit
                                  ? _c(
                                      "div",
                                      { staticClass: "yqg-text-danger" },
                                      [
                                        _vm._v(
                                          "\n                        最大" +
                                            _vm._s(_vm.byteWithUnit) +
                                            "\n                    "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            : [
                                _c("a-button", { attrs: { icon: "plus" } }, [
                                  _vm._v(
                                    "\n                        上传图片\n                    "
                                  ),
                                ]),
                                _vm._v(" "),
                                _vm.byteWithUnit
                                  ? _c(
                                      "div",
                                      { staticClass: "yqg-text-danger" },
                                      [
                                        _vm._v(
                                          "\n                        最大" +
                                            _vm._s(_vm.byteWithUnit) +
                                            "\n                    "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                        ]
                      }),
                    ],
                    2
                  )
                : _vm._e(),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }