/**
 * @Author: xymo
 * @Date: 2021-04-13
 * @Last Modified by: yefenghan
 * @Last Modified time: 2024-06-17 23:34:03
 */

import Cookie from 'js-cookie';

import BusinessName from 'collection-admin-web/common/constant/business-name';
import EnumAll from 'collection-admin-web/common/constant/enum';
import {permissionArray, PermissionMap} from 'collection-admin-web/common/constant/permission-type';
import User from 'collection-admin-web/common/resource/user';
import Enum from 'collection-admin-web/common/util/enum';
import Storage from 'collection-admin-web/common/util/local-storage';
import {genStructMap} from 'collection-admin-web/common/util/tool';

const SAVE = 'SAVE';
const CLEAR = 'CLEAR';

const BooleanType = EnumAll.Basic.Boolean.TYPE;

export default {
    state: {
        user: {},
        fetched: false,
        privileges: {},
        permissions: {}
    },

    getters: {
        fetched: state => state.fetched,
        isLogin: state => !!state.user.collectorId,
        businessName: state => state.user.businessName,
        user: state => state.user,
        isYqgBusiness: state => state.user.businessName === BusinessName.YQG,
        isSuperAdmin: state => state.user.businessName === BusinessName.SUPER
            && state.user.roles?.includes('SUPER_ADMIN'),
        privileges: state => state.privileges,
        permissions: state => state.permissions,
        maskMobileNumber: state => state.user.maskMobileNumber === BooleanType.TRUE
    },

    mutations: {
        [SAVE](state, user) {
            const privilegeTypesMap = user.roleGroupVOList.flatMap(({privilegeTypes}) => privilegeTypes)
                .reduce((accu, type) => ({...accu, [type]: type}), {});

            state.user = user;
            state.fetched = true;
            state.privileges = genStructMap(permissionArray, privilegeType => !!privilegeTypesMap[privilegeType]);
            state.permissions = Object.keys(PermissionMap)
                .reduce((accu, type) => ({...accu, [type]: !!privilegeTypesMap[PermissionMap[type]]}), {});
        },

        [CLEAR](state) {
            state.user = {};
            state.fetched = true;
        }
    },

    actions: {
        fetch({commit}) {
            return User
                .fetchUserInfo()
                .then(({data: {body: user}}) => {
                    commit(SAVE, user);
                    Storage.setUserInfo(user);

                    return user;
                });
        },

        syncUserInfo({commit}, user) {
            commit(SAVE, user);

            return Promise.resolve();
        },

        login({dispatch}, data) {
            return User
                .login(data)
                .then(() => dispatch('fetch'));
        },

        logout({commit}) {
            Cookie.remove('userToken');
            commit(CLEAR);
            Storage.removeUserInfo();
            Enum.clearCacheYqgEnums();

            return Promise.resolve();
        }
    }
};
