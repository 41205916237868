<!-- @author huayizhang -->
<!-- @email huayizhang@yangqianguan.com -->
<!-- @date 2021-10-22 13:58:26.096 -->
<!-- @desc generated by yqg-cli@1.2.7 -->

<template>
    <yqg-simple-form
        title="申请减免"
        :options="FormOptions"
        :values="editing"
        @confirm="onConfirm"
        @cancel="dismiss"
    >
        <template #remainAmount="{values: {reduceAmount}}">
            {{ remainAmount && remainAmount - reduceAmount | numberCommasFixed2 }}
        </template>

        <yqg-upload-images
            slot="imageUrls"
            slot-scope="{form}"
            v-decorator="['imageUrls', {initialValue: []}]"
            :size="5 * 1024 * 1024"
            field="imageUrls"
            :form="form"
        />
    </yqg-simple-form>
</template>

<script type="text/babel">

    import _ from 'underscore';

    import EnumAll from 'collection-admin-web/common/constant/enum';
    import modal from 'collection-admin-web/common/mixin/modal';
    import Trial from 'collection-admin-web/common/resource/trial';

    import FormOptions from './constant/form-options';

    export default {
        name: 'ApplyRepayReduceModal',

        mixins: [modal],

        props: {
            caseId: {
                type: [Number, String],
                required: true
            },

            orderNumber: {
                type: [Number, String],
                required: true
            }
        },

        data() {
            return {
                FormOptions,
                CaseReduceTypeMap: {},
                editing: {
                    caseId: +this.caseId,
                    orderNumber: this.orderNumber
                },
                remainAmount: 0
            };
        },

        async mounted() {
            await this.fetchReduceTypeEnum();

            this.getReduceType();
        },

        methods: {
            async fetchReduceTypeEnum() {
                await EnumAll.Trial.ReduceTypeEnum.query();
            },

            getReduceType() {
                const vm = this;
                const {caseId, orderNumber} = vm;

                Trial.getReduceType({params: {caseId, orderNumber}}).then(({data: {body}}) => {
                    vm.CaseReduceTypeMap = _.pick(EnumAll.Trial.ReduceTypeEnum.MAP, ...body);
                });
            },

            trialCaseByReduceType(form, reduceType) {
                const vm = this;
                const {caseId, orderNumber} = vm;

                if (!reduceType) return;

                Trial.trialCaseByReduceType({params: {caseId, orderNumber, reduceType}})
                    .then(({data: {body: {orderMaxReduceAmount, orderRemainAmount}}}) => {
                        vm.remainAmount = orderRemainAmount || 0;
                        form.setFieldsValue({
                            reduceAmount: orderMaxReduceAmount || 0
                        });
                    });
            },

            onConfirm({record}) {
                const vm = this;

                Trial.applyRepayReduce(record).then(({data: {body}}) => {
                    if (body) {
                        vm.$app.opSuccess('申请减免');
                        vm.close();
                    }
                }).catch(x => x);
            }
        }
    };

</script>
