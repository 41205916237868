<!-- @Author: giligili -->
<!-- @Date: 2021-08-17 15:48:33.628 -->
<!-- @Last Modified by: yefenghan -->
<!-- @Last Modified time: 2024-08-18 17:48:27 -->

<template>
    <span
        v-if="show"
        class="dial-btn"
        @click="dial"
    >
        <slot>
            <a-button
                class="dial-btn yqg-btn-info"
                size="small"
                :disabled="disabledDial"
            >
                <a-icon type="phone" />外呼
            </a-button>
        </slot>
    </span>
</template>

<script type="text/babel">

    import _ from 'underscore';
    import {mapGetters} from 'vuex';

    import EnumAll from 'collection-admin-web/common/constant/enum';
    import RelativeType from 'collection-admin-web/common/constant/types/relative-type';
    import phoneNumberMask3 from 'collection-admin-web/common/filter/phone-number-mask-new';
    import Ytalk from 'collection-admin-web/common/resource/call/ytalk';
    import CaseDetail from 'collection-admin-web/common/resource/case/detail';
    import {aesDecryptCallSecret} from 'collection-admin-web/common/util/encryption';
    import Enum from 'collection-admin-web/common/util/enum';

    import RequestType from './constant/request-type';

    const BooleanType = EnumAll.Basic.Boolean.TYPE;
    const ChannelType = EnumAll.Call.Channel.TYPE;

    export default {
        name: 'YqgLayoutDialBtn',

        props: {
            caseId: {
                type: [Number, String],
                default: undefined
            },

            callTag: {
                type: String,
                default: undefined
            },

            disabled: {
                type: Boolean,
                default: false
            },

            contactInfo: {
                type: Object,
                default: () => ({})
            },

            relativeType: {
                type: String,
                default: RelativeType.CASE
            },

            encryptedMobileNumber: {
                type: String,
                default: undefined
            },

            maskedMobileNumber: {
                type: String,
                default: undefined
            },

            visualMobileNumberList: {
                type: Array,
                default: () => []
            },

            // 剩余拨打次数
            surplusCallCnt: {
                type: Number,
                default: Infinity
            },

            // 来源
            source: {
                type: String,
                required: true
            }
        },

        computed: {
            ...mapGetters(['user']),
            ...mapGetters('caseDetail', ['stashCollectionFlow']),

            disabledDial() {
                const {disabled, $app: {ytalk}, stashCollectionFlow} = this;

                return disabled || !(ytalk && ytalk.isLogin && ytalk.canCall && !stashCollectionFlow);
            },

            show() {
                const {thirdPartyType, callAccount} = this.user;

                return thirdPartyType === ChannelType.YQG && callAccount;
            },

            dialInfoList() {
                const {visualMobileNumberList} = this;

                const allDialInfoList = [
                    ...visualMobileNumberList
                        .map(mobileNumberInfo => this.getVisualDialInfo(mobileNumberInfo)),
                    this.getRealDialInfo()
                ];

                return allDialInfoList.filter(({surplusCallCnt}) => (
                    surplusCallCnt !== '0'
                    && surplusCallCnt !== 0
                ));
            }
        },

        methods: {
            formatContactInfo() {
                const {caseId, relativeType} = this;
                const {
                    collectionType,
                    contactRank,
                    contactVerifyStatus,
                    displayMobileNumber,
                    maskedMobileNumber,
                    encryptedMobileNumber,
                    name,
                    encryptedUserName,
                    phoneBookId,
                    relationship,
                    type: contactType,
                    userName,
                    feedbackId,
                    remindFlow
                } = this.contactInfo;

                const contactInfo = {
                    caseId,
                    caseType: relativeType,
                    collectionType: contactType || collectionType,
                    contactRank,
                    contactType: contactType || collectionType,
                    contactVerifyStatus,
                    displayMobileNumber: displayMobileNumber || maskedMobileNumber,
                    encryptedCollecteeMobile: encryptedMobileNumber || this.encryptedMobileNumber,
                    encryptMobileNumber: encryptedMobileNumber || this.encryptedMobileNumber,
                    phoneBookId,
                    relationship,
                    userName: name || userName,
                    encryptedUserName,
                    feedbackId,
                    remindFlow
                };

                return contactInfo;
            },

            getRealEncryptedMobileNumber() {
                const {contactInfo: {encryptedMobileNumber}} = this;

                return encryptedMobileNumber || this.encryptedMobileNumber;
            },

            getRealDialInfo() {
                const encryptedMobileNumber = this.getRealEncryptedMobileNumber();

                return {
                    type: 'real',
                    callTag: this.callTag,
                    contactInfo: this.formatContactInfo(),
                    maskedMobileNumber: this.maskedMobileNumber
                        || phoneNumberMask3(aesDecryptCallSecret(encryptedMobileNumber)),
                    encryptedMobileNumber,
                    surplusCallCnt: this.surplusCallCnt
                };
            },

            getVisualDialInfo(mobileNumberInfo) {
                return {
                    type: 'visual',
                    contactInfo: {
                        ...this.formatContactInfo(),
                        contactType: mobileNumberInfo.contactType,
                        collectionType: mobileNumberInfo.contactType,
                        maskedMobileNumber: mobileNumberInfo.virtualNumber,
                        encryptedMobileNumber: mobileNumberInfo.phoneId,
                        encryptedCollecteeMobile: mobileNumberInfo.phoneId
                    },
                    maskedMobileNumber: mobileNumberInfo.virtualNumber,
                    ...mobileNumberInfo,
                    surplusCallCnt: Infinity
                };
            },

            /**
             * @desc 获得外呼信息
             * X-Gateway-Tag 线路标签 callTag
             * X-Area-Type 外显号码类型 户籍 or 手机号 areaType
             * X-Area-Code 区域code areaCode
             */
            getCallInfo({type, callTag, contactInfo = {}}) {
                // 虚拟号还是用之前的callTag
                if (type === 'visual') return {callTag};

                const caseId = this.caseId || contactInfo.caseId;
                const {contactType} = contactInfo;

                return Ytalk.getCallInfo({params: {caseId, contactType}})
                    .then(({data: {body}}) => body || {})
                    .catch(() => ({}));
            },

            dial: _.throttle(async function dial() {
                const {disabledDial, caseId} = this;

                if (disabledDial) return;

                const dialInfo = await this.selectDialInfo();

                const canDial = caseId && this.user.maskMobileNumber && await this.dialCheck(dialInfo);

                if (!canDial) return;

                const {callTag, areaType, areaCode} = await this.getCallInfo(dialInfo);

                const {
                    type,
                    contactInfo,
                    calledNumber,
                    callingNumber,
                    maskedMobileNumber,
                    encryptedMobileNumber
                } = dialInfo;

                if (type === 'visual') {
                    this.$app.ytalk.dialVisual({
                        callTag,
                        contactInfo,
                        calledNumber,
                        callingNumber,
                        maskedMobileNumber
                    }, this.source);

                    return;
                }

                this.$app.ytalk.dial({callTag, areaType, areaCode, contactInfo, encryptedMobileNumber}, this.source);
            }, 3e3),

            async selectDialInfo() {
                const {dialInfoList} = this;

                if (dialInfoList.length > 1) {
                    const defaultMaskedMobileNumber = dialInfoList[0].maskedMobileNumber;

                    const selectDialModal = this.$modal.SimpleModal({
                        title: '选择呼叫的手机号',
                        label: '手机号',
                        field: 'maskedMobileNumber',
                        type: 'select',
                        defaultCond: {maskedMobileNumber: defaultMaskedMobileNumber},
                        fieldOptions: {
                            enumType: Enum.from(dialInfoList.reduce(
                                (accu, {maskedMobileNumber}) => ({...accu, [maskedMobileNumber]: maskedMobileNumber}),
                                {}
                            ))
                        }
                    });

                    return selectDialModal
                        .then(({maskedMobileNumber}) => maskedMobileNumber)
                        .then(mask => dialInfoList.find(dialInfo => dialInfo.maskedMobileNumber === mask))
                        .then(dialInfo => {
                            if (dialInfo.type === 'real') return dialInfo;

                            return this.fetchRepairedCallNumberInfo(dialInfo);
                        });
                }

                const [dialInfo] = dialInfoList;

                if (dialInfo.type === 'visual') return this.fetchRepairedCallNumberInfo(dialInfo);

                return dialInfo;
            },

            fetchRepairedCallNumberInfo(dialInfo) {
                const {repairResultId} = dialInfo;

                return CaseDetail.getRepairedCallNumberInfo({params: {repairResultId}})
                    .then(({data: {body}}) => ({
                        ...dialInfo,
                        ...body,
                        callTag: body.tag
                    }));
            },

            async dialCheck(dialInfo) {
                if (dialInfo.type === 'visual') return true;

                const {relativeType} = this;
                const caseId = this.caseId || this.contactInfo?.caseId;
                const encryptedCollecteeMobile = this.contactInfo?.encryptedMobileNumber || this.encryptedMobileNumber;

                try {
                    if (relativeType === RelativeType.REMIND_CASE) return true;

                    const {data: {body}} = await Ytalk.checkBeforeAddCallDetail({params: {
                        caseId,
                        requestType: RequestType.CHECK,
                        encryptedCollecteeMobile
                    }});

                    if (!body) return false;

                    if (body.isOverDial === BooleanType.TRUE) {
                        const modalIns = this.$modal.SimpleModal({
                            title: +body.overDialNum ? '超频提醒' : '即将超频提醒',
                            customRender() {
                                return (
                                    <div>
                                        {
                                            +body.overDialNum
                                                ? <p>此号码今日已经超频：{ body.overDialNum }次。</p>
                                                : null
                                        }
                                        <p class="yqg-text-hint">{ body.message }</p>
                                    </div>
                                );
                            },
                            customFooter(props, modal) {
                                return (
                                    <span>
                                        <a-button onClick={() => modal.$emit('dismiss')}>关闭</a-button>

                                        <a-button type="primary" onClick={() => modal.$emit('close')}>继续拨打</a-button>
                                    </span>
                                );
                            }
                        });

                        return modalIns
                            .then(() => Ytalk.checkBeforeAddCallDetail({params: {
                                caseId,
                                requestType: RequestType.DIAL,
                                encryptedCollecteeMobile
                            }}))
                            .then(({data: {body: ret}}) => !!ret)
                            .catch(() => false);
                    }

                    return true;
                } catch (err) {
                    return false;
                }
            }
        }
    };
</script>
