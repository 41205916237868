<!-- @author giligili -->
<!-- @email zhimingwang@yangqianguan.com -->
<!-- @date 2021-09-06 15:28:15.149 -->
<!-- @desc generated by yqg-cli@5.1.2 -->

<template>
    <div
        v-if="showKeyboard"
        class="ytalk-keyboard"
    >
        <span
            v-for="number in keyNumbers"
            :key="number"
            class="keyboard-number"
            @click="$emit('change', number)"
        >
            <span class="number">
                {{ number }}
            </span>
        </span>
    </div>
</template>

<script type="text/babel">

    export default {
        name: 'Keyboard',

        data() {
            return {
                showKeyboard: false,
                keyNumbers: [1, 2, 3, 4, 5, 6, 7, 8, 9, '*', 0, '#']
            };
        }
    };
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .ytalk-keyboard {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .keyboard-number {
            position: relative;
            display: inline-block;
            width: 30%;
            padding-top: 30%;
            margin-bottom: 5%;
            box-shadow: 2px 2px 5px #333;
            color: #337ab7;
            font-size: 20px;
            text-align: center;
            cursor: pointer;

            &:focus {
                background-color: #ccc;
            }

            &:active {
                box-shadow: none;
            }

            &:hover {
                background-color: #eee;
            }

            .number {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
</style>
