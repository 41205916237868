<!-- @author xymo -->
<!-- @email xymo@yangqianguan.com -->
<!-- @date 2021-04-14 13:52:19.408 -->
<!-- @desc generated by yqg-cli@5.0.2 -->

<template>
    <a-dropdown
        placement="bottomRight"
        :trigger="['click']"
        :get-popup-container="triggerNode => triggerNode"
    >
        <a-icon type="user" />
        <a-menu
            slot="overlay"
            class="dropdown-menu"
        >
            <a-menu-item @click="onItemClick">
                <span class="ant-popover-content">
                    <span class="ant-popover-arrow popover-arrow" />
                </span>
                <span class="text-style">
                    Hi, {{ user.name }}
                </span>
            </a-menu-item>
            <a-menu-divider />
            <a-menu-item @click="onItemClick">
                <router-link :to="{name: 'modifyPassword'}">
                    修改密码
                </router-link>
            </a-menu-item>
            <a-menu-item
                class="text-style"
                @click="onLogout"
            >
                登出
            </a-menu-item>
        </a-menu>
    </a-dropdown>
</template>

<script type="text/babel">

import {mapGetters, mapActions} from 'vuex';

export default {
    name: 'HeaderUser',

    computed: {
        ...mapGetters(['user'])
    },

    methods: {
        ...mapActions(['logout']),

        onItemClick({domEvent}) {
            domEvent.stopPropagation();
        },

        onLogout() {
            const {logout, $router} = this;
            logout().then(() => $router.push({name: 'login'}));
        }
    }
};

</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.dropdown-menu {
    margin-top: 22px;
    width: 160px;
    text-align: center;
}

.popover-arrow {
    top: -4px;
    right: 9px;
    color: #fff;
}

.text-style {
    color: #67c65a;
}
</style>
