/**
 * @author lany44
 * @date 2018/6/11-下午6:32
 * @file enum-options-provider
 */
import _ from 'underscore';

const enumFieldDefUpdater = fieldDef => {
    const {type, props = {}, enumType} = fieldDef;

    switch (type) {
        case 'select-array': {
            return {
                ...fieldDef,
                props: {
                    ...props,
                    list: enumType.LIST
                }
            };
        }
        case 'select-cascader': {
            return {
                ...fieldDef,
                props: {
                    ...props,
                    list: enumType.FOREST
                }
            };
        }
        case 'filter-select': {
            const {
                options: {textField, valueField},
                LIST,
                FILTERMAP
            } = enumType;
            const map = FILTERMAP ?? {};
            if (_.isEmpty(map)) {
                LIST.forEach(({[textField]: text, [valueField]: value, pinyin}) => {
                    map[value] = {
                        text,
                        filter: pinyin
                    };
                });
            }

            return {
                ...fieldDef,
                type: 'select',
                props: {
                    ...props,
                    map
                }
            };
        }
        default: {
            return {
                ...fieldDef,
                type: 'select',
                props: {
                    ...props,
                    map: enumType.MAP
                }
            };
        }
    }
};

export default (options, defHandler = enumFieldDefUpdater, defsName = 'fieldDefs') => {
    const Defs = options[defsName] || [];

    return Promise.all(
        Defs.map(async def => {
            await def?.enumType?.query?.();
        })
    ).then(() => ({
        ...options,
        [defsName]: Defs.map(def => (def.enumType ? defHandler(def) : def))
    }));
};

export const handleEnumRefresh = (options, handler, key = 'bind', defsName = 'fieldDefs') => {
    const Defs = options[defsName] || [];
    Defs.forEach(def => {
        const {enumType} = def;
        if (enumType?.query) {
            if (key === 'bind') {
                enumType.onRefresh?.(handler);
            } else {
                enumType.offRefresh?.(handler);
            }
        }
    });
};

export const hanldeEnumConvert = (
    colDef = {},
    handler = enumFieldDefUpdater
) => colDef?.enumType?.query?.()?.then(() => ({
    ...(colDef.enumType ? handler(colDef) : colDef)
}));

