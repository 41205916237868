/*
 * Author: giligili
 * Created Date: 2021-06-09
 * email: zhimingwang@yangqianguan.com
 */

import {create} from '@yqg/resource';

import commonApi from 'yqg-common/constant/CommonApiRouter';

const {apiWeb} = commonApi;

// 区别在于后端的token是基于私有bucket获得的
const api = {
    /* @desc 获得七牛token(前端的token) */
    getToken: {
        url: apiWeb.getToken,
        method: 'get'
    },

    /* @desc 获得七牛token(催收后端的token) */
    getQiniuPrivateFileUploadToken: {
        url: '/admin/operation/col/getQiniuPrivateFileUploadToken',
        method: 'get'
    },

    /* @desc 根据七牛的fileKey查询资源地址 */
    getQiniuPrivateFileUrl: {
        url: '/admin/operation/col/getQiniuPrivateFileUrl',
        method: 'get'
    }
};

export default create(api);
