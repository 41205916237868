<!-- @author giligili -->
<!-- @email zhimingwang@yangqianguan.com -->
<!-- @date 2021-08-11 13:51:23.164 -->
<!-- @desc generated by yqg-cli@5.1.1 -->

<template>
    <span class="ytalk-timer">
        {{ time | millisToMinSecond }}
    </span>
</template>

<script type="text/babel">

    export default {
        name: 'YtalkTimer',

        props: {
            startTime: {
                type: Number,
                default: Date.now()
            }
        },

        data() {
            return {
                time: 0
            };
        },

        mounted() {
            const {startTime} = this;

            this.timer = setInterval(() => {
                this.time = Date.now() - startTime;
            }, 1e3);
        },

        beforeDestroy() {
            clearInterval(this.timer);
        }
    };

</script>
