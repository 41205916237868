/*
 * @Author: huayizhang
 * @Date: 2021-06-02 14:26:08
 * @Last Modified by: huayizhang
 * @Last Modified time: 2021-08-02 11:35:34
 */
import { assign, customGet, customPost } from '@yqg/resource';
const api = {
    urlPrefix: '/admin/operation/col/admin/',
    list: customGet('getUserFeedback'),
    listOwn: customGet('getOwnUserFeedback'),
    deal: customPost('dealWithUserFeedback'),
    add: customPost('addUserFeedback')
};
export default assign(api);
