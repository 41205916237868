<!-- @author giligili -->
<!-- @email zhimingwang@yangqianguan.com -->
<!-- @date 2021-07-21 12:51:06.029 -->
<!-- @desc generated by yqg-cli@5.1.1 -->

<template>
    <div class="alarm-flow-received">
        你于 {{ notification.data.timeRemind | dateTime }} 收到了一个 {{ notification.data.remark }} 提醒

        <yqg-router-link
            v-if="processFlowType === 'CREATE_USER'"
            to="account"
            text="请查看"
            :query="{processFlowId}"
        />

        <yqg-quality-review-link
            v-else
            text="请查看"
            :query="{processFlowType}"
            :process-flow-id="processFlowId"
        />
    </div>
</template>

<script type="text/babel">
    export default {
        name: 'AlarmFlowReceived',

        props: {
            notification: {
                type: Object,
                default: () => ({})
            }
        },

        computed: {
            processFlowType() {
                return this.notification?.data?.extraData?.processFlowType;
            },

            processFlowId() {
                return this.notification?.data?.extraData?.processFlowId;
            }
        }
    };
</script>
