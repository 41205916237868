/*
 * @Author: giligili
 * @Date: 2022-10-31 11:40:15
 * @Last Modified by: giligili
 * @Last Modified time: 2023-07-12 14:43:40
 */
export default class YqgResizeObserver {
    constructor() {
        this.resizeListener = (entries) => {
            entries.forEach(entry => { var _a; return (_a = this.map.get(entry.target)) === null || _a === void 0 ? void 0 : _a.forEach(fn => fn(entry)); });
        };
        this.map = new Map();
        this.resizeObserver = new ResizeObserver(this.resizeListener);
    }
    addEventListener(dom, listener) {
        const listeners = this.map.get(dom) || [];
        this.map.set(dom, [...listeners, listener]);
        if (!listeners.length)
            this.resizeObserver.observe(dom);
    }
    removeEventListener(dom, listener) {
        const listeners = this.map.get(dom) || [];
        const nextListeners = listeners.filter(cb => cb !== listener);
        this.map.set(dom, nextListeners);
        if (!nextListeners.length)
            this.clearEventListener(dom);
    }
    clearEventListener(dom) {
        this.map.delete(dom);
        this.resizeObserver.unobserve(dom);
    }
    disconnect() {
        this.map.clear();
        this.map = null;
        this.resizeObserver.disconnect();
    }
}
