/**
 * @author center
 * @date 2020/1/8-下午4:59
 * @file phone-mask
 */

export default () => (phone, maskNum = 4, suffixNum) => {
    if (!phone) {
        return null;
    }

    const regStr = suffixNum ? `(^\\+?\\d{3})(\\d*)(\\d{${suffixNum}})` : `(^\\+?\\d{3})(\\d{1,${maskNum}})(\\d*)`;

    return phone.replace(new RegExp(regStr), (num, $1, $2, $3) => ($1 + $2.replace(/./g, '*') + $3));
};
