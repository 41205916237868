var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-layout",
    { staticClass: "yqg-layout" },
    [
      !_vm.$app.isYchatQuoted
        ? _c(
            "a-layout-header",
            {
              class: ["yqg-header", _vm.headerClassName],
              attrs: { id: "yqg-collection-header" },
            },
            [
              _c(
                "div",
                { staticClass: "yqg-header-left" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "yqg-header-logo",
                      attrs: { to: { name: "index" } },
                    },
                    [_vm._v("\n                天网资产保全\n            ")]
                  ),
                  _vm._v(" "),
                  !_vm.$route.meta.hideSider
                    ? _c("a-icon", {
                        staticClass: "yqg-header-trigger",
                        attrs: {
                          type: _vm.collapsed ? "menu-unfold" : "menu-fold",
                        },
                        on: {
                          click: function () {
                            return (_vm.collapsed = !_vm.collapsed)
                          },
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.siderVisible
                    ? _c("header-search", {
                        staticClass: "header-search",
                        attrs: { routes: _vm.siderRoutes },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _vm.isLogin
                ? _c(
                    "div",
                    { staticClass: "yqg-header-icons" },
                    [
                      _vm.showYtalk &&
                      (_vm.$app.permissions.CALL_MULTI_DIAL ||
                        _vm.$app.privileges.CALL.MULTI.DIAL.RECOMMEND)
                        ? _c("header-multi-call")
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.canUseCanary ? _c("yqg-canary-input") : _vm._e(),
                      _vm._v(" "),
                      !_vm.isProd ? _c("yqg-swim-lane-input") : _vm._e(),
                      _vm._v(" "),
                      _vm.$app.privileges.EXTRA.PINGAN.AUTO.DIAL.STATUS
                        ? _c("header-predict-call")
                        : _vm._e(),
                      _vm._v(" "),
                      _c("header-sms"),
                      _vm._v(" "),
                      _vm.$app.privileges.EXTRA.SHOW.FILE.LIST
                        ? _c("header-file-list")
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.$app.permissions.CALL_QUERY_UNMASK_PHONE
                        ? _c("header-tool")
                        : _vm._e(),
                      _vm._v(" "),
                      _c("header-feedback"),
                      _vm._v(" "),
                      _c("header-rank"),
                      _vm._v(" "),
                      _c("header-notification"),
                      _vm._v(" "),
                      _vm.$app.permissions.KNOWLEDGE_TEXT_QUERY
                        ? _c("header-knowledge")
                        : _vm._e(),
                      _vm._v(" "),
                      _c("header-user"),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "a-layout-sider",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.$route.meta.hideSider && !_vm.$app.isYchatQuoted,
              expression: "!$route.meta.hideSider && !$app.isYchatQuoted",
            },
          ],
          staticClass: "yqg-sider",
          attrs: {
            collapsible: "",
            collapsed: _vm.collapsed,
            "collapsed-width": "70",
            trigger: null,
            width: "230",
          },
        },
        [
          _vm.siderVisible
            ? _c(
                "a-menu",
                {
                  staticClass: "yqg-sider-menu",
                  attrs: {
                    "default-open-keys": _vm.siderRoutes.map(function (ref) {
                      var name = ref.name

                      return name
                    }),
                    "selected-keys": [_vm.siderSelectKeys || ""],
                    theme: "dark",
                    mode: "inline",
                  },
                },
                _vm._l(_vm.siderRoutes, function (ref) {
                  var subName = ref.name
                  var meta = ref.meta
                  var subRoutes = ref.subRoutes
                  return _c(
                    "a-sub-menu",
                    { key: subName, staticClass: "sub-menu" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "sider-title",
                          attrs: { slot: "title" },
                          slot: "title",
                        },
                        [
                          meta.icon
                            ? _c("a-icon", { attrs: { type: meta.icon } })
                            : _vm._e(),
                          _vm._v(" "),
                          _c("span", [_vm._v(_vm._s(meta.navTitle))]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm._l(subRoutes, function (ref) {
                        var name = ref.name
                        var path = ref.path
                        var ref_meta = ref.meta
                        var navTitle = ref_meta.navTitle
                        var external = ref_meta.external
                        return _c(
                          "a-menu-item",
                          { key: name, staticClass: "sider-menu" },
                          [
                            external
                              ? [
                                  _c(
                                    "a",
                                    { attrs: { href: path, target: "_blank" } },
                                    [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(navTitle) +
                                          "\n                        "
                                      ),
                                    ]
                                  ),
                                ]
                              : [
                                  _c(
                                    "router-link",
                                    { attrs: { to: { name: name } } },
                                    [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(navTitle) +
                                          "\n                        "
                                      ),
                                    ]
                                  ),
                                ],
                          ],
                          2
                        )
                      }),
                    ],
                    2
                  )
                }),
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "a-layout",
        { staticClass: "yqg-layout yqg-container" },
        [
          _c(
            "div",
            { attrs: { id: "yqg-collection-sticky" } },
            [
              _vm.isLogin && !_vm.$app.isYchatQuoted
                ? _c("placard", {
                    style: _vm.$app.isYchatQuoted ? { top: 0 } : {},
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("div", { attrs: { id: "yqg-collection-placehold" } }),
            ],
            1
          ),
          _vm._v(" "),
          _c("yqg-content", {
            staticClass: "yqg-content",
            style: _vm.$app.isYchatQuoted ? { paddingTop: 0 } : {},
          }),
          _vm._v(" "),
          !_vm.$app.isYchatQuoted ? _c("yqg-footer") : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.showYtalk ? _c("yqg-layout-ytalk") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }