<!-- @author giligili -->
<!-- @email zhimingwang@yangqianguan.com -->
<!-- @date 2021-07-30 19:17:34.250 -->
<!-- @desc generated by yqg-cli@5.1.1 -->

<template>
    <div class="multi-call-btn">
        <div
            v-if="showCallStatus && dataSource.length"
            class="call-status mr10"
        >
            最近结果：
            <a-tooltip
                class="yqg-text-success"
                title="接起"
            >
                {{ callCount.connected }}
            </a-tooltip>
            /
            <a-tooltip
                class="yqg-text-warning"
                title="接通未接起"
            >
                {{ callCount.ringHangup }}
            </a-tooltip>
            /
            <a-tooltip
                class="yqg-text-danger"
                title="未接通"
            >
                {{ callCount.disconnected }}
            </a-tooltip>
        </div>

        <a-button
            v-if="$app.ytalk.canCall"
            class="yqg-btn-info"
            :disabled="!$app.ytalk.isLogin"
            @click="multiDial"
            v-text="'一键呼叫'"
        />

        <a-button
            v-if="$app.ytalk.canHangUp"
            type="danger"
            @click="$app.ytalk.handleUserAction('terminateSessions')"
            v-text="'全部挂断'"
        />
    </div>
</template>

<script type="text/babel">

    import _ from 'underscore';

    import EnumAll from 'collection-admin-web/common/constant/enum';
    import Ytalk from 'collection-admin-web/common/resource/call/ytalk';

    const DialType = EnumAll.Call.DialType.TYPE;

    export default {
        name: 'YqgMultiCallBtn',

        props: {
            value: {
                type: Array,
                default: () => []
            },

            dialType: {
                type: String,
                default: DialType.MULTI
            },

            dataSource: {
                type: Array,
                default: () => []
            },

            showCallStatus: {
                type: Boolean,
                default: true
            }
        },

        computed: {
            callCount() {
                const {dataSource, showCallStatus} = this;

                if (dataSource.length && showCallStatus) {
                    return {
                        connected: this.getCount('CONNECTED'),
                        ringHangup: this.getCount('RING_HANGUP'),
                        disconnected: this.getCount('DISCONNECTED')
                    };
                }

                return {};
            }
        },

        methods: {
            multiDial: _.throttle(function multiCall() {
                const {dialType, dataSource} = this;

                const encryptedMobileNumbers = dataSource.map(({encryptedMobileNumber}) => encryptedMobileNumber);

                this.$app.ytalk.handleUserAction('syncExtraData', {dialType});
                Ytalk.multiDial({dialType, encryptedMobileNumbers});
            }, 3e3),

            getCount(status) {
                const {dataSource} = this;

                return dataSource.filter(({lastCallStatus}) => lastCallStatus === status).length;
            }
        }
    };

</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .multi-call-btn {
        display: inline-flex;
        align-items: center;
    }

</style>
