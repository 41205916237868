var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "alarm-remind-case" },
    [
      _c("span", { staticClass: "mr10" }, [
        _vm._v(_vm._s(_vm.notification.data.name) + " 的催告提醒"),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "mr10" }, [
        _vm._v(_vm._s(_vm._f("dateTime")(_vm.notification.data.timeRemind))),
      ]),
      _vm._v(" "),
      _c(
        "yqg-router-link",
        {
          staticClass: "mr10",
          attrs: {
            to: "remindCase",
            query: { remindCaseId: _vm.remindCaseId },
          },
        },
        [
          _vm._v(
            "\n        " +
              _vm._s(_vm.notification.data.remark || "无备注") +
              "\n    "
          ),
        ]
      ),
      _vm._v("\n\n    已到时间\n"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }