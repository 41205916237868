/**
 * @author giligili
 * @date 2021-06-24
 * @email zhimingwang@yangqianguan.com
 * @desc 联系人类型
 */

export default {
    NEW: 'NEW', // 新建联系人
    NEW_SELF: 'NEW_SELF', // 新建本人
    SELF: 'SELF', // 本人
    IMMEDIATE: 'IMMEDIATE', // 紧急联系人
    REPAIRED_SELF: 'REPAIRED_SELF', // 修复本人(本人手机号空号..., 后端调用第三方API得到虚拟手机号)

    CONTACT: 'CONTACT', // 通讯录联系人(其他)
    CALL_RECORD: 'CALL_RECORD', // 通话记录联系人
    BOTH: 'BOTH', // 通讯录与通话记录 CONTACT AND CALL_RECORD
    STRONG: 'STRONG' // 强相关号码
};
