<!-- @Author: chengyuzhang -->
<!-- @Date: 2020-05-11 19:38:51.035 -->
<!-- @Last Modified by: egafrandika -->
<!-- @Last Modified time: 2023-07-31 13:50:55 -->

<template>
    <div class="md-editor">
        <div class="app-bar">
            <a-button
                v-for="action in actionList"
                :key="action.name"
                class="button"
                :disabled="disabled"
                @click="callAction(action)"
            >
                <a-icon
                    v-if="action.icon.type === 'icon'"
                    :type="action.icon.value"
                />
                <span v-else> {{ action.icon.value }} </span>
            </a-button>
        </div>

        <div :style="customizedStyle">
            <textarea
                ref="textarea"
                :value="value"
                class="textarea"
                :disabled="disabled"
                :style="{cursor: `${disabled ? 'not-allowed': 'pointer'}`}"
                @input="onInput"
                @keydown="handleKeyDown"
            />
        </div>
    </div>
</template>

<script type="text/babel">
import KeyCodeMap from '../../../../../util/keyCodeMap';
import bold from '../action/bold';
import code from '../action/code';
import header from '../action/header';
import italic from '../action/italic';
import link from '../action/link';
import quote from '../action/quote';
import tab from '../action/tab';
import untab from '../action/untab';
import ytube from '../action/ytube';

const DEFAULT_ACTION_LIST = [
    header,
    bold,
    italic,
    link,
    quote,
    code,
    ytube
];

const DEFAULT_OPTIONS = {
    override: false,
    actionList: [], // 用户定义的 action list
    actionOrder: null // 定义 action 展示的顺序，用 name 指定
};

const KeyCodeEventMap = {
    [KeyCodeMap.tab]: tab
};

const KeyCodeShiftEventMap = {
    [KeyCodeMap.tab]: untab
};

export default {
    name: 'MdEditor',

    model: {
        prop: 'value',
        event: 'change'
    },

    props: {
        value: {
            type: String,
            required: true
        },

        options: {
            type: Object,
            default: () => ({})
        },

        customizedStyle: {
            type: Object,
            default: () => ({})
        },

        disabled: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        actionList() {
            const options = {...DEFAULT_OPTIONS, ...this.options};
            const {override, actionList: optionActionList, actionOrder} = options;
            const actionList = override ? optionActionList : [...DEFAULT_ACTION_LIST, ...optionActionList];

            if (actionOrder) {
                const actionMap = actionList.reduce((map, cur) => {
                    map[cur.name] = cur; // 用 name 作为 key

                    return map;
                }, {});

                return actionOrder.map(name => actionMap[name]);
            }

            return actionList;
        }
    },

    created() {
        Object.defineProperties(this, { // could not use computed, so use this for convenient
            textarea: {
                get() {
                    return this.$refs && this.$refs.textarea;
                }
            },

            text: {
                get() {
                    return this.textarea && this.textarea.value;
                }
            },

            selectionStart: {
                get() {
                    return this.textarea && this.textarea.selectionStart;
                }
            },

            selectionEnd: {
                get() {
                    return this.textarea && this.textarea.selectionEnd;
                }
            },

            selectionText: {
                get() {
                    const {textarea, selectionStart, selectionEnd, text} = this;
                    if (!textarea) return '';

                    return (selectionStart === selectionEnd) ? '' : text.slice(selectionStart, selectionEnd);
                }
            }
        });
    },

    methods: {
        callAction(action = {}) {
            if (action.insert) {
                return this.handleInsert(action);
            }

            if (action.modify) {
                return this.handleModify(action);
            }

            return null;
        },

        handleKeyDown(event) {
            if (event.shiftKey && KeyCodeShiftEventMap[event.keyCode]) {
                event.preventDefault();
                this.callAction(KeyCodeShiftEventMap[event.keyCode]);

                return;
            }

            if (KeyCodeEventMap[event.keyCode]) {
                event.preventDefault();
                this.callAction(KeyCodeEventMap[event.keyCode]);
            }
        },

        async handleInsert(action) {
            const {textarea, text, selectionText, selectionStart, selectionEnd} = this;
            const replaceText = await action.insert(selectionText);
            const newText = `${text.slice(0, selectionStart)}${replaceText}${text.slice(selectionEnd)}`;
            this.emitChange(newText);
            this.$nextTick(() => {
                textarea.setSelectionRange(selectionStart, selectionStart + replaceText.length);
                textarea.focus();
            });
        },

        async handleModify(action) {
            const {textarea, text, selectionStart, selectionEnd} = this;
            const {
                text: newText,
                selectionStart: newSelectionStart,
                selectionEnd: newSelectionEnd
            } = await action.modify({text, selectionStart, selectionEnd});
            this.emitChange(newText);
            this.$nextTick(() => {
                textarea.setSelectionRange(newSelectionStart, newSelectionEnd);
                textarea.focus();
            });
        },

        onInput() {
            const {$refs: {textarea: {value}}} = this;
            this.emitChange(value);
        },

        emitChange(value) {
            this.$emit('change', value);
        }
    }
};

</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.md-editor {
    .app-bar {
        .button {
            border: 0;
            font-weight: 600;
            padding: 0 8px;
            box-shadow: none;
        }
    }

    .textarea {
        width: 100%;
        height: 100%;
        padding: 20px;
        border: none;
        resize: none;
        background-color: #f6f6f6;
        font-size: 14px;
        font-family: "Monaco", courier, monospace;

        &:focus {
            outline: none;
            border: 1px solid #ccc;
        }
    }
}
</style>
