/**
 * @Author: giligili
 * @Date: 2022-02-10
 * @Last Modified by: yefenghan
 * @Last Modified time: 2024-09-09 15:07:53
 */
import { assign, customGet, customPost } from '@yqg/resource';
const api = {
    urlPrefix: '/admin/operation/col/feedback/',
    /* @desc 用 caseId 查反馈记录 */
    listFeedbackFlow: customGet('listFeedbackFlow'),
    /* @desc 用 反馈记录id 查操作记录 */
    listFeedbackOperationFlow: customGet('listFeedbackOperationFlow'),
    /* @desc 处理反馈记录 */
    handleFeedbackFlow: customPost('handleFeedbackFlow'),
    /* @desc 反馈问题类型 */
    listFeedbackOperationType: customGet('listFeedbackOperationType'),
    /* @desc 用客服工单ID查客服工单flowId 跳转用*/
    getCustomerServiceFlowId: customGet('getCustomerServiceFlowId'),
    /* @desc 解密手机号 */
    getUnmaskedMobileNumberByFeedbackId: customGet('getUnmaskedMobileNumberByFeedbackId'),
    /* @desc 诉讼详情 用 caseId 查反馈记录 */
    listLawsuitFeedbackFlow: customGet('listLawsuitFeedbackFlow'),
    /** @desc 获取所有反馈记录 */
    listAllTypeFeedbackFlow: customPost('listAllTypeFeedbackFlow'),
    /** @desc 所有反馈记录 查操作记录 */
    listAllTypeFeedbackOperationFlow: customGet('listAllTypeFeedbackOperationFlow')
};
export default assign(api);
