<!-- @Author: giligili -->
<!-- @Date: 2021-08-10 15:17:26.055 -->
<!-- @Last Modified by: giligili -->
<!-- @Last Modified time: 2023-07-27 17:25:02 -->

<template>
    <div
        class="ytalk"
        :style="ytalkZIndex"
    >
        <!-- isRegistering from ytalk mixin -->
        <a-spin
            v-if="!isLogin"
            :spinning="isRegistering"
        >
            <a-button
                type="primary"
                :disabled="!ytalkConfig"
                @click="loginYtalk"
                v-text="'登录外呼中心'"
            />
        </a-spin>

        <div
            v-else
            class="ytalk-container"
        >
            <!-- 登录成功后, 拨号前 canCall, isBusy, isAvailable from ytalk mixin -->
            <div
                v-if="canCall"
                class="ytalk-call-prepare"
            >
                <div
                    style="display: flex; flex-direction: row-reverse; margin-bottom: 10px;"
                >
                    <a-button
                        type="exclamation-circle"
                        style="color: #339bf0;"
                        @click="onStartSpeechDetection"
                    >
                        通话检测
                    </a-button>
                </div>

                <agent-status-change-timer
                    v-if="agentStatusChangeTipsVisible && ytalkConfig && ytalkConfig.autoChangeAgentStatusInterval"
                    :countdown="ytalkConfig.autoChangeAgentStatusInterval"
                    :agent-status="ytalkConfig.agentStatus"
                />

                <work-status
                    :is-busy="isBusy"
                    :work-status="shared.workStatus"
                    :work-status-map="shared.workStatusMap"
                    :is-loading="shared.workStatusLoading"
                    @workStatusChange="onWorkStatusChange"
                />

                <div class="ytalk-call mb10">
                    <a-input
                        type="text"
                        placeholder="请输入对方号码"
                        :value="shared.mobileNumber"
                        :max-length="32"
                        :disabled="disableInputMobileNumber"
                        @change="onChangeMobileNumber"
                    />

                    <a-button
                        class="yqg-btn-info"
                        @click="manualDial"
                        v-text="'呼叫'"
                    />

                    <a-button
                        type="primary"
                        @click="handleUserAction('unregister')"
                        v-text="'登出'"
                    />
                </div>

                <span
                    v-if="isBusy"
                    class="ytalk-tip"
                >
                    当前状态无法接入电话
                </span>
            </div>

            <template v-else-if="isCallTest">
                <span class="connect">
                    回声测试中
                </span>
            </template>

            <!-- 拨号中, canHangUp, isConnected from ytalk mixin -->
            <div v-else-if="canHangUp">
                <!-- keyboard 软键盘 -->
                <keyboard
                    v-if="isConnected"
                    ref="keyboard"
                    @change="sendDTMF"
                />

                <div class="ytalk-call-info">
                    <!-- 通话中, 通话已经接起 -->
                    <span
                        v-if="isConnected"
                        class="ytalk-call-connected"
                    >
                        <!-- 坐席辅助 -->
                        <agent-copilot
                            v-if="shared.answerList"
                            :copilot-info-list="shared.answerList"
                        />

                        <!-- 呼入线路 -->
                        <p
                            v-if="
                                shared.customerInfo
                                    && shared.customerInfo.gatewayName
                                    && $app.permissions.CASE_DETAIL_DISPLAY_CALL_IN_GATEWAY_CODE
                            "
                        >
                            <span>线路</span>: <span class="yqg-text-success">{{ shared.customerInfo.gatewayName }}</span>
                        </p>

                        <span class="yqg-text-success">
                            <span v-if="shared.maskedMobileNumber">{{ shared.maskedMobileNumber }}</span>
                            <span v-else>{{ displayPhone | phoneNumberMask3 }}</span>

                            通话中
                        </span>

                        <timer
                            ref="timer"
                            :start-time="shared.startTime"
                        />

                        <a-button @click="clickKeyboardIcon">
                            <img
                                :src="keyboardIcon"
                                class="icon keyboard"
                            >
                        </a-button>

                        <a-button
                            v-if="shared.isMuted"
                            @click="handleUserAction('unmute')"
                        >
                            <img
                                :src="muteIcon"
                                class="icon"
                            >
                        </a-button>

                        <a-button
                            v-else
                            @click="handleUserAction('mute')"
                        >
                            <img
                                :src="unmuteIcon"
                                class="icon"
                            >
                        </a-button>
                    </span>

                    <!-- 收到☎️ , 通话未被接起 -->
                    <span
                        v-else-if="canAnswer"
                        class="ytalk-call-receive"
                    >
                        <!-- 呼入客户的信息 -->
                        <p v-if="shared.customerInfo && shared.customerInfo.title">
                            {{ shared.customerInfo.title }}
                        </p>
                        <p v-if="shared.customerInfo && shared.customerInfo.message">
                            {{ shared.customerInfo.message }}
                        </p>

                        <!-- 呼入线路 -->
                        <p
                            v-if="
                                shared.customerInfo
                                    && shared.customerInfo.gatewayName
                                    && $app.permissions.CASE_DETAIL_DISPLAY_CALL_IN_GATEWAY_CODE
                            "
                        >
                            <span>线路</span>: <span class="yqg-text-success">{{ shared.customerInfo.gatewayName }}</span>
                        </p>

                        <span v-if="shared.maskedMobileNumber">{{ shared.maskedMobileNumber }}</span>
                        <span v-else>{{ displayPhone | phoneNumberMask3 }}</span>

                        来电呼入

                        <a-popconfirm
                            v-if="shared.customerInfo && shared.customerInfo.status === 'FAIL'"
                            :title="shared.customerInfo.popTitle"
                            @confirm="caseErrorAnswer"
                        >
                            <a-button
                                class="yqg-btn-success"
                                v-text="'接听'"
                            />
                        </a-popconfirm>

                        <a-button
                            v-else
                            class="yqg-btn-success"
                            @click="answer"
                            v-text="'接听'"
                        />
                    </span>

                    <!-- 主动拨号, 通话未被接起 -->
                    <span
                        v-else
                        class="ytalk-call-send"
                    >
                        <span v-if="shared.maskedMobileNumber">{{ shared.maskedMobileNumber }}</span>
                        <span v-else>{{ displayPhone | phoneNumberMask3 }}</span>

                        呼叫中
                    </span>

                    <a-button
                        type="danger"
                        @click="hangup"
                        v-text="'挂断'"
                    />
                </div>
            </div>

            <div class="ytalk-audio">
                <audio
                    ref="YTalkRemoteAudio"
                    autoplay="autoplay"
                />
                <audio
                    ref="YTalkHangupRecord"
                    :src="HangupRecord"
                />
                <audio
                    ref="YTalkRingRecord"
                    loop="loop"
                    :src="RingRecord"
                />
                <audio
                    ref="YTalkCallRecord"
                    loop="loop"
                    :src="CallRecord"
                />
                <audio
                    ref="YTalkConnectedRecord"
                    :src="ContentedRecord"
                />
            </div>

            <a-modal
                v-if="detectionModalVisible"
                title="通话检测"
                :visible="detectionModalVisible"
                :width="600"
                :closable="false"
                :footer="null"
            >
                <SpeechDetectionModal
                    ref="SpeechDetectionModal"
                    type="collectionCallTest"
                    :call-account-id="callAccountId"
                    @closeModal="closeDetectionModal"
                    @hangup="hangup"
                    @onSelectChange="onDetectionModalSelectChange"
                    @setMediaConstraints="setMediaConstraints"
                />
            </a-modal>
        </div>
    </div>
</template>

<script type="text/babel" lang="js">

    import {mapGetters, mapActions} from 'vuex';

    import CallRecord from '@yqg/vue/ytalk/audio/call.mp3';
    import ContentedRecord from '@yqg/vue/ytalk/audio/contented.mp3';
    import HangupRecord from '@yqg/vue/ytalk/audio/hangup.mp3';
    import RingRecord from '@yqg/vue/ytalk/audio/ring.mp3';

    import SpeechDetectionModal from '@shared/client/component/yqg-speech-detection/modal';
    import YTalk from '@shared/client/ytalk';
    import YTalkMetric from '@shared/client/ytalk-metric';

    import EnumAll from 'collection-admin-web/common/constant/enum';
    import bus, {
        OnYtalkCallRelease,
        OnYtalkUserAgentCallRelease,
        OnYtalkAgentStatusCallRelease,
        OnCloseMultiCallModal,
        OnCloseMessageModal
    } from 'collection-admin-web/common/constant/event-bus';
    import ytalk from 'collection-admin-web/common/mixin/ytalk/mixin';
    import Ytalk from 'collection-admin-web/common/resource/call/ytalk';
    import Knowledge from 'collection-admin-web/common/resource/knowledge';
    import {
        aesDecryptCallSecret,
        aesEncryptCallSecret
    } from 'collection-admin-web/common/util/encryption';
    import EventLog, {EVENT, parseCatch, STATUS} from 'collection-admin-web/common/util/event-log';
    import {COMMON_FLOW_SOURCE} from 'collection-admin-web/common/util/event-log/constant/source/flow-source';

    import createFlowModal from 'src/common/util/controller/flow-modal';

    import keyboardIcon from './assets/keyboard.png';
    import muteIcon from './assets/mute.png';
    import unmuteIcon from './assets/unmute.png';
    import AgentCopilot from './components/agent-copilot';
    import AgentStatusChangeTimer from './components/agent-status-change-timer';
    import Keyboard from './components/keyboard';
    import Timer from './components/timer';
    import WorkStatus from './components/work-status';
    import CallTypeManager from './helper/call-type/call-type-manager';
    import RenewalManager from './helper/renewal';
    import workStatusMixin from './mixin/work-status-mixin';

    const internalIp = require('internal-ip');

    const BooleanType = EnumAll.Basic.Boolean.TYPE;

    const flowModalControl = createFlowModal();

    // 呼出可能没有callId，即: 发出INVITE消息后, [180, 183]没有带callId, 没有建立起正常的通话
    // 针对这种情况要特殊处理(添加通话记录, 广播电话挂断事件... 判断一下callId)
    export default {
        name: 'Ytalk',

        components: {
            Timer,
            Keyboard,
            AgentStatusChangeTimer,
            AgentCopilot,
            WorkStatus,
            SpeechDetectionModal
        },

        mixins: [ytalk, workStatusMixin],

        ReconnectMaxTimes: -1,

        data() {
            return {
                ytalkConfig: null,

                HangupRecord,
                RingRecord,
                CallRecord,
                ContentedRecord,

                muteIcon,
                unmuteIcon,
                keyboardIcon,

                agentStatusChangeTipsVisible: false,
                detectionModalVisible: false,
                mediaConstraintsSelf: null
            };
        },

        computed: {
            ...mapGetters(['user']),

            ytalkZIndex() {
                const {isLogin} = this;

                return isLogin
                    ? {zIndex: 1100} // 因为modal的zIndex是1000
                    : {zIndex: 900};
            },

            /*
             * @override ytalk mixin displayPhone
             * @desc 催收外呼使用加密手机号
             * @todo 这里的逻辑有点过于复杂了, 应该可以只使用maskedMobileNumber
             */
            displayPhone() {
                const {outgoingMobileNumber} = this;
                // mobileNumber 外呼的真正手机号

                // incomingMobileNumber 呼入的手机号(header)

                // encryptedMobileNumber 加密手机号(外呼, 一键多呼(x))
                // 一键多呼也是将 incomingMobileNumber 进行加密的
                // 所以这里不考虑encrypedMobileNumber为一键多呼的场景, 如果需要加密单独用 incomingMobileNumber 处理
                const {direction, incomingMobileNumber} = this.shared;

                return direction === 'outgoing' ? outgoingMobileNumber : incomingMobileNumber;
            },

            outgoingMobileNumber() {
                const {mobileNumber, encryptedMobileNumber} = this.shared;

                return mobileNumber || aesDecryptCallSecret(encryptedMobileNumber);
            },

            disableInputMobileNumber() {
                return !this.$app.permissions.EXTRA_DIAL_WITH_PLAIN_TEXT_PHONE_NUMBER;
            },
            isCallTest() {
                return this.shared.callType === 'CALL_TEST';
            },
            callAccountId() {
                return this.user?.callAccount;
            },
        },

        watch: {
            canHangUp(nextValue) {
                if (!this.callSession) return;
                // 打电话中刷接口防止自动登出
                if (nextValue) {
                    // 同步打电话中状态
                    Ytalk.setWorkStatus({
                        isCalling: BooleanType.TRUE
                    }).catch(x => x);
                    RenewalManager.startRenewal();
                } else {
                    RenewalManager.stopRenewal();
                }
            },
            isConnected(nextValue) {
                if (!this.callSession || !nextValue) return;
                // 通话测试不添加记录，挂断电话后shared数据会被清空, 所以当前通话类型需要从callSession中获取
                if (this.callSession.call?.sharedData?.callType === 'CALL_TEST') return;
                this.logger('addConnectCallDetail', '添加接通通话记录');

                this.callSession.addCallDetail({connected: true})
                    .then(({type, message}) => {
                        this.uploadEventLog(EVENT.YTALK_ADD_CONNECT_CALL_DETAIL, {
                            status: type === 'success' ? STATUS.SUCCESS : STATUS.FAIL,
                        });
                        this.$message[type](message);
                    })
                    .catch(reason => {
                        const reasonStr = reason?.toString();
                        if (reasonStr !== 'addCallDetailLock') {
                            // lock的情况不上报
                            this.uploadEventLog(EVENT.YTALK_ADD_CONNECT_CALL_DETAIL, {
                                status: STATUS.FAIL,
                                reason: reasonStr,
                            });
                        }

                        this.logger(
                            'addConnectCallDetail:error',
                            '添加接通通话记录失败',
                            {reason: reasonStr}
                        );
                    });

                this.logger('startAgentCopilot', '开启坐席辅助');

                if (this.$app.permissions.EXTRA_DIAL_WITH_ASSIST) {
                    this.callSession.startAgentCopilot()
                        .then(({type, message}) => {
                            this.uploadEventLog(EVENT.YTALK_START_AGENT_COPILOT, {
                                status: type === 'success' ? STATUS.SUCCESS : STATUS.FAIL,
                            });
                            this.$message[type](message);
                        })
                        .catch(reason => {
                            this.uploadEventLog(EVENT.YTALK_START_AGENT_COPILOT, {
                                status: STATUS.FAIL,
                                reason: reason?.toString(),
                            });
                        });
                }
            },

            canCall(nextValue) {
                if (!this.callSession || !nextValue) return;
                // 通话测试不添加记录，挂断电话后shared数据会被清空, 所以当前通话类型需要从callSession中获取
                if (this.callSession.call?.sharedData?.callType === 'CALL_TEST') return;
                this.logger('addUnConnectCallDetail', '添加未接通通话记录');

                // 可以挂断表示通话结束(可能接通/可能未接通, 通过callSession.addCallDetail里面的lock控制)
                this.callSession.addCallDetail()
                    .then(({type, message}) => {
                        this.uploadEventLog(EVENT.YTALK_ADD_UNCONNECT_CALL_DETAIL, {
                            status: type === 'success' ? STATUS.SUCCESS : STATUS.FAIL,
                        });
                        this.$message[type](message);
                    })
                    .catch(reason => {
                        const reasonStr = reason?.toString();
                        if (reasonStr !== 'addCallDetailLock') {
                            // lock的情况不上报
                            this.uploadEventLog(EVENT.YTALK_ADD_UNCONNECT_CALL_DETAIL, {
                                status: STATUS.FAIL,
                                reason: reasonStr,
                            });
                        }

                        this.logger(
                            'addUnConnectCallDetail:error',
                            '添加未接通通话记录失败',
                            {reason: reasonStr}
                        );
                    });
            }
        },

        mounted() {
            this.$app.ytalk = this;

            this.fetchIP();
            this.fetchCallConfig();

            window.name = window.name || `${this.user?.callAccount}(${Date.now()})`;

            bus.$on(OnYtalkAgentStatusCallRelease, this.setCallEndAgentStatusByEvent);

            this.ytalk = new YTalk();

            this.ytalk.addConsumer(new YTalkMetric({
                getDefaultData: () => ({
                    account: this.user?.callAccount,
                    business: 'Web_COLLECTION',
                    workplace: '',
                    agentStatus: this.shared.status,
                })
            }));
        },

        destroyed() {
            this.ytalk.destroy();
        },

        beforeDestroy() {
            bus.$off(OnYtalkAgentStatusCallRelease, this.setCallEndAgentStatusByEvent);

            this.clearAgentStatusChangeTimeout();
        },

        methods: {
            ...mapActions('caseDetail', ['addStashCollectionFlow']),

            logger(name, description, meta = {}) {
                try {
                    const action = this.userAgent
                        ? `ytalk:userAgent:${name}`
                        : `ytalk:${name}`;

                    let ytalkStatus = '拨号中';
                    ytalkStatus = this.canCall ? '拨号前(登陆后的初始状态)' : ytalkStatus;
                    ytalkStatus = this.canAnswer ? '来电呼入(接通前)' : ytalkStatus;
                    ytalkStatus = this.isConnected ? '已接通' : ytalkStatus;

                    const payload = this.userAgent
                        ? {
                            callId: this?.callSession?.call?.sharedData?.callId, // callId
                            logTime: new Date().toString(), // 时间
                            timestamp: new Date().getTime(),
                            callAccount: this.user?.callAccount, // 座席号
                            callType: this?.callSession?.call?.extraData?.dialType, // 呼叫类型
                            encryptedMobileNumber: this?.callSession?.call?.extraData?.encryptedMobileNumber, // 加密手机号
                            agentStatus: this.shared?.status,
                            ytalkStatus,
                            canHangUp: this.canHangUp, // 拨号中和已经接通都可以挂断

                            ...meta
                        }
                        : {
                            logTime: new Date().toString(), // 时间
                            callAccount: this.user?.callAccount, // 座席号
                            agentStatus: this.shared?.status,
                            ytalkStatus,
                            canHangUp: this.canHangUp, // 拨号中和已经接通都可以挂断

                            ...meta
                        };

                    YqgReporter.info({
                        action,
                        description,

                        payload
                    });
                } catch (err) {
                    YqgReporter.info({
                        action: 'logger:error',
                        err: `${err?.toString()}`
                    });
                }
            },

            uploadEventLog(eventName, meta = {}) {
                EventLog.uploadEventLog(eventName, {
                    ...meta,
                    extra: {
                        ...(meta.extra || {}),
                        callInfo: this.getCallInfo(),
                    }
                });
            },

            getCallInfo() {
                let callInfo = {
                    callAccount: this.user?.callAccount, // 座席号
                    agentStatus: this.shared?.status,
                    workStatus: this.shared?.workStatus,
                };
                if (this.userAgent) {
                    const call = this.callSession?.call;
                    callInfo = {
                        ...callInfo,
                        isUserAgent: true,
                        callId: call?.sharedData?.callId, // callId
                        callType: call?.extraData?.dialType, // 呼叫类型
                        encryptedMobileNumber: call?.extraData?.encryptedMobileNumber, // 加密手机号
                    };
                }

                return callInfo;
            },

            initYtalk(sipInfo) {
                if (this.isLogin) return; // 可能已经是登录的了，其他页面登录状态共享过来的

                this.initSip(sipInfo);

                // 在发出 register message 前，需要等待 websocket 链接建立，所以可以在这里增加 register header
                if (this.userAgent) { // initSip 可能异常
                    const registrator = this.userAgent.registrator();
                    registrator.setExtraHeaders([
                        `X-IP: ${this.ip}`,
                        `X-UA: ${window.navigator.userAgent} - ${window.name}`
                    ]);

                    this.ytalk.addDevice(this.userAgent);
                }
            },

            async loginYtalk() {
                const mediaDevice = this.isAutoAnswerAvailable()
                    ? await this.isValidMediaDevices()
                    : true;

                if (!mediaDevice) return;

                const {user: {callAccount, callPassword}} = this; // agent info
                const {ytalkConfig: {
                    ytalkAgentSocket: socket,
                    ytalkAgentUriHost: uriHost
                }} = this; // ytalk server info

                const sipInfo = {callAccount, callPassword, socket, uriHost};

                this.loginYtalkConfirm()
                    .then(() => this.initYtalk(sipInfo)) // from ytalk mixin
                    .catch(err => err);
            },

            loginYtalkConfirm() {
                const {user: {callAccount}} = this;

                return this.$modal.SimpleModal({
                    title: '外呼平台',
                    field: 'agentAccount',
                    label: '账号',
                    type: 'text',
                    defaultCond: {agentAccount: callAccount}
                });
            },

            setAgentBusy() {
                const {AgentStatus: {BUSY: status}} = this;

                this.setWorkStatusByAgentStatus(status);
            },

            setAgentAvailable() {
                const {AgentStatus: {AVAILABLE: status}} = this;

                this.setWorkStatusByAgentStatus(status);
            },

            onWorkStatusChange(id) {
                if (!this.canCall) return;

                this.setWorkStatusById(id);

                this.handleAllAction('clearAgentStatusChangeTimeout');
                this.handleUserAction('clearSetCallEndAgentStatusByEvent');
            },

            getAgentStatusChangeMethod() {
                const {agentStatus = this.AgentStatus.BUSY} = this.ytalkConfig || {};

                return agentStatus === this.AgentStatus.BUSY
                    ? this.setAgentBusy
                    : this.setAgentAvailable;
            },

            openAgentStatusChangeTimeout() {
                this.clearAgentStatusChangeTimeout();

                const {autoChangeAgentStatusInterval = 0} = this.ytalkConfig || {};

                const func = this.getAgentStatusChangeMethod();

                this.agentStatusChangeTipsVisible = true;
                this.agentStatusChangeTimeout = setTimeout(() => {
                    // 防止请求多次改变坐席状态的接口
                    // 防止在下一次通话中改变状态
                    if (this.userAgent && this.canCall) func();

                    this.clearAgentStatusChangeTimeout();
                }, autoChangeAgentStatusInterval * 1000);
            },

            clearAgentStatusChangeTimeout() {
                this.agentStatusChangeTipsVisible = false;

                clearTimeout(this.agentStatusChangeTimeout);
            },

            setCallEndAgentStatusByEvent(payload) {
                this.handleUserAction('setCallEndAgentStatusByEvent', payload);
            },

            clickKeyboardIcon() {
                const {keyboard} = this.$refs;

                keyboard.showKeyboard = !keyboard.showKeyboard;
            },

            sendDTMF(val) {
                this.handleUserAction('sendDTMF', val);
            },

            transferIVR() {
                const {satisfacationAvailable} = this.ytalkConfig || {};
                const {isConnected, shared: {callId: callUuid}} = this;

                if (!satisfacationAvailable) {
                    this.logger('satisfacationAvailable:error', '丢失满意度配置');
                }

                if (
                    !isConnected
                    || !callUuid
                    || satisfacationAvailable !== BooleanType.TRUE
                    || this.isCallTest
                ) {
                    return Promise.reject();
                }

                return Ytalk.transferSatisIvr({params: {callUuid}})
                    .then(({data: {body}}) => {
                        this.uploadEventLog(EVENT.YTALK_TRANSFER_SATIS_IVR, {
                            status: body ? STATUS.SUCCESS : STATUS.FAIL,
                        });

                        if (!body) return Promise.reject();

                        return Promise.resolve();
                    }).catch(err => {
                        this.uploadEventLog(EVENT.YTALK_TRANSFER_SATIS_IVR, {
                            status: STATUS.FAIL,
                            reason: parseCatch(err),
                        });

                        return Promise.resolve();
                    });
            },

            onChangeMobileNumber({target: {value}}) {
                this.shared.mobileNumber = value.replace(/\D/g, '');

                this.syncShared();
            },

            // 手拨
            async manualDial() {
                const {shared: {mobileNumber}} = this;

                if (!mobileNumber) {
                    this.$message.error('请输入对方号码');

                    return;
                }

                try {
                    await Ytalk.checkMobileCallCnt({params: {mobileNumber}});

                    const encryptedMobileNumber = aesEncryptCallSecret(mobileNumber);

                    this.handleUserAction('call', {encryptedMobileNumber});

                    this.logger('dial:input', '输入框外呼', {encryptedMobileNumber});

                    this.uploadEventLog(EVENT.YTALK_DIAL_INPUT, {
                        encryptedMobileNumber,
                    });

                    this.shared.dialByInput = true;

                    this.syncShared();
                } catch (err) {
                    // 超过呼叫次数
                    this.shared.mobileNumber = '';

                    this.syncShared();
                }
            },

            // 外呼
            dial({contactInfo, callTag, areaType, areaCode, encryptedMobileNumber}, source) {
                this.handleUserAction('syncExtraData', {contactInfo, callTag, areaType, areaCode});

                this.handleUserAction('call', {encryptedMobileNumber});

                this.logger('dial:compenent', '外呼组件外呼', {encryptedMobileNumber});

                this.uploadEventLog(EVENT.YTALK_DIAL_COMPONENT, {
                    encryptedMobileNumber,
                    source,
                    isVisual: false,
                });
            },

            // 虚拟号外呼
            dialVisual({
                contactInfo,
                callTag,
                maskedMobileNumber,
                calledNumber,
                callingNumber
            }, source) {
                this.handleUserAction('syncExtraData', {
                    contactInfo,
                    callTag,
                    callingNumber
                });

                this.handleAllAction('sync', {...this.shared, maskedMobileNumber});

                this.handleUserAction('call', {encryptedMobileNumber: calledNumber});

                this.uploadEventLog(EVENT.YTALK_DIAL_COMPONENT, {
                    encryptedMobileNumber: calledNumber,
                    source,
                    isVisual: true,
                });
            },

            answer() {
                const customerInfo = this.shared.customerInfo;

                if (customerInfo?.status !== 'SUCCESS') {
                    this.$message.info('正在查找客户信息, 请稍后接听!!');

                    return;
                }

                this.handleUserAction('answer');
                this.route2CaseDetail(customerInfo);
            },

            caseErrorAnswer() {
                this.handleUserAction('answer');

                const customerInfo = this.shared.customerInfo;
                if (customerInfo.caseId) this.route2CaseDetail(customerInfo);
            },

            hangup() {
                this.transferIVR()
                    .catch(() => this.handleUserAction('terminateSessions'));

                this.logger('manual:hangup', '手动挂断');

                this.uploadEventLog(EVENT.YTALK_SEAT_HANGUP);

                /* 加一个记录挂断延迟的log start */
                const callId = this.shared?.callId;
                if (!callId) return;
                // 坐席可能频繁点挂断，这里只上报第一次
                if (this.hangupLogInfo?.callId === callId) return;

                this.hangupLogInfo = {
                    callId,
                    timeout: setTimeout(() => {
                        if (this.canCall) return;
                        // 防止2s内有新的通话，污染log
                        if (callId !== this.shared?.callId) return;

                        YqgReporter.info({
                            action: 'HANGUP_DELAY',
                            description: '挂断延迟',
                            payload: {
                                time: new Date().toString(),
                                callId: this.shared?.callId,
                                encryptedMobileNumber: this.shared?.encryptedMobileNumber,
                                callAccount: this.user.callAccount,
                                status: this.shared?.status
                            }
                        });
                    }, 2000)
                };
                /* 加一个记录挂断延迟的log end */
            },

            fetchCallConfig() {
                Ytalk.getCallConfig()
                    .then(({data: {body}}) => {
                        this.ytalkConfig = body;
                    })
                    .catch(err => this.logger('getCallConfig:error', '获取ytalk配置异常', {err}));
            },

            fetchIP() {
                internalIp.v4()
                    .then(res => (this.ip = res))
                    .catch(err => err);
            },

            /* @desc apply by ytalk mixin(jssip event) start */
            onRegisterSuccess() {
                this.handleAllAction('clearAgentStatusChangeTimeout');
                this.handleUserAction('clearSetCallEndAgentStatusByEvent');

                this.fetchWorkStatus()
                    .then(() => {
                        if (this.$app.permissions.ROLE_TYPE_SPECIALIST) {
                            // 催员自动切换空闲
                            this.setAgentAvailable();
                        }
                    })
                    .catch(err => this.logger('getWorkStatus:error', '获取工作状态异常', {err}));

                // 关联一下坐席和技能组, 坐席可以接预测式外呼
                Ytalk.setAgentSkillId()
                    .catch(err => err);
            },

            onUnregistered() {
                Ytalk.setWorkStatusLogout()
                    .catch(err => err);
                this.handleAllAction('clearAgentStatusChangeTimeout');
                this.handleUserAction('clearSetCallEndAgentStatusByEvent');

                this.callSession = null;
            },

            onNewMessage(response) {
                const {sipMessageType, multiAnswerMobile, curStatus, chatOperation, message} = response;

                if (chatOperation == 'SEAT_ASSIST' && message) {
                    const messageData = JSON.parse(message);
                    this.getKnowledgeInfo(messageData);

                    return;
                }

                if (sipMessageType === 'USER_EXPIRE_NOTIFY') {
                    this.logger('user_expire', '坐席心跳丢失');

                    return;
                }

                // 话后处理默认置忙
                if (sipMessageType === 'USER_STATUS_SYNC' && curStatus === 'HANGUP_HANDLE') {
                    if (!this.ytalkConfig.autoChangeAgentStatusInterval) {
                        const setAgentStatus = this.getAgentStatusChangeMethod();

                        setAgentStatus();
                    } else {
                        this.setAgentBusy();
                    }

                    this.handleUserAction('setCallEndAgentStatusByMessage');
                    this.handleAllAction('updateSessionInfo', {
                        connected: this.isConnected,
                        endTime: Date.now()
                    });

                    this.logger('agentStatusChange:hangup_handle', '坐席状态改变为话后处理', {
                        curStatus
                    });
                    this.logger('prevCallInfo', '前一通通话信息', {
                        callId: this.shared?.callId,
                        callStartTime: this.shared?.startTime,
                        callEndTime: Date.now(),
                        connected: this.isConnected,
                        pageCallEndTime: this.$refs.timer?.$el?.innerText // 页面显示的时间
                    });

                    return;
                }

                // 一键多呼(一键多呼是 ytalk 先接通坐席，然后待用户接通后通过 message 告诉我们一些用户手机号...信息)
                const {shared: {isMultiCall, customData}, $refs: {YTalkConnectedRecord}} = this;
                if (customData && isMultiCall && multiAnswerMobile) {
                    this.callSession.call.peerAccept({multiAnswerMobile});

                    // 这里要让ringRecord先暂停(ytalk mixin 中的同步逻辑), 后播放接通提示音
                    setTimeout(() => YTalkConnectedRecord?.play());
                }
            },

            // 新会话
            onNewRTCSession({request, session}) {
                this.logger('newRTCSession', 'ytalk发送/收到INVITE消息');
                const {extraData, shared, ytalkConfig} = this;

                this.handleAllAction('newSessionInfo');
                this.handleAllAction('clearAgentStatusChangeTimeout');

                this.callSession = new CallTypeManager({
                    session,
                    request,
                    extraData,
                    ytalkConfig,
                    sharedData: shared,
                    setAgentStatusMethod: () => {
                        if (!this.ytalkConfig.autoChangeAgentStatusInterval) return;

                        this.handleAllAction('setAgentStatusByTimeout');
                    }
                });

                this.extraData = {};

                flowModalControl.start();

                // 呼出设置header
                (async () => {
                    try {
                        const headers = await this.callSession.call.getHeaders();

                        if (this.callSession.call.type === 'DIAL') {
                            const {user: {collectorId}} = this;
                            if (extraData.contactInfo) {
                                const {contactInfo: {caseId, collectionType, caseType}} = extraData;

                                if (caseId && collectionType && collectorId && caseType) {
                                    const uniqueIdentifier = `${caseId}-${collectionType}-${collectorId}-${new Date().getTime()}-${caseType}`;
                                    headers['X-Unique-Identifier'] = uniqueIdentifier;
                                } else {
                                    this.logger('dial:uniqueIdentifier', '手拨uniqueIdentifier', {collectionType, caseId, collectorId, caseType});
                                }
                            }
                        }

                        if (headers && Object.keys(headers).length) {
                            Object.keys(headers).forEach(key => {
                                request.setHeader(key, headers[key]);
                            });
                        }
                    } catch (err) {
                        // ignore
                    }
                })();

                // 呼入设置手机号...共享数据
                (async () => {
                    try {
                        const sharedData = await this.callSession.call.getSharedData();
                        if (sharedData && Object.keys(sharedData).length) {
                            Object.assign(this.shared, sharedData);
                            this.syncShared();
                        }
                    } catch (err) {
                        // ignore
                    }
                })();

                let inboundTitle = '';
                let gatewayName = '';
                // 跳转案件详情
                (async () => {
                    try {
                        if (this.shared.callDirection === 'CALL_IN') {
                            Object.assign(this.shared, {customerInfo: {status: 'INIT'}});
                            this.syncShared();
                        }

                        const {
                            link,
                            title,
                            caseId,
                            message,
                            modalInfo,
                            contactInfo,
                            gatewayName: gateway
                        } = await this.callSession.call.getCaseDetail();

                        inboundTitle = title;
                        gatewayName = gateway;

                        if (!link && message) {
                            this.$message.error(message);
                        }

                        if (!link && this.shared.callDirection === 'CALL_IN') {
                            if (!caseId) {
                                Object.assign(this.shared, {customerInfo: {
                                    title,
                                    status: 'FAIL',
                                    message,
                                    popTitle: '未找到案件，确认接听嘛？',
                                    gatewayName
                                }});
                                this.syncShared();

                                return;
                            }

                            Object.assign(this.shared, {customerInfo: {
                                title,
                                status: 'FAIL',
                                popTitle: '进线案件异常, 确认接听嘛?',
                                caseId,
                                message,
                                contactInfo,
                                gatewayName
                            }});
                            this.syncShared();

                            return;
                        }

                        // 回拨, 预测式外呼 (呼入)
                        if (link && this.shared.callDirection === 'CALL_IN') {
                            Object.assign(this.shared, {
                                customerInfo: {
                                    status: 'SUCCESS',
                                    title,
                                    caseId,
                                    message,
                                    contactInfo,
                                    gatewayName
                                }
                            });
                            this.syncShared();

                            // 截屏用户
                            const isScreenshotCustomer = this.callSession?.call?.extraData?.isScreenshotCustomer;

                            const {autoDialAutoAnswer} = this.ytalkConfig || {};

                            // 预测式外呼且在配置中的机构
                            const predictAutoAnswer = autoDialAutoAnswer && this.callSession?.call?.type === 'PREDICT';

                            /**
                             * 自动接听，并跳转案件详情
                             * 1、截屏用户
                             * 2、预测式外呼且在配置中的机构
                             */
                            if (isScreenshotCustomer || predictAutoAnswer) {
                                this.answer();
                            }

                            return;
                        }

                        // 一键多呼（呼出)
                        if (link) {
                            this.handleAllAction('linkToCaseDetail', {contactInfo, message, modalInfo});
                        }
                    } catch (err) {
                        if (this.shared.callDirection === 'CALL_IN') {
                            Object.assign(this.shared, {
                                customerInfo: {
                                    title: inboundTitle,
                                    status: 'FAIL',
                                    popTitle: '未找到案件，确认接听嘛？',
                                    gatewayName
                                }
                            });
                            this.syncShared();
                        }

                        this.logger('linkToCaseDetail:error', '跳转案件详情失败', {
                            err: `${err?.toString()}`
                        });
                    }
                })();
            },

            onSessionProgress() {
                const {shared} = this;

                // 呼出同步 callId
                this.callSession.call.updateSharedData(shared);

                this.logger('dial:callId', '手拨callId', {callId: this.shared?.callId});
            },

            onSessionAccepted() {
                const {callSession, $refs: {YTalkConnectedRecord}} = this;

                if (callSession.call.type === 'DIAL' && YTalkConnectedRecord) {
                    // 这里要让ringRecord先暂停(ytalk mixin 中的同步逻辑), 后播放接通提示音
                    setTimeout(() => YTalkConnectedRecord.play());
                }
            },

            // 通话接通
            onSessionConnected() {
                const {shared: {startTime}} = this;

                this.handleAllAction('updateSessionInfo', {startTime});
            },

            onSessionFailed() {
                // 没有 callId 认为不是个有效的通话（e.g. 信令未发出、限流）
                const {shared: {callId}} = this;

                if (!callId) this.reset();
            },

            onRegisterFailed({case: reason} = {}) {
                if (this.userAgent) {
                    this.userAgent.stop();
                    this.userAgent = null;
                }

                this.logger('USER_REGISTER_FAIL', '登录失败', {reason});
            },

            /* @desc apply by ytalk mixin(jssip event) end */

            /* @desc apply by ytalk mixin(broadcast channel callback) start */
            /* --- apply at active tab start --- */
            activeToast({type, message}) {
                this.$message[type](message);
            },

            activeCallEnd({gatewayHangupCause}) {
                if (gatewayHangupCause) {
                    this.$error({
                        title: '【外呼平台】',
                        content: gatewayHangupCause
                    });
                }
            },
            /* --- apply at active tab end --- */

            /* --- apply at user tab start --- */
            // 一些不需要共享, 只在 user tab 上保存的数据, 会被挂到 CallType 的实例上
            userSyncExtraData(payload = {}) {
                this.extraData = payload;
            },

            userCallEnd() {
                const {isConnected: connected} = this;
                const {extraData} = this.callSession.call;

                const payload = {connected, extraData, source: COMMON_FLOW_SOURCE.CALL_END};

                if (this.shared.callId) {
                    this.handleAllAction('callEnd', payload);
                }

                bus.$emit(OnYtalkUserAgentCallRelease, payload);
            },

            userSetCallEndAgentStatusByMessage() {
                this.callSession?.setAgentStatus?.();
            },

            userSetCallEndAgentStatusByEvent(payload = {}) {
                const {encryptedMobileNumber} = payload;

                if (encryptedMobileNumber !== this.callSession?.call?.extraData?.encryptedMobileNumber) return;

                this.callSession?.updateSetAgentStatusMethod?.(() => {
                    this.handleAllAction('setAgentStatusByEvent');
                });

                if (this.canCall) {
                    this.callSession?.setAgentStatus?.();
                }
            },

            userClearSetCallEndAgentStatusByEvent() {
                this.callSession?.stopSetAgentStatusMethod?.();
            },
            /* --- apply at user tab end --- */

            /* --- apply at all tabs start --- */
            allCallEnd(payload = {}) {
                bus.$emit(OnYtalkCallRelease, payload);
            },

            allSetAgentStatusByTimeout() {
                this.openAgentStatusChangeTimeout();
            },

            allSetAgentStatusByEvent() {
                this.clearAgentStatusChangeTimeout();

                // 下催记后 固定切换为空闲状态
                const func = this.setAgentAvailable;

                if (this.userAgent) {
                    func();
                }
            },

            allClearAgentStatusChangeTimeout() {
                this.clearAgentStatusChangeTimeout();
            },

            allLinkToCaseDetail({
                message,
                contactInfo,
                modalInfo: {sdkType, message: fullMessage, caseId}
            }) {
                bus.$emit(OnCloseMultiCallModal);

                try {
                    this.logger('linkToCaseDetail:success', '跳转案件详情成功', {
                        contactInfo,
                        sdkType,
                        fullMessage,
                        caseId
                    });
                } catch (err) {
                    // ignore
                }

                if (!message) {
                    this.$router.push({
                        name: 'CaseDetail',
                        query: {caseId: window.btoa(caseId)},
                        params: {
                            type: 'addStashCollectionFlow',
                            contactInfo: {...contactInfo, sessionInfo: this.sessionInfo}
                        }
                    })
                    .catch(err => err);

                    return;
                }

                const modal = this.$modal.SimpleModal({
                    customTitle() {
                        return (
                            <yqg-enum
                                defaultText="未知SDK"
                                value={sdkType}
                                enumType={EnumAll.Basic.SdkType}
                            />
                        );
                    },
                    customRender() {
                        return (
                            <div>{fullMessage}</div>
                        );
                    }
                }, {dialogProps: {width: 300}});

                modal
                    .then(() => {
                        this.handleAllAction('closeMessageModal');

                        this.$router.push({
                            name: 'CaseDetail',
                            query: {caseId: window.btoa(caseId)},
                            params: {
                                type: 'addStashCollectionFlow',
                                contactInfo: {...contactInfo, sessionInfo: this.sessionInfo}
                            }
                        }).catch(err => err);
                    }).catch(err => err);
            },

            allCloseMessageModal() {
                bus.$emit(OnCloseMessageModal);
            },

            // sessionInfo保存着通话信息(每个通话都会实例化一个)
            // 更新的时候改变属性
            // 如果其他组件有需要用到通话信息时，传引用
            allNewSessionInfo() {
                this.sessionInfo = {};
            },

            allUpdateSessionInfo(nextSessionInfo) {
                // 这里捕获一下错误
                // 每次发送/接收invite消息, 都会在this上挂一个sessionInfo
                // 如果发送/接收invite消息后, 未接通前。重新打开一个tab页面(跳过了this.sessionInfo = {})
                // 这个时候this.sessionInfo = undefined, 下面会报错
                try {
                    Object.assign(this.sessionInfo, nextSessionInfo);
                } catch (err) {
                    // ignore err
                }
            },

            /* --- apply at all tabs end --- */
            /* @desc apply by ytalk mixin(broacast cahnnel callback) end */
            route2CaseDetail({caseId, contactInfo}) {
                const param = {
                    name: 'CaseDetail',
                    query: {caseId: window.btoa(caseId)},
                    params: {
                        type: 'addStashCollectionFlow',
                        contactInfo: {...contactInfo, sessionInfo: this.sessionInfo}
                    }
                };

                // push 相同路由
                this.$router.push(param).catch(err => err);
            },

            getKnowledgeInfo({questionId = null}) {
                if (!questionId) return;

                Knowledge.fetchByQuestionCategoryId({questionId}, {hideLoading: true}).then(({data: {body}}) => {
                    const {answerList = []} = this.shared;
                    answerList.unshift(body);

                    Object.assign(this.shared, {
                        answerList
                    });

                    this.syncShared();
                });
            },

            addstreamSelf(stream) {
                this.$refs.SpeechDetectionModal?.handleOutStream && this.$refs.SpeechDetectionModal.handleOutStream(stream);
            },

            closeDetectionModal() {
                this.detectionModalVisible = false;
            },

            onDetectionModalSelectChange(deviceId) {
                this.$refs.YTalkRemoteAudio && this.$refs.YTalkRemoteAudio.setSinkId(deviceId);
            },

            setMediaConstraints(deviceId) {
                if (!deviceId) {
                    this.mediaConstraintsSelf = null;
                } else if (deviceId === 'default') {
                    this.mediaConstraintsSelf = null;
                } else {
                    this.mediaConstraintsSelf = {
                        audio: {
                            noiseSuppression: true,  // 噪声抑制
                            echoCancellation: true,  // 回声消除
                            highpassFilter: true,    // 高通滤波器
                            deviceId: {exact: deviceId}
                        },
                        video: false
                    };
                }
            },

            onStartSpeechDetection() {
                if (!this.userAgent) {
                    this.$warning({
                        title: '请到通话主页面中进行通话检测!',
                    });

                    return;
                }

                this.detectionModalVisible = true;
            },

        }
    };
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .ytalk {
        position: fixed;
        bottom: 25px;
        right: 40px;
        z-index: 900;

        &-container {
            background-color: white;
            padding: 15px 20px;
            border-radius: 10px;
            box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.1);

            .ytalk-call {
                display: flex;
                align-items: center;

                > *:not(:last-child) {
                    margin-right: 5px;
                }

                input {
                    width: 130px;
                }
            }

            .ytalk-tip {
                font-size: 0.8em;
                color: #aaa;
            }

            .ytalk-call-connected {
                .icon {
                    width: 20px;

                    &.keyboard {
                        width: 18px;
                    }
                }
            }

            .ytalk-audio {
                display: none;
            }
        }
    }
</style>
