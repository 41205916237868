/*
 * @Author: qxy
 * @Date: 2020-06-19 13:35:43
 * @Last Modified by: qxy
 * @Last Modified time: 2020-06-19 14:29:00
 */

/* eslint-disable max-len */

export default {
    name: 'custom-plus',
    theme: 'outline',
    icon: {
        tag: 'svg',
        attrs: {viewBox: '0 0 1024 1024', x: '0', y: '0'},
        children: [
            {
                tag: 'path',
                attrs: {
                    d: 'M404.82 912V619.18H112V404.82h292.82V112h214.36v292.82H912v214.36H619.18V912z'
                }
            }
        ]
    }
};
