/*
 * @Author: qxy
 * @Date: 2020-04-20 16:16:52
 * @Last Modified by: shaotanliang@fintopia.tech
 * @Last Modified time: 2024-05-28 16:09:33
 */

/**
 *
 * @param pDigitNum 百分数保留几位小数
 */
const decimalToPercent = (pDigitNum = 2) => ({
    // 0.101 => 10.1%
    filter: x => x && (x = +x) && (Math.round(x * (10 ** (pDigitNum + 2))) / (10 ** pDigitNum)),

    // 10.11% => 0.101
    reverseFilter: x => x && (x = +x) && (Math.round(x * (10 ** pDigitNum)) / (10 ** (pDigitNum + 2)))
});

export default (input, filterParams) => ((input !== null && input === +input && !isNaN(input))
    ? `${decimalToPercent(filterParams?.digit).filter(input).toFixed(filterParams?.digit ?? 2)}%` : '/');
