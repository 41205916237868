<!-- @author panezhang -->
<!-- @email panezhang@yangqianguan.com -->
<!-- @date 2018-07-29 23:09:19.787 -->
<!-- @desc generated by yqg-cli@0.3.1 -->

<!-- eslint-disable vue/no-mutating-props -->
<template>
    <div class="typed-property">
        <div
            :style="style"
            class="card item"
        >
            <div class="title">
                <div class="left">
                    <span class="key">
                        {{ propertyKey }}
                    </span>
                    <span class="type">
                        [{{ typeText }}]
                    </span>
                </div>

                <div class="right">
                    <button
                        v-if="hasChild(type)"
                        v-b-tooltip="createToolTip"
                        class="simple sm"
                        @click="$emit('add', propertyKey, property)"
                    >
                        <i class="b-icon-create" />
                    </button>
                    <button
                        v-b-tooltip="'编辑'"
                        class="simple sm"
                        @click="$emit('edit', prefix, property)"
                    >
                        <i class="b-icon-edit" />
                    </button>
                    <button
                        v-b-tooltip="'复制节点'"
                        class="simple sm"
                        @click="$emit('clone', prefix, property)"
                    >
                        <i class="b-icon-copy" />
                    </button>
                    <button
                        v-b-tooltip="'删除'"
                        class="simple sm"
                        @click="$emit('remove', propertyKey, property)"
                    >
                        <i class="b-icon-trash" />
                    </button>
                    <button class="simple sm sortable-handle">
                        <i class="b-icon-adjust" />
                    </button>
                    <button
                        class="simple sm"
                        @click="collapse = !collapse"
                    >
                        <i :class="{'b-icon-collapse-down': collapse, 'b-icon-collapse-up': !collapse}" />
                    </button>
                </div>
            </div>

            <div
                v-show="!collapse"
                class="value"
            >
                <span v-if="type === TYPE.TEXT">
                    {{ value }}
                </span>

                <img
                    v-else-if="type === TYPE.IMAGE"
                    :src="value"
                    :alt="value"
                >

                <div v-else-if="type === TYPE.IMAGE_LIST">
                    <img
                        v-for="(imgSrc, index) in value"
                        :key="index"
                        :src="imgSrc"
                        :alt="imgSrc"
                    >
                </div>

                <yqg-marked
                    v-else-if="type === TYPE.MARKDOWN"
                    :md="value"
                />

                <yqg-code-preview
                    v-else-if="type === TYPE.JSON_RAW"
                    :code="JSON.stringify(value, null, 2)"
                />

                <pre v-else-if="displayPreview">{{ value }}</pre>
            </div>
        </div>

        <div
            v-if="hasChild(type)"
            v-show="!collapse"
            class="item-children"
        >
            <yqg-json-typed-editor
                v-model="property.value"
                :prefix="propertyKey"
                :level="level + 1"
                :type="type"
                :bucket="bucket"
                :dir="dir"
                :root-dir="rootDir"
                @change="$emit('change')"
            />
        </div>
    </div>
</template>

<script>
import TYPE from './constant/property-type';
import TYPE_MAP from './constant/property-type-map';

import bus, {EXPAND_ALL, COLLAPSE_ALL} from './event-bus';
import {isValidType} from './lib';

const CREATE_TOOLTIP = {
    [TYPE.JSON_TYPED]: '添加对象属性',
    [TYPE.LIST]: '添加数组元素'
};

let defaultCollapse = false;

export default {
    name: 'TypedProperty',

    props: {
        property: {
            type: Object,
            required: true
        },

        prefix: {
            type: String,
            required: true
        },

        level: {
            type: Number,
            required: true
        },

        index: {
            type: Number,
            required: true
        },

        bucket: null,

        dir: null,

        rootDir: null
    },

    data() {
        return {
            TYPE,
            collapse: defaultCollapse
        };
    },

    computed: {
        type() {
            return this.property.type;
        },

        typeText() {
            const {property: {type}} = this;

            return TYPE_MAP[type];
        },

        createToolTip() {
            const {type} = this;

            return CREATE_TOOLTIP[type];
        },

        value() {
            return this.property.value;
        },

        propertyKey() {
            const {property: {key}, prefix, index} = this;
            const actualKey = key ? `${prefix ? `.${key}` : key}` : `[${index}]`;

            return prefix ? `${prefix}${actualKey}` : actualKey;
        },

        style() {
            const {level} = this;

            return {
                marginLeft: `${level * 30}px`
            };
        },

        displayPreview() {
            return ![TYPE.JSON_TYPED, TYPE.LIST, TYPE.FILE_LIST].includes(this.type);
        }
    },

    created() {
        bus.$on(EXPAND_ALL, this.onExpend);
        bus.$on(COLLAPSE_ALL, this.onCollapse);
    },

    destroyed() {
        bus.$off(EXPAND_ALL, this.onExpend);
        bus.$off(COLLAPSE_ALL, this.onCollapse);
    },

    methods: {
        onExpend() {
            this.collapse = false;
            defaultCollapse = false;
        },

        onCollapse() {
            this.collapse = true;
            defaultCollapse = true;
        },

        hasChild: isValidType
    }
};

</script>

<style lang="scss" rel="stylesheet/scss">

    @import "~base-ui/src/style/variables";

    .typed-property {
        .item {
            .title {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            .key {
                font-weight: bolder;
                color: $primary-dark;
            }

            .type {
                font-size: $form-label-font-size;
                color: $primary-lighter;
            }

            .value {
                margin-top: 15px;

                img {
                    display: inline-block;
                    max-width: 200px;
                    margin: 0 15px 15px 0;
                    vertical-align: middle;
                    border: 1px dashed $gray-light;
                }
            }

            .sortable-handle {
                cursor: move;
            }
        }
    }

</style>
