var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "collection-flows-table" },
    [
      _vm.title
        ? _c("p", { staticClass: "yqg-island-title" }, [
            _vm._v("\n        " + _vm._s(_vm.title) + "\n    "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.formatCollectionFlows.length
        ? _c("yqg-simple-table", {
            attrs: {
              options: _vm.TableOptions,
              pagination: false,
              records: _vm.formatCollectionFlows,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "result",
                  fn: function (ref) {
                    var timePromised = ref.record.timePromised
                    var value = ref.value
                    var def = ref.def
                    return [
                      _c(
                        "yqg-table-cell",
                        { attrs: { def: def, value: value } },
                        [
                          timePromised
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("moment")(timePromised, "YYYY-MM-DD")
                                  )
                                ),
                              ])
                            : _vm._e(),
                        ]
                      ),
                    ]
                  },
                },
              ],
              null,
              false,
              1911625371
            ),
          })
        : _c("p", [_vm._v("\n        暂无数据\n    ")]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }