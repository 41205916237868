<!-- @author xinzhong -->
<!-- @email xinzhong@yangqianguan.com -->
<!-- @date 2019-12-24 14:51:56.908 -->
<!-- @desc generated by yqg-cli@1.1.1 -->

<template>
    <div class="yqg-stage-tag">
        <slot class="slot-content" />
        <div
            class="tag"
            :style="{'background-color': stageConfig.color}"
        >
            {{ stageConfig.name }}
        </div>
    </div>
</template>

<script type="text/babel">

    import StageType from 'yqg-common/constant/StageType';
    import Config from 'yqg-common/vue/constant/CommonConfig';

    export default {
        name: 'YqgStageTag',

        data() {
            return {
                curStage: Config.stage,
                stageMap: {
                    [StageType.dev]: {name: '开发环境', color: '#81d2e7'},
                    [StageType.test]: {name: '测试环境', color: '#6cbe8b'},
                    [StageType.feat]: {name: 'feat环境', color: '#f8956c'},
                    [StageType.prod]: {name: '生产环境', color: '#ec524d'}
                }
            };
        },

        computed: {
            stageConfig() {
                const {curStage, stageMap} = this;
                const curConfig = stageMap[curStage];

                if (curConfig) return curConfig;
                if (/prod/i.test(curStage)) return {...stageMap[StageType.prod], name: curStage};
                if (/test/i.test(curStage)) return {...stageMap[StageType.test], name: curStage};

                return {name: curStage, color: '#81d2e7'};
            }
        }
    };

</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .yqg-stage-tag {
        display: flex;
        align-items: center;

        .tag {
            height: 22px;
            padding: 0 12px;
            margin-left: 20px;
            color: #fff;
            border-radius: 3px;
            font-size: 12px;
            line-height: 22px;
        }
    }

</style>
