/*
 * @Author: yefenghan 
 * @Date: 2024-05-22 10:35:57 
 * @Last Modified by: yefenghan
 * @Last Modified time: 2024-05-22 15:51:57
 */
import RenewalResource from 'collection-admin-web/common/resource/case/renewal';

const RENEWAL_TIME = 1 * 60 * 1000;

class RenewalManager {

    constructor() {
        this.timer = null;
    }

    startRenewal() {
        this.stopRenewal();

        this.timer = setInterval(() => {
            RenewalResource.renewal();
        }, RENEWAL_TIME);
    }

    stopRenewal() {
        this.timer && clearInterval(this.timer);
        this.timer = null;
    }

}

export default new RenewalManager();
