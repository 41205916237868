<!-- @Author: xymo -->
<!-- @Date: 2023-07-20 -->
<!-- @Last Modified by: xymo -->
<!-- @Last Modified time: 2023-07-20 18:51:28 -->

<template>
    <a-button-group class="sort-button-group">
        <a-button
            v-for="(btn, idx) in btnsConfig"
            :key="idx"
            :type="btn.status === 'off' ? 'default' : 'primary'"
            :class="{
                active: btn.status !== 'off'
            }"
            class="button"
            @click="handleClick(idx)"
        >
            <span>{{ btn.text }}</span>
            <a-icon
                :type="btn.status === 'ascend' ? 'arrow-up' : 'arrow-down'"
                :style="{
                    color: btn.status === 'off' ? '#1890ff' : ''
                }"
            />
        </a-button>
    </a-button-group>
</template>

<script>

import event from 'collection-admin-web/common/mixin/event';

const statusConfig = ['descend', 'ascend'];

const btnsConfig = [{
    text: '推荐',
    statusConfig,
    status: 'off',
    field: 'DEFAULT'
}, {
    text: '收',
    statusConfig,
    status: 'off',
    field: 'COLLECTED_COUNT'
}, {
    text: '创',
    statusConfig,
    status: 'off',
    field: 'CREATED_TIME'
}, {
    text: '更',
    statusConfig,
    status: 'off',
    field: 'UPDATED_TIME'
}];

export default {
    name: 'SortButtonGroup',

    mixins: [event],

    data() {
        return {
            btnsConfig
        };
    },

    mounted() {
        this.handleClick(0);
    },

    methods: {
        handleClick(key) {
            this.btnsConfig = this.btnsConfig.map((val, index) => {
                if (index === key) {
                    const {statusConfig: config, status} = val;
                    const res = {
                        ...val,
                        status: config[(config.indexOf(status) + 1) % config.length]
                    };

                    this.uploadEvent({
                        key: 'KNOWLEDGE',
                        elementContent: `btn-sort-query-${val.field}`
                    });

                    this.$emit('change', res);

                    return res;
                }

                return {
                    ...val,
                    status: 'off'
                };
            });
        },

        reset() {
            this.btnsConfig = btnsConfig;
            this.handleClick(0);
        }
    },
};
</script>

<style lang="scss" scoped>
.sort-button-group {
    .button {
        color: #1890ff;

        &.active {
            color: #fff;
        }
    }

    &::v-deep .ant-btn {
        padding: 0 10px;

        .anticon {
            margin-left: 0;
        }
    }
}
</style>
