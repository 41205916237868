/**
 * @author giligili
 * @date 2021-07-21
 * @email zhimingwang@yangqianguan.com
 * @desc 通知类型(header 小铃铛和 案件详情通知提醒)
 */

export default {
    ALARM_CASE: 'ALARM_CASE',
    ALARM_COMPLAINT: 'ALARM_COMPLAINT',
    ALARM_FEEDBACK: 'ALARM_FEEDBACK',
    ALARM_REMIND_CASE: 'ALARM_REMIND_CASE',
    ALARM_USER_LOGIN_INFO: 'ALARM_USER_LOGIN_INFO',
    ALARM_FLOW_RECEIVED: 'ALARM_FLOW_RECEIVED',
    ALARM_FLOW_END: 'ALARM_FLOW_END',
    ALARM_REMOTE_LOGIN: 'ALARM_REMOTE_LOGIN',
    ALARM_AUDIT_ABNORMAL: 'ALARM_AUDIT_ABNORMAL',
    ALARM_ASSIST_CASE: 'ALARM_ASSIST_CASE',
    ALARM_ASSIST_CASE_STOP: 'ALARM_ASSIST_CASE_STOP',
    ALARM_ASSIST_CASE_AGREE: 'ALARM_ASSIST_CASE_AGREE',
    ALARM_ASSIST_CASE_FINISH: 'ALARM_ASSIST_CASE_FINISH',
    ALARM_ASSIST_CASE_REFUSE: 'ALARM_ASSIST_CASE_REFUSE',
    ALARM_ADD_FLOW: 'ALARM_ADD_FLOW',
    ALARM_FEEDBACK_FLOW: 'ALARM_FEEDBACK_FLOW',
    ALARM_REPAY_RECEIPT_RESULT: 'ALARM_REPAY_RECEIPT_RESULT',
    ALARM_MULTI_DIAL_TRANSFER: 'ALARM_MULTI_DIAL_TRANSFER', // 一键多呼转接提醒
    ALARM_TRANSFER_CALL: 'ALARM_TRANSFER_CALL', // 客服转接未接通
    ALARM_DIAL_BACK: 'ALARM_DIAL_BACK', // 回拨未接通
    ALARM_RISK_UPGRADE_ASSIGN: 'ALARM_RISK_UPGRADE_ASSIGN', // 风险升级撤案提醒
    ALARM_SMART_IVR_TRANSFER_MANUAL: 'ALARM_SMART_IVR_TRANSFER_MANUAL', // 机器人转人工未接通提醒
    ALARM_RISK_UPGRADE_ASSIGN_MANUAL_REMAIN: 'ALARM_RISK_UPGRADE_ASSIGN_MANUAL_REMAIN', // 风险升级撤案提醒-允许手动留案
    ALARM_WECHAT_MESSAGE: 'ALARM_WECHAT_MESSAGE',
    ALARM_SENSITIVE_ASSIGN_CASE: 'ALARM_SENSITIVE_ASSIGN_CASE', // 敏感词案件手动分案提醒
    ALARM_URGE_SENSITIVE_ASSIGN_CASE: 'ALARM_URGE_SENSITIVE_ASSIGN_CASE', // 敏感词案件手动分案跟进提醒
    REPAY_FAIL_MESSAGE: 'REPAY_FAIL_MESSAGE', // 还款失败提醒
    BLACK_PRODUCTION_USER_ASSIGN: 'BLACK_PRODUCTION_USER_ASSIGN', // 敏感词撤案提醒
    EXPERT_CASE_ENTRY_MESSAGE: 'EXPERT_CASE_ENTRY_MESSAGE', // 专家组进案提醒
    WORK_STATUS_WARN_DURATION: 'WORK_STATUS_WARN_DURATION', // 工作状态异常持续时间提醒
    ALARM_OA_ADD_ACCOUNT: 'ALARM_OA_ADD_ACCOUNT', // 新增账号提醒
};
