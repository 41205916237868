/**
 * @author giligili
 * @date 2021-07-05
 * @email zhimingwang@yangqianguan.com
 * @desc rate enum
 */

export default [
    {value: 0.5, label: '0.5倍速'},
    {value: 1, label: '1倍速'},
    {value: 1.5, label: '1.5倍速'},
    {value: 2, label: '2倍速'}
];
