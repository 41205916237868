var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.value || _vm.value.length <= _vm.maxLength
    ? _c(
        "div",
        {
          staticClass: "base-text",
          style: { maxWidth: _vm.def.width || _vm.width },
        },
        [_vm._v("\n    " + _vm._s(_vm.value) + "\n")]
      )
    : _c(
        "a-tooltip",
        { attrs: { title: _vm.value, "overlay-style": { maxWidth: "70%" } } },
        [
          _c(
            "div",
            {
              staticClass: "tooltip-text",
              style: { maxWidth: _vm.def.width || _vm.width },
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.maxLength && _vm.value.length > _vm.maxLength
                      ? _vm.value.substring(0, _vm.maxLength)
                      : _vm.value
                  ) +
                  "\n    "
              ),
            ]
          ),
        ]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }