var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", { domProps: { textContent: _vm._s("发券") } }),
      _vm._v(" "),
      _c(
        "a-descriptions",
        [
          _c("a-descriptions-item", { attrs: { label: "剩余待还" } }, [
            _c("span", { staticClass: "yqg-text-danger" }, [
              _vm._v(
                "\n                " +
                  _vm._s(
                    _vm._f("numberCommasFixed2")(
                      _vm.couponInfo.totalRemainAmount || 0
                    )
                  ) +
                  "元\n            "
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("a-descriptions-item", { attrs: { label: "发券上限" } }, [
            _c("span", { staticClass: "yqg-text-danger" }, [
              _vm._v(
                "\n                " +
                  _vm._s(
                    _vm._f("numberCommasFixed2")(
                      _vm.couponInfo.maxCouponAmount || 0
                    )
                  ) +
                  "元\n            "
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("a-descriptions-item", { attrs: { label: "有效期" } }, [
            _c("span", { staticClass: "yqg-text-danger" }, [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.couponInfo.couponExpiredDays) +
                  "天\n            "
              ),
            ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "yqg-atlantis yqg-reduce-rule mb10" }, [
        _vm._v("\n        " + _vm._s(_vm.ReduceRule) + "\n    "),
      ]),
      _vm._v(" "),
      _c("yqg-simple-form", {
        attrs: { options: _vm.FormOptions, values: _vm.editing },
        on: { confirm: _vm.onConfirm, cancel: _vm.dismiss },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }