<!-- @author giligili -->
<!-- @email zhimingwang@fintopia.tech -->
<!-- @date 2023-02-06 12:08:10.216 -->
<!-- @desc generated by yqg-cli@5.9.3 -->

<template>
    <p
        v-if="visible"
        class="agent-status-change-timer"
    >
        <span v-if="time">
            坐席状态将在
            <span class="yqg-text-danger">{{ time }}s</span>
            后变为
            <span class="yqg-text-danger">{{ status }}</span>
        </span>

        <span v-else>
            坐席状态将变为
            <span class="yqg-text-danger">{{ status }}</span>
        </span>
    </p>
</template>

<script type="text/babel">

    export default {
        name: 'AgentStatusChangeTimer',

        props: {
            countdown: {
                type: Number,
                default: 0
            },

            agentStatus: {
                type: String,
                default: 'BUSY'
            }
        },

        data() {
            return {
                startTime: Date.now(),
                time: this.countdown
            };
        },

        computed: {
            status() {
                return this.agentStatus === 'AVAILABLE'
                    ? '空闲'
                    : '忙碌';
            },

            visible() {
                return this.time <= 5;
            }
        },

        mounted() {
            const {startTime, countdown} = this;

            this.timer = setInterval(() => {
                if (this.time <= 0) {
                    clearInterval(this.timer);

                    return;
                }

                this.time = Math.ceil(countdown - (Date.now() - startTime) / 1000);
            }, 1e3);
        },

        beforeDestroy() {
            clearInterval(this.timer);
        }
    };

</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .agent-status-change-timer {
        color: #aaa;
    }

</style>
