/**
 * @author giligili
 * @date 2023-03-14
 * @email zhimingwang@fintopia.tech
 * @desc fields
 */

import EnumAll from 'collection-admin-web/common/constant/enum';

import {getSdTextStaticProps} from 'src/common/constant/fields';
import {genField} from 'src/common/util/tool';

import {StatusEnum} from './enums';

export const term = genField('期数', 'term', 'input');
export const billingDate = genField('应还日期', 'billingDate', 'date');
export const repaidDate = genField('实还日期', 'repaidDate', 'date');
export const status = genField('状态', 'status', 'input', {
    enumType: StatusEnum,
    staticProps: getSdTextStaticProps(val => val === StatusEnum.TYPE.FINISHED)
});
export const isOverdue = genField('是否逾期', 'isOverdue', 'input', {
    enumType: EnumAll.Basic.Boolean,
    staticProps: getSdTextStaticProps(val => val === EnumAll.Basic.Boolean.TYPE.TRUE)
});
export const amount = genField('应还总额', 'amount', 'number', {
    filter: 'numberCommasFixed2'
});
export const repaidAmount = genField('已还总额', 'repaidAmount', 'number', {
    filter: 'numberCommasFixed2'
});
export const remainAmount = genField('待还总额', 'remainAmount', 'number', {
    filter: 'numberCommasFixed2'
});
