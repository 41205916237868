var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import autobind from 'autobind-decorator';
import axios from 'axios';
import axiosUtils from 'axios/lib/utils';
import { getCustomRequest, setUrlParams } from './util';
export { axios, autobind };
export * from './decorator';
export * from './util';
export function create(opts) {
    var res = new Resource();
    res.extend(opts);
    return res;
}
export function assign(opts) {
    var res = new Resource();
    Object.entries(opts).forEach(function (_a) {
        var key = _a[0], value = _a[1];
        if (typeof value === 'function') {
            res[key] = value.bind(res);
            return;
        }
        res[key] = value;
    });
    return res;
}
var Resource = (function () {
    function Resource() {
        var _this = this;
        ['get', 'patch', 'post', 'put', 'delete', 'save', 'savePatch'].forEach(function (method) { return _this[method] = _this[method].bind(_this); });
    }
    Object.defineProperty(Resource.prototype, "computedUrlPrefix", {
        get: function () {
            var urlPrefix = this.urlPrefix;
            if (urlPrefix && !/\/$/.test(urlPrefix)) {
                return "".concat(urlPrefix, "/");
            }
            return urlPrefix;
        },
        enumerable: false,
        configurable: true
    });
    Resource.prototype.extend = function (options) {
        var _this = this;
        if (!options)
            return;
        var url = options.url, urlPrefix = options.urlPrefix, getHost = options.getHost, keepPathVar = options.keepPathVar, config = __rest(options, ["url", "urlPrefix", "getHost", "keepPathVar"]);
        Object.assign(this, {
            url: url,
            urlPrefix: urlPrefix,
            getHost: getHost,
            keepPathVar: !!keepPathVar
        });
        Object.keys(config).forEach(function (key) {
            var requestMappingParam = config[key];
            _this[key] = getCustomRequest(requestMappingParam).bind(_this);
        });
    };
    Resource.prototype.parse = function (url, dataOrConfig, config) {
        var _a = this, _b = _a.getHost, getHost = _b === void 0 ? '' : _b, computedUrlPrefix = _a.computedUrlPrefix;
        var pathname = url;
        if (computedUrlPrefix && (!url || !/^\//.test(url))) {
            pathname = url ? computedUrlPrefix + url : computedUrlPrefix.slice(0, -1);
        }
        var parsedArgs = [dataOrConfig, config].map(function (params) {
            if (axiosUtils.isFormData(params))
                return params;
            return params instanceof Array ? __spreadArray([], params, true) : params && __assign({}, params);
        });
        var parsedUrl = "".concat(getHost && getHost()).concat(setUrlParams.call.apply(setUrlParams, __spreadArray([this, pathname], parsedArgs, false)));
        return __spreadArray([parsedUrl], parsedArgs, true);
    };
    Resource.prototype.get = function (paramsOrConfig) {
        var _a = this.parse(this.url, paramsOrConfig), parsedUrl = _a[0], parsedParamsOrConfig = _a[1];
        return axios.get(parsedUrl, parsedParamsOrConfig);
    };
    Resource.prototype.patch = function (data, config) {
        var _a = this.parse(this.url, data, config), parsedUrl = _a[0], parsedData = _a[1], parsedConfig = _a[2];
        return axios.patch(parsedUrl, parsedData, parsedConfig);
    };
    Resource.prototype.post = function (data, config) {
        var _a = this.parse(this.url, data, config), parsedUrl = _a[0], parsedData = _a[1], parsedConfig = _a[2];
        return axios.post(parsedUrl, parsedData, parsedConfig);
    };
    Resource.prototype.put = function (data, config) {
        var _a = this.parse(this.url, data, config), parsedUrl = _a[0], parsedData = _a[1], parsedConfig = _a[2];
        return axios.put(parsedUrl, parsedData, parsedConfig);
    };
    Resource.prototype.delete = function (paramsOrConfig) {
        var _a = this.parse(this.url, paramsOrConfig), parsedUrl = _a[0], parsedParamsOrConfig = _a[1];
        return axios.delete(parsedUrl, parsedParamsOrConfig);
    };
    Resource.prototype.save = function (data, options) {
        if (options === void 0) { options = { id: 'id' }; }
        return this[data[options.id] ? 'put' : 'post'](data, options);
    };
    Resource.prototype.savePatch = function (data, options) {
        if (options === void 0) { options = { id: 'id' }; }
        return this[data[options.id] ? 'patch' : 'post'](data, options);
    };
    Resource.create = create;
    Resource.assign = assign;
    return Resource;
}());
export default Resource;
