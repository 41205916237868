/*
 * Author: xymo
 * Created Date: 2021-04-27
 * email: xymo@yangqianguan.com
 */

import ValidStatusMap from 'collection-admin-web/common/constant/enum/valid-status-map';
import Admin from 'collection-admin-web/common/resource/admin';
import Area from 'collection-admin-web/common/resource/permission/area';
import Enum from 'collection-admin-web/common/util/enum';

const options = {
    dataBodyField: 'areaList',
    valueField: 'areaId',
    queryOnce: true
};

export const ChildAreaEnum = Enum.query(Area.getChildArea, options);
export const ChildAreaBySortEnum = Enum.query(Area.getChildAreaBySort, options);
export const AdminValidAreaMapEnum = Enum.query(() => Admin.getAreaMap({params: {status: ValidStatusMap.TYPE.VALID}}), {
    queryOnce: true,
    dataBodyField: 'areaMap'
});
export const AreaOutsourcedMap = Enum.query(Area.getCurrentArea, {
    dataBodyField: 'areaList',
    valueField: 'areaId',
    textField: 'outsourced',
    queryOnce: true
});

// 当前机构 + 下级机构(不区分管理员)
export const AreaEnum = Enum.query(Area.getAreaMap, {
    queryOnce: true,
    dataBodyField: 'areaMap'
});

export const AreaTagEnum = Enum.from({
    LAW_COL_LITIGATE: '诉讼机构'
});

// 当前机构 + 下级机构(管理员才会返回下级机构)
export default Enum.query(Area.getCurrentArea, options);

