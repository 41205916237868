var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "speech-detection" },
    [
      _c("a-spin", { attrs: { spinning: _vm.spinning, tip: "Loading..." } }, [
        _c("div", { staticStyle: { "font-size": "14px" } }, [
          _c("div", [_vm._v("检测说明：")]),
          _vm._v(" "),
          _c("div", [
            _vm._v(
              "请尝试说几句话，判断耳机里能否听到你刚才说话的声音。如果能听到，代表麦克风、扬声器、网络没有问题。"
            ),
          ]),
          _vm._v(" "),
          _c("div", [_vm._v("无声解决方案：")]),
          _vm._v(" "),
          _c("div", [
            _vm._v(
              "请切换麦克风或者扬声器设备后重试。如还未解决，请反馈管理员。如果切换设备后能够听到声音，请在电脑设置中调整为对应设备。"
            ),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "align-items": "center",
              "margin-top": "20px",
            },
          },
          [
            _c(
              "span",
              { staticStyle: { width: "80px", "font-size": "14px" } },
              [_vm._v("麦克风")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticStyle: {
                  width: "420px",
                  background: "#eee",
                  height: "20px",
                },
                attrs: { id: "volume-bar" },
              },
              [
                _c("div", {
                  staticStyle: {
                    width: "0",
                    background: "#76c7c0",
                    height: "100%",
                  },
                  attrs: { id: "volume" },
                }),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticStyle: { "margin-top": "10px" } },
          [
            _c("span", { staticStyle: { "font-size": "14px" } }, [
              _vm._v("输入设备名称："),
            ]),
            _vm._v(" "),
            _c(
              "a-select",
              {
                staticStyle: { width: "400px" },
                on: { change: _vm.onAudioInputChange },
                model: {
                  value: _vm.audioInputDeviceId,
                  callback: function ($$v) {
                    _vm.audioInputDeviceId = $$v
                  },
                  expression: "audioInputDeviceId",
                },
              },
              _vm._l(_vm.audioInputDevices, function (device) {
                return _c(
                  "a-select-option",
                  { key: device.deviceId, attrs: { value: device.deviceId } },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(device.label) +
                        "\n                "
                    ),
                  ]
                )
              }),
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "align-items": "center",
              "margin-top": "20px",
            },
          },
          [
            _c(
              "span",
              { staticStyle: { width: "80px", "font-size": "14px" } },
              [_vm._v("扬声器")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticStyle: {
                  width: "420px",
                  background: "#eee",
                  height: "20px",
                },
                attrs: { id: "volume-bar2" },
              },
              [
                _c("div", {
                  staticStyle: {
                    width: "0",
                    background: "#76c7c0",
                    height: "100%",
                  },
                  attrs: { id: "volume2" },
                }),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticStyle: { "margin-top": "10px" } },
          [
            _c("span", { staticStyle: { "font-size": "14px" } }, [
              _vm._v("输出设备名称："),
            ]),
            _vm._v(" "),
            _c(
              "a-select",
              {
                staticStyle: { width: "400px" },
                on: { change: _vm.onAudioOutChange },
                model: {
                  value: _vm.audioOutDevicesId,
                  callback: function ($$v) {
                    _vm.audioOutDevicesId = $$v
                  },
                  expression: "audioOutDevicesId",
                },
              },
              _vm._l(_vm.audioOutDevices, function (device) {
                return _c(
                  "a-select-option",
                  { key: device.deviceId, attrs: { value: device.deviceId } },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(device.label) +
                        "\n                "
                    ),
                  ]
                )
              }),
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticStyle: {
              "margin-top": "20px",
              display: "flex",
              "flex-direction": "row-reverse",
            },
          },
          [
            _c(
              "a-button",
              { attrs: { type: "primary" }, on: { click: _vm.handleCancel } },
              [_vm._v("\n                关闭\n            ")]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }