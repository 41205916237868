var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
const createUuid = () => 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
    .replace(/[xy]/g, (c) => {
    const r = Math.random() * 16 | 0;
    const v = c === 'x' ? r : ((r & 0x3) | 0x8);
    return v.toString(16);
});
const genPromise = () => {
    let resolve = () => { };
    let reject = () => { };
    const promise = new Promise((res, rej) => {
        resolve = res;
        reject = rej;
    });
    return {
        promise,
        resolve,
        reject,
    };
};
class DB {
    constructor(options) {
        this.openDB = () => {
            const { name, tbName, version, onError } = this.options;
            const request = window.indexedDB.open(name, version);
            request.onupgradeneeded = (event) => {
                const db = event.target.result;
                if (!db.objectStoreNames.contains(tbName)) {
                    // 定义主键
                    db.createObjectStore(tbName, { keyPath: '_indexdb_id' }); // eslint-disable-line
                }
                this.db = db;
            };
            request.onsuccess = (event) => {
                const db = event.target.result;
                this.db = db;
                this.type = 'INDEXDB';
                if (this.historyList.length) {
                    this.dbWrite(this.historyList);
                }
            };
            request.onerror = (event) => {
                var _a;
                onError({
                    type: 'OPEN',
                    error: (_a = event.target) === null || _a === void 0 ? void 0 : _a.error,
                });
                this.type = 'MEMORY';
                this.memoryWrite(this.historyList);
            };
        };
        this.dbWrite = (list) => {
            const { tbName, onError } = this.options;
            // 内存中缓存
            this.dbMemoryList.push(...list);
            // 写入 db
            return new Promise((resolve, reject) => {
                const transaction = this.db.transaction(tbName, 'readwrite');
                const objectStore = transaction.objectStore(tbName);
                list.forEach(data => objectStore.add(data));
                transaction.oncomplete = resolve;
                transaction.onerror = (event) => {
                    var _a;
                    onError({
                        type: 'WRITE',
                        list,
                        error: (_a = event.target) === null || _a === void 0 ? void 0 : _a.error,
                    });
                    reject(event);
                };
            });
        };
        this.dbReset = (list) => {
            return new Promise((resolve, reject) => {
                const { tbName, onError } = this.options;
                const transaction = this.db.transaction(tbName, 'readwrite');
                const objectStore = transaction.objectStore(tbName);
                list.forEach((item) => {
                    const { _indexdb_readTimestamp } = item, rest = __rest(item, ["_indexdb_readTimestamp"]); // eslint-disable-line
                    objectStore.put(rest);
                });
                transaction.oncomplete = () => {
                    resolve(true);
                };
                transaction.onerror = (event) => {
                    var _a;
                    onError({
                        type: 'RESET_LOCK',
                        error: (_a = event.target) === null || _a === void 0 ? void 0 : _a.error,
                    });
                    reject(event);
                };
            });
        };
        this.dbDelete = (list) => {
            return new Promise((resolve, reject) => {
                const { tbName, onError } = this.options;
                const transaction = this.db.transaction(tbName, 'readwrite');
                const objectStore = transaction.objectStore(tbName);
                list.forEach((item) => {
                    objectStore.delete(item._indexdb_id); // eslint-disable-line
                });
                transaction.oncomplete = () => {
                    resolve(true);
                };
                transaction.onerror = (event) => {
                    var _a;
                    onError({
                        type: 'DELETE',
                        error: (_a = event.target) === null || _a === void 0 ? void 0 : _a.error,
                    });
                    reject(event);
                };
            });
        };
        this.dbRead = (limit = 10) => {
            const { dataReadTimeout = 20e3, tbName, onError } = this.options;
            return new Promise((resolve1, reject2) => {
                const { promise, resolve, reject } = genPromise();
                if (this.dbMemoryList.length) {
                    const data = this.dbMemoryList.splice(0, limit);
                    promise
                        .then(() => {
                        this.dbDelete(data);
                    }).catch(() => {
                        this.dbMemoryList.push(data);
                    });
                    resolve1({
                        data,
                        reject,
                        resolve,
                    });
                    return;
                }
                let count = 0;
                const results = [];
                promise
                    .then(() => {
                    this.dbDelete(results);
                }).catch(() => {
                    this.dbReset(results);
                });
                const transaction = this.db.transaction(tbName, 'readwrite');
                const objectStore = transaction.objectStore(tbName);
                const cursorRequest = objectStore.openCursor();
                cursorRequest.onsuccess = (event) => {
                    const cursor = event.target.result;
                    const timestamp = new Date().getTime();
                    if (cursor && count < limit) {
                        const record = cursor.value;
                        const valid = !record._indexdb_readTimestamp || ((timestamp - record._indexdb_readTimestamp) >= dataReadTimeout); // eslint-disable-line
                        if (valid) {
                            results.push(record);
                            count += 1;
                            objectStore.put(Object.assign(Object.assign({}, record), { _indexdb_readTimestamp: timestamp }));
                        }
                        cursor.continue();
                    }
                };
                cursorRequest.onerror = (event) => {
                    var _a;
                    reject2(event);
                    onError({
                        type: 'READ_CURSOR',
                        error: (_a = event.target) === null || _a === void 0 ? void 0 : _a.error,
                    });
                };
                transaction.oncomplete = () => {
                    resolve1({
                        data: results,
                        reject,
                        resolve,
                    });
                };
                transaction.onerror = (event) => {
                    var _a;
                    reject2(event);
                    onError({
                        type: 'READ',
                        error: (_a = event.target) === null || _a === void 0 ? void 0 : _a.error,
                    });
                };
            });
        };
        this.type = 'INIT';
        this.historyList = [];
        this.memoryList = [];
        this.dbMemoryList = [];
        this.options = options;
        this.openDB();
    }
    memoryWrite(list) {
        return new Promise(resolve => {
            this.memoryList.push(...list);
            resolve(true);
        });
    }
    memoryRead(limit = 10) {
        const data = this.memoryList.splice(0, limit);
        return new Promise(resolve1 => {
            const { promise, resolve, reject } = genPromise();
            // 数据还原
            promise.catch(() => {
                this.memoryList.push(data);
            });
            resolve1({
                data,
                reject,
                resolve,
            });
        });
    }
    createPrimaryKey() {
        const timestamp = new Date().getTime();
        return `${createUuid()}_${timestamp}`;
    }
    write(data) {
        const cacheData = Object.assign(Object.assign({}, data), { _indexdb_id: this.createPrimaryKey(), _indexdb_writeTimestamp: new Date().getTime() });
        if (this.type === 'INIT') {
            this.historyList.push(cacheData);
            return;
        }
        if (this.type === 'INDEXDB') {
            this.dbWrite([cacheData]);
            return;
        }
        this.memoryWrite([cacheData]);
    }
    read(limit = 10) {
        return new Promise((resolve, reject) => {
            if (this.type === 'INIT') {
                return reject(false);
            }
            if (this.type === 'INDEXDB') {
                return resolve(this.dbRead(limit));
            }
            resolve(this.memoryRead(limit));
        });
    }
}
export default DB;
