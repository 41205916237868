<!-- @author giligili -->
<!-- @email zhimingwang@yangqianguan.com -->
<!-- @date 2021-07-21 12:51:06.029 -->
<!-- @desc generated by yqg-cli@5.1.1 -->

<template>
    <div class="alarm-case">
        <yqg-case-link
            class="mr10"
            :text="notification.data.name"
            :case-id="notification.data.caseId"
        />

        <span class="mr10">{{ notification.data.timeRemind | dateTime }}</span>

        <span
            v-if="notification.data.extraData.contact"
            class="mr10"
        >
            {{ notification.data.extraData.contact }}
        </span>

        <span class="mr10">
            {{ notification.data.extraData.label || '无标签' }}
        </span>

        <span>{{ notification.data.remark || '无备注' }}</span>
    </div>
</template>

<script type="text/babel">
    export default {
        name: 'AlarmCase',

        props: {
            notification: {
                type: Object,
                default: () => ({})
            }
        }
    };
</script>
