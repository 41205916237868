/**
 * @author moxinyu
 * @date 2019/1/21-下午3:42
 * @file MachineResultMap
 */

import Enum from 'collection-admin-web/common/util/enum';

export default Enum.from({
    FAIL: '违规',
    EMPTY: '录音为空',
    PASS: '合规'
});
