<!-- @author giligili -->
<!-- @email zhimingwang@yangqianguan.com -->
<!-- @date 2021-07-30 16:06:21.872 -->
<!-- @desc generated by yqg-cli@5.1.1 -->

<template>
    <div class="recommend-call-list">
        <div class="container mb20">
            <yqg-simple-form
                :options="$options.FormOptions"
                confirm-label="刷新列表"
                @confirm="onRefresh"
            />

            <div v-if="recommendCaseList.length">
                <contact-list
                    :data-source="recommendCaseList"
                    @change="rowSelection"
                />
            </div>

            <a-empty v-else />
        </div>

        <div class="footer">
            <yqg-layout-multi-call-btn
                v-if="selectContactList.length"
                :data-source="selectContactList"
                :value="selectEncryptedMobileNumberList"
                :dial-type="$options.DialType.RECOMMEND_MULTI"
            />
        </div>
    </div>
</template>

<script type="text/babel">

    import {mapActions, mapGetters} from 'vuex';

    import EnumAll from 'collection-admin-web/common/constant/enum';
    import CallMulti from 'collection-admin-web/common/resource/call/multi';

    import ContactList from '../contact-list';
    import FormOptions from './constant/form-options';

    const DialType = EnumAll.Call.DialType.TYPE;

    export default {
        name: 'RecommendCallList',

        FormOptions,

        DialType,

        components: {
            ContactList
        },

        data() {
            return {
                selectContactList: [],
                selectEncryptedMobileNumberList: []
            };
        },

        computed: {
            ...mapGetters('multiCall', ['recommendCaseList'])
        },

        methods: {
            ...mapActions('multiCall', ['fetchMultiCallList']),

            onRefresh({record}) {
                CallMulti.refreshRecommendList({params: record})
                    .then(({data: {body}}) => {
                        if (body) {
                            this.$emit('refresh', record);

                            this.fetchMultiCallList(record);
                        } else {
                            this.$message.error('刷新列表失败');
                        }
                    })
                    .catch(err => err);
            },

            rowSelection(selectedRecords) {
                this.selectContactList = selectedRecords;
                this.selectEncryptedMobileNumberList = selectedRecords
                    .map(({encryptedMobileNumber}) => encryptedMobileNumber);
            }
        }
    };

</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    @import "collection-admin-web/common/style/mixin.scss";

    .recommend-call-list {
        .container {
            @include scroll-y(calc(100vh - 350px));
        }

        .footer {
            text-align: right;
        }
    }

</style>
