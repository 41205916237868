/**
 * @author giligili
 * @date 2021-06-24
 * @email zhimingwang@yangqianguan.com
 * @desc 挂断方
 */

import DisconnectedUser from '../types/call-disconnected-user';

export default {
    [DisconnectedUser.USER]: '用户',
    [DisconnectedUser.AGENT]: '坐席'
};
