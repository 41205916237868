var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "yqg-antv-popover" },
    [
      _c(
        "a-popover",
        _vm._b(
          {
            ref: "antvPopover",
            scopedSlots: _vm._u(
              [
                {
                  key: "content",
                  fn: function () {
                    return [
                      _c(
                        "popover-content",
                        { on: { "hook:mounted": _vm.popoverContentMounted } },
                        [_vm._t("content")],
                        2
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              true
            ),
          },
          "a-popover",
          _vm.$attrs,
          false
        ),
        [_vm._v(" "), _vm._t("default")],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }