/**
 * @author giligili
 * @date 2021-12-20
 * @email zhimingwang@yangqianguan.com
 * @desc table-options
 */

import * as Fields from './fields';

export default {
    rowKey: 'id',
    colDefs: [
        Fields.fileName,
        Fields.displayContent,
        Fields.timeCreated,
        Fields.op
    ]
};
