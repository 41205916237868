/**
 * @author giligili
 * @date 2021-05-20
 * @email zhimingwang@fintopia.tech
 * @desc 诉讼枚举
 */

import Enum from 'collection-admin-web/common/util/enum';

export const Status = Enum.from({
    INIT: '已委托机构',
    ENDORSED: '已诉前保全',
    HOLDING: '已执保',
    REGISTERED: '已立案',
    FINISHED: '执行完毕',
    MEDIATE: '调解',
    LAWSUIT_ENDORSE: '已诉中保全',
    JUDGED: '已判决',
    EXECUTED: '已执行',
    APPEALED: '客户上诉',
    ACCEPT: '已受理'
});

export const Type = Enum.from({
    ENDORSE: '保全',
    LAWSUIT: '诉讼',
    OTHER: '其它'
});

export const ComplaintType = Enum.from({
    MAJOR: '被诉且有投诉倾向(重大)',
    ESPECIALLY: '被诉且已采取回应手段(特大)'
});
