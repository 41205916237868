/*
 * @Author: yefenghan 
 * @Date: 2024-07-12 15:58:41 
 * @Last Modified by: yefenghan
 * @Last Modified time: 2024-08-18 15:37:53
 */
import {BooleanType} from 'collection-admin-web/common/constant/boolean';
import AgentStatus from 'collection-admin-web/common/mixin/ytalk/constant/agent-status';
import WorkStatusResource from 'collection-admin-web/common/resource/advanced/work-status';
import Ytalk from 'collection-admin-web/common/resource/call/ytalk';
import {EVENT, parseCatch, STATUS} from 'collection-admin-web/common/util/event-log';

const AgentStatusMap = {
    [AgentStatus.BUSY]: '忙碌',
    [AgentStatus.AVAILABLE]: '空闲'
};

export default {
    computed: {
        busyId() {
            const {workStatusMap} = this.shared;

            return this.getWorkStatusIdByName(workStatusMap, AgentStatusMap[AgentStatus.BUSY]);
        },
        availableId() {
            const {workStatusMap} = this.shared;

            return this.getWorkStatusIdByName(workStatusMap, AgentStatusMap[AgentStatus.AVAILABLE]);
        },
        statusNameMap() {
            return {
                [AgentStatus.BUSY]: this.busyId,
                [AgentStatus.AVAILABLE]: this.availableId
            };
        }
    },
    methods: {
        /**
         * 获取工作状态列表 并设置默认忙碌
         */
        fetchWorkStatus() {
            return WorkStatusResource.getAllWorkStatus({params: {}})
                .then(({data: {body: {data = []}}}) => {
                    const workStatusMap = data
                        .map(i => ({
                            ...i,
                            label: i.name,
                            value: i.id
                        }));
                    // 默认忙碌
                    const busy = workStatusMap.find(i => i.name === AgentStatusMap[AgentStatus.BUSY]);

                    Object.assign(this.shared, {workStatusMap, workStatus: busy ? busy.id : ''});
                    this.syncShared();
                });
        },
        /**
         * 通过工作状态id设置工作状态
         */
        setWorkStatusById(id, isAuto = false) {
            const workStatusItem = this.getWorkStatusItemById(id);
            if (!workStatusItem) return;

            // 要切换的工作状态id与当前相同 不做切换
            // if (id === this.shared.workStatus) return;

            Object.assign(this.shared, {workStatusLoading: true});
            this.syncShared();

            // isWiring代表是否可以接线
            const status = workStatusItem.isWiring === BooleanType.TRUE ? AgentStatus.AVAILABLE : AgentStatus.BUSY;

            Ytalk.setWorkStatus({
                workStatusId: id,
                // 用于服务端检查【是否接线】字段是否变更
                isWiring: workStatusItem.isWiring
            })
                .then(({data: {body}}) => {
                    // uploadEventLog from mixin
                    this.uploadEventLog(EVENT.YTALK_CHANGE_WORK_STATUS, {
                        status: body ? STATUS.SUCCESS : STATUS.FAIL,
                        extra: {
                            workStatusId: id,
                            workStatusName: workStatusItem.name,
                            isAuto
                        }
                    });
                    if (body) {
                        this.reset({
                            status,
                            workStatus: id,
                            workStatusLoading: false
                        });
                    } else {
                        Object.assign(this.shared, {workStatusLoading: false});
                        this.syncShared();
                    }
                })
                .catch(err => {
                    this.uploadEventLog(EVENT.YTALK_CHANGE_WORK_STATUS, {
                        status: STATUS.FAIL,
                        reason: parseCatch(err),
                        extra: {
                            workStatusId: id,
                            workStatusName: workStatusItem.name,
                            isAuto
                        }
                    });
                    Object.assign(this.shared, {workStatusLoading: false});
                    this.syncShared();
                });
        },
        /**
         * 通过坐席状态设置工作状态(自动切换)
         * @param name AgentStatus.BUSY | AgentStatus.AVAILABLE
         */
        setWorkStatusByAgentStatus(name) {
            const id = this.statusNameMap[name];

            return this.setWorkStatusById(id, true);
        },
        /**
         * 根据工作状态名获取id
         */
        getWorkStatusIdByName(workStatusMap, name) {
            const item = (workStatusMap || []).find(i => i.name === name);

            return item ? item.id : '';
        },
        /**
         * 根据工作状态id获取工作状态
         */
        getWorkStatusItemById(id) {
            const {workStatusMap} = this.shared;

            return (workStatusMap || []).find(i => i.id === id);
        }
    }
};
