var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return Number.isInteger(_vm.surplusCallCnt)
    ? _c(
        "div",
        { staticClass: "wrap-surplus-call-cnt" },
        [
          _c(
            "a-popover",
            {
              attrs: { placement: _vm.placement },
              scopedSlots: _vm._u(
                [
                  {
                    key: "content",
                    fn: function () {
                      return [
                        _c("div", [
                          _vm._v(
                            "\n                手拨次数：" +
                              _vm._s(_vm.manualCallCnt) +
                              "\n                "
                          ),
                          _c("br"),
                          _vm._v(" "),
                          _vm.ivrCallCnt
                            ? _c("span", [
                                _vm._v(
                                  "机器人拨打次数：" + _vm._s(_vm.ivrCallCnt)
                                ),
                              ])
                            : _vm._e(),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                3430873223
              ),
            },
            [
              _vm._v(" "),
              _vm._t("default", function () {
                return [
                  _c("span", { staticClass: "surplus-call-cnt" }, [
                    _vm._v(
                      "\n                (" +
                        _vm._s(_vm.surplusCallCnt) +
                        ")\n            "
                    ),
                  ]),
                ]
              }),
            ],
            2
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }