/**
 * @author center
 * @date 2020/6/23-下午10:48
 * @file qiniu-config
 */

import CommonApiRouter from './CommonApiRouter';

export default {
    insurance: {
        bucket: 'insurance',
        rootDir: 'static/insurance-upload'
    }
};

export const QiniuGetTokenUrlMap = {
    default: CommonApiRouter.apiWeb.getToken,
    insurance: '/api-web/yqg-upload/insurance/get-token'
};
