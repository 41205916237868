/**
 * @Author: giligili
 * @Date: 2021-07-08
 * @Last Modified by: xymo
 * @Last Modified time: 2023-07-05 16:19:58
 */

import Resource from '@yqg/resource';

const api = {
    urlPrefix: '/admin/operation/col/case/',

    getAssignOptMap: {
        url: 'getAssignOptMap',
        method: 'get'
    },

    listAssignment: {
        url: 'listAssignment',
        method: 'post'
    },

    exportAssignment: {
        url: 'exportAssignment',
        method: 'post'
    }
};

export default Resource.create(api);
