<!-- @Author: giligili -->
<!-- @Date: 2023-03-13 13:24:42 -->
<!-- @Last Modified by: giligili -->
<!-- @Last Modified time: 2023-07-12 17:56:29 -->

<template>
    <div class="edit-mediate-plan-modal">
        <yqg-simple-form
            v-if="isEdit"
            ref="ysf"
            title="协商方案申请"
            :values="defaultCond"
            :options="$options.FormOptions"
            @cancel="onCancel"
            @reset="onReset"
            @confirm="onConfirm"
        >
            <template #orderId>
                <span>{{ orderId }}</span>
                <span v-if="remainAmountVisible">（{{ remainAmount }}元）</span>
            </template>
        </yqg-simple-form>

        <yqg-static-form
            v-else
            title="协商方案查看"
            dynamic-props
            :values="defaultCond"
            :options="$options.FormOptions"
        >
            <template #orderId>
                <span>{{ orderId }}</span>
                <span v-if="remainAmountVisible">（{{ remainAmount }}元）</span>
            </template>
        </yqg-static-form>
    </div>
</template>

<script type="text/babel">

    import {BigNumber} from 'bignumber.js';
    import moment from 'moment';
    import _ from 'underscore';

    import {pickValue} from '@yqg/vue/antd/util/object';

    import {deepClone} from 'yqg-common/core/ToolFunction';

    import EnumAll from 'collection-admin-web/common/constant/enum';

    import bus, {OnRefreshMediateSchemeList, OnAddReduceSuccess} from 'src/common/constant/event-bus';
    import {clearFields} from 'src/common/constant/fields';
    import {validatorAmount} from 'src/common/constant/validator';
    import Mediate from 'src/common/resource/mediate';
    import Trial from 'src/common/resource/trial';
    import {isLawsuitCase} from 'src/common/util/business';
    import Storage from 'src/common/util/local-storage';
    import {isDef} from 'src/common/util/object';

    import FormOptions from '../../constant/edit-mediate-plan-modal-form-options';
    import * as Fields from '../../constant/fields';

    const BooleanType = EnumAll.Basic.Boolean.TYPE;

    const handleValues = (values, props) => {
        const {mediationInstalments, isReduce, ...rest} = values;

        return {
            ...rest,
            ...props,
            isReduce: isReduce || BooleanType.FALSE,
            mediationInstalments: mediationInstalments
                .map(instalment => ({...instalment, orderId: props.orderId}))
        };
    };

    const getMediationInstalments = value => {
        const instalments = Array.isArray(value) ? value : Object.values(value);

        return instalments.sort(({term: termA}, {term: termB}) => termA - termB);
    };

    const getMediationInstalmentKeys = record => Object.keys(record)
        .sort((key1, key2) => record[key1].term - record[key2].term);

    export default {
        name: 'EditMediatePlanModal',

        BooleanType,

        FormOptions,

        props: {
            isEdit: {
                type: Boolean,
                default: true
            },

            caseId: {
                type: String,
                required: true
            },

            orderId: {
                type: String,
                required: true
            },

            mediateInfo: {
                type: Object,
                default: () => ({})
            }
        },

        data() {
            const defaultCond = this.getDefaultCond();

            return {
                isLawsuit: false,
                canReduce: false,
                CaseReduceTypeMap: {},

                defaultCond,

                ...this.getInitValue(defaultCond),

                reduceTips: ''
            };
        },

        computed: {
            remainAmountVisible() {
                const {remainAmount} = this;

                return typeof remainAmount === 'number';
            }
        },

        async mounted() {
            if (this.isEdit) window.addEventListener('beforeunload', this.beforeunload);

            await this.fetchReduceTypeEnum();

            // TODO 溢出金额有问题, 产品直接把这个字段去掉了, 这个字段还关联了一下业务逻辑
            // 这里先默认不展示，先发一个版本
            // 后面单独提一个diff 删除一下相关逻辑，然后在发一下
            // this.fetchCaseArea();

            if (!this.isEdit) return;

            // 获得订单剩余金额，需要注意因为前端有缓存，所以如果金额或者分期变了，要重置表单
            await this.fetchRemainAmount(this.consistencyCheck);

            // 前端缓存，为了校验，需要获得最大减免金额
            if (this.defaultCond.reduceType) this.getMaxReduceAmount(this.defaultCond.reduceType);

            // 是否能减免
            await this.supportReduce();
            const {canReduce} = this;

            if (!canReduce) return;

            // 处理减免
            this.getReduceType();
        },

        beforeDestroy() {
            if (!this.isEdit) return;

            window.removeEventListener('beforeunload', this.beforeunload);

            this.broswerCacheFormValues();
        },

        methods: {
            getInitDefaultCond() {
                const {orderId, caseId} = this;

                return {
                    caseId,
                    orderId,
                    isInstalment: BooleanType.FALSE,
                    isReduce: BooleanType.FALSE,
                    mediationInstalments: []
                };
            },

            getInitValue(defaultCond) {
                const {
                    terms,
                    isInstalment,
                    reduceAmount,
                    remainAmount,
                    overflowAmount,
                    completedTime,
                    mediationInstalments
                } = defaultCond;

                // 这里的数据都是计算分期列表的时候使用的
                // 因为分期列表有点卡，为了减少render次数，不是响应改变的
                // 用防抖优化，在onChange时记录值，然后触发 更新分期列表
                // 所以初始化时也要更新一下这些值
                return {
                    // 后端没有存订单最初的剩余待还金额, 需要用相关金额计算出来
                    // 针对反显
                    // 编辑/前端缓存的时候是从接口获得的
                    remainAmount: (new BigNumber(0))
                        .add(remainAmount || 0)
                        .sub(overflowAmount || 0)
                        .add(reduceAmount || 0)
                        .toNumber(),

                        isInstalment,

                        reduceAmount,
                        overflowAmount,
                        // 计算出的剩余待还 剩余待还 + 溢出金额 - 减免金额
                        remainAmountByCalc: remainAmount,

                        terms,
                        // 计算的期数，不分期的话默认是1
                        termsByCalc: mediationInstalments.length,

                        completedTime
                };
            },

            getDefaultCond() {
                const {isEdit, mediateInfo} = this;

                if (!isEdit) {
                    const {mediationInstalments} = mediateInfo;

                    const isInstalment = (mediationInstalments.length < 2)
                        ? BooleanType.FALSE
                        : BooleanType.TRUE;

                    return {
                        ...this.getInitDefaultCond(),
                        isInstalment,
                        ...mediateInfo
                    };
                }

                const mediation = _.omit(this.getBroswerCacheMediation(), 'fe');

                return {
                    ...this.getInitDefaultCond(),
                    ...mediation
                };
            },

            fetchRemainAmount(consistencyCheck = () => true) {
                const {orderId} = this;

                return Mediate.trialOrderAmount({orderId})
                    .then(({data: {body: {remainAmount, processingLoanInstalmentIds}}}) => {
                        this.remainAmount = remainAmount;
                        this.loanInstalmentIds = processingLoanInstalmentIds;

                        this.calcRemainAmount();

                        if (!consistencyCheck(remainAmount, processingLoanInstalmentIds)) {
                            this.$message.error('订单金额或分期发生变化, 缓存失效, 请重新提交');

                            this.onReset();
                        }
                    })
                    .catch(err => err);
            },

            async fetchReduceTypeEnum() {
                await EnumAll.Trial.ReduceTypeEnum.query();

                this.CaseReduceTypeMap = EnumAll.Trial.ReduceTypeEnum.MAP;
            },

            async fetchCaseArea() {
                this.isLawsuit = await isLawsuitCase(this.caseId);
            },

            supportReduce() {
                const {orderId, loanInstalmentIds} = this;

                if (!this.$app.permissions.CASE_DETAIL_ALL_SETTLE) return;

                return Trial.supportReduceV2({params: {orderId, loanInstalmentIds}})
                    .then(({data: {body: {support, errorMsg}}}) => {
                        if (support !== BooleanType.TRUE) return this.reduceTips = errorMsg;

                        this.canReduce = true;
                    })
                    .catch(err => err);
            },

            getReduceType() {
                const {orderId} = this;

                Mediate.getReduceTypes({params: {orderId}}).then(({data: {body}}) => {
                    if (!body) return;

                    this.CaseReduceTypeMap = _.pick(EnumAll.Trial.ReduceTypeEnum.MAP, ...body);
                });
            },

            reduceTrial(reduceType) {
                const {orderId, loanInstalmentIds} = this;

                if (!reduceType) return;

                Trial.reduceTrialV2({params: {orderId, loanInstalmentIds, reduceType}})
                    .then(({data: {body: {trialAmount, maxReduceAmount = 0}}}) => {
                        this.reduceAmount = trialAmount;
                        this.maxReduceAmount = maxReduceAmount;

                        const message = validatorAmount({
                            maxAmount: Math.min(this.maxReduceAmount, this.remainAmount), label: '减免金额'
                        })(null, this.reduceAmount);
                        const errors = message ? [{message}] : null;
                        this.$refs.ysf.form.setFields({reduceAmount: {value: this.reduceAmount, errors}});

                        this.calcRemainAmount();

                        this.genMediationInstalmentsAmount();
                    })
                    .catch(err => err);
            },

            getMaxReduceAmount(reduceType) {
                const {orderId, loanInstalmentIds} = this;

                if (!reduceType) return;

                Trial.reduceTrial({params: {orderId, loanInstalmentIds, reduceType}})
                    .then(({data: {body: {maxReduceAmount = 0}}}) => {
                        this.maxReduceAmount = maxReduceAmount;
                    })
                    .catch(err => err);
            },

            // 一致性校验, 这里简单拼接一下string -_-
            consistencyCheck(amount, ids) {
                const {fe} = this.getBroswerCacheMediation();

                if (!fe) return true;

                const {sha} = fe;

                return sha === [amount, ...ids].join('');
            },

            // 计算剩余还款金额
            calcRemainAmount() {
                // catch bigNumber 'number type has more than 15 significant digits'
                try {
                    const {overflowAmount, reduceAmount, remainAmount} = this;

                    const amount = (new BigNumber(+remainAmount || 0))
                            .add(+overflowAmount || 0)
                            .sub(+reduceAmount || 0)
                            .toNumber();

                    this.remainAmountByCalc = amount;
                    this.$refs.ysf.form.setFieldsValue({[Fields.remainAmount.field]: amount});
                } catch (err) {
                    // ignore
                }
            },

            // 计算每期的应还日期
            calcMediationInstalmentsBillingDate(endTime) {
                const {termsByCalc} = this;

                if (!termsByCalc) return [];

                const startTime = moment().endOf('day');
                const dayStamp = 24 * 60 * 60 * 1000;
                const days = (endTime - startTime) / dayStamp;
                const gap = Math.floor(days / termsByCalc);
                const mod = (days % termsByCalc) * dayStamp;

                return (new Array(termsByCalc))
                    .fill(endTime)
                    .reduce((accu, curr, index) => [index ? (curr - mod - gap * dayStamp * index) : curr, ...accu], []);
            },

            // 计算每期的应还金额
            calcMediationInstalmentsAmount(remainAmount) {
                // catch bigNumber 'number type has more than 15 significant digits'
                try {
                    const {termsByCalc} = this;

                    if (!termsByCalc) return [];

                    const amountBN = new BigNumber(remainAmount);
                    const gap = amountBN.div(termsByCalc).floor();
                    const mod = amountBN.sub(gap.mul(termsByCalc));

                    return (new Array(termsByCalc))
                        .fill(0)
                        .reduce((accu, curr, index) => [index ? gap : gap.add(mod), ...accu], [])
                        .map(val => val.toNumber());
                } catch (err) {
                    // ignore
                }

                return [];
            },

            // 计算每期的金额，同时校验金额 e.g. 必填...
            calcMediationInstalmentsAmountFieldValues(amounts) {
                const amountFieldValues = amounts.map(amount => {
                    const message = this.getValidatorAmountMessage(amount);

                    return {
                        value: amount,
                        errors: message ? [{message}] : null,
                        touched: true
                    };
                });

                return {amountFieldValues};
            },

            // 计算每期应还日期, 同时校验日期是否正确，比如第一期的时间超过了最后一期。。。
            calcMediationInstalmentsBillingDateFieldValues(billingDates, rowValues) {
                return billingDates.map((billingDate, idx) => {
                    const message = this.getValidatorBillingDateMessage(billingDate, rowValues.slice(idx + 1));

                    return {
                        value: billingDate,
                        errors: message ? [{message}] : null,
                        touched: true
                    };
                });
            },

            // 期数，是否分期，最终还款时间改变时计算一下分期列表
            // 如果涉及到期数的改变，重新计算金额。反之，只改变应还日期就好了
            genMediationInstalments: _.debounce(function () {
                const mediationInstalmentsIns = this.$refs.ysf?.$refs?.mediationInstalments;
                const {isInstalment, terms, completedTime} = this;

                const termsByCalc = isInstalment === BooleanType.TRUE
                    ? terms
                    : 1;
                this.termsByCalc = termsByCalc;

                if (!Number.isInteger(termsByCalc) || termsByCalc < 1 || termsByCalc > 10 || !completedTime) return;

                const billingDates = this.calcMediationInstalmentsBillingDate(completedTime);
                const mediationInstalments = (new Array(termsByCalc))
                    .fill(0)
                    .map((_, idx) => ({
                        term: idx + 1,
                        billingDate: billingDates[idx]
                    }));

                const amounts = this.calcMediationInstalmentsAmount(this.remainAmountByCalc);

                if (!mediationInstalmentsIns) {
                    mediationInstalments.forEach((instalment, idx) => {
                        instalment.amount = amounts[idx];
                    });

                    this.defaultCond = {...this.defaultCond, mediationInstalments};

                    return;
                }

                const {record} = mediationInstalmentsIns.getFormCtx();
                if (Object.keys(record).length !== this.termByCalc) {
                    mediationInstalments.forEach((instalment, idx) => {
                        instalment.amount = amounts[idx];
                    });
                } else {
                    const instalments = getMediationInstalments(record);
                    const amounts = instalments.map(({amount}) => amount);
                    mediationInstalments.forEach((instalment, idx) => {
                        instalment.amount = amounts[idx];
                    });
                }

                this.$refs.ysf.form.setFieldsValue({mediationInstalments});
            }, 300),

            // 剩余待还改变时计算一下分期列表内每期的应还金额和剩余待还
            genMediationInstalmentsAmount: _.debounce(function() {
                const mediationInstalmentsIns = this.$refs.ysf?.$refs?.mediationInstalments;

                if (!mediationInstalmentsIns) return;

                const {record} = mediationInstalmentsIns.getFormCtx();
                const instalments = getMediationInstalments(record);

                if (!instalments.length) return;

                const {remainAmountByCalc} = this;
                const amounts = this.calcMediationInstalmentsAmount(remainAmountByCalc);

                this.$refs.ysf.form.setFieldsValue({
                    mediationInstalments: instalments.map((instalment, idx) => ({
                        ...instalment,
                        amount: amounts[idx]
                    }))
                });
            }, 300),

            getValidatorBillingDateMessage(value, values) {
                if (!isDef(value)) return '必填';

                const termString = values
                    .filter(rowValue => pickValue(rowValue, Fields.billingDate.field))
                    .filter(rowValue => pickValue(rowValue, Fields.billingDate.field) < value)
                    .map(rowValue => pickValue(rowValue, Fields.term.field))
                    .join(', ');

                return termString ? `不应该大于${termString}期的${Fields.billingDate.label}` : '';
            },

            getValidatorAmountMessage(value) {
                if (!isDef(value)) return '必填';

                return value > 0 ? '' : '应还金额应该大于0';
            },

            validatorBillingDate({value, rowKey, ysf}) {
                const {record} = ysf.getFormCtx();

                const idx = getMediationInstalmentKeys(record)
                    .findIndex(key => key === rowKey);
                const values = getMediationInstalments(record)
                    .slice(idx + 1);

                return this.getValidatorBillingDateMessage(value, values);
            },

            validatorAmount({value}) {
                return this.getValidatorAmountMessage(value);
            },

            onIsInstalmentChange(formCtx) {
                this.isInstalment = formCtx.value;

                clearFields([Fields.completedTime.field, Fields.terms.field])(formCtx);

                this.onTermsChange(null);
                this.onCompletedTimeChange({...formCtx, value: undefined});
            },

            onTermsChange(terms) {
                this.terms = terms;

                this.genMediationInstalments();
            },

            onCompletedTimeChange({value, record}) {
                if (record.isReduce === BooleanType.TRUE) {
                    if (value !== this.completedTime) {
                        this.$message.info('减免失效日期已改变, 请注意!');
                    }

                    this.$refs.ysf.form.setFieldsValue({[Fields.reduceExpiredTime.field]: value});
                }

                this.completedTime = value;

                this.genMediationInstalments();
            },

            onReduceTypeChange(value) {
                this.reduceTrial(value);
            },

            onOverflowAmountChange({value}) {
                this.overflowAmount = value;

                this.calcRemainAmount();

                this.genMediationInstalmentsAmount();
            },

            onIsReduceChange(formCtx) {
                const {value, record} = formCtx;

                if (value === BooleanType.TRUE) {
                    this.$refs.ysf.form.setFieldsValue({
                        [Fields.reduceExpiredTime.field]: pickValue(record, Fields.completedTime.field)
                    });
                }

                clearFields([
                    Fields.reduceType.field,
                    Fields.reduceAmount.field,
                    Fields.imageUrls.field
                ])(formCtx);

                this.onReduceAmountChange(null);
            },

            onReduceAmountChange(reduceAmount) {
                this.reduceAmount = reduceAmount;

                this.calcRemainAmount();

                this.genMediationInstalmentsAmount();
            },

            // 分期改变时计算一下每个表单的值，日期是为了校验，还有计算当前分期的剩余还款金额
            onMediateInstalmentsChange: _.debounce(function ({value, def: {field}, rowKey, record}) {
                const form = this.$refs.ysf.$refs.mediationInstalments.form;

                // value 还没有负载到 record 上
                const nextRecord = deepClone(record);
                nextRecord[rowKey][field] = value;

                const rowKeys = getMediationInstalmentKeys(nextRecord);
                const rowValues = getMediationInstalments(nextRecord);

                // 计算金额
                const amounts = rowValues.map(({amount}) => amount);
                const {amountFieldValues} = this.calcMediationInstalmentsAmountFieldValues(amounts);

                // 计算日期
                const billingDates = rowValues.map(({billingDate}) => billingDate);
                const billingDateFieldValues = this.calcMediationInstalmentsBillingDateFieldValues(
                    billingDates,
                    rowValues
                );

                form.setFields(
                    rowKeys.reduce((accu, curr, idx) => ({
                        ...accu,
                        [curr]: {
                            amount: amountFieldValues[idx],
                            billingDate: billingDateFieldValues[idx]
                        }
                    }), {})
                );
            }, 300),

            onReset() {
                this.defaultCond = this.getInitDefaultCond();

                Object.assign(this, this.getInitValue(this.defaultCond));

                this.fetchRemainAmount();

                this.clearBroswerCache();
            },

            onCancel() {
                this.$emit('dismiss');
            },

            onConfirm({values}) {
                const {caseId, orderId} = this;

                const postValues = handleValues(values, {caseId, orderId});
                postValues.mediationInstalments = getMediationInstalments(postValues.mediationInstalments);

                Mediate.createMediationScheme(postValues)
                    .then(({data: {body}}) => {
                        if (!body) return this.$message.error('协商方案申请失败!');

                        this.cacheLock = true;
                        this.clearBroswerCache();

                        this.$message.success('协商方案申请成功!');

                        bus.$emit(OnRefreshMediateSchemeList, {orderNumber: orderId});
                        bus.$emit(OnAddReduceSuccess);

                        this.$emit('close');
                    })
                    .catch(err => err);
            },

            getBroswerCacheMediation() {
                const {caseId, orderId} = this;

                const key = `.${caseId}-${orderId}`;
                const mediations = Storage.getMediationSchemes({});

                return mediations[key] || {};
            },

            clearBroswerCache() {
                const {caseId, orderId} = this;
                const key = `.${caseId}-${orderId}`;

                const mediationSchemes = Storage.getMediationSchemes({});

                Storage.setMediationSchemes(_.omit(mediationSchemes, key));
            },

            broswerCacheFormValues() {
                if (!this.isEdit) return;
                if (this.cacheLock) return;

                const {values} = this.$refs.ysf.getFormCtx();
                const mediationInstalments = this.$refs.ysf.$refs.mediationInstalments
                    ? getMediationInstalments(this.$refs.ysf.$refs.mediationInstalments.getFormCtx().values)
                        .map(value => _.omit(value, 'feID'))
                    : [];

                const {caseId, orderId} = this;

                const key = `.${caseId}-${orderId}`;
                const obj = {
                    [key]: {
                        ...deepClone(values),
                        mediationInstalments,
                        fe: {sha: [this.remainAmount, ...this.loanInstalmentIds].join('')}
                    }
                };

                // 先把当前缓存的数据删除，然后在添加到缓存
                // 这是为了当超出限制时，删除的是最久的数据
                const mediationSchemes = {..._.omit(Storage.getMediationSchemes({}), key), ...obj};
                Object.keys(mediationSchemes)
                    .reverse()
                    .forEach((key, idx) => {
                        if ((idx + 1) > 10) {
                            delete mediationSchemes[key];
                        }
                    });

                Storage.setMediationSchemes(mediationSchemes);
            },

            beforeunload(event) {
                const ev = event || window.event;

                ev.preventDefault();
                ev.returnValue = '';

                this.broswerCacheFormValues();
            }
        }
    };

</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .edit-mediate-plan-modal {
        ::v-deep .ant-calendar-picker {
            td {
                padding: 0;
            }
        }

        ::v-deep .yqg-simple-table {
            margin-top: 0;
        }

        ::v-deep .has-error {
            .yqg-simple-table {
                .ant-table-body {
                    border: 1px solid #f5222d;

                    .ant-input {
                        border-color: #d9d9d9;

                        &:hover {
                            border-color: #d9d9d9;
                        }
                    }

                    .ant-input-number {
                        border-color: #d9d9d9;
                        box-shadow: none;

                        &:hover {
                            border-color: #d9d9d9;
                        }
                    }

                    .has-error {
                        .ant-input {
                            border-color: #f5222d;

                            &:hover {
                                border-color: #f5222d;
                            }
                        }

                        .ant-input-number {
                            border-color: #f5222d;

                            &:hover {
                                border-color: #f5222d;
                            }
                        }
                    }
                }
            }
        }
    }

</style>
