<!-- @Author: Kyles Light -->
<!-- @Date: 2018-03-14 12:30:52.850 -->
<!-- @Last Modified by: giligili -->
<!-- @Last Modified time: 2024-04-26 19:09:09 -->

<template>
    <div
        v-b-click-outside="closeMenu"
        class="yqg-nav-popover user-icon-wrap"
    >
        <div v-if="showAvatar && workCardUrl">
            <img
                :src="workCardUrl"
                class="work-card-avatar"
                @click="changeMenuOpen"
            >
        </div>

        <i
            v-else
            class="b-icon-user"
            @click="changeMenuOpen"
        />

        <ul
            v-if="isMenuOpen"
            class="card drop-down-menu"
        >
            <div @click="closeMenu">
                <slot />
            </div>
        </ul>
    </div>
</template>

<script type="text/babel">
import workCard from 'yqg-common/vue/mixin/work-card';

export default {
    name: 'YqgNavPopover',

    mixins: [workCard],

    props: {
        showAvatar: {
            type: Boolean,
            default: false
        },

        hideOaAvatar: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            isMenuOpen: false
        };
    },

    methods: {
        changeMenuOpen() {
            const vm = this;
            vm.isMenuOpen = !vm.isMenuOpen;
        },

        closeMenu() {
            const vm = this;
            vm.isMenuOpen = false;
        }
    }
};

</script>
<style lang="scss" rel="stylesheet/scss" scoped>
.work-card-avatar {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    cursor: pointer;
}
</style>
