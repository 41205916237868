/**
* @author huayizhang
* @date 2019/6/18-11:02
* @file chart-events.js
*/

export default [
    'axisareaselected',
    'brush',
    'brushselected',
    'click',
    'datarangeselected',
    'dataviewchanged',
    'datazoom',
    'dblclick',
    'focusnodeadjacency',
    'geoselectchanged',
    'geoselected',
    'geounselected',
    'globalout',
    'legendselectchanged',
    'legendselected',
    'legendunselected',
    'magictypechanged',
    'mapselectchanged',
    'mapselected',
    'mapunselected',
    'mousedown',
    'mouseout',
    'mouseover',
    'mouseup',
    'pieselectchanged',
    'pieselected',
    'pieunselected',
    'restore',
    'timelinechanged',
    'timelineplaychanged',
    'unfocusnodeadjacency',
    'updateAxisPointer'
];
