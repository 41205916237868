<!-- @author giligili -->
<!-- @email zhimingwang@yangqianguan.com -->
<!-- @date 2021-06-23 14:47:52.217 -->
<!-- @desc generated by yqg-cli@4.0.7 -->

<!--
    对yqg-simple-table 使用的def-value 优化

    1. enumType 不存在的 text 使用 / 代替, 即: / -> value, enumType: {[value]: [text]}
-->

<template>
    <div class="yqg-table-cell">
        <span v-if="enumType && !enumMap[value]">/</span>

        <span v-else>
            <def-value
                v-bind="$vnode.data.props"
                :def="def"
                :value="value"
                v-on="$listeners"
            />

            <slot />
        </span>
    </div>
</template>

<script type="text/babel">

    export default {
        name: 'YqgTableCell',

        props: {
            def: {
                type: Object,
                required: true
            },
            value: {
                type: [String, Number, Array, Boolean, Object],
                default: ''
            }
        },

        data() {
            return {
                enumMap: {},
                enumType: null
            };
        },

        async mounted() {
            const {enumType} = this.def;

            if (enumType) {
                this.enumType = enumType;

                const {QUERY_PROMISE, query} = enumType;

                const queryPromise = QUERY_PROMISE || (query && query());

                await queryPromise;

                this.enumMap = this.enumType.MAP;
            }
        }
    };

</script>
