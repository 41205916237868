var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "header-knowledge" },
    [
      _c("a-button", {
        attrs: { type: "link", icon: "book" },
        on: { click: _vm.showDrawer },
      }),
      _vm._v(" "),
      _c(
        "a-drawer",
        {
          attrs: {
            title: "知识库",
            placement: "right",
            width: "60%",
            visible: _vm.visible,
            "z-index": 900,
            "mask-closable": true,
          },
          on: { close: _vm.onClose },
        },
        [_c("knowledge-application")],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }