/*
 * Author: xymo
 * Created Date: 2022-12-23
 * email: xymo@yangqianguan.com
 */

import Resource from '@yqg/resource';

const api = {
    urlPrefix: 'admin/operation/col/feedback/',

    list: {
        url: 'listRelativeFlows',
        method: 'post'
    },

    export: {
        url: 'exportRelativeFlows',
        method: 'post'
    },

    listFeedbackType: {
        url: 'listFeedbackType',
        method: 'get'
    }
};

export default Resource.create(api);
