<!-- @Author: giligili -->
<!-- @Date: 2021-07-27 18:26:40.372 -->
<!-- @Last Modified by: giligili -->
<!-- @Last Modified time: 2023-06-30 16:56:12 -->

<template>
    <div
        v-if="$app.privileges.CASE.DETAIL.LIST.COUPON"
        class="advance-settle"
    >
        <p class="yqg-island-title">
            结清减免

            <a-button
                v-if="!caseFinished"
                size="small"
                class="yqg-btn-warning"
                @click="onSettlementReduceTrial()"
                v-text="'结清减免试算'"
            />
        </p>

        <!-- 未结清案件 -->
        <div
            v-if="!caseFinished && mainVisibility"
            class="yqg-island"
        >
            <div class="mb10">
                <div class="mb10">
                    <a-button
                        v-if="$app.privileges.CASE.DETAIL.SETTLE.IN.ADVANCE && sendCouponVisible"
                        size="small"
                        type="primary"
                        @click="openSendCouponModal()"
                        v-text="'发券'"
                    />

                    <a-button
                        v-if="$app.privileges.CASE.DETAIL.ALL.SETTLE && applyRepayReduceButtonVisibility"
                        size="small"
                        type="primary"
                        @click="openApplyRepayReduceModal()"
                        v-text="'申请减免'"
                    />
                </div>

                <p v-if="tips">
                    不能减免的原因: <span class="yqg-text-danger">{{ tips }}</span>
                </p>
            </div>
        </div>

        <div v-if="records.length">
            <div class="yqg-panel-title">
                减免记录
            </div>

            <yqg-simple-table
                :records="records"
                :options="$options.TableOptions"
            />
        </div>
    </div>
</template>

<script type="text/babel">

    import CaseStatusMap from 'collection-admin-web/app/case/constant/enum/case-status-map';
    import bus, {OnAddReduceSuccess} from 'collection-admin-web/common/constant/event-bus';
    import {CaseLawsuitDetail} from 'collection-admin-web/common/constant/router-name';
    import BooleanType from 'collection-admin-web/common/constant/types/boolean';
    import Trial from 'collection-admin-web/common/resource/trial';
    import {getProcessingLoanInstalmentIds} from 'collection-admin-web/common/util/business';

    import TableOptions from './constant/table-options';
    import ApplyRepayReduceModal from './modal/apply-repay-reduce-modal';
    import SendCouponModal from './modal/send-coupon-modal';

    export default {
        name: 'AdvanceSettle',

        TableOptions,

        props: {
            /** @desc caseId, orderNumber, status */
            orderInfo: {
                type: Object,
                default: () => ({})
            }
        },

        data() {
            return {
                CaseStatusMap,
                isSendCoupunSuccess: false,
                mainVisibility: false,
                applyRepayReduceButtonVisibility: false,
                tips: '',

                records: []
            };
        },

        computed: {
            caseFinished() {
                return this.orderInfo.status === CaseStatusMap.TYPE.FINISHED;
            },

            // 这个功能不用props传入, 这样只在业务组件内就能知道那些功能在那些页面开启, 便于收敛逻辑, 否则还需要全局搜一下
            sendCouponVisible() {
                const {$route} = this;

                return $route.name !== CaseLawsuitDetail;
            }
        },

        mounted() {
            this.list();

            bus.$on(OnAddReduceSuccess, this.list);
        },

        beforeDestroy() {
            bus.$off(OnAddReduceSuccess, this.list);
        },

        methods: {
            async onSettlementReduceTrial() {
                const vm = this;
                const {orderInfo: {caseId, orderNumber}} = vm;

                vm.mainVisibility = true;
                // 校验订单是否可以提前结清接口，可以的话显示申请减免按钮
                if (vm.$app.privileges.CASE.DETAIL.ALL.SETTLE) {
                    const loanInstalmentIds = await getProcessingLoanInstalmentIds(caseId, orderNumber);

                    if (!loanInstalmentIds.length) {
                        this.$message.info('订单可能已经结清, 请刷新页面查看!');

                        return;
                    }

                    const {data: {body}} = await Trial.supportReduce({params: {orderId: orderNumber, loanInstalmentIds}});

                    vm.applyRepayReduceButtonVisibility = (body?.support === BooleanType.TRUE);
                    vm.tips = body?.errorMsg;
                }
            },

            list() {
                if (!this.$app.privileges.CASE.DETAIL.LIST.COUPON) return;

                const {orderInfo: {caseId, orderNumber}} = this;

                Trial.listCaseTrialByOrderNumber({params: {caseId, orderNumber}})
                    .then(({data: {body: {trialList = []}}}) => {
                        this.records = trialList;
                    })
                    .catch(err => err);
            },

            openApplyRepayReduceModal() {
                const vm = this;
                const {orderInfo: {caseId, orderNumber}} = vm;

                vm.$modal.open(ApplyRepayReduceModal, {caseId, orderNumber}).then(vm.list).catch(x => x);
            },

            openSendCouponModal() {
                const vm = this;
                // 多次发券后端接口会报错
                if (vm.isSendCoupunSuccess) {
                    vm.$message.error('你已经发过券了, “奖励券”失效前不允许发放新券');

                    return;
                }

                const {orderInfo: {caseId, orderNumber}} = vm;

                vm.$modal.open(SendCouponModal, {caseId, orderNumber}).then(() => {
                    vm.isSendCoupunSuccess = true;
                    vm.list();
                }).catch(x => x);
            }
        }
    };

</script>
