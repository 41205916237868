<!-- @author xiaodongyu -->
<!-- @email xiaodongyu@yangqianguan.com -->
<!-- @date 2018-10-11 13:08:27.402 -->
<!-- @desc generated by yqg-cli@0.3.6 -->

<template>
    <b-dialog
        :open="visible"
        :close-on-click-outside="false"
        title="上传文件"
        @close="cancel"
    >
        <div class="file-upload-modal-body b-form form-block">
            <b-form-group
                label="文件描述:"
                label-horizontal
            >
                <input
                    v-model="description"
                    type="text"
                >
            </b-form-group>

            <b-form-group label="">
                <yqg-upload
                    v-model="url"
                    auto-upload
                    :bucket="bucket"
                    :root-dir="rootDir"
                    :dir="dir"
                />
            </b-form-group>
        </div>

        <button
            slot="actions"
            class="simple lg"
            @click="cancel()"
        >
            取消
        </button>
        <button
            slot="actions"
            class="simple lg"
            @click="confirm()"
        >
            确定
        </button>
    </b-dialog>
</template>

<script type="text/babel">

export default {
    name: 'FileUploadModal',

    props: {
        bucket: null,

        dir: null,

        rootDir: null
    },

    data() {
        return {
            description: '',
            url: '',
            visible: false
        };
    },

    methods: {
        cancel() {
            this.reject();
        },

        confirm() {
            const {$toast, description, url} = this;
            if (!url) {
                $toast.error('请上传一个文件');

                return;
            }

            this.resolve({description, url});
        }
    }
};

</script>
