<!-- @Author: giligili -->
<!-- @Date: 2023-03-17 02:32:08 -->
<!-- @Last Modified by: giligili -->
<!-- @Last Modified time: 2023-03-17 02:32:08 -->

<template>
    <div class="view-reduce">
        <yqg-static-form
            title="减免详情"
            :values="defaultCond"
            :options="$options.FormOptions"
        />
    </div>
</template>

<script type="text/babel">

    import FormOptions from '../../constant/view-reduce-modal-form-options';

    export default {
        name: 'ViewReduceModal',

        FormOptions,

        props: {
            reduceInfo: {
                type: Object,
                default: () => ({})
            }
        },

        data() {
            return {
                defaultCond: {...this.reduceInfo}
            };
        }
    };

</script>
