<template>
    <component
        :is="comp"
        :key="timezone"
        v-bind="def.props"
        v-model="date"
        @change="onChange"
    />
</template>

<script>
import moment from 'moment';
import 'moment-timezone';
import {mapGetters} from 'vuex';

import FieldMap from '../constant/field-map';

const ModeMethodMap = {
    dayStart: ['startOf', 'day'],
    dayEnd: ['endOf', 'day'],
    secondStart: ['startOf', 'second'],
    secondEnd: ['endOf', 'second']
};

export default {
    name: 'FieldDate',

    inject: {
        timestamp: {
            default: undefined
        }
    },

    model: {
        prop: 'value',
        event: 'change'
    },

    props: {
        value: {
            type: [Number, Array, String],
            default: undefined
        },

        def: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            date: null
        };
    },

    computed: {
        ...mapGetters(['timezone']),

        comp() {
            const {def} = this;

            return FieldMap[def.type];
        },

        isTimestamp() {
            return this.def.timestamp ?? ((this.$app?.timestamp ?? this.timestamp) !== false);
        }
    },

    watch: {
        value() {
            this.setDate();
        },

        timezone() {
            if (!this.isTimestamp) return;

            this.setDate();
            this.onChange(this.date);
        }
    },

    mounted() {
        this.setDate();
    },

    methods: {
        setDate() {
            const {def, timezone} = this;
            let {value: date} = this;

            if (Array.isArray(date)) {
                date = date.map(item => (typeof item === 'number' ? moment.tz(item, def.timezone || timezone) : item));
            } else if (date || date === 0) {
                if (def.type === 'time' && !this.isTimestamp) {
                    date = moment.tz(date, def.props.format || 'HH:mm:ss', def.timezone || timezone);
                } else {
                    date = moment.tz(date, def.timezone || timezone);
                }
            }

            this.date = date;
        },

        onChange(val, str) {
            const {def: {roundMode}, isTimestamp} = this;

            let timestampOrStr = str;
            if (isTimestamp || roundMode) {
                if (Array.isArray(val)) {
                    timestampOrStr = val.map(date => this.dealWithRoundMode(date));
                } else {
                    timestampOrStr = this.dealWithRoundMode(val);
                }
            }

            this.$emit('change', timestampOrStr);
        },

        dealWithRoundMode(date) {
            const {isTimestamp, def: {props: {format}}} = this;
            if (!date) return date;

            const {type} = this.def;
            let {roundMode} = this.def;
            let momentInstance = date;
            if (!roundMode && type === 'dateTime') {
                roundMode = 'secondStart';
            }

            if (roundMode && ModeMethodMap[roundMode]) {
                const [method, param] = ModeMethodMap[roundMode];

                momentInstance = date[method](param);
            }

            return isTimestamp ? momentInstance.valueOf() : momentInstance.format(format || 'YYYY-MM-DD HH:mm:ss');
        }
    }
};
</script>
