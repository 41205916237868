<!-- @author xinzhong -->
<!-- @email xinzhong@yangqianguan.com -->
<!-- @date 2019-05-29 17:48:14.247 -->
<!-- @desc generated by yqg-cli@1.1.1 -->

<template>
    <div class="yqg-call-center">
        <button
            v-if="!isLogin"
            class="sm"
            @click="openLoginModal"
        >
            外呼登录
        </button>

        <div v-if="isLogin">
            <div
                v-if="ms"
                :style="{marginBottom: '10px'}"
            >
                网络延迟:
                <span :style="style">{{ ms }}</span>
            </div>

            <!-- keyboard 软键盘 -->
            <keyboard
                v-if="isConnected"
                ref="keyboard"
                @change="sendDtmf"
            />

            <div
                v-if="canCall && validCallIn"
                class="ytalk-status-content"
                :class="{'text-success': isAvailable, 'text-info': isBusy}"
            >
                当前坐席状态：{{ AgentStatusMap[agentInfo.status] }}

                <button
                    v-if="isBusy"
                    class="sm default"
                    @click="updateAgentStatus(AgentStatus.AVAILABLE)"
                >
                    空闲
                </button>

                <button
                    v-if="isAvailable"
                    class="sm info"
                    @click="updateAgentStatus(AgentStatus.BUSY)"
                >
                    忙碌
                </button>

                <slot name="echoTest" />

                <i
                    v-if="canConfig"
                    v-b-tooltip="'坐席设置'"
                    class="b-icon-setting"
                    style="color: #ccc;"
                    @click="openConfigModal"
                />
            </div>

            <div class="ytalk-call-content">
                <div
                    v-if="canCall"
                    class="call-content"
                >
                    <input
                        v-model="agentInfo.mobileNumber"
                        type="text"
                        placeholder="请输入对方号码"
                    >

                    <button
                        class="sm"
                        @click="call({mobileNumber: agentInfo.mobileNumber})"
                    >
                        <i class="b-icon-phone" />
                    </button>
                </div>

                <div
                    v-if="canHangUp"
                    class="hangup-content"
                >
                    <template v-if="isEavesdrop">
                        <span class="connect">
                            监听中
                        </span>
                        <button
                            class="sm danger"
                            @click="hangup"
                        >
                            挂断
                        </button>
                    </template>

                    <template v-else-if="isCallTest">
                        <span class="connect">
                            回声测试中
                        </span>
                        <button
                            class="sm danger"
                            @click="hangup"
                        >
                            结束测试
                        </button>
                    </template>

                    <template v-else>
                        <slot />
                        <slot name="input" />

                        <span :class="{connect: isConnected}">
                            {{ displayPhone | yqgPhoneMask }}
                            {{ isConnected ? '通话中' : (canAnswer ? '来电中' : '呼叫中') }}
                        </span>

                        <span
                            v-if="isConnected"
                            class="connected-ops"
                        >
                            <button
                                v-if="!isMuted"
                                class="sm normal"
                                @click="mute"
                            >
                                <i class="b-icon-micro" />
                            </button>

                            <button
                                v-else
                                class="sm normal"
                                @click="unmute"
                            >
                                <i class="b-icon-micro-silence" />
                            </button>

                            <button
                                class="sm normal"
                                @click="toggleKeyboard"
                            >
                                <img
                                    :src="KeyboardIcon"
                                    class="keyboard"
                                >
                            </button>
                        </span>

                        <button
                            v-if="canAnswer"
                            class="sm"
                            @click="answer"
                        >
                            接听
                        </button>
                        <button
                            class="sm danger"
                            @click="hangup"
                        >
                            挂断<span v-if="hangupCountDown && hangupCountDown > 0">({{ hangupCountDown }}s)</span>
                        </button>

                        <button
                            v-if="canaMnualTransferSatis"
                            class="sm default"
                            @click="transfer"
                        >
                            挂断转接满意度
                        </button>
                    </template>
                </div>

                <button
                    v-else
                    class="sm default logout-content"
                    @click="unRegister"
                >
                    登出
                </button>
            </div>

            <audio
                id="YTalkRemoteAudio"
                autoplay="autoplay"
                style="display: none;"
            />
            <audio
                id="YTalkHangupRecord"
                :src="HangupRecord"
                style="display: none;"
            />
            <audio
                id="YTalkContentedRecord"
                :src="ContentedRecord"
                style="display: none;"
            />
            <audio
                id="YTalkRingRecord"
                loop="loop"
                :src="RingRecord"
                style="display: none;"
            />
        </div>
    </div>
</template>

<script type="text/babel">

    /* eslint-disable */

    import JsSIP from 'jssip';

    import bus, {
        YTALK_ADD_CALL_DETAIL,
        YTALK_MAKE_CALL,
        YTALK_CALL_END,
        YTALK_SELECT_SCENE,
        YTALK_SELECT_SCENE_END,
        YTALK_WORKER_CALL_END,
        YTALK_CAN_CALL_CHANGE,
        YQG_CALL_BTN_MOUNTED,
        YTALK_CALL_CENTER_LOGIN
    } from 'yqg-common/vue/constant/event-bus';
    import StageUtil from 'yqg-common/core/StageUtil';
    import {isMobile} from 'yqg-common/core/ToolFunction';

    import YTalk from '@shared/client/ytalk';
    import YTalkSipRTT, {levelColors, lossColor} from '@shared/client/ytalk/feature/sip-rtt';
    import YTalkMetric from '@shared/client/ytalk-metric';

    import ClientWorker from './lib/talk.shared-worker';

    import AgentStatusMap, {AgentStatus} from './constant/agent-status-map';
    import {PortEvents, WorkerEvents} from './constant/shared-worker-events';
    import SessionDirectionMap from './constant/session-direction-map';
    import TransferSatisType from './constant/transfer-satis-type';
    import CallType from './constant/call-type';

    import ChatOperationType from './constant/chat-operation-type';

    import LoginModal from './modal/login-modal';
    import SelectSceneModal from './modal/select-scene-modal';

    import RingRecord from './lib/ring.mp3';
    import HangupRecord from './lib/hangup.mp3';
    import ContentedRecord from './lib/contented.mp3';

    import KeyboardIcon from './img/keyboard.png';

    import Keyboard from './keyboard';

    const internalIp = require('internal-ip');

    const CALL_ID_HEADER = 'X-Call-Uuid';
    const CALL_TYPE = 'X-Call-Type';
    const IS_AUDIO_INPUT = 'audioinput';

    const MediaConstraints = {
        audio: {
            mandatory: {
                googNoiseSuppression: true,
                googNoiseSuppression2: true,
                googEchoCancellation: true,
                googHighpassFilter: true
            }
        },
        video: false
    };

    export default {
        name: 'YqgCallCenter',

        components: {
            Keyboard
        },

        props: {
            accountInfo: {
                type: Object,
                default: () => ({})
            },

            canConfig: {
                type: Boolean,
                default: false
            },

            debug: {
                type: Boolean,
                default: false
            },

            validCallIn: {
                type: Boolean,
                default: false
            },

            defaultBusy: {
                type: Boolean,
                default: true
            },

            sceneTips: {
                type: Array,
                default: () => ([])
            },

            latestSceneUrl: {
                type: String,
                default: ''
            },

            hangupInterval: { // 呼出挂断间隔
                type: Number,
                default: 0
            },

            socketConf: {
                type: Object,
                default: () => ({})
            }
        },

        data() {
            return {
                ms: '',
                style: {},
                scene: null,
                toggleScene: false,
                isForceLogout: false,

                AgentStatus,
                AgentStatusMap,

                HangupRecord,
                RingRecord,
                ContentedRecord,

                isMobile: isMobile(navigator.userAgent),

                serverConfig: {},
                agentInfo: {
                    status: AgentStatus.INIT,
                    isMuted: false,
                    mobileNumber: '',
                    incomingMobileNumber: ''
                },

                callInfo: {},
                callType: '',

                sceneMap: {},

                currentSession: null,
                userAgent: null,
                direction: '',
                lastTime: null, // 用于节流的计时器
                countTimer: null,
                hangupCountDown: this.hangupInterval,

                KeyboardIcon
            };
        },

        computed: {
            showSceneMap() {
                const vm = this;
                const {sceneMap = {}} = vm;

                return Object.keys(sceneMap).length > 1;
            },

            displayPhone() {
                const vm = this;

                return vm.callInfo.direction === SessionDirectionMap.OUTGOING
                    ? vm.callInfo.mobileNumber : vm.agentInfo.incomingMobileNumber;
            },

            canaMnualTransferSatis() {
                const vm = this;

                return vm.agentInfo.status === AgentStatus.CONNECTED
                    && vm.callInfo.satisfactionTemplateId
                    && vm.callInfo.transferSatisType === TransferSatisType.MANUAL;
            },

            canCall() {
                const vm = this;
                let canCall;

                switch (vm.agentInfo.status) {
                    case AgentStatus.LOGIN:
                    case AgentStatus.AVAILABLE:
                    case AgentStatus.BUSY:
                        canCall = true;
                        break;
                    default:
                        canCall = false;
                }

                return canCall;
            },

            canAnswer() {
                const vm = this;
                let canAnswer;

                switch (vm.agentInfo.status) {
                    case AgentStatus.OFFERING:
                        canAnswer = true;
                        break;
                    default:
                        canAnswer = false;
                }

                return canAnswer;
            },

            canHangUp() {
                const vm = this;
                let canHangup;

                switch (vm.agentInfo.status) {
                    case AgentStatus.CALLING:
                    case AgentStatus.CONNECTED:
                    case AgentStatus.DIALING:
                    case AgentStatus.OFFERING:
                        canHangup = true;
                        break;
                    default:
                        canHangup = false;
                }

                return canHangup;
            },

            isLogin() {
                const vm = this;
                let isLogin;

                switch (vm.agentInfo.status) {
                    case AgentStatus.INIT:
                    case AgentStatus.LOGOUT:
                        isLogin = false;
                        break;
                    default:
                        isLogin = true;
                }

                return isLogin;
            },

            isConnected() {
                const vm = this;
                return vm.agentInfo.status === AgentStatus.CONNECTED;
            },

            isAvailable() {
                const vm = this;
                return vm.agentInfo.status === AgentStatus.AVAILABLE;
            },

            isBusy() {
                const vm = this;
                return vm.agentInfo.status === AgentStatus.BUSY;
            },

            isMuted() {
                const vm = this;
                return vm.agentInfo.isMuted;
            },

            // 监听(特殊的呼入)
            isEavesdrop() {
                const vm = this;
                const {callType} = vm;

                return callType === CallType.EAVESDROP;
            },

            // 测试坐席回声(特殊的呼入)
            isCallTest() {
                return this.callType === CallType.CALL_TEST;
            }
        },

        watch: {
            canCall(val) {
                const vm = this;
                vm.emitCanCallChange(val);
            },

            'callInfo.$ringStartTime': function (val) {
                this.clearCountTimer();
                if (val && val > 0) {
                    this.setCountByTime();
                }
            },

            isLogin(val) {
                const vm = this;

                vm.$emit('update-login-status', val);
            },

            agentInfo: {
                immediate: true,

                handler() {
                    const vm = this;
                    const cleasStatus = [
                        AgentStatus.AVAILABLE,
                        AgentStatus.CONNECTED,
                        AgentStatus.BUSY,
                        AgentStatus.LOGOUT,
                        AgentStatus.LOGIN
                    ];

                    if (cleasStatus.includes(vm.agentInfo.status)) {
                        vm.clearCountTimer();
                    }
                }
            }
        },

        mounted() {
            const vm = this;
            if (!vm.isMobile) vm.initClientWorker();
            if ((StageUtil.isTestEnv() || StageUtil.isDevEnv()) && this.debug) JsSIP.debug.enable('JsSIP:*');
            bus.$on(YTALK_SELECT_SCENE, this.openSelectSceneModal);
            bus.$on(YTALK_MAKE_CALL, this.call);
            bus.$on(YQG_CALL_BTN_MOUNTED, vm.emitCanCallChange);
            bus.$on(YTALK_CALL_CENTER_LOGIN, vm.openLoginModal);

            vm.getIP();

            this.ytalk = new YTalk();
            this.ytalk.useFeature(new YTalkSipRTT({
                onRTTChange: ({ms, level, loss}) => {
                    const color = loss ? lossColor : levelColors[level];
                    this.ms = ms;
                    this.style = {color};
                }
            }));
        },

        beforeDestroy() {
            bus.$off(YTALK_SELECT_SCENE, this.openSelectSceneModal);
            bus.$off(YTALK_MAKE_CALL, this.call);
            bus.$off(YQG_CALL_BTN_MOUNTED, this.emitCanCallChange);
            bus.$off(YTALK_CALL_CENTER_LOGIN, this.openLoginModal);

            this.clearCountTimer();
        },

        destroyed() {
            const vm = this;
            vm.resetWindowCloseEvent();
            if (vm.userAgent) vm.sendWorkerMessage(PortEvents.CloseActivePort);
            if (vm.userAgent) vm.userAgent.stop();

            this.ytalk.destroy();
        },

        methods: {
            async getIP() {
                const vm = this;

                try {
                    vm.IP = await internalIp.v4();
                } catch (err) {
                    // ignore
                }
            },

            clearCountTimer() {
                this.hangupCountDown = 0;

                if (this.countTimer) {
                    clearInterval(this.countTimer);
                    this.countTimer = null;
                }
            },

            initClientWorker() {
                const vm = this;
                if (!window.SharedWorker) {
                    vm.$toast.error('浏览器版本过低，外呼无法正常使用！');
                    return;
                }

                if (!vm.clientWorker) {
                    vm.clientWorker = new ClientWorker('YTalkCallCenter');
                    vm.clientWorker.port.onmessage = message => vm.onWorkerMessage(...message.data);
                    vm.clientWorker.port.start();
                }
            },

            getCallInfo: () => this.callInfo,

            getAgentInfo: () => this.agentInfo,

            onWorkerMessage(type, ...payload) {
                const vm = this;
                switch (type) {
                    case WorkerEvents.SetPortAgentInfo: {
                        vm.setAgentInfo(...payload);
                        break;
                    }
                    case WorkerEvents.SetPortCallInfo: {
                        vm.setCallInfo(...payload);
                        break;
                    }
                    case WorkerEvents.AgentCall: {
                        if (vm.userAgent) vm.call(...payload);
                        break;
                    }
                    case WorkerEvents.AgentHangUp: {
                        if (vm.userAgent) vm.hangup();
                        break;
                    }
                    case WorkerEvents.AgentUnRegister: {
                        if (vm.userAgent) vm.unRegister();
                        break;
                    }
                    case WorkerEvents.AgentAnswer: {
                        if (vm.userAgent) vm.answer();
                        break;
                    }
                    case WorkerEvents.AgentSendDtmf: {
                        if (vm.userAgent) vm.sendDtmf(...payload);
                        break;
                    }
                    case WorkerEvents.OnCallReleased: {
                        bus.$emit(YTALK_WORKER_CALL_END, ...payload);
                        break;
                    }
                    case WorkerEvents.OnTransferCallReleased: {
                        if (vm.userAgent) vm.callRelease();
                        break;
                    }
                    case WorkerEvents.SetPortSceneMap: {
                        vm.setSceneMap(...payload);
                        break;
                    }
                    default:
                        break;
                }
            },

            sendWorkerMessage(type, payload = {}) {
                const vm = this;
                if (vm.clientWorker) {
                    vm.clientWorker.port.postMessage([type, payload]);
                }
            },

            mute() {
                const vm = this;

                if (vm.userAgent && vm.currentSession) {
                    vm.currentSession.mute();

                    const {audio} = vm.currentSession.isMuted();
                    vm.agentInfo.isMuted = audio;

                    vm.$emit('change-hold-on-status', {callUuid: vm.callInfo.callId, isMuted: audio});
                    vm.sendWorkerMessage(PortEvents.SetWorkerAgentInfo, vm.agentInfo);
                } else {
                    vm.sendWorkerMessage(PortEvents.mute);
                }
            },

            unmute() {
                const vm = this;

                if (vm.userAgent && vm.currentSession) {
                    vm.currentSession.unmute();

                    const {audio} = vm.currentSession.isMuted();
                    vm.agentInfo.isMuted = audio;

                    vm.$emit('change-hold-on-status', {callUuid: vm.callInfo.callId, isMuted: audio});
                    vm.sendWorkerMessage(PortEvents.SetWorkerAgentInfo, vm.agentInfo);
                } else {
                    vm.sendWorkerMessage(PortEvents.unmute);
                }
            },

            sendDtmf(val) {
                const vm = this;

                if (vm.userAgent) {
                    vm.currentSession.sendDTMF(val);
                } else {
                    vm.sendWorkerMessage(PortEvents.SendDtmf, val);
                }
            },

            toggleKeyboard() {
                const vm = this;
                const {keyboard} = vm.$refs;

                if (!keyboard) return;

                keyboard.showKeyboard = !keyboard.showKeyboard;
            },

            isRemote({originator}) {
                return originator === 'remote';
            },

            setAgentInfo(agentInfo) {
                const vm = this;
                vm.agentInfo = agentInfo;
                vm.$emit('get-account-info', agentInfo);

                if (this.ytalk.consumers.some(consumer => consumer instanceof YTalkMetric)) return;

                this.ytalk.addConsumer(new YTalkMetric({
                    getDefaultData: () => ({
                        account: agentInfo.account,
                        business: 'ADMIN',
                        workplace: '中青大厦',
                        agentStatus: this.agentInfo.status,
                    })
                }));
            },

            setCallInfo(callInfo) {
                const vm = this;
                vm.callInfo = callInfo;
            },

            setSceneMap(sceneMap) {
                const vm = this;
                vm.sceneMap = sceneMap;
            },

            setWorkerCallInfo(callInfo) {
                const vm = this;
                vm.callInfo = {...vm.callInfo, ...callInfo};
                vm.sendWorkerMessage(PortEvents.SetWorkerCallInfo, vm.callInfo);
            },

            setAgentStatus(status) {
                const vm = this;
                vm.agentInfo.status = status;
                vm.sendWorkerMessage(PortEvents.SetWorkerAgentInfo, vm.agentInfo);
            },

            openLoginModal() {
                const vm = this;
                vm.$modal.open(LoginModal, {accountInfo: vm.accountInfo, socketConf: vm.socketConf})
                    .then((loginInfo = {}) => {
                        vm.initSip(loginInfo);
                        vm.agentInfo.account = loginInfo.account;
                        vm.defaultInviteHeaderString = loginInfo.inviteHeaderString;
                        vm.sendWorkerMessage(PortEvents.SetWorkerAgentInfo, vm.agentInfo);
                    })
                    .catch(err => err);
            },

            openConfigModal() {
                const vm = this;
                vm.$emit('open-config-modal');
            },

            transfer() {
                const vm = this;
                const {callInfo: {callId, satisfactionTemplateId}} = vm;
                vm.$emit('transfer', {
                    uuid: callId,
                    templateId: satisfactionTemplateId
                });

                if (vm.userAgent) {
                    vm.callRelease();
                } else {
                    vm.sendWorkerMessage(PortEvents.TransferCallReleased);
                }
            },

            toggleSceneSelect() {
                const vm = this;
                const {toggleScene} = vm;
                vm.toggleScene = !toggleScene;
            },

            initSip(loginInfo = {}) {
                const vm = this;

                try {
                    const {account, password, socket, uriHost} = loginInfo;

                    vm.serverConfig = {socket, uriHost};

                    const webSocket = new JsSIP.WebSocketInterface(`${socket}?userId=${account}`);

                    vm.callAccount = account;

                    const configuration = {
                        password,
                        sockets: [webSocket],
                        uri: `sip:${account}@${uriHost}`,
                        register: false,
                        session_timers: false,
                        contact_uri: `sip:${account}@${uriHost};transport=wss`
                    };

                    vm.userAgent = new JsSIP.UA(configuration);
                    
                    vm.userAgent._registrator.setExtraHeaders([
                        `X-IP: ${vm.IP}`,
                        `X-UA: ${window.navigator.userAgent}`
                    ]);

                    vm.userAgent.on('registered', vm.onRegisterSuccess);
                    vm.userAgent.on('unregistered', vm.onUnregistered);
                    vm.userAgent.on('registrationFailed', vm.onRegisterFailed);
                    vm.userAgent.on('newRTCSession', vm.onNewRTCSession);
                    vm.userAgent.on('newMessage', vm.onNewMessage);
                    vm.userAgent.on('disconnected', vm.onDisconnected)

                    vm.userAgent.start(); // 初始化

                    this.ytalk.addDevice(vm.userAgent);

                    vm.register();
                } catch (err) {
                    vm.$toast.error('外呼初始化失败');
                }
            },

            defaultAlertSipMessageTip(content) {
                this.$modal.openMessageModal(content, '注意');
            },

            handleAlertSipMessage(responseBody) {
                const finallyResponseBody = responseBody || {};
                const {sipMessageType, body} = finallyResponseBody;

                if (sipMessageType !== 'ALERT') return;

                // 默认提示
                this.defaultAlertSipMessageTip(body);
            },

            onNewMessage(data) {
                const vm = this;
                const body = JSON.parse(data.request.body);
                const {
                    callId,
                    chatOperation,
                    satisfactionTemplateId,
                    transferSatisType,
                    sceneMessage,
                    sipMessageType,
                    curStatus,
                    message
                } = body;

                if (sceneMessage) {
                    vm.sceneMap = sceneMessage;

                    vm.sendWorkerMessage(PortEvents.SetSceneMap, vm.sceneMap);
                    return;
                }

                this.handleAlertSipMessage(body);
                
                if (sipMessageType === 'LOGIN_CHECK' && callId) {
                    if (vm.registerCallId !== callId) {
                        vm.$modal.openMessageModal(`您的外呼账号【${vm.callAccount}】已在其他地方登录`, '注意');
                        vm.unRegister();
                    }
                    return;
                }

                if (sipMessageType && curStatus) {
                    // 通话结束后坐席为 HANGUP_HANDLE 状态，HANGUP_HANDLE时根据业务需求进行话后处理比如坐席空闲忙碌
                    if (curStatus === AgentStatus.HANGUP_HANDLE) {
                        if (vm.defaultBusy) {
                            vm.setAgentStatus(AgentStatus.BUSY);
                            vm.updateAgentStatus(AgentStatus.BUSY);
                         } else {
                            vm.setAgentStatus(AgentStatus.AVAILABLE);
                            vm.updateAgentStatus(AgentStatus.AVAILABLE);
                        }
                    };

                    return;
                };

                if (satisfactionTemplateId && transferSatisType) {
                    vm.callInfo.satisfactionTemplateId = satisfactionTemplateId;
                    vm.callInfo.transferSatisType = transferSatisType;
                    return;
                }

                switch (chatOperation) {
                    case ChatOperationType.LOGOUT_USER: {
                        vm.isForceLogout = true;

                        vm.unRegister();
                        vm.$modal.openMessageModal('坐席已被强制登出', '注意');
                        return;
                    }

                    case ChatOperationType.LINE_FLASH: {
                        if (message) vm.$toast.error(message);
                        return;   
                    }

                    case ChatOperationType.HANGUP_CALL: {
                        vm.$modal.openMessageModal('通话已经被制挂断', '注意');
                        return;
                    }
                }
            },

            onDisconnected(data) {
                // 后端主动关闭 socket，这时候不需要重试
                if (data?.error?.code === 4001 && this.userAgent) {
                    this.userAgent.stop();

                    return;
                }
            },

            register() {
                if (this.userAgent) this.userAgent.register();
            },

            unRegister() {
                const vm = this;

                if (vm.userAgent) {
                    vm.userAgent.unregister({all: false});
                } else {
                    vm.sendWorkerMessage(PortEvents.UnRegister);
                }
            },

            hangup() {
                const vm = this;

                if (vm.hangupCountDown && vm.hangupCountDown > 0) {
                    vm.$toast.info(`响铃时间低于${vm.hangupInterval}秒，暂不支持挂断`);
                    return;
                }

                YqgReporter.info({
                    action: 'hangup',
                    agentInfo: vm.agentInfo,
                    callId: vm.callInfo && vm.callInfo.callId
                });

                if (vm.callInfo.satisfactionTemplateId && vm.isConnected
                    && vm.callInfo.transferSatisType === TransferSatisType.AUTO) {
                    vm.transfer();
                    return;
                }

                if (vm.userAgent) {
                    vm.currentSession && vm.currentSession.terminate();
                } else {
                    vm.sendWorkerMessage(PortEvents.HangUp);
                }
            },

            async openSelectSceneModal(callInfo = {}, disableEmit = false) {
                const vm = this;
                let scene;
                let latestSceneList = [];
                const {mobileNumber, userId, encryptMobileNumber} = callInfo;

                if ((!mobileNumber && !encryptMobileNumber) || !vm.canCall) {
                    vm.$toast.error('请登录并输入外呼号码');
                    throw new Error('call invalid');
                }

                if (vm.showSceneMap) {
                    try {
                        latestSceneList = Object.keys(vm.sceneMap)
                            .map(sceneCode => ({
                                sceneCode,
                                available: true
                            }));

                        if (vm.latestSceneUrl && (userId || mobileNumber || encryptMobileNumber)) {
                            const params = encryptMobileNumber
                                ? {encryptMobileNumber}
                                : userId ? {userId} : {mobileNumber};
                            const {data: {body = []}} = await vm.$http.get(vm.latestSceneUrl, {params});
                            latestSceneList = body;
                        }

                        scene = await vm.$modal.open(SelectSceneModal, {
                            sceneMap: vm.sceneMap,
                            sceneTips: vm.sceneTips,
                            latestSceneList
                        });
                    } catch (err) {
                        throw new Error('scene invalid');
                    }
                }

                const curSceneInfo = latestSceneList.find(({sceneCode}) => sceneCode === scene);

                if (!disableEmit) {
                    bus.$emit(YTALK_SELECT_SCENE_END, {
                        ...callInfo,
                        ytalkScene: curSceneInfo?.sceneCode,
                        is400Number: curSceneInfo?.is400Number
                    });
                }

                return scene;
            },
            
            getDefaultInviteHeaders(callInfo) {
                const vm = this;

                const {defaultInviteHeaderString} = vm;

                if (callInfo.extraHeaders) return callInfo.extraHeaders;

                let defaultHeaders = {};

                try {
                    defaultHeaders = JSON.parse(defaultInviteHeaderString);
                } catch(err) {
                    // ignore
                }

                return Object.keys(defaultHeaders).map((key) => `${key}: ${defaultHeaders[key]}`);
            },

            async call(callInfo = {}, extraHeaders = []) {
                const vm = this;
                const {userAgent} = vm;
                const {mobileNumber, encryptMobileNumber, delay, ytalkScene, showScene = true} = callInfo;

                extraHeaders = extraHeaders.length
                    ? extraHeaders
                    : this.getDefaultInviteHeaders(callInfo);

                YqgReporter.info({
                    action: 'call',
                    agentInfo: vm.agentInfo
                });

                try {
                    // 请求一个可用的媒体输入和输出设备的列表
                    // const devicesInfoArr = await navigator.mediaDevices.enumerateDevices();
                    // // 判断是否有可用的音频输入设备
                    // if (!devicesInfoArr.some(({deviceId, kind}) => deviceId && kind === IS_AUDIO_INPUT)) {
                    //     vm.$toast.error('请打开浏览器麦克风权限，并检查音频输入设备');
                    //     return;
                    // }
                } catch {
                    // ignore
                }

                if ((!mobileNumber && !encryptMobileNumber) || !vm.canCall) {
                    vm.$toast.error('请登录并输入外呼号码');
                    return;
                }

                try {
                    if (vm.showSceneMap && !ytalkScene && showScene) {
                        vm.scene = await vm.openSelectSceneModal(callInfo, true);
                    } else if (ytalkScene) {
                        vm.scene = ytalkScene;
                    }
                } catch (err) {
                    return;
                }

                const mainTask = () => {
                    if (userAgent) {
                        const options = {
                            extraHeaders,
                            mediaConstraints: MediaConstraints
                        };

                        const callNumber = encryptMobileNumber || `0${mobileNumber}`;
                        
                        vm.currentSession = userAgent.call(`sip:${callNumber}@${vm.serverConfig.uriHost}`, options);
                        vm.callInfo = callInfo;
                        vm.sendWorkerMessage(PortEvents.SetWorkerCallInfo, vm.callInfo);
                        vm.sendWorkerMessage(PortEvents.SetWorkerAgentInfo, vm.agentInfo);
                    } else {
                        vm.sendWorkerMessage(PortEvents.Call, {...callInfo, ytalkScene: vm.scene});
                    }
                };

                const throttleMainTask = delay => {
                    const now = Date.now();

                    if (now - vm.lastTime > delay) {
                        setTimeout(mainTask, delay);
                        vm.lastTime = now;
                    } else {
                        vm.$toast.error('请勿连续拨打');
                    }
                };

                if (delay) {
                    throttleMainTask(delay);
                } else {
                    mainTask();
                }
            },

            setWindowCloseEvent() {
                const vm = this;
                if (vm.userAgent) {
                    // 注册页关闭提示
                    window.onbeforeunload = (event) => {
                        if (!vm.userAgent) return;
                        vm.userAgent.unregister({all: false});
                        event = event || window.event;
                        if (event) event.returnValue = '确定关闭页面？';
                        return '确定关闭页面？';
                    };

                    window.onunload = () => {
                        if (!vm.userAgent) return;
                        vm.sendWorkerMessage(PortEvents.CloseActivePort);
                    };
                }
            },

            resetWindowCloseEvent() {
                window.onbeforeunload = null;
                window.onunload = null;
            },

            /** ************** 事件响应 *****************/
            progress() {
                const vm = this;
                vm.setAgentStatus(AgentStatus.DIALING);
            },

            failed({message}) {
                const vm = this;

                const filedMessage = message && message.getHeader('GATEWAY_HANGUP_CAUSE');
                if (filedMessage) {
                    vm.$toast.info(filedMessage);
                }

                if (vm.isForceLogout) return;

                if (!vm.callInfo.callId) {
                    vm.setAgentStatus(AgentStatus.BUSY);
                }

                vm.callRelease();
            },

            ended() {
                const vm = this;

                if (vm.isForceLogout) return;
                vm.callRelease();
            },

            callRelease() {
                const vm = this;

                vm.clearCountTimer();

                if (vm.userAgent && vm.callInfo.callId) {
                    YqgReporter.info({
                        action: 'addDetail',
                        callId: vm.callInfo.callId
                    });

                    vm.sendWorkerMessage(PortEvents.CallReleased, vm.callInfo);
                    bus.$emit(YTALK_CALL_END, vm.callInfo);
                }

                vm.callType = '';
                vm.currentSession = null;
                vm.callInfo = {};
                vm.agentInfo.isMuted = false;
                vm.sendWorkerMessage(PortEvents.SetWorkerCallInfo, vm.callInfo);
                vm.sendWorkerMessage(PortEvents.SetWorkerAgentInfo, vm.agentInfo);

                document.getElementById('YTalkHangupRecord')
                    .play();
                vm.resetAudio();
            },

            updateAgentStatus(status) {
                const vm = this;
                vm.$emit('set-agent-status', status);
            },

            confirmed() {
                const vm = this;
                vm.setAgentStatus(AgentStatus.CONNECTED);
            },

            onRegisterSuccess(data) {
                const vm = this;
                vm.$toast.success('外呼登录成功');

                vm.registerCallId = data?.response?.call_id;
                vm.isForceLogout = false;
                vm.setWindowCloseEvent();

                if (vm.defaultBusy) {
                    vm.setAgentStatus(AgentStatus.BUSY);
                } else {
                    vm.setAgentStatus(AgentStatus.AVAILABLE);
                }
            },

            onRegisterFailed() {
                const vm = this;
                vm.$toast.info('外呼登录失败');
                vm.setAgentStatus(AgentStatus.LOGOUT);
            },

            onUnregistered() {
                const vm = this;
                vm.$toast.info('外呼登出成功');
                if (vm.userAgent) {
                    vm.userAgent.stop();
                    vm.userAgent = null;
                }

                vm.setAgentStatus(AgentStatus.LOGOUT);
                vm.resetWindowCloseEvent();
            },

            answer() {
                const vm = this;

                YqgReporter.info({
                    action: 'answer',
                    agentInfo: vm.agentInfo,
                    callId: vm.callInfo && vm.callInfo.callId
                });

                if (vm.userAgent) {
                    const options = {
                        mediaConstraints: MediaConstraints
                    };
                    if (vm.currentSession) {
                        vm.currentSession.answer(options);
                        vm.currentSession.connection.addEventListener('addstream', vm.onSessionAddStream);
                    }
                } else {
                    vm.sendWorkerMessage(PortEvents.Answer);
                }
            },

            onNewRTCSession(data) {
                const vm = this;
                vm.currentSession = data.session;

                if (!vm.isRemote(data)) {
                    vm.currentSession.on('connecting', vm.onSessionConnecting);
                    vm.currentSession.connection.addEventListener('addstream', vm.onSessionAddStream);

                    if (vm.showSceneMap && vm.scene) data.request && data.request.setHeader('X-SCENE', vm.scene);
                }

                vm.currentSession.on('accepted', vm.onSessionAccepted);
                vm.currentSession.on('confirmed', vm.onSessionConfirmed);
                vm.currentSession.on('progress', vm.onSessionProgress);
                vm.currentSession.on('ended', vm.ended);
                vm.currentSession.on('failed', vm.failed);
            },

            onSessionAddStream(data) {
                document.getElementById('YTalkRemoteAudio').srcObject = data.stream;
                document.getElementById('YTalkRemoteAudio')
                    .play();
            },

            onSessionConnecting() {
                const vm = this;
                vm.setAgentStatus(AgentStatus.DIALING);
            },

            onSessionAccepted() {
                const vm = this;
                vm.callInfo.connected = true;
                vm.setAgentStatus(AgentStatus.CONNECTED);
                vm.sendWorkerMessage(PortEvents.SetWorkerCallInfo, vm.callInfo);

                document.getElementById('YTalkContentedRecord')
                    .play();
                document.getElementById('YTalkRingRecord')
                    .pause();
                document.getElementById('YTalkRingRecord').currentTime = 0;
            },

            onSessionConfirmed() {
                const vm = this;
                vm.setAgentStatus(AgentStatus.CONNECTED);
            },

            onSessionProgress(data) {
                const vm = this;
                let callId;
                let incomingRequest;

                const {currentSession: {direction, remote_identity: {_display_name}}} = vm;
                vm.$set(vm.agentInfo, 'incomingMobileNumber', _display_name);

                if (data.response && data.response.status_code === 183) return;
                // 手拨时 ytalk后端会发送 180 和 183 两个消息都会触发 onSessionProgress 方法 拦截一个

                vm.callInfo.direction = direction;
                vm.agentInfo.status = direction === SessionDirectionMap.OUTGOING
                    ? AgentStatus.DIALING
                    : AgentStatus.OFFERING;

                if (direction !== SessionDirectionMap.OUTGOING) { // 呼入
                    incomingRequest = vm.currentSession && vm.currentSession._request;
                    
                    vm.callType = incomingRequest?.getHeader(CALL_TYPE);

                    if ([CallType.EAVESDROP, CallType.INBOUND_FLOW_TEST, CallType.CALL_TEST].includes(vm.callType)) {
                        vm.answer();
                    }

                    callId = incomingRequest && incomingRequest.getHeader(CALL_ID_HEADER);
                    document.getElementById('YTalkRingRecord')
                        .play();

                    const xPhoneNumber = incomingRequest && incomingRequest.getHeader('X-PHONE-NUMBER');
                    const xMenuKeyInput = incomingRequest && incomingRequest.getHeader('X-MENU-KEY-INTENT');

                    const satisfactionTemplateId = incomingRequest && incomingRequest.getHeader('X-SATIS-TEMPLATE-ID');
                    const transferSatisType = incomingRequest && incomingRequest.getHeader('X-SATIS-TRANSFER-TYPE');

                    vm.callInfo.satisfactionTemplateId = satisfactionTemplateId;
                    vm.callInfo.transferSatisType = transferSatisType;

                    if (xPhoneNumber) vm.$emit('get-user-id', xPhoneNumber);
                    if (xMenuKeyInput) {
                        vm.callInfo.userChooseOption = xMenuKeyInput;
                        vm.$emit('get-user-key-input', xMenuKeyInput);
                    }
                } else {
                    callId = data.response && data.response.getHeader(CALL_ID_HEADER);
                    vm.callInfo.$ringStartTime = Date.now();
                }

                YqgReporter.info({
                    action: 'onSessionProgress',
                    agentInfo: vm.agentInfo,
                    callId,
                    direction
                });

                vm.callInfo.callId = callId;
                vm.sendWorkerMessage(PortEvents.SetWorkerCallInfo, vm.callInfo);
                vm.sendWorkerMessage(PortEvents.SetWorkerAgentInfo, vm.agentInfo);
                vm.addCallDetail();
            },

            resetAudio() {
                document.getElementById('YTalkRingRecord')
                    .pause();
                document.getElementById('YTalkRingRecord').currentTime = 0;

                document.getElementById('YTalkRemoteAudio')
                    .pause();
                document.getElementById('YTalkRemoteAudio').srcObject = null;
            },

            emitCanCallChange(canCall = this.canCall) {
                const vm = this;

                bus.$emit(YTALK_CAN_CALL_CHANGE, canCall);
            },

            addCallDetail() {
                const vm = this;
                if (vm.callInfo.callId) {
                    bus.$emit(YTALK_ADD_CALL_DETAIL, vm.callInfo);
                }
            },

            setCountByTime() {
                const vm = this;
                if (!vm.callInfo.$ringStartTime || !vm.hangupInterval || vm.hangupInterval < 1) {
                    vm.hangupCountDown = 0;
                    return;
                }

                vm.hangupCountDown = vm.hangupInterval;

                vm.countTimer = setInterval(() => {
                    vm.hangupCountDown = vm.hangupInterval - Math.floor((Date.now() - vm.callInfo.$ringStartTime) / 1e3);

                    if (vm.hangupCountDown < 1) {
                        vm.clearCountTimer();
                    }
                }, 1e3);
            }
        }
    };

</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .yqg-call-center {
        position: fixed;
        bottom: 25px;
        right: 40px;
        z-index: 999;
        background-color: white;
        padding: 15px 20px;
        border-radius: 10px;
        box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.1);

        input {
            width: 120px;
        }

        .connect {
            color: #0b9067;
        }

        button[disabled] {
            cursor: not-allowed;
        }

        .ytalk-call-content {
            display: flex;
            align-items: center;

            .call-content {
                flex: 1;
                display: flex;
                align-items: center;

                input {
                    flex: 1;
                    margin-right: 5px;
                }
            }

            .logout-content {
                margin-left: 5px;
            }

            .hangup-content {
                margin-left: 5px;
            }
        }

        .ytalk-status-content {
            margin-bottom: 10px;
            color: #666;

            &.text-success {
                color: #0b9067;
            }

            &.text-info {
                color: #f90;
            }

            button {
                margin-left: 5px;

                &.success {
                    background-color: #0b9067;
                    border: none;
                }

                &.info {
                    background-color: #f7c411;
                    border: none;
                }
            }
        }

        .user-id-wrapper {
            margin: 5px 0;

            .label {
                color: #777;
            }

            .user-id {
                color: #0c8dfd;
            }
        }

        .user-input {
            padding: 5px 5px 10px;
            font-size: 12px;

            label {
                color: #666;
            }

            span {
                color: #000;
            }
        }

        .connected-ops {
            display: inline-flex;

            button:not(:last-child) {
                line-height: 0;
                margin-right: 4px;
            }

            .keyboard {
                width: 15px;
            }
        }
    }

</style>
