var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "inline-color-btn" },
    [
      _vm._t("default", null, { color: _vm.color }),
      _vm._v(" "),
      _c(
        "a-popover",
        {
          attrs: {
            placement: "bottom",
            trigger: "click",
            "overlay-class-name": "rich-color-panel",
          },
          model: {
            value: _vm.visible,
            callback: function ($$v) {
              _vm.visible = $$v
            },
            expression: "visible",
          },
        },
        [
          _c(
            "template",
            { slot: "content" },
            [
              _c(
                "a-button",
                {
                  staticClass: "auto-btn",
                  attrs: { size: "small", block: "" },
                  on: {
                    click: function ($event) {
                      return _vm.onChange(_vm.initColor)
                    },
                  },
                },
                [_vm._v("\n                自动\n            ")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "color-title" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.$t("rich.primaryColor")) +
                    "\n            "
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "primary-color-list" },
                _vm._l(
                  _vm.$options.PrimaryColors,
                  function (primaryColor, idx) {
                    return _c(
                      "div",
                      {
                        key: primaryColor,
                        staticClass: "primary-color-column",
                      },
                      [
                        _c("div", {
                          staticClass: "color-box primary-color-box",
                          style: "background-color: " + primaryColor + ";",
                          on: {
                            click: function ($event) {
                              return _vm.onChange(primaryColor)
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm._l(
                          _vm.$options.PrimaryRateColors[idx],
                          function (rateColor) {
                            return _c("div", {
                              key: rateColor,
                              staticClass: "color-box",
                              style: "background-color: " + rateColor + ";",
                              on: {
                                click: function ($event) {
                                  return _vm.onChange(rateColor)
                                },
                              },
                            })
                          }
                        ),
                      ],
                      2
                    )
                  }
                ),
                0
              ),
              _vm._v(" "),
              _c("div", { staticClass: "color-title" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.$t("rich.standardColor")) +
                    "\n            "
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "standard-color-list" },
                _vm._l(_vm.$options.StandardColors, function (standardColor) {
                  return _c("div", {
                    key: standardColor,
                    staticClass: "color-box standard-color-box",
                    style: "background-color: " + standardColor + ";",
                    on: {
                      click: function ($event) {
                        return _vm.onChange(standardColor)
                      },
                    },
                  })
                }),
                0
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("a-icon", { staticClass: "icon-down", attrs: { type: "down" } }),
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }