/**
 * @author KylesLight
 * @date 21/10/2017-1:18 PM
 * @file vue-config
 */

import Vue from 'vue';

import '@shared/client/vue-error-handler';
import component from './component';
import directive from './directive';
import filter from './filter';

Vue.use(component);
Vue.use(directive);
Vue.use(filter);

export default Vue;
