/**
 * @author giligili
 * @date 2021-06-25
 * @email zhimingwang@yangqianguan.com
 * @desc 跳转到其他路由
 */

export default routerName => ({
    data() {
        const {route: {meta: {yqgPrivileges = []}}} = this.$router.resolve({name: routerName});

        return {
            routerLink: this.$app.isAuthorized(yqgPrivileges)
        };
    },

    methods: {
        to(query = {}) {
            if (!this.routerLink) return;

            const url = this.$router.resolve({
                name: routerName || this.routerName,
                query
            });

            window.open(url.href, '_blank');
        }
    }
});

