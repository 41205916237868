<!-- @author huayizhang -->
<!-- @email huayizhang@yangqianguan.com -->
<!-- @date 2020-05-29 17:14:58.117 -->
<!-- @desc generated by yqg-cli@1.1.9 -->

<template>
    <div class="yqg-color-select">
        <div
            v-b-click-outside="() => closePopper()"
            class="popper-group"
        >
            <div class="picker-button-wrapper">
                <button
                    :class="['picker-button', {empty: !model}]"
                    :style="genStyle(model)"
                    :disabled="disabled"
                    @click="openPopper"
                >
                    <i class="b-icon-bottom icon" />
                </button>
            </div>

            <b-popper :visible="visibility">
                <div class="action-card">
                    <button
                        class="reset-button"
                        @click="onReset"
                    >
                        <i class="b-icon-cross icon" />
                    </button>

                    <button
                        v-for="color in colors"
                        :key="color"
                        :class="['color-button', {selected: color === model}]"
                        :style="genStyle(color)"
                        @click="onChoose(color)"
                    />
                </div>
            </b-popper>
        </div>
    </div>
</template>

<script type="text/babel">

export default {
    name: 'YqgColorSelect2',

    props: {
        value: {
            type: String,
            default: ''
        },

        colors: {
            type: Array,
            default: () => ([])
        },

        disabled: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            visibility: false
        };
    },

    computed: {
        model: {
            get() {
                return this.value;
            }
        }
    },

    methods: {
        onChoose(color) {
            const vm = this;

            vm.$emit('input', color);
            vm.closePopper();
        },

        onReset() {
            const vm = this;

            vm.$emit('input', '');
            vm.closePopper();
        },

        genStyle: color => `backgroundColor:${color || 'transparent'};`,

        closePopper() {
            const vm = this;

            vm.visibility = false;
        },

        openPopper() {
            const vm = this;

            vm.visibility = true;
        }
    }
};

</script>

<style lang="scss" rel="stylesheet/scss" scoped>

    @import "~yqg-common/vue/style/variables.scss";

    $wrapper-length: 40px; // 外边框边长
    $color-length: 30px; // 色块边长
    $color-gap: 8px; // 色块间距
    $count: 5; // 一行放几个

    .yqg-color-select {
        width: $wrapper-length;

        button {
            padding: 0;
            border-radius: 0;
        }

        .picker-button-wrapper {
            width: $wrapper-length;
            height: $wrapper-length;
            padding: 4px;
            box-sizing: border-box;
            border: 1px solid #e6e6e6;
            border-radius: 4px;

            button.picker-button {
                width: 100%;
                height: 100%;
                border: 1px solid $gray-dark;

                &.empty {
                    .icon {
                        color: $gray-dark;
                    }
                }

                &:disabled {
                    cursor: not-allowed;
                }
            }
        }

        .popper-group {
            position: relative;
            height: $wrapper-length;

            ::v-deep .b-popper-wrap {
                position: static;

                .b-popper {
                    left: 50% !important;
                    top: 100% !important;
                    transform: translateX(-50%);
                    z-index: 9999;

                    .action-card {
                        display: flex;
                        flex-wrap: wrap;
                        padding: $color-gap;
                        padding-bottom: 0;
                        width: $count * $color-length + ($count - 1) * $color-gap; // 最多容纳五个的宽度
                        background-color: white;

                        button {
                            width: $color-length;
                            height: $color-length;
                            margin: 0 0 $color-gap $color-gap;

                            &:nth-child(5n+1) {
                                margin-left: 0;
                            }

                            &.color-button {
                                border: none;

                                &.selected {
                                    box-shadow: 0 0 3px 2px #409eff;
                                }
                            }

                            &.reset-button {
                                background-color: white;
                                color: $gray-dark;
                                border: 1px solid $gray-dark;
                            }
                        }
                    }
                }
            }
        }
    }

</style>
