/*
 * Author: giligili
 * Created Date: 2021-05-13
 * email: zhimingwang@yangqianguan.com
 * desc: 账号审批的状态
 */

export default {
    INIT: 'INIT', // 审批中
    CERTIFIED: 'CERTIFIED', // 审批通过
    DENIED: 'DENIED' // 审批拒绝
};
