/*
 * Author: xymo
 * Created Date: 2021-04-21
 * email: xymo@yangqianguan.com
 */

import Resource from '@yqg/resource';

const api = {
    listAllRoleGroup: {
        url: '/admin/operation/col/privilege/listAllRoleGroup',
        method: 'get'
    }
};

export default Resource.create(api);
