var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "file-wrapper card", class: _vm.stateClass },
    [
      _c("span", { staticClass: "state-text" }, [
        _vm._v("[" + _vm._s(_vm.stateText) + "] "),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "file-size" }, [
        _vm._v("[" + _vm._s(_vm._f("yqgUnitByte")(_vm.file.size)) + "]"),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "file-name" }, [_vm._v(_vm._s(_vm.file.name))]),
      _vm._v(" "),
      _c("div", { staticClass: "btn-group" }, [
        _c(
          "button",
          {
            staticClass: "sm normal",
            attrs: { disabled: _vm.disabled },
            on: {
              click: function ($event) {
                return _vm.onUpload()
              },
            },
          },
          [_c("i", { staticClass: "b-icon-upload" })]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "sm danger",
            on: {
              click: function ($event) {
                return _vm.onDelete()
              },
            },
          },
          [_c("i", { staticClass: "b-icon-trash" })]
        ),
      ]),
      _vm._v(" "),
      _c("div", {
        staticClass: "progress-bar",
        style: { width: _vm.progress },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }