/*
 * Author: xymo
 * Created Date: 2021-04-13
 * email: xymo@yangqianguan.com
 */

import Vue from 'vue';
import Vuex from 'vuex';
import CreateLogger from 'vuex/dist/logger';

import {STAGE} from 'yqg-common/constant/AppConfig';
import CommonConfig from 'yqg-common/vue/constant/CommonConfig';

import modules from './modules';

Vue.use(Vuex);

const store = new Vuex.Store({
    modules,
    strict: CommonConfig.vueDebug,
    plugins: STAGE === 'dev'
        ? [CreateLogger()]
        : []
});

if (module.hot) { // 开发模式热更新
    module.hot.accept(['./modules'], () => {
        store.hotUpdate({
            modules: require('./modules').default // eslint-disable-line
        });
    });
}

export default store;
