/*
 * @Author: yefenghan 
 * @Date: 2024-08-13 16:02:35 
 * @Last Modified by: yefenghan
 * @Last Modified time: 2024-08-17 19:41:34
 */
import EVENT from './event';

export default {
    [EVENT.TOUCH_BATCH_SEND_SMS]: '批量发送短信',
    [EVENT.TOUCH_ASSIGN_CASE_TO_IVR]: 'IVR拨打',
    [EVENT.TOUCH_BATCH_ASSIGN_CASE_TO_IVR]: 'IVR批量拨打',
    [EVENT.TOUCH_CASE_SEND_SMS]: '案件详情顶部发送短信',
    [EVENT.TOUCH_CASE_COLLECTION_SEND_SMS]: '案件详情发送催收短信',
    [EVENT.TOUCH_SEND_AGREEMENT_SMS]: '案件详情生成协议发送短信',
    [EVENT.TOUCH_H5_REPAY_SEND_SMS]: '发送H5快捷还款券',
    [EVENT.TOUCH_SEND_INSTALMENT_COUPON]: '发送奖励提现券',
    [EVENT.TOUCH_SEND_SETTLE_IN_ADVANCE_COUPON]: '发送结算减免券',

    [EVENT.ADD_FLOW]: '添加催记',
    [EVENT.ADD_COMPLAINT_FLOW]: '添加投诉催记',

    [EVENT.YTALK_DIAL_INPUT]: '手动输入开始拨打',
    [EVENT.YTALK_DIAL_COMPONENT]: '外呼组件开始拨打',
    [EVENT.YTALK_SEAT_HANGUP]: '坐席手动挂断',
    [EVENT.YTALK_TRANSFER_SATIS_IVR]: '转发满意度匹配',
    [EVENT.YTALK_ADD_CONNECT_CALL_DETAIL]: '添加接通通话记录',
    [EVENT.YTALK_ADD_UNCONNECT_CALL_DETAIL]: '添加未接通通话记录',
    [EVENT.YTALK_START_AGENT_COPILOT]: '开启坐席辅助',
    [EVENT.YTALK_CHANGE_WORK_STATUS]: '坐席工作状态切换'
};
