<!-- @Author: xymo -->
<!-- @Date: 2023-09-06 10:54:16 -->
<!-- @Last Modified by: xymo -->
<!-- @Last Modified time: 2023-09-06 10:54:16 -->

<template>
    <div class="agent-copilot">
        <a-collapse default-active-key="1">
            <a-collapse-panel
                key="1"
                header="坐席辅助"
            >
                <div
                    v-for="item in formatCopilotInfoList"
                    :key="item.id"
                    ref="content"
                    class="content"
                >
                    <div class="question">
                        <span class="question-label">
                            用户可能想要了解：
                        </span>

                        <span class="question-content">
                            <html-text :text="item.question" />
                        </span>
                    </div>

                    <p class="answer-tips">
                        推荐话术
                    </p>

                    <div
                        v-for="(answer, index) in item.answerList"
                        :key="index"
                        class="answer"
                    >
                        <div class="answer-item">
                            <span class="answer-tips"> 答案{{ index+1 }}：</span>
                            <html-text :text="answer.answer" />
                        </div>
                    </div>

                    <a-divider />
                </div>
            </a-collapse-panel>
        </a-collapse>
    </div>
</template>

<script type="text/babel">

    import htmlText from './components/html-text';

    export default {
        name: 'AgentCopilot',

        components: {
            htmlText
        },

        props: {
            copilotInfoList: {
                type: Array,
                default: () => []
            }
        },

        computed: {
            formatCopilotInfoList() {
                let id = 0;

                return this.copilotInfoList.map(({question, answerList = []}) => {
                    id++;

                    return {
                        id,
                        question,
                        answerList: answerList?.map(({answer}) => {
                            return {
                                answer
                            };
                        })
                    };
                });
            }
        }
    };

</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .agent-copilot {
        margin-bottom: 10px;
        width: 370px;
        max-height: 200px;
        overflow: scroll;

        .content {
            .question {
                font-size: 14px;
                font-weight: bold;
                margin-bottom: 10px;

                &-label {
                    color: rgb(24, 144, 255);
                }
            }

            .answer {
                font-size: 14px;

                &-tips {
                    color: rgb(24, 144, 255);
                    font-size: 14px;
                    font-weight: bold;
                    margin-bottom: 10px;
                }

                &-item {
                    margin-bottom: 10px;
                }
            }
        }
    }

</style>
