/**
 * @desc 管理登录
 * 无登出失败的情况（即使 Register(expire: 0) 没有发出去，也会认为登出成功）
 */
import { HubClient } from '../hub';
// enum Status {
//     INIT, // 未登录
//     REGISTERING,
//     UN_REGISTERING,
//     REGISTER_SUCCESSED,
//     REGISTER_FAILED,
// };
// const JsSipCauses = JsSIP.C.causes;
class RegisterHubClient extends HubClient {
    constructor(options) {
        super(Object.assign(Object.assign({}, options), { name: RegisterHubClient.clientName }));
        this.handleRegistered = () => {
            this.client.emit('REGISTER_SUCCESSED');
        };
        this.handleUnRegistered = () => {
            this.client.emit('UN_REGISTER_SUCCESSED');
        };
        this.listenRegister = () => {
            // 登录成功
            this.device.addListener('registered', this.handleRegistered);
            // 登录失败
            // 登出成功
            this.device.addListener('unregistered', this.handleUnRegistered);
        };
        this.removeRegister = () => {
            this.device.removeListener('registered', this.handleRegistered);
            this.device.removeListener('unregistered', this.handleUnRegistered);
        };
        this.overrideRegisterCallId = () => {
            const { device, page } = this;
            if (!page || !device)
                return;
            const callId = device._registrator._call_id; // eslint-disable-line
            // 更改 callId
            const registerCallId = [page.browserId, page.id, callId].filter(vl => vl).join('_');
            this.registerCallId = registerCallId;
            device._registrator._call_id = registerCallId; // eslint-disable-line
        };
        this.getRaw = () => {
            return {
                callId: this.registerCallId
            };
        };
        this.destroy = () => {
            this.registerCallId = null;
            this.removeRegister();
            this.client.emit('REGISTER_DISTROY');
        };
        const { device, hubService } = options;
        this.device = device;
        // this.status = Status.REGISTERING;
        hubService.getClient('pageClient', (client) => {
            this.page = client.getRaw();
            this.overrideRegisterCallId();
        });
        this.listenRegister();
        super.register();
    }
}
RegisterHubClient.clientName = 'registerClient';
export default RegisterHubClient;
