<!-- @author giligili -->
<!-- @email zhimingwang@yangqianguan.com -->
<!-- @date 2021-07-21 12:51:06.029 -->
<!-- @desc generated by yqg-cli@5.1.1 -->

<template>
    <div class="alarm-flow-end">
        您于 {{ notification.data.timeRemind | dateTime }} 收到一个 {{ notification.data.remark }} 提醒

        <span
            v-if="processFlowType !== 'CREATE_USER'"
            @click="finishNotification(notification.data)"
        >
            <yqg-router-link
                text="请查看"
                to="qualityReviewFlow"
                :query="{processFlowId}"
            />
        </span>

        <span
            v-else
            @click="finishNotification(notification.data)"
        >
            <yqg-router-link
                text="请查看"
                to="account"
                :query="{processFlowId}"
            />
        </span>
    </div>
</template>

<script type="text/babel">

    import {mapActions} from 'vuex';

    export default {
        name: 'AlarmFlowEnd',

        props: {
            notification: {
                type: Object,
                default: () => ({})
            }
        },

        computed: {
            processFlowType() {
                return this.notification?.data?.extraData?.processFlowType;
            },

            processFlowId() {
                return this.notification?.data?.extraData?.processFlowId;
            }
        },

        methods: {
            ...mapActions('notification', ['finishNotification'])
        }
    };
</script>
