<!-- @Author: giligili -->
<!-- @Date: 2023-05-06 11:44:06 -->
<!-- @Last Modified by: giligili -->
<!-- @Last Modified time: 2023-05-06 11:44:06 -->

<template>
    <img
        v-if="$app.permissions.EXTRA_ADMIN_SYSTEM_JUMP"
        class="loan-admin-link"
        :src="$options.icon"
        @click="jump2LoanAdmin"
    >
</template>

<script type="text/babel">

    import StageUtil from 'yqg-common/core/StageUtil';

    import icon from './imgs/icon.png';

    const TEST_DOMAIN = 'https://loan-admin-test.yangqianguan.com';
    const PROD_DOMAIN = 'https://loan-admin.fintopia.tech';

    const domain = StageUtil.isProdEnv() ? PROD_DOMAIN : TEST_DOMAIN;

    export default {
        name: 'LoanAdminLink',

        icon,

        props: {
            query: {
                type: Object,
                default: () => ({})
            },

            path: {
                type: String,
                required: true
            }
        },

        methods: {
            jump2LoanAdmin() {
                const {query, path} = this;

                const queryString = Object.entries(query)
                    .map(([key, value]) => `${key}=${value}`)
                    .join('&');

                const url = `${domain}${path}?${queryString}`;

                window.open(url);
            }
        }
    };

</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .loan-admin-link {
        width: 24px;
        cursor: pointer;
    }

</style>
