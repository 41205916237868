<!-- @author chengyuzhang -->
<!-- @email chengyuzhang@yangqianguan.com -->
<!-- @date 2020-08-31 14:48:05.472 -->
<!-- @desc generated by yqg-cli@1.2.9 -->

<template>
    <div class="yqg-transfer">
        <div
            v-if="sourceRecords.length"
            class="select-wrapper"
        >
            <div class="source-wrapper">
                <slot name="sourceHeader" />
                <yqg-simple-table
                    :options="processSourceTableOptions"
                    :records="sourceRecords"
                />
            </div>

            <a-icon
                class="icon"
                type="caret-right"
            />

            <div class="target-wrapper">
                <slot name="targetHeader" />
                <yqg-simple-table
                    :options="processTargetTableOptions"
                    :records="targetRecords"
                    simple-empty
                >
                    <template #op="record">
                        <a-popconfirm
                            title="是否确认删除"
                            @confirm="deleteItem(record)"
                        >
                            <a-button
                                size="small"
                                type="danger"
                                icon="delete"
                            />
                        </a-popconfirm>
                    </template>
                </yqg-simple-table>
            </div>
        </div>

        <div
            v-else
            class="no-data"
        >
            暂无数据
        </div>
    </div>
</template>

<script type="text/babel">
import _ from 'underscore';

export default {
    name: 'YqgTransfer',

    props: {
        sourceTableOptions: {
            type: Object,
            required: true
        },

        targetTableOptions: {
            type: Object,
            default: () => ({})
        },

        sourceRecords: {
            type: Array,
            default: () => []
        },

        defaultTargetList: {
            type: Array,
            default: () => []
        }
    },

    data() {
        return {
            targetList: [],
            targetRecords: []
        };
    },

    computed: {
        processSourceTableOptions() {
            const {sourceTableOptions} = this;

            return {
                rowSelection: {
                    columnWidth: 37,
                    selectedRowKeys: this.targetList,
                    onChange: this.onSelectChange,
                    getCheckboxProps: record => ({
                        props: {
                            disabled: record.selected
                        }
                    })
                },
                search: true,
                pagination: false,
                scroll: {x: 290},
                ...sourceTableOptions
            };
        },

        processTargetTableOptions() {
            const {
                targetTableOptions: {colDefs, ...rest},
                sourceTableOptions: {colDefs: sourceColDefs, ...sourceRest}} = this;

            if (colDefs) {
                return {
                    search: true,
                    pagination: false,
                    scroll: {x: 290},
                    colDefs: [
                        ...colDefs,
                        {field: 'op', column: {width: 60}}
                    ],
                    ...rest
                };
            }

            return {
                search: true,
                pagination: false,
                scroll: {x: 290},
                colDefs: [
                    ...sourceColDefs,
                    {field: 'op', column: {width: 60}}
                ],
                ...sourceRest
            };
        }
    },

    mounted() {
        const {defaultTargetList, sourceRecords} = this;
        const {length} = sourceRecords;
        let recordsPoint = 0;

        if (defaultTargetList.length) {
            const selectedRecords = [];
            for (let i = 0; i < length; i += 1) {
                if (sourceRecords[i].id === defaultTargetList[recordsPoint]) {
                    selectedRecords.push(sourceRecords[i]);
                    recordsPoint += 1;
                }
            }

            this.onSelectChange(defaultTargetList, selectedRecords);
        }
    },

    methods: {
        onSelectChange(selectedList, selectedRecords) {
            this.targetList = selectedList.sort((item1, item2) => item1 - item2);
            const targetRecords = this.targetRecords
                .concat(selectedRecords)
                .sort((left, right) => left.id - right.id);
            this.targetRecords = _.uniq(targetRecords, true, _.iteratee(val => val.id));

            this.$emit('change', {
                targetList: this.targetList,
                targetRecords: this.targetRecords
            });

            for (let i = 0; i < selectedRecords.length; i += 1) {
                this.$set(selectedRecords[i], 'selected', true);
            }
        },

        deleteItem({record, index}) {
            const {targetList, targetRecords} = this;

            this.$delete(record, 'selected');
            this.$delete(targetList, index);
            this.$delete(targetRecords, index);
            this.$emit('change', {targetList, targetRecords});
        }
    }

};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.yqg-transfer {
    .select-wrapper {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        .icon {
            align-self: center;
        }

        & ::v-deep .yqg-simple-table {
            width: 290px;

            .ant-table-tbody tr td {
                white-space: normal;
            }
        }
    }
}
</style>
