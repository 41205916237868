/**
 * @author giligili
 * @date 2021/07/27
 * @file reduce-rule
 */

export default `
    提现规则：发送“奖励券”后，客户可在APP [个人中心 - 我的优惠券] 列表中查看。
    “奖励券”在客户结清前不可使用，客户按照剩余待还金额全额还款后，可点击“奖励券”上的【去领取】按钮，输入银行卡信息进行提现。
    “奖励券”失效前不允许发放新券。
`;

