var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "alarm-feedback-flow" },
    [
      _vm._v("\n    【反馈提醒】\n\n    "),
      _c("span", { staticClass: "mr10" }, [
        _vm._v(_vm._s(_vm._f("dateTime")(_vm.notification.data.timeRemind))),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "mr10" }, [
        _vm._v(_vm._s(_vm.notification.data.remark)),
      ]),
      _vm._v(" "),
      _c("yqg-router-link", {
        staticClass: "mr10",
        attrs: {
          text: "请及时处理",
          hash: _vm.hash,
          to: _vm.to,
          query: _vm.query,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }