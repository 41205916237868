<!-- @Author: huayizhang -->
<!-- @Date: 2021-07-21 18:24:32.020 -->
<!-- @Last Modified by: xymo -->
<!-- @Last Modified time: 2024-02-21 15:13:20 -->

<template>
    <a-popover
        :title="title"
        placement="bottom"
    >
        <ul
            slot="content"
            class="feature-tips"
        >
            <li
                v-for="(tipText, index) in wechatHashCodeTipsText"
                :key="index"
            >
                {{ tipText }}
            </li>
        </ul>
        <a-icon
            v-if="$app.privileges.CASE.DETAIL.GET.MOBILE.HASH.CODE"
            class="yqg-add-official-wechat-friend"
            type="copy"
            @click="openSelectSymbolModal"
        />
    </a-popover>
</template>

<script type="text/babel">

    import CorpWechat from 'collection-admin-web/common/resource/corp-wechat';
    import {clipboard} from 'collection-admin-web/common/util/tool';

    import SelectSymbolModal from './modal/select-symbol-modal';
    import WechatHashCodeModal from './modal/wechat-hash-code-modal';

    const ERROR_MAP = {
        GET_MODILE_HASH_CODE: {
            toast: '查询手机号随机码失败!',
            type: 'GET_MODILE_HASH_CODE'
        }
    };

    export default {
        name: 'YqgAddOfficialWechatFriend',

        props: {
            caseId: {
                type: String,
                default: ''
            },

            phoneBookId: {
                type: [String, Number],
                default: ''
            }
        },

        data() {
            return {
                title: '复制手机号随机码',
                wechatHashCodeTipsText: [
                    '手机号随机码用于企业微信添加用户',
                    '每一个随机码的有效时间为30分钟',
                    '若随机码失效, 请重新复制'
                ]
            };
        },

        methods: {
            copyUserWechatHashCode(symbol) {
                const vm = this;
                const {caseId, phoneBookId} = vm;

                CorpWechat.getMobileHashcode({params: {caseId, phoneBookId, symbol}}).then(({data: {body}}) => {
                    if (body) return body;

                    // eslint-disable-next-line prefer-promise-reject-errors
                    return Promise.reject({type: ERROR_MAP.GET_MODILE_HASH_CODE.type});
                }).then(vm.write2SystemClipboard, vm.handleRequestError);
            },

            write2SystemClipboard(text) {
                const vm = this;

                clipboard.write(text)
                    .then(
                        () => vm.$app.opSuccess('复制手机号随机码'),
                        () => vm.openUserWechatHashCodeModal(text)
                    );
            },

            openSelectSymbolModal() {
                const vm = this;

                vm.$modal.open(SelectSymbolModal).then(({symbol}) => this.copyUserWechatHashCode(symbol));
            },

            openUserWechatHashCodeModal(code) {
                const vm = this;

                vm.$message.info('复制手机号随机码失败, 请手动复制并联系开发人员!');

                vm.$modal.open(WechatHashCodeModal, {code});
            },

            handleRequestError({type}) {
                const vm = this;

                if (ERROR_MAP[type]) {
                    vm.$message.error(ERROR_MAP[type].toast || '未知错误');
                }
            }
        }
    };

</script>
