import ReportStrategy from './strategy';
// const getOfflineTimeout = (radio = 4) => {
//     const cacheOfflineTimeout = window.localStorage.getItem('_ytalk_log_offline_timeout');
//     let offlineTimeout = cacheOfflineTimeout ? +cacheOfflineTimeout : 0;
//     offlineTimeout = offlineTimeout % 7;
//     offlineTimeout = offlineTimeout + 1;
//     window.localStorage.setItem('_ytalk_log_offline_timeout', `${offlineTimeout}`);
//     return offlineTimeout * radio;
// };
// const offlineTimeout = getOfflineTimeout();
class YtalkMetric {
    constructor(options) {
        this.apply = (options) => {
            const { ytalk, hubService } = options;
            hubService.getClient('sessionClient', (client) => {
                this.sessionClient = client.getClient();
                this.listenSession();
            });
            hubService.getClient('messageClient', (client) => {
                this.messageClient = client.getClient();
                this.listenMessage();
            });
            const { stage, country, url } = this.options;
            this.exception = new ReportStrategy({
                limitRate: 1,
                stage,
                country,
                url,
                dbName: 'ytalk_exception',
                tbName: 'exception',
                version: 1,
                limit: 5,
                getDefaultData: this.options.getDefaultData,
            }, {
                appId: 'web_ytalk_exception',
                appVersion: ytalk.getVersion(),
                measurement: 'app_metrics_for_web_ytalk_exception',
            });
            this.rtpMetric = new ReportStrategy({
                limitRate: 1,
                stage,
                country,
                url,
                dbName: 'ytalk',
                tbName: 'rtpMetric',
                version: 1,
                limit: 3,
                getDefaultData: this.options.getDefaultData,
            }, {
                appId: 'web_ytalk_rpt',
                appVersion: ytalk.getVersion(),
                measurement: 'app_metrics_for_web_ytalk_rtp',
            });
        };
        this.rtpStats = ({ session, stats }) => {
            const { uuid: sessionUuid, callUuid, callType, connected } = session;
            const { input, output } = stats;
            if (!this.rtpMetric)
                return;
            const data = {
                sessionUuid,
                callUuid,
                callType,
                connected,
                input,
                output,
                type: 'RTP_STATS',
            };
            this.rtpMetric.report(data);
        };
        this.loginCheck = ({ reason }) => {
            this.exceptionReport({ type: 'ABNORMAL_LOGOUT', reason });
        };
        this.handleSessionException = ({ session, reason, message }) => {
            const { uuid: sessionUuid, callUuid, callType, connected } = session;
            this.exceptionReport({
                type: 'SESSION_EXCEPTION',
                sessionUuid,
                callUuid,
                callType,
                connected,
                reason,
                message,
            });
        };
        this.listenSession = () => {
            if (!this.sessionClient)
                return;
            this.sessionClient.addListener('RTP_STATS_END', this.rtpStats);
            this.sessionClient.addListener('SESSION_EXCEPTION', this.handleSessionException);
        };
        this.removeSession = () => {
            if (!this.sessionClient)
                return;
            this.sessionClient.removeListener('RTP_STATS_END', this.rtpStats);
            this.sessionClient.removeListener('SESSION_EXCEPTION', this.handleSessionException);
        };
        this.listenMessage = () => {
            if (!this.messageClient)
                return;
            this.messageClient.addListener('LOGIN_CHECK', this.loginCheck);
        };
        this.removeMessage = () => {
            if (!this.messageClient)
                return;
            this.messageClient.removeListener('LOGIN_CHECK', this.loginCheck);
        };
        this.destroy = () => {
            this.removeSession();
            this.removeMessage();
        };
        this.options = Object.assign({}, {
            getDefaultData: () => ({})
        }, options);
    }
    exceptionReport(data = {}) {
        if (!this.exception)
            return;
        this.exception.report(Object.assign({
            type: null,
            reason: null,
            message: null,
            sessionUuid: null,
            callUuid: null,
            callType: null,
            callStatus: null,
            connected: null,
            socketCode: null,
            socketWasClean: null,
            socketReason: null,
            duration: null,
            localRegisterCallId: null,
            remoteRegisterCallId: null,
            pageId: null,
            browserId: null,
            devicePageId: null,
            device: null,
            inCalling: null,
            behavior: null,
        }, data));
    }
}
export default YtalkMetric;
