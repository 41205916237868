<template>
    <div>
        <div
            v-if="!isHideNav"
            v-b-click-outside="closeDropDownMenu"
            class="nav-menu-wrap container-wide"
        >
            <div class="nav container-wide mat">
                <div class="nav-menu container">
                    <div class="nav-drop">
                        <a
                            class="drop-down-icon"
                            @click="toggleDropDownMenu"
                        >
                            <span>
                                <i class="b-icon-unordered-list" />
                            </span>
                        </a>
                    </div>

                    <div
                        class="nav-left"
                        @click="closeDropDownMenu"
                    >
                        <slot name="left" />
                    </div>

                    <div class="nav-root-wrap level-center">
                        <ul class="level-center">
                            <li
                                v-for="route in routes.root"
                                :key="route.path"
                            >
                                <router-link
                                    :to="route"
                                    :class="{'active': isActive(route)}"
                                    class="nav-link"
                                >
                                    {{ getI18n(route.meta.navTitle) }}
                                </router-link>
                            </li>
                        </ul>
                    </div>

                    <div class="nav-center">
                        <slot name="center" />
                    </div>

                    <div class="nav-right">
                        <slot name="right" />
                    </div>
                </div>

                <transition name="nav-slide">
                    <div
                        v-if="isDropDownMenuOpen"
                        class="nav-drop-menu container-wide"
                    >
                        <ul class="container b-col">
                            <li
                                v-for="route in routes.root"
                                :key="route.path"
                                :class="{'active': isActive(route)}"
                            >
                                <div
                                    class="drop-menu-item"
                                    @click="dropMenuJump(route)"
                                >
                                    {{ getI18n(route.meta.navTitle) }}
                                </div>
                            </li>
                        </ul>
                    </div>
                </transition>
            </div>

            <div
                v-if="hasSubMenu && currentSubMenu"
                class="sub-nav container-wide level-center"
            >
                <ul class="level-center sub-nav-list">
                    <li
                        v-for="route in currentSubMenu"
                        :key="route.path"
                        :class="{'active': isActive(route)}"
                        class="sub-nav-item"
                    >
                        <router-link :to="route">
                            {{ getI18n(route.meta.navTitle) }}
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>

        <div class="nav-buffer" />
        <div
            v-if="hasSubMenu && currentSubMenu"
            class="sub-nav-buffer"
        />
    </div>
</template>

<script type="text/babel">

import {mapGetters} from 'vuex';

import StageType from 'yqg-common/constant/StageType';
import CommonConfig from 'yqg-common/vue/constant/CommonConfig';

export default {
    name: 'NavMenu',

    data() {
        return {
            isDropDownMenuOpen: false,

            documentInitTitle: document.title
        };
    },

    computed: {
        ...mapGetters(['isAuthorized']),

        routes() {
            const routes = {
                all: [], // all valid root & children routes
                root: [], // all valid root routes
                children: [] // all valid children routes
            };

            const vm = this;
            const {$RouterMap, isRouteRender, isAuthorized} = vm;

            // check the root route
            const rootRoutes = Object.values($RouterMap).filter(route => isRouteRender(route));

            // check the children routes
            // remove root route whose children routes all not pass the permission check
            routes.root = rootRoutes.filter(rootRoute => {
                let rootRouteShowOnNav = true;

                if (Array.isArray(rootRoute.children)) {
                    const validChildrenRoutes = rootRoute.children.filter(route => {
                        route.parent = rootRoute;

                        return isAuthorized(route);
                    });

                    if (validChildrenRoutes.length === 0) rootRouteShowOnNav = false;
                    routes.all = routes.all.concat(validChildrenRoutes);
                    routes.children = routes.children.concat(validChildrenRoutes);
                    rootRoute.children = [].concat(validChildrenRoutes);
                }

                return rootRouteShowOnNav;
            });

            routes.all = routes.all.concat(routes.root);

            return routes;
        },

        currentRoute() {
            const vm = this;
            const {routes: {all}, $route: {path}} = vm;

            return all.find(route => route.path === path) || {};
        },

        hasSubMenu() {
            const vm = this;
            const {currentRoute, currentSubMenu, isRouteRender} = vm;

            return currentRoute.parent && currentSubMenu.some(route => isRouteRender(route));
        },

        isHideNav() {
            const vm = this;
            const {$route: {meta = {}}} = vm;

            return meta.hideNav;
        },

        currentSubMenu() {
            const vm = this;
            const {currentRoute} = vm;

            return currentRoute.parent && currentRoute.parent.children;
        }

    },

    created() {
        const vm = this;

        vm.initCheckRoutePrivilegeInterceptor();
    },

    methods: {
        getI18n(key) {
            return this.$t?.(key) || key;
        },

        isActive({path, parent}) {
            const vm = this;
            const {currentRoute} = vm;

            if (currentRoute.parent) {
                return parent ? path === currentRoute.path : path === currentRoute.parent.path;
            }

            return path === currentRoute.path;
        },

        isRouteRender(route) {
            const vm = this;
            const {isAuthorized} = vm;
            const {meta = {}} = route;

            return meta.navTitle && isAuthorized(route);
        },

        toggleDropDownMenu() {
            const vm = this;
            vm.isDropDownMenuOpen = !vm.isDropDownMenuOpen;
        },

        closeDropDownMenu() {
            const vm = this;

            vm.isDropDownMenuOpen = false;
        },

        dropMenuJump(route) {
            const vm = this;

            vm.$router.replace(route);
            vm.isDropDownMenuOpen = false;
        },

        initCheckRoutePrivilegeInterceptor() {
            const vm = this;
            const {routes: {all}, $route, $RouterMap, $router, isAuthorized, documentInitTitle} = vm;

            const checkRoutePrivilege = (route, next) => {
                if (!isAuthorized(route)) {
                    vm.$toast.error('不支持访问该路由', 3000);

                    return $router.replace($RouterMap.index);
                }

                if (route.meta && route.meta.redirectValidChild) {
                    const checkRoute = all.find(({path}) => path === route.path) || route;

                    return $router.replace(checkRoute.children[0]);
                }

                const HostNameWhiteList = [
                    'chidori-admin-easycash.geteasycash.asia' // chidori feat 环境用于ojk demo 的域名不加prefix
                ];
                const inWhiteList = HostNameWhiteList.includes(location.hostname);
                const prefix = (CommonConfig.stage === StageType.prod || inWhiteList)
                    ? ''
                    : `(${CommonConfig.stage}) `;
                const tit = this.getI18n(route.meta.navTitle);
                const postfix = (route.meta && tit) ? ` - ${tit}` : '';
                document.title = `${prefix}${documentInitTitle}${postfix}`;

                return next && next();
            };

            $router.beforeEach((to, from, next) => {
                checkRoutePrivilege(to, next);
            });

            checkRoutePrivilege($route);
        }
    }

};

</script>
