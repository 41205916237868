/*
 * @Author: wenxiujiang 
 * @Date: 2023-07-20 06:26:31 
 * @Last Modified by: suzilin
 * @Last Modified time: 2023-07-24 15:19:21
 */

import {merge, qiniuFileDef} from '../../../constant/fields';
import Fields from './fields';

export default ({fileDef = qiniuFileDef, multiple = false}) => {
    const fieldAudio = merge(fileDef, Fields.audio);
    const acceptArr = ['MP3', 'WAV', 'MP2', 'AAC', 'MOV'];
    if (multiple) {
        fieldAudio.props = merge(fieldAudio.props, {
            single: !multiple,
            draggable: true,
            accept: acceptArr.map(ext => `.${ext.toLowerCase()}`).join(','),
            valueField: ''
        });
    }

    return {
        layout: 'horizontal',
        fieldDefs: [
            fieldAudio
        ]
    };
};
