import { createClientId } from '../helper';
import { HubClient } from '../hub';
class PageHubClient extends HubClient {
    constructor(options) {
        super(Object.assign(Object.assign({}, options), { name: PageHubClient.clientName }));
        this.initBrowser = () => {
            const localBrowserId = window.localStorage.getItem('_BROWSER_ID_');
            this.browserId = localBrowserId || createClientId();
            window.localStorage.setItem('_BROWSER_ID_', this.browserId);
        };
        this.initPage = () => {
            const localId = window.sessionStorage.getItem('_PAGE_ID_');
            this.id = localId || createClientId();
            window.sessionStorage.setItem('_PAGE_ID_', this.id);
        };
        this.isDevicePage = () => {
            return this.ytalk.isDevicePage();
        };
        this.onBeforeunload = (event) => {
            var _a;
            if (!this.isDevicePage())
                return;
            this.client.emit('REFRESH');
            const message = '确定关闭页面?';
            if (event) {
                event.returnValue = message;
                (_a = event.preventDefault) === null || _a === void 0 ? void 0 : _a.call(event);
            }
            return message;
        };
        this.getRaw = () => {
            const { browserId, id } = this;
            return {
                browserId,
                id,
                devicePage: this.isDevicePage(),
            };
        };
        this.destroy = () => {
            window.removeEventListener('beforeunload', this.onBeforeunload);
        };
        const { ytalk } = options;
        this.ytalk = ytalk;
        this.id = '';
        this.initPage();
        this.browserId = '';
        this.initBrowser();
        window.addEventListener('beforeunload', this.onBeforeunload);
        super.register();
    }
}
PageHubClient.clientName = 'pageClient';
export default PageHubClient;
