<!-- @author yanglan -->
<!-- @email yanglan@yangqianguan.com -->
<!-- @date 2018-05-11 11:23:53.268 -->
<!-- @desc generated by yqg-cli@0.1.0-beta.8 -->

<script type="text/babel">

import enumOptionsConvert from '../../constant/enum/enum-options-convert';

export default {
    name: 'YqgTable',

    props: {
        records: {
            type: Array,
            required: true
        },

        options: {
            type: Object,
            required: true
        },

        pagination: {
            type: Object,
            default: () => {}
        },

        deep: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            innerOptions: null
        };
    },

    created() {
        const vm = this;

        vm.updateInnerOptions();

        vm.$watch('options', this.updateInnerOptions, {
            immediate: this.deep,
            deep: this.deep
        });
    },

    methods: {
        updateInnerOptions() {
            const vm = this;

            enumOptionsConvert(vm.options, vm.updateEnumColDef, 'colDefs').then(options => {
                const {colDefs} = options;
                vm.innerOptions = {
                    ...options,
                    colDefs: colDefs.filter(({isHide}) => !isHide)
                };
                vm.$emit('enum-fetched');
            }).catch(err => err);
        },

        updateEnumColDef(colDef) {
            return colDef.enumType ? {...colDef, map: colDef.enumType.MAP} : colDef;
        }

    },

    render(createElement) {
        const vm = this;
        const {
            records,
            innerOptions,
            pagination,
            $scopedSlots,
            $slots,
            $listeners
        } = vm;

        return innerOptions ? createElement('b-table', {
            props: {
                ...vm.$attrs,
                records,
                options: innerOptions,
                pagination
            },
            scopedSlots: {
                ...$scopedSlots,
                ...Object.keys($slots).reduce((acc, key) => {
                    acc[key] = () => $slots[key];

                    return acc;
                }, {})
            },
            on: {
                ...$listeners
            }
        }) : null;
    }
};

</script>
