<!-- @Author: ruiwang -->
<!-- @Date: 2020-3-13 14:12:44 -->
<!-- @Last Modified by: shaotanliang -->
<!-- @Last Modified time: 2023-10-08 16:37:47 -->

<template>
    <div class="ant-table ant-table-bordered">
        <div class="ant-table-body">
            <table
                class="info-table"
                style="table-layout: fixed;"
            >
                <template v-for="(group, groupIdx) in tableOptions">
                    <thead
                        :key="groupIdx"
                        class="ant-table-thead"
                    >
                        <tr :key="groupIdx">
                            <th
                                v-for="({label, headerTip}, idx) in group"
                                :key="idx"
                            >
                                {{ $t(label) }}
                                <a-tooltip
                                    v-if="headerTip"
                                    :trigger="headerTip.trigger || 'hover'"
                                    :placement="headerTip.placement || 'top'"
                                >
                                    <template slot="title">
                                        <span>{{ headerTip.content }}</span>
                                    </template>
                                    <a-icon
                                        type="question-circle"
                                        :style="headerTip.style || {}"
                                    />
                                </a-tooltip>
                            </th>
                        </tr>
                    </thead>
                    <tbody
                        :key="`tbody-${groupIdx}`"
                        class="ant-table-tbody"
                    >
                        <tr>
                            <td
                                v-for="(def, idx) in group"
                                :key="idx"
                            >
                                <slot
                                    :name="def.field"
                                    v-bind="{def, value: pickValue(tableData, def.field), record: tableData, defaultText: '/'}"
                                >
                                    <component
                                        :is="def.staticComp || 'def-value'"
                                        v-bind="getCompProps({def, value: pickValue(tableData, def.field), record: tableData, defaultText: '/'})"
                                    />
                                </slot>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </table>
        </div>
    </div>
</template>

<script>
import _ from 'underscore';

import staticProps from '../mixin/static-props';
import {pickValue} from '../util/object';

export default {
    name: 'YqgInfoTable',

    mixins: [staticProps],

    props: {
        colNum: {
            type: Number,
            default: 6
        },

        tableTitle: {
            type: [Array],
            default: () => []
        },

        tableData: {
            type: [Object],
            default: () => ({})
        },

        options: {
            type: Object,
            default: () => ({})
        }
    },

    computed: {
        tableOptions() {
            const {colNum, options} = this;
            let {tableTitle} = this;
            tableTitle = tableTitle.map(({field, value, ...rest}) => ({field: field || value, ...rest}));

            return _.chunk((tableTitle.length && tableTitle) || options.colDefs, colNum);
        }
    },

    methods: {
        pickValue
    }
};
</script>

<style lang="scss">
.info-table {
    th,
    td {
        font-size: 0.9em;
        white-space: unset !important;
        word-wrap: break-word;
    }
}
</style>
