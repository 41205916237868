<!-- @author huayizhang -->
<!-- @email huayizhang@yangqianguan.com -->
<!-- @date 2021-07-20 16:59:04.561 -->
<!-- @desc generated by yqg-cli@1.2.7 -->

<template>
    <div
        class="yqg-collect-result-select"
        :class="{border: options.length > 1}"
    >
        <template v-for="(option, index) in options">
            <div
                :key="index"
                :class="{'collection-result-select-wrap': options.length > 1}"
            >
                <div
                    v-if="options.length > 1"
                    class="title"
                >
                    {{ option.title }}
                </div>

                <yqg-antv-radio
                    :value="value"
                    :enum-type="option.enumType"
                    @change="onChange"
                />
            </div>
        </template>
    </div>
</template>

<script type="text/babel">

    import CollectOptions from './constant/collect-options';
    import CollectType from './constant/collect-type';

    export default {
        name: 'YqgCollectResultSelect',

        props: {
            value: {
                type: String,
                default: ''
            },

            type: {
                type: String,
                default: CollectType.CALL
            }
        },

        computed: {
            options() {
                const {type} = this;

                return CollectOptions[type] || [];
            }
        },

        methods: {
            onChange(value) {
                this.$emit('change', value);
            }
        }
    };

</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .yqg-collect-result-select {
        display: flex;

        &.border {
            border: 1px solid #ccc;
        }

        .collection-result-select-wrap {
            display: inline-flex;
            flex-direction: column;
            align-items: center;
            flex: 1;

            &:first-child {
                border-right: 1px solid #ccc;
            }

            .title {
                width: 100%;
                padding: 6px;
                line-height: 22px;
                text-align: center;
                background: #eee;
            }

            ::v-deep .ant-radio-wrapper {
                display: block;
                height: 30px;
                line-height: 30px;
            }
        }
    }

</style>
