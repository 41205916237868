<!-- @author giligili -->
<!-- @email zhimingwang@yangqianguan.com -->
<!-- @date 2021-07-30 13:52:19.408 -->
<!-- @desc generated by yqg-cli@5.0.2 -->

<template>
    <div ref="smsEl">
        <a-popover
            v-model="visible"
            class="header-sms"
            trigger="click"
            placement="leftBottom"
            :get-popup-container="() => $refs.smsEl"
        >
            <a-icon
                type="mail"
                @click="fetchSmsList"
            />

            <template #content>
                <yqg-simple-table
                    :records="records"
                    :options="$options.TableOptions"
                >
                    <template #op="{record}">
                        <a-button
                            size="small"
                            type="danger"
                            @click="cancelSend(record)"
                            v-text="'取消'"
                        />
                    </template>
                </yqg-simple-table>
            </template>
        </a-popover>
    </div>
</template>

<script type="text/babel">

    import CaseDetail from 'collection-admin-web/common/resource/case/detail';

    import TableOptions from './constant/table-options';

    export default {
        name: 'HeaderSms',

        TableOptions,

        data() {
            return {
                visible: false,

                records: []
            };
        },

        methods: {
            fetchSmsList() {
                CaseDetail.listDelayMessage()
                    .then(({data: {body: {messages}}}) => {
                        this.records = messages;

                        this.visible = true;
                    })
                    .catch(err => err);
            },

            cancelSend({id: messageFlowId}) {
                CaseDetail.cancelDelayMessage({params: {messageFlowId}})
                    .then(({data: {body}}) => {
                        const msg = '取消短信';

                        if (body) {
                            this.$app.opSuccess(msg);

                            this.fetchSmsList();
                        } else {
                            this.$app.opFail(msg);
                        }
                    })
                    .catch(err => err);
            }
        }
    };
</script>
