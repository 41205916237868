var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-layout-content",
    { staticClass: "content" },
    [
      _vm.isShowBreadcrumb
        ? _c(
            "a-breadcrumb",
            { staticClass: "mb10" },
            [
              _vm._l(_vm.$route.matched, function (ref, idx) {
                var name = ref.name
                var ref_meta = ref.meta
                var navTitle = ref_meta.navTitle
                var hideInBreadcrumb = ref_meta.hideInBreadcrumb
                return [
                  !hideInBreadcrumb
                    ? _c(
                        "a-breadcrumb-item",
                        { key: name },
                        [
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to:
                                  idx === _vm.$route.matched.length - 1
                                    ? _vm.$route
                                    : { name: name },
                              },
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(navTitle) +
                                  "\n                "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              }),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isShowContentHead
        ? [
            _c("h2", {
              domProps: { textContent: _vm._s(_vm.$route.meta.navTitle) },
            }),
            _vm._v(" "),
            _c("a-divider", { staticClass: "yqg-divider" }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("router-view"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }