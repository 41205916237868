<!-- @author giligili -->
<!-- @email zhimingwang@fintopia.tech -->
<!-- @date 2023-04-18 15:52:19.408 -->
<!-- @desc generated by yqg-cli@5.0.2 -->

<template>
    <div
        v-if="notification"
        class="placard"
    >
        <yqg-marquee :content="notification" />
    </div>
</template>

<script type="text/babel">

import AnnouncementResource from 'collection-admin-web/common/resource/advanced/announcement';

export default {
    name: 'LayoutPlacard',

    data() {
        return {
            notification: null
        };
    },

    mounted() {
        this.fetchPlacard();
    },

    methods: {
        fetchPlacard() {
            AnnouncementResource.getLatestStaticText({params: {staticTextType: 'SYSTEM_NOTIFICATION'}})
                .then(({data: {body: {text}}}) => {
                    this.notification = text;
                })
                .catch(err => err);
        }
    }
};

</script>
