/**
 * @Author: xymo
 * @Date: 2023-07-19
 * @Last Modified by: xymo
 * @Last Modified time: 2023-07-19 14:27:45
 */
import { defineForm } from '@yqg/type';
import Fields from './fields';
export default defineForm({
    layout: 'inline',
    fieldDefs: [
        Fields.keyword,
        Fields.questionCategoryIds,
        Fields.sort
    ]
});
