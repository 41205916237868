/*
 * @Author: yefenghan 
 * @Date: 2024-08-13 17:07:32 
 * @Last Modified by: yefenghan
 * @Last Modified time: 2024-08-13 17:08:04
 */
export default {
    SUCCESS: 'SUCCESS',
    FAIL: 'FAIL',
};
