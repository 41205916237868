<!-- @author wenxiujiang -->
<!-- @email wenxiujiang@yangqianguan.com -->
<!-- @date 2022-8-26 11:35:09 -->
<!-- @desc field-textarea.vue -->

<template>
    <a-textarea
        :value="value"
        v-bind="def.props"
        @change="onChange"
    />
</template>

<script type="text/babel">
export default {
    name: 'FieldTextarea',

    model: {
        prop: 'value',
        event: 'change'
    },

    props: {
        value: {
            type: [String, Number],
            default: ''
        },
        def: {
            type: Object,
            required: true
        },
        format: { // 支持业务对于输入内容的format处理, 需要时在业务component中定义format即可
            type: Function,
            default: null
        }
    },

    methods: {
        onChange(evt) {
            const {format} = this;
            const {value} = evt.target;

            if (format && value) {
                try {
                    Object.assign(evt.target, {
                        value: format(value),
                        originalVal: value
                    });
                } catch (err) {
                    this.$emit('change', evt);

                    throw err;
                }
            }

            this.$emit('change', evt);
        }
    }
};
</script>
