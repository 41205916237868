/**
 * @Author: giligili
 * @Date: 2021-06-22
 * @Last Modified by: shaotanliang@fintopia.tech
 * @Last Modified time: 2023-10-24 11:27:11
 */

import Resource from '@yqg/resource';

import {isRefresh, refreshMark} from 'collection-admin-web/common/util/browser';

const api = {
    urlPrefix: '/admin/operation/col/enterpriseWeChat/',

    getWechatAuthInfo: {
        url: 'getWechatAuthInfo',
        method: 'get'
    },

    getJsSDKSignature: {
        url: 'getJsSDKSignature',
        method: 'get'
    },

    getAgentJsSDKSignature: {
        url: 'getAgentJsSDKSignature',
        method: 'get'
    },

    getCaseId: {
        url: 'getCaseIdByExternalUserIdAndWechatUserId',
        method: 'get'
    },

    getMobileHashcode: {
        url: 'getMobileHashcode',
        method: 'get'
    },

    listWechatAddUserFlowStatistic: {
        url: 'listWechatAddUserFlowStatistic',
        method: 'get'
    },

    exportWechatAddUserFlowStatistic: {
        url: 'exportWechatAddUserFlowStatistic',
        download: true
    },

    listWechatAddUserFlow: {
        url: 'listWechatAddUserFlow',
        method: 'get'
    },

    exportWechatAddUserFlow: {
        url: 'exportWechatAddUserFlow',
        download: true
    },

    createEmployee: {
        url: 'createEmployee',
        method: 'post'
    },

    getCaseInfo: {
        url: 'getCaseInfo',
        method: 'post'
    },

    getUserInfoByUserName: {
        url: 'getUserInfoByUserName',
        method: 'get'
    },

    changeBindCaseUserId: {
        url: 'changeBindCaseUserId',
        method: 'get'
    },

    createPreRepayment: {
        url: 'createPreRepayment',
        method: 'post'
    },

    createRepayment: {
        url: 'createRepayment',
        method: 'post'
    },

    cancelPerRepayment: {
        url: 'cancelPerRepayment',
        method: 'post'
    },

    supportCreateWechatRepayment: {
        url: 'supportCreateWechatRepayment',
        method: 'post'
    },
};

const resource = Resource.create(api);

export default {
    ...resource,
    getJsSDKSignature(...vals) {
        return resource.getJsSDKSignature(...vals)
            .then(({data: {body}}) => body);
    },
    getAgentJsSDKSignature(...vals) {
        return resource.getAgentJsSDKSignature(...vals)
            .then(({data: {body}}) => body);
    },
    getWechatAuthInfo(...vals) {
        let cacheInfo = null;

        try {
            cacheInfo = JSON.parse(sessionStorage.getItem('wechatAuthInfo'));
        } catch (err) {
            // ignore err
        }

        if (isRefresh() && cacheInfo) {
            return Promise.resolve(cacheInfo);
        }

        YqgReporter.info({
            action: 'cache:error',
            description: '缓存失效',
            payload: {
                params: vals,
                isRefresh: isRefresh(),
                cacheInfo
            }
        });

        return resource.getWechatAuthInfo(...vals)
            .then(({data: {body}}) => {
                if (body) {
                    sessionStorage.setItem('wechatAuthInfo', JSON.stringify(body));
                }

                refreshMark();

                return body;
            });
    }
};
