const NOOP = () => { };
// 解析 json
export const jsonParse = (val, defaultValue = null) => {
    if (!val)
        return defaultValue;
    let ret = defaultValue;
    try {
        ret = JSON.parse(val);
    }
    catch (err) {
        // ignore err;
    }
    return ret;
};
// 把 1-61 转换成 1-9 A-z
const charTransform = (number) => {
    if (number <= 9)
        return `${number}`;
    if (number <= 35)
        return String.fromCharCode(number + 55);
    return String.fromCharCode(number + 61);
};
// 生成浏览器/Tab id
export const createClientId = () => {
    const date = new Date();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hour = date.getHours();
    const minute = date.getMinutes();
    const second = date.getSeconds();
    const uid = 'xxxxxxxx'
        .replace(/./g, (char) => {
        if (char !== 'x')
            return char;
        const nu = Math.random() * 16 | 0;
        return nu.toString(16);
    });
    const timeId = [month, day, hour, minute, second]
        .map((number) => charTransform(number))
        .join('');
    return `${uid}-${timeId}`;
};
// 生成 uuid
export const createUuid = () => 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
    .replace(/[xy]/g, (c) => {
    const r = Math.random() * 16 | 0;
    const v = c === 'x' ? r : ((r & 0x3) | 0x8);
    return v.toString(16);
});
// 生成 promise
export const genPromise = () => {
    let resolve = NOOP;
    let reject = NOOP;
    const promise = new Promise((res, rej) => {
        resolve = res;
        reject = rej;
    });
    return {
        promise,
        resolve,
        reject,
    };
};
// 挂起
export const sleep = (time) => {
    const { promise, resolve } = genPromise();
    setTimeout(resolve, time);
    return promise;
};
// 劫持方法
export const methodIntercept = (intercept, origin, callback) => (...vls) => {
    try {
        intercept(...vls);
    }
    catch (err) {
        callback === null || callback === void 0 ? void 0 : callback(err);
        // ignore
    }
    return origin(...vls);
};
