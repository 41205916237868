function objectWithoutProperties(obj, exclude) {
  var target = {}
  for (var k in obj)
    if (
      Object.prototype.hasOwnProperty.call(obj, k) &&
      exclude.indexOf(k) === -1
    )
      target[k] = obj[k]
  return target
}
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-select",
    _vm._b(
      {
        attrs: { "show-search": "", "filter-option": _vm.filterOption },
        on: { change: _vm.onChange, search: _vm.onSearch },
        scopedSlots: _vm._u([
          {
            key: "dropdownRender",
            fn: function (menu) {
              return _c(
                "div",
                {
                  ref: "list",
                  staticClass: "infinite-list-container",
                  on: {
                    scroll: function ($event) {
                      return _vm.scrollEvent($event)
                    },
                  },
                },
                [
                  _c("div", {
                    staticClass: "infinite-list-fake",
                    style: { height: _vm.fakeListHeight + "px" },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "infinite-list",
                      style: { transform: _vm.getTransform },
                    },
                    [
                      _c("v-nodes", {
                        attrs: { vnodes: _vm.restructure(menu) },
                      }),
                    ],
                    1
                  ),
                ]
              )
            },
          },
        ]),
        model: {
          value: _vm.compValue,
          callback: function ($$v) {
            _vm.compValue = $$v
          },
          expression: "compValue",
        },
      },
      "a-select",
      _vm.def.props,
      false
    ),
    [
      _vm._v(" "),
      _vm._l(_vm.selectOptions, function (ref) {
        var label = ref.label
        var val = ref.value
        var pinyin = ref.pinyin
        var rest$1 = objectWithoutProperties(ref, ["label", "value", "pinyin"])
        var rest = rest$1
        return _c(
          "a-select-option",
          _vm._b(
            { key: val, attrs: { value: val, pinyin: pinyin } },
            "a-select-option",
            rest,
            false
          ),
          [
            _vm.def.tooltipOption
              ? _c("a-tooltip", { attrs: { title: label } }, [
                  _vm._v("\n            " + _vm._s(label) + "\n        "),
                ])
              : [_vm._v("\n            " + _vm._s(label) + "\n        ")],
          ],
          2
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }