/*
 * @Author: huayizhang 
 * @Date: 2021-05-28 17:05:05 
 * @Last Modified by: yefenghan
 * @Last Modified time: 2024-08-07 00:40:24
 */

export default ({fileKey, fileLable, extra}) => ({
    layout: 'horizontal',
    fieldDefs: [
        {
            field: fileKey,
            label: fileLable,
            required: true,
            type: 'file',
            props: {raw: true, single: true},
            itemProps: {extra: extra ? extra : ''}
        }
    ]
});
