<!-- @Author: xymo -->
<!-- @Date: 2023-09-06 15:26:35 -->
<!-- @Last Modified by: xymo -->
<!-- @Last Modified time: 2023-09-06 15:26:35 -->

<template>
    <def-value
        :value="content"
        :def="{type: 'rich'}"
    />
</template>

<script type="text/babel">

export default {
    name: 'HtmlText',

    props: {
        text: {
            type: String,
            default: ''
        }
    },

    data() {
        return {
            content: ''
        };
    },

    watch: {
        text() {
            this.getContent();
        }
    },

    mounted() {
        this.getContent();
    },

    methods: {
        parseContent(text) {
            if (!text) return '';

            if (this.$app.permissions.KNOWLEDGE_DOWNLOAD_RECORD) return text;

            // 给 audio 标签添加 controlsList
            const audioReg = /(<audio[\s\S]*?)(\/{0,1}>)/g;

            return text.replace(audioReg, '$1 controlsList="nodownload" $2');
        },

        getContent() {
            this.content = this.parseContent(this.text);
        }
    }
};
</script>

