<!-- @Author: giligili -->
<!-- @Date: 2021-08-03 12:22:43.545 -->
<!-- @Last Modified by: giligili -->
<!-- @Last Modified time: 2023-07-05 13:28:29 -->

<template>
    <yqg-router-link
        :to="to"
        :query="query"
    >
        <slot>{{ text }}</slot>
    </yqg-router-link>
</template>

<script type="text/babel">

    import RelativeType from 'collection-admin-web/common/constant/types/relative-type';

    export default {
        name: 'YqgCaseLink',

        props: {
            caseId: {
                type: [String, Number],
                required: true
            },

            text: {
                type: [String, Number],
                default: '查看详情'
            },

            caseType: {
                type: String,
                default: RelativeType.CASE
            },

            queryCond: {
                type: Object,
                default: null
            }
        },

        computed: {
            encryCaseId() {
                return window.btoa(this.caseId);
            },

            to() {
                const {caseType} = this;

                return caseType === RelativeType.CASE
                    ? 'case'
                    : 'remindCase';
            },

            query() {
                const {caseType, encryCaseId} = this;
                // 返回 undefined url 上不会有 queryCond
                const queryCond = this.queryCond ? window.encodeURIComponent(JSON.stringify(this.queryCond)) : undefined;

                const key = caseType === RelativeType.CASE
                    ? 'caseId'
                    : 'remindCaseId';

                return {[key]: encryCaseId, queryCond};
            }
        }
    };
</script>
