<!-- @Author: xymo -->
<!-- @Date: 2023-07-19 15:37:35 -->
<!-- @Last Modified by: xymo -->
<!-- @Last Modified time: 2023-07-19 15:37:35 -->

<template>
    <a-pagination
        class="yqg-pagination"
        v-bind="{...$options.defaultPaginationOptions, ...pagination, current: pagination.pageNo}"
        @change="onChange"
        @showSizeChange="onChange"
    />
</template>

<script type="text/babel">
export default {
    name: 'YqgPagination',

    defaultPaginationOptions: {
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50', '100', '200'],
        showTotal: (total, [start, end]) => `${start}-${end}行, 共${total}行`
    },

    props: {
        pagination: {
            type: Object,
            required: true
        }
    },

    methods: {
        onChange(pageNo, pageSize) {
            this.$emit('change', {pageNo, pageSize});
        }
    }
};
</script>
