<!-- @Author: giligili -->
<!-- @Date: 2023-03-14 23:53:01 -->
<!-- @Last Modified by: giligili -->
<!-- @Last Modified time: 2023-03-14 23:53:01 -->

<template>
    <div class="instalment-list-tab mb20">
        <p class="yqg-island-title">
            分期记录
        </p>

        <div v-if="!mediateInstalmentVisible">
            <slot name="instalment" />
        </div>

        <div v-else>
            <a-tabs>
                <a-tab-pane
                    key="instalment"
                    tab="分期记录"
                >
                    <slot name="instalment" />
                </a-tab-pane>

                <a-tab-pane
                    key="mediateInstalment"
                    tab="调解分期记录"
                >
                    <slot
                        name="mediateInstalment"
                        :order-id="orderNumber"
                        :mediation-scheme-id="mediationSchemeId"
                    />
                </a-tab-pane>
            </a-tabs>
        </div>
    </div>
</template>

<script type="text/babel">

    import bus, {OnRefreshMediateSchemeList} from 'src/common/constant/event-bus';
    import Mediate from 'src/common/resource/mediate';
    import {isDef} from 'src/common/util/object';

    export default {
        name: 'InstalmentListTab',

        inject: ['caseId'],

        props: {
            orderNumber: {
                type: String,
                required: true
            }
        },

        data() {
            window.bus = bus;

            return {
                mediationSchemeId: null,
                mediateInstalmentTitle: ''
            };
        },

        computed: {
            mediateInstalmentVisible() {
                return isDef(this.mediationSchemeId);
            }
        },

        mounted() {
            this.getMediationSchemeId();

            bus.$on(OnRefreshMediateSchemeList, this.refreshMediationSchemeId);
        },

        beforeDestroy() {
            bus.$off(OnRefreshMediateSchemeList, this.refreshMediationSchemeId);
        },

        methods: {
            async getMediationSchemeId() {
                if (
                    !this.$app.permissions.MEDIATE_SCHEME_QUERY
                    || !this.$app.permissions.MEDIATE_INSTALMENT_QUERY
                ) return;

                const {caseId, orderNumber: orderId} = this;

                const {data: {body: {schemes}}} = await Mediate.listMediationSchemes({params: {caseId, orderId}});
                const mediationSchemeId = (schemes.find(({status}) => status === 'VALID' || status === 'FINISH') || {}).id;

                this.mediationSchemeId = mediationSchemeId;
            },

            refreshMediationSchemeId({orderNumber}) {
                if (orderNumber !== this.orderNumber) return;

                this.getMediationSchemeId();
            }
        }
    };

</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .instalment-list-tab {
        ::v-deep .yqg-simple-table {
            margin-top: 0;

            .yst-header {
                margin-bottom: 0;
            }
        }
    }
</style>
