var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "alarm-risk-upgrade-assign" },
    [
      _vm._v(
        "\n    【撤案提醒】" + _vm._s(_vm.notification.data.remark) + "\n\n    "
      ),
      _c("yqg-case-link", {
        attrs: { text: "案件详情 >", "case-id": _vm.notification.data.caseId },
      }),
      _vm._v(" "),
      _c("p", { staticClass: "mt10" }, [
        _c(
          "a",
          {
            on: {
              click: function ($event) {
                return _vm.finishNotification(_vm.notification.data)
              },
            },
          },
          [_vm._v("我知道了")]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }