/**
 * @Author: giligili
 * @Date: 2021-06-25
 * @Last Modified by: yefenghan
 * @Last Modified time: 2024-06-06 18:37:28
 */

import AES from 'crypto-js/aes';
import Utf8 from 'crypto-js/enc-utf8';
import ECB from 'crypto-js/mode-ecb';
import Pkcs7 from 'crypto-js/pad-pkcs7';

import CommonApiRouter from 'yqg-common/constant/CommonApiRouter';
import paramsSerializer from 'yqg-common/core/Http/lib/http-param-serializer';

import {SECRET, CALL_SECRET} from 'collection-admin-web/common/constant/encryption-keys';

/**
 * AES 加密
 * @param message, key
 * @returns 密码对象Base64字符串
 */
export const aesEncrypt = (message, key) => {
    if (message && key) {
        const encodeKey = Utf8.parse(key);
        const ciphertext = AES.encrypt(message, encodeKey, {
            mode: ECB,
            padding: Pkcs7
        });

        return ciphertext.toString();
    }

    return message;
};

/**
 * AES 解密
 * @param ciphertext, key
 * @returns utf8字符串
 */
export const aesDecrypt = (ciphertext, key) => {
    if (ciphertext && key) {
        const encodeKey = Utf8.parse(key);
        const decrypted = AES.decrypt(ciphertext, encodeKey, {
            mode: ECB,
            padding: Pkcs7
        });

        return decrypted.toString(Utf8);
    }

    return ciphertext;
};

// AES解密 默认密钥 call_secret
export const aesDecryptCallSecret = val => val && aesDecrypt(val, CALL_SECRET);

// AES加密 默认密钥 call_secret
export const aesEncryptCallSecret = val => val && aesEncrypt(val, CALL_SECRET);

// 获取pipe audio url
export const getPipeAudioUrl = ({src, fileName, decrypt = aesDecryptCallSecret}) => {
    const params = {
        url: AES.encrypt(decrypt(src), SECRET).toString(),
        contentType: 'audio/mpeg',
        contentDisposition: `attachment;filename=${fileName}`
    };

    return `${CommonApiRouter.apiWeb.pipe}?${paramsSerializer(params)}`;
};
