/**
 * @author giligili
 * @date 2021-08-18
 * @email zhimingwang@yangqianguan.com
 * @desc 管理需要在当前聚焦的tab上执行的事件
 *       事件执行时需要通知其他tab取消该事件
 */

export default class ActiveActionCallbackManager {

    constructor() {
        this.allActionCallbacks = {};
    }

    static callbackFactory({payload, callback}) {
        return () => {
            if (document.visibilityState === 'hidden') return;

            callback(payload);
        };
    }

    addActionCallback({type, actionCallback}) {
        this.allActionCallbacks[type] = this.allActionCallbacks[type] || [];

        this.allActionCallbacks[type].push(actionCallback);
    }

    cancelActionCallback({type}) {
        const actionCallbacks = this.allActionCallbacks[type] || [];

        actionCallbacks.forEach(action => document.removeEventListener('visibilitychange', action));
    }

    runActionCallback({type, callback, payload}) {
        const actionCallback = ActiveActionCallbackManager.callbackFactory({payload, callback});

        this.addActionCallback({type, actionCallback});

        document.addEventListener('visibilitychange', actionCallback);

        actionCallback();
    }

}
