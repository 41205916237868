/**
 * @Author: xymo
 * @Date: 2021-05-18
 * @Last Modified by: xymo
 * @Last Modified time: 2023-06-06 13:43:58
 */
import { assign, customGet, customPost } from '@yqg/resource';
const api = {
    urlPrefix: '/admin/operation/col/tag/',
    listCaseTagConfig: customGet('listCaseTagConfig'),
    addCaseTagConfig: customPost('addCaseTagConfig'),
    updateCaseTagConfig: customPost('updateCaseTagConfig'),
    getAreaTagGroupList: customGet('getAreaTagGroupList'),
    getCaseTagGroupList: customGet('getCaseTagGroupList')
};
export default assign(api);
