var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c("p", { staticClass: "agent-status-change-timer" }, [
        _vm.time
          ? _c("span", [
              _vm._v("\n        坐席状态将在\n        "),
              _c("span", { staticClass: "yqg-text-danger" }, [
                _vm._v(_vm._s(_vm.time) + "s"),
              ]),
              _vm._v("\n        后变为\n        "),
              _c("span", { staticClass: "yqg-text-danger" }, [
                _vm._v(_vm._s(_vm.status)),
              ]),
            ])
          : _c("span", [
              _vm._v("\n        坐席状态将变为\n        "),
              _c("span", { staticClass: "yqg-text-danger" }, [
                _vm._v(_vm._s(_vm.status)),
              ]),
            ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }