var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "header-notification" },
    [
      _c(
        "a-popover",
        {
          staticClass: "header-notification",
          attrs: {
            placement: "leftBottom",
            trigger: "none",
            "get-popup-container": _vm.getPopupContainer,
            "overlay-class-name": _vm.overlayClassName,
          },
          scopedSlots: _vm._u([
            {
              key: "content",
              fn: function () {
                return [
                  _c("div", { staticClass: "popover" }, [
                    _c("div", { staticClass: "title" }, [
                      _c("h4", { staticClass: "mb0" }, [
                        _vm._v(
                          "\n                        通知\n                    "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "btns" },
                        [
                          _vm.$app.permissions.NOTIFICATION_MUTATE
                            ? _c(
                                "a-tooltip",
                                {
                                  attrs: {
                                    placement: "top",
                                    "get-popup-container": function (
                                      triggerNode
                                    ) {
                                      return triggerNode
                                    },
                                  },
                                },
                                [
                                  _c("template", { slot: "title" }, [
                                    _c("div", [
                                      _vm._v("清理非账号案件下的提醒"),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", [
                                      _vm._v("过程可能会有延迟, 请耐心等待"),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c("a-button", {
                                    attrs: { size: "small", type: "primary" },
                                    domProps: {
                                      textContent: _vm._s("消息清理"),
                                    },
                                    on: { click: _vm.clearNotification },
                                  }),
                                ],
                                2
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.$app.permissions.WORK_STATUS_NOTIFICATION
                            ? _c(
                                "yqg-router-link",
                                { attrs: { to: "workStatusNotification" } },
                                [
                                  _c("a-button", {
                                    attrs: { size: "small", type: "primary" },
                                    domProps: {
                                      textContent: _vm._s("状态提醒"),
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.$app.permissions.CASE_FEEDBACK_FLOW_RELATIVE_QUERY
                            ? _c(
                                "yqg-router-link",
                                { attrs: { to: "feedbackRecord" } },
                                [
                                  _c("a-button", {
                                    attrs: { size: "small", type: "primary" },
                                    domProps: {
                                      textContent: _vm._s("工单反馈"),
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "yqg-router-link",
                            { attrs: { to: "remind" } },
                            [
                              _c("a-button", {
                                attrs: { size: "small", type: "primary" },
                                domProps: { textContent: _vm._s("案件提醒") },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "yqg-router-link",
                            { attrs: { to: "app" } },
                            [
                              _c("a-button", {
                                attrs: { size: "small" },
                                domProps: {
                                  textContent: _vm._s("APP登录历史"),
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "container mt20" },
                      [
                        _vm.notifications.length
                          ? _vm._l(
                              _vm.notifications,
                              function (notification, index) {
                                return _c(
                                  "div",
                                  { key: index, staticClass: "notification" },
                                  [
                                    _c(
                                      _vm.$options.ComponentMap[
                                        notification.type
                                      ],
                                      {
                                        tag: "component",
                                        attrs: { notification: notification },
                                      }
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "mt20 received-time" },
                                      [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(
                                              _vm._f("dateTime")(
                                                notification.timeReceived
                                              )
                                            ) +
                                            "\n                            "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              }
                            )
                          : _c("a-empty"),
                      ],
                      2
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.popoverVisible,
            callback: function ($$v) {
              _vm.popoverVisible = $$v
            },
            expression: "popoverVisible",
          },
        },
        [
          _c(
            "a-badge",
            {
              attrs: {
                "number-style": _vm.numberStyle,
                count: _vm.notificationCount,
                offset: [3, -3],
              },
            },
            [_c("a-icon", { attrs: { type: "bell" } })],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }