<!-- @author youboli -->
<!-- @email youboli@yangqianguan.com -->
<!-- @date 2022-11-02 11:48:22 -->
<!-- @desc yqg-card-list -->

<script>

    import _ from 'underscore';

    const DEFAULT_PAGINATION = {
        pageSize: 12,
        pageSizeOptions: ['12', '24', '48', '96', '192'],
        showSizeChanger: true
    };

    export default {
        name: 'YqgCardList',

        props: {
            records: {
                type: Array,
                default: () => ([])
            },

            options: {
                type: Object,
                default: () => ({})
            },

            pagination: {
                type: [Boolean, Object],
                default: () => ({})
            },

            defaultPagination: {
                type: Object,
                default: () => DEFAULT_PAGINATION
            }
        },

        data() {
            return {
                clientSearchInput: '',
                innerPagination: null
            };
        },

        computed: {
            enableClientSearch() {
                return this.options.enableClientSearch && !!this.records?.length;
            },

            activeRecords() {
                const {records, clientSearchInput} = this;
                if (!records) return null;
                if (!clientSearchInput) return records;

                // 1. 客户端搜索，只从接口返回中搜索
                return this.records.filter(record => JSON.stringify(record).includes(clientSearchInput));
            }
        },

        watch: {
            enableClientSearch() {
                if (this.options?.defaultSearchValue && this.enableClientSearch) {
                    this.onClientSearch(this.options.defaultSearchValue);
                }
            }
        },

        methods: {
            getWrappedSlots(slotsMapping, context, scope) {
                const wrappedSlots = Object.entries(slotsMapping).reduce((acc, [slotName, slot]) => {
                    if (!_.isFunction(slot)) return {...acc, [slotName]: null};

                    return {
                        ...acc,
                        [slotName]: (
                            <template slot={slotName}>
                                {slot.bind(context, scope)()}
                            </template>
                        )
                    };
                }, {});

                return Object.values(wrappedSlots);
            },

            renderItem(record, index) {
                const {$scopedSlots} = this;
                const {itemTitle, itemExtra, itemActions, itemContent} = $scopedSlots;

                // 将 a-card 的 slots 对外暴露为名字为 item* 的 scopedSlots
                const [title, extra, actions, defaultSlot] = this.getWrappedSlots(
                    {
                        title: itemTitle,
                        extra: itemExtra,
                        actions: itemActions,
                        default: itemContent || $scopedSlots.default
                    },
                    this,
                    {record, index}
                );

                return (
                    <a-list-item class="yqg-card-list-item">
                        <a-card>
                            {title}
                            {extra}
                            {actions}
                            {defaultSlot}
                        </a-card>
                    </a-list-item>
                );
            },

            getPagiantion() {
                const {pagination, defaultPagination, innerPagination} = this;

                if (pagination === false) return pagination;
                if (pagination?.pageNo) pagination.current = pagination.pageNo;

                return {
                    ...defaultPagination,
                    showTotal: (total, range) => `${range.join('-')}个, 共${total}个`,
                    onChange: (current, pageSize) => this.onPaginationChange({pageSize, current}),
                    onShowSizeChange: (current, pageSize) => this.onPaginationChange({pageSize, current}),
                    ...pagination,
                    ...innerPagination
                };
            },

            onPaginationChange(pagination) {
                const {pageNo, current, pageSize} = pagination;
                if (pageNo === current && pageSize === this.pagination.pageSize) return;

                pagination.pageNo = current;
                this.innerPagination = pagination;
                this.$emit('change', pagination);
            },

            renderClientSearch() {
                return (
                    <a-input-search
                        defaultValue={this.options?.defaultSearchValue}
                        class="client-search"
                        placeholder="表内搜索"
                        onSearch={this.onClientSearch}
                    />
                );
            },

            onClientSearch(value) {
                this.clientSearchInput = value ? value.trim() : '';

                this.$emit('search', this.clientSearchInput);
            }
        },

        render() {
            const {activeRecords, enableClientSearch, renderItem, $scopedSlots, $attrs} = this;

            if (!activeRecords) return null;

            const pagination = this.getPagiantion();

            return (
                <div class="yqg-card-list">
                    {enableClientSearch && this.renderClientSearch()}
                    {
                        !activeRecords.length
                            ? <a-empty />
                            : (
                                <a-list
                                    data-source={activeRecords}
                                    grid={{column: 4, gutter: 16}}
                                    pagination={pagination}
                                    scopedSlots={{renderItem, ...$scopedSlots}}
                                    {...{props: $attrs}}
                                />
                            )
                    }
                </div>
            );
        }
    };

</script>

<style lang="scss" scoped>
    .yqg-card-list {
        margin-top: 20px;

        .client-search {
            width: 200px;
            margin-bottom: 10px;
        }

        .ant-empty {
            padding: 16px;
            border: 1px solid #e8e8e8;
        }

        ::v-deep .ant-list {
            .ant-list-pagination {
                text-align: left;
            }
        }

        ::v-deep .ant-card {
            &-head {
                position: relative;
                min-height: 36px;
                padding: 0 12px;

                &-title {
                    padding: 8px 0;
                }

                &::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    width: 100%;
                    height: 2px;
                    background-color: #48b;
                }
            }

            &-body {
                height: 150px;
                padding: 12px;
                overflow-y: auto;

                .ant-form.yqg-static-form {
                    .ant-form-item {
                        &-label {
                            padding-bottom: 0;
                        }

                        &-control {
                            line-height: 1.2;
                        }

                        label {
                            font-size: 12px;
                            color: rgba(0, 0, 0, 65%);
                        }
                    }
                }
            }

            &-actions {
                li {
                    margin: 6px 0;

                    > span {
                        display: flex;
                        justify-content: space-between;

                        .ant-btn,
                        > span {
                            flex: 1;

                            &:not(:last-of-type) {
                                border-right: 1px solid #e8e8e8;
                            }
                        }
                    }
                }
            }
        }
    }

</style>
