/**
 * @Author: xymo
 * @Date: 2021-04-27
 * @Last Modified by: yefenghan
 * @Last Modified time: 2024-09-06 10:48:36
 */

// 这个是旧滴权限逻辑，通过$app.privileges或者$app.isAuthorized($app.PermissionTypes)
export const permissionArray = [
    'SUPER_ADMIN', // 超级管理员, 系统初始化(外包), 后端通过job跑出来的账号只有这个权限, 需要能访问权限管理给自己添加权限
    'CALL_QUALITY_RELATIVE_QUERY',
    'CALL_QUALITY_RELATIVE_EXPORT',
    'CALL_QUALITY_RELATIVE_ASSIGN_TASK',
    'CALL_QUALITY_RELATIVE_UPLOAD',
    'CALL_QUALITY_DETAIL_QUERY',
    'CALL_QUALITY_DETAIL_SAVE',
    'CALL_QUALITY_DETAIL_UPDATE',
    'CALL_QUALITY_OWN_QUERY',
    'CALL_QUALITY_CONFIG_QUERY',
    'CALL_QUALITY_CONFIG_MUTATE',
    'CALL_QUALITY_CONFIG_DELETE',
    'CALL_QUALITY_REPORT_ANALYZE',
    'CALL_QUALITY_REPORT_MANUAL',
    'CALL_QUALITY_REPORT_MACHINE_FAIL_REASON',
    'CALL_QUALITY_REPORT_MANUAL_FAIL_REASON',
    'CALL_QUALITY_REPORT_AUDIT',
    'CALL_QUALITY_REPORT_COMPANY',
    'CALL_QUALITY_REPORT_MISMATCH',
    'CALL_QUALITY_REPORT_AUDIT_MONITOR',
    'PROCESS_FLOW_FAIL_QUERY',
    'PROCESS_FLOW_QUERY',
    'PROCESS_FLOW_EXPORT',
    'PROCESS_FLOW_DETAIL',
    'PROCESS_FLOW_SUBMIT',
    'PROCESS_FLOW_HANDLE',
    'PROCESS_FLOW_CANCEL',
    'PROCESS_FLOW_CHANGE_OPERATOR',
    'PROCESS_FLOW_EXPORT_CREATE_USER',
    'ROLE_GROUP_QUERY',
    'ROLE_GROUP_MUTATE',
    'ROLE_GROUP_DELETE',
    'ROLE_GROUP_CONFIG',
    'ROLE_GROUP_SETUP',
    'ADMIN_USER_QUERY',
    'ADMIN_USER_CREATE',
    'ADMIN_USER_MUTATE',
    'ADMIN_USER_EXPORT',
    'ADMIN_USER_PASSWORD_RESET',
    'ADMIN_USER_CALL_ACCOUNT_CHANGE',
    'ADMIN_USER_BUBBLE_QUERY',
    'ADMIN_USER_BUBBLE_EXPORT',
    'ADMIN_USER_APPLY_CREATE',
    'PROCESS_FLOW_QUERY_CREATE_USER',
    'PROCESS_FLOW_DETAIL_CREATE_USER',
    'PROCESS_FLOW_HANDLE_CREATE_USER',
    'ADMIN_USER_WECHAT_CREATE',
    'AREA_QUERY',
    'AREA_QUERY_PRO',
    'AREA_MUTATE',
    'AREA_ADJUST_PARENT',
    'AREA_EXPORT',
    'AREA_EXTRA_CONFIG_QUERY',
    'AREA_EXTRA_CONFIG_MUTATE',
    'AREA_TAG_QUERY',
    'AREA_TAG_MUTATE',
    'ASSIGN_CONFIG_QUERY',
    'ASSIGN_CONFIG_MUTATE',
    'ASSIGN_CONFIG_DELETE',
    'ASSIGN_CONFIG_TRIGGER',
    'CASE_FLOW_QUERY',
    'CASE_FLOW_EXPORT',
    'CASE_FLOW_IMPORT',
    'CASE_RELATIVE_QUERY',
    'CASE_RELATIVE_ASSIGN_CASE',
    'CASE_RELATIVE_ASSIGN_CASE_BY_CASE_ID',
    'CASE_RELATIVE_EXPORT',
    'CASE_RELATIVE_ASSIGN_IVR',
    'DEMAND_LETTER_IMPORT',
    'CASE_RELATIVE_ASSIST_COLLECTION',
    'CASE_RELATIVE_ASSIST_EXPORT',
    'CASE_RELATIVE_UNMASK_EXPORT', // 明文导出案件
    'CASE_OWN_QUERY',
    'CASE_OWN_BATCH_SEND_MESSAGE',
    'CASE_DETAIL_QUERY',
    'CASE_DETAIL_TRIAL_CASE',
    'CASE_DETAIL_COUPON_SEND',
    'CASE_DETAIL_CONTACT_QUERY',
    'CASE_DETAIL_CONTACT_MUTATE',
    'CASE_DETAIL_TRIAL_OVERDUE_CASE',
    'CASE_DETAIL_FLOW_MUTATE',
    'CASE_DETAIL_FLOW_REVIEW_QUERY',
    'CASE_DETAIL_FLOW_REVIEW_MUTATE',
    'CASE_DETAIL_EDIT_PHONE_STAR',
    'CASE_DETAIL_EDIT_STAR',
    'CASE_DETAIL_OPEN_OFFLINE_REPAY_SWITCH',
    'CASE_DETAIL_OPEN_WECHAT_PAY_SWITCH',
    'CASE_DETAIL_OPEN_ALIPAY_SWITCH',
    'CASE_DETAIL_QUERY_ORDER_DETAIL_REPAYMENT',
    'CASE_DETAIL_ASSIST_COLLECTION',
    'CASE_DETAIL_GET_MOBILE_HASH_CODE',
    'CASE_DETAIL_SETTLE_IN_ADVANCE',
    'CASE_DETAIL_ADD_BLACK_LIST',
    'CASE_DETAIL_LIST_HISTORY_ORDER',
    'CASE_DETAIL_LIST_HISTORY_MESSAGE',
    'CASE_DETAIL_LIST_COUPON',
    'CASE_DETAIL_SHOW_INSTALMENT_FEE',
    'CASE_DETAIL_SHOW_DIAL_BACK_INFO',
    'CASE_DETAIL_ALL_SETTLE',
    'CASE_DETAIL_SHOW_REPAIRED_PHONE_NUMBER_INFO',
    'CASE_DETAIL_SAVE_RISK_BLACK_LIST', // 提交欺诈用户
    'CASE_DETAIL_QUERY_RISK_BLACK_LIST_RESULT', // 查询欺诈用户结果
    'CASE_DETAIL_SEND_OFFLINE_H5_REPAYMENT_MESSAGE', // h5还款
    'CASE_DETAIL_APPLY_MEDIATE',
    'CASE_DETAIL_UPLOAD_REPAY_RECEIPT',
    'CASE_REPAYMENT_QUERY',
    'CASE_REPAYMENT_EXPORT',
    'CASE_TAG_QUERY',
    'CASE_TAG_MUTATE',
    'CASE_PERFORMANCE_SELF_QUERY',
    'CASE_PERFORMANCE_RELATIVE_QUERY',
    'CASE_PERFORMANCE_EXPORT',
    'CASE_FEEDBACK_FLOW_QUERY', // 反馈记录管理-查询
    'CASE_FEEDBACK_FLOW_HANDLE', // 反馈记录管理-处理
    'CASE_FEEDBACK_FLOW_JUMP', // 反馈记录管理-跳转到客服系统
    'CASE_FEEDBACK_FLOW_UNMASKED_MOBILE_NUMBER_QUERY',
    'DEMAND_LETTER_QUERY',
    'DEMAND_LETTER_EXPORT',
    'REMIND_CASE_RELATIVE_QUERY',
    'REMIND_CASE_RELATIVE_ASSIGN',
    'REMIND_CASE_RELATIVE_EXPORT',
    'REMIND_CASE_OWN_QUERY',
    'REMIND_CASE_DETAIL_QUERY',
    'REMIND_CASE_DETAIL_MUTATE',
    'REMIND_CASE_DETAIL_FLOW_QUERY',
    'REMIND_CASE_DETAIL_FLOW_MUTATE',
    'REMIND_CASE_NOTIFICATION_QUERY',
    'REMIND_CASE_NOTIFICATION_MUTATE',
    'REMIND_CASE_REPAYMENT_QUERY',
    'COMPLAINT_ASSIGN',
    'COMPLAINT_FLOW_MUTATE',
    'NOTIFICATION_QUERY',
    'NOTIFICATION_MUTATE',
    'AREA_COLLECTION_RATE_QUERY',
    'AREA_SATISFACTION_RATE_QUERY',
    'AUTO_DIAL_ASSIGNMENT_SUMMERY',
    'CALL_VACANT_COLLECTOR',
    'CALL_DETAIL_QUERY',
    'CALL_DETAIL_QUERY_ALL',
    'CALL_DETAIL_EXPORT',
    'CALL_SUMMARY_QUERY',
    'CALL_SUMMARY_EXPORT',
    'CALL_CONFIG_QUERY',
    'CALL_CONFIG_MUTATE',
    'CALL_CONFIG_DELETE',
    'CALL_PERFORMANCE_SELF_QUERY',
    'CALL_PERFORMANCE_RELATIVE_QUERY',
    'CALL_PERFORMANCE_EXPORT',
    'CALL_MULTI_DIAL',
    'CALL_MULTI_DIAL_RECOMMEND',
    'CALL_QUALITY_QUERY',
    'CALL_QUALITY_EXPORT',
    'AUTO_DIAL_PROGRESS_QUERY',
    'EXTRA_CALL_DETAIL_QUERY_REDIRECT',
    'COMPANY_QUERY',
    'COMPANY_MUTATE',
    'FEEDBACK_QUERY',
    'FEEDBACK_MUTATE',
    'CONFIGURATION_QUERY',
    'CONFIGURATION_MUTATE',
    'CONFIGURATION_DELETE',
    'CONFIGURATION_FORMAT_MUTATE',
    'IVR_SCENE_QUERY',
    'IVR_SCENE_MUTATE',
    'IVR_SCENE_DELETE',
    'BUSINESS_QUERY',
    'BUSINESS_MUTATE',
    'BUSINESS_DELETE',
    'GATEKEEPER_QUERY',
    'GATEKEEPER_MUTATE',
    'GATEKEEPER_DELETE',
    'CACHE_SET_QUERY',
    'CACHE_SET_DELETE',
    'CACHE_SET_MUTATE',
    'CACHE_SET_AUTO_DIAL_BLACKLIST_MUTATE',
    'EXTRA_SHOW_FILE_LIST', // 查看异步导出文件
    'EXTRA_PINGAN_AUTO_DIAL_STATUS',
    'EXTRA_CASE_STATISTICS',
    'EXTRA_GET_COLLECTOR_MAP',
    'EXTRA_CASE_RELATIVE_CONTACT_QUERY',
    'EXTRA_ADMIN_MOBILE_MASK',
    'EXTRA_BUBBLE_NOTIFICATION',
    'EXTRA_GET_AREA_MAP', // 查询机构
    'ROLE_TYPE_ADMIN',
    'ROLE_TYPE_AUDIT_SPECIALIST',
    'ROLE_TYPE_SPECIALIST',
    'ROLE_TYPE_EXTEND_SPECIALIST',
    'STATIC_TEXT_QUERY',
    'STATIC_TEXT_MUTATE',
    'STATIC_TEXT_DELETE',
    'ASSIGN_RECORD_QUERY',
    'ASSIGN_RECORD_EXPORT',
    'MONTHLY_PERFORMANCE_SELF_QUERY',
    'MONTHLY_PERFORMANCE_RELATIVE_QUERY',
    'JOB_QUERY',
    'JOB_MUTATE',
    'JOB_OPERATE',
    'JOB_DELETE',
    'WITHHOLD_FLOW_REPORT_QUERY',
    'WITHHOLD_FLOW_DETAIL_QUERY',
    'WITHHOLD_FLOW_DETAIL_EXPORT',
    'WITHHOLD_FLOW_REPORT_EXPORT',
    'ENTERPRISE_WECHAT_REPORT_QUERY',
    'ENTERPRISE_WECHAT_REPORT_EXPORT',
    'ENTERPRISE_WECHAT_ADD_USER_FLOW_QUERY',
    'ENTERPRISE_WECHAT_ADD_USER_FLOW_EXPORT'
];

// $app.permissions
export const PermissionMap = {
    SUPER_ADMIN: 'SUPER_ADMIN',

    /** @desc 角色相关权限 start */
    ROLE_TYPE_YQG_AUTO_DIAL: 'ROLE_TYPE_YQG_AUTO_DIAL', // 预测外呼帐号
    ROLE_TYPE_COMPLAINT_MANAGER: 'ROLE_TYPE_COMPLAINT_MANAGER', // 投诉岗
    /** @desc 角色相关权限 end  */

    CALL_QUALITY_RELATIVE_QUERY: 'CALL_QUALITY_RELATIVE_QUERY',
    CALL_QUALITY_RELATIVE_EXPORT: 'CALL_QUALITY_RELATIVE_EXPORT',
    CALL_QUALITY_RELATIVE_ASSIGN_TASK: 'CALL_QUALITY_RELATIVE_ASSIGN_TASK',
    CALL_QUALITY_RELATIVE_UPLOAD: 'CALL_QUALITY_RELATIVE_UPLOAD',
    CALL_QUALITY_DETAIL_QUERY: 'CALL_QUALITY_DETAIL_QUERY',
    CALL_QUALITY_DETAIL_SAVE: 'CALL_QUALITY_DETAIL_SAVE',
    CALL_QUALITY_DETAIL_UPDATE: 'CALL_QUALITY_DETAIL_UPDATE',
    CALL_QUALITY_DETAIL_EXPORT: 'CALL_QUALITY_DETAIL_EXPORT',
    CALL_QUALITY_OWN_QUERY: 'CALL_QUALITY_OWN_QUERY',
    CALL_QUALITY_CONFIG_QUERY: 'CALL_QUALITY_CONFIG_QUERY',
    CALL_QUALITY_CONFIG_MUTATE: 'CALL_QUALITY_CONFIG_MUTATE',
    CALL_QUALITY_CONFIG_DELETE: 'CALL_QUALITY_CONFIG_DELETE',
    CALL_QUALITY_REPORT_ANALYZE: 'CALL_QUALITY_REPORT_ANALYZE',
    CALL_QUALITY_REPORT_MANUAL: 'CALL_QUALITY_REPORT_MANUAL',
    CALL_QUALITY_REPORT_MACHINE_FAIL_REASON: 'CALL_QUALITY_REPORT_MACHINE_FAIL_REASON',
    CALL_QUALITY_REPORT_MANUAL_FAIL_REASON: 'CALL_QUALITY_REPORT_MANUAL_FAIL_REASON',
    CALL_QUALITY_REPORT_AUDIT: 'CALL_QUALITY_REPORT_AUDIT',
    CALL_QUALITY_REPORT_COMPANY: 'CALL_QUALITY_REPORT_COMPANY',
    CALL_QUALITY_REPORT_MISMATCH: 'CALL_QUALITY_REPORT_MISMATCH',
    CALL_QUALITY_REPORT_AUDIT_MONITOR: 'CALL_QUALITY_REPORT_AUDIT_MONITOR',
    PROCESS_FLOW_FAIL_QUERY: 'PROCESS_FLOW_FAIL_QUERY',
    PROCESS_FLOW_QUERY: 'PROCESS_FLOW_QUERY',
    PROCESS_FLOW_EXPORT: 'PROCESS_FLOW_EXPORT',
    PROCESS_FLOW_DETAIL: 'PROCESS_FLOW_DETAIL',
    PROCESS_FLOW_SUBMIT: 'PROCESS_FLOW_SUBMIT',
    PROCESS_FLOW_HANDLE: 'PROCESS_FLOW_HANDLE',
    PROCESS_FLOW_CANCEL: 'PROCESS_FLOW_CANCEL',
    PROCESS_FLOW_CHANGE_OPERATOR: 'PROCESS_FLOW_CHANGE_OPERATOR',
    PROCESS_FLOW_EXPORT_CREATE_USER: 'PROCESS_FLOW_EXPORT_CREATE_USER',
    ROLE_GROUP_QUERY: 'ROLE_GROUP_QUERY',
    ROLE_GROUP_MUTATE: 'ROLE_GROUP_MUTATE',
    ROLE_GROUP_DELETE: 'ROLE_GROUP_DELETE',
    ROLE_GROUP_CONFIG: 'ROLE_GROUP_CONFIG',
    ROLE_GROUP_SETUP: 'ROLE_GROUP_SETUP',
    ADMIN_USER_QUERY: 'ADMIN_USER_QUERY',
    ADMIN_USER_CREATE: 'ADMIN_USER_CREATE',
    ADMIN_USER_MUTATE: 'ADMIN_USER_MUTATE',
    ADMIN_USER_EXPORT: 'ADMIN_USER_EXPORT',
    ADMIN_USER_APPROVAL_EXPORT: 'ADMIN_USER_APPROVAL_EXPORT',
    ADMIN_USER_PASSWORD_RESET: 'ADMIN_USER_PASSWORD_RESET',
    ADMIN_USER_CALL_ACCOUNT_CHANGE: 'ADMIN_USER_CALL_ACCOUNT_CHANGE',
    ADMIN_USER_BUBBLE_QUERY: 'ADMIN_USER_BUBBLE_QUERY',
    ADMIN_USER_BUBBLE_EXPORT: 'ADMIN_USER_BUBBLE_EXPORT',
    ADMIN_USER_APPLY_CREATE: 'ADMIN_USER_APPLY_CREATE',
    PROCESS_FLOW_QUERY_CREATE_USER: 'PROCESS_FLOW_QUERY_CREATE_USER',
    PROCESS_FLOW_DETAIL_CREATE_USER: 'PROCESS_FLOW_DETAIL_CREATE_USER',
    PROCESS_FLOW_HANDLE_CREATE_USER: 'PROCESS_FLOW_HANDLE_CREATE_USER',
    ADMIN_USER_WECHAT_CREATE: 'ADMIN_USER_WECHAT_CREATE',
    AREA_QUERY: 'AREA_QUERY',
    AREA_QUERY_PRO: 'AREA_QUERY_PRO',
    AREA_MUTATE: 'AREA_MUTATE',
    AREA_ADJUST_PARENT: 'AREA_ADJUST_PARENT',
    AREA_EXPORT: 'AREA_EXPORT',
    AREA_EXTRA_CONFIG_QUERY: 'AREA_EXTRA_CONFIG_QUERY',
    AREA_EXTRA_CONFIG_MUTATE: 'AREA_EXTRA_CONFIG_MUTATE',
    AREA_TAG_QUERY: 'AREA_TAG_QUERY',
    AREA_TAG_MUTATE: 'AREA_TAG_MUTATE',
    AREA_REMOVE_ADMIN_USER: 'AREA_REMOVE_ADMIN_USER', // 机构解绑成员
    AREA_CALL_CONFIG_MANAGE: 'AREA_CALL_CONFIG_MANAGE', // 机构外呼/呼叫标签配置
    ASSIGN_CONFIG_QUERY: 'ASSIGN_CONFIG_QUERY',
    ASSIGN_CONFIG_MUTATE: 'ASSIGN_CONFIG_MUTATE',
    ASSIGN_CONFIG_DELETE: 'ASSIGN_CONFIG_DELETE',
    ASSIGN_CONFIG_TRIGGER: 'ASSIGN_CONFIG_TRIGGER',
    CASE_FLOW_QUERY: 'CASE_FLOW_QUERY',
    CASE_FLOW_EXPORT: 'CASE_FLOW_EXPORT',
    CASE_FLOW_IMPORT: 'CASE_FLOW_IMPORT',
    CASE_RELATIVE_QUERY: 'CASE_RELATIVE_QUERY',
    CASE_RELATIVE_MORE_QUERY: 'CASE_RELATIVE_MORE_QUERY',
    CASE_RELATIVE_ASSIGN_CASE: 'CASE_RELATIVE_ASSIGN_CASE',
    CASE_RELATIVE_ASSIGN_CASE_BY_CASE_ID: 'CASE_RELATIVE_ASSIGN_CASE_BY_CASE_ID',
    CASE_RELATIVE_EXPORT: 'CASE_RELATIVE_EXPORT',
    CASE_RELATIVE_ASSIGN_IVR: 'CASE_RELATIVE_ASSIGN_IVR',
    DEMAND_LETTER_IMPORT: 'DEMAND_LETTER_IMPORT',
    CASE_RELATIVE_ASSIST_COLLECTION: 'CASE_RELATIVE_ASSIST_COLLECTION',
    CASE_RELATIVE_ASSIST_EXPORT: 'CASE_RELATIVE_ASSIST_EXPORT',
    CASE_RELATIVE_UNMASK_EXPORT: 'CASE_RELATIVE_UNMASK_EXPORT',
    CASE_RELATIVE_BATCH_SEND_SMS: 'CASE_RELATIVE_BATCH_SEND_SMS', // 批量发送短信

    CASE_RELATIVE_QUERY_BY_ASSIGN_TIMES: 'CASE_RELATIVE_QUERY_BY_ASSIGN_TIMES',
    CASE_RELATIVE_EXPORT_CASE_ASSIGN_TIMES: 'CASE_RELATIVE_EXPORT_CASE_ASSIGN_TIMES',

    CASE_OWN_QUERY: 'CASE_OWN_QUERY',
    CASE_OWN_BATCH_SEND_MESSAGE: 'CASE_OWN_BATCH_SEND_MESSAGE',
    CASE_DETAIL_QUERY: 'CASE_DETAIL_QUERY',
    CASE_DETAIL_TRIAL_CASE: 'CASE_DETAIL_TRIAL_CASE',
    CASE_DETAIL_COUPON_SEND: 'CASE_DETAIL_COUPON_SEND',
    CASE_DETAIL_CONTACT_QUERY: 'CASE_DETAIL_CONTACT_QUERY',
    CASE_DETAIL_CONTACT_MUTATE: 'CASE_DETAIL_CONTACT_MUTATE',
    CASE_DETAIL_TRIAL_OVERDUE_CASE: 'CASE_DETAIL_TRIAL_OVERDUE_CASE',
    CASE_DETAIL_FLOW_MUTATE: 'CASE_DETAIL_FLOW_MUTATE',
    CASE_DETAIL_FLOW_REVIEW_QUERY: 'CASE_DETAIL_FLOW_REVIEW_QUERY',
    CASE_DETAIL_FLOW_REVIEW_MUTATE: 'CASE_DETAIL_FLOW_REVIEW_MUTATE',
    CASE_DETAIL_EDIT_PHONE_STAR: 'CASE_DETAIL_EDIT_PHONE_STAR',
    CASE_DETAIL_EDIT_STAR: 'CASE_DETAIL_EDIT_STAR',
    CASE_DETAIL_OPEN_OFFLINE_REPAY_SWITCH: 'CASE_DETAIL_OPEN_OFFLINE_REPAY_SWITCH',
    CASE_DETAIL_OPEN_WECHAT_PAY_SWITCH: 'CASE_DETAIL_OPEN_WECHAT_PAY_SWITCH',
    CASE_DETAIL_OPEN_ALIPAY_SWITCH: 'CASE_DETAIL_OPEN_ALIPAY_SWITCH',
    CASE_DETAIL_QUERY_ORDER_DETAIL_REPAYMENT: 'CASE_DETAIL_QUERY_ORDER_DETAIL_REPAYMENT',
    CASE_DETAIL_ASSIST_COLLECTION: 'CASE_DETAIL_ASSIST_COLLECTION',
    CASE_DETAIL_GET_MOBILE_HASH_CODE: 'CASE_DETAIL_GET_MOBILE_HASH_CODE',
    CASE_DETAIL_SETTLE_IN_ADVANCE: 'CASE_DETAIL_SETTLE_IN_ADVANCE',
    CASE_DETAIL_ADD_BLACK_LIST: 'CASE_DETAIL_ADD_BLACK_LIST',
    CASE_DETAIL_LIST_HISTORY_ORDER: 'CASE_DETAIL_LIST_HISTORY_ORDER',
    CASE_DETAIL_LIST_HISTORY_MESSAGE: 'CASE_DETAIL_LIST_HISTORY_MESSAGE',
    CASE_DETAIL_LIST_COUPON: 'CASE_DETAIL_LIST_COUPON',
    CASE_DETAIL_SHOW_INSTALMENT_FEE: 'CASE_DETAIL_SHOW_INSTALMENT_FEE',
    CASE_DETAIL_SHOW_DIAL_BACK_INFO: 'CASE_DETAIL_SHOW_DIAL_BACK_INFO',
    CASE_DETAIL_ALL_SETTLE: 'CASE_DETAIL_ALL_SETTLE',
    CASE_DETAIL_SHOW_REPAIRED_PHONE_NUMBER_INFO: 'CASE_DETAIL_SHOW_REPAIRED_PHONE_NUMBER_INFO',
    CASE_DETAIL_SAVE_RISK_BLACK_LIST: 'CASE_DETAIL_SAVE_RISK_BLACK_LIST',
    CASE_DETAIL_QUERY_RISK_BLACK_LIST_RESULT: 'CASE_DETAIL_QUERY_RISK_BLACK_LIST_RESULT',
    CASE_DETAIL_SEND_OFFLINE_H5_REPAYMENT_MESSAGE: 'CASE_DETAIL_SEND_OFFLINE_H5_REPAYMENT_MESSAGE',
    CASE_DETAIL_APPLY_MEDIATE: 'CASE_DETAIL_APPLY_MEDIATE',
    CASE_DETAIL_UPLOAD_REPAY_RECEIPT: 'CASE_DETAIL_UPLOAD_REPAY_RECEIPT',
    CASE_DETAIL_SEND_MESSAGE: 'CASE_DETAIL_SEND_MESSAGE',
    CASE_DETAIL_DISPLAY_CALL_IN_GATEWAY_CODE: 'CASE_DETAIL_DISPLAY_CALL_IN_GATEWAY_CODE', // 显示线路名称
    CASE_DETAIL_WITHHOLD: 'CASE_DETAIL_WITHHOLD', // 代扣
    CASE_DETAIL_SEND_INSTALMENT_COUPON: 'CASE_DETAIL_SEND_INSTALMENT_COUPON', // 发送账单减免券
    CASE_DETAIL_QUERY_INSTALMENT_COUPON: 'CASE_DETAIL_QUERY_INSTALMENT_COUPON', // 查询账单减免券
    CASE_DETAIL_QUERY_CUSTOMER_SERVICE: 'CASE_DETAIL_QUERY_CUSTOMER_SERVICE', // 客服服务记录查询
    CASE_DETAIL_SEND_H5_SMS_WITH_SPECIFIED_MOBILE: 'CASE_DETAIL_SEND_H5_SMS_WITH_SPECIFIED_MOBILE', // h5还款允许自定义手机号

    /** @desc 辖内委案 start */
    CASE_RELATIVE_BATCH_OPEN_REPAYMENT_SWITCH: 'CASE_RELATIVE_BATCH_OPEN_REPAYMENT_SWITCH', // 批量打开支付宝/微信还款开关
    CASE_RELATIVE_EXCEL_BATCH_SEND_SMS: 'CASE_RELATIVE_EXCEL_BATCH_SEND_SMS', // 超过诉讼期的订单上传excel，然后根据这个excel发短信
    /** @desc 辖内委案 end */
    CASE_REPAYMENT_QUERY: 'CASE_REPAYMENT_QUERY',
    CASE_REPAYMENT_EXPORT: 'CASE_REPAYMENT_EXPORT',
    CASE_TAG_QUERY: 'CASE_TAG_QUERY',
    CASE_TAG_MUTATE: 'CASE_TAG_MUTATE',
    CASE_PERFORMANCE_SELF_QUERY: 'CASE_PERFORMANCE_SELF_QUERY',
    CASE_PERFORMANCE_RELATIVE_QUERY: 'CASE_PERFORMANCE_RELATIVE_QUERY',
    CASE_PERFORMANCE_EXPORT: 'CASE_PERFORMANCE_EXPORT',
    CASE_FEEDBACK_FLOW_QUERY: 'CASE_FEEDBACK_FLOW_QUERY',
    CASE_FEEDBACK_FLOW_HANDLE: 'CASE_FEEDBACK_FLOW_HANDLE',
    CASE_FEEDBACK_FLOW_JUMP: 'CASE_FEEDBACK_FLOW_JUMP',
    CASE_FEEDBACK_FLOW_UNMASKED_MOBILE_NUMBER_QUERY: 'CASE_FEEDBACK_FLOW_UNMASKED_MOBILE_NUMBER_QUERY',
    DEMAND_LETTER_QUERY: 'DEMAND_LETTER_QUERY',
    DEMAND_LETTER_EXPORT: 'DEMAND_LETTER_EXPORT',
    REMIND_CASE_RELATIVE_QUERY: 'REMIND_CASE_RELATIVE_QUERY',
    REMIND_CASE_RELATIVE_ASSIGN: 'REMIND_CASE_RELATIVE_ASSIGN',
    REMIND_CASE_RELATIVE_EXPORT: 'REMIND_CASE_RELATIVE_EXPORT',
    REMIND_CASE_OWN_QUERY: 'REMIND_CASE_OWN_QUERY',
    REMIND_CASE_DETAIL_QUERY: 'REMIND_CASE_DETAIL_QUERY',
    REMIND_CASE_DETAIL_MUTATE: 'REMIND_CASE_DETAIL_MUTATE',
    REMIND_CASE_DETAIL_FLOW_QUERY: 'REMIND_CASE_DETAIL_FLOW_QUERY',
    REMIND_CASE_DETAIL_FLOW_MUTATE: 'REMIND_CASE_DETAIL_FLOW_MUTATE',
    REMIND_CASE_NOTIFICATION_QUERY: 'REMIND_CASE_NOTIFICATION_QUERY',
    REMIND_CASE_NOTIFICATION_MUTATE: 'REMIND_CASE_NOTIFICATION_MUTATE',
    REMIND_CASE_REPAYMENT_QUERY: 'REMIND_CASE_REPAYMENT_QUERY',
    COMPLAINT_ASSIGN: 'COMPLAINT_ASSIGN',
    COMPLAINT_FLOW_MUTATE: 'COMPLAINT_FLOW_MUTATE',
    NOTIFICATION_QUERY: 'NOTIFICATION_QUERY',
    NOTIFICATION_MUTATE: 'NOTIFICATION_MUTATE',
    AREA_COLLECTION_RATE_QUERY: 'AREA_COLLECTION_RATE_QUERY',
    AREA_SATISFACTION_RATE_QUERY: 'AREA_SATISFACTION_RATE_QUERY',
    AUTO_DIAL_ASSIGNMENT_SUMMERY: 'AUTO_DIAL_ASSIGNMENT_SUMMERY',
    CALL_VACANT_COLLECTOR: 'CALL_VACANT_COLLECTOR',
    CALL_DETAIL_QUERY: 'CALL_DETAIL_QUERY',
    CALL_DETAIL_QUERY_ALL: 'CALL_DETAIL_QUERY_ALL',
    CALL_DETAIL_QUERY_DIAL_BACK_NUMBER: 'CALL_DETAIL_QUERY_DIAL_BACK_NUMBER',
    CALL_DETAIL_EXPORT: 'CALL_DETAIL_EXPORT',
    CALL_SUMMARY_QUERY: 'CALL_SUMMARY_QUERY',
    CALL_SUMMARY_EXPORT: 'CALL_SUMMARY_EXPORT',
    CALL_CONFIG_QUERY: 'CALL_CONFIG_QUERY',
    CALL_CONFIG_MUTATE: 'CALL_CONFIG_MUTATE',
    CALL_CONFIG_DELETE: 'CALL_CONFIG_DELETE',
    CALL_PERFORMANCE_SELF_QUERY: 'CALL_PERFORMANCE_SELF_QUERY',
    CALL_PERFORMANCE_RELATIVE_QUERY: 'CALL_PERFORMANCE_RELATIVE_QUERY',
    CALL_PERFORMANCE_EXPORT: 'CALL_PERFORMANCE_EXPORT',
    CALL_MULTI_DIAL: 'CALL_MULTI_DIAL',
    CALL_MULTI_DIAL_RECOMMEND: 'CALL_MULTI_DIAL_RECOMMEND',
    CALL_QUALITY_QUERY: 'CALL_QUALITY_QUERY',
    CALL_QUALITY_EXPORT: 'CALL_QUALITY_EXPORT',
    AUTO_DIAL_PROGRESS_QUERY: 'AUTO_DIAL_PROGRESS_QUERY',
    EXTRA_CALL_DETAIL_QUERY_REDIRECT: 'EXTRA_CALL_DETAIL_QUERY_REDIRECT',
    COMPANY_QUERY: 'COMPANY_QUERY',
    COMPANY_MUTATE: 'COMPANY_MUTATE',
    FEEDBACK_QUERY: 'FEEDBACK_QUERY',
    FEEDBACK_MUTATE: 'FEEDBACK_MUTATE',
    CONFIGURATION_QUERY: 'CONFIGURATION_QUERY',
    CONFIGURATION_MUTATE: 'CONFIGURATION_MUTATE',
    CONFIGURATION_DELETE: 'CONFIGURATION_DELETE',
    CONFIGURATION_FORMAT_MUTATE: 'CONFIGURATION_FORMAT_MUTATE',
    IVR_SCENE_QUERY: 'IVR_SCENE_QUERY',
    IVR_SCENE_MUTATE: 'IVR_SCENE_MUTATE',
    IVR_SCENE_DELETE: 'IVR_SCENE_DELETE',
    BUSINESS_QUERY: 'BUSINESS_QUERY',
    BUSINESS_MUTATE: 'BUSINESS_MUTATE',
    BUSINESS_DELETE: 'BUSINESS_DELETE',
    GATEKEEPER_QUERY: 'GATEKEEPER_QUERY',
    GATEKEEPER_MUTATE: 'GATEKEEPER_MUTATE',
    GATEKEEPER_DELETE: 'GATEKEEPER_DELETE',
    CACHE_SET_QUERY: 'CACHE_SET_QUERY',
    CACHE_SET_DELETE: 'CACHE_SET_DELETE',
    CACHE_SET_MUTATE: 'CACHE_SET_MUTATE',
    CACHE_SET_ORDER_MUTATE: 'CACHE_SET_ORDER_MUTATE', // 通过订单号查询
    CACHE_SET_AUTO_DIAL_BLACKLIST_MUTATE: 'CACHE_SET_AUTO_DIAL_BLACKLIST_MUTATE',
    EXTRA_ADMIN_SYSTEM_JUMP: 'EXTRA_ADMIN_SYSTEM_JUMP', // 跳转到admin, 一般都是产品用
    EXTRA_SHOW_FILE_LIST: 'EXTRA_SHOW_FILE_LIST',
    EXTRA_PINGAN_AUTO_DIAL_STATUS: 'EXTRA_PINGAN_AUTO_DIAL_STATUS',
    EXTRA_CASE_STATISTICS: 'EXTRA_CASE_STATISTICS',
    EXTRA_GET_COLLECTOR_MAP: 'EXTRA_GET_COLLECTOR_MAP',
    EXTRA_CASE_RELATIVE_CONTACT_QUERY: 'EXTRA_CASE_RELATIVE_CONTACT_QUERY',
    EXTRA_ADMIN_MOBILE_MASK: 'EXTRA_ADMIN_MOBILE_MASK',
    EXTRA_BUBBLE_NOTIFICATION: 'EXTRA_BUBBLE_NOTIFICATION',
    EXTRA_GET_AREA_MAP: 'EXTRA_GET_AREA_MAP',
    ROLE_TYPE_ADMIN: 'ROLE_TYPE_ADMIN',
    ROLE_TYPE_AUDIT_SPECIALIST: 'ROLE_TYPE_AUDIT_SPECIALIST',
    ROLE_TYPE_SPECIALIST: 'ROLE_TYPE_SPECIALIST',
    ROLE_TYPE_EXTEND_SPECIALIST: 'ROLE_TYPE_EXTEND_SPECIALIST',
    STATIC_TEXT_QUERY: 'STATIC_TEXT_QUERY',
    STATIC_TEXT_MUTATE: 'STATIC_TEXT_MUTATE',
    STATIC_TEXT_DELETE: 'STATIC_TEXT_DELETE',
    ASSIGN_RECORD_QUERY: 'ASSIGN_RECORD_QUERY',
    ASSIGN_RECORD_EXPORT: 'ASSIGN_RECORD_EXPORT',
    MONTHLY_PERFORMANCE_SELF_QUERY: 'MONTHLY_PERFORMANCE_SELF_QUERY',
    MONTHLY_PERFORMANCE_RELATIVE_QUERY: 'MONTHLY_PERFORMANCE_RELATIVE_QUERY',
    JOB_QUERY: 'JOB_QUERY',
    JOB_MUTATE: 'JOB_MUTATE',
    JOB_OPERATE: 'JOB_OPERATE',
    JOB_DELETE: 'JOB_DELETE',
    WITHHOLD_FLOW_REPORT_QUERY: 'WITHHOLD_FLOW_REPORT_QUERY',
    WITHHOLD_FLOW_DETAIL_QUERY: 'WITHHOLD_FLOW_DETAIL_QUERY',
    WITHHOLD_FLOW_DETAIL_EXPORT: 'WITHHOLD_FLOW_DETAIL_EXPORT',
    WITHHOLD_FLOW_REPORT_EXPORT: 'WITHHOLD_FLOW_REPORT_EXPORT',
    ENTERPRISE_WECHAT_REPORT_QUERY: 'ENTERPRISE_WECHAT_REPORT_QUERY',
    ENTERPRISE_WECHAT_REPORT_EXPORT: 'ENTERPRISE_WECHAT_REPORT_EXPORT',
    ENTERPRISE_WECHAT_ADD_USER_FLOW_QUERY: 'ENTERPRISE_WECHAT_ADD_USER_FLOW_QUERY',
    ENTERPRISE_WECHAT_ADD_USER_FLOW_EXPORT: 'ENTERPRISE_WECHAT_ADD_USER_FLOW_EXPORT',

    /* @desc 诉讼相关 start */
    // 委案
    LAWSUIT_RELATIVE_MARK: 'LAWSUIT_RELATIVE_MARK',
    LAWSUIT_RELATIVE_QUERY_LIST: 'LAWSUIT_RELATIVE_QUERY_LIST',
    LAWSUIT_RELATIVE_QUERY_DETAIL: 'LAWSUIT_RELATIVE_QUERY_DETAIL',
    LAWSUIT_RELATIVE_DOWNLOAD_MATERIAL: 'LAWSUIT_RELATIVE_DOWNLOAD_MATERIAL',
    CASE_RELATIVE_LAWSUIT_ASSIGN_CASE: 'CASE_RELATIVE_LAWSUIT_ASSIGN_CASE',
    LAWSUIT_RELATIVE_EXPORT: 'LAWSUIT_RELATIVE_EXPORT', // 导出
    LAWSUIT_RELATIVE_UPLOAD_LAWSUIT_OPERATION: 'LAWSUIT_RELATIVE_UPLOAD_LAWSUIT_OPERATION', // 批量操作诉讼记录
    // 详情
    LAWSUIT_RELATIVE_ADD_LAWSUIT: 'LAWSUIT_RELATIVE_ADD_LAWSUIT',
    LAWSUIT_RELATIVE_ADD_COMPLAINT: 'LAWSUIT_RELATIVE_ADD_COMPLAINT',
    LAWSUIT_RELATIVE_QUERY_COMPLAINT: 'LAWSUIT_RELATIVE_QUERY_COMPLAINT',
    LAWSUIT_RELATIVE_QUERY_LAWSUIT: 'LAWSUIT_RELATIVE_QUERY_LAWSUIT',
    /* @desc 诉讼相关 end */

    /* @desc 协催相关 start */
    CROSS_AREA_ASSIST_COLLECTION: 'CROSS_AREA_ASSIST_COLLECTION', // 跨机构协催
    /* @desc 协催相关 end */

    /* @desc 一些特殊的权限管理 start */
    EXTRA_SHOW_COLLECTOR_NAME_IN_MESSAGE: 'EXTRA_SHOW_COLLECTOR_NAME_IN_MESSAGE', // 短信历史显示催收员姓名
    /* @desc 一些特殊的权限管理 end */

    /** @desc 投诉质检 start */
    COMPLAINT_QUALITY_QUERY: 'COMPLAINT_QUALITY_QUERY', // 投诉质检查询
    COMPLAINT_QUALITY_EXPORT: 'COMPLAINT_QUALITY_EXPORT', // 投诉质检导出
    COMPLAINT_QUALITY_DETAIL_SAVE: 'COMPLAINT_QUALITY_DETAIL_SAVE', // 保存人工结果
    COMPLAINT_QUALITY_DETAIL_UPDATE: 'COMPLAINT_QUALITY_DETAIL_UPDATE', // 编辑人工结果
    COMPLAINT_QUALITY_QUALITY_EXPORT: 'COMPLAINT_QUALITY_QUALITY_EXPORT', // 录音质检导出
    COMPLAINT_QUALITY_DETAIL_QUERY: 'COMPLAINT_QUALITY_DETAIL_QUERY', // 详情
    /** @desc 投诉质检 end   */

    /** @desc 投诉工作量监控 start */
    COMPLAINT_PERFORMANCE_QUERY: 'COMPLAINT_PERFORMANCE_QUERY',
    COMPLAINT_PERFORMANCE_EXPORT: 'COMPLAINT_PERFORMANCE_EXPORT',
    /** @desc 投诉工作量监控 end */

    MONTHLY_REPAYMENT_PERFORMANCE_RELATIVE_QUERY: 'MONTHLY_REPAYMENT_PERFORMANCE_RELATIVE_QUERY',
    MONTHLY_REPAYMENT_PERFORMANCE_SELF_QUERY: 'MONTHLY_REPAYMENT_PERFORMANCE_SELF_QUERY',
    MONTHLY_REPAYMENT_PERFORMANCE_RELATIVE_EXPORT: 'MONTHLY_REPAYMENT_PERFORMANCE_RELATIVE_EXPORT',
    MONTHLY_REPAYMENT_PERFORMANCE_SELF_EXPORT: 'MONTHLY_REPAYMENT_PERFORMANCE_SELF_EXPORT',

    MESSAGE_GROUP_SAVE: 'MESSAGE_GROUP_SAVE',
    MESSAGE_GROUP_QUERY: 'MESSAGE_GROUP_QUERY',
    MESSAGE_GROUP_UPDATE: 'MESSAGE_GROUP_UPDATE',
    MESSAGE_GROUP_DELETE: 'MESSAGE_GROUP_DELETE',

    CASE_DETAIL_DISPLAY_REPAID_AMOUNT: 'CASE_DETAIL_DISPLAY_REPAID_AMOUNT',

    CASE_RELATIVE_IMPORT_CASE_MEDIATE_NUMBER: 'CASE_RELATIVE_IMPORT_CASE_MEDIATE_NUMBER',

    AUTO_DIAL_TRIGGER: 'AUTO_DIAL_TRIGGER', //手动触发预测外呼

    CASE_FEEDBACK_FLOW_RELATIVE_QUERY: 'CASE_FEEDBACK_FLOW_RELATIVE_QUERY',
    CASE_FEEDBACK_FLOW_RELATIVE_EXPORT: 'CASE_FEEDBACK_FLOW_RELATIVE_EXPORT',

    ENTERPRISE_WECHAT_RELATIVE_EXTERNAL_USERS_QUERY: 'ENTERPRISE_WECHAT_RELATIVE_EXTERNAL_USERS_QUERY',
    ENTERPRISE_WECHAT_EXTERNAL_USER_FLOWS_QUERY: 'ENTERPRISE_WECHAT_EXTERNAL_USER_FLOWS_QUERY',
    ENTERPRISE_WECHAT_MESSAGE_EXPORT: 'ENTERPRISE_WECHAT_MESSAGE_EXPORT',
    ENTERPRISE_WECHAT_MESSAGE_QUERY: 'ENTERPRISE_WECHAT_MESSAGE_QUERY',

    MEDIATE_INSTALMENT_QUERY: 'MEDIATE_INSTALMENT_QUERY', // 调解分期记录查询
    MEDIATE_SCHEME_QUERY: 'MEDIATE_SCHEME_QUERY', // 查询协商方案
    MEDIATE_SCHEME_SAVE: 'MEDIATE_SCHEME_SAVE', // 生成协商方案
    MEDIATE_SCHEME_CANCEL: 'MEDIATE_SCHEME_CANCEL', // 作废协商方案

    /* 敏感词管理 start */
    SENSITIVE_WORDS_MANUAL_REMAIN_CASE: 'SENSITIVE_WORDS_MANUAL_REMAIN_CASE', // 手动留案
    /* 敏感词管理 end */

    EXTRA_DIAL_WITH_PLAIN_TEXT_PHONE_NUMBER: 'EXTRA_DIAL_WITH_PLAIN_TEXT_PHONE_NUMBER', // 拨打明文手机号

    CALL_DETAIL_QUALITY_INSPECT: 'CALL_DETAIL_QUALITY_INSPECT',

    SENSITIVE_WORDS_QUERY: 'SENSITIVE_WORDS_QUERY', // 敏感词查询

    SENSITIVE_WORDS_EXPORT: 'SENSITIVE_WORDS_EXPORT',

    CACHE_SET_EXPORT: 'CACHE_SET_EXPORT',

    KNOWLEDGE_TEXT_QUERY: 'KNOWLEDGE_TEXT_QUERY', // 知识库管理-查询
    KNOWLEDGE_TEXT_STAR: 'KNOWLEDGE_TEXT_STAR', // 知识库管理-收藏
    KNOWLEDGE_DOWNLOAD_RECORD: 'KNOWLEDGE_DOWNLOAD_RECORD', // 知识库管理-下载录音

    EXTRA_DIAL_WITH_ASSIST: 'EXTRA_DIAL_WITH_ASSIST', // 坐席辅助权限

    WORKSPACE_QUERY: 'WORKSPACE_QUERY', // 我的工作台

    CALL_QUERY_UNMASK_PHONE: 'CALL_QUERY_UNMASK_PHONE', // 查询解密手机号,

    ENTERPRISE_WECHAT_CHANGE_CASE: 'ENTERPRISE_WECHAT_CHANGE_CASE', // 企微收款-换绑案件信息
    ENTERPRISE_WECHAT_COLLECTION: 'ENTERPRISE_WECHAT_COLLECTION', // 企微收款-发起对外收款

    CASE_DETAIL_TRIAL_REPAYMENT_CASE: 'CASE_DETAIL_TRIAL_REPAYMENT_CASE',
    CASE_DETAIL_OPEN_REPAYMENT_SWITCH: 'CASE_DETAIL_OPEN_REPAYMENT_SWITCH', // 微信支付宝支付开关权限

    LAWSUIT_RELATIVE_CASE_TAG_SEARCH_CONDITION: 'LAWSUIT_RELATIVE_CASE_TAG_SEARCH_CONDITION',

    CASE_OWN_QUERY_NEW: 'CASE_OWN_QUERY_NEW',

    CASE_RELATIVE_QUERY_NEW: 'CASE_RELATIVE_QUERY_NEW',

    AGREEMENT_QUERY: 'AGREEMENT_QUERY', // 协议查询(根据url下载协议，下载协议按钮的权限)

    AGREEMENT_CREATE: 'AGREEMENT_CREATE',

    CONNECT_HISTORY_QUERY: 'CONNECT_HISTORY_QUERY',

    EXTRA_PLAIN_TEXT_PHONE_NUMBER: 'EXTRA_PLAIN_TEXT_PHONE_NUMBER', // 发起协议明文手机号输入

    CASE_DETAIL_GET_RECOMMEND_REPAY_CHANNEL: 'CASE_DETAIL_GET_RECOMMEND_REPAY_CHANNEL',

    EXTRA_TAG_VACATION_COLLECTION: 'EXTRA_TAG_VACATION_COLLECTION',

    CASE_RELATIVE_QUERY_WITH_ROBOT_FLOW: 'CASE_RELATIVE_QUERY_WITH_ROBOT_FLOW',

    CALL_QUERY_CALL_INFO: 'CALL_QUERY_CALL_INFO', // 呼叫记录查询

    CASE_DETAIL_QUERY_CALL_INFO: 'CASE_DETAIL_QUERY_CALL_INFO', // 详情页呼叫记录查询

    EXTRA_HANDLE_UNASSIGNED_ACCOUNT: 'EXTRA_HANDLE_UNASSIGNED_ACCOUNT', // 特殊权限管理-处理待分配账号

    EXTRA_DISPLAY_UNMASK_USERNAME: 'EXTRA_DISPLAY_UNMASK_USERNAME', // 特殊权限管理-查看员工明文姓名

    SCHEDULING_QUERY: 'SCHEDULING_QUERY', // 排班管理-工作时间查询
    SCHEDULING_UPDATE: 'SCHEDULING_UPDATE', // 排班管理-工作时间操作

    ASSIGN_SCHEDULING_QUERY: 'ASSIGN_SCHEDULING_QUERY', // 排班管理-排班查询
    ASSIGN_SCHEDULING_UPDATE: 'ASSIGN_SCHEDULING_UPDATE', // 排班管理-排班操作
    ASSIGN_SCHEDULING_EXPORT: 'ASSIGN_SCHEDULING_EXPORT', // 排班管理-排班导出
    ASSIGN_SCHEDULING_IMPORT: 'ASSIGN_SCHEDULING_IMPORT', // 排班管理-排班导入

    WORK_STATUS_QUERY: 'WORK_STATUS_QUERY', // 工作状态查询
    WORK_STATUS_UPDATE: 'WORK_STATUS_UPDATE', // 工作状态操作
    WORK_STATUS_NOTIFICATION: 'WORK_STATUS_NOTIFICATION', // 工作状态提醒

    CASE_DETAIL_CREATE_BLACK_LIST_ASSIGN: 'CASE_DETAIL_CREATE_BLACK_LIST_ASSIGN', // 添加分案黑名单

    BLACK_LIST_QUERY_ASSIGN: 'BLACK_LIST_QUERY_ASSIGN', // 黑名单管理-查询分案黑名单
    BLACK_LIST_EXPORT_ASSIGN: 'BLACK_LIST_EXPORT_ASSIGN', // 黑名单管理-导出分案黑名单
    BLACK_LIST_MUTATE_ASSIGN: 'BLACK_LIST_MUTATE_ASSIGN', // 黑名单管理-新建编辑分案黑名单

    RULE_CODE_QUERY: 'RULE_CODE_QUERY', // 规则码查询
    RULE_CODE_UPDATE: 'RULE_CODE_UPDATE', // 规则码更新、导入、资源配置

    WORK_DASHBOARD_QUERY: 'WORK_DASHBOARD_QUERY', // 驾驶舱查询
    WORK_DASHBOARD_QUERY_MANAGEMENT: 'WORK_DASHBOARD_QUERY_MANAGEMENT', // 驾驶舱组长查询
    EXTRA_NEW_FEATURE_TRIAL: 'EXTRA_NEW_FEATURE_TRIAL' // 新功能试用
};

const PermissionTypes = permissionArray.reduce((map, type) => {
    const keys = type.split('_');
    let tmp = map;
    keys.forEach((key, idx) => {
        if (idx === keys.length - 1) {
            if (tmp[key]) {
                tmp[key].self = type;
            } else {
                tmp[key] = type;
            }

            return;
        }

        if (tmp[key]) {
            if (typeof tmp[key] == 'string') {
                tmp[key] = {
                    self: tmp[key]
                };
            }
        } else {
            tmp[key] = {};
        }

        tmp = tmp[key];
    });

    return map;
}, {});

export default PermissionTypes;
