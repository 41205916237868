/*
 * @Author: xiaodongyu
 * @Date 2020-12-17 16:28:15
 * @Last Modified by: xiaodongyu
 * @Last Modified time: 2020-12-19 11:09:08
 */

import {Node} from 'tiptap';

import {LinkOutline, FileOutline} from '../../../../icons';

const AntIconMap = {
    link: LinkOutline,
    file: FileOutline
};

export default class AntIcon extends Node {

    get name() {
        return 'anticon';
    }

    get schema() {
        return {
            content: 'inline*',
            group: 'inline',
            draggable: false,
            inline: true,
            attrs: {
                type: {
                    default: null
                }
            },
            parseDOM: [
                {
                    tag: 'svg',
                    getAttrs: dom => ({
                        type: dom.getAttribute('data-icon')
                    })
                }
            ],
            toDOM: node => {
                const {icon} = AntIconMap[node.attrs.type];

                return [
                    'svg',
                    {
                        ...icon.attrs,
                        'class': 'anticon',
                        'data-icon': node.attrs.type,
                        'width': '1em',
                        'height': '1em',
                        'fill': 'currentColor'
                    },
                    ...icon.children.map(({tag, attrs}) => [tag, attrs])
                ];
            }
        };
    }

    get view() {
        return {
            props: {
                node: {
                    type: Object,
                    required: true
                }
            },

            render() {
                return <a-icon type={this.node.attrs.type}/>;
            }
        };
    }

}
