/**
 * @author giligili
 * @date 2022-11-01
 * @email zhimingwang@fintopia.tech
 * @desc 投诉质检
 */

import {create as createResource} from '@yqg/resource';

import {roundTimestampToMonthStart} from 'yqg-common/vue/utils/time';

const api = {
    urlPrefix: '/admin/operation/col/callQuality/',

    listRelativeComplaintQualityTask: {
        url: 'listRelativeComplaintQualityTask',
        method: 'post'
    },

    exportComplaintQualityTask: {
        url: 'exportComplaintQualityTask',
        download: true
    },

    listBonusReason: {
        url: 'listBonusReason',
        method: 'get'
    },

    listDeductReason: {
        url: 'listDeductReason',
        method: 'get'
    }
};

const handleCond = cond => {
    const {$bonusReason = [], $deductReason = [], ...rest} = cond;

    return {
        ...rest,
        callTimeMonth: roundTimestampToMonthStart(cond.callTimeMonth),
        machineResults: JSON.stringify(cond.machineResults),
        manualResults: JSON.stringify(cond.manualResults),
        statisfactionKeys: JSON.stringify(cond.statisfactionKeys),
        machineFailReasons: JSON.stringify(cond.machineFailReasons),
        manualFailReasons: JSON.stringify([...$bonusReason, ...$deductReason])
    };
};

const resource = createResource(api);

export default {
    ...resource,

    exportComplaintQualityTask: ({params}) => resource.exportComplaintQualityTask({params: handleCond(params)}),

    listRelativeComplaintQualityTask: data => resource.listRelativeComplaintQualityTask(handleCond(data))
};

