/*
 * @Author: huayizhang 
 * @Date: 2021-07-06 11:37:19 
 * @Last Modified by: huayizhang
 * @Last Modified time: 2021-07-22 17:47:29
 */
import Flow from 'collection-admin-web/common/resource/flow';
import Enum from 'collection-admin-web/common/util/enum';

// ---------------------------------------------------------------------------
/** @desc 催员可添加的投诉接通催记 */
export const ComplaintConnectResultsMap = Enum.query(Flow.listComplaintFlowResults, {
    dataBodyField: 'connectResults',
    queryOnce: true
});

/** @desc 催员可添加的投诉未接通催记 */
export const ComplaintUnconnectResultsMap = Enum.query(Flow.listComplaintFlowResults, {
    dataBodyField: 'unconnectResults',
    queryOnce: true
});

/** @desc 投诉所有催记，主要用来映射中文 */
export const ComplaintFlowResultsMap = Enum.query(() => Flow.listComplaintFlowResults().then(resp => {
    const {connectResults, unconnectResults} = resp.data.body;

    resp.data.body.$AllFlowResultsMap = {
        ...connectResults,
        ...unconnectResults
    };

    return resp;
}), {
    dataBodyField: '$AllFlowResultsMap',
    queryOnce: true
});

// ---------------------------------------------------------------------------
/** @desc 案件查询时的接通催记 */
export const CaseSearchConnectResultsMap = Enum.query(Flow.listFlowResultsByConfig, {
    dataBodyField: 'connectResults',
    queryOnce: true
});

/** @desc 案件查询时的未接通催记 */
export const CaseSearchUnconnectResultsMap = Enum.query(Flow.listFlowResultsByConfig, {
    dataBodyField: 'unconnectedResults',
    queryOnce: true
});

// ---------------------------------------------------------------------------
/** @desc 催员可添加的接通催记 */
export const ConnectResultsMap = Enum.query(Flow.listFlowResults, {
    dataBodyField: 'connectResults',
    queryOnce: true
});

/** @desc 催员可添加的未接通催记 */
export const UnconnectResultsMap = Enum.query(Flow.listFlowResults, {
    dataBodyField: 'unconnectResults',
    queryOnce: true
});

/** @desc 催员可添加的企业微信催记 */
export const WechatResultsMap = Enum.query(Flow.listFlowResults, {
    dataBodyField: 'enterpriseWechatConnectResults',
    queryOnce: true
});

/** @desc 所有的催记，主要用来映射，这个应该没有了 TODO: check **/
export const FlowResultsMap = Enum.query(() => Flow.listFlowResults().then(resp => {
    const {connectResults, unconnectResults, enterpriseWechatConnectResults} = resp.data.body;

    resp.data.body.$AllFlowResultsMap = {
        ...connectResults,
        ...unconnectResults,
        ...enterpriseWechatConnectResults
    };

    return resp;
}), {
    dataBodyField: '$AllFlowResultsMap',
    queryOnce: true
});

/** @desc 所有的催记，主要用来映射，包括一些后端自动添加的催记，用来映射中文 **/
export default Enum.query(() => Flow.listFlowResults().then(resp => {
    const {connectResults, unconnectResults, enterpriseWechatConnectResults, autoGeneratedResults} = resp.data.body;

    resp.data.body.$AllFlowResultsMap = {
        ...connectResults,
        ...unconnectResults,
        ...enterpriseWechatConnectResults,
        ...autoGeneratedResults
    };

    return resp;
}), {
    dataBodyField: '$AllFlowResultsMap',
    queryOnce: true
});
