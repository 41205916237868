/**
 * @Author: giligili
 * @Date: 2022-03-11
 * @Last Modified by: giligili
 * @Last Modified time: 2023-07-12 18:06:43
 */
// TODO: performance 上好像有关于是否刷新的字段
export const isRefresh = () => {
    const { hash } = window.location;
    return hash.includes('refresh');
};
export const refreshMark = () => {
    window.location.hash = 'refresh';
};
