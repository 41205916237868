/**
 * @author giligili
 * @date 2023-03-10
 * @email zhimingwang@fintopia.tech
 * @desc fields
 */

import EnumAll from 'collection-admin-web/common/constant/enum';
import {genField} from 'collection-admin-web/common/util/tool';

export const orderId = genField('订单号', 'orderId');
export const timeCreated = genField('申请时间', 'timeCreated', 'dateTime');
export const operatorName = genField('申请人', 'operatorName');
export const collectorName = genField('申请人', 'collectorName');
export const isReduce = genField('是否减免', 'isReduce', 'radio', {
    enumType: EnumAll.Basic.Boolean
});
export const reduceType = genField('减免方式', 'reduceType', 'select', {
    enumType: EnumAll.Trial.ReduceTypeEnum
});
export const terms = genField('分期', 'terms', 'number');
export const term = genField('期数', 'term', 'number');
export const reduceAmount = genField('减免金额(元)', 'reduceAmount', 'number', {
    filter: 'numberCommasFixed2'
});
export const overflowAmount = genField('溢出金额(元)', 'overflowAmount', 'number', {
    filter: 'numberCommasFixed2'
});
export const remainAmount = genField('应还金额', 'remainAmount', 'number', {
    filter: 'numberCommasFixed2'
});
export const statusDesc = genField('状态', 'statusDesc');
export const comments = genField('备注', 'comments', 'textarea');
export const imageUrls = genField('图片', 'imageUrls', 'upload', {
    props: {ui: 'image', listType: 'picture-card', limit: Infinity, maxByte: 5 * 1024 * 1024},
    component: 'yqg-antv-upload',
    staticComp: 'yqg-antv-upload',
    staticProps: {ui: 'image', listType: 'picture-card', limit: Infinity, disabled: true},
});
export const isInstalment = genField('是否分期', 'isInstalment', 'radio', {
    enumType: EnumAll.Basic.Boolean
});
export const completedTime = genField('一次性还款日期', 'completedTime', 'date', {
    roundMode: 'dayEnd'
});
export const reduceExpiredTime = genField('减免失效日期', 'reduceExpiredTime', 'date', {
    roundMode: 'dayEnd'
});
export const billingDate = genField('应还日期', 'billingDate', 'date', {
    roundMode: 'dayEnd'
});
export const amount = genField('应还金额', 'amount', 'number', {
    filter: 'numberCommasFixed2'
});
export const mediationInstalments = genField('分期列表', 'mediationInstalments', 'text');
export const op = genField('操作', 'op');
