/*
 * @Author: huayizhang 
 * @Date: 2021-10-22 14:30:36 
 * @Last Modified by: huayizhang
 * @Last Modified time: 2021-10-26 11:46:02
 */

// 减免相关接口, 券也是一种减免

import Resource from '@yqg/resource';

const api = {
    urlPrefix: '/admin/operation/col/trial/',

    canAllSettle: {
        url: 'canAllSettle',
        method: 'get'
    },

    getReduceType: {
        url: 'getReduceType',
        method: 'get'
    },

    trialCaseByReduceType: {
        url: 'trialCaseByReduceType',
        method: 'get'
    },

    applyRepayReduce: {
        url: 'applyRepayReduce',
        method: 'post'
    },

    listCaseTrialByOrderNumber: {
        url: 'listCaseTrialByOrderNumber',
        method: 'get'
    },

    supportReduce: {
        url: 'supportReduce',
        method: 'get'
    },

    reduceTrial: {
        url: 'reduceTrial',
        method: 'get'
    },

    reduceTrialV2: {
        url: 'reduceTrialV2',
        method: 'get'
    },

    listReduceTypes: {
        url: 'listReduceTypes',
        method: 'get'
    },

    getCaseTrialById: {
        url: 'getCaseTrialById',
        method: 'get'
    },

    supportReduceV2: {
        url: 'supportReduceV2',
        method: 'get'
    },

    // 减免和券的状态(生效.....)
    listCaseTrialStatuses: {
        url: 'listCaseTrialStatuses',
        method: 'get'
    },

    // 减免和券的类型(IRR24, 提现券....)
    listCaseTrialTypes: {
        url: 'listCaseTrialTypes',
        method: 'get'
    },

    /** 账单减免券 start */
    // 是否支持发送账单减免券
    supportSendInstalmentCoupon: {
        url: 'supportSendInstalmentCoupon',
        method: 'get'
    },

    // 账单减免券信息
    getInstalmentCouponInfo: {
        url: 'getInstalmentCouponInfo',
        method: 'get'
    },

    // 批量发送账单减免券
    batchSendInstalmentCouponsByCaseId: {
        url: 'batchSendInstalmentCouponsByCaseId',
        method: 'post'
    },

    // 账单减免券列表
    listInstalmentCouponsByCaseId: {
        url: 'listInstalmentCouponsByCaseId',
        method: 'get'
    }
    /** 账单减免券 end */
};
export default Resource.create(api);
