/**
 * @Author: xymo
 * @Date: 2021-04-27
 * @Last Modified by: xymo
 * @Last Modified time: 2023-06-26 11:26:41
 */
import _ from 'underscore';
import { assign, customGet, customPost } from '@yqg/resource';
const api = {
    urlPrefix: '/admin/operation/col/area/',
    listArea: customGet('listArea'),
    addArea: customPost('addArea'),
    updateArea: customPost('updateArea'),
    getCurrentArea: customGet('getCurrentArea'),
    getAreaList: customGet('getAreaList'),
    getChildArea: customGet('getChildArea'),
    getExtraConfig: customGet('getExtraConfig'),
    updateExtraConfig: customPost('updateExtraConfig'),
    listAreaAdminUser: customGet('listAreaAdminUser'),
    getAreaMap: customGet('getAreaMap'),
    removeAreaAdminUser: customPost('removeAreaAdminUser')
};
const resource = assign(api);
const genAreaTree = areaList => {
    const parents = areaList.filter(({ parentId }) => (parentId === null
        || !areaList.some(({ areaId }) => (areaId === parentId))));
    let children = _.without(areaList, ...parents);
    for (let index = 0; index < parents.length; index += 1) {
        const { areaId } = parents[index];
        const temp = children.filter(({ parentId }) => (parentId === areaId));
        if (temp.length) {
            children = _.without(children, ...temp);
            parents.splice(index + 1, 0, ...temp);
        }
    }
    return parents;
};
// 根据树形机构图的顺序排序
resource.getChildAreaBySort = function getChildAreaBySort(...argvs) {
    return resource.getChildArea.call(this, ...argvs)
        .then(({ data: { body: { areaList = [] } } }) => ({
        data: { body: { areaList: genAreaTree(areaList) } }
    }));
};
export default resource;
