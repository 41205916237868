var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-dropdown",
    {
      attrs: {
        placement: "bottomRight",
        trigger: ["click"],
        "get-popup-container": function (triggerNode) {
          return triggerNode
        },
      },
    },
    [
      _c("a-icon", { attrs: { type: "user" } }),
      _vm._v(" "),
      _c(
        "a-menu",
        {
          staticClass: "dropdown-menu",
          attrs: { slot: "overlay" },
          slot: "overlay",
        },
        [
          _c("a-menu-item", { on: { click: _vm.onItemClick } }, [
            _c("span", { staticClass: "ant-popover-content" }, [
              _c("span", { staticClass: "ant-popover-arrow popover-arrow" }),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "text-style" }, [
              _vm._v(
                "\n                Hi, " +
                  _vm._s(_vm.user.name) +
                  "\n            "
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("a-menu-divider"),
          _vm._v(" "),
          _c(
            "a-menu-item",
            { on: { click: _vm.onItemClick } },
            [
              _c("router-link", { attrs: { to: { name: "modifyPassword" } } }, [
                _vm._v("\n                修改密码\n            "),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "a-menu-item",
            { staticClass: "text-style", on: { click: _vm.onLogout } },
            [_vm._v("\n            登出\n        ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }