/*
 * Author: xymo
 * Created Date: 2021-04-21
 * email: xymo@yangqianguan.com
 */

import AvailableMap from 'collection-admin-web/common/constant/maps/available';
import Enum from 'collection-admin-web/common/util/enum';

export default Enum.from(AvailableMap);

export const AvailableBoolean = Enum.from({
    true: '启用',
    false: '禁用'
});
