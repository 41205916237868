var render, staticRenderFns
import script from "./simple-modal.vue?vue&type=script&lang=js&"
export * from "./simple-modal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/jenkins/agent/workspace/fintopia-web7chz7/fintopia-web-ci/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('70285282')) {
      api.createRecord('70285282', component.options)
    } else {
      api.reload('70285282', component.options)
    }
    
  }
}
component.options.__file = "src/common/modal/simple-modal/simple-modal.vue"
export default component.exports