<!-- @author giligili -->
<!-- @email zhimingwang@yangqianguan.com -->
<!-- @date 2021-09-16 11:42:19.663 -->
<!-- @desc generated by yqg-cli@5.1.2 -->

<template>
    <div class="yqg-antv-popover-content">
        <slot />
    </div>
</template>

<script type="text/babel">

    export default {
        name: 'YqgAntvPopoverContent'
    };
</script>
