/*
 * Author: giligili
 * Created Date: 2021-05-13
 * email: zhimingwang@yangqianguan.com
 */

export default {
    PENDING: 'PENDING', // 处理中
    ACCEPTED: 'ACCEPTED', // 同意
    REJECTED: 'REJECTED' // 拒绝
};
