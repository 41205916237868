/**
 * @author giligili
 * @date 2022-03-03
 * @email zhimingwang@yangqianguan.com
 * @desc 短信相关
 */

import Resource from '@yqg/resource';

const api = {
    urlPrefix: '/admin/operation/col/',

    /* @desc 批量发送短信 */
    batchSendMessage: {
        url: 'batchSendMessage',
        method: 'post'
    },

    /* @desc 获得手动批量发送短信的模版集合 */
    getCanManualBatchSendMessageGroups: {
        url: 'getCanManualBatchSendMessageGroups',
        method: 'get'
    },

    /* @desc 获得模版内容 */
    getMessageTemplate: {
        url: 'getMessageTemplate',
        method: 'get'
    },

    /* @desc 获得短信签名 */
    getAccessibleAppNames: {
        url: 'getAccessibleAppNames',
        method: 'get'
    }
};

export default Resource.create(api);
