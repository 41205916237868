var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-form-item",
    {
      attrs: {
        colon: false,
        label: _vm.label,
        "label-col": { span: 4 },
        "wrapper-col": { span: 20 },
      },
    },
    [
      _c("yqg-antv-select", {
        attrs: {
          value: _vm.value,
          multi: _vm.multi,
          "enum-type": _vm.enumType,
        },
        on: { change: _vm.onChange },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }