var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$app.isAuthorized(_vm.$app.PermissionTypes.CALL.MULTI.DIAL)
    ? _c("img", {
        staticClass: "yqg-add-multi-call-list",
        attrs: { src: _vm.Icon },
        on: {
          click: function ($event) {
            return _vm.onAddPhone()
          },
        },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }