import {assign, customGet, customPost} from '@yqg/resource';

const api = {
    callTest: customGet('admin/operation/call/callTest'),

    ychatCallTest: customPost('/rpc/originate/call-test'),

    collectionCallTest: customGet('/admin/operation/col/call/callTest'),
};

export default assign(api);
