<!-- @author giligili -->
<!-- @email zhimingwang@yangqianguan.com -->
<!-- @date 2022-11-24 08:51:06.029 -->
<!-- @desc generated by yqg-cli@5.1.1 -->

<template>
    <div class="alarm-multi-dial-transfer">
        【反馈提醒】

        <span class="mr10">{{ notification.data.timeRemind | dateTime }}</span>
        <span class="mr10">{{ notification.data.remark }}</span>

        <yqg-router-link
            class="mr10"
            text="请及时处理"
            to="case"
            :query="{caseId: encryCaseId}"
        />
    </div>
</template>

<script type="text/babel">

    export default {
        name: 'AlarmMultiDialTransfer',

        props: {
            notification: {
                type: Object,
                default: () => ({})
            }
        },

        computed: {
            encryCaseId() {
                return window.btoa(this.notification.data.caseId);
            }
        }
    };
</script>
