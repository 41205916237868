<!-- @Author: xiaoduoshu -->
<!-- @Date: 2023-08-18 17:07:01 -->
<!-- @Last Modified by: xiaoduoshu -->
<!-- @Last Modified time: 2023-08-18 17:07:01 -->

<template>
    <a-collapse
        :id="collapseId"
        @change="handleChange"
    >
        <a-collapse-panel>
            <slot />
        </a-collapse-panel>
    </a-collapse>
</template>

<script type="text/babel">

    export default {
        name: 'DetailCollapse',

        props: {
            records: {
                type: Array,
                default: null
            },

            onRefresh: {
                type: Function,
                required: true
            }
        },

        data() {
            return {
                collapseId: null
            };
        },

        mounted() {
            this.collapseId = `${this.$el.parentElement.className.trim().split(' ')[0]}-collapse`;
        },

        methods: {
            handleChange(activeKey) {
                // 关闭折叠板时无需调用接口获取数据
                if (this.records || !activeKey?.length) return;

                this.onRefresh();
            },
        }
    };

</script>
