/*
 * Author: giligili
 * Created Date: 2021-05-13
 * email: zhimingwang@yangqianguan.com
 */

import BooleanType from 'collection-admin-web/common/constant/types/boolean';

export default {
    [BooleanType.TRUE]: '启用',
    [BooleanType.FALSE]: '禁用'
};
