<!-- @Author: giligili -->
<!-- @Date: 2021-09-16 11:42:19.663 -->
<!-- @Last Modified by: xymo -->
<!-- @Last Modified time: 2023-08-15 17:04:50 -->

<template>
    <div class="yqg-antv-popover">
        <a-popover
            v-bind="$attrs"
            ref="antvPopover"
        >
            <template #content>
                <popover-content @hook:mounted="popoverContentMounted">
                    <slot name="content" />
                </popover-content>
            </template>

            <slot />
        </a-popover>
    </div>
</template>

<script type="text/babel">

    import PopoverContent from './components/popover-content';

    export default {
        name: 'YqgAntvPopover',

        components: {
            PopoverContent
        },

        inheritAttrs: false,

        methods: {
            popoverContentMounted() {
                // 这里的300ms 基于 ant-design-vue/es/vc-trigger/less effect animate-duration: 0.3s
                setTimeout(() => {
                    this.$refs.antvPopover?.$refs?.tooltip?.$refs?.tooltip?.$refs?.trigger?.forcePopupAlign();
                }, 300);
            }
        }
    };

</script>
