/**
 * @Author: giligili
 * @Date: 2021-06-16
 * @Last Modified by: yefenghan
 * @Last Modified time: 2024-06-13 15:34:47
 */

import Enum from 'collection-admin-web/common/util/enum';

import ConnectedStatusMap from '../maps/call-connected-status';
import {default as ContactMap, QualityContactMap} from '../maps/call-contact';
import DialMap from '../maps/call-dial';
import CallDirectionMap from '../maps/call-direction';
import DisconnectedUserMap from '../maps/call-disconnected-user';
import CallRepairChannelMap from '../maps/call-repair-channel';
import SatisfactionTransferMap from '../maps/call-satisfaction-transfer';
import CollecteeTypeMap from '../maps/collectee-type';

export const DialType = Enum.from(DialMap);
export const ContactType = Enum.from(ContactMap);
export const QualityContactType = Enum.from(QualityContactMap);
export const CollecteeType = Enum.from(CollecteeTypeMap);
export const CallDirection = Enum.from(CallDirectionMap);
export const ConnectedStatus = Enum.from(ConnectedStatusMap);
export const DisconnectedUser = Enum.from(DisconnectedUserMap);
export const CallRepairChannel = Enum.from(CallRepairChannelMap);
export const SatisfactionTransfer = Enum.from(SatisfactionTransferMap);

export const CallType = Enum.from({
    NORMAL: '手动拨打', // 手动拨打
    MULTI: '一键多呼', // 一键多呼
    RECOMMEND_MULTI: '一键多呼-批量推荐', // 推荐的一键多呼
    DIAL_BACK: '回拨', // 回拨
    TRANSFER: '转接', // 转接
    COMPLAINT_DIAL_BACK: '投诉进线', // 一种特殊的回拨
    PREDICT: '预测外呼', // 预测外呼
    PREDICT_MANUAL: '预测外呼-手动', // 预测外呼-手动
    IVR: 'IVR', // IVR
    SMART_IVR: '智能外呼', // 智能外呼
});

// 录音上报枚举
export const RecordAccessType = Enum.from({
    ONLINE: '访问',
    DOWNLOAD: '下载'
});

// 录音查询 拨打状态枚举
export const CallConnectedStatusMap = Enum.from({
    INIT: '新建',
    RING_READY: '振铃中',
    ANSWERED: '通话中',
    HANGUP: '已挂断',
});
export const CallUnConnectedStatusMap = Enum.from({
    FAILED: '呼叫失败',
    RING_HANGUP: '振铃后挂断'
});
export const CallStatusMap = Enum.from({
    ...CallConnectedStatusMap.MAP,
    ...CallUnConnectedStatusMap.MAP
});

// 外呼查询 拨打状态枚举
export const CallOutConnectedStatusMap = Enum.from({
    INIT: '新建',
    HANGUP: '已挂断',
});
export const CallOutUnConnectedStatusMap = Enum.from({
    FAILED: '呼叫失败',
    RING_HANGUP: '振铃后挂断'
});
export const CallOutStatusMap = Enum.from({
    ...CallOutConnectedStatusMap.MAP,
    ...CallOutUnConnectedStatusMap.MAP
});

export const HangupCauseMap = Enum.from({
    NORMAL_CLEARING: '正常挂断',
    ORIGINATOR_CANCEL: '主叫取消',
    UNALLOCATED_NUMBER: '号码不存在',
    USER_BUSY: '用户正忙',
    NO_USER_RESPONSE: '暂时无法接通',
    NO_ANSWER: '无应答',
    NO_ROUTE_TRANSIT_NET: '无法路由到网络',
    NO_ROUTE_DESTINATION: '无法路由到目的地',
    SUBSCRIBER_ABSENT: '用户不可及',
    CALL_REJECTED: '拒绝呼叫',
    NUMBER_CHANGED: '号码已改变',
    REDIRECTION_TO_NEW_DESTINATION: '重定向',
    EXCHANGE_ROUTING_ERROR: '交换路由错误',
    DESTINATION_OUT_OF_ORDER: '目标无序',
    INVALID_NUMBER_FORMAT: '非法号码格式',
    FACILITY_REJECTED: '设备拒绝',
    NORMAL_UNSPECIFIED: '未指定的正常情况',
    NORMAL_CIRCUIT_CONGESTION: '路由不可达',
    NETWORK_OUT_OF_ORDER: '网络状态不好',
    NORMAL_TEMPORARY_FAILURE: '暂时失败',
    SWITCH_CONGESTION: '设备拥塞',
    REQUESTED_CHAN_UNAVAIL: '通道无法接入',
    OUTGOING_CALL_BARRED: '禁止呼出',
    INCOMING_CALL_BARRED: '禁止呼入',
    BEARERCAPABILITY_NOTAUTH: '无权限发送',
    BEARERCAPABILITY_NOTAVAIL: '发送无效',
    BEARERCAPABILITY_NOTIMPL: '发送无法执行',
    FACILITY_NOT_IMPLEMENTED: '设备无效',
    SERVICE_NOT_IMPLEMENTED: '服务不可用',
    INCOMPATIBLE_DESTINATION: '目标冲突',
    RECOVERY_ON_TIMER_EXPIRE: '定时器超时',
    CHANNEL_UNACCEPTABLE: '通道无法接入',
    CALL_AWARDED_DELIVERED: '已经建立频道',
    RESPONSE_TO_STATUS_ENQUIRY: '状态查询',
    ACCESS_INFO_DISCARDED: '信息丢弃',
    FACILITY_NOT_SUBSCRIBED: '设备没有预定',
    SERVICE_UNAVAILABLE: '服务无效',
    CHAN_NOT_IMPLEMENTED: '不支持的通道类型',
    INVALID_CALL_REFERENCE: '无效的通话引用',
    INVALID_MSG_UNSPECIFIED: '无效信息',
    MANDATORY_IE_MISSING: '缺失必要消息',
    MESSAGE_TYPE_NONEXIST: '无效消息类型',
    WRONG_MESSAGE: '错误消息',
    IE_NONEXIST: '信息单元不存在',
    INVALID_IE_CONTENTS: '无效信息内容',
    WRONG_CALL_STATE: '错误通话状态',
    MANDATORY_IE_LENGTH_ERROR: '参数错误',
    PROTOCOL_ERROR: '协议错误',
    INTERWORKING: '交互通话已结束',
    CRASH: '系统崩溃',
    SYSTEM_SHUTDOWN: '系统停止',
    LOSE_RACE: '竞争失败',
    MANAGER_REQUEST: '人工干预',
    BLIND_TRANSFER: '呼叫前转',
    ATTENDED_TRANSFER: '呼叫后转',
    ALLOTTED_TIMEOUT: '通道回答超时',
    USER_CHALLENGE: '用户挑战',
    MEDIA_TIMEOUT: '媒体超时',
    USER_NOT_REGISTERED: '用户未注册',
    PROGRESS_TIMEOUT: '处理超时',
    GATEWAY_DOWN: '网关错误',
    PICKED_OFF: '分机截获接听',
    PRE_EMPTED: '未知情况',
    UNSPECIFIED: '未指定的情况',
    SYSTEM_ERROR: '内部错误',
    NO_AVAILABLE_GATEWAY: '没有可用网关',
    USER_UNREGISTER: '坐席登出',
    ABNORMAL_CALL: '异常通话',
    INVALID_GATEWAY: '网关不存在',
    USER_ANSWER_HANGUP: '坐席挂断',
    PHONE_ANSWER_HANGUP: '用户挂断',
    BLACKLIST_NUMBER: '黑名单号码',
    FORCE_HANGUP: '强制挂断',
    TRANSFER_HANGUP: '转接挂断',
    PROCESSING_CALL: '有正在进行的通话',
    FORCE_LOGOUT: '强制登出坐席导致的挂断',
    USER_ANOTHER_REGISTER: '坐席异常登录导致的挂断',
    TAKE_OVER_HANGUP: '接管挂断',
    INBOUND_WAIT_TIMEOUT: '呼入等待时间过长系统挂断',
    SYSTEM_HANGUP: '系统挂断',
    UNKNOWN: '未知原因',
});

export const VoiceResultMap = Enum.from({
    SELF: '本人声纹',
    NOT_SELF: '疑似非本人声纹',
    BLACK: '疑似黑产声纹',
    UN_REGISTERED: '未找到用户声纹',
    RECOGNIZE_FAIL: '识别失败',
    WAIT_RECOGNIZE: '等待声纹识别',
    NOT_VOICE_RECOGNIZE: '不识别声纹'
});

export {
    ComplaintFlowResultsMap,
    ComplaintConnectResultsMap,
    ComplaintUnconnectResultsMap,

    WechatResultsMap,
    ConnectResultsMap,
    UnconnectResultsMap,
    FlowResultsMap,

    CaseSearchConnectResultsMap,
    CaseSearchUnconnectResultsMap,

    default as CollectionResult
} from './flow-results-map';

export {default as Channel} from './call-center-type';
export {default as CollectionType} from './collection-type';
export {default as SatisfactionResult} from './satisfaction-result';
