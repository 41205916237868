/*
 * @Author: huayizhang 
 * @Date: 2021-06-02 13:58:58 
 * @Last Modified by: yefenghan
 * @Last Modified time: 2024-07-31 21:27:27
 */

import _ from 'underscore';

import {PermissionMap} from 'collection-admin-web/common/constant/permission-type';
import User from 'collection-admin-web/common/resource/user';
import Enum from 'collection-admin-web/common/util/enum';

export const COLLECTOR_ID_ALL_KEY = 0;

export const PredictCollectorMap = Enum.query(
    () => User.getCollectorMap({params: {privilegeType: PermissionMap.ROLE_TYPE_YQG_AUTO_DIAL}}),
    {
        queryOnce: true,
        dataBodyField: 'collectorMap'
    }
);

export const CollectorMapWithoutAll = Enum.query(() => User.getCollectorMap().then(resp => {
    const {collectorMap} = resp.data.body;
    resp.data.body.$collectorMap = _.omit(collectorMap, COLLECTOR_ID_ALL_KEY);

    return resp;
}), {
    queryOnce: true,
    dataBodyField: '$collectorMap'
});

export const CollectorMapWithSystem = Enum.query(() => User.getCollectorMap().then(resp => {
    const {collectorMap} = resp.data.body;
    resp.data.body.$collectorMap = {
        '-1': '客服系统',
        '0': '催收系统',
        ...collectorMap
    };

    return resp;
}), {
    queryOnce: true,
    dataBodyField: '$collectorMap'
});
export default Enum.query(User.getCollectorMap, {
    queryOnce: true,
    dataBodyField: 'collectorMap'
});
