/**
 * @Author: giligili
 * @Date: 2021-08-18
 * @Last Modified by: xymo
 * @Last Modified time: 2024-06-05 17:30:04
 */

import EnumAll from 'collection-admin-web/common/constant/enum';
import {aesEncryptCallSecret} from 'collection-admin-web/common/util/encryption';

import BasicType from './basic-type';

const CallDialType = EnumAll.Call.DialType.TYPE;

export default class DialType extends BasicType {

    type = 'DIAL';

    constructor(props) {
        super(props);

        const {encryptedMobileNumber, mobileNumber} = this.sharedData;

        this.extraData.dialType = CallDialType.NORMAL;
        this.extraData.encryptedMobileNumber = this.extraData.contactInfo?.encryptedMobileNumber
            || encryptedMobileNumber
            || aesEncryptCallSecret(mobileNumber);
    }

    /* @desc override */
    getHeaders() {
        const {headersRet, extraData, ytalkConfig} = this;

        const {callTag, callingNumber, areaCode, areaType} = extraData;

        const headers = [
            {key: 'X-Gateway-Tag', value: callTag || ytalkConfig.callTag},
            {key: 'X-Virtual-Caller-Number', value: callingNumber},
            {key: 'X-Area-Type', value: areaType},
            {key: 'X-Area-Code', value: areaCode}
        ].filter(({value}) => value);

        if (!headers.length) headersRet.reject();

        headersRet.resolve(headers.reduce(
            (accu, {key, value}) => ({
                ...accu,
                [key]: value
            }),
            {}
        ));

        return headersRet.promise;
    }

}
