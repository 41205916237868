<!-- @author huayizhang -->
<!-- @email huayizhang@yangqianguan.com -->
<!-- @date 2021-07-07 14:57:34.474 -->
<!-- @desc generated by yqg-cli@1.2.7 -->

<template>
    <a-button
        v-if="processFlowId && processFlowType"
        type="link"
        class="yqg-flow-id-link"
        @click="to()"
    >
        {{ text || processFlowId }}
    </a-button>
</template>

<script type="text/babel">

    import * as RouterName from 'collection-admin-web/common/constant/router-name';

    export default {
        name: 'YqgFlowIdLink',

        props: {
            processFlowId: {
                type: [String, Number],
                default: null
            },

            processFlowType: {
                type: String,
                default: ''
            },

            text: {
                type: String,
                default: ''
            }
        },

        data() {
            const {processFlowId, processFlowType} = this;
            const [routerName, query] = processFlowType === 'CREATE_USER'
                ? [RouterName.AccountApplyDetail, {processFlowId}]
                : [RouterName.QualityReviewDetail, {processFlowId: window.btoa(processFlowId), processFlowType}];
            const {route: {meta: {yqgPrivileges = []}}} = this.$router.resolve({name: routerName});

            return {
                hasPermission: this.$app.isAuthorized(yqgPrivileges),
                routerName,
                query
            };
        },

        methods: {
            to() {
                const {hasPermission, routerName, query} = this;

                if (!hasPermission) return;

                const {href} = this.$router.resolve({
                    name: routerName,
                    query
                });

                window.open(href, '_blank');
            }
        }
    };

</script>
