/**
 * @Author: xymo
 * @Date: 2024-08-22
 * @Last Modified by: xymo
 * @Last Modified time: 2024-08-22 14:16:39
 */

import _ from 'underscore';

export default class Storage {

    constructor(key, useSessionStorage = false) {
        this.key = key;
        this.cb = _.noop;
        this.useSessionStorage = useSessionStorage;
        this.storage = useSessionStorage ? sessionStorage : localStorage;
    }

    set(cache) {
        this.storage.setItem(this.key, JSON.stringify(cache));
    }

    get() {
        try {
            return JSON.parse(this.storage.getItem(this.key));
        } catch (err) {
            return null;
        }
    }

    remove() {
        this.storage.removeItem(this.key);
    }

    on(fn) {
        this.cb = event => {
            if (event.key === this.key && _.isFunction(fn)) {
                fn(event);
            }
        };

        window.addEventListener('storage', this.cb);
    }

    off(needsRemove = false) {
        this.cb = _.noop;
        window.removeEventListener('storage', this.cb);
        if (needsRemove) this.remove();
    }

}
