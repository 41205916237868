/**
 * @author giligili
 * @date 2021-06-24
 * @email zhimingwang@yangqianguan.com
 * @desc 通话类型
 */

export default {
    NORMAL: 'NORMAL', // 手拨(预测式外呼)
    DIAL_BACK: 'DIAL_BACK', // 回拨
    MULTI: 'MULTI', // 一键多呼
    RECOMMEND_MULTI: 'RECOMMEND_MULTI', // 推荐的一键多呼
    TRANSFER: 'TRANSFER', // 转接
    COMPLAINT_DIAL_BACK: 'COMPLAINT_DIAL_BACK', // 投诉进线
    SMART_IVR_TRANSFER_MANUAL: 'SMART_IVR_TRANSFER_MANUAL' // 智能外呼转人工
};
