import { render, staticRenderFns } from "./html-text.vue?vue&type=template&id=087939b6&"
import script from "./html-text.vue?vue&type=script&lang=js&"
export * from "./html-text.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/jenkins/agent/workspace/fintopia-web7chz7/fintopia-web-ci/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('087939b6')) {
      api.createRecord('087939b6', component.options)
    } else {
      api.reload('087939b6', component.options)
    }
    module.hot.accept("./html-text.vue?vue&type=template&id=087939b6&", function () {
      api.rerender('087939b6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/app/knowledge/application/component/html-text.vue"
export default component.exports