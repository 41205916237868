<template>
    <yqg-simple-form
        :title="title"
        :options="options"
        :confirm-label="confirmLable"
        @confirm="confirm"
        @cancel="$emit('dismiss')"
    >
        <template
            v-if="href"
            #extraBtns
        >
            <a-button
                class="yqg-form-btn yqg-btn-yellow"
                :href="href"
                download
            >
                {{ downloadButtonText }}
            </a-button>
        </template>
    </yqg-simple-form>
</template>

<script type="text/babel">

    import FormOptions from './constant/form-options';

    export default {
        name: 'SimpleUploadModal',

        props: {
            api: {
                type: Function,
                required: true
            },

            title: {
                type: String,
                required: true
            },

            confirmLable: {
                type: String,
                default: '上传文件'
            },

            downloadButtonText: {
                type: String,
                default: '下载模版文件'
            },

            fileKey: {
                type: String,
                default: 'file'
            },

            fileLable: {
                type: String,
                default: '选择Excel文件'
            },

            href: {
                type: String,
                default: ''
            },

            extra: {
                type: String,
                default: ''
            }
        },

        computed: {
            options() {
                const {fileKey, fileLable, extra} = this;

                return FormOptions({fileKey, fileLable, extra});
            }
        },

        methods: {
            async confirm({values}) {
                this.$download({resource: this.api, data: values})
                    .then(({data: {body}}) => {
                        if (!body) return;

                        this.$app.opSuccess();
                        this.$emit('close');
                    })
                    .catch(err => err);
            }
        }
    };
</script>
