<!-- @Author: giligili -->
<!-- @Date: 2021-07-06 18:26:32.295 -->
<!-- @Last Modified by: xymo -->
<!-- @Last Modified time: 2023-09-01 16:39:49 -->

<template>
    <yqg-modal
        class="user-info-modal"
        :class="customClass"
        title="用户信息详情"
        v-on="btnConfig"
    >
        <template #container>
            <div v-if="userInfo">
                <a-row>
                    <a-col :span="6">
                        姓名
                    </a-col>
                    <a-col :span="18">
                        {{ userName }}
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="6">
                        身份证号
                    </a-col>
                    <a-col :span="18">
                        {{ userInfo.idInfo.idNo | idNumberMask }}
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="6">
                        年龄
                    </a-col>
                    <a-col :span="18">
                        {{ age }}
                    </a-col>
                </a-row>

                <a-row>
                    <a-col :span="6">
                        民族
                    </a-col>
                    <a-col :span="18">
                        {{ userInfo.idInfo.nation }}
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="6">
                        地址
                    </a-col>
                    <a-col :span="18">
                        {{ userInfo.idInfo.address }}
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="6">
                        发证机关
                    </a-col>
                    <a-col :span="18">
                        {{ userInfo.idInfo.issueAgency }}
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="6">
                        有效期
                    </a-col>
                    <a-col :span="18">
                        {{ userInfo.idInfo.expireDate | moment('YYYY年MM月DD日') }}
                    </a-col>
                </a-row>

                <template v-if="userInfo.loanBankAccount">
                    <a-row>
                        <a-col :span="6">
                            鉴权储蓄卡号
                        </a-col>
                        <a-col :span="18">
                            {{ userInfo.loanBankAccount.bankAccountNumber }}
                        </a-col>
                    </a-row>
                    <a-row>
                        <a-col :span="6">
                            银行名称
                        </a-col>
                        <a-col :span="18">
                            {{ userInfo.loanBankAccount.bankName }}
                        </a-col>
                    </a-row>
                    <a-row>
                        <a-col :span="6">
                            银行预留手机号
                        </a-col>
                        <a-col :span="18">
                            {{ userInfo.loanBankAccount.mobileNumber | phoneNumberMask3 }}

                            <yqg-layout-dial-btn
                                :case-id="caseId"
                                :encrypted-mobile-number="encryptedBankMobile"
                                :contact-info="contactInfo"
                                :relative-type="relativeType"
                                :call-tag="callTag"
                                :source="dialSource"
                            />
                        </a-col>
                    </a-row>
                </template>

                <a-row>
                    <a-col :span="6">
                        注册手机号
                    </a-col>
                    <a-col :span="18">
                        {{ mobileNumber | phoneNumberMask3 }}
                    </a-col>
                </a-row>

                <a-row v-if="riskBaseInfo">
                    <a-col :span="6">
                        电子邮件
                    </a-col>
                    <a-col :span="18">
                        {{ riskBaseInfo.email | emailMask }}
                    </a-col>
                </a-row>

                <a-row>
                    <a-col :span="6">
                        省-市-区
                    </a-col>
                    <a-col :span="18">
                        {{ userInfo.allContactInfo.addressProvince }}
                        -
                        {{ userInfo.allContactInfo.addressCity }}
                        -
                        {{ userInfo.allContactInfo.addressDistrict }}
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="6">
                        详细地址
                    </a-col>
                    <a-col :span="18">
                        {{ userInfo.allContactInfo.addressDetail }}
                    </a-col>
                </a-row>

                <a-row v-if="riskBaseInfo">
                    <a-col :span="6">
                        学历
                    </a-col>
                    <a-col :span="18">
                        {{ riskBaseInfo.education || '/' }}
                    </a-col>
                </a-row>

                <a-row>
                    <a-col :span="6">
                        婚姻状态
                    </a-col>
                    <a-col :span="18">
                        {{ $options.MarryStatusMap[userInfo.allContactInfo.marryStatus] }}
                    </a-col>
                </a-row>

                <a-row v-if="riskBaseInfo">
                    <a-col :span="6">
                        就业状况
                    </a-col>
                    <a-col :span="18">
                        {{ riskBaseInfo.workStatus || '/' }}
                    </a-col>
                </a-row>

                <a-row>
                    <a-col :span="6">
                        公司名称
                    </a-col>
                    <a-col :span="18">
                        {{ userInfo.cashLoanEmploymentInfo.companyName }}
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="6">
                        月收入（元）
                    </a-col>
                    <a-col :span="18">
                        {{ userInfo.cashLoanEmploymentInfo.monthlyIncome }}
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="6">
                        职业
                    </a-col>
                    <a-col :span="18">
                        {{ userInfo.cashLoanEmploymentInfo.occupation }}
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="6">
                        工资发放日
                    </a-col>
                    <a-col :span="18">
                        {{ formatPayDay(userInfo) }}
                    </a-col>
                </a-row>
                <a-row v-if="userInfo.companyAddress">
                    <a-col :span="6">
                        公司地址
                    </a-col>
                    <a-col :span="18">
                        {{ userInfo.companyAddress }}
                    </a-col>
                </a-row>
            </div>
        </template>
    </yqg-modal>
</template>

<script type="text/babel">

    import {pinyin} from 'pinyin-pro';

    import EnumAll from 'collection-admin-web/common/constant/enum';
    import OrderDataType from 'collection-admin-web/common/constant/types/order-data-type';
    import RelativeType from 'collection-admin-web/common/constant/types/relative-type';
    import GeneralResource from 'collection-admin-web/common/resource/general';
    import {aesEncryptCallSecret} from 'collection-admin-web/common/util/encryption';

    import MarryStatusMap from './constant/marry-status-map';

    export default {
        name: 'UserInfoModal',

        MarryStatusMap,

        props: {
            caseId: {
                type: [String, Number],
                required: true
            },

            callTag: {
                type: String,
                default: undefined
            },

            dataType: {
                type: String,
                default: OrderDataType.ORDER
            },

            collectionFlows: {
                type: Array,
                default: undefined
            },

            relativeType: {
                type: String,
                default: RelativeType.CASE
            },

            customClass: {
                type: String,
                default: ''
            },

            showBottomBtn: {
                type: Boolean,
                default: true
            },

            dialSource: {
                type: String,
                required: true
            }
        },

        data() {
            return {
                maskNum: 6,
                age: null,
                userInfo: null,
                userName: null,
                riskBaseInfo: null,

                contactInfo: {},
                encryptedBankMobile: '',
            };
        },

        computed: {
            mobileNumber() {
                return this.userInfo?.mobileNumberInfo?.mobileNumber;
            },

            serviceProvider() {
                return this.userInfo?.mobileNumberInfo?.serviceProvider;
            },

            btnConfig() {
                return this.showBottomBtn ? {
                    cancel: () => this.$emit('dismiss'),
                    confirm: () => this.$emit('dismiss')
                } : {};
            }
        },

        mounted() {
            this.fetchUserInfo();
        },

        methods: {
            formatPayDay({cashLoanEmploymentInfo: {payDay = '0'}}) {
                return payDay === '0' ? '不定期' : '每月' + payDay + '日';
            },

            fetchUserInfo() {
                const {caseId, dataType} = this;

                GeneralResource.getData({params: {parentId: caseId, dataType}})
                    .then(({data: {body: {dataList: {authInfo = {}, riskInfo = null}}}}) => {
                        // 兼容idInfo为空的情况
                        authInfo.idInfo = authInfo.idInfo || {};
                        this.userInfo = authInfo;
                        this.age = authInfo.idInfo.idNo ? new Date().getFullYear() - authInfo.idInfo.idNo.substring(6, 10) : '';
                        this.userName = this.getUserName(authInfo.idInfo.name);

                        this.riskBaseInfo = riskInfo;

                        this.contactInfo = {
                            type: EnumAll.Call.ContactType.TYPE.SELF,
                            name: authInfo.idInfo.name,
                            collectionFlows: this.collectionFlows
                        };
                        this.encryptedBankMobile = aesEncryptCallSecret(authInfo.loanBankAccount.mobileNumber);
                    })
                    .catch(err => err);
            },
            getUserName(userName) {
                if (!userName) return '';
                if (this.$app.permissions.EXTRA_DISPLAY_UNMASK_USERNAME) {
                    // 有权限只返回姓名 需要显示姓名和拼音
                    return `${userName} ${pinyin(userName, {surname: 'head', type: 'string'})}`;
                }

                // 无权限只展示拼音
                return userName;
            },
        }
    };

</script>
