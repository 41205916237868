<!-- @author giligili -->
<!-- @email zhimingwang@yangqianguan.com -->
<!-- @date 2021-08-03 12:22:43.545 -->
<!-- @desc generated by yqg-cli@5.1.1 -->

<template>
    <yqg-router-link
        to="qualityReview"
        :query="nextQuery"
    >
        <slot>{{ text }}</slot>
    </yqg-router-link>
</template>

<script type="text/babel">
    export default {
        name: 'YqgQualityReviewLink',

        props: {
            processFlowId: {
                type: [String, Number],
                required: true
            },

            processFlowType: {
                type: String,
                default: undefined
            },

            text: {
                type: String,
                default: '查看详情'
            },

            query: {
                type: Object,
                default: () => ({})
            }
        },

        computed: {
            encryProcessFlowId() {
                return window.btoa(this.processFlowId);
            },

            nextQuery() {
                const {query, encryProcessFlowId} = this;

                return {
                    ...query,
                    processFlowId: encryProcessFlowId
                };
            }
        }
    };
</script>
