/*
 * @Author: xiaodongyu
 * @Date 2019-11-29 23:27:11
 * @Last Modified by: hongweihu
 * @Last Modified time: 2023-11-08 14:45:52
 */
import moment from 'moment';
import DefValue from '../component/def-value.vue';
import { dateTimeDef } from './common-fields';
export * from './common-fields';
export const extendsDefValue = (options) => (Object.assign({ extends: DefValue }, options));
export const dateTimeDayStartDef = Object.assign(Object.assign({}, dateTimeDef), { props: { showTime: { defaultValue: moment('00:00:00', 'HH:mm:ss') } } });
export const dateTimeDayEndDef = Object.assign(Object.assign({}, dateTimeDef), { props: { showTime: { defaultValue: moment('23:59:59', 'HH:mm:ss') } } });
