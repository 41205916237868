var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "call-list" }, [
    _c(
      "div",
      { staticClass: "container mb20" },
      [
        _c(
          "p",
          [
            _vm._v(
              "\n            当前已选择 " +
                _vm._s(_vm.numberList.length) +
                " 个号码,\n            最多选择 " +
                _vm._s(_vm.multiListMaxSize) +
                " 个\n            "
            ),
            _c("a-button", {
              attrs: { size: "small" },
              domProps: { textContent: _vm._s("清空") },
              on: { click: _vm.onDeleteAll },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _vm.caseList.length
          ? _c("contact-list", {
              attrs: { "data-source": _vm.caseList },
              scopedSlots: _vm._u(
                [
                  {
                    key: "op",
                    fn: function (ref) {
                      var data = ref.data
                      var record = ref.record
                      return [
                        _c("a-button", {
                          attrs: {
                            size: "small",
                            type: "danger",
                            icon: "delete",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.onDelete(data, record)
                            },
                          },
                        }),
                      ]
                    },
                  },
                ],
                null,
                false,
                1027825883
              ),
            })
          : _c("a-empty"),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "footer" },
      [
        _vm.caseList.length
          ? _c("yqg-layout-multi-call-btn", {
              attrs: {
                "data-source": _vm.numberList,
                value: _vm.encryptedMobileNumberList,
              },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }