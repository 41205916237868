var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ant-table ant-table-bordered" }, [
    _c("div", { staticClass: "ant-table-body" }, [
      _c(
        "table",
        { staticClass: "info-table", staticStyle: { "table-layout": "fixed" } },
        [
          _vm._l(_vm.tableOptions, function (group, groupIdx) {
            return [
              _c("thead", { key: groupIdx, staticClass: "ant-table-thead" }, [
                _c(
                  "tr",
                  { key: groupIdx },
                  _vm._l(group, function (ref, idx) {
                    var label = ref.label
                    var headerTip = ref.headerTip
                    return _c(
                      "th",
                      { key: idx },
                      [
                        _vm._v(
                          "\n                            " +
                            _vm._s(_vm.$t(label)) +
                            "\n                            "
                        ),
                        headerTip
                          ? _c(
                              "a-tooltip",
                              {
                                attrs: {
                                  trigger: headerTip.trigger || "hover",
                                  placement: headerTip.placement || "top",
                                },
                              },
                              [
                                _c("template", { slot: "title" }, [
                                  _c("span", [
                                    _vm._v(_vm._s(headerTip.content)),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("a-icon", {
                                  style: headerTip.style || {},
                                  attrs: { type: "question-circle" },
                                }),
                              ],
                              2
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ]),
              _vm._v(" "),
              _c(
                "tbody",
                { key: "tbody-" + groupIdx, staticClass: "ant-table-tbody" },
                [
                  _c(
                    "tr",
                    _vm._l(group, function (def, idx) {
                      return _c(
                        "td",
                        { key: idx },
                        [
                          _vm._t(
                            def.field,
                            function () {
                              return [
                                _c(
                                  def.staticComp || "def-value",
                                  _vm._b(
                                    { tag: "component" },
                                    "component",
                                    _vm.getCompProps({
                                      def: def,
                                      value: _vm.pickValue(
                                        _vm.tableData,
                                        def.field
                                      ),
                                      record: _vm.tableData,
                                      defaultText: "/",
                                    }),
                                    false
                                  )
                                ),
                              ]
                            },
                            null,
                            {
                              def: def,
                              value: _vm.pickValue(_vm.tableData, def.field),
                              record: _vm.tableData,
                              defaultText: "/",
                            }
                          ),
                        ],
                        2
                      )
                    }),
                    0
                  ),
                ]
              ),
            ]
          }),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }