/**
 * @author center
 * @date 2022/12/20-4:22 下午
 * @file swim-lane
 */

import Storage from '.';

const StorageKey = 'SwimLane';
export default new Storage(StorageKey, true);
