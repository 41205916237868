/**
 * @author giligili
 * @date 2021-11-15
 * @email zhimingwang@yangqianguan.com
 * @desc 呼叫方向
 */

import CallDirection from '../types/call-direction-types';

export default {
    [CallDirection.CALL_IN]: '呼入',
    [CallDirection.CALL_OUT]: '呼出'
};
