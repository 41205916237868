/**
 * @Author: xiaodongyu
 * @Date: 2023-02-28 14:40:22
 * @Last Modified by: xiaodongyu
 * @Last Modified time: 2023-02-28 14:41:16
 */
import { pickValue } from '../util/object';
export const DEFAULT_PAGINATION = {
    pageSizeOptions: ['10', '20', '50', '100', '200'],
    showSizeChanger: true
};
export const DEFAULT_TABLE_OPTIONS = {
    bordered: true,
    scroll: { x: true },
    rowKey: 'id'
};
export const ClientSorterMap = {
    number: field => (record1, record2) => {
        const val1 = pickValue(record1, field) || 0;
        const val2 = pickValue(record2, field) || 0;
        return val1 - val2;
    },
    string: field => (record1, record2) => {
        const val1 = pickValue(record1, field) || '';
        const val2 = pickValue(record2, field) || '';
        return `${val1}`.localeCompare(`${val2}`);
    }
};
