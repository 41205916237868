/*
* @Author: wenxiujiang
* @Date:   2019-03-15 13:40:50
 * @Last Modified by: huayizhang
 * @Last Modified time: 2023-02-16 18:55:57
*/

export const BooleanType = {
    TRUE: 'TRUE',
    FALSE: 'FALSE'
};

export const BooleanMap = {
    TRUE: '是',
    FALSE: '否'
};

export const LowerBooleanType = {
    true: 'true',
    false: 'false'
};

export const LowerBooleanMap = {
    true: '是',
    false: '否'
};
