var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.isHideNav
      ? _c(
          "div",
          {
            directives: [
              {
                name: "b-click-outside",
                rawName: "v-b-click-outside",
                value: _vm.closeDropDownMenu,
                expression: "closeDropDownMenu",
              },
            ],
            staticClass: "nav-menu-wrap container-wide",
          },
          [
            _c(
              "div",
              { staticClass: "nav container-wide mat" },
              [
                _c("div", { staticClass: "nav-menu container" }, [
                  _c("div", { staticClass: "nav-drop" }, [
                    _c(
                      "a",
                      {
                        staticClass: "drop-down-icon",
                        on: { click: _vm.toggleDropDownMenu },
                      },
                      [_vm._m(0)]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "nav-left",
                      on: { click: _vm.closeDropDownMenu },
                    },
                    [_vm._t("left")],
                    2
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "nav-root-wrap level-center" }, [
                    _c(
                      "ul",
                      { staticClass: "level-center" },
                      _vm._l(_vm.routes.root, function (route) {
                        return _c(
                          "li",
                          { key: route.path },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "nav-link",
                                class: { active: _vm.isActive(route) },
                                attrs: { to: route },
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(_vm.getI18n(route.meta.navTitle)) +
                                    "\n                            "
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      }),
                      0
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "nav-center" },
                    [_vm._t("center")],
                    2
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "nav-right" }, [_vm._t("right")], 2),
                ]),
                _vm._v(" "),
                _c("transition", { attrs: { name: "nav-slide" } }, [
                  _vm.isDropDownMenuOpen
                    ? _c(
                        "div",
                        { staticClass: "nav-drop-menu container-wide" },
                        [
                          _c(
                            "ul",
                            { staticClass: "container b-col" },
                            _vm._l(_vm.routes.root, function (route) {
                              return _c(
                                "li",
                                {
                                  key: route.path,
                                  class: { active: _vm.isActive(route) },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "drop-menu-item",
                                      on: {
                                        click: function ($event) {
                                          return _vm.dropMenuJump(route)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(
                                            _vm.getI18n(route.meta.navTitle)
                                          ) +
                                          "\n                            "
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            }),
                            0
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _vm.hasSubMenu && _vm.currentSubMenu
              ? _c(
                  "div",
                  { staticClass: "sub-nav container-wide level-center" },
                  [
                    _c(
                      "ul",
                      { staticClass: "level-center sub-nav-list" },
                      _vm._l(_vm.currentSubMenu, function (route) {
                        return _c(
                          "li",
                          {
                            key: route.path,
                            staticClass: "sub-nav-item",
                            class: { active: _vm.isActive(route) },
                          },
                          [
                            _c("router-link", { attrs: { to: route } }, [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.getI18n(route.meta.navTitle)) +
                                  "\n                    "
                              ),
                            ]),
                          ],
                          1
                        )
                      }),
                      0
                    ),
                  ]
                )
              : _vm._e(),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "nav-buffer" }),
    _vm._v(" "),
    _vm.hasSubMenu && _vm.currentSubMenu
      ? _c("div", { staticClass: "sub-nav-buffer" })
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [_c("i", { staticClass: "b-icon-unordered-list" })])
  },
]
render._withStripped = true

export { render, staticRenderFns }