/**
 * @author KylesLight
 * @date 11/10/16-10:06 PM
 * @file UnitByteFilter
 */

export default function () {
    'ngInject';

    return size => {
        if (typeof size !== 'number' || size < 0) return 0;

        const units = ['B', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
        const unitFactor = 1024;
        let result = `${size} B`;
        let index = 0;

        while (size >= unitFactor && index !== units.length - 1) {
            index += 1;
            size /= unitFactor;
            result = `${size.toFixed(2)} ${units[index]}`;
        }

        return result;
    };
}
