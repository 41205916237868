/**
 * @author giligili
 * @date 2021-08-02
 * @email zhimingwang@yangqianguan.com
 * @desc fields
 */

import {genField} from 'collection-admin-web/common/util/tool';

export const collectorName = genField('催收员', 'collectorName', 'input');
export const displayMobileNumber = genField('号码', 'displayMobileNumber', 'input');
export const messageName = genField('模板', 'messageName', 'input');
export const sendTime = genField('定时', 'sendTime', 'dateTime');
export const op = genField('操作', 'op', 'input');
