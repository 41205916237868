/**
 * @author giligili
 * @date 2021-07-06
 * @email zhimingwang@yangqianguan.com
 * @desc 自定义时间format
 */

import moment from 'moment';

export default (val, format) => moment(val).format(format);
