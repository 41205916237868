/**
 * @Author: xymo
 * @Date: 2023-09-01
 * @Last Modified by: xymo
 * @Last Modified time: 2023-09-01 17:09:46
 */

export default phone => {
    if (!phone) return '';

    const num = phone.replace(/\D/g, '');

    if (num.length <= 5) {
        return phone;
    }

    return num.slice(0, 3) + '****' + num.slice(-4);
};
