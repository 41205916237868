var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("yqg-layout-network-loading", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.reqCount > 0,
            expression: "reqCount > 0",
          },
        ],
      }),
      _vm._v(" "),
      _c("a-config-provider", { attrs: { locale: _vm.zhCN } }, [
        _c(
          "div",
          { attrs: { id: "app-content" } },
          [
            _vm.fetched
              ? [
                  !_vm.$route.meta.customLayout
                    ? _c("yqg-layout")
                    : _c("router-view"),
                ]
              : _vm._e(),
            _vm._v(" "),
            _c("yqg-simple-modal", { attrs: { "disable-watermark": "" } }),
          ],
          2
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }