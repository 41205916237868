<!-- @Author: xinzhong -->
<!-- @Date: 2020-11-25 16:16:08.762 -->
<!-- @Last Modified by: giligili -->
<!-- @Last Modified time: 2023-05-26 16:08:44 -->

<template>
    <div id="app">
        <yqg-layout-network-loading v-show="reqCount > 0" />

        <a-config-provider :locale="zhCN">
            <div id="app-content">
                <template v-if="fetched">
                    <yqg-layout v-if="!$route.meta.customLayout" />
                    <router-view v-else />
                </template>

                <yqg-simple-modal disable-watermark />
            </div>
        </a-config-provider>
    </div>
</template>

<script>

import 'moment/locale/zh-cn';

import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';
import moment from 'moment';
import _ from 'underscore';
import Vue from 'vue';
import {mapActions, mapGetters} from 'vuex';

import {apiErrorReportToSentry} from '@shared/client/util/sentry-error';
import testNetwork from '@shared/client/util/speed-test/speed-test';
import YqgWatermark from '@shared/common/util/watermark';

import paramsSerializer from 'yqg-common/core/http.serialize';
import http from 'yqg-common/vue/vue-http';

import Component from 'collection-admin-web/common/component';
import {whiteList} from 'collection-admin-web/common/constant/error-request-whitelist';
import PermissionTypes from 'collection-admin-web/common/constant/permission-type';
import {Cockpit} from 'collection-admin-web/common/constant/router-name';
import BooleanType from 'collection-admin-web/common/constant/types/boolean';
import {
    CODE_SUCCESS,
    CODE_NOT_LOGIN,
    YQG_STATUS_CODE_COLLECTION_LOGIN_NEED_CAPTCHA as LOGIN_NEED_CAPTCHA,
    YQG_STATUS_CODE_COLLECTION_LOGIN_NEED_SMS_VERIFY as LOGIN_NEED_SMS_VERIFY,
    YQG_STATUS_CODE_COLLECTION_LONGTIME_NO_OPERATION_KICKOUT as LONGTIME_NO_OPERATION_KICKOUT,
    YQG_STATUS_CODE_COLLECTION_SEND_TOO_MANY_MESSAGE as SEND_TOO_MANY_MESSAGE,
    YQG_STATUS_CODE_COLLECTON_DATA_ACCESS_RESTRICTED as DATA_ACCESS_RESTRICTED,
    YQG_STATUS_CODE_COLLECTON_OPERATION_WARN as OPERATION_WARN,
    YQG_STATUS_CODE_COLLECTON_DIAL_CONTACT_BEFORE_VALID_SELF as DIAL_CONTACT_BEFORE_VALID_SELF,
    YQG_STATUS_CODE_COLLECTON_WEIGHT_OVER_LIMIT as WEIGHT_OVER_LIMIT,
    YQG_STATUS_CODE_COLLECTON_UNAVAILABLE_FOR_ENTERPRISE_WECHAT as UNAVAILABLE_FOR_ENTERPRISE_WECHAT,
    YQG_STATUS_CODE_COLLECTON_MESSAGE_EXCEED_SAME_MOBILE_LIMIT as MESSAGE_EXCEED_SAME_MOBILE_LIMIT,
    YQG_STATUS_CODE_COLLECTON_BUBBLE_SIGN_IN_FIRST as BUBBLE_SIGN_IN_FIRST
} from 'collection-admin-web/common/constant/yqg-status';
import Filter from 'collection-admin-web/common/filter';
import Global from 'collection-admin-web/common/global';
import Layout from 'collection-admin-web/common/layout';
import Modal from 'collection-admin-web/common/modal';
import Override from 'collection-admin-web/common/override';
import router, {setTitle} from 'collection-admin-web/common/router';
import store from 'collection-admin-web/common/store';
import RequestTimeoutLogger from 'collection-admin-web/common/util/logger/request-timeout';
import createUuid from 'collection-admin-web/common/util/uuid';

import Storage from 'src/common/util/local-storage';
import {isQuotedByHosts} from 'src/common/util/tool';

// set for Vue plugins
Vue.use(Global);
Vue.use(Filter);
Vue.use(Layout);
Vue.use(Component);
Vue.use(Override);
moment.locale('zh-cn');

export default {
    name: 'App',

    router,
    store,

    data() {
        return {
            zhCN,
            PermissionTypes,
            fetched: false,
            reqCount: 0,
            BooleanType,
            isYchatQuoted: isQuotedByHosts(['ychat']),

            // 全局保存组件实例(一些全局数据/方法是定义在组件内的, 不方便拆出来)
            ytalk: undefined,
            speedTest: null,
            watermark: null
        };
    },

    computed: {
        ...mapGetters(['isLogin', 'privileges', 'permissions', 'user'])
    },

    watch: {
        $route(route) {
            this.fetched = false;
            const {yqgPrivileges} = route.meta;
            if (yqgPrivileges && !this.isLogin) {
                const localUserInfo = Storage.getUserInfo();
                if (localUserInfo) {
                    this.syncUserInfo(localUserInfo).then(() => {
                        if (this.redirectToCockpit(route)) {
                            return;
                        }

                        // 本地缓存如果通过页面权限校验 直接加载页面
                        if (this.isAuthorized(yqgPrivileges)) {
                            this.fetched = true;
                        }
                    });
                }

                this.fetch().then(requestUserInfo => {
                    // 无缓存 可跳转时直接跳转
                    if (!localUserInfo && this.redirectToCockpit(route)) {
                        return;
                    }

                    if (!this.isAuthorized(yqgPrivileges)) {
                        // 鉴权不通过 路由跳转到主页不需要再额外处理fetched
                        this.$error({title: '没有权限'});
                        this.$router.replace({name: 'index'});
                    } else {
                        // 鉴权通过 如果本地缓存和后端数据不一致 需要刷新一下页面
                        const isEqual = _.isEqual(localUserInfo, requestUserInfo);
                        if (!isEqual) {
                            this.fetched = false;
                            this.$nextTick(() => {
                                this.fetched = true;
                            });
                        } else {
                            this.fetched = true;
                        }
                    }
                });

                return;
            }

            this.fetched = true;
        },
        isLogin() {
            if (this.isLogin) {
                // 测速网络
                if (!this.speedTest) {
                    this.speedTest = new testNetwork({
                        appId: 'Web_COLLECTION',
                        country: 'CN',
                        payload: {
                            userId: this.user.collectorId,
                            name: this.user.name,
                        }
                    });
                }

                try {
                    this.speedTest.start();
                } catch (error) {
                    this.speedTest.stop();
                }
            } else {
                this.speedTest && this.speedTest.stop();
            }
        },

        user(user) {
            // 添加水印
            if (this.watermark) {
                this.watermark?.destroy();
            }

            // 登出以后登录页面不显示水印
            if (!Object.keys(user).length) return;

            const {name, collectorId} = user;

            this.watermark = new YqgWatermark({
                content: `${name}-${collectorId}`
            });

            this.watermark?.create();
        }
    },

    mounted() {
        /*
         * @desc Vue.prototype.$modal 是在yqg-simple-modal内的beforeCreate生命周期内赋值的
         * Vue.use(Modal)会在Vue.prototype.$modal上增加新的方法, 所以要在这里安装依赖
         */
        Vue.use(Modal);
    },

    created() {
        Vue.prototype.$app = this;
        this.initHttpInterceptor();

        router.beforeEach((to, from, next) => {
            if (this.redirectToCockpit(to)) {
                return;
            }

            const {yqgPrivileges} = to.meta;
            if (yqgPrivileges && !this.isAuthorized(yqgPrivileges)) {
                this.$error({title: '没有权限'});

                if (from.name === 'login') {
                    this.$router.replace({name: 'index'});
                }

                return;
            }

            setTitle(to);
            next();
        });

        const {route} = router.resolve(location.pathname);
        setTitle(route);
    },

    methods: {
        ...mapActions(['fetch', 'syncUserInfo', 'logout']),

        isAuthorized(hasAny) {
            const {state: {user: {user: {roleGroupVOList = []}}}} = this.$store;
            const privileges = roleGroupVOList.flatMap(({privilegeTypes}) => privilegeTypes);

            return !hasAny || !hasAny.length || [].concat(hasAny).some(privilege => privileges.includes(privilege));
        },

        redirectToCockpit(route) {
            if (route.name === 'index') {
                const {route: {meta: {yqgPrivileges = []}}} = this.$router.resolve({name: Cockpit});

                if (this.isAuthorized(yqgPrivileges)) {
                    const {route: currentRoute} = router.resolve(location.pathname);
                    if (currentRoute.name !== Cockpit) {
                        this.$router.replace({name: Cockpit});
                    }

                    return true;
                }
            }

            return false;
        },

        getValidator({validator, ...formCtx}) {
            return (rule, value, callback) => {
                const msgKey = validator({rule, value, ...formCtx});
                if (msgKey) {
                    callback(msgKey);

                    return;
                }

                callback();
            };
        },

        opSuccess(operation = '操作') {
            this.$message.success(`${operation}成功！`);
        },

        opFail(operation = '操作') {
            this.$message.error(`${operation}失败! `);
        },

        initHttpInterceptor() {
            const {$error} = this;
            const openingCodes = {};
            http.interceptors.request.use(
                config => {
                    config.paramsSerializer = paramsSerializer;

                    const uuid = createUuid();
                    config.headers['request-uuid'] = uuid;

                    if (!config.hideLoading) {
                        this.reqCount += 1;

                        config.logger = new RequestTimeoutLogger({
                            url: config.url,
                            uuid
                        });
                    }

                    return config;
                },
                err => {
                    this.reqCount = Math.max(0, this.reqCount - 1);

                    return Promise.reject(err);
                }
            );

            // http response 拦截器
            http.interceptors.response.use(
                res => {
                    const {status, data: {status: {code, detail} = {}}, config, config: {hideLoading, responseType}} = res;
                    const isInWhiteList = whiteList.includes(config.url);
                    if (res.config.logger) {
                        res.config.logger.report();
                    }

                    if (!hideLoading) {
                        this.reqCount = Math.max(0, this.reqCount - 1);
                    }

                    if (responseType === 'blob') {
                        return res;
                    }

                    switch (code) {
                        case CODE_SUCCESS: // api 正常返回
                            return Promise.resolve(res);

                        case CODE_NOT_LOGIN:
                            this.logout();
                            if (this.$route.meta.yqgPrivileges) {
                                this.$router.replace({name: 'login'})
                                    .catch(err => err);
                            }

                            break;

                        case LOGIN_NEED_CAPTCHA:
                        case LOGIN_NEED_SMS_VERIFY:
                            break;

                        case SEND_TOO_MANY_MESSAGE:
                        case DATA_ACCESS_RESTRICTED:
                        case OPERATION_WARN:
                        case DIAL_CONTACT_BEFORE_VALID_SELF:
                        case WEIGHT_OVER_LIMIT:
                        case UNAVAILABLE_FOR_ENTERPRISE_WECHAT:
                        case MESSAGE_EXCEED_SAME_MOBILE_LIMIT:
                        case BUBBLE_SIGN_IN_FIRST:
                        case LONGTIME_NO_OPERATION_KICKOUT:
                            if (!hideLoading && !isInWhiteList) {
                                const content = detail || '未知错误';
                                $error({title: code, content});
                            }

                            break;

                        default: {
                            apiErrorReportToSentry(config, {
                                httpCode: status,
                                code,
                                detail
                            });
                            if (!hideLoading && !isInWhiteList && !openingCodes[code]) {
                                openingCodes[code] = true;
                                const content = detail || '未知错误';
                                $error({
                                    title: code,
                                    content,
                                    onCancel() {
                                        openingCodes[code] = false;
                                    },
                                    onOk() {
                                        openingCodes[code] = false;
                                    },
                                });
                            }
                        }
                    }

                    return Promise.reject(res);
                },
                error => {
                    const {response: {config: {hideLoading, url} = {}, status} = {}, isAxiosError} = error;
                    const isInWhiteList = whiteList.includes(url);
                    if (!hideLoading) {
                        this.reqCount = Math.max(0, this.reqCount - 1);
                    }

                    if (!isAxiosError && isInWhiteList) {
                        $error({title: status, content: error.message || '网络异常'});
                    }

                    return Promise.reject(error.response || error);
                }
            );
        }
    }

};

</script>

<style lang="scss" rel="stylesheet/scss">
.yqg-spin {
    &.ant-spin-nested-loading > div > .ant-spin {
        position: fixed;
        min-height: 100vh;
        z-index: 1100;

        .ant-spin-dot {
            top: 40%;
        }
    }

    .ant-spin-blur {
        overflow: unset;
    }
}
</style>
