/*
 * @Author: egafrandika
 * @Date: 2023-07-31 14:10:46
 * @Last Modified by: egafrandika
 * @Last Modified time: 2023-07-31 14:10:46
 */

const createVideoEmbed = videoUrl => {
    return `<iframe src="${videoUrl}" frameborder="0" allowfullscreen></iframe>`;
};

export default {
    name: 'video',

    icon: {
        type: 'text',
        value: 'YT'
    },

    insert(selectionText = '') {
        if (!selectionText) return createVideoEmbed('https://www.youtube.com/embed/***URL***');
    }
};
