<template>
    <div
        title="问题反馈"
        class="sug-container"
        @click="jumptoSuggestion"
    >
        <img
            v-if="imgIcon"
            src="./suggestion.png"
        >

        <a-icon
            v-else
            class="icon"
            type="bulb-o"
        />
    </div>
</template>

<script>
    export default {
        name: 'YqgSuggestionShortcuts',

        props: {
            systemId: {
                type: String,
                default: ''
            },
            imgIcon: {
                type: Boolean,
                default: false
            }
        },

        methods: {
            jumptoSuggestion() {
                if (!this.systemId) {
                    this.$message.warning('请携带生产环境的systemId跳转');

                    return;
                }

                window.open(`https://suggestion.fintopia.tech/feedback-list?systemId=${this.systemId}`);
            }
        }
    };
</script>

<style lang="scss" scoped>
.sug-container {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;

    &:hover {
        cursor: pointer;
    }

    & img {
        width: 30px;
        height: 30px;
    }

    .icon {
        font-size: 20px;
    }
}
</style>

