var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "alarm-repay-receipt-result" },
    [
      _vm._v("\n    订单:\n    "),
      _c("yqg-case-link", {
        attrs: {
          text: _vm.notification.data.orderNumber,
          "case-id": _vm.notification.data.caseId,
        },
      }),
      _vm._v("\n    的对公还款被拒绝，请重新提交。\n"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }