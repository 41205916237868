<!-- @author giligili -->
<!-- @email zhimingwang@yangqianguan.com -->
<!-- @date 2021-06-25 11:35:53.864 -->
<!-- @desc generated by yqg-cli@4.0.7 -->

<template>
    <div
        v-if="src"
        class="yqg-pipe-audio"
    >
        <audio
            ref="audio"
            controls
            controlsList="nodownload"
            :src="src"
            @play="$emit('played')"
            @contextmenu.prevent
        >
            暂不支持音频播放
        </audio>

        <a-tooltip>
            <template #title>
                <span>{{ rate }}倍速</span>
            </template>

            <input
                v-model="rate"
                class="rate"
                max="2"
                min="0.5"
                step="0.5"
                type="range"
                @change="setRate"
            >
        </a-tooltip>

        <a
            v-if="download"
            class="download-btn"
            target="_blank"
            download
            @click="downloadAudio"
        >
            <a-icon type="cloud-download" />
        </a>
    </div>
</template>

<script type="text/babel">

    import Resource from '@yqg/resource';

    import download from 'collection-admin-web/common/global/download';
    import {aesDecryptCallSecret} from 'collection-admin-web/common/util/encryption';

    export default {
        name: 'YqgPipeAudio',

        props: {
            file: {
                type: String,
                required: true
            },

            download: {
                type: Boolean,
                default: false
            },

            fileName: {
                type: String,
                default: ''
            }
        },

        data() {
            return {
                rate: 1
            };
        },

        computed: {
            src() {
                return this.getAudioUrl();
            }
        },

        methods: {
            setRate() {
                this.$refs.audio.playbackRate = this.rate;
            },

            getAudioUrl() {
                const {file} = this;
                if (!file) return file;

                return aesDecryptCallSecret(file);
            },

            downloadAudio() {
                const {src, fileName} = this;

                if (!src) return;

                const resource = Resource.create({url: src}).get;

                const defaultFileName = `record-${Date.now()}.mp3`;

                download(
                    {resource},
                    {fileName: fileName || defaultFileName, hideLoading: true}
                );

                this.$emit('downloaded');
            }
        }
    };

</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .yqg-pipe-audio {
        display: flex;
        align-items: center;
        min-width: 360px;

        audio {
            min-width: 270px;
        }

        .rate {
            min-width: 60px;
            margin-left: 10px;
            padding-left: 0;
            padding-right: 0;
        }

        .download-btn {
            margin-left: 10px;
            font-size: 20px;
        }
    }
</style>
