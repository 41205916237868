var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showSwitch
    ? _c(
        "div",
        { staticClass: "yqg-environment-switch" },
        [
          _c(
            "a-button",
            { attrs: { type: "primary" }, on: { click: _vm.switchEnv } },
            [_vm._v("\n        " + _vm._s(_vm.buttonText) + "\n    ")]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }