<!-- @Author: giligili -->
<!-- @Date: 2022-12-09 13:29:08.473 -->
<!-- @Last Modified by: yefenghan -->
<!-- @Last Modified time: 2024-08-14 01:45:06 -->

<template>
    <span class="yqg-layout-add-flow-btn">
        <!-- 普通催员 -->
        <a-button
            v-if="$app.privileges.CASE.DETAIL.FLOW.MUTATE"
            type="primary"
            size="small"
            icon="plus"
            @click="addCollectionFlow"
        >
            添加催记
        </a-button>

        <!-- 投诉岗 -->
        <a-button
            v-if="$app.privileges.COMPLAINT.FLOW.MUTATE"
            type="primary"
            size="small"
            icon="plus"
            @click="addComplaintCollectionFlow"
        >
            添加投诉催记
        </a-button>
    </span>
</template>

<script type="text/babel">

    import bus, {
        OnOpenAddComplaintFlowModal
    } from 'collection-admin-web/common/constant/event-bus';
    import {COMPLAINT_FLOW_SOURCE, FLOW_SOURCE} from 'collection-admin-web/common/util/event-log/constant/source/flow-source';

    export default {
        name: 'AddFlowBtn',

        inject: ['openCollectionFlowEditModal'],

        props: {
            contactInfo: {
                type: Object,
                required: true
            },
            flowSource: {
                type: String,
                default: FLOW_SOURCE.FEEDBACK_LIST
            },
            complaintFLowSource: {
                type: String,
                default: COMPLAINT_FLOW_SOURCE.FEEDBACK_LIST
            }
        },

        methods: {
            addCollectionFlow() {
                const {
                    caseId,
                    contactType,
                    contactName,
                    encryptedUserName,
                    maskedMobileNumber,
                    encryptedMobileNumber
                } = this.contactInfo;

                this.openCollectionFlowEditModal({
                    contactInfo: {
                        caseId,
                        name: contactName,
                        encryptedUserName,
                        contactType,
                        maskedMobileNumber,
                        encryptedMobileNumber
                    },
                    source: this.flowSource
                });
            },

            addComplaintCollectionFlow() {
                const {
                    feedbackId,
                    contactType,
                    contactName,
                    encryptedUserName,
                    maskedMobileNumber,
                    encryptedMobileNumber,
                } = this.contactInfo;

                bus.$emit(OnOpenAddComplaintFlowModal, {
                    name: contactName,
                    encryptedUserName,
                    type: contactType,
                    encryptedMobileNumber,
                    displayMobileNumber: maskedMobileNumber,
                    feedbackId,
                    source: this.complaintFLowSource
                });
            }
        }
    };

</script>
