/**
 * @author giligili
 * @date 2021-08-17
 * @email zhimingwang@yangqianguan.com
 * @desc RequestType
 */

export default {
    CHECK: 'CHECK',
    DIAL: 'DIAL'
};
