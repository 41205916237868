<!-- @author giligili -->
<!-- @email zhimingwang@yangqianguan.com -->
<!-- @date 2021-08-31 14:31:09.252 -->
<!-- @desc generated by yqg-cli@5.1.2 -->

<template>
    <div class="yqg-antv-upload">
        <component
            :is="is"
            :key="key"
            v-bind="props"
            @change="onChange"
        />
    </div>
</template>

<script type="text/babel">

    import UploadFile from './components/upload-file';
    import UploadImage from './components/upload-image';
    import UploadUrl from './components/upload-url';
    import * as BucketType from './constant/bucket-type';

    let key = 0;

    export default {
        name: 'YqgAntvUpload',

        components: {
            UploadUrl,
            UploadImage,
            UploadFile
        },

        inheritAttrs: false,

        model: {
            prop: 'value',
            event: 'change'
        },

        /** 
         * @desc 因为yqg-simple-form没有帮我们传attrs
         * 所以为了保证组件在yqg-simple-form内可以正常工作，子组件用到的属性，也要在父组件上声明一下
         * -_-..
         */
        props: {
            value: {
                type: [String, Array],
                default: undefined
            },

            ui: {
                type: String,
                default: 'url'
            },

            /* @desc 最大上传数量 */
            limit: {
                type: Number,
                default: 1
            },

            listType: {
                type: String,
                default: 'picture'
            },

            disabled: {
                type: Boolean,
                default: false
            },

            bucket: {
                type: String,
                default: BucketType.WEB // web or collection
            },

            maxByte: {
                type: Number,
                default: undefined
            }
        },

        data() {
            return {
                key: key++
            };
        },

        computed: {
            is() {
                const {ui} = this;

                return `upload-${ui}`;
            },

            props() {
                const {$attrs = {}, $props = {}} = this;

                return {
                    ...$attrs,
                    ...$props
                };
            }
        },

        methods: {
            onChange(val) {
                this.$emit('change', val);
            }
        }
    };

</script>
