/**
 * @author lany44
 * @date 2018/6/1-上午11:33
 * @file filter
 */

import {numberWithCommasFixed2, numberWithCommasFixed4, numberWithCommas} from '../../core/ToolFunction/StringUtil';
import {
    dateMonth,
    dateTime,
    dateHyphen,
    hourMinutes,
    month,
    monthDay,
    monthHyphen,
    customizeDate, // 自定义的时间格式
    durationFormat
} from './lib/date';
import MillisToMinSecond from './lib/millis-to-min-second';
import nullFilter from './lib/null-filter';
import numberToPercentFilter from './lib/number-to-percent-filter';
import percentFixed2Filter from './lib/percent-fixed2-filter';
import percentFixed4Filter from './lib/percent-fixed4-filter';
import phoneMaskFilter from './lib/phone-mask';
import secondsToMinute from './lib/seconds-filter';
import subStringFilter from './lib/sub-string-filter';
import unitByte from './lib/unit-byte-filter'; // TODO by panezhang filter 抽象到 @yqg/function

export default {

    install(Vue) {
        Vue.filter('yqgNumberWithCommasFixed2', numberWithCommasFixed2);
        Vue.filter('yqgNumberWithCommasFixed4', numberWithCommasFixed4);
        Vue.filter('yqgNumberWithCommas', numberWithCommas);
        Vue.filter('nullFilter', nullFilter);
        Vue.filter('yqgNumberToPercent', numberToPercentFilter());
        Vue.filter('yqgPhoneMask', phoneMaskFilter());
        Vue.filter('yqgPercentFixed2', percentFixed2Filter());
        Vue.filter('yqgPercentFixed4', percentFixed4Filter());
        Vue.filter('yqgSecondsToMinute', secondsToMinute());
        Vue.filter('yqgUnitByte', unitByte());
        Vue.filter('yqgSubString', subStringFilter());

        Vue.filter('yqgMonth', month);
        Vue.filter('yqgMonthHyphen', monthHyphen);
        Vue.filter('yqgDateHyphen', dateHyphen);
        Vue.filter('yqgDateMonth', dateMonth);
        Vue.filter('yqgDateTime', dateTime);
        Vue.filter('YqgHourMinutes', hourMinutes);
        Vue.filter('YqgMonthDay', monthDay);
        Vue.filter('YqgCustomizeDate', customizeDate);
        Vue.filter('durationFormat', durationFormat);
        Vue.filter('millisToMinSecond', MillisToMinSecond);
    }

};

