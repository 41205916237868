
/**
 * @author giligili
 * @date 2021-06-24
 * @email zhimingwang@yangqianguan.com
 * @desc 满意度结果枚举
 */

import Common from 'collection-admin-web/common/resource/common';
import Enum from 'collection-admin-web/common/util/enum';

export default Enum.query(Common.getSatisfactionMap, {
    queryOnce: true
});
