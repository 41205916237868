/**
 * @author giligili
 * @date 2021-07-20
 * @email zhimingwang@yangqianguan.com
 * @desc 短信类型 getAvailableMessage 接口配置
 */

export default {
    APP_URL: 'APP_URL',
    APP_URL2: 'APP_URL2',
    H5_REPAY: 'H5_REPAY', // 快捷还款
    COLLECTION: 'COLLECTION', // 催收
    BANK_ACCOUNT: 'BANK_ACCOUNT' // 对公还款
};

