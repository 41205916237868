/**
 * @Author: xymo
 * @Date: 2021-04-12
 * @Last Modified by: giligili
 * @Last Modified time: 2023-07-26 19:10:58
 */

import {Empty, AutoComplete, Pagination, Breadcrumb, Space, Alert} from 'ant-design-vue';

import Antd from '@yqg/vue/antd/component';
import CommonI18n from '@yqg/vue/antd/component/i18n';

import {imageDef, fileDef} from 'collection-admin-web/common/constant/fields';

import YqgAddMultiCallList from './yqg-add-multi-call-list';
import YqgAddOfficialWechatFriend from './yqg-add-official-wechat-friend';
import YqgAntvCheckbox from './yqg-antv-checkbox';
import YqgAntvDatePicker from './yqg-antv-date-picker';
import YqgAntvPopover from './yqg-antv-popover';
import YqgAntvRadio from './yqg-antv-radio';
import YqgAntvSelect from './yqg-antv-select';
import YqgAntvUpload from './yqg-antv-upload';
import YqgAntvUriTabs from './yqg-antv-uri-tabs';
import YqgCallQualityDetailLink from './yqg-call-quality-detail-link';
import YqgCallRecordLink from './yqg-call-record-link';
import YqgCanaryInput from './yqg-canary-input';
import YqgCaseLink from './yqg-case-link';
import YqgCheckWrap from './yqg-check-wrap';
import YqgCollectResultSelect from './yqg-collect-result-select';
import YqgColorPicker from './yqg-color-picker';
import YqgEnum from './yqg-enum';
import YqgFlowIdLink from './yqg-flow-id-link';
import YqgJsonArrayVisual from './yqg-json-array-visual';
import YqgJsonVisual from './yqg-json-visual';
import YqgLayout from './yqg-layout';
import YqgMarquee from './yqg-marquee';
import YqgModal from './yqg-modal';
import YqgMultiInput from './yqg-multi-input';
import YqgOrderNumbersCell from './yqg-order-numbers-cell';
import YqgPagination from './yqg-pagination';
import YqgPipeAudio from './yqg-pipe-audio';
import YqgQualityReviewLink from './yqg-quality-review-link';
import YqgResizeObserve from './yqg-resize-observe';
import YqgRouterLink from './yqg-router-link';
import YqgSdkIcon from './yqg-sdk-icon';
import YqgTableCell from './yqg-table-cell';
import YqgUploadImages from './yqg-upload-images';
import YqgWavesurfer from './yqg-wavesurfer';

export default {
    install(Vue) {
        [
            Empty,
            Antd,
            AutoComplete,
            Pagination,
            Breadcrumb,
            Space,
            Alert,
            CommonI18n
        ].forEach(Vue.use.bind(Vue));

        Vue.component('YqgEnum', YqgEnum);
        Vue.component('YqgModal', YqgModal);
        Vue.component('YqgLayout', YqgLayout);
        Vue.component('YqgTableCell', YqgTableCell);
        Vue.component('YqgPipeAudio', YqgPipeAudio);
        Vue.component('YqgAntvRadio', YqgAntvRadio);
        Vue.component('YqgAntvSelect', YqgAntvSelect);
        Vue.component('YqgMultiInput', YqgMultiInput);
        Vue.component('YqgJsonVisual', YqgJsonVisual);
        Vue.component('YqgWavesurfer', YqgWavesurfer);
        Vue.component('YqgColorPicker', YqgColorPicker);
        Vue.component('YqgAntvUriTabs', YqgAntvUriTabs);
        Vue.component('YqgAntvCheckbox', YqgAntvCheckbox);
        Vue.component('YqgCallRecordLink', YqgCallRecordLink);
        Vue.component('YqgJsonArrayVisual', YqgJsonArrayVisual);
        Vue.component('YqgOrderNumbersCell', YqgOrderNumbersCell);
        Vue.component('YqgCallQualityDetailLink', YqgCallQualityDetailLink);
        Vue.component('YqgFlowIdLink', YqgFlowIdLink);
        Vue.component('YqgSdkIcon', YqgSdkIcon);
        Vue.component('YqgAddMultiCallList', YqgAddMultiCallList);
        Vue.component('YqgCollectResultSelect', YqgCollectResultSelect);
        Vue.component('YqgAntvDatePicker', YqgAntvDatePicker);
        Vue.component('YqgAddOfficialWechatFriend', YqgAddOfficialWechatFriend);
        Vue.component('YqgUploadImages', YqgUploadImages);
        Vue.component('YqgRouterLink', YqgRouterLink);
        Vue.component('YqgCaseLink', YqgCaseLink);
        Vue.component('YqgQualityReviewLink', YqgQualityReviewLink);
        Vue.component('YqgAntvUpload', YqgAntvUpload);
        Vue.component('YqgAntvPopover', YqgAntvPopover);
        Vue.component('YqgCheckWrap', YqgCheckWrap);
        Vue.component('YqgResizeObserve', YqgResizeObserve);
        Vue.component('YqgMarquee', YqgMarquee);
        Vue.component('YqgPagination', YqgPagination);
        Vue.component('YqgCanaryInput', YqgCanaryInput);

        /*
         * @desc 重写一些组件的默认props
         */
        // yqg-simple-form -> field type markdown
        const {options: {props: markdownProps}} = Vue.component('field-md-editor');

        markdownProps.fileDef.default = () => fileDef;
        markdownProps.imageDef.default = () => imageDef;
    }
};
