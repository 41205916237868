<!-- @author weisun -->
<!-- @email weisun@fintopia.tech -->
<!-- @date 2022-10-20 17:18:26.799 -->
<!-- @desc generated by yqg-cli@5.2.0 -->

<template>
    <router-link
        v-if="linkText"
        :target="target"
        :to="to"
        v-text="linkText"
    />
    <p v-else>
        /
    </p>
</template>

<script>

import _ from 'underscore';

const LinkTarget = '_blank';

export default {
    name: 'StaticCompLink',

    props: {
        record: {
            type: Object,
            default: () => ({})
        },

        def: {
            type: Object,
            default: () => ({})
        }
    },

    data() {
        const {def, record} = this;

        return {
            target: def.linkOptions?.target ?? LinkTarget,
            linkText: def.linkOptions?.linkText ?? record[def.field],
            to: this.getToOptions()
        };
    },

    methods: {
        getToOptions() {
            const {linkOptions: {to}} = this.def;
            const res = {name: to.name};

            if (to.query) {
                res.query = _.pick(this.record, ...to.query);
            }

            return res;
        }
    }
};

</script>
