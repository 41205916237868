/**
 * @Author: giligili
 * @Date: 2023-03-15
 * @Last Modified by: giligili
 * @Last Modified time: 2023-07-12 17:14:21
 */
import EnumAll from 'src/common/constant/enum';
import { AreaTagEnum } from 'src/common/constant/enum/area';
import Area from 'src/common/resource/area';
import CaseDetail from 'src/common/resource/case/detail';
const AreaTag = AreaTagEnum.TYPE;
const caseAreaCustomizer = (areaType) => (caseId) => Area.getAreaTagsByCaseId({ params: { caseId } })
    .then(({ data: { body: { areaTags } } }) => {
    if (!areaTags)
        return !!areaTags;
    return areaTags.some(areaTag => areaTag === areaType);
})
    .catch(() => false);
// 是不是诉讼案件
export const isLawsuitCase = caseAreaCustomizer(AreaTag.LAW_COL_LITIGATE);
// 分期列表
export const getInstalmentList = (caseId, orderNumber) => CaseDetail.listInstalment({ params: { caseId, orderNumber } })
    .then(({ data: { body } }) => {
    if (!body)
        return [];
    if (body) {
        const { dataList } = body;
        return dataList;
    }
});
// 借款分期ID
const InstalmentStatusType = EnumAll.Case.InstalmentStatus.TYPE;
export const getProcessingLoanInstalmentIds = (caseId, orderNumber) => getInstalmentList(caseId, orderNumber)
    .then(list => list.filter(({ status }) => status === InstalmentStatusType.PROCEEDING))
    .then(list => list.map(({ loanInstalmentId }) => loanInstalmentId));
