/*
 * Author: xymo
 * Created Date: 2021-06-10
 * email: xymo@yangqianguan.com
 */

import {numberWithCommas} from 'collection-admin-web/common/util/string';

export default input => (
    (input === +input || !isNaN(input)) ? numberWithCommas((+input).toFixed(2)) : input
);
