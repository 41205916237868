/*
 * @Author: xiaodongyu
 * @Date 2019-05-29 16:53:02
 * @Last Modified by: xiaodongyu
 * @Last Modified time: 2021-01-28 15:30:51
 */

import {reportBuilder, setIgnoreError} from '@yqg/reporter';

import ApiRouter from '../constant/CommonApiRouter';

reportBuilder.setOptions({
    defaultAjaxUrl: ApiRouter.apiWeb.report
});

export function ignoreRouteError() {
    setIgnoreError(err => !err || err._isRouter); // eslint-disable-line no-underscore-dangle
}

export function ignorePrismError() {
    setIgnoreError(err => !err
        || (err.stack && err.stack.includes('Prism.util.isActive is not a function')));
}
