/*
 * @Author: giligili
 * @Date: 2022-10-31 11:57:15
 * @Last Modified by: giligili
 * @Last Modified time: 2022-10-31 11:57:15
 */

import _ from 'underscore';

export default {
    methods: {
        getVcTable() {
            return this.$refs.yst?.$refs?.mainTable?.$children[0]?.$refs?.vcTable;
        },

        onYstResizeChange: _.debounce(function onYstResizeChange() {
            const vcTable = this.getVcTable();

            if (!vcTable) return;

            vcTable.$refs.table.syncFixedTableRowHeight();
        }, 100)
    }
};
