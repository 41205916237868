<!-- @author giligili -->
<!-- @email zhimingwang@yangqianguan.com -->
<!-- @date 2021-05-21 13:21:59.563 -->
<!-- @desc generated by yqg-cli@4.0.7 -->

<template>
    <div class="yqg-json-array-visual">
        <template v-for="(val, idx) in values">
            <yqg-json-visual
                :key="val.componentId"
                :value="val"
                v-bind="jsonVisualProps"
                @delete="() => deleteJson(idx)"
                @change="(nextValue) => changeJson(idx, nextValue)"
            />
        </template>

        <a-button
            size="small"
            type="primary"
            @click="addJson"
        >
            添加
        </a-button>
    </div>
</template>

<script type="text/babel">
    import {isJsonString} from 'collection-admin-web/common/util/object';

    export default {
        name: 'YqgJsonArrayVisual',

        props: {
            value: {
                type: [Array, String],
                default: () => []
            },

            jsonVisualProps: {
                type: Object,
                default: () => ({})
            }
        },

        data() {
            return {
                values: this.formatValue(this.value)
            };
        },

        watch: {
            value(nextValue) {
                this.values = this.formatValue(nextValue);
            }
        },

        methods: {
            formatValue(value) {
                let ret = value;

                if (typeof value == 'string') {
                    if (isJsonString(value) && value.charAt(0) === '[') {
                        ret = JSON.parse(value);
                    } else {
                        ret = [{}];
                    }
                }

                return ret;
            },

            deleteJson(idx) {
                this.values.splice(idx, 1);
            },

            addJson() {
                this.values.push({});
            },

            changeJson(idx, value) {
                this.$set(this.values, idx, value);
            }
        }
    };
</script>
