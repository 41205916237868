<!-- @author yanglan -->
<!-- @email yanglan@yangqianguan.com -->
<!-- @date 2018-03-26 11:17:39.209 -->
<!-- @desc generated by yqg-cli@0.1.0-alpha.2 -->

<template>
    <div
        :class="{'show-loading': isRequesting}"
        class="yqg-nav-loading loading-wrap"
    >
        <div class="line" />
        <span class="rotating-icon" />
    </div>
</template>

<script type="text/babel">

export default {
    name: 'YqgNavLoading',

    props: {
        isRequesting: {
            type: Boolean,
            default: false
        }
    }
};

</script>
