/**
 * @author zhangpeng
 * @date 16/3/16-上午12:20
 * @file ToolFunction
 */

// node modules
import moment from 'moment';

/* ****************************************************************************************************************** */
/* ================================================= string utils start ============================================= */

export * from './StringUtil';

/* ================================================= string utils end =============================================== */
/* ****************************************************************************************************************** */

/* ****************************************************************************************************************** */
/* ================================================= ua utils start ========================================== */

export const isMobile = ua => /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(ua);

export const isAndroid = ua => /android/i.test(ua);

export const isIOS = ua => /iphone|ipad|ipod/i.test(ua);

export const isBubbleWebview = ua => /bubble\//i.test(ua);

export const isBubbleEcWebview = ua => /bubbling\//i.test(ua);

export const isWebview = ua => isBubbleWebview(ua) || isBubbleEcWebview(ua);

export const isWxWorkWebview = ua => /wxwork\//i.test(ua);

/* ================================================= ua utils end ============================================ */
/* ****************************************************************************************************************** */

/* ****************************************************************************************************************** */
/* ================================================= date time utils start ========================================== */
export const dateFormat = (date, format) => date && moment(new Date(date)).format(format || 'YYYY-MM-DD HH:mm:ss');
/* ================================================= date time utils end ============================================ */
/* ****************************************************************************************************************** */

/* ****************************************************************************************************************** */
/* ================================================= object utils start =========================================== */

export * from './ObjectUtil';

/* ================================================= object utils end ============================================= */
/* ****************************************************************************************************************** */

/**
 * 根据field的名字来决定该列用什么filter
 * TODO Will be removed
 * @param field
 * @returns {*}
 */
export const genFilterByFieldName = (field = '') => {
    let filter;
    if (field.toLowerCase().indexOf('time') === 0) {
        filter = 'dateTime';
    }

    return filter;
};

/**
 * 根据field的名字来决定该列可能用什么filter
 * TODO Will be removed
 * @param field
 * @returns {*}
 */

export const genPossibleFilterByFieldName = (field = '') => {
    let filter;
    const fieldLowerCase = field.toLowerCase();
    if (fieldLowerCase.includes('date')) {
        filter = 'date';
    } else if (fieldLowerCase.includes('time')) {
        filter = 'dateTime';
    } else if (fieldLowerCase.includes('amount')) {
        filter = 'numCommasFixed2';
    }

    return filter;
};
