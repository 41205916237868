var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "yqg-infinite-pagination" },
    [
      _c("a-button", {
        staticClass: "ant-pagination-prev",
        attrs: { disabled: !_vm.hasPrev, icon: "left" },
        on: { click: _vm.prev },
      }),
      _vm._v(" "),
      _c("span", { staticClass: "ant-pagination-item" }, [
        _vm._v(_vm._s(_vm.current)),
      ]),
      _vm._v(" "),
      _c("a-button", {
        staticClass: "ant-pagination-next",
        attrs: { disabled: !_vm.hasNext, icon: "right" },
        on: { click: _vm.next },
      }),
      _vm._v(" "),
      _vm.showSizeChanger
        ? _c("a-select", {
            staticClass: "ant-pagination-options",
            attrs: {
              "default-value": _vm.pagination.pageSize,
              options: _vm.genOptions,
            },
            on: { change: _vm.changePageSize },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }