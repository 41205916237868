/*
 * @Author: ruiwang
 * @Date: 2019-11-18 17:12:55
 * @Last Modified by: ruiwang
 * @Last Modified time: 2024-07-03 12:12:29
 */

import DefType from '../../../constant/def-type';
import FieldButtonGroup from '../component/field-button-group';
import FieldFile from '../component/field-file';
import FieldGroupForm from '../component/field-group-form';
import FieldInput from '../component/field-input';
import FieldSelect from '../component/field-select';
import FieldTextarea from '../component/field-textarea';
import FieldTree from '../component/field-tree';
import FieldVirtualSelect from '../component/field-virtual-select';

const getContainer = (trigger, dialogContext) => (dialogContext ? trigger.parentNode : document.body);

export const defaultPropsMap = {
    [DefType.select]: {
        placeholder: 'common.pleaseSelect',
        allowClear: true,
        showSearch: true,
        optionFilterProp: 'pinyin',
        getPopupContainer: getContainer
    },
    [DefType.virtualSelect]: {
        placeholder: 'common.pleaseSelect',
        allowClear: true,
        showSearch: true,
        optionFilterProp: 'pinyin',
        getPopupContainer: getContainer
    },
    [DefType.dateTime]: {
        showTime: true,
        getCalendarContainer: getContainer
    },
    [DefType.date]: {
        getCalendarContainer: getContainer
    },
    [DefType.time]: {
        getPopupContainer: getContainer
    },
    [DefType.tree]: {
        placeholder: 'common.pleaseSelect',
        allowClear: true,
        showSearch: true,
        treeDefaultExpandAll: true,
        dropdownStyle: {maxHeight: '400px', overflow: 'auto'},
        treeNodeFilterProp: 'title'
    },
    [DefType.color]: {
        type: 'color'
    }
};

export default {
    [DefType.text]: FieldInput,
    [DefType.textarea]: FieldTextarea,
    [DefType.number]: 'a-input-number',
    [DefType.date]: 'a-date-picker',
    [DefType.dateTime]: 'a-date-picker',
    [DefType.dateRange]: 'a-range-picker',
    [DefType.time]: 'a-time-picker',
    [DefType.month]: 'a-month-picker',
    [DefType.switch]: 'a-switch',
    [DefType.select]: FieldSelect,
    [DefType.virtualSelect]: FieldVirtualSelect,
    [DefType.tree]: FieldTree,
    [DefType.checkbox]: 'field-checkbox',
    [DefType.radio]: 'field-radio',
    [DefType.file]: FieldFile,
    [DefType.code]: () => import('../component/field-code'),
    [DefType.sql]: () => import('../component/field-sql'),
    [DefType.buttonGroup]: FieldButtonGroup,
    [DefType.markdown]: 'field-md-editor',
    [DefType.rich]: 'field-rich',
    [DefType.autoComplete]: 'field-auto-complete',
    [DefType.group]: FieldGroupForm
};
