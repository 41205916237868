var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$app.permissions.MEDIATE_SCHEME_SAVE ||
    _vm.$app.permissions.MEDIATE_SCHEME_QUERY
    ? _c("div", { staticClass: "mediate-plan" }, [
        _c(
          "p",
          { staticClass: "yqg-island-title" },
          [
            _vm._v("\n        协商方案\n\n        "),
            _vm.$app.permissions.MEDIATE_SCHEME_SAVE
              ? _c("a-button", {
                  attrs: { size: "small", type: "primary" },
                  domProps: { textContent: _vm._s("制定协商方案") },
                  on: { click: _vm.openMediatePlanModal },
                })
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _vm.records && _vm.records.length
          ? _c(
              "div",
              { staticClass: "yqg-island" },
              [
                _c("div", { staticClass: "yqg-panel-title" }, [
                  _vm._v("\n            方案记录\n        "),
                ]),
                _vm._v(" "),
                _c(
                  "yqg-resize-observe",
                  { on: { change: _vm.onYstResizeChange } },
                  [
                    _c("yqg-simple-table", {
                      ref: "yst",
                      attrs: {
                        records: _vm.records,
                        options: _vm.$options.TableOptions,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "imageUrls",
                            fn: function (ref) {
                              var value = ref.value
                              return [
                                _c(
                                  "div",
                                  { staticClass: "imageUrls" },
                                  [
                                    value
                                      ? _c("yqg-antv-upload", {
                                          attrs: {
                                            value: value,
                                            limit: Infinity,
                                            disabled: true,
                                            ui: "image",
                                            "list-type": "picture-card",
                                          },
                                        })
                                      : _c("span", [_vm._v("/")]),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                          {
                            key: "op",
                            fn: function (ref) {
                              var record = ref.record
                              return [
                                _c("a-button", {
                                  attrs: { size: "small", type: "primary" },
                                  domProps: { textContent: _vm._s("查看") },
                                  on: {
                                    click: function ($event) {
                                      return _vm.viewMediatePlanModal(record)
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _vm.$app.permissions.CASE_DETAIL_LIST_COUPON &&
                                record.isReduce === _vm.$app.BooleanType.TRUE
                                  ? _c("a-button", {
                                      attrs: { size: "small", type: "primary" },
                                      domProps: {
                                        textContent: _vm._s("审批进度"),
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.viewReduceModal(record)
                                        },
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.$app.permissions.MEDIATE_SCHEME_CANCEL &&
                                record.status !== "INVALID" &&
                                record.status !== "FINISH"
                                  ? _c(
                                      "a-popconfirm",
                                      {
                                        attrs: {
                                          title: "确认作废当前方案嘛？",
                                        },
                                        on: {
                                          confirm: _vm.cancelMediationScheme,
                                        },
                                      },
                                      [
                                        _c("a-button", {
                                          attrs: {
                                            size: "small",
                                            type: "danger",
                                          },
                                          domProps: {
                                            textContent: _vm._s("作废"),
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        868381637
                      ),
                    }),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }