<!-- @Author: xymo -->
<!-- @Date: 2023-07-18 -->
<!-- @Last Modified by: xymo -->
<!-- @Last Modified time: 2023-07-19 14:27:44 -->

<template>
    <div class="application-content">
        <yqg-simple-form
            :options="$options.QueryOptions"
            confirm-label="搜索"
            :values="defaultCond"
            @reset="onReset"
            @confirm="onConfirm"
        >
            <template #sort>
                <sort-button-group
                    ref="sortButtonGroup"
                    @change="handleSortChange"
                />
            </template>
        </yqg-simple-form>

        <div class="content">
            <a-spin :spinning="isLoading">
                <qa-display
                    v-if="qaList.length"
                    :type="type"
                    :keywords="keywords"
                    :qa-list="qaList"
                    @collect="fetchQa(pagination)"
                />

                <template v-else>
                    <a-empty
                        v-if="!isLoading"
                        class="mt40"
                    />
                    <div
                        v-else
                        style="height: 100px;"
                    />
                </template>
            </a-spin>
        </div>

        <yqg-pagination
            v-if="pagination.total"
            class="mt10"
            :pagination="pagination"
            @change="fetchQa"
        />
    </div>
</template>

<script type="text/babel">

    import event from 'collection-admin-web/common/mixin/event';
    import Resource from 'collection-admin-web/common/resource/knowledge';

    import QueryOptions from '../constant/query-options';
    import qaDisplay from './qa-display';
    import sortButtonGroup from './sort-button-group';

    export default {
        name: 'ApplicationContent',

        QueryOptions,

        components: {
            qaDisplay,
            sortButtonGroup
        },

        mixins: [event],

        props: {
            type: {
                type: String,
                default: 'ALL'
            }
        },

        data() {
            return {
                isLoading: false,
                defaultCond: {
                    knowledgeSearchType: this.type
                },
                qaList: [],
                pagination: {
                    pageNo: 1,
                    pageSize: 10,
                    total: 0
                },
                keywords: '',
                cond: null,
                sortCond: {
                    questionSortType: 'DEFAULT',
                    desc: 'TRUE'
                }
            };
        },

        methods: {
            onReset() {
                this.defaultCond = {};

                this.pagination = {
                    pageNo: 1,
                    pageSize: 10,
                    total: 0
                };

                this.sortCond = {
                    questionSortType: 'DEFAULT',
                    desc: 'TRUE'
                };

                this.cond = null;
                this.keywords = '';

                this.$refs.sortButtonGroup.reset();
            },

            onConfirm({values}) {
                this.keywords = values?.keyword || '';
                this.cond = {...values, ...this.sortCond};
                this.pagination.pageNo = 1;
                this.fetchQa(this.pagination);
            },

            fetchQa({pageSize = 0, pageNo = 0}) {
                this.pagination.pageSize = pageSize;
                this.pagination.pageNo = pageNo;

                this.uploadEvent({
                    key: 'KNOWLEDGE',
                    elementContent: `query-${this.type}`
                });

                this.isLoading = true;
                Resource.listByCondition({
                    ...this.cond, knowledgeSearchType: this.type, ...this.pagination
                }).then(({data: {body: {list = [], total = 0}}}) => {
                    this.pagination.total = total;

                    if (this.type === 'COLLECTED') {
                        this.qaList = list.reduce((acc, qa) => {
                            const res = this.qaStatus(qa, true);
                            if (res) acc.push(res);

                            return acc;
                        }, []);
                    } else {
                        this.qaList = list.reduce((acc, qa) => {
                            const res = this.qaStatus(qa);
                            if (res) acc.push(res);

                            return acc;
                        }, []);
                    }
                }).finally(() => {
                    this.isLoading = false;
                });
            },

            qaStatus(qa) {
                const expired = time => Date.now() - new Date(time).getTime() > 0;
                if (!qa.enable) {
                    return false;
                }

                if ((qa.enableStartTime || qa.enableEndTime)) {
                    if (!expired(qa.enableStartTime)) return ({disabled: 1, ...qa});
                    if (expired(qa.enableEndTime)) return ({disabled: 2, ...qa});
                }

                return qa;
            },

            handleSortChange({field, status}) {
                const sortType = status === 'off' ? {
                    questionSortType: '',
                    desc: ''
                } : {
                    questionSortType: field,
                    desc: status === 'descend' ? 'TRUE' : 'FALSE'
                };
                if (!sortType['questionSortType']) sortType['questionSortType'] = 'DEFAULT';

                this.sortCond = sortType;

                this.cond = {
                    ...this.cond,
                    ...this.sortCond
                };
                this.fetchQa(this.pagination);
            }
        }
    };
</script>
