/**
 * @Author: giligili
 * @Date: 2023-03-10
 * @Last Modified by: giligili
 * @Last Modified time: 2023-03-28 23:51:42
 */

import {BigNumber} from 'bignumber.js';
import moment from 'moment';

import {pickValue} from '@yqg/vue/antd/util/object';

import EnumAll from 'collection-admin-web/common/constant/enum';

import {merge, required} from 'src/common/constant/fields';
import {validatorAmount} from 'src/common/constant/validator';

import * as Fields from './fields';

const BooleanType = EnumAll.Basic.Boolean.TYPE;

const calcAmountDiff = (remainAmount, instalments) => {
    const amount = (instalments || [])
        .reduce((accu, {amount}) => accu.add(amount || 0), new BigNumber(0));

    return (new BigNumber(remainAmount)).sub(amount).toNumber();
};

export default {
    layout: 'horizontal',
    selfUpdate: true,
    fieldDefs: [
        Fields.orderId,
        merge(required(Fields.isInstalment), {
            itemProps: {selfUpdate: false},
            onChange: formCtx => formCtx.ctx.onIsInstalmentChange(formCtx)
        }),
        merge(required(Fields.terms), {
            props: {placeholder: '请输入2和10之间的整数'},
            onChange: ({ctx, record, value}) => ctx.onTermsChange(value, record),
            hide: ({record}) => pickValue(record, Fields.isInstalment.field) === BooleanType.FALSE,
            rules: [
                {validator: ({value}) => (value < 2 || value > 10 || !Number.isInteger(value)) ? '期数应该是2和10之间的整数' : ''}
            ]
        }),
        merge(required(Fields.completedTime), {
            itemProps: {selfUpdate: false},
            props: {disabledDate: current => current && current <= moment().startOf('day')},
            onChange: formCtx => formCtx.ctx.onCompletedTimeChange(formCtx),
            hide: ({record}) => pickValue(record, Fields.isInstalment.field) === BooleanType.FALSE,
            label: '最后一期应还日期'
        }),
        merge(required(Fields.completedTime), {
            itemProps: {selfUpdate: false},
            props: {disabledDate: current => current && current <= moment().startOf('day')},
            onChange: formCtx => formCtx.ctx.onCompletedTimeChange(formCtx),
            hide: ({record}) => pickValue(record, Fields.isInstalment.field) === BooleanType.TRUE
        }),
        merge(required(Fields.isReduce), {
            itemProps: {
                class: 'yqg-extra-danger',
                extra: ({ctx}) => ctx.reduceTips,
                selfUpdate: false
            },
            disabled: ({ctx}) => !ctx.canReduce,
            hide: ({ctx}) => !ctx.$app.permissions.CASE_DETAIL_ALL_SETTLE,
            onChange: formCtx => formCtx.ctx.onIsReduceChange(formCtx)
        }),
        merge(required(Fields.reduceType), {
            itemProps: {selfUpdate: false},
            enumType: ({ctx}) => ctx.isEdit ? ctx.CaseReduceTypeMap : Fields.reduceType.enumType,
            hide: ({record}) => pickValue(record, Fields.isReduce.field) === BooleanType.FALSE,
            onChange: ({ctx, value}) => ctx.onReduceTypeChange(value)
        }),
        merge(required(Fields.reduceAmount), {
            hide: ({record}) => pickValue(record, Fields.isReduce.field) === BooleanType.FALSE
                || !pickValue(record, Fields.reduceType.field),
            rules: [{validator: ({ctx, value}) => validatorAmount({
                maxAmount: Math.min(ctx.maxReduceAmount || 0, ctx.remainAmount), label: '减免金额'
            })(null, value)}],
            onChange: ({ctx, value, record}) => ctx.onReduceAmountChange(value, record)
        }),
        merge(Fields.imageUrls, {
            itemProps: {
                selfUpdate: false,
                class: 'yqg-extra-danger',
                extra: '需提供相关证明材料，否则可能无法通过审批'
            },
            hide: ({record}) => pickValue(record, Fields.isReduce.field) === BooleanType.FALSE
        }),
        merge(required(Fields.reduceExpiredTime), {
            itemProps: {selfUpdate: false},
            props: ({record}) => ({disabledDate: current => current && current < pickValue(record, Fields.completedTime.field)}),
            hide: ({record}) => pickValue(record, Fields.isReduce.field) === BooleanType.FALSE,
            rules: [{validator: ({record, value}) => {
                const label = pickValue(record, Fields.isInstalment.field) === BooleanType.TRUER
                    ? '最后一期应还日期'
                    : '一次性还款日期';

                return value < pickValue(record, Fields.completedTime.field)
                    ? `减免失效日期不应该小于${label}`
                    : '';
            }}]
        }),
        required(Fields.comments),
        merge(Fields.overflowAmount, {
            hide: ({ctx}) => ctx.isEdit
                ? !ctx.isLawsuit
                : (ctx.overflowAmount ? false : !ctx.isLawsuit),
            onChange: formCtx => formCtx.ctx.onOverflowAmountChange(formCtx),
            rules: [
                {validator: ({value}) => value < 0 ? '溢出金额应该不小于0' : ''},
                {validator: ({value}) => value && /\.\d{3,}/.test(value) ? '溢出金额最多2位小数' : ''}
            ]
        }),
        merge(Fields.remainAmount, {
            label: '剩余待还(元)',
            component: 'def-value'
        }),
        merge(Fields.mediationInstalments, {
            itemProps: {
                selfUpdate: false,
                class: 'yqg-extra-danger',
                extra: ({ctx}) => ctx.isEdit ? '若日期/金额不能均分, 多余部分将分配到最后一期上' : ''
            },
            props: {ref: 'mediationInstalments'},
            hideIdx: true,
            hasAdd: false,
            hasDelete: false,
            hide: ({ctx}) => !Number.isInteger(ctx.termsByCalc) || ctx.termsByCalc < 1 || ctx.termsByCalc > 10 || !ctx.completedTime,
            rules: [{validator: ({value, record}) => {
                const {remainAmount} = record;
                const amountDiff = calcAmountDiff(remainAmount, value);

                if (amountDiff === 0) return '';

                return `分期总应还金额不等于剩余待还, 剩余${amountDiff}元`;
            }}],
            tableOptions: {
                sync: true,
                rowKey: 'term',
                colDefs: [
                    merge(Fields.term, {component: 'def-value'}),

                    // 详情
                    merge(Fields.billingDate, {
                        hide: ({ctx}) => ctx.isEdit,
                    }),
                    merge(Fields.amount, {
                        hide: ({ctx}) => ctx.isEdit,
                    }),

                    // 不分期
                    merge(Fields.billingDate, {
                        colHide: ({ctx: {ctx}}) => !!ctx, // 反显的时候最外层的ctx是def-value的ctx
                        hide: ({ctx}) => ctx.termsByCalc !== 1,
                        component: 'def-value'
                    }),
                    merge(Fields.amount, {
                        colHide: ({ctx: {ctx}}) => !!ctx, // 反显的时候最外层的ctx是def-value的ctx
                        hide: ({ctx}) => ctx.termsByCalc !== 1,
                        component: 'def-value',
                        rules: [{validator: formCtx => formCtx.ctx.validatorAmount(formCtx)}]
                    }),

                    // 分期
                    merge(Fields.billingDate, {
                        colHide: ({ctx: {ctx}}) => !!ctx, // 反显的时候最外层的ctx是def-value的ctx
                        hide: ({ctx}) => ctx.termsByCalc === 1,
                        disabled: ({ctx, rowValues}) => rowValues.term === ctx.termsByCalc,
                        props: {disabledDate: current => current && current <= moment().startOf('day')},
                        rules: [{validator: formCtx => formCtx.ctx.validatorBillingDate(formCtx)}],
                        onChange: formCtx => formCtx.ctx.onMediateInstalmentsChange(formCtx)
                    }),
                    merge(Fields.amount, {
                        colHide: ({ctx: {ctx}}) => !!ctx, // 反显的时候最外层的ctx是def-value的ctx
                        hide: ({ctx}) => ctx.termsByCalc === 1,
                        rules: [{validator: formCtx => formCtx.ctx.validatorAmount(formCtx)}],
                        onChange: formCtx => formCtx.ctx.onMediateInstalmentsChange(formCtx)
                    })
                ]
            }
        })
    ]
};
