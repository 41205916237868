/*
 * @Author: huayizhang 
 * @Date: 2021-07-30 15:21:23 
 * @Last Modified by: huayizhang
 * @Last Modified time: 2021-07-30 15:30:10
 */

import UserFeedbackTypesMap from './enum/user-feedback-types-map';

export default {
    layout: 'vertical',
    fieldDefs: [
        {label: '意见类别', field: 'userFeedbackType', enumType: UserFeedbackTypesMap, required: true},
        {
            label: '请补充详细问题和意见', field: 'userFeedback', type: 'textarea',
            props: {autoSize: {minRows: 5}}, required: true
        },
        {label: '相关问题截图或照片', field: 'relatedPic'}
    ]
};
