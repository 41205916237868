var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "yqg-color-select" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "b-click-outside",
            rawName: "v-b-click-outside",
            value: function () {
              return _vm.closePopper()
            },
            expression: "() => closePopper()",
          },
        ],
        staticClass: "popper-group",
      },
      [
        _c("div", { staticClass: "picker-button-wrapper" }, [
          _c(
            "button",
            {
              class: ["picker-button", { empty: !_vm.model }],
              style: _vm.genStyle(_vm.model),
              attrs: { disabled: _vm.disabled },
              on: { click: _vm.openPopper },
            },
            [_c("i", { staticClass: "b-icon-bottom icon" })]
          ),
        ]),
        _vm._v(" "),
        _c("b-popper", { attrs: { visible: _vm.visibility } }, [
          _c(
            "div",
            { staticClass: "action-card" },
            [
              _c(
                "button",
                { staticClass: "reset-button", on: { click: _vm.onReset } },
                [_c("i", { staticClass: "b-icon-cross icon" })]
              ),
              _vm._v(" "),
              _vm._l(_vm.colors, function (color) {
                return _c("button", {
                  key: color,
                  class: ["color-button", { selected: color === _vm.model }],
                  style: _vm.genStyle(color),
                  on: {
                    click: function ($event) {
                      return _vm.onChoose(color)
                    },
                  },
                })
              }),
            ],
            2
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }