/**
 * @author giligili
 * @date 2021-08-02
 * @email zhimingwang@yangqianguan.com
 * @desc enums
 */

import Enum from 'collection-admin-web/common/util/enum';

export const AutoDialStatus = Enum.from({
    FINISHED: '预测外呼结束',
    PROCEEDING: '预测外呼进行中'
});
