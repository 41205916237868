var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "yqg-antv-select" },
    [
      _c(
        "a-select",
        {
          attrs: {
            placeholder: "请选择",
            value: _vm.value,
            mode: _vm.mode,
            size: _vm.size,
            "allow-clear": _vm.allowClear,
          },
          on: { change: _vm.onChange },
        },
        _vm._l(_vm.options, function (option) {
          return _c("a-select-option", { key: option.value }, [
            _vm._v("\n            " + _vm._s(option.label) + "\n        "),
          ])
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }