var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$app.privileges.CASE.DETAIL.LIST.COUPON
    ? _c("div", { staticClass: "advance-settle" }, [
        _c(
          "p",
          { staticClass: "yqg-island-title" },
          [
            _vm._v("\n        结清减免\n\n        "),
            !_vm.caseFinished
              ? _c("a-button", {
                  staticClass: "yqg-btn-warning",
                  attrs: { size: "small" },
                  domProps: { textContent: _vm._s("结清减免试算") },
                  on: {
                    click: function ($event) {
                      return _vm.onSettlementReduceTrial()
                    },
                  },
                })
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        !_vm.caseFinished && _vm.mainVisibility
          ? _c("div", { staticClass: "yqg-island" }, [
              _c("div", { staticClass: "mb10" }, [
                _c(
                  "div",
                  { staticClass: "mb10" },
                  [
                    _vm.$app.privileges.CASE.DETAIL.SETTLE.IN.ADVANCE &&
                    _vm.sendCouponVisible
                      ? _c("a-button", {
                          attrs: { size: "small", type: "primary" },
                          domProps: { textContent: _vm._s("发券") },
                          on: {
                            click: function ($event) {
                              return _vm.openSendCouponModal()
                            },
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.$app.privileges.CASE.DETAIL.ALL.SETTLE &&
                    _vm.applyRepayReduceButtonVisibility
                      ? _c("a-button", {
                          attrs: { size: "small", type: "primary" },
                          domProps: { textContent: _vm._s("申请减免") },
                          on: {
                            click: function ($event) {
                              return _vm.openApplyRepayReduceModal()
                            },
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.tips
                  ? _c("p", [
                      _vm._v("\n                不能减免的原因: "),
                      _c("span", { staticClass: "yqg-text-danger" }, [
                        _vm._v(_vm._s(_vm.tips)),
                      ]),
                    ])
                  : _vm._e(),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.records.length
          ? _c(
              "div",
              [
                _c("div", { staticClass: "yqg-panel-title" }, [
                  _vm._v("\n            减免记录\n        "),
                ]),
                _vm._v(" "),
                _c("yqg-simple-table", {
                  attrs: {
                    records: _vm.records,
                    options: _vm.$options.TableOptions,
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }