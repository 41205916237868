/*
 * Author: giligili
 * Created Date: 2021-05-20
 * email: zhimingwang@yangqianguan.com
 * desc: 通用配置相关的枚举
 */

import GenrealConfigMap from 'collection-admin-web/common/constant/maps/general-config';
import Enum from 'collection-admin-web/common/util/enum';

export const valueType = Enum.from(GenrealConfigMap);
