/*
 * @Author: xiaodongyu
 * @Date 2020-02-14 16:13:42
 * @Last Modified by: xiaodongyu
 * @Last Modified time: 2022-12-02 00:27:30
 */

import Vue from 'vue';

Vue.config.errorHandler = (err, vm, info) => {
    if (typeof YqgReporter !== 'undefined') {
        // set logData.crashed when it's crashing error
        const logData = ['@render', 'render', 'renderError', 'data()'].includes(info) ? {
            crashed: info
        } : '';
        YqgReporter.fatalException(logData, err);
    }

    console.error(err); // eslint-disable-line
};
