/*
 * @Author: yefenghan 
 * @Date: 2024-07-12 11:27:36 
 * @Last Modified by: yefenghan
 * @Last Modified time: 2024-07-15 15:47:19
 */

/**
 * 将毫秒转为时分秒
 * @param {number} milliseconds 毫秒数
 * @param {string} join 连接符号
 */
export function transferMilliseconds(milliseconds = 0, join = ':') {
    const seconds = Math.floor(milliseconds / 1000);
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    return [hours, minutes, remainingSeconds]
        .map(unit => unit.toString().padStart(2, '0'))
        .join(join);
}
