var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "yqg-nav-loading loading-wrap",
      class: { "show-loading": _vm.isRequesting },
    },
    [
      _c("div", { staticClass: "line" }),
      _vm._v(" "),
      _c("span", { staticClass: "rotating-icon" }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }