/**
 * @Author: xymo
 * @Date: 2024-02-22
 * @Last Modified by: xymo
 * @Last Modified time: 2024-02-22 16:16:38
 */

import EnterpriseWeChat from 'collection-admin-web/common/resource/permission/enterprise-we-chat';
import Enum from 'collection-admin-web/common/util/enum';

export default Enum.query(EnterpriseWeChat.listSymbolByCollector, {
    valueField: 'name',
    textField: 'desc',
    queryOnce: true
});
