/**
 * @author giligili
 * @date 2021-07-26
 * @email zhimingwang@yangqianguan.com
 * @desc 还款类型
 */

export default {
    AT_WILL: '随借随还',
    BY_TERM: '按期还款'
};
