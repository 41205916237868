<!-- @Author: xiaodongyu -->
<!-- @Date: 2019-12-12 18:30:12 -->
<!-- @Last Modified by: ruiwang -->
<!-- @Last Modified time: 2023-07-13 15:43:43 -->

<script type="text/babel">
import _ from 'underscore';
import draggable from 'vuedraggable';

import {evalProp} from '../../../util/object';

export default {
    name: 'FieldChildForm',

    props: {
        def: {
            type: Object,
            required: true
        },

        value: {
            type: Array,
            default: undefined
        },

        ctx: {
            type: Object,
            default: () => ({})
        }
    },

    data() {
        const {def, ctx, value} = this;
        let curValues;

        if (value) {
            curValues = value.map((item, index) => ({
                ...item,
                $timestamp: index
            }));
        }

        if (!value) {
            curValues = evalProp(def.minLen, {ctx}) === 0 ? [] : [{}];
        }

        return {
            curValues
        };
    },

    computed: {
        canAdd() {
            const {def: {hasAdd = true, maxLen = Infinity}, ctx, curValues = []} = this;

            return evalProp(hasAdd, {ctx}) && curValues.length < evalProp(maxLen, {ctx});
        },

        canCopy() {
            const {def: {hasCopy}, ctx, canAdd} = this;

            return canAdd && evalProp(hasCopy, {ctx});
        },

        canDelete() {
            const {def: {hasDelete = true, minLen = 0}, ctx, curValues = []} = this;

            return evalProp(hasDelete, {ctx}) && curValues.length > evalProp(minLen, {ctx});
        }
    },
    watch: {
        value(value) {
            if (this.def.formOptions.sync && value !== this.curValues) {
                this.curValues = [...(value || [])];
            }
        }
    },

    methods: {
        singleHasDelete(value, idx, curValues) {
            const {def: {formOptions: {hasDelete = true}}} = this;

            return evalProp(hasDelete, {value, extraData: {idx, curValues}});
        },

        async asyncValidator() {
            const {$refs, getChildForms} = this;
            const childForms = getChildForms($refs);
            const results = await Promise.all(
                childForms.map(
                    ({form}) => new Promise(
                        resolve => form.validateFields(error => resolve(!error))
                    )
                )
            );

            return results.every(valid => valid);
        },

        childKey(values = {}, idx) {
            const {def: {childKey = 'id'}, ctx} = this;

            return `${evalProp(({record}) => (record[childKey] || record.$timestamp), {values, record: values, ctx}) || ''}-${idx}`;
        },

        onAdd() {
            const {ctx, def: {getInitialValue, extendAdd}} = this;
            const initialValue = evalProp(getInitialValue, {ctx}) || {};
            initialValue.$timestamp = new Date().getTime();

            if (extendAdd) {
                extendAdd(this.curValues, {initialValue, ctx});
            } else {
                this.curValues.push(initialValue);
            }

            this.$nextTick(() => {
                this.onChange();
            });
        },

        onCopy(idx) {
            const record = _.clone(this.curValues[idx]);
            record.$timestamp = new Date().getTime();
            this.curValues.splice(idx + 1, 0, record);
            this.$nextTick(() => {
                this.onChange();
            });
        },

        onChange(idx, {record} = {}) {
            if (idx >= 0) {
                this.curValues.splice(idx, 1, record);
            }

            if (this.def.formOptions.sync) {
                this.$emit('change', this.curValues);
            }
        },

        onDelete(idx, len = 1) {
            const {ctx, def: {extendDelete}} = this;

            this.curValues.splice(idx, len);
            if (extendDelete) {
                extendDelete(this.curValues, {idx, ctx});
            }

            this.$nextTick(() => {
                this.onChange();
            });
        },

        getChildForms(refs) {
            return Object.keys(refs).reduce((acc, cur) => {
                if (/childForm-(?=.*[\d]$)/.test(cur) && refs[cur]) {
                    acc.push(refs[cur]);
                }

                return acc;
            }, []);
        },

        getFormValues() {
            const {def} = this;
            const childForms = this.getChildForms(this.$refs);

            if (!childForms.length) return {def, values: [], record: []};

            const {values, record} = childForms
                .map(ref => ref.getFormValues())
                .reduce(
                    ({values, record}, curr) => ({
                        values: values.concat(curr.values),
                        record: record.concat(curr.record)
                    }),
                    {values: [], record: []}
                );

            return {def, values, record};
        },

        getItemTitle(itemIndex) {
            const {def: {itemTitle}, ctx, $t} = this;
            if (!itemTitle) return null;

            if (_.isFunction(itemTitle)) {
                return evalProp(itemTitle, {ctx, itemIndex});
            }

            return `${$t(itemTitle)} ${itemIndex + 1}`;
        },

        async onConfirm() {
            const {def, def: {formOptions: {sync}}, $refs, getChildForms} = this;
            const childForms = getChildForms($refs);

            if (!childForms.length) return {err: null, def, values: [], record: []};

            const results = await Promise.all(childForms.map(ref => ref.onConfirm()));
            const err = results.reduce((acc, cur) => acc || cur.err, null);
            const valueArr = results.map(({values}) => values);
            const records = results.map(({record}) => record);
            if (!sync) this.$emit('change', records); // 给没配sync的过校验

            return {err, values: valueArr, record: records, def};
        },

        renderSlot(name, props = {}) {
            const {$scopedSlots} = this;

            return $scopedSlots[name] && $scopedSlots[name](props);
        },

        renderSlotBtns({formCtx, idx, child}) {
            const {
                curValues, def, onChange, canDelete, singleHasDelete, onDelete, $t, renderSlot, canCopy, ctx
            } = this;
            const fragment = [];
            const childFormExtraBtnNode = renderSlot('childFormExtraBtn', {...formCtx, onChange, idx});
            if (childFormExtraBtnNode) {
                fragment.push(childFormExtraBtnNode);
            }

            if (canCopy && !def.childCard) {
                fragment.push(
                    <a-button
                        type="primary"
                        vOn:click={() => this.onCopy(idx)}
                    >
                        {$t(def.copyText || 'common.copy')}
                    </a-button>
                );
            }

            if (
                (curValues.length > 1 || !def.required)
                && canDelete && singleHasDelete(child, idx, curValues)
                && !def.childCard
            ) {
                const {confirmDeleteText, popClose} = def;

                fragment.push(popClose
                    ? <a-popconfirm
                        title={`${confirmDeleteText ? evalProp(confirmDeleteText, {ctx, idx, value: child}) : `${$t('common.confirmDelete')}?`}`}
                        trigger="click"
                        vOn:confirm={() => onDelete(idx)}
                    >
                        <a-button type="danger">
                            {$t('common.delete')}
                        </a-button>
                    </a-popconfirm>
                    : <a-button
                        type="danger"
                        vOn:click={() => onDelete(idx)}
                    >
                        {$t('common.delete')}
                    </a-button>
                );
            }

            return fragment;
        },

        renderAddBtn() {
            // addIconOnly-有时候需求不想在按钮里显示文字，只想显示图标
            const {$t, def: {addShape, addIcon, addText, addIconOnly = false}, onAdd} = this;

            return (
                <a-button
                    type="primary"
                    shape={addShape}
                    icon={addIcon}
                    vOn:click={onAdd}
                >
                    { (addIconOnly && addIcon) ? '' : $t(addText || 'common.create') }
                </a-button>
            );
        },

        renderChildForm({idx, child}) {
            const {ctx, def, childKey, renderSlotBtns, onChange} = this;

            return (
                <yqg-simple-form
                    key={childKey(child, idx)}
                    ref={`childForm-${idx}`}
                    ctx={ctx}
                    values={child}
                    idx={idx}
                    options={def.formOptions}
                    scopedSlots={{
                        extraBtns: formCtx => renderSlotBtns({formCtx, idx, child})
                    }}
                    vOn:change={formValues => onChange(idx, formValues)}
                />
            );
        },

        renderForm() {
            const {
                def, def: {childCard, draggable: isDraggable = false, popClose, copyText, confirmDeleteText}, canAdd, canDelete,
                curValues, singleHasDelete, renderChildForm, getItemTitle, onDelete, canCopy, onCopy, ctx
            } = this;

            if (!canAdd && !curValues.length) {
                return (
                    <div>/</div>
                );
            }

            if (childCard) {
                return (
                    <div>
                        {
                            curValues?.map((child, idx) => {
                                const CloseIcon = popClose
                                    ? (
                                    <a-popconfirm
                                        title={`${confirmDeleteText ? evalProp(confirmDeleteText, {ctx, idx, value: child}) : `${this.$t('common.confirmDelete')}?`}`}
                                        trigger="click"
                                        vOn:confirm={() => onDelete(idx)}
                                    >
                                        <a-icon
                                            class="close"
                                            type="close"
                                        />
                                    </a-popconfirm>
                                )
                                : <a-icon
                                    class="close"
                                    type="close"
                                    vOn:click={() => onDelete(idx)}
                                />;

                                return (
                                    <a-card key={idx} class="child-form-card">
                                        <div class="card-header">
                                            <div class="yqg-form-title title">{getItemTitle(idx)}</div>
                                            {
                                                (curValues.length > 1 || !def.required)
                                                && canDelete && singleHasDelete(child, idx, curValues)
                                                && CloseIcon
                                            }
                                        </div>
                                        {renderChildForm({idx, child})}
                                        {canCopy && (
                                            <div class="card-footer">
                                                <a-button
                                                    type="primary"
                                                    vOn:click={() => onCopy(idx)}
                                                >
                                                    {this.$t(copyText || 'common.copy')}
                                                </a-button>
                                            </div>
                                        )}
                                    </a-card>
                                );
                            })
                        }
                        {canAdd && this.renderAddBtn()}
                    </div>
                );
            }

            return (
                <a-card>
                    <draggable draggable=".drag-item" disabled={!isDraggable} vModel={this.curValues}>
                        {curValues.map((child, idx) => (
                            <div class="drag-item">
                                {renderChildForm({idx, child})}
                                <a-divider class="divider" />
                            </div>
                        ))}
                        {canAdd && this.renderAddBtn()}
                    </draggable>
                </a-card>
            );
        }
    },

    render() {
        const {renderSlot, renderForm} = this;

        return (
            <a-config-provider autoInsertSpaceInButton={false}>
                <div class="field-child-form">
                    {renderSlot('head')}
                    {renderForm()}
                    {renderSlot('foot')}
                </div>
            </a-config-provider>
        );
    }
};
</script>

<style lang="scss" rel="stylesheet/scss">
.field-child-form {
    .divider {
        margin: 10px 0;
    }

    .yqg-simple-form {
        .ant-form-inline {
            .ant-form-item-label {
                justify-content: flex-start;
            }
        }
    }

    .child-form-card {
        margin-bottom: 16px;

        .card-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 16px;

            .title {
                color: rgba(0, 0, 0, 0.6);
            }

            .close {
                font-size: 18px;
            }
        }
    }
}
</style>
