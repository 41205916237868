<!-- @Author mengpeng -->
<!-- @Date: 2022-01-29 16:51:07 -->
<!-- @Last Modified by: mengpeng -->
<!-- @Last Modified time: 2022-02-07 15:29:33 -->

<template>
    <div class="yqg-infinite-pagination">
        <a-button
            class="ant-pagination-prev"
            :disabled="!hasPrev"
            icon="left"
            @click="prev"
        />
        <span class="ant-pagination-item">{{ current }}</span>
        <a-button
            class="ant-pagination-next"
            :disabled="!hasNext"
            icon="right"
            @click="next"
        />
        <a-select
            v-if="showSizeChanger"
            :default-value="pagination.pageSize"
            class="ant-pagination-options"
            :options="genOptions"
            @change="changePageSize"
        />
    </div>
</template>

<script>
const defaultPageSizeOptions = ['10', '20', '50', '100', '200'];

export default {
    name: 'YqgInfinitePagination',

    props: {
        pagination: {
            type: Object,
            required: true
        },
        dataSize: {
            type: Number,
            required: true
        },
        showSizeChanger: {
            type: Boolean,
            default: true
        }
    },

    data() {
        return {
            current: 1
        };
    },

    computed: {
        hasPrev({current}) {
            return current > 1;
        },

        hasNext({pagination: {pageSize}, dataSize}) {
            return dataSize === Number(pageSize);
        },

        genOptions({pagination: {pageSizeOptions = defaultPageSizeOptions}}) {
            return pageSizeOptions.map(value => ({value: Number(value), label: `${value} 条/页`}));
        }
    },

    watch: {
        'pagination.pageNo'(current) {
            this.current = current;
        }
    },

    methods: {
        prev() {
            if (this.hasPrev) {
                this.changePage(this.current - 1);
            }
        },

        next() {
            if (this.hasNext) {
                this.changePage(this.current + 1);
            }
        },

        changePage(page) {
            const {
                pagination: {pageSize}
            } = this;
            const pageNo = page < 1 ? 1 : page;
            this.current = pageNo;
            this.$emit('change', {pageNo, pageSize, current: this.current});
        },

        changePageSize(pageSize) {
            const current = 1;
            this.$emit('change', {pageNo: current, pageSize: Number(pageSize), current});
        }
    }
};
</script>

<style lang="scss" scoped>
.yqg-infinite-pagination {
    margin: 16px 0;
    display: table;
}
</style>
