/**
 * @Author: giligili
 * @Date: 2021-08-11
 * @Last Modified by: yefenghan
 * @Last Modified time: 2024-08-18 15:23:44
 */

import EnumAll from 'collection-admin-web/common/constant/enum';
import Ytalk from 'collection-admin-web/common/resource/call/ytalk';

import BasicType from './basic-type';
import ComplaintInboundType from './complaint-inbound-type';
import DialType from './dial-type';
import InboundTransferType from './inbound-transfer-type';
import InboundType from './inbound-type';
import MultiType from './multi-type';
import PredictType from './predict-type';
import TransferType from './transfer-type';

const {YQG} = EnumAll.Call.Channel.TYPE;
const BooleanType = EnumAll.Basic.Boolean.TYPE;
const CallDialType = EnumAll.Call.DialType.TYPE;

export default class CallType {

    incomingCallTypeMap = {
        MULTI: MultiType,
        INBOUND: InboundType,
        PREDICTIVE: PredictType,
        INBOUND_TRANSFER: InboundTransferType,
        TRANSFER: TransferType
    };

    constructor({
        session,
        request,
        extraData = {},
        sharedData = {},
        ytalkConfig = {},
        setAgentStatusMethod = () => ({})
    }) {
        this.addCallDetailLock = false;

        this.useSetAgentStatusMethodLock = false;
        this.setAgentStatusMethodLock = false;
        this.canSetAgentStatus = true;

        this.setAgentStatusMethod = setAgentStatusMethod;

        const {direction} = session;

        if (direction === 'outgoing') {
            this.call = new DialType({extraData, sharedData, ytalkConfig});

            return;
        }

        const callType = request.getHeader('X-Call-Type');
        const scene = request.getHeader('X-SCENE');
        const gatewayCode = request.getHeader('X-GATEWAY-CODE');
        const screenshotCustomer = request.getHeader('X-screenshotCustomer');

        const inviteInfo = {gatewayCode, screenshotCustomer};

        let Constructor = this.incomingCallTypeMap[callType];
        Constructor = (scene === 'collection_inbound_tousu_400' && callType === 'INBOUND')
            ? ComplaintInboundType
            : Constructor;

        if (Constructor) {
            this.call = new Constructor({extraData, sharedData, ytalkConfig, inviteInfo});

            return;
        }

        this.call = new BasicType({extraData, sharedData, ytalkConfig, inviteInfo});
    }

    addCallDetail({connected = false} = {}) {
        if (this.addCallDetailLock) return Promise.reject('addCallDetailLock');

        this.addCallDetailLock = true;

        const {
            sharedData: {
                callId,
                customData,
                isMultiCall,
                callDirection,
                predictiveCustomData
            },
            extraData: {
                callingNumber,
                encryptedMobileNumber, // 呼入，输入框手拨时与ytalk交互的加密手机号
                dialType = CallDialType.NORMAL,
                isDialBack = BooleanType.FALSE,
                contactInfo: {
                    caseId,
                    caseType,
                    contactType,
                    phoneBookId,
                    contactRank,
                    encryptedMobileNumber: contactEncryptedMobileNumber // 案件联系人信息的加密手机号
                } = {}
            }
        } = this.call;

        const payload = {
            callId,
            dialType,
            customData: customData || predictiveCustomData,
            connected: connected ? BooleanType.TRUE : BooleanType.FALSE,
            isMultiCall: isMultiCall ? BooleanType.TRUE : BooleanType.FALSE,
            isDialBack,
            callingNumber,
            callDirection,
            thirdPartyType: YQG,
            // 下面的优先级顺序不能更改(拨打虚拟号的时候，实际给ytalk的encryptedMobileNumber, 并不是催收后端要接收的encryptedMobileNumber)
            // 所以contactEncryptedMobileNumber的优先级高于encryptedMobileNumber
            encryptMobileNumber: contactEncryptedMobileNumber || encryptedMobileNumber,
            caseId,
            caseType,
            contactType,
            phoneBookId,
            contactRank
        };

        if (!callId) return Promise.reject('noCallId');

        return Ytalk.addCallDetail(payload)
            .then(({data: {body}}) => {
                if (body) {
                    return {
                        type: 'success',
                        message: '添加外呼记录成功'
                    };
                }

                return {
                    type: 'error',
                    message: '添加外呼记录失败'
                };
            })
            .catch(() => {
                return {
                    type: 'error',
                    message: '添加外呼记录失败'
                };
            });
    }

    startAgentCopilot() {
        const {
            sharedData: {
                callId,
                customData,
                isMultiCall,
                dialByInput = false
            },
            extraData: {
                encryptedMobileNumber: encryptMobileNumber,
                contactInfo: {
                    caseId,
                    contactType,
                    encryptedMobileNumber: contactEncryptedMobileNumber
                } = {}
            }
        } = this.call;

        const payload = isMultiCall ? {
            callId,
            encryptedMobileNumber: contactEncryptedMobileNumber || encryptMobileNumber,
            isMultiCall: BooleanType.TRUE,
            customData
        } : {
            callId,
            caseId,
            contactType,
            encryptedMobileNumber: contactEncryptedMobileNumber || encryptMobileNumber,
            isMultiCall: BooleanType.FALSE
        };

        if (!callId) return Promise.reject('noCallId');
        if (!payload?.encryptedMobileNumber) return Promise.reject('noEncryptedMobileNumber');

        if (dialByInput) return Promise.reject('dialByInput');

        return Ytalk.dialWithAssist(payload)
            .then(({data: {body}}) => {
                if (body) {
                    return {
                        type: 'success',
                        message: '开启坐席辅助成功'
                    };
                }

                return {
                    type: 'error',
                    message: '开启坐席辅助失败'
                };
            })
            .catch(() => {
                return {
                    type: 'error',
                    message: '开启坐席辅助失败'
                };
            });
    }

    updateSetAgentStatusMethod(func) {
        this.setAgentStatusMethod = func;

        this.useSetAgentStatusMethodLock = true;
    }

    stopSetAgentStatusMethod() {
        this.canSetAgentStatus = false;
    }

    setAgentStatus() {
        if (!this.canSetAgentStatus) return;

        if (this.useSetAgentStatusMethodLock && this.setAgentStatusMethodLock) return;

        if (this.useSetAgentStatusMethodLock && !this.setAgentStatusMethodLock) this.setAgentStatusMethodLock = true;

        this.setAgentStatusMethod();
    }

}
