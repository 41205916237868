/**
 * @Author: giligili
 * @Date: 2020/01/25
 * @Last Modified by: zhimingwang
 * @Last Modified time: 2024-06-21 17:20:33
 */

export default {
    INBOUND: 'INBOUND', // 回拨
    PREDICTIVE: 'PREDICTIVE', // 预测外呼
    MULTI: 'MULTI', // 一键多呼
    EAVESDROP: 'EAVESDROP', // 监听
    CALL_TEST: 'CALL_TEST', // 回声测试
    INBOUND_FLOW_TEST: 'INBOUND_FLOW_TEST' // 可视化流程测试
};
