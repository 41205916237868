/*
 * @Author: qxy
 * @Date: 2020-12-01 11:33:38
 * @Last Modified by: qxy
 * @Last Modified time: 2020-12-02 15:09:33
 */

/* eslint-disable max-len */

export default {
    name: 'custom-dial-keyboard',
    theme: 'outline',
    icon: {
        tag: 'svg',
        attrs: {viewBox: '0 0 1024 1024', x: '0', y: '0'},
        children: [
            {
                tag: 'path',
                attrs: {
                    d: 'M179.2 294.4a115.2 115.2 0 110-230.4 115.2 115.2 0 010 230.4zm0 332.8a115.2 115.2 0 110-230.4 115.2 115.2 0 010 230.4zM512 294.4A115.2 115.2 0 11512 64a115.2 115.2 0 010 230.4zm0 332.8a115.2 115.2 0 110-230.4 115.2 115.2 0 010 230.4zm332.8-332.8a115.2 115.2 0 110-230.4 115.2 115.2 0 010 230.4zm0 332.8a115.2 115.2 0 110-230.4 115.2 115.2 0 010 230.4zM179.2 960a115.2 115.2 0 110-230.4 115.2 115.2 0 010 230.4zm332.8 0a115.2 115.2 0 110-230.4 115.2 115.2 0 010 230.4zm332.8 0a115.2 115.2 0 110-230.4 115.2 115.2 0 010 230.4z'
                }
            }
        ]
    }
};
