/**
 * @Author: giligili
 * @Date: 2021-07-30
 * @Last Modified by: yefenghan
 * @Last Modified time: 2024-07-17 17:45:19
 */
import { assign, customGet, customPost } from '@yqg/resource';
import { requestNotConcurrency } from 'collection-admin-web/common/util/tool';
const api = {
    urlPrefix: '/admin/operation/col/call',
    checkBeforeAddCallDetail: customGet('checkBeforeAddCallDetail'),
    getCallConfig: customPost('getCallConfig'),
    setAgentStatus: customGet('yqg/setAgentStatus'),
    setAgentSkillId: customGet('yqg/setAgentSkillId'),
    transferSatisIvr: customGet('yqg/transferSatisIvr'),
    addCallDetail: customPost('yqg/addCallDetail'),
    beforeConnect: customPost('pingan/beforeConnect'),
    multiDial: customPost('multi/dialList'),
    getCase: customPost('multi/getCase'),
    getCaseDetail: customPost('dialBack/getCaseDetail'),
    getCallInfo: customGet('getCallInfo'),
    checkMobileCallCnt: customGet('checkMobileCallCnt'),
    dialWithAssist: customPost('yqg/dialWithAssist'),
    setWorkStatus: customPost('yqg/setWorkStatus'),
    setWorkStatusLogout: customPost('yqg/setWorkStatusLogout'),
};
const resource = assign(api);
export default Object.assign(Object.assign({}, resource), { 
    // 这个接口并发请求会报错, 所以这里限制下，让其串行
    // 这个接口可能在定时器回调内执行，也可能由坐席行为触发，是否会并发不可控，所以在这边处理一下.
    setAgentStatus: requestNotConcurrency(resource.setAgentStatus), setWorkStatus: requestNotConcurrency(resource.setWorkStatus) });
