/*
 * @Author: wenxiujiang
 * @Date:   2021-01-26 16:32:54
 * @Last Modified by: xiaodongyu
 * @Last Modified time: 2021-04-13 15:20:38
 */

/* eslint-disable max-len */

export default {
    name: 'plus',
    theme: 'outline',
    icon: {
        tag: 'svg',
        attrs: {viewBox: '0 0 100 100', stroke: 'currentColor'},
        children: [
            {
                tag: 'line',
                attrs: {
                    'x1': 12,
                    'y1': 50,
                    'x2': 88,
                    'y2': 50,
                    'stroke-width': 6,
                    'stroke-linecap': 'round'
                }
            },
            {
                tag: 'line',
                attrs: {
                    'x1': 50,
                    'y1': 12,
                    'x2': 50,
                    'y2': 88,
                    'stroke-width': 6,
                    'stroke-linecap': 'round'
                }
            }
        ]
    }
};
