/**
 * @Author: giligili
 * @Date: 2021-08-02
 * @Last Modified by: yefenghan
 * @Last Modified time: 2024-07-12 15:16:48
 */

import Notification from 'collection-admin-web/common/resource/notification';

const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION';
const UPDATE_NEW_NOTIFICATION_COUNT = 'UPDATE_NEW_NOTIFICATION_COUNT';

export default {
    namespaced: true,

    state: {
        notificationList: [],
        newNotificationCount: 0
    },

    getters: {
        notificationList: state => state.notificationList,
        newNotificationCount: state => state.newNotificationCount
    },

    mutations: {
        [UPDATE_NOTIFICATION](state, payload) {
            state.notificationList = payload;
        },

        [UPDATE_NEW_NOTIFICATION_COUNT](state, payload) {
            state.newNotificationCount = payload;
        }
    },

    actions: {
        fetchNotificationList({commit}) {
            Notification.queryNotification({hideLoading: true})
                .then(({data: {body: {notificationList = [], newNotificationCount = 0}}}) => {
                    commit(UPDATE_NOTIFICATION, notificationList);
                    commit(UPDATE_NEW_NOTIFICATION_COUNT, newNotificationCount);
                })
                .catch(err => err);
        },

        finishNotification({dispatch}, {notificationId}) {
            Notification.finishNotification({notificationId})
                .then(({data: {body}}) => {
                    if (body) {
                        dispatch('fetchNotificationList');
                    }
                })
                .catch(err => err);
        },

        clearNotification({dispatch}) {
            Notification.batchFinishTransferCaseNotification()
                .then(({data: {body}}) => {
                    if (body) {
                        dispatch('fetchNotificationList');
                    }
                })
                .catch(err => err);
        }
    }
};
