/*
 * @Author: yefenghan 
 * @Date: 2024-05-22 10:37:19 
 * @Last Modified by:   yefenghan 
 * @Last Modified time: 2024-05-22 10:37:19 
 */
import {create} from '@yqg/resource';

const api = {
    urlPrefix: '/admin/operation/col/case/',

    renewal: {
        url: 'renewal',
        method: 'get'
    },
};

export default create(api);
