<!-- @author Kyles Light -->
<!-- @email kuilin@yangqianguan.com -->
<!-- @date 2018-04-02 16:07:07.961 -->
<!-- @desc generated by yqg-cli@0.1.0-beta.3 -->

<template>
    <pre
        :class="`lang-${lang}`"
        class="yqg-code-preview"
    ><code v-text="code" /></pre>
</template>

<script type="text/babel">

import Prism from 'prismjs';

export default {
    name: 'YqgCodePreview',

    props: {
        code: {
            type: String,
            default: ''
        },
        lang: {
            type: String,
            default: 'json'
        }
    },

    mounted() {
        this.highlight();
    },

    updated() {
        this.$nextTick(this.highlight);
    },

    methods: {
        highlight() {
            Prism.highlightElement(this.$el.firstElementChild);
        }
    }
};

</script>
