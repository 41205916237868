var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "sug-container",
      attrs: { title: "问题反馈" },
      on: { click: _vm.jumptoSuggestion },
    },
    [
      _vm.imgIcon
        ? _c("img", { attrs: { src: require("./suggestion.png") } })
        : _c("a-icon", { staticClass: "icon", attrs: { type: "bulb-o" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }