<!-- @Author: giligili -->
<!-- @Date: 2023-04-18 16:11:58 -->
<!-- @Last Modified by: giligili -->
<!-- @Last Modified time: 2023-04-18 16:11:58 -->

<template>
    <div class="yqg-marquee">
        <a-tooltip
            placement="bottom"
            :overlay-style="tooltipStyle"
            :get-popup-container="() => $el"
        >
            <template slot="title">
                <yqg-md-view
                    class="marquee-detail"
                    :md-text="content"
                />
            </template>

            <div
                ref="marquee"
                class="marquee-text"
                @mouseenter="pause"
                @mouseleave="resume"
            >
                <yqg-md-view
                    ref="marqueeText"
                    :md-text="content"
                />
            </div>
        </a-tooltip>
    </div>
</template>

<script type="text/babel">

    import _ from 'underscore';

    function linear(currentTime, startValue, changeValue, duration) {
        return changeValue * currentTime / duration + startValue;
    }

    function createAnimation(from, to, duration, callback, easing = linear) {
        let start = 0;
        let frame = null;

        const step = function() {
            const value = easing(start, from, to - from, Math.ceil(duration / 10));

            start++;

            if (start <= Math.ceil(duration / 10)) {
                frame = requestAnimationFrame(step);

                return callback(value);
            } else {
                return callback(to, true);
            }
        };

        return {
            run() {
                step();
            },

            pause() {
                window.cancelAnimationFrame(frame);
            },

            resume() {
                step();
            },

            destroy() {
                window.cancelAnimationFrame(frame);
            }
        };
    }

    const speed = 100; // px/frame

    export default {
        name: 'YqgMarquee',

        props: {
            content: {
                type: String,
                default: ''
            }
        },

        data() {
            return {
                tooltipStyle: {
                    maxWidth: '80%',
                    maxHeight: '50vh',
                    overflow: 'auto'
                }
            };
        },

        mounted() {
            this.start();

            const onResize = _.debounce(this.start, 100);

            window.addEventListener('resize', onResize);

            this.$once('hook:beforeDestroy', () => {
                this.stop();

                window.removeEventListener('resize', onResize);
            });
        },

        methods: {
            getMarqueeInfo() {
                const {$refs: {marquee, marqueeText}} = this;

                if (!marquee || !marqueeText) return null;

                const {width} = marquee.getBoundingClientRect();
                const {width: scrollWidth} = marqueeText.$el.getBoundingClientRect();

                return {width, scrollWidth};
            },

            animationAvailable() {
                const marqueeInfo = this.getMarqueeInfo();

                if (!marqueeInfo) return false;

                const {scrollWidth, width} = marqueeInfo;

                return scrollWidth > width;
            },

            animate() {
                const marqueeInfo = this.getMarqueeInfo();

                if (!marqueeInfo) return;

                const {scrollWidth, width} = marqueeInfo;

                this.animation = createAnimation(width, -scrollWidth, (scrollWidth + width) / speed * 1000, (tx, end) => {
                    // 这里不能给用:style, 因为 tooltip 会随着 render 闪烁
                    this.$refs.marqueeText.$el.style.transform = `translate3d(${tx}px, 0, 0)`;

                    if (end) this.start();
                });

                this.animation.run();
            },

            start() {
                const available = this.animationAvailable();

                if (this.animation) this.stop();

                if (!available) return;

                this.animate();
            },

            stop() {
                if (this.$refs.marqueeText) this.$refs.marqueeText.$el.style.transform = 'translate3d(0, 0, 0)';

                if (!this.animation) return;

                this.animation.destroy();
                this.animation = null;
            },

            pause() {
                if (!this.animation) return;

                this.animation.pause();
            },

            resume() {
                if (!this.animation) return;

                this.animation.resume();
            }
        }
    };

</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .yqg-marquee {
        width: 100%;

        .marquee-text {
            height: 40px;
            text-align: center;
            overflow: hidden;
            cursor: pointer;

            ::v-deep .md-view {
                width: max-content;
                max-width: unset;
                overflow: unset;
            }

            ::v-deep * {
                display: inline-block;
                height: 40px;
                padding: 0;
                margin: 0;
                font-size: 14px;
                line-height: 40px;
                white-space: nowrap;
                vertical-align: top;
            }
        }

        .marquee-detail {
            color: #fff;
        }
    }
</style>
