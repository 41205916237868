<!-- @Author: ruiwang -->
<!-- @Date: 2020-9-7 17:46:10 -->
<!-- @Last Modified by: xinzhong -->
<!-- @Last Modified time: 2023-06-13 14:39:27 -->

<template>
    <a-select
        v-model="compValue"
        :auto-clear-search-value="!isMultipleSelectAll"
        v-bind="def.props"
        @change="onChange"
        @search="onSearch"
        @dropdownVisibleChange="onDropdownVisibleChange"
    >
        <template
            v-if="isMultipleSelectAll"
            #dropdownRender="menu"
        >
            <v-nodes :vnodes="menu" />
            <a-divider style="margin: 4px 0;" />
            <div
                class="select-all-btn-groups"
                @mousedown="e => e.preventDefault()"
            >
                <a-button
                    size="small"
                    @click="clearAll"
                >
                    清空
                </a-button>
                <a-button
                    size="small"
                    type="primary"
                    @click="selectAll"
                >
                    全选
                </a-button>
            </div>
        </template>

        <a-select-option
            v-for="{label, value: val, ...rest} in selectOptions"
            v-bind="rest"
            :key="val"
            :value="val"
            :title="tooltipVisible ? label : ''"
        >
            {{ label }}
        </a-select-option>
    </a-select>
</template>

<script>
import _ from 'underscore';

import {enumType} from '../../../mixin';
import {numbersToStr} from '../../../util/object';

/* type SelectOption = {
    label: string,
    value: any,
    pinyin: string,
    [optionLabelProp: string]: string
} */

export default {
    name: 'FieldSelect',

    components: {
        VNodes: {
            functional: true,
            render: (h, ctx) => ctx.props.vnodes
        }
    },

    mixins: [enumType],

    props: {
        value: {
            type: [Number, String, Array],
            default: undefined
        },

        tooltipOption: {
            type: Boolean,
            default: false
        }
    },

    data() {
        const {def: {props: {mode}}, value} = this;
        const compValue = mode === 'multiple' && !value ? undefined : numbersToStr(value);

        return {
            compValue,
            searchText: undefined,
            changed: false,
            timeout: null
        };
    },

    computed: {
        isMultipleSelectAll() {
            return this.def.props.mode === 'multiple' && this.def.selectAll;
        },

        selectOptions() {
            // selectOption: SelectOption
            const {
                def: {validateSearch, autoSearchMinLen = Infinity},
                compValue,
                searchText,
                options
            } = this;

            if (validateSearch || options.length > autoSearchMinLen) {
                return options.filter(({value, pinyin}) => {
                    if (searchText) {
                        return pinyin.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
                    }

                    return [].concat(compValue).includes(value);
                });
            }

            return options;
        },

        tooltipVisible() {
            const {tooltipOption, def} = this;

            if (def.tooltipOption !== undefined) {
                return def.tooltipOption;
            }

            return tooltipOption;
        }
    },

    watch: {
        value(val) {
            if (val === this.compValue) return;

            const {def: {props: {mode}}} = this;
            if (mode === 'multiple' && !val) val = undefined;

            this.getSuperText(val);
        },

        wrappedEnumType() {
            const {value} = this;
            this.getSuperText(value);
        }
    },

    methods: {
        onChange(val) {
            const {def: {props: {mode}}, wrappedEnumType, changed} = this;

            // 因为tags模式手输的值肯定不在wrappedEnumType内，故不用filter
            // filter的话会导致第一个手输的值被filter掉
            if (mode !== 'tags' && !changed && Array.isArray(val) && wrappedEnumType) {
                val = val.filter(item => wrappedEnumType.MAP[item]);
                this.changed = true;
            }

            this.$emit('change', val);
        },

        onSearch(val) {
            this.searchText = val;
            this.$emit('search', val);

            const {def: {props: {filterOption} = {}}, timeout} = this;

            if (filterOption !== false) return;

            if (timeout) clearTimeout(timeout);

            this.timeout = setTimeout(() => this.initData(val), 300);
        },

        getSuperText(value) {
            const {wrappedEnumType} = this;
            let res;
            const getText = key => {
                if (wrappedEnumType && !wrappedEnumType.MAP[key]) {
                    return wrappedEnumType.getText(key);
                }

                return key;
            };

            if (Array.isArray(value)) {
                res = value.map(item => getText(item));
            } else {
                res = getText(value);
            }

            this.compValue = numbersToStr(res) ?? undefined;
        },

        selectAll() {
            const {selectOptions, searchText} = this;
            const val = selectOptions?.filter(
                ({pinyin}) => !searchText || pinyin.toLowerCase().indexOf(searchText.toLowerCase()) > -1)
                .map(({value}) => value) || [];
            const uniqVal = _.uniq([...(this.compValue || []), ...val]); // 去重，防止重复添加
            this.$emit('change', uniqVal);
        },

        clearAll() {
            this.$emit('change', undefined);
        },

        onDropdownVisibleChange(open) {
            if (!open) { // 收起时dropdown时，清空 searchText
                this.searchText = undefined;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.select-all-btn-groups {
    padding: 4px 8px 8px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
}
</style>
