/*
 * @Author: qxy
 * @Date: 2020-07-08 16:28:00
 * @Last Modified by: qxy
 * @Last Modified time: 2020-07-08 16:29:11
 */

/* eslint-disable max-len */

export default {
    name: 'custom-unmuted',
    theme: 'outline',
    icon: {
        tag: 'svg',
        attrs: {viewBox: '0 0 1024 1024', x: '0', y: '0'},
        children: [
            {
                tag: 'path',
                attrs: {
                    d: 'M817.23 354.462c-27.568 0-49.23 19.692-49.23 47.261v78.77c0 137.845-116.185 252.06-257.97 252.06s-257.968-114.215-257.968-252.06v-78.77c0-27.57-21.662-47.261-49.231-47.261s-45.293 19.692-45.293 47.261v78.77c0 175.26 133.908 319.015 305.231 342.645v66.954h-80.738c-27.57 0-49.231 19.693-49.231 47.262s21.662 47.261 49.23 47.261H640c27.57 0 49.23-19.692 49.23-47.261s-21.66-47.262-49.23-47.262h-80.738v-66.954c173.292-23.63 307.2-167.384 307.2-342.646v-78.769c0-27.57-21.662-47.261-49.231-47.261zM512 638.03c86.646 0 157.538-70.893 157.538-157.539V194.954c0-86.646-68.923-155.57-155.569-155.57h-3.938c-86.646 0-155.57 68.924-155.57 155.57v285.538c0 86.646 70.893 157.539 157.539 157.539z'
                }
            }
        ]
    }
};
