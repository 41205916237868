/*
 * @Author: qxy
 * @Date: 2020-06-19 13:48:43
 * @Last Modified by: qxy
 * @Last Modified time: 2020-06-19 13:59:13
 */

/* eslint-disable max-len */

export default {
    name: 'custom-minus',
    theme: 'outline',
    icon: {
        tag: 'svg',
        attrs: {viewBox: '0 0 1024 1024', x: '0', y: '0'},
        children: [
            {
                tag: 'path',
                attrs: {
                    d: 'M911.232 420.565v109.718q0 22.848-16 38.848t-38.848 16H161.536q-22.848 0-38.848-16t-16-38.848V420.565q0-22.848 16-38.848t38.848-16h694.848q22.848 0 38.848 16t16 38.848z'
                }
            }
        ]
    }
};
