/*
 * @Author: huayizhang 
 * @Date: 2021-07-26 19:01:47 
 * @Last Modified by:   huayizhang 
 * @Last Modified time: 2021-07-26 19:01:47 
 */

export default {
    CALL_IN: 'CALL_IN',
    CALL_OUT: 'CALL_OUT'
};
