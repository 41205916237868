/**
 * @Author: zhangpeng
 * @Date: 17/2/28-下午5:19
 * @Last Modified by: youboli
 * @Last Modified time: 2024-03-13 16:11:19
 */
/* global
  __DEBUG__, __STAGE__, __PRIVILEGE_CHECK__,
  __WEB_USER_HOST__, __WEB_HOST__, __API_HOST__,
  __CHIDORI_API_HOST__, __CHIDORI_HOST__, __MAYURI_API_HOST__,
  __WEB_USER_KUNLUN_HOST__
*/

/* eslint-disable*/
/* injected by yqg-cli default */
// 防止vite打包时，__DEBUG__等变量被替换成undefined
export const DEBUG = typeof __DEBUG__ !== 'undefined' ? __DEBUG__ : '';
export const STAGE = typeof __STAGE__ !== 'undefined' ? __STAGE__ : '';
export const PRIVILEGE_CHECK = typeof __PRIVILEGE_CHECK__ !== 'undefined' ? __PRIVILEGE_CHECK__ : '';
export const WEB_HOST = typeof __WEB_HOST__ !== 'undefined' ? __WEB_HOST__ : '';
export const API_HOST = typeof __API_HOST__ !== 'undefined' ? __API_HOST__ : '';
export const CHIDORI_API_HOST = typeof __CHIDORI_API_HOST__ !== 'undefined' ? __CHIDORI_API_HOST__ : '';
export const CHIDORI_HOST = typeof __CHIDORI_HOST__ !== 'undefined' ? __CHIDORI_HOST__ : '';
export const MAYURI_API_HOST = typeof __MAYURI_API_HOST__ !== 'undefined' ? __MAYURI_API_HOST__ : '';

export const isOverseasStage = /overseas|indo|mex|india|brazil|phi|sea|eu/i.test(STAGE);
export const isDevStage = /dev/i.test(STAGE);
export const isProdStage = /prod/i.test(STAGE);
export const isPudaoStage = /pudao/i.test(STAGE);
export const isMockStage = /mock/i.test(STAGE);
export const isIndoStage = /indo/i.test(STAGE);
export const isEuStage = /eu/i.test(STAGE);
export const isNewOverseasTestStage = /test-sea|test-indo|test-mex/i.test(STAGE);
export const isIndoTestStage = /test-indo/i.test(STAGE);
export const isSeaTestStage = /test-sea/i.test(STAGE);
export const isMexTestStage = /test-mex/i.test(STAGE);
export const isPhiProdStage = /prod-phi/i.test(STAGE);
export const isOverseasTestStage = /test-overseas/i.test(STAGE);

/* inject from config build.global */
export const WEB_USER_HOST = typeof __WEB_USER_HOST__ !== 'undefined' ? __WEB_USER_HOST__ : '';
export const WEB_USER_KUNLUN_HOST = typeof __WEB_USER_KUNLUN_HOST__ !== 'undefined' ? __WEB_USER_KUNLUN_HOST__ : '';
