/*
 * Author: giligili
 * Created Date: 2021-05-13
 * email: zhimingwang@yangqianguan.com
 * desc: 公用的枚举
 */

import _ from 'underscore';

import BooleanType from 'collection-admin-web/common/constant/maps/boolean';
import CommonResource from 'collection-admin-web/common/resource/common';
import User from 'collection-admin-web/common/resource/user';
import Enum from 'collection-admin-web/common/util/enum';

import {COLLECTOR_ID_ALL_KEY} from './collector-map';

export const Boolean = Enum.from(BooleanType);

export const SdkType = Enum.query(() => CommonResource.listCaseTagByTypeCode({params: {typeCode: 'SDK_TYPE'}}), {
    dataBodyField: 'caseTagConfigList',
    valueField: 'code',
    textField: 'name',
    queryOnce: true
});
export const ComplaintCollector = Enum.query(
    () => User.listComplaintManager().then(res => {
        const {collectorMap} = res.data.body;
        res.data.body.collectorMap = _.omit(collectorMap, COLLECTOR_ID_ALL_KEY);

        return res;
    }),
    {
        queryOnce: true,
        dataBodyField: 'collectorMap'
    }
);
// 下级机构
export {default as Collector, CollectorMapWithoutAll, PredictCollectorMap} from './collector-map';
export {default as Available, AvailableBoolean} from './available';
export {default as SatisfactionResult} from './satisfaction-result';
// 同机构或者兄弟机构，后端有判断，根据跨机构协催的权限点
export {default as SameLevelCollector} from './same-level-collector-map';
// 兄弟机构 + 下级机构
export const CollectorMapWithBrotherAreasWithoutAll = Enum.query(
    () => User.getCollectorMapWithBrotherAreas().then(res => {
        const {collectorMap} = res.data.body;
        res.data.body.collectorMap = _.omit(collectorMap, COLLECTOR_ID_ALL_KEY);

        return res;
    }),
    {
        queryOnce: true,
        dataBodyField: 'collectorMap'
    }
);
export {
    default as Area,
    ChildAreaEnum as ChildArea,
    ChildAreaBySortEnum as ChildAreaBySort
} from './area';
