/**
 * @author giligili
 * @date 2022-04-02
 * @email zhimingwang@yangqianguan.com
 * @desc override 重写一些组件的逻辑
 */

const NOOP = () => {};

export default {
    install(Vue) {
        const confirm = Vue.prototype.$confirm;

        Object.assign(Vue.prototype, {
            $confirm({onOk = NOOP, onCancel = NOOP, ...rest} = {}) {
                return new Promise((resolve, reject) => {
                    confirm.call(this, {
                        onOk() {
                            onOk();
                            resolve();
                        },
                        onCancel() {
                            onCancel();
                            reject();
                        },
                        title: '确认',
                        centered: true,
                        ...rest
                    });
                });
            }
        });
    }
};
