/*
 * @Author: huayizhang 
 * @Date: 2021-05-14 15:21:16 
 * @Last Modified by: huayizhang
 * @Last Modified time: 2021-05-14 15:22:01
 */

import Enum from 'collection-admin-web/common/util/enum';

export default Enum.from({
    VALID: '启用',
    INVALID: '禁用'
});
