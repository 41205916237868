var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "yqg-upload" }, [
    _c("div", { staticClass: "yqg-upload-input-wrapper" }, [
      _vm.uploadLocal
        ? _c(
            "div",
            [
              _c("b-input", { attrs: { placeholder: _vm.hintText } }),
              _vm._v(" "),
              _c("input", {
                attrs: {
                  multiple: _vm.multiple,
                  accept: _vm.accept,
                  disabled: _vm.uploading,
                  type: "file",
                },
                on: { change: _vm.onSelect },
              }),
            ],
            1
          )
        : _c(
            "div",
            [
              _c("b-input", {
                attrs: { value: _vm.value, placeholder: _vm.hintText },
                on: { input: _vm.onInput },
              }),
            ],
            1
          ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "yqg-upload-value-wrapper" },
      [
        !_vm.multiple
          ? _c(
              "label",
              [
                _c("b-switch", {
                  attrs: { disabled: _vm.uploading },
                  model: {
                    value: _vm.uploadLocal,
                    callback: function ($$v) {
                      _vm.uploadLocal = $$v
                    },
                    expression: "uploadLocal",
                  },
                }),
                _vm._v(" "),
                _c("span", [_vm._v("本地上传")]),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.multiple
          ? _c(
              "div",
              { staticClass: "value-list" },
              [
                _c(
                  "draggable",
                  {
                    attrs: { list: _vm.value, handle: ".sortable-handle" },
                    on: {
                      start: function ($event) {
                        _vm.dragging = true
                      },
                      end: function ($event) {
                        _vm.dragging = false
                      },
                    },
                  },
                  _vm._l(_vm.value, function (url, index) {
                    return _c("file-link", {
                      key: index,
                      staticClass: "file-item",
                      attrs: { url: url, sortable: "" },
                      on: {
                        delete: function ($event) {
                          return _vm.onDeleteValue(index)
                        },
                      },
                    })
                  }),
                  1
                ),
              ],
              1
            )
          : _vm.value
          ? _c("file-link", {
              attrs: { url: _vm.value },
              on: {
                delete: function ($event) {
                  return _vm.onReset()
                },
              },
            })
          : _vm._e(),
      ],
      1
    ),
    _vm._v(" "),
    _vm.uploadLocal && _vm.fileWrappers && _vm.fileWrappers.length
      ? _c(
          "div",
          { staticClass: "yqg-upload-file-wrapper" },
          [
            _vm._l(_vm.fileWrappers, function (wrapper, index) {
              return _c("file-wrapper", {
                key: wrapper.file.name,
                staticClass: "file-item",
                attrs: { wrapper: wrapper, disabled: _vm.uploading },
                on: {
                  upload: function ($event) {
                    return _vm.onUpload([wrapper])
                  },
                  delete: function ($event) {
                    return _vm.onDeleteWrapper(index)
                  },
                },
              })
            }),
            _vm._v(" "),
            _vm.multiple
              ? _c(
                  "button",
                  {
                    staticClass: "normal",
                    attrs: { disabled: _vm.uploading },
                    on: {
                      click: function ($event) {
                        return _vm.onUpload()
                      },
                    },
                  },
                  [_vm._v("\n            上传全部\n        ")]
                )
              : _vm._e(),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }