var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-pagination",
    _vm._b(
      {
        staticClass: "yqg-pagination",
        on: { change: _vm.onChange, showSizeChange: _vm.onChange },
      },
      "a-pagination",
      Object.assign({}, _vm.$options.defaultPaginationOptions, _vm.pagination, {
        current: _vm.pagination.pageNo,
      }),
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }