/**
 * @Author: giligili
 * @Date: 2021-07-06
 * @Last Modified by: xiaoduoshu
 * @Last Modified time: 2023-08-18 17:28:34
 */

import YqgLayoutAddFlowBtn from './add-flow-btn';
import YqgLayoutAdvanceSettle from './advance-settle';
import YqgLayoutCollectionFlowsTable from './collection-flows-table';
import YqgLayoutDetailCollapse from './detail-collapse';
import YqgLayoutDialBtn from './dial-btn';
import YqgLayoutInstalmentListTab from './instalment-list-tab';
import YqgLayoutLoanAdminLink from './load-admin-link';
import YqgLayoutMdPreview from './md-preview';
import YqgLayoutMediateInstalmentList from './mediate-instalment-list';
import YqgLayoutMediatePlan from './mediate-plan';
import YqgLayoutMultiCallBtn from './multi-call-btn';
import YqgLayoutNetworkLoading from './network-loading';
import YqgSurplusCallCnt from './surplus-call-cnt';
import YqgLayoutYtalk from './ytalk';

export default {
    install(Vue) {
        Vue.component('YqgLayoutYtalk', YqgLayoutYtalk);
        Vue.component('YqgLayoutDialBtn', YqgLayoutDialBtn);
        Vue.component('YqgLayoutMdPreview', YqgLayoutMdPreview);
        Vue.component('YqgLayoutMediatePlan', YqgLayoutMediatePlan);
        Vue.component('YqgLayoutMultiCallBtn', YqgLayoutMultiCallBtn);
        Vue.component('YqgLayoutNetworkLoading', YqgLayoutNetworkLoading);
        Vue.component('YqgLayoutCollectionFlowsTable', YqgLayoutCollectionFlowsTable);
        Vue.component('YqgSurplusCallCnt', YqgSurplusCallCnt);
        Vue.component('YqgLayoutAddFlowBtn', YqgLayoutAddFlowBtn);
        Vue.component('YqgLayoutAdvanceSettle', YqgLayoutAdvanceSettle);
        Vue.component('YqgLayoutMediateInstalmentList', YqgLayoutMediateInstalmentList);
        Vue.component('YqgLayoutInstalmentListTab', YqgLayoutInstalmentListTab);
        Vue.component('YqgLayoutLoanAdminLink', YqgLayoutLoanAdminLink);
        Vue.component('YqgLayoutDetailCollapse', YqgLayoutDetailCollapse);
    }
};
