var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "alarm-case" },
    [
      _c("yqg-case-link", {
        staticClass: "mr10",
        attrs: {
          text: _vm.notification.data.name,
          "case-id": _vm.notification.data.caseId,
        },
      }),
      _vm._v(" "),
      _c("span", { staticClass: "mr10" }, [
        _vm._v(_vm._s(_vm._f("dateTime")(_vm.notification.data.timeRemind))),
      ]),
      _vm._v(" "),
      _vm.notification.data.extraData.contact
        ? _c("span", { staticClass: "mr10" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.notification.data.extraData.contact) +
                "\n    "
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("span", { staticClass: "mr10" }, [
        _vm._v(
          "\n        " +
            _vm._s(_vm.notification.data.extraData.label || "无标签") +
            "\n    "
        ),
      ]),
      _vm._v(" "),
      _c("span", [_vm._v(_vm._s(_vm.notification.data.remark || "无备注"))]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }