<!-- @author xinzhong -->
<!-- @email xinzhong@yangqianguan.com -->
<!-- @date 2020-10-28 13:27:36.934 -->
<!-- @desc generated by yqg-cli@1.2.7 -->

<template>
    <b-dialog
        :open="visible"
        dialog-class="sm"
        :close-on-click-outside="false"
        title="请选择本次呼出线路"
        class="select-scene-dialog"
        @close="cancel"
    >
        <div class="select-scene-modal-body">
            <p
                v-if="latestScene && sceneMap[latestScene]"
                class="error"
            >
                该用户最近一次拨通线路：{{ sceneMap[latestScene] }}
            </p>

            <div
                v-if="showInvalidTip"
                class="reason"
            >
                <p
                    v-for="(item, index) in invalidList"
                    :key="index"
                >
                    {{ item.reason }}
                </p>
            </div>

            <div class="select-content">
                <div
                    v-for="sceneItem in latestSceneList"
                    :key="sceneItem.sceneCode"
                    class="scene-item"
                    :class="{active: sceneItem.sceneCode === scene, disabled: !sceneItem.available}"
                    @click="selectScene(sceneItem)"
                >
                    {{ sceneMap[sceneItem.sceneCode] }}
                </div>
            </div>

            <ul
                v-if="sceneTips && sceneTips.length"
                class="tip-list"
            >
                <li
                    v-for="(tip, index) in sceneTips"
                    :key="index"
                >
                    {{ tip }}
                </li>
            </ul>
        </div>

        <div class="call-btn-group">
            <button
                slot="actions"
                class="primary lg"
                @click="confirm()"
            >
                立即拨打
            </button>
        </div>
    </b-dialog>
</template>

<script type="text/babel">

export default {
    name: 'SelectSceneModal',

    props: {
        sceneMap: {
            type: Object
        },

        sceneTips: {
            type: Array,
            default: () => ([])
        },

        latestSceneList: {
            type: Array,
            default: () => []
        }
    },

    data() {
        return {
            visible: false,

            latestScene: null,
            scene: ''
        };
    },

    computed: {
        invalidList() {
            const vm = this;

            return vm.latestSceneList.filter(sceneItem => !sceneItem.available && !sceneItem.is400Number);
        },

        showInvalidTip() {
            const vm = this;

            return !vm.latestSceneList.some(sceneItem => sceneItem.available && !sceneItem.is400Number);
        }
    },

    beforeMount() {
        const vm = this;
        const latestSceneItem = vm.latestSceneList.find(sceneItem => sceneItem.useRecently);
        const validSceneItem = vm.latestSceneList.find(sceneItem => sceneItem.available);
        vm.latestScene = latestSceneItem?.sceneCode || '';
        vm.scene = (latestSceneItem?.available && vm.latestScene) || validSceneItem?.sceneCode;
    },

    methods: {
        cancel() {
            const vm = this;
            vm.reject();
        },

        confirm() {
            const vm = this;
            if (!vm.scene) return;
            vm.resolve(vm.scene);
        },

        selectScene({sceneCode, available}) {
            if (available) {
                this.scene = sceneCode;
            }
        }
    }
};

</script>

<style lang="scss" rel="stylesheet/scss" scoped>

    .select-scene-dialog {
        ::v-deep .b-dialog {
            max-width: 440px;
        }
    }

    .select-scene-modal-body {
        .error {
            color: red;
        }

        .reason {
            font-size: 13px;
            color: brown;
        }

        .select-content {
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            .scene-item {
                padding: 10px 15px;
                min-width: 100px;
                border: 1px solid #ccc;
                background: white;
                border-radius: 4px;
                text-align: center;
                cursor: pointer;
                margin: 0 15px 10px 0;

                &.active {
                    position: relative;
                    color: #7681ff;
                    border: 1px solid #7681ff;
                    background: rgba(118, 129, 255, .1);

                    &:after {
                        content: "";
                        background: url("../../img/checked.png");
                        background-size: 100% 100%;
                        position: absolute;
                        top: -1px;
                        right: -1px;
                        width: 16px;
                        height: 16px;
                    }
                }

                &.disabled {
                    background: #ccc;
                    cursor: not-allowed;
                }
            }
        }

        .tip-list {
            margin-top: 15px;
            font-size: 13px;
            line-height: 1.5;
        }
    }

    .call-btn-group {
        margin-top: 20px;
        text-align: center;
    }

</style>
