/**
 * @author huayizhang
 * @date 2018/11/08 - 14:4
 * @file UserFeedbackTypesMap
 */

import Enum from 'collection-admin-web/common/util/enum';

export default Enum.from({
    ERROR_REPORT: '功能异常：功能故障或不可用、数据异常等',
    ADVICE: '系统建议：用的不爽，我有好建议',
    OTHERS: '其它'
});
