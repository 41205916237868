/**
 * @Author: center
 * @Date: 2022/12/20-4:22 下午
 * @Last Modified by: xymo
 * @Last Modified time: 2024-08-22 14:16:39
 */

import Storage from '.';

const CanaryKey = 'CanaryKey';
export default new Storage(CanaryKey, true);
