import { render, staticRenderFns } from "./md-editor.vue?vue&type=template&id=88379e4a&scoped=true&"
import script from "./md-editor.vue?vue&type=script&lang=js&"
export * from "./md-editor.vue?vue&type=script&lang=js&"
import style0 from "./md-editor.vue?vue&type=style&index=0&id=88379e4a&lang=scss&rel=stylesheet%2Fscss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "88379e4a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/jenkins/agent/workspace/fintopia-web7chz7/fintopia-web-ci/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('88379e4a')) {
      api.createRecord('88379e4a', component.options)
    } else {
      api.reload('88379e4a', component.options)
    }
    module.hot.accept("./md-editor.vue?vue&type=template&id=88379e4a&scoped=true&", function () {
      api.rerender('88379e4a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "packages/vue/antd/component/yqg-simple-form/component/field-md-editor/component/md-editor.vue"
export default component.exports