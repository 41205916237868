var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "alarm-remote-login" }, [
    _vm._v("\n    " + _vm._s(_vm.notification.data.remark) + "\n\n    "),
    _c(
      "a",
      {
        on: {
          click: function ($event) {
            return _vm.finishNotification(_vm.notification.data)
          },
        },
      },
      [_vm._v("忽略此通知")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }