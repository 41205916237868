/**
 * @Author: xymo
 * @Date: 2023-09-19
 * @Last Modified by: xymo
 * @Last Modified time: 2023-09-19 14:23:04
 */
import { assign, customPost } from '@yqg/resource';
const api = {
    uploadEvents: customPost('/event-api/web/uploadEvents')
};
export default assign(api);
