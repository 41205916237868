var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.linkText
    ? _c("router-link", {
        attrs: { target: _vm.target, to: _vm.to },
        domProps: { textContent: _vm._s(_vm.linkText) },
      })
    : _c("p", [_vm._v("\n    /\n")])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }