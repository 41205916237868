/**
 * @Author: giligili
 * @Date: 2021-05-10
 * @Last Modified by: giligili
 * @Last Modified time: 2023-08-04 16:53:14
 */
import fileSaver from 'file-saver';
/* @desc 生成field */
export const genField = (label, field, type, extraProps = {}) => (Object.assign({ label,
    field,
    type }, extraProps));
/** @desc TODO 后面废弃掉 start */
function recurStringList(result = {}, keys, func) {
    const key = keys.shift();
    if (!(result instanceof Object)) {
        result = { self: result }; // eslint-disable-line
    }
    const value = result[key];
    if (!keys.length) {
        return Object.assign(Object.assign({}, result), { [key]: value instanceof Object
                ? Object.assign(Object.assign({}, value), { self: func() }) : func() });
    }
    return Object.assign(Object.assign({}, result), { [key]: recurStringList(result[key], keys, func) });
}
/* @desc 根据连字符生成结构化的对象 */
export const genStructMap = (stringList, func, char = '_') => stringList.reduce((accu, string) => recurStringList(accu, string.split(char), func.bind(null, string)), {});
/** @desc TODO 后面废弃掉 end */
export function isImage(ContentType) {
    const reg = /^image\/.+/;
    return reg.test(ContentType);
}
export function isAudio(ContentType) {
    const reg = /^audio\/.+/;
    return reg.test(ContentType);
}
export function isVideo(ContentType) {
    const reg = /^video\/.+/;
    return reg.test(ContentType);
}
export const clipboard = {
    write(text) {
        try {
            const input = document.createElement('textarea');
            // hidden input
            input.style.position = 'fixed';
            input.style.top = '0';
            input.style.left = '0';
            input.style.zIndex = '0';
            input.style.opacity = '0';
            document.body.appendChild(input);
            input.value = text;
            input.select();
            document.execCommand('Copy');
            document.body.removeChild(input);
            return Promise.resolve(text);
        }
        catch (err) {
            return Promise.reject(err);
        }
    }
};
/** @desc 入参不变缓存返回值 */
export const createMemory = () => {
    let memoryArgvs = [];
    let memoryRet = null;
    return fn => function memoryFactory(...argvs) {
        if (memoryArgvs.length !== argvs.length) {
            memoryRet = fn(...argvs);
        }
        else if (memoryArgvs.some((argv, idx) => argvs[idx] !== argv)) {
            memoryRet = fn(...argvs);
        }
        memoryArgvs = argvs;
        return memoryRet;
    };
};
/* @desc 单例函数(也可以理解为节流, 不过阀值不是time 而是fulfilled 状态的 promise实例) */
export const singleFactory = fn => {
    let flag = false;
    return function single(...vals) {
        if (flag)
            return Promise.reject();
        flag = true;
        return fn.call(this, ...vals)
            .then(res => {
            flag = false;
            return res;
        }).catch(err => {
            flag = false;
            return Promise.reject(err);
        });
    };
};
/* a-select的搜索配置，只匹配中文/英文，不匹配拼音 */
export const aSelectFilterOption = (input, option) => (option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0);
/* @desc 转驼峰 */
export const toCamelCase = (...cases) => cases.reduce((accu, curr) => accu + curr.slice(0, 1).toUpperCase() + curr.slice(1));
/* @desc TODO: 没用到 获得url信息, domain, query, path... */
export const parseUrl = (url = '') => {
    const domainReg = /^https?:\/\/([^/]+).*/;
    return {
        domain: url.replace(domainReg, '$1')
    };
};
/* @desc 生成Promise */
export const genPromise = () => {
    let resolve = null;
    let reject = null;
    const promise = new Promise((res, rej) => {
        resolve = res;
        reject = rej;
    });
    return { resolve, reject, promise };
};
export const getInstallmentIdLessThanTerm = (installmentList, MaxTerm, equals = false, key = 'instalmentId') => installmentList
    .filter(({ term }) => (equals ? term <= MaxTerm : term < MaxTerm))
    .map(item => item[key]);
/**
 * @desc 处理条件流程, 前一个step返回Promise.reject(false), 则执行下一个step
 *       返回Promise.resolve() 表示成功
 * @param {Function} steps
 * @return {Promise}
 */
export const conditionFlowController = (...steps) => steps.reduce((prev, curr) => prev
    .catch(reason => {
    if (reason === false)
        return curr();
    return Promise.reject(reason);
}), Promise.reject(false));
export const jsonParse = (val, defaultValue = null) => {
    let ret = defaultValue;
    try {
        ret = JSON.parse(val);
    }
    catch (err) {
        // ignore err;
    }
    return ret;
};
export const yqgDecodeURIComponent = (val, defaultValue = '') => {
    let ret = defaultValue;
    try {
        ret = window.decodeURIComponent(val);
    }
    catch (err) {
        // ignore err;
    }
    return ret;
};
/**
 * @desc 将同一个请求的并发，改成串行
 * @param {Function}
 * @return {Promise}
 */
export const requestNotConcurrency = func => {
    let promise = Promise.resolve();
    return function (...args) {
        const ret = promise.then(() => func(...args));
        promise = ret.catch(err => err);
        return ret;
    };
};
const TOP_BUFFER = 10;
export const pageJump2 = (layout) => {
    const header = document.getElementById('yqg-collection-header');
    const sticky = document.getElementById('yqg-collection-sticky');
    const height = [header, sticky].filter(dom => dom)
        .reduce((accu, curr) => accu + curr.getBoundingClientRect().height, 0);
    const { top: layoutTop } = layout.getBoundingClientRect();
    document.documentElement.scrollTop += (layoutTop - height - TOP_BUFFER);
};
export const isQuotedByHosts = (hostPrefixs = []) => {
    const { referrer } = document;
    if (!referrer)
        return false;
    const { host } = new URL(referrer);
    return hostPrefixs.some(prefix => host.includes(prefix));
};
export const saveFileFromResponse = (response, defaultFileName = 'download') => {
    // do errorHandler when response type is json
    // get the headers' content disposition
    const cd = response.headers['content-disposition'];
    // get the file name with regex
    const regex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const match = regex.exec(cd);
    let fileName = defaultFileName;
    if (!!cd && match[1]) {
        try {
            const decodedFileName = decodeURIComponent(match[1]);
            fileName = decodedFileName.replace(/^UTF-8/i, '');
        }
        catch (err) {
            fileName = match[1];
        }
    }
    // replace double quote and single quote added to your file name
    fileName = fileName.replace(/"|'/g, '');
    fileSaver.saveAs(response.data, fileName);
};
export const removeNonNumberAndNonLineBreakChar = (value) => value.replace(/[^\d\n]/g, '');
export const removeNonNumberChar = (value) => value.replace(/[^\d]/g, '');
