/**
 * @author center
 * @date 2019/6/3-下午5:08
 * @file agent-status
 */

export const AgentStatus = {
    INIT: 'INIT',
    LOGIN: 'LOGIN',
    LOGOUT: 'LOGOUT',
    AVAILABLE: 'IDLE',
    BUSY: 'BUSY',
    CALLING: 'CALLING',
    DIALING: 'DIALING',
    OFFERING: 'OFFERING',
    CONNECTED: 'CONNECTED',
    HANGUP_HANDLE: 'HANGUP_HANDLE'
};

export default {
    [AgentStatus.INIT]: '初始化',
    [AgentStatus.LOGIN]: '登录',
    [AgentStatus.LOGOUT]: '登出',
    [AgentStatus.AVAILABLE]: '空闲',
    [AgentStatus.BUSY]: '忙碌',
    [AgentStatus.CALLING]: '外呼中',
    [AgentStatus.DIALING]: '外拨振铃',
    [AgentStatus.OFFERING]: '呼入振铃',
    [AgentStatus.CONNECTED]: '接通'
};
