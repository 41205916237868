<!-- @Author: giligili -->
<!-- @Date: 2023-05-18 11:52:56 -->
<!-- @Last Modified by: giligili -->
<!-- @Last Modified time: 2023-05-18 11:52:56 -->

<template>
    <div class="alarm-risk-upgrade-assign">
        【撤案提醒】{{ notification.data.remark }}

        <yqg-case-link
            text="案件详情 >"
            :case-id="notification.data.caseId"
        />

        <p class="mt10">
            <a @click="finishNotification(notification.data)">我知道了</a>
        </p>
    </div>
</template>

<script type="text/babel">

    import {mapActions} from 'vuex';

    export default {
        name: 'AlarmRiskUpgradeAssign',

        props: {
            notification: {
                type: Object,
                default: () => ({})
            }
        },

        methods: {
            ...mapActions('notification', ['finishNotification'])
        }
    };

</script>
