<!-- @author yanglan -->
<!-- @email yanglan@yangqianguan.com -->
<!-- @date 2018-10-29 16:08:21.506 -->
<!-- @desc generated by yqg-cli@0.3.6 -->

<template>
    <b-input
        v-model="editing"
        :rows="rows"
        :rows-max="rowsMax"
        :placeholder="placeholder"
        :filter="filter"
        v-bind="$attrs"
        multi-line
        @change="onChange"
    />
</template>

<script type="text/babel">

export default {
    name: 'YqgArrayInput',

    model: {
        prop: 'value',
        event: 'change'
    },

    props: {
        value: {
            type: Array,
            default: () => ([])
        },
        placeholder: {
            type: String,
            default: '一行一条'
        },
        rows: {
            type: Number,
            default: 5
        },
        rowsMax: {
            type: Number,
            default: 10
        }
    },

    data() {
        return {
            filter: {
                filter: value => value.join('\n'),

                reverseFilter: valueString => valueString.trim()
                    .split('\n')
                    .map(item => item.trim())
                    .filter(item => item !== '')
            },
            editing: this.value
        };
    },

    methods: {
        onChange() {
            this.$emit('change', this.editing);
        }
    }
};

</script>
