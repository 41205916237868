/**
 * @Author: giligili
 * @Date: 2021-08-02
 * @Last Modified by: yefenghan
 * @Last Modified time: 2024-07-18 11:27:06
 */

import NotificationType from 'collection-admin-web/common/constant/types/notification';

export default {
    [NotificationType.ALARM_CASE]: 'AlarmCase',
    [NotificationType.ALARM_ADD_FLOW]: 'AlarmAddFlow',
    [NotificationType.ALARM_FEEDBACK]: 'alarmFeedback',
    [NotificationType.ALARM_COMPLAINT]: 'AlarmComplaint',
    [NotificationType.ALARM_REMIND_CASE]: 'AlarmRemindCase',
    [NotificationType.ALARM_REMOTE_LOGIN]: 'AlarmRemoteLogin',
    [NotificationType.ALARM_USER_LOGIN_INFO]: 'AlarmUserLogin',

    [NotificationType.ALARM_FLOW_END]: 'AlarmFlowEnd',
    [NotificationType.ALARM_FLOW_RECEIVED]: 'AlarmFlowReceived',

    [NotificationType.ALARM_FEEDBACK_FLOW]: 'AlarmFeedbackFlow',

    [NotificationType.ALARM_AUDIT_ABNORMAL]: 'AlarmAuditAbnormal',

    [NotificationType.ALARM_ASSIST_CASE]: 'AlarmAssistCase',
    [NotificationType.ALARM_ASSIST_CASE_STOP]: 'AlarmAssistCaseStop',
    [NotificationType.ALARM_ASSIST_CASE_AGREE]: 'AlarmAssistCaseAgree',
    [NotificationType.ALARM_ASSIST_CASE_REFUSE]: 'AlarmAssistCaseRefuse',
    [NotificationType.ALARM_ASSIST_CASE_FINISH]: 'AlarmAssistCaseFinish',

    [NotificationType.ALARM_REPAY_RECEIPT_RESULT]: 'AlarmRepayReceiptResult',

    [NotificationType.ALARM_MULTI_DIAL_TRANSFER]: 'AlarmMultiDialTransfer',

    [NotificationType.ALARM_TRANSFER_CALL]: 'AlarmTransferCall',
    [NotificationType.ALARM_DIAL_BACK]: 'AlarmDialBack',
    [NotificationType.ALARM_SMART_IVR_TRANSFER_MANUAL]: 'AlarmIvrTransferManual',

    [NotificationType.ALARM_RISK_UPGRADE_ASSIGN]: 'AlarmRiskUpgradeAssign',
    [NotificationType.ALARM_RISK_UPGRADE_ASSIGN_MANUAL_REMAIN]: 'AlarmRiskUpgradeAssign',

    [NotificationType.ALARM_WECHAT_MESSAGE]: 'AlarmWechatMessage',

    [NotificationType.ALARM_SENSITIVE_ASSIGN_CASE]: 'AlarmSensitiveAssignCase',

    [NotificationType.ALARM_URGE_SENSITIVE_ASSIGN_CASE]: 'AlarmUrgeSensitiveAssignCase',

    [NotificationType.REPAY_FAIL_MESSAGE]: 'RepayFailMessage',

    [NotificationType.BLACK_PRODUCTION_USER_ASSIGN]: 'BlackProductionUserAssign',

    [NotificationType.EXPERT_CASE_ENTRY_MESSAGE]: 'ExpertCaseEntryMessage',

    [NotificationType.WORK_STATUS_WARN_DURATION]: 'WorkStatusWarnDuration',

    [NotificationType.ALARM_OA_ADD_ACCOUNT]: 'AlarmOaAddAccount',
};
