<!-- @author panezhang -->
<!-- @email panezhang@yangqianguan.com -->
<!-- @date 2018-07-27 15:07:17.314 -->
<!-- @desc generated by yqg-cli@0.3.0 -->

<template>
    <div
        :class="stateClass"
        class="file-wrapper card"
    >
        <span class="state-text">[{{ stateText }}] </span>
        <span class="file-size">[{{ file.size | yqgUnitByte }}]</span>
        <span class="file-name">{{ file.name }}</span>

        <div class="btn-group">
            <button
                :disabled="disabled"
                class="sm normal"
                @click="onUpload()"
            >
                <i class="b-icon-upload" />
            </button>
            <button
                class="sm danger"
                @click="onDelete()"
            >
                <i class="b-icon-trash" />
            </button>
        </div>

        <div
            :style="{width: progress}"
            class="progress-bar"
        />
    </div>
</template>

<script>

import {STATE_MAP} from './constant';

export default {
    name: 'FileWrapper',

    props: {
        wrapper: {
            type: Object,
            required: true
        },

        disabled: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        file() {
            return this.wrapper.file;
        },

        stateText() {
            return STATE_MAP[this.wrapper.state];
        },

        stateClass() {
            return this.wrapper.state.toLowerCase();
        },

        progress() {
            const {wrapper: {loaded, total}} = this;
            if (!loaded || !total) return 0;
            const progress = Math.floor(loaded * 1e3 / total) / 10;

            return progress ? `${progress}%` : 0;
        }
    },

    methods: {
        onUpload() {
            this.$emit('upload');
        },

        onDelete() {
            this.$emit('delete');
        }
    }
};

</script>

<style lang="scss" rel="stylesheet/scss" scoped>

    .file-wrapper {
        position: relative;

        .progress-bar {
            position: absolute;
            bottom: 0;
            left: 0;
            height: 2px;
            width: 0;
        }

        .btn-group {
            position: absolute;
            bottom: 15px;
            right: 10px;
        }

        .file-name {
            font-weight: bolder;
        }

        &.uploading {
            .state-text {
                color: darkorange;
            }

            .progress-bar {
                background-color: darkorange;
            }
        }

        &.success {
            .state-text {
                color: darkgreen;
            }

            .progress-bar {
                background-color: darkgreen;
            }
        }

        &.failed {
            .state-text {
                color: red;
            }

            .progress-bar {
                background-color: red;
            }
        }
    }

</style>
