var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "string-array-layout" },
    [
      _c(
        "a-form-item",
        {
          attrs: {
            colon: false,
            "label-col": { span: 4 },
            "wrapper-col": { span: 20 },
          },
        },
        [
          _c(
            "span",
            { staticClass: "label", attrs: { slot: "label" }, slot: "label" },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.label) +
                  "\n             \n            "
              ),
              _c("a-icon", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.showDetail,
                    expression: "!showDetail",
                  },
                ],
                attrs: { type: "plus-circle" },
                on: { click: _vm.showDetailChange },
              }),
              _vm._v(" "),
              _c("a-icon", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showDetail,
                    expression: "showDetail",
                  },
                ],
                attrs: { type: "minus-circle" },
                on: { click: _vm.showDetailChange },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("yqg-multi-input", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showDetail,
                expression: "showDetail",
              },
            ],
            attrs: { values: _vm.value },
            on: { change: _vm.onChange },
          }),
          _vm._v(" "),
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.showDetail,
                  expression: "!showDetail",
                },
              ],
            },
            [_vm._v("...")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }