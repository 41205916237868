/*
 * Author: xymo
 * Created Date: 2021-04-21
 * email: xymo@yangqianguan.com
 */

import Privilege from 'collection-admin-web/common/resource/privilege';
import Enum from 'collection-admin-web/common/util/enum';

export default Enum.query(Privilege.listAllRoleGroup, {dataBodyField: 'colRoleGroupList'});
