/**
 * @author panezhang
 * @date 2018/7/30-11:39
 * @file event-bus
 */

import Vue from 'vue';

export const EXPAND_ALL = 'expendAll';

export const COLLAPSE_ALL = 'collapseAll';

export default new Vue();
