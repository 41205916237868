/**
 * @author giligili
 * @date 2021-08-24
 * @email zhimingwang@yangqianguan.com
 * @desc 催收结果配置
 */

import {
    WechatResultsMap,

    ConnectResultsMap,
    UnconnectResultsMap,

    ComplaintConnectResultsMap,
    ComplaintUnconnectResultsMap
} from 'collection-admin-web/common/constant/enum/flow-results-map';

import CollectType from './collect-type';

export default {
    [CollectType.CALL]: [
        {title: '接通', enumType: ConnectResultsMap},
        {title: '未接通', enumType: UnconnectResultsMap}
    ],
    [CollectType.WECHAT]: [
        {enumType: WechatResultsMap}
    ],
    [CollectType.COMPLAINT]: [
        {title: '接通', enumType: ComplaintConnectResultsMap},
        {title: '未接通', enumType: ComplaintUnconnectResultsMap}
    ]
};
