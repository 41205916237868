/**
 * @author zhangpeng
 * @date 17/2/28-下午5:45
 * @file StageUtil
 */

import getStage from '@yqg/stage';

import {STAGE} from '../constant/AppConfig';

export default getStage(STAGE); // eslint-disable-line global-require
