/*
 * @Author: hongweihu
 * @Date: 2022-01-06 16:54:15
 * @Last Modified by: hongweihu
 * @Last Modified time: 2022-01-06 17:39:21
 */

const setStorage = (itemName, item) => {
    window.localStorage.setItem(itemName, item);
};

const getStorage = itemName => window.localStorage.getItem(itemName);

const removeItem = itemName => window.localStorage.removeItem(itemName);

export {setStorage, getStorage, removeItem};
