/**
 * @author giligili
 * @date 2021-06-24
 * @email zhimingwang@yangqianguan.com
 * @desc 通话状态
 */

import BooleanType from '../types/boolean';

export default {
    [BooleanType.TRUE]: '接通',
    [BooleanType.FALSE]: '未接通'
};
