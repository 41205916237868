var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-button-group",
    { staticClass: "sort-button-group" },
    _vm._l(_vm.btnsConfig, function (btn, idx) {
      return _c(
        "a-button",
        {
          key: idx,
          staticClass: "button",
          class: {
            active: btn.status !== "off",
          },
          attrs: { type: btn.status === "off" ? "default" : "primary" },
          on: {
            click: function ($event) {
              return _vm.handleClick(idx)
            },
          },
        },
        [
          _c("span", [_vm._v(_vm._s(btn.text))]),
          _vm._v(" "),
          _c("a-icon", {
            style: {
              color: btn.status === "off" ? "#1890ff" : "",
            },
            attrs: {
              type: btn.status === "ascend" ? "arrow-up" : "arrow-down",
            },
          }),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }