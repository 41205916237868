/*
 * @Author: smwang
 * @Date: 2018-10-09 19:32:40
 * @Last Modified by: smwang
 * @Last Modified time: 2018-10-09 19:33:41
 */

function isObject(value) {
    // http://jsperf.com/isobject4
    return value !== null && typeof value === 'object';
}

function serializeValue(val) {
    if (isObject(val)) {
        return (val instanceof Date) ? val.toISOString() : JSON.stringify(val);
    }

    return val;
}

function encodeUriQuery(val, pctEncodeSpaces) {
    return encodeURIComponent(val)
        .replace(/%40/gi, '@')
        .replace(/%3A/gi, ':')
        .replace(/%24/g, '$')
        .replace(/%2C/gi, ',')
        .replace(/%3B/gi, ';')
        .replace(/%20/g, (pctEncodeSpaces ? '%20' : '+'));
}

export default function (params) {
    if (!params) return '';

    const parts = [];

    Object.keys(params).forEach(key => {
        const value = params[key];

        if (value === null || value === undefined) return;

        if (value instanceof Array) {
            value.forEach(val => parts.push(`${encodeUriQuery(key)}=${encodeUriQuery(serializeValue(val))}`));

            return;
        }

        parts.push(`${encodeUriQuery(key)}=${encodeUriQuery(serializeValue(value))}`);
    });

    return parts.join('&');
}
