var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "alarm-multi-dial-transfer" },
    [
      _vm._v("\n    【反馈提醒】\n\n    "),
      _c("span", { staticClass: "mr10" }, [
        _vm.callTime
          ? _c("span", [_vm._v(_vm._s(_vm._f("dateTime")(_vm.callTime)))])
          : _c("span", [
              _vm._v(
                _vm._s(_vm._f("dateTime")(_vm.notification.data.timeRemind))
              ),
            ]),
      ]),
      _vm._v("\n\n    400进线需回电用户\n\n    "),
      _c("yqg-router-link", {
        staticClass: "mr10",
        attrs: {
          text: "请及时处理",
          to: "case",
          query: { caseId: _vm.encryCaseId },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }