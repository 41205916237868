var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("yqg-static-form", {
    attrs: {
      title: "手机号随机码",
      options: _vm.$options.FormOptions,
      values: { code: _vm.code },
    },
    on: { cancel: _vm.dismiss },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }