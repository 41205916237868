var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("a-select", {
    attrs: {
      "show-search": "",
      "option-filter-prop": "children",
      value: undefined,
      options: _vm.options,
      placeholder: "搜索",
    },
    on: { select: _vm.onSelect },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }