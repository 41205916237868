<!-- @author giligili -->
<!-- @email zhimingwang@yangqianguan.com -->
<!-- @date 2022-12-16 16:51:06.029 -->
<!-- @desc generated by yqg-cli@5.1.1 -->

<template>
    <div class="alarm-multi-dial-transfer">
        【回拨提醒】

        <span class="mr10">
            <span v-if="callTime">{{ callTime | dateTime }}</span>
            <span v-else>{{ notification.data.timeRemind | dateTime }}</span>
        </span>

        回拨进线需回电用户

        <yqg-router-link
            class="mr10"
            text="请及时处理"
            to="case"
            :query="{caseId: encryCaseId}"
        />
    </div>
</template>

<script type="text/babel">

    export default {
        name: 'AlarmDialBack',

        props: {
            notification: {
                type: Object,
                default: () => ({})
            }
        },

        computed: {
            encryCaseId() {
                return window.btoa(this.notification.data.caseId);
            },

            callTime() {
                const {notification} = this;

                return +notification.data.extraData?.callTime;
            }
        }
    };
</script>
